import {
  SagaReturnType,
  all,
  call,
  debounce,
  put,
  select,
  takeLatest
} from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { ProjectionAnalysisDataDto } from '../../../types/dto/ProjectionAnalysisDataDto';
import { ProjectionAnalysisParametersDto } from '../../../types/dto/ProjectionAnalysisParametersDto';
import { errorResponseStatusHandler } from '../../../utils/responseStatusHandler';
import { loadersActions } from '../../loaders/actions';
import { getOperationName } from '../../operationPage/selectors';
import { scenarioAnalysisActions } from '../actions';
import { getCurrentWhatIfScenario } from '../selectors';
import { getScenarioAnalysisActualProjectionData } from './selectors';

function* getProjectionDataProjectionModel(
  action: ActionType<typeof scenarioAnalysisActions.projectedRevenue.getProjectionModel>
) {
  yield put(loadersActions.revenueProjection.activate());
  yield put(scenarioAnalysisActions.projectedRevenue.settings.save(null));

  const operationName: string = yield select(getOperationName);
  const scenarioName: string = yield select(getCurrentWhatIfScenario);

  try {
    const scenarioResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getScenarioAnalysisProjectedRevenue,
      operationName,
      action.payload,
      scenarioName,
      { projectionModel: action.meta }
    );
    const actualResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getProjectedAnalysis,
      operationName,
      action.payload,
      { projectionModel: action.meta }
    );

    toast.success(
      'You changed Revenue Projection Model. The basis is calculated based on historical data. If you want to save this data select Save.',
      { position: 'top-center' }
    );

    yield put(
      scenarioAnalysisActions.projectedRevenue.save({
        scenarioTable: scenarioResponse.data,
        actualTable: actualResponse.data
      })
    );
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.revenueProjection.disable());
  }
}

function* getProjectionData(
  action: ActionType<typeof scenarioAnalysisActions.projectedRevenue.get>
) {
  yield put(loadersActions.revenueProjection.activate());
  const operationName: string = yield select(getOperationName);
  const scenarioName: string = yield select(getCurrentWhatIfScenario);

  try {
    if (scenarioName) {
      const scenarioResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
        insuranceAPI.getScenarioAnalysisProjectedRevenue,
        operationName,
        action.payload,
        scenarioName,
        action.meta
      );
      const actualResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
        insuranceAPI.getProjectedAnalysis,
        operationName,
        action.payload,
        action.meta
      );

      yield put(
        scenarioAnalysisActions.projectedRevenue.save({
          scenarioTable: scenarioResponse.data,
          actualTable: actualResponse.data
        })
      );
    }
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.revenueProjection.disable());
  }
}

type actualTableType = SagaReturnType<typeof getScenarioAnalysisActualProjectionData>;

function* getProjectionDataForSpecificYear(
  action: ActionType<typeof scenarioAnalysisActions.projectedRevenue.getOnBlur>
) {
  yield put(loadersActions.revenueProjection.activate());
  const operationName: string = yield select(getOperationName);
  const scenarioName: string = yield select(getCurrentWhatIfScenario);
  const actualTable: actualTableType = yield select(getScenarioAnalysisActualProjectionData);

  try {
    const scenarioResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getScenarioAnalysisProjectedRevenue,
      operationName,
      action.payload,
      scenarioName,
      action.meta
    );
    yield put(
      scenarioAnalysisActions.projectedRevenue.save({
        scenarioTable: scenarioResponse.data,
        actualTable
      })
    );
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.revenueProjection.disable());
  }
}

function* getProjectionSettings(
  action: ActionType<typeof scenarioAnalysisActions.projectedRevenue.settings.get>
) {
  yield put(loadersActions.revenueProjection.activate());
  const operationName: string = yield select(getOperationName);

  try {
    const response: AxiosResponse<ProjectionAnalysisParametersDto> = yield call(
      insuranceAPI.getProjectedAnalysisSettings,
      operationName,
      action.payload
    );
    if (response.status === 204) {
      yield put(scenarioAnalysisActions.projectedRevenue.settings.save(null));
      yield put(scenarioAnalysisActions.projectedRevenue.get(action.payload, {}));
    } else {
      const { year, ...params } = response.data;
      yield put(scenarioAnalysisActions.projectedRevenue.settings.save({ year, ...params }));
      yield put(scenarioAnalysisActions.projectedRevenue.get(year, params));
    }
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
  }
}

function* updateProjectionSettings(
  action: ActionType<typeof scenarioAnalysisActions.projectedRevenue.settings.update>
) {
  const operationName: string = yield select(getOperationName);
  const { year, ...params } = action.payload;

  try {
    yield call(insuranceAPI.updateProjectedAnalysisSettings, operationName, action.payload);
    yield put(scenarioAnalysisActions.projectedRevenue.settings.save({ year, ...params }));
    yield toast.success('Changes Saved!', {
      position: 'top-right'
    });
    yield put(scenarioAnalysisActions.projectedRevenue.get(year, params));
  } catch (e) {
    console.error(e);
  }
}

function* scenarioAnalysisProjectionDataSagaWatcher() {
  yield all([
    debounce(
      800,
      getType(scenarioAnalysisActions.projectedRevenue.getProjectionModel),
      getProjectionDataProjectionModel
    ),
    takeLatest(getType(scenarioAnalysisActions.projectedRevenue.get), getProjectionData),
    takeLatest(
      getType(scenarioAnalysisActions.projectedRevenue.getOnBlur),
      getProjectionDataForSpecificYear
    ),
    takeLatest(
      getType(scenarioAnalysisActions.projectedRevenue.settings.get),
      getProjectionSettings
    ),
    takeLatest(
      getType(scenarioAnalysisActions.projectedRevenue.settings.update),
      updateProjectionSettings
    )
  ]);
}

export default scenarioAnalysisProjectionDataSagaWatcher;
