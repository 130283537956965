import styled from 'styled-components/macro';

import color from '../../../utils/color';
import Column from '../Column/Column';

const ColumnLabel = styled(Column)`
  text-align: left;
  color: ${color.TEXT_PRIMARY};
`;

export default ColumnLabel;
