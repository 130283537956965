import {
  CorePriceTrendsData,
  PriceTrendsData,
  PriceTrendsMetadata
} from '../../components/Charts/PriceTrend/types';
import { formatDateMonth, formatDateYearMonth } from '../../utils/dateFormatter';
import { months as orderedMonths } from '../../utils/mapQuarter';

export interface PriceTrendsChartData {
  actual: PriceTrendsData[];
  expected: PriceTrendsData[];
}

export function createEmptyRecord(products: string[]): CorePriceTrendsData {
  const record: CorePriceTrendsData = {};
  products.forEach(product => {
    record[product] = null;
  });
  return record;
}

export function mergeWithMetaData(
  data: CorePriceTrendsData,
  metaData: PriceTrendsMetadata
): PriceTrendsData {
  return { ...data, ...metaData } as PriceTrendsData;
}

export function quarterMonths(date: Date) {
  const quarter = Math.floor(date.getMonth() / 3);
  const firstDate = new Date(date.getFullYear(), quarter * 3, 1);
  const endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0);
  return `${formatDateMonth(firstDate)}-${formatDateYearMonth(endDate)}`;
}

export function sortObjectByDateKeys(obj: Record<string, any>): Record<string, any> {
  const sortedKeys: string[] = Object.keys(obj).sort((a, b) => {
    const [monthA, yearA] = a.split(' ');
    const [monthB, yearB] = b.split(' ');

    if (yearA !== yearB) return parseInt(yearA, 10) - parseInt(yearB, 10);
    return orderedMonths.indexOf(monthA) - orderedMonths.indexOf(monthB);
  });

  const sortedObj: Record<string, any> = {};

  for (const key of sortedKeys) {
    sortedObj[key] = obj[key];
  }

  return sortedObj;
}
