import { ActionType, createAction } from 'typesafe-actions';

import { WhatsNew, WhatsNewJson } from '../WhatsNew.types';

export const whatsNewActions = {
  setDataRequest: createAction('@whatsNew/SET_REQ')(),
  setDataSuccess: createAction('@whatsNew/SET_SUCCESS')<WhatsNewJson>(),
  markAllAsRead: createAction('@whatsNew/MARK_ALL_AS_READ')<WhatsNew>(),
  markAsRead: createAction('@whatsNew/MARK_AS_READ')<string>()
};

export type WhatsNewActions = ActionType<typeof whatsNewActions>;
