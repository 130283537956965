import { AxiosResponse } from 'axios';
import { closestTo, isAfter, isBefore, isEqual, isSameDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

import { Dates, Events } from '../../types/Calendar/Calendar';
import { CalendarDto } from '../../types/dto/CalendarDto';
import { utcDateToLocalDate } from '../../utils/utcDateToLocalDate';

export enum DateType {
  INACTIVE,
  ACTIVE,
  HOLIDAY,
  SUSPENDED,
  NO_DATA
}

const mapCalendarDtoToCalendar = ({ data }: AxiosResponse<CalendarDto>) => {
  const dates: Dates = {};
  const events: Events = {};

  let firstDate: Date | undefined;
  let lastDate: Date | undefined;
  let firstReinsuranceYear: number | undefined;
  let lastReinsuranceYear: number | undefined;
  let currentReinsuranceYear: number | undefined;
  let lastComparedDateDate: Date | undefined;
  const calendarInfo: {
    firstDate?: Date;
    lastDate?: Date;
    firstReinsuranceYear?: number;
    lastReinsuranceYear?: number;
    currentReinsuranceYear: number;
  } = { currentReinsuranceYear: new Date().getFullYear() };

  (data.value ?? [])
    .filter(({ Active }) => Active)
    .forEach(v => {
      const calendarDate = utcDateToLocalDate(v.CalendarDate);
      const salesEffectiveDate = v.SalesEffectiveDate
        ? utcDateToLocalDate(v.SalesEffectiveDate)
        : undefined;
      const salesEndDateTime = v.SalesEndDatetime
        ? zonedTimeToUtc(v.SalesEndDatetime.replace('Z', ''), 'America/Chicago')
        : undefined;

      if (!firstDate || isBefore(calendarDate, firstDate)) {
        firstDate = calendarDate;
      }
      if (!lastDate || isAfter(calendarDate, lastDate)) {
        lastDate = calendarDate;
      }

      if (!firstReinsuranceYear || firstReinsuranceYear > v.ReinsuranceYear) {
        firstReinsuranceYear = v.ReinsuranceYear;
      }
      if (!lastReinsuranceYear || lastReinsuranceYear < v.ReinsuranceYear) {
        lastReinsuranceYear = v.ReinsuranceYear;
      }

      const getDateType = () => {
        if (v.IsSalesSuspended) {
          return DateType.SUSPENDED;
        }
        if (v.IsFederalHoliday || v.IsCMEHoliday) {
          return DateType.HOLIDAY;
        }
        if (salesEffectiveDate && isSameDay(calendarDate, salesEffectiveDate)) {
          return DateType.ACTIVE;
        }
        return DateType.INACTIVE;
      };

      const day = calendarDate.getDate();
      const month = calendarDate.getMonth();
      const year = calendarDate.getFullYear();

      const type = getDateType();
      if (type === DateType.SUSPENDED || type === DateType.HOLIDAY || v.EventName) {
        if (!events[v.ReinsuranceYear]) {
          events[v.ReinsuranceYear] = [];
        }
        events[v.ReinsuranceYear].push([year, month, day]);
      }

      if (
        !currentReinsuranceYear ||
        !lastComparedDateDate ||
        !isEqual(
          lastComparedDateDate,
          closestTo(new Date(), [lastComparedDateDate, calendarDate]) ?? 0
        )
      ) {
        currentReinsuranceYear = v.ReinsuranceYear;
        lastComparedDateDate = calendarDate;
      }

      if (!dates[year]) {
        dates[year] = {};
      }
      if (!dates[year][month]) {
        dates[year][month] = {};
      }
      dates[year][month][day] = {
        type,
        date: calendarDate,
        salesEffectiveDate: salesEffectiveDate,
        reinsuranceYear: v.ReinsuranceYear,
        event: v.EventName ? v.EventName : '',
        salesEndDateTime: salesEndDateTime
      };
    });

  if (firstDate) {
    calendarInfo.firstDate = firstDate;
  }
  if (lastDate) {
    calendarInfo.lastDate = lastDate;
  }

  if (firstReinsuranceYear) {
    calendarInfo.firstReinsuranceYear = firstReinsuranceYear;
  }
  if (lastReinsuranceYear) {
    calendarInfo.lastReinsuranceYear = lastReinsuranceYear;
  }
  if (currentReinsuranceYear) {
    calendarInfo.currentReinsuranceYear = currentReinsuranceYear;
  }

  return { dates, events, calendarInfo };
};

export default mapCalendarDtoToCalendar;
