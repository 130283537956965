import styled from 'styled-components/macro';

import CheckboxIcon from '../../../assets/icons/CheckboxIcon';
import color from '../../../utils/color';

const Checkbox = styled(CheckboxIcon)<{ selected?: boolean }>`
  &&& {
    color: ${({ selected = false }) => (selected ? color.DRP_INPUT : color.NEUTRAL_DARK)};
    font-size: 20px;
    cursor: pointer;
  }
`;

export default Checkbox;
