import * as React from 'react';
import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import { HistoricalData } from '../../types/HistoricalData';

export const historicalDataActions = {
  getAll: createAction('@historical_data/GET_ALL')<string>(),
  saveAll: createAction('@historical_data/SAVE_ALL')<
    HistoricalData[],
    React.Dispatch<React.SetStateAction<HistoricalData[]>>
  >(),
  addAll: createAction('@historical_data/ADD_ALL')<HistoricalData[]>(),
  addNewRows: createAction('@historical_data/ADD_NEW_ROWS')<HistoricalData[]>(),
  sortHistoricalData: createAction('@historical_data/SORT')<SortDirection>(),
  editRecord: createAction('@historical_data/EDIT')<HistoricalData, () => void>(),
  updateRecord: createAction('@historical_data/UPDATE')<HistoricalData>(),
  clear: createAction('@historical_data/CLEAR')<{ year: number; month: number }>(),
  delete: createAction('@historical_data/DELETE')<{
    year: number;
    month: number;
    callback: () => void;
  }>(),
  remove: createAction('@historical_data/REMOVE')<{ year: number; month: number }>()
};

export type HistoricalDataActions = ActionType<typeof historicalDataActions>;
