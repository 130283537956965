import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import SortDirection from '../../../enums/SortDirection';
import insuranceAPI from '../../../services/insuranceService';
import { Agency } from '../../../types/Agency';
import Operation from '../../../types/Operation/Operation';
import { OperationDto } from '../../../types/dto/OperationDto';
import sortObjectByKey from '../../../utils/sortObjectByKey';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { AuthState } from '../../authentication/reducer';
import { getAuthState, getEmail } from '../../authentication/selectors';
import { loadersActions } from '../../loaders/actions';
import { adminActions } from '../actions';
import { getOperations } from '../selectors';
import { agencyOperationsParams } from '../types';

function* fetchOperations(action: ActionType<typeof adminActions.operations.getAgencyOperations>) {
  try {
    yield put(loadersActions.adminOperations.activate());
    yield put(loadersActions.operationList.activate());
    let userName = action.payload.userName;
    if (!userName) userName = yield select(getEmail);
    let userRole = action.payload.userRole;
    if (!userRole) userRole = yield select(getAuthState);
    let operations: AxiosResponse<OperationDto[]>;
    if (userName) {
      if (userRole === AuthState.producer) {
        operations = yield call(
          insuranceAPI.getProducerOperations,
          userName,
          action.payload.bindingRequirements
        );
      } else if (userRole !== AuthState.globalAdministrator) {
        operations = yield call(
          insuranceAPI.getAgentOperations,
          userName,
          action.payload.bindingRequirements
        );
      } else {
        operations = yield call(
          insuranceAPI.getAgencyOperations,
          action.payload.agencyName,
          action.payload.bindingRequirements
        );
      }
      yield put(adminActions.operations.addAllOperations(operations.data));
    }
    yield put(loadersActions.adminOperations.disable());
    yield put(loadersActions.operationList.disable());
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* createAgencyOperation(action: ActionType<typeof adminActions.operations.addOperation>) {
  try {
    yield put(loadersActions.spinner.activate());
    const operationName: AxiosResponse<{ operationName: string }> = yield call(
      insuranceAPI.createAgencyOperation,
      action.meta.agencyName,
      action.payload
    );
    yield put(
      adminActions.operations.newOperation({
        ...action.payload,
        name: operationName.data.operationName
      })
    );
    yield put(loadersActions.spinner.disable());
    action.meta.cb();
    yield put(
      adminActions.operations.sort({ sort: action.meta.sort, direction: action.meta.direction })
    );
    toast.success('Operation created');
  } catch (error: any) {
    yield action.meta.cb();
    yield put(loadersActions.spinner.disable());
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* editAgencyOperation(action: ActionType<typeof adminActions.operations.editOperation>) {
  try {
    const agency: Agency | null = yield select(getAdminPageAgency);
    const operationName: AxiosResponse<{ operationName: string }> = yield call(
      insuranceAPI.editAgencyOperation,
      agency?.name ?? '',
      action.meta.name,
      action.payload
    );
    yield put(
      adminActions.operations.updateOperation(
        { ...action.payload, name: operationName.data.operationName },
        action.meta.name
      )
    );
    yield toast.success('Successfully edited operation.');
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  } finally {
    action.meta.cb();
  }
}

function* deleteAgencyOperation(
  action: ActionType<typeof adminActions.operations.deleteOperation>
) {
  try {
    yield put(loadersActions.spinner.activate());

    const operations: Operation[] = yield select(getOperations);
    const selectedOperation = operations.find(
      ({ name }: { name: string }) => name === action.payload
    );
    const OperationFullName = selectedOperation?.fullName || action.payload;

    const agency: Agency | null = yield select(getAdminPageAgency);
    yield call(insuranceAPI.deleteAgencyOperation, agency?.name ?? '', action.payload);
    yield put(adminActions.operations.removeOperation(action.payload));
    yield put(loadersActions.spinner.disable());
    action.meta();
    toast.success(`Successfully deleted operation ${OperationFullName}`);
  } catch (error) {
    action.meta();
    yield put(loadersActions.spinner.disable());
    console.log(error);
  }
}

export function* sortOperations({
  payload: { sort, direction }
}: ActionType<typeof adminActions.operations.sort>): any {
  if (!sort) {
    sort = 'fullName';
  }
  if (!direction) {
    direction = SortDirection.ASCENDING;
  }

  const operations = yield select(getOperations);

  operations.sort(sortObjectByKey(sort, direction));

  yield put(adminActions.operations.addAllOperations([...operations]));
}

function* editOperationInsuranceProgram(
  action: ActionType<typeof adminActions.operations.editOperationInsuranceProgram>
) {
  try {
    const operations: Operation[] = yield select(getOperations);
    const agency: Agency | null = yield select(getAdminPageAgency);
    const operation = operations.find(o => o.fullName === action.payload.fullName);
    const operationName: AxiosResponse<{ operationName: string }> = yield call(
      insuranceAPI.editAgencyOperation,
      agency?.name ?? '',
      action.meta.name,
      action.payload
    );
    action.meta.cb();
    yield put(
      adminActions.operations.updateOperation(
        { ...action.payload, name: operationName.data.operationName },
        action.meta.name
      )
    );
    if (
      operation &&
      operation.insurancePrograms.includes('LGM') !==
        action.payload.insurancePrograms.includes('LGM')
    ) {
      yield toast.success(
        `Livestock Gross Margin - Dairy ${
          action.payload.insurancePrograms.includes('LGM') ? 'enabled' : 'disabled'
        } for ${operation.fullName}.`
      );
    }
    if (
      operation &&
      operation.insurancePrograms.includes('DRP') !==
        action.payload.insurancePrograms.includes('DRP')
    ) {
      yield toast.success(
        `Dairy Revenue Protection ${
          action.payload.insurancePrograms.includes('DRP') ? 'enabled' : 'disabled'
        } for ${operation.fullName}.`
      );
    }
    if (
      operation &&
      operation.insurancePrograms.includes('LRP') !==
        action.payload.insurancePrograms.includes('LRP')
    ) {
      yield toast.success(
        `Livestock Risk Protection ${
          action.payload.insurancePrograms.includes('LRP') ? 'enabled' : 'disabled'
        } for ${operation.fullName}.`
      );
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* editAllOperations(action: ActionType<typeof adminActions.operations.editAllOperations>) {
  const agency: Agency | null = yield select(getAdminPageAgency);
  const agencyName: string = agency?.name ?? '';
  try {
    yield all(
      action.payload.map(operation =>
        call(insuranceAPI.editAgencyOperation, agencyName, operation.name, operation)
      )
    );
    yield put(adminActions.operations.getAgencyOperations(agencyOperationsParams(agencyName)));
    action.meta();
    toast.success('Operations Edited');
  } catch (e) {
    console.error(e);
  }
}

export function* operationsSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.operations.getAgencyOperations), fetchOperations),
    takeLatest(getType(adminActions.operations.addOperation), createAgencyOperation),
    takeLatest(getType(adminActions.operations.editOperation), editAgencyOperation),
    takeLatest(
      getType(adminActions.operations.editOperationInsuranceProgram),
      editOperationInsuranceProgram
    ),
    takeLatest(getType(adminActions.operations.deleteOperation), deleteAgencyOperation),
    takeLatest(getType(adminActions.operations.sort), sortOperations),
    takeLatest(getType(adminActions.operations.editAllOperations), editAllOperations)
  ]);
}
