import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { Agency } from '../../types/Agency';
import OperationReportData from '../../types/ManagementReports/OperationReport';
import { SmsUsageDto } from '../../types/SmsSettings';
import { getAgency } from '../agency/selectors';
import { managementReportActions } from './actions';

function* getOperationReports(
  action: ActionType<typeof managementReportActions.getOperationReports>
) {
  const agency: Agency | null = yield select(getAgency);
  action.meta(true);
  const {
    startCalendarYear,
    endCalendarYear,
    startQuarter,
    endQuarter,
    startReinsuranceYear,
    endReinsuranceYear
  } = action.payload;
  try {
    const operationReportsDto: AxiosResponse<OperationReportData> = yield call(
      insuranceAPI.getOperationReports,
      agency?.name ?? '',
      startCalendarYear,
      endCalendarYear,
      startQuarter,
      endQuarter,
      startReinsuranceYear,
      endReinsuranceYear
    );
    yield put(managementReportActions.saveOperationReports(operationReportsDto.data));
  } catch (e) {
    console.error(e);
  } finally {
    action.meta(false);
  }
}

function* getSmsUsage(action: ActionType<typeof managementReportActions.getSmsUsage>) {
  const { startDate, endDate, agency } = action.payload;
  try {
    const smsUsageDto: AxiosResponse<SmsUsageDto[]> = yield call(
      insuranceAPI.getSmsUsage,
      startDate,
      endDate,
      agency,
      true
    );
    yield put(managementReportActions.saveSmsCulmulativeUsageData(smsUsageDto.data));
  } catch (e) {
    console.error(e);
    toast.error('Sms Usage Tracking Data can not be retrieved!');
    yield put(managementReportActions.saveSmsCulmulativeUsageData([]));
  }
}

function* getSmsUsageForAgency(
  action: ActionType<typeof managementReportActions.getSmsUsageForAgency>
) {
  const { startDate, endDate, agency } = action.payload.settings;
  const agencyName = action.payload.agencyName;
  try {
    const smsUsageDto: AxiosResponse<SmsUsageDto[]> = yield call(
      insuranceAPI.getSmsUsageForAgency,
      agencyName,
      startDate,
      endDate,
      agency,
      true
    );
    yield put(managementReportActions.saveSmsCulmulativeUsageData(smsUsageDto.data));
  } catch (e) {
    console.error(e);
    toast.error('Sms Usage Tracking Data can not be retrieved!');
    yield put(managementReportActions.saveSmsCulmulativeUsageData([]));
  }
}

function* getSmsDetailedUsage(
  action: ActionType<typeof managementReportActions.getSmsDetailedUsage>
) {
  const { startDate, endDate, agency } = action.payload;
  try {
    const smsUsageDto: AxiosResponse<SmsUsageDto[]> = yield call(
      insuranceAPI.getSmsUsage,
      startDate,
      endDate,
      agency,
      false
    );
    yield put(managementReportActions.saveSmsDetailedUsageData(smsUsageDto.data));
  } catch (e) {
    console.error(e);
    toast.error('Sms Usage Tracking Data can not be retrieved!');
    yield put(managementReportActions.saveSmsDetailedUsageData([]));
  }
}

function* getSmsDetailedUsageForAgency(
  action: ActionType<typeof managementReportActions.getSmsDetailedUsageForAgency>
) {
  const { startDate, endDate, agency } = action.payload.settings;
  const agencyName = action.payload.agencyName;
  try {
    const smsUsageDto: AxiosResponse<SmsUsageDto[]> = yield call(
      insuranceAPI.getSmsUsageForAgency,
      agencyName,
      startDate,
      endDate,
      agency,
      false
    );
    yield put(managementReportActions.saveSmsDetailedUsageData(smsUsageDto.data));
  } catch (e) {
    console.error(e);
    toast.error('Sms Usage Tracking Data can not be retrieved!');
    yield put(managementReportActions.saveSmsCulmulativeUsageData([]));
  }
}

function* managementReportSagaWatcher() {
  yield all([
    takeLatest(getType(managementReportActions.getOperationReports), getOperationReports),
    takeLatest(getType(managementReportActions.getSmsUsage), getSmsUsage),
    takeLatest(getType(managementReportActions.getSmsUsageForAgency), getSmsUsageForAgency),
    takeLatest(getType(managementReportActions.getSmsDetailedUsage), getSmsDetailedUsage),
    takeLatest(
      getType(managementReportActions.getSmsDetailedUsageForAgency),
      getSmsDetailedUsageForAgency
    )
  ]);
}

export default managementReportSagaWatcher;
