import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Endorsement } from '../../../types/Endorsement/Endorsement';
import { EndorsementSummary } from '../../../types/Endorsement/EndorsementSummary';
import { PracticeName } from '../../../types/Practice';
import { CoverageRequestDto, CoverageRequestHistoryDto } from '../../../types/dto/CoverageRequest';
import { EndorsementDrp, EndorsementLrp } from '../../../types/dto/EndorsementDto';
import createUniqueArray from '../../../utils/createUniqueArray';
import { EndorsementActions, endorsementActions } from '../actions';

type InitialState = {
  operations: string[];
  operationEndorsementsSummaries: {
    [key: string]: EndorsementSummary[];
  };
  operationEndorsements: {
    [key: string]: Endorsement[];
  };
  filteredOperationEndorsements: {
    [key: string]: Endorsement[];
  };
  coverageRequest: CoverageRequestDto | undefined;
  coverageRequests: CoverageRequestDto[];
  coverageRequestsFetchTimeStamp: Date | undefined;
  coverageRequestHistory: CoverageRequestHistoryDto[];
  coverageRequestEndorsements: EndorsementDrp[] | EndorsementLrp[];
  quarterList: PracticeName[];
};

const initialState: InitialState = {
  operations: [],
  operationEndorsementsSummaries: {},
  operationEndorsements: {},
  filteredOperationEndorsements: {},
  coverageRequest: undefined,
  coverageRequests: [],
  coverageRequestsFetchTimeStamp: undefined,
  coverageRequestHistory: [],
  coverageRequestEndorsements: [],
  quarterList: []
};

export const endorsementReducer = (state = initialState, action: EndorsementActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(endorsementActions.addAllEndorsementSummaries):
        draft.operations = createUniqueArray([...draft.operations, action.payload]);
        draft.operationEndorsementsSummaries[action.payload] = action.meta;
        return;
      case getType(endorsementActions.addAllEndorsements):
        draft.operations = createUniqueArray([...draft.operations, action.payload]);
        draft.operationEndorsements[action.payload] = action.meta;
        return;
      case getType(endorsementActions.addFilteredEndorsements):
        draft.filteredOperationEndorsements[action.payload] = action.meta;
        return;
      case getType(endorsementActions.addEndorsement): {
        draft.operationEndorsements[action.payload] = [
          ...draft.operationEndorsements[action.payload],
          action.meta
        ];
        return;
      }
      case getType(endorsementActions.setCoverageRequest): {
        draft.coverageRequest = action.payload;
        return;
      }
      case getType(endorsementActions.setCoverageRequests): {
        draft.coverageRequests = action.payload;
        return;
      }
      case getType(endorsementActions.setCoverageRequestFetchTimeStamp): {
        draft.coverageRequestsFetchTimeStamp = new Date();
        return;
      }
      case getType(endorsementActions.resetCoverageRequests): {
        draft.coverageRequests = [];
        draft.coverageRequestsFetchTimeStamp = undefined;
        return;
      }
      case getType(endorsementActions.saveCoverageRequestHistory): {
        draft.coverageRequestHistory = action.payload;
        return;
      }
      case getType(endorsementActions.setCoverageRequestEndorsements): {
        draft.coverageRequestEndorsements = action.payload;
        return;
      }
      case getType(endorsementActions.saveQuarterListCoverageRequestByRefIdentifier): {
        draft.quarterList = action.payload;
        return;
      }
      case getType(endorsementActions.removeEndorsement): {
        if (!draft.operationEndorsements[action.payload.operation]) {
          return;
        }
        const endorsementIndex = draft.operationEndorsements[action.payload.operation].findIndex(
          ({ id }) => id === action.payload.id
        );
        let filteredEndorsementIndex = -1;
        if (draft.filteredOperationEndorsements[action.payload.operation]) {
          filteredEndorsementIndex = draft.filteredOperationEndorsements[
            action.payload.operation
          ].findIndex(({ id }) => id === action.payload.id);
        }
        if (endorsementIndex !== -1 || filteredEndorsementIndex !== -1) {
          if (endorsementIndex !== -1) {
            draft.operationEndorsements[action.payload.operation].splice(endorsementIndex, 1);
          }
          if (filteredEndorsementIndex !== -1) {
            draft.filteredOperationEndorsements[action.payload.operation].splice(
              filteredEndorsementIndex,
              1
            );
          }
        }
        return;
      }
    }
  });
