import { RootState } from '../store';

export const getOperationPreferences = (state: RootState) => state.operationPreferences;

export const getOperationPreferencesStateCode = (state: RootState) =>
  state.operationPreferences.stateCode;

export const getOperationPreferencesClassPrice = (state: RootState) =>
  state.operationPreferences.preferredClassPriceWeightingFactor;

export const getOperationPreferencesComponentPrice = (state: RootState) =>
  state.operationPreferences.preferredComponentPriceWeightingFactor;

export const getShowPreferredInsuredShare = (state: RootState) =>
  state.operationPreferences.preferredInsuredShare < 1;
