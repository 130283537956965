enum InsurancePlan {
  drp = 83,
  lrp = 81,
  lgm = 82
}

export const getInsurancePlanEnum = (insurancePlan: string | number): InsurancePlan => {
  if (typeof insurancePlan === 'string' || (insurancePlan as any) instanceof String) {
    return InsurancePlan[(insurancePlan as string).toLowerCase() as keyof typeof InsurancePlan];
  }

  return insurancePlan;
};

export default InsurancePlan;
