import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

export type TableVariant = 'default' | 'secondary' | 'aipsync' | 'admin' | 'subRows';

export const tableVariants: Record<TableVariant, React.CSSProperties> = {
  default: {
    backgroundColor: color.BACKGROUND,
    color: color.TEXT_PRIMARY,
    fontWeight: 400
  },
  secondary: {
    backgroundColor: color.BACKGROUND_TABLE_HEADER,
    color: color.TEXT_PRIMARY,
    fontWeight: 600
  },
  aipsync: {
    backgroundColor: color.BACKGROUND_SECONDARY,
    color: color.TEXT_PRIMARY,
    fontWeight: 400,
    position: 'sticky'
  },
  admin: {
    backgroundColor: '#fff',
    color: color.TEXT_PRIMARY,
    fontWeight: 600
  },
  subRows: {
    backgroundColor: color.BACKGROUND_TABLE_HEADER,
    color: color.TEXT_PRIMARY,
    fontSize: '1rem'
  }
};

const HeaderWrapper = styled.div<{ index?: number; justifyLead?: boolean; variant?: TableVariant }>`
  width: 100%;
  display: flex;
  justify-content: ${({ variant }) => (variant === 'admin' ? 'flex-start' : 'center')};
  align-items: center;
  gap: 4px;

  &:first-child {
    justify-content: ${({ index, justifyLead, variant }) =>
      (justifyLead && index === 0) || variant === 'admin' ? 'flex-start' : 'center'};
  }
`;

interface TrProps extends React.HTMLAttributes<HTMLTableRowElement> {
  variant?: TableVariant;
  selected?: boolean;
}

const Tr = styled.tr<TrProps>`
  ${({ variant, selected }) => {
    let backgroundColor: string = color.BACKGROUND_SECONDARY;
    if (variant === 'aipsync' || variant === 'admin' || variant === 'subRows') backgroundColor = '';
    if (selected) backgroundColor = color.DRP_INPUT;

    return css`
      background-color: ${backgroundColor};
      border-bottom: ${color.BACKGROUND_SECONDARY} 1px solid;
      text-align: center;
      color: ${color.TEXT_PRIMARY};
      font-size: 12px;
      cursor: pointer;

      &:last-of-type {
        border-bottom: ${color.NEUTRAL} solid 2px;
      }
      &:nth-child(even) {
        background-color: ${selected ? color.DRP_INPUT : color.BACKGROUND};
      }
    `;
  }}
`;

interface ThProps extends React.HTMLAttributes<HTMLTableCellElement> {
  headerType?: 'columns' | 'groups';
  cellSeparator?: boolean;
  drawRightBorder?: boolean;
  variant?: TableVariant;
}

const Th = styled.th<ThProps>`
  ${({ headerType, cellSeparator, drawRightBorder, variant = 'default' }) => {
    return css`
      padding: 10px;
      border-bottom: solid 2px ${color.NEUTRAL};
      text-align: ${variant === 'admin' ? 'left' : 'center'};

      background-color: ${headerType === 'groups' ? color.BACKGROUND_TABLE_GROUP : ''};
      font-size: ${headerType === 'groups' || variant === 'admin' || variant === 'subRows'
        ? '14px'
        : '12px'};
      border-right: ${cellSeparator || drawRightBorder || headerType === 'groups'
        ? '1px solid ' + color.NEUTRAL
        : 'none'};

      &:first-child {
        white-space: nowrap;
        border-right: ${headerType === 'groups' || drawRightBorder ? '' : 'none'};
      }

      &:last-child {
        border-right: none;
      }
    `;
  }}
`;

interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  variant: TableVariant;
}

const TableComp = styled.table<TableProps>`
  border-collapse: collapse;
  width: 100%;
`;

interface TdProps extends React.HTMLAttributes<HTMLTableCellElement> {
  cellSeparator?: boolean;
  drawRightBorder?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  variant?: TableVariant;
}

const Td = styled.td<TdProps>`
  ${({ cellSeparator, drawRightBorder, textAlign, variant = 'default' }) => {
    return css`
      border-bottom: solid 1px ${color.NEUTRAL_LIGHT};
      height: 2rem;
      font-size: ${variant === 'admin' || variant === 'subRows' ? '0.875rem' : '0.75rem'};

      text-align: ${textAlign};
      padding-right: ${textAlign === 'right' ? '10px' : 0};
      padding-left: ${textAlign === 'left' ? '10px' : 0};
      padding-top: ${variant === 'admin' ? '0.7rem' : '0'};
      padding-bottom: ${variant === 'admin' ? '0.7rem' : '0'};
      border-right: ${cellSeparator || drawRightBorder ? '1px solid ' + color.NEUTRAL : 'none'};

      &:first-child {
        text-align: ${textAlign ?? 'center'};
        width: ${cellSeparator ? '170px' : 'auto'};
        min-width: ${cellSeparator ? '170px' : 'auto'};
      }

      &:last-child {
        border-right: ${drawRightBorder ? '1px solid ' + color.NEUTRAL : 'none'};
      }
    `;
  }}
`;

interface THeadProps extends React.HTMLAttributes<HTMLTableRowElement> {
  variant?: TableVariant;
}

const THead = styled.thead<THeadProps>`
  ${({ theme, variant = 'default' }) => {
    const { backgroundColor, color, fontWeight, position } = theme.components.table[variant];
    return css`
      background-color: ${backgroundColor};
      color: ${color};
      font-weight: ${fontWeight};
      position: ${position ?? 'relative'};
      z-index: 1;
      top: 0;
    `;
  }}
`;

const TableHeader = styled.div`
  padding: 5px;
  width: 100%;
  color: ${color.TEXT_PRIMARY};
  background-color: ${color.BACKGROUND_TABLE_HEADER};
  border-bottom: ${color.PRIMARY} 2px solid;
  font-size: 1rem;
`;

const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  background-color: ${color.BACKGROUND_TABLE_HEADER};
  border-bottom: ${color.PRIMARY} 2px solid;
`;

const PrimaryTitle = styled.div`
  padding: 5px;
  color: ${color.TEXT_PRIMARY};
  font-size: 1rem;
`;

const SecondaryTitle = styled.div`
  padding: 7px 15px;
  color: ${color.TEXT_PRIMARY};
  font-size: 0.75rem;
`;

const SortIconWrapper = styled.div`
  font-size: 1rem;
`;

const FilterWrapper = styled.div`
  width: 90%;
`;

const ThWrapper = styled.div<{
  thWrapperHeight?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  height: ${({ thWrapperHeight }) => thWrapperHeight ?? 'auto'};
  justify-content: space-between;
`;

const TFoot = styled.tfoot`
  font-weight: 600;
  background-color: ${color.BACKGROUND_SECONDARY};
`;

export {
  HeaderWrapper,
  Tr,
  Th,
  TableComp,
  Td,
  THead,
  TableHeader,
  TableHeaderContainer,
  PrimaryTitle,
  SecondaryTitle,
  SortIconWrapper,
  FilterWrapper,
  ThWrapper,
  TFoot
};
