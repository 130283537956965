import styled from 'styled-components/macro';

import color from '../../../utils/color';

const Column = styled.td<{ width?: number }>`
  width: ${({ width }) => (width ? width : 'auto')};
  text-align: right;
  padding: 5px 0;
  color: ${color.PRIMARY};
`;

export default Column;
