import { all, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { call, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import lgmApi from '../../../services/lgmService';
import { LgmRow } from '../../../types/Lgm/LgmRow';
import { LgmWhatIfActual, LgmWhatIfActualDto } from '../../../types/Lgm/LgmWhatIfActual';
import { LgmWhatIfPayload } from '../../../types/Lgm/LgmWhatIfPayload';
import { LgmWhatIfPremiumDto } from '../../../types/Lgm/LgmWhatIfPremium';
import { formatDateISO } from '../../../utils/dateFormatter';
import { lgmLoaderActions } from '../loaders/actions';
import { getLgmRowData } from '../quoter/selectors';
import { getActualPrices, getLgmCalendarDate, getLgmDeductible } from '../settings/selectors';
import { lgmWhatIfActions } from './actions';
import { getLgmWhatIfActualPrices } from './selector';

function* getLgmWhatIfActual(action: ActionType<typeof lgmWhatIfActions.getLgmWhatIfActual>) {
  yield put(lgmLoaderActions.setLgmWhatIfActualLoader(true));
  yield put(lgmLoaderActions.setLgmWhatIfPremiumLoader(true));

  const calendarDate: Date = yield select(getLgmCalendarDate);
  const salesDate = formatDateISO(calendarDate);
  const deductibleLevel: number = yield select(getLgmDeductible);

  try {
    const actualPrices: AxiosResponse<LgmWhatIfActualDto> = yield call(
      lgmApi.getLgmWhatIfActual,
      salesDate
    );
    yield put(lgmWhatIfActions.saveLgmWhatIfActual(actualPrices.data.actualWhatIfValues));
    yield put(lgmLoaderActions.setLgmWhatIfActualLoader(false));
    const lgmWhatIfData: LgmWhatIfPayload = {
      actualPrices: action.payload.actualPrices,
      salesDate,
      deductibleLevel,
      monthValues: action.payload.monthValues,
      whatIfActualMonthValues: actualPrices.data.actualWhatIfValues.map(e => ({
        date: e.date,
        actualMilkPrice: e.actualMilkPrice,
        actualCornPrice: e.actualCornPrice,
        actualSoybeanPrice: e.actualSoybeanPrice
      }))
    };

    const premiumData: AxiosResponse<LgmWhatIfPremiumDto> = yield call(
      lgmApi.getLgmWhatIfPremium,
      lgmWhatIfData
    );
    yield put(lgmWhatIfActions.saveLgmWhatIfPremium(premiumData.data.whatIfPremiumValues));
    yield put(lgmLoaderActions.setLgmWhatIfPremiumLoader(false));
  } catch (e) {
    yield put(lgmWhatIfActions.saveLgmWhatIfActual([]));
    yield put(lgmWhatIfActions.saveLgmWhatIfPremium([]));
    console.error(e);
  } finally {
    yield put(lgmLoaderActions.setLgmWhatIfActualLoader(false));
    yield put(lgmLoaderActions.setLgmWhatIfPremiumLoader(false));
  }
}

function* getlgmWhatIfPremium(
  action: ActionType<typeof lgmWhatIfActions.changeLgmWhatIfActualRow>
) {
  const salesDate: Date = yield select(getLgmCalendarDate);
  const deductibleLevel: number = yield select(getLgmDeductible);
  const quarterRows: LgmRow[] = yield select(getLgmRowData);
  const isActual: boolean = yield select(getActualPrices);

  const { date, values } = action.payload;

  try {
    yield put(lgmLoaderActions.setLgmWhatIfPremiumLoader(true));
    yield put(
      lgmWhatIfActions.saveLgmWhatIfActualRow(
        {
          ...values
        },
        date
      )
    );
    const actualPrices: LgmWhatIfActual[] = yield select(getLgmWhatIfActualPrices);
    const lgmWhatIfData: LgmWhatIfPayload = {
      actualPrices: isActual,
      salesDate: formatDateISO(salesDate),
      deductibleLevel,
      monthValues: quarterRows.map(e => ({
        date: formatDateISO(e.date),
        coveredMilkCwt: e.quantity.milk,
        coveredCorn: e.quantity.corn,
        coveredSoybeanMeal: e.quantity.sbm
      })),
      whatIfActualMonthValues: actualPrices.map(e => ({
        date: e.date,
        actualMilkPrice: e.actualMilkPrice,
        actualCornPrice: e.actualCornPrice,
        actualSoybeanPrice: e.actualSoybeanPrice
      }))
    };
    const premiumData: AxiosResponse<LgmWhatIfPremiumDto> = yield call(
      lgmApi.getLgmWhatIfPremium,
      lgmWhatIfData
    );
    yield put(lgmWhatIfActions.saveLgmWhatIfPremium(premiumData.data.whatIfPremiumValues));
    toast.success('Updated What If Premium');
  } catch (e) {
    console.error(e);
  } finally {
    yield put(lgmLoaderActions.setLgmWhatIfPremiumLoader(false));
  }
}

export function* lgmWhatIfSagaWatcher() {
  yield all([
    takeLatest(getType(lgmWhatIfActions.getLgmWhatIfActual), getLgmWhatIfActual),
    takeLatest(getType(lgmWhatIfActions.changeLgmWhatIfActualRow), getlgmWhatIfPremium)
  ]);
}
