import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

type Field = { expected: number; actual: number };

export interface DairyRevenueProtectionWhatIfExpectedActualState {
  butterfat: Field;
  protein: Field;
  otherSolidsPrice: Field;
  nonfatSolidsPrice: Field | null;
  classIIIPrice: Field;
  classIVPrice: Field;
}

const initialState: DairyRevenueProtectionWhatIfExpectedActualState = {
  butterfat: { expected: 0, actual: 0 },
  protein: { expected: 0, actual: 0 },
  otherSolidsPrice: { expected: 0, actual: 0 },
  nonfatSolidsPrice: { expected: 0, actual: 0 },
  classIIIPrice: { expected: 0, actual: 0 },
  classIVPrice: { expected: 0, actual: 0 }
};

export const dairyRevenueProtectionWhatIfExpectedActualReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(dairyRevenueProtectionActions.setWhatIfExpectedActual):
        return action.payload;
      case getType(dairyRevenueProtectionActions.setActualInputs.butterfat):
        draft.butterfat.actual = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.setActualInputs.protein):
        draft.protein.actual = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.setActualInputs.otherSolids):
        draft.otherSolidsPrice.actual = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.setActualInputs.nonfatSolids):
        if (draft.nonfatSolidsPrice) {
          draft.nonfatSolidsPrice.actual = action.payload;
        }
        return;
      case getType(dairyRevenueProtectionActions.setActualInputs.classIII):
        draft.classIIIPrice.actual = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.setActualInputs.classIV):
        draft.classIVPrice.actual = action.payload;
        return;
      default:
        return state;
    }
  });
