import { AxiosResponse } from 'axios';

import { HistoricalAnalysis } from '../../types/HistoricalAnalysis';
import { HistoricalAnalysisDto } from '../../types/dto/HistoricalAnalysisDto';

const mapHistoricalAnalysisDtoToHistoricalAnalysis = ({
  data
}: AxiosResponse<HistoricalAnalysisDto>): HistoricalAnalysis => ({
  historyAnalysisModels: data.historyAnalysisModels.map(
    ({ reportPeriod, collectionDate, class: classData, component, horizon }) => ({
      reportPeriod: new Date(reportPeriod),
      collectionDate: new Date(collectionDate),
      class: classData
        ? {
            expectedPrice: classData.expectedPrice,
            actualPrice: classData.actualPrice,
            indemnity: classData.indemnityCwt
          }
        : null,
      component: component
        ? {
            expectedPrice: component.expectedPrice,
            actualPrice: component.actualPrice,
            indemnity: component.indemnityCwt
          }
        : null,
      horizon
    })
  ),
  summary: data.summary
});

export default mapHistoricalAnalysisDtoToHistoricalAnalysis;
