import { ActionType, createAction } from 'typesafe-actions';

import { LgmEstimatedPayload } from '../../../types/Lgm/LgmEstimatedPayload';
import { LgmWhatIfActual } from '../../../types/Lgm/LgmWhatIfActual';
import LgmWhatIfPremium from '../../../types/Lgm/LgmWhatIfPremium';

export const lgmWhatIfActions = {
  getLgmWhatIfPremium: createAction('@lgm_whatIf/GET_PREMIUM')(),
  getLgmWhatIfActual: createAction('@lgm_whatIf/GET_ACTUAL')<LgmEstimatedPayload>(),
  saveLgmWhatIfActual: createAction('@lgm_whatIf/SAVE_ACTUAL')<LgmWhatIfActual[]>(),
  saveLgmWhatIfPremium: createAction('@lgm_whatIf/SAVE_PREMIUM')<LgmWhatIfPremium[]>(),
  changeLgmWhatIfActualRow: createAction('@lgm_whatIF/CHANGE_ACTUAL')<{
    values: { milk: number; corn: number; sbm: number };
    date: string;
  }>(),
  saveLgmWhatIfActualRow: createAction('@lgm_whatIF/SAVE_ACTUAL')<
    { milk: number; corn: number; sbm: number },
    string
  >()
};

export type LGMWhatIfActions = ActionType<typeof lgmWhatIfActions>;
