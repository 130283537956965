import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

export interface DairyRevenueProtectionWhatIfInputsState {
  butter: [number, number, number];
  cheese: [number, number, number];
  dryWhey: [number, number, number];
  noFatDryMilk: [number, number, number];
  milkProductionPerCow: number;
  classIIIMilk: [number, number, number];
  classIVMilk: [number, number, number];
  actualMilkProduction: number;
  actualButterfatTest: number;
  actualProteinTest: number;
}

const initialState: DairyRevenueProtectionWhatIfInputsState = {
  butter: [0, 0, 0],
  cheese: [0, 0, 0],
  dryWhey: [0, 0, 0],
  noFatDryMilk: [0, 0, 0],
  milkProductionPerCow: 0,
  classIIIMilk: [0, 0, 0],
  classIVMilk: [0, 0, 0],
  actualMilkProduction: 1000000,
  actualButterfatTest: 3.9,
  actualProteinTest: 3.2
};

const createNewValue = (
  array: [number, number, number],
  index: 0 | 1 | 2,
  value: number
): [number, number, number] => {
  const newArray: [number, number, number] = [array[0], array[1], array[2]];
  newArray[index] = value;
  return newArray;
};

export const dairyRevenueProtectionWhatIfInputsReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => {
  switch (action.type) {
    case getType(dairyRevenueProtectionActions.whatIfInputs.butter):
      return { ...state, butter: createNewValue(state.butter, action.meta, action.payload) };
    case getType(dairyRevenueProtectionActions.whatIfInputs.cheese):
      return { ...state, cheese: createNewValue(state.cheese, action.meta, action.payload) };
    case getType(dairyRevenueProtectionActions.whatIfInputs.dryWhey):
      return { ...state, dryWhey: createNewValue(state.dryWhey, action.meta, action.payload) };
    case getType(dairyRevenueProtectionActions.whatIfInputs.noFatDryMilk):
      return {
        ...state,
        noFatDryMilk: createNewValue(state.noFatDryMilk, action.meta, action.payload)
      };
    case getType(dairyRevenueProtectionActions.whatIfInputs.classIIIMilk):
      return {
        ...state,
        classIIIMilk: createNewValue(state.classIIIMilk, action.meta, action.payload)
      };
    case getType(dairyRevenueProtectionActions.whatIfInputs.classIVMilk):
      return {
        ...state,
        classIVMilk: createNewValue(state.classIVMilk, action.meta, action.payload)
      };
    case getType(dairyRevenueProtectionActions.whatIfInputs.milkProductionPerCow):
      return { ...state, milkProductionPerCow: action.payload };
    case getType(dairyRevenueProtectionActions.setWhatIfClassIIIMilk):
      return { ...state, classIIIMilk: action.payload };
    case getType(dairyRevenueProtectionActions.setWhatIfClassIVMilk):
      return { ...state, classIVMilk: action.payload };
    case getType(dairyRevenueProtectionActions.setWhatIfInputs):
      return { ...state, ...action.payload };
    case getType(dairyRevenueProtectionActions.whatIfInputs.actualButterfatTest):
    case getType(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest):
      return { ...state, actualButterfatTest: action.payload };
    case getType(dairyRevenueProtectionActions.whatIfInputs.actualMilkProduction):
    case getType(dairyRevenueProtectionActions.settings.setDeclaredMilkProduction):
      return { ...state, actualMilkProduction: action.payload };
    case getType(dairyRevenueProtectionActions.whatIfInputs.actualProteinTest):
    case getType(dairyRevenueProtectionActions.settings.setDeclaredProteinTest):
      return { ...state, actualProteinTest: action.payload };
    default:
      return state;
  }
};
