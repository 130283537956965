import { AxiosResponse } from 'axios';

import { EndorsementSummary } from '../../../types/Endorsement/EndorsementSummary';
import { EndorsementSummaryDto } from '../../../types/dto/EndorsementSummaryDto';

export const mapEndorsementSummaryDtoToEndorsementSummary = ({
  data
}: AxiosResponse<EndorsementSummaryDto[]>): EndorsementSummary[] =>
  data.map(item => ({
    ...item,
    endorsement: item.endorsement
      ? { ...item.endorsement, lastUpdate: new Date(item.endorsement.lastUpdate) }
      : null
  }));
