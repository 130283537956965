import { Zodios, ZodiosOptions, makeApi } from '@zodios/core';
import { z } from 'zod';

const TagTagged = z.object({
  name: z.string(),
  title: z.string()
});

export const LrpEndorsement = z.object({
  actions: z.string().optional().nullable(),
  active: z.boolean().optional().nullable(),
  actualPrice: z.number().optional().nullable(),
  actualPriceCwt: z.number().optional().nullable(),
  actualPriceDate: z.date().optional().nullable(),
  actualPriceHead: z.number().optional().nullable(),
  agencyName: z.string().optional().nullable(),
  aipBound: z.boolean().optional().nullable(),
  aipIgnore: z.boolean().optional().nullable(),
  aipImported: z.boolean().optional().nullable(),
  aipLinked: z.boolean().optional().nullable(),
  aipSubmitted: z.boolean().optional().nullable(),
  aipSynced: z.boolean().optional().nullable(),
  bindingRequestId: z.number().optional().nullable(),
  brokerageNotes: z.string().optional().nullable(),
  calendarYear: z.number().optional().nullable(),
  changeInActualPrice: z.number().optional().nullable(),
  changeInActualPriceCwt: z.number().optional().nullable(),
  changeInActualPriceHead: z.number().optional().nullable(),
  cmePutComparisonStrikePrice: z.number().optional().nullable(),
  cmePutPremiumPrice: z.number().optional().nullable(),
  commodity: z.string().optional().nullable(),
  comparisonMonth: z.string().optional().nullable(),
  conservationCompliance: z.number().optional().nullable(),
  conservationCompliancePercent: z.number().optional().nullable(),
  conservationComplianceSubsidyReduction: z.number().optional().nullable(),
  conservationComplianceSubsidyReductionCwt: z.number().optional().nullable(),
  conservationComplianceSubsidyReductionHead: z.number().optional().nullable(),
  countyCode: z.string().optional().nullable(),
  coverageMinusFuturesPrice: z.number().optional().nullable(),
  coverageMinusFuturesPriceCwt: z.number().optional().nullable(),
  coveragePercent: z.number().optional().nullable(),
  coveragePercentageRank: z.number().optional().nullable(),
  coveragePrice: z.number().optional().nullable(),
  coveragePriceCwt: z.number().optional().nullable(),
  coveragePriceHead: z.number().optional().nullable(),
  createdOn: z.date().optional().nullable(),
  deleted: z.boolean().optional().nullable(),
  endDate: z.date().optional().nullable(),
  endMonth: z.number().optional().nullable(),
  endorsementLength: z.number().optional().nullable(),
  endorsementLengthCount: z.number().optional().nullable(),
  endYear: z.number().optional().nullable(),
  estimatedActualPrice: z.number().optional().nullable(),
  estimatedActualPriceCwt: z.number().optional().nullable(),
  estimatedActualPriceHead: z.number().optional().nullable(),
  expectedPrice: z.number().optional().nullable(),
  expectedPriceCwt: z.number().optional().nullable(),
  expectedPriceHead: z.number().optional().nullable(),
  finalUpdate: z.boolean().optional().nullable(),
  formattedUpdatedDate: z.string().optional().nullable(),
  forecastedIndemnity: z.number().optional().nullable(),
  forecastedIndemnityCwt: z.number().optional().nullable(),
  forecastedIndemnityHead: z.number().optional().nullable(),
  futuresPrice: z.number().optional().nullable(),
  futuresPriceCwt: z.number().optional().nullable(),
  grossIndemnity: z.number().optional().nullable(),
  grossIndemnityCwt: z.number().optional().nullable(),
  grossIndemnityHead: z.number().optional().nullable(),
  grossEstimatedIndemnity: z.number().optional().nullable(),
  grossEstimatedIndemnityCwt: z.number().optional().nullable(),
  id: z.number().optional().nullable(),
  indemnityEstimate: z.number().optional().nullable(),
  indemnityEstimateCwt: z.number().optional().nullable(),
  indemnityEstimateHead: z.number().optional().nullable(),
  insurancePlan: z.string().optional().nullable(),
  insuredSharePercent: z.number().optional().nullable(),
  insuredValue: z.number().optional().nullable(),
  isBeginner: z.boolean().optional().nullable(),
  liability: z.number().optional().nullable(),
  liabilityCwt: z.number().optional().nullable(),
  liabilityHead: z.number().optional().nullable(),
  lrpPremium: z.number().optional().nullable(),
  netEstimatedIndemnity: z.number().optional().nullable(),
  netEstimatedIndemnityCwt: z.number().optional().nullable(),
  netGuarantee: z.number().optional().nullable(),
  netGuaranteeCwt: z.number().optional().nullable(),
  netGuaranteeHead: z.number().optional().nullable(),

  numberOfHead: z.number().optional().nullable(),
  operationName: z.string().optional().nullable(),
  operationId: z.number().optional().nullable(),
  penIdentifier: z.string().optional().nullable(),
  practiceCode: z.string().optional().nullable(),
  premium: z.number().optional().nullable(),
  premiumCwt: z.number().optional().nullable(),
  premiumHead: z.number().optional().nullable(),
  premiumProducer: z.number().optional().nullable(),
  premiumProducerCwt: z.number().optional().nullable(),
  premiumProducerHead: z.number().optional().nullable(),
  producerPremium: z.number().optional().nullable(),
  producerPremiumCwt: z.number().optional().nullable(),
  producerPremiumHead: z.number().optional().nullable(),
  putBasisSelection: z.string().optional().nullable(),
  putBasisSelectionId: z.number().optional().nullable(),
  putMonthSelection: z.string().optional().nullable(),
  putMonthSelectionId: z.number().optional().nullable(),
  putStrikeSelection: z.string().optional().nullable(),
  putStrikeSelectionId: z.number().optional().nullable(),
  rank: z.number().optional().nullable(),
  reinsuranceYear: z.number().optional().nullable(),
  salesEffectiveDate: z.date().optional().nullable(),
  share: z.number().optional().nullable(),
  stateCode: z.string().optional().nullable(),
  status: z.string().optional().nullable(),
  statusId: z.number().optional().nullable(),
  subsidyAmount: z.number().optional().nullable(),
  subsidyAmountCwt: z.number().optional().nullable(),
  subsidyAmountHead: z.number().optional().nullable(),
  subsidyPercent: z.number().optional().nullable(),
  tags: z.array(TagTagged).optional().nullable(),
  targetWeight: z.number().optional().nullable(),
  targetWeightMax: z.number().optional().nullable(),
  targetWeightMin: z.number().optional().nullable(),
  totalPremium: z.number().optional().nullable(),
  totalPremiumCwt: z.number().optional().nullable(),
  totalPremiumHead: z.number().optional().nullable(),
  totalWeight: z.number().optional().nullable(),
  typeCode: z.string().optional().nullable(),
  updated: z.date().optional().nullable()
});
export type LrpEndorsementType = z.infer<typeof LrpEndorsement>;

const PriceOverviewItem = z.object({
  salesEffectiveDate: z.date().optional(),
  month: z.number().optional(),
  endDate: z.date().optional(),
  numberOfHead: z.number().optional(),
  targetWeight: z.number().optional(),
  expectedPrice: z.number().optional(),
  expectedPriceCwt: z.number().optional(),
  expectedPriceHead: z.number().optional(),
  producerPremium: z.number().optional(),
  producerPremiumCwt: z.number().optional(),
  producerPremiumHead: z.number().optional(),
  netGuarantee: z.number().optional(),
  netGuaranteeCwt: z.number().optional(),
  netGuaranteeHead: z.number().optional(),
  coveragePrice: z.number().optional(),
  coveragePriceCwt: z.number().optional(),
  coveragePriceHead: z.number().optional(),
  grossIndemnity: z.number().optional(),
  grossIndemnityCwt: z.number().optional().nullable(),
  grossIndemnityHead: z.number().optional()
});
export type PriceOverviewItemType = z.infer<typeof PriceOverviewItem>;

const PriceOverviewResponse = z.object({
  latest: PriceOverviewItem,
  previous: PriceOverviewItem,
  last7Days: PriceOverviewItem,
  last30Days: PriceOverviewItem
});
export type PriceOverviewResponseType = z.infer<typeof PriceOverviewResponse>;

const LrpAlertModelResponse = z.object({
  id: z.number(),
  operationName: z.string(),
  trigger: z.number(),
  triggerCwt: z.number(),
  triggerHead: z.number(),
  condition: z.string().optional().nullable(),
  created: z.date(),
  triggered: z.date().nullable().optional(),
  startDate: z.date(),
  endDate: z.date(),
  alertType: z.string(),
  commodity: z.number(),
  commodityType: z.number(),
  commodityName: z.string(),
  commodityTypeName: z.string(),
  coveragePercentageRank: z.number().optional().nullable(),
  targetWeight: z.number(),
  expired: z.boolean(),
  latest: PriceOverviewItem.optional().nullable()
});
export type LrpAlertModelResponseType = z.infer<typeof LrpAlertModelResponse>;

const DataPointItem = z.object({
  date: z.date(),
  cwt: z.number(),
  head: z.number(),
  total: z.number().optional().nullable()
});
export type DataPointItemType = z.infer<typeof DataPointItem>;

const LrpHistoricalAnalysisResponse = z.object({
  actual: z.any().optional().nullable(),
  latest: z.any().optional().nullable(),
  previous: z.any().optional().nullable(),
  days7: z.any().optional().nullable(),
  days30: z.any().optional().nullable(),
  overall: z.any().optional().nullable(),
  dataPoints: z.array(DataPointItem).optional().nullable(),
  historicalOverview: z.any().optional().nullable()
});
export type LrpHistoricalAnalysisResponseType = z.infer<typeof LrpHistoricalAnalysisResponse>;

const LrpAlertModel = z.object({
  id: z.number().optional().nullable(),
  commodity: z.string(),
  commodityType: z.string(),
  endDateFrom: z.date(),
  endDateTo: z.date(),
  alertType: z.string(),
  triggerPriceCwt: z.number(),
  triggerPricePerHead: z.number(),
  triggered: z.date().nullable().optional(),
  targetWeight: z.number(),
  condition: z.string().nullable().optional(),
  active: z.boolean(),
  coveragePercentageRank: z.number().optional().nullable(),
  status: z.boolean().nullable().optional(),
  latest: z
    .object({
      endDate: z.date().optional().nullable(),
      date: z.date().optional().nullable(),
      priceCwt: z.number().optional().nullable(),
      priceHead: z.number().optional().nullable()
    })
    .optional()
    .nullable(),
  created: z.date()
});
export type LrpAlertModelType = z.infer<typeof LrpAlertModel>;

const AlertUpdateCreateModel = z.object({
  triggerCwt: z.number().optional().nullable(),
  triggerHead: z.number().optional().nullable(),
  condition: z.string().optional().nullable(),
  startDate: z.date().optional().nullable(),
  endDate: z.date().optional().nullable(),
  alertType: z.string().optional().nullable(),
  commodity: z.string().optional().nullable(),
  commodityType: z.string().optional().nullable(),
  coveragePercentageRank: z.number().optional().nullable(),
  targetWeight: z.number().optional().nullable()
});
export type AlertUpdateCreateModelType = z.infer<typeof AlertUpdateCreateModel>;

const endpoints = makeApi([
  {
    method: 'get',
    path: '/lrp/:operationName/endorsement',
    alias: 'getEndorsements',
    parameters: [
      {
        name: 'ReinsuranceYearTo',
        type: 'Query',
        schema: z.number().optional()
      },

      {
        name: 'TagNames',
        type: 'Query',
        schema: z.array(z.string()).optional()
      },
      {
        name: 'Commodity',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'TypeCode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ReinsuranceYearFrom',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'SalesEffectiveDateFrom',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'SalesEffectiveDateTo',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'EndDateFrom',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'CalendarYearFrom',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'CalendarYearTo',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'operationName',
        type: 'Path',
        schema: z.string()
      },

      {
        name: 'EndDateTo',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'TopCondition',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'GroupByEndMonth',
        type: 'Query',
        schema: z.boolean().optional()
      },
      {
        name: 'actualPriceDate',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(LrpEndorsement)
  },
  {
    method: 'get',
    path: '/lrp/:operationName/endorsement/request/:referenceIdentifier',
    alias: 'getEndorsementRequestByRefId',
    parameters: [
      {
        name: 'operationName',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'referenceIdentifier',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'tagTypes',
        type: 'Query',
        schema: z.array(z.string()).optional()
      }
    ],
    response: z.array(LrpEndorsement)
  },
  {
    method: 'get',
    path: '/lrp/:operationName/endorsement/summary',
    alias: 'getEndorsementSummaries',
    parameters: [
      {
        name: 'operationName',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'Commodity',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'TypeCode',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'ReinsuranceYearFrom',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'ReinsuranceYearTo',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'CalendarYearFrom',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'CalendarYearTo',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'SalesEffectiveDateFrom',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'SalesEffectiveDateTo',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'EndDateFrom',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'EndDateTo',
        type: 'Query',
        schema: z.date().optional()
      },
      {
        name: 'TopCondition',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'GroupByEndMonth',
        type: 'Query',
        schema: z.boolean().optional()
      },
      {
        name: 'TagNames',
        type: 'Query',
        schema: z.array(z.string()).optional()
      },
      {
        name: 'actualPriceDate',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: z.array(LrpEndorsement)
  },

  {
    method: 'get',
    path: '/alerts/lrp/:operationName/alert',
    alias: 'getAlerts',
    parameters: [
      {
        name: 'operationName',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'expired',
        type: 'Query',
        schema: z.boolean().optional()
      }
    ],
    response: z.array(LrpAlertModelResponse)
  },
  {
    method: 'get',
    path: '/alerts/lrp/:id',
    alias: 'getAlertById',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number()
      }
    ],
    response: LrpAlertModelResponse
  },

  {
    method: 'post',
    path: '/alerts/lrp/:operationName',
    alias: 'createAlert',
    parameters: [
      {
        name: 'operationName',
        type: 'Path',
        schema: z.string()
      },
      {
        name: 'body',
        type: 'Body',
        schema: AlertUpdateCreateModel
      }
    ],
    response: z.void()
  },
  {
    method: 'delete',
    path: '/alerts/lrp/:id',
    alias: 'deleteAlert',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().nullable()
      }
    ],
    response: z.void()
  },
  {
    method: 'put',
    path: '/alerts/lrp/:id',
    alias: 'updateAlert',
    parameters: [
      {
        name: 'id',
        type: 'Path',
        schema: z.number().optional().nullable()
      },
      {
        name: 'body',
        type: 'Body',
        schema: AlertUpdateCreateModel
      }
    ],
    response: z.void()
  },
  {
    method: 'get',
    path: '/alerts/lrp/price/overview',
    alias: 'getPriceOverview',
    parameters: [
      {
        name: 'Date',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'EndDate',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'StartEndDate',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'CommodityName',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'CommodityTypeName',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'NumberOfHead',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'TargetWeight',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'CoveragePercentageRank',
        type: 'Query',
        schema: z.number().optional().nullable()
      }
    ],
    response: PriceOverviewResponse
  },
  {
    method: 'get',
    path: 'lrp/analysis/historic/:measure',
    alias: 'getHistoricalAnalysis',
    parameters: [
      {
        name: 'measure',
        type: 'Path',
        schema: z.string()
      },

      {
        name: 'YearFrom',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'Commodity',
        type: 'Query',
        schema: z.string().optional()
      },

      {
        name: 'TargetWeight',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'Deductible',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'NumberOfHead',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'CommodityType',
        type: 'Query',
        schema: z.string().optional()
      },
      {
        name: 'YearTo',
        type: 'Query',
        schema: z.number().optional()
      },
      {
        name: 'Date',
        type: 'Query',
        schema: z.string().optional()
      }
    ],
    response: LrpHistoricalAnalysisResponse
  }
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
