import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import OperationContact from '../../types/Operation/OperationContact';
import createUniqueArray from '../../utils/createUniqueArray';
import { OperationContactActions, operationContactActions } from './actions';

interface OperationContactState {
  allOperations: string[];
  operations: {
    [name: string]: {
      allAgents: string[];
      agents: {
        [agentName: string]: OperationContact;
      };
    };
  };
  agencyOperationsContacts: OperationContact[];
}

const initialState: OperationContactState = {
  allOperations: [],
  operations: {},
  agencyOperationsContacts: []
};

export const operationContactReducer = (state = initialState, action: OperationContactActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(operationContactActions.addAllOperationContact): {
        const operations = new Set<string>();
        action.payload.forEach(data => {
          operations.add(data.operationName);
          if (!draft.operations[data.operationName]) {
            draft.operations[data.operationName] = { allAgents: [], agents: {} };
          }
          draft.operations[data.operationName].allAgents.push(data.userId);
          draft.operations[data.operationName].agents[data.userId] = data;
        });
        operations.forEach(name => {
          draft.operations[name].allAgents = createUniqueArray(draft.operations[name].allAgents);
        });
        return;
      }
      case getType(operationContactActions.addAllAgencyOperationContact): {
        draft.agencyOperationsContacts = action.payload;
        return;
      }
      case getType(operationContactActions.addOperationContact): {
        if (!draft.operations[action.payload.operationName]) {
          draft.operations[action.payload.operationName] = { allAgents: [], agents: {} };
        }
        draft.operations[action.payload.operationName].allAgents = createUniqueArray([
          ...draft.operations[action.payload.operationName].allAgents,
          action.payload.userId
        ]);
        draft.operations[action.payload.operationName].agents[action.payload.userId] =
          action.payload;
        return;
      }
      case getType(operationContactActions.removeAgentContact):
        if (draft.operations[action.meta]) {
          draft.operations[action.meta].allAgents = draft.operations[action.meta].allAgents.filter(
            name => name !== action.payload
          );
          delete draft.operations[action.meta].agents[action.payload];
        }
    }
  });
