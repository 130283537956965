import { createSelector } from 'reselect';

import { formatDateYearMonth } from '../../../utils/dateFormatter';
import { utcDateToLocalDate } from '../../../utils/utcDateToLocalDate';
import { RootState } from '../../store';
import { getLgmRowData } from '../quoter/selectors';

export const getLgmWhatIfActualPrices = (state: RootState) => state.lgm.whatIf.actualPrices;

export const getLgmWhatIfPremiumPrices = (state: RootState) => state.lgm.whatIf.premiumPrices;

export const getLgmWhatIfRowData = createSelector(
  getLgmWhatIfActualPrices,
  getLgmRowData,
  (actualPrices, quoterPrices) => {
    return actualPrices.map((actualPrice, index) => {
      const actualUTCFormattedDate = utcDateToLocalDate(actualPrice.date);
      const actualPriceDate = formatDateYearMonth(actualUTCFormattedDate);
      const quoterPriceIndex = quoterPrices.findIndex(
        quoterPrice => formatDateYearMonth(quoterPrice.date) === actualPriceDate
      );
      return {
        id: index,
        date: actualPriceDate,
        actualPrices: {
          milk: actualPrice.actualMilkPrice,
          corn: actualPrice.actualCornPrice,
          sbm: actualPrice.actualSoybeanPrice
        },
        expectedPrices: {
          milk: quoterPriceIndex !== -1 ? quoterPrices[quoterPriceIndex].prices.milk : 0,
          corn: quoterPriceIndex !== -1 ? quoterPrices[quoterPriceIndex].prices.corn : 0,
          sbm: quoterPriceIndex !== -1 ? quoterPrices[quoterPriceIndex].prices.sbm : 0
        }
      };
    });
  }
);
