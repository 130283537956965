export const monthSelectData = [
  { id: 0, name: 'January' },
  { id: 1, name: 'February' },
  { id: 2, name: 'March' },
  { id: 3, name: 'April' },
  { id: 4, name: 'May' },
  { id: 5, name: 'June' },
  { id: 6, name: 'July' },
  { id: 7, name: 'August' },
  { id: 8, name: 'September' },
  { id: 9, name: 'October' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' }
] as const;

export type MonthsEnum = (typeof monthSelectData)[number]['name'];
export type MonthsIndexEnum = (typeof monthSelectData)[number]['id'];

type DateKeyType = `${MonthsEnum}-${number}`;
type DateKeyIndexType = `${MonthsIndexEnum}-${number}`;

export const getDateKey = (date: Date | null | undefined): DateKeyType => {
  if (!date) return 'January-2022';
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${monthSelectData[month].name}-${year}`;
};

export const getDateIndexKey = (date: Date | null | undefined): DateKeyIndexType => {
  if (!date) return '0-2022';
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${monthSelectData[month].id}-${year}`;
};

export const monthSelectDataString = [
  { id: '01', name: 'January' },
  { id: '02', name: 'February' },
  { id: '03', name: 'March' },
  { id: '04', name: 'April' },
  { id: '05', name: 'May' },
  { id: '06', name: 'June' },
  { id: '07', name: 'July' },
  { id: '08', name: 'August' },
  { id: '09', name: 'September' },
  { id: '10', name: 'October' },
  { id: '11', name: 'November' },
  { id: '12', name: 'December' }
];
