import { Dispatch, SetStateAction } from 'react';
import { ActionType, createAction } from 'typesafe-actions';

import AllQuarters from '../../types/AllQuarters';
import { HistoricalAnalysis } from '../../types/HistoricalAnalysis';
import { Practice } from '../../types/Practice';
import { PriceReport } from '../../types/PriceReport';
import { Indemnity } from '../../types/dto/WhatIfDto';
import { DairyRevenueProtectionPriceComponentsState } from './priceComponents/reducer';
import {
  ClassPriceWeightingFactorRestrictedValue,
  ComponentPriceWeightingFactorRestrictedValue
} from './settings/reducer';
import { DairyRevenueProtectionWhatIfComponentsState } from './whatIfDairyComponents/reducer';
import { DairyRevenueProtectionWhatIfValuesState } from './whatIfDairyValues/reducer';
import { DairyRevenueProtectionWhatIfExpectedActualState } from './whatIfExpectedActual/reducer';
import { DairyRevenueProtectionWhatIfInputsState } from './whatIfInputs/reducer';

export const dairyRevenueProtectionActions = {
  start: createAction('@drp_init')<{ operationName: string; stateCode: string }, () => void>(),
  startSave: createAction('@drp_init/SAVE')<Practice>(),
  startFetch: createAction('@drp_init/FETCH')(),
  fetchQuoter: createAction('@drp_quoter/FETCH')(),
  settings: {
    setState: createAction('@drp_settings/SET_STATE')<number>(),
    setShowBasis: createAction('@drp_settings/SET_SHOW_BASIS')<boolean>(),
    setShowIndemnity: createAction('@drp_settings/SET_SHOW_INDEMNITY')<boolean>(),
    setShowSubsidy: createAction('@drp_settings/SET_SHOW_SUBSIDY')<boolean>(),
    setShowAllQuarters: createAction('@drp_settings/SET_SHOW_ALL_QUARTERS')<boolean>(),
    setShowProtectionFactorAnalysis: createAction(
      '@drp_settings/SET_SHOW_PROTECTION_FACTOR_ANALYSIS'
    )<boolean>(),
    setQuarter: createAction('@drp_settings/SET_QUARTER')<number>(),
    setCanSell: createAction('@drp_settings/SET_CAN_SELL')<boolean, string | null>(),
    setDeclaredMilkProduction: createAction('@drp_settings/SET_DMP')<number>(),
    setCalendarDate: createAction('@drp_settings/SET_CALENDAR_DATE')<Date>(),
    setCoverageLevel: createAction('@drp_settings/SET_COVERAGE')<number>(),
    setProtectionFactor: createAction('@drp_settings/SET_PROTECTION')<number>(),
    setDeclaredButterfatTest: createAction('@drp_settings/SET_D_BUTTERFAT')<number>(),
    setDeclaredProteinTest: createAction('@drp_settings/SET_D_PROTEIN')<number>(),
    setDeclaredClassPriceWeightingFactor: createAction('@drp_settings/SET_DCPWF')<number>(),
    setDeclaredComponentPriceWeightingFactor: createAction('@drp_settings/SET_DComPWF')<number>(),
    updatePractice: createAction('@drp_what_if_inputs/UPDATE_PRACTICE')<Practice>(),
    updatePracticeQuarter: createAction('@drp_what_if_inputs/UPDATE_PRACTICE_QUARTER')<number>(),
    setShare: createAction('@drp_settings/SET_SHARE')<number>(),
    setQuarterDeviation: createAction('@drp_settings/SET_QUARTER_DEVIATION')<{
      practiceCode: number;
      deviation: Record<'class' | 'component', boolean>;
    }>(),
    updateAll: createAction('@drp_what_if_inputs/UPDATE_ALL')<{
      state: number;
      quarter: number;
      declaredMilkProduction: number;
      salesEffectiveDate: Date;
      coverageLevel: number;
      declaredButterfatTest: number;
      protectionFactor: number;
      declaredProteinTest: number;
      declaredClassPriceWeightingFactor: number;
      quarterList: Array<{
        quarter: string;
        practiceCode: number;
        proteinTest: number;
        butterfatTest: number;
        componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
        classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
      }>;
    }>(),
    updateIsSalesDateAvailable: createAction(
      '@drp_what_if_inputs/UPDATE_IS_SALES_DATE_AVAILABLE'
    )<boolean>()
  },
  whatIfInputs: {
    butter: createAction('@drp_what_if_inputs/SET_BUTTER')<number, 0 | 1 | 2>(),
    cheese: createAction('@drp_what_if_inputs/SET_CHEESE')<number, 0 | 1 | 2>(),
    dryWhey: createAction('@drp_what_if_inputs/SET_DRY_WHEY')<number, 0 | 1 | 2>(),
    noFatDryMilk: createAction('@drp_what_if_inputs/SET_NO_FAT_DRY_MILK')<number, 0 | 1 | 2>(),
    classIIIMilk: createAction('@drp_what_if_inputs/SET_CLASS_III_MILK')<number, 0 | 1 | 2>(),
    classIVMilk: createAction('@drp_what_if_inputs/SET_CLASS_IV_MILK')<number, 0 | 1 | 2>(),
    milkProductionPerCow: createAction('@drp_what_if_inputs/SET_MILK_PRODUCTION_PER_COW')<number>(),
    actualMilkProduction: createAction('@drp_what_if_inputs/SET_ACTUAL_MILK_PRODUCTION')<number>(),
    actualButterfatTest: createAction('@drp_what_if_inputs/SET_ACTUAL_BUTTERFAT_TEST')<number>(),
    actualProteinTest: createAction('@drp_what_if_inputs/SET_ACTUAL_PROTEIN_TEST')<number>()
  },
  setWhatIfValues: createAction(
    '@dairy_revenue_protection/SET_WHAT_IF_VALUES'
  )<DairyRevenueProtectionWhatIfValuesState>(),
  setWhatIfInputs: createAction(
    '@dairy_revenue_protection/SET_WHAT_IF_INPUTS'
  )<DairyRevenueProtectionWhatIfInputsState>(),
  setWhatIfExpectedActual: createAction(
    '@dairy_revenue_protection/SET_WHAT_IF_EXPECTED_ACTUAL'
  )<DairyRevenueProtectionWhatIfExpectedActualState>(),
  setWhatIfComponents: createAction(
    '@dairy_revenue_protection/SET_WHAT_IF_COMPONENTS'
  )<DairyRevenueProtectionWhatIfComponentsState>(),
  dairyComponents: {
    all: createAction(
      '@dairy_revenue_protection/SET_DAIRY_COMPONENTS'
    )<DairyRevenueProtectionPriceComponentsState>(),
    setBasisComponent: createAction('@dairy_revenue_protection/SET_BASIS_COMPONENT')<
      [number, number]
    >(),
    setBasisClass: createAction('@dairy_revenue_protection/SET_BASIS_CLASS')<[number, number]>(),
    setBasisCwtComponent: createAction('@dairy_revenue_protection/SET_BASIS_CWT_COMPONENT')<
      [number, number]
    >(),
    setBasisCwtClass: createAction('@dairy_revenue_protection/SET_BASIS_CWT_CLASS')<
      [number, number]
    >()
  },
  setWhatIfClassIIIMilk: createAction('@dairy_revenue_protection/SET_WHAT_IF_CLASS_III_MILK')<
    [number, number, number]
  >(),
  setWhatIfClassIVMilk: createAction('@dairy_revenue_protection/SET_WHAT_IF_CLASS_IV_MILK')<
    [number, number, number]
  >(),
  setRawWhatIf: createAction('@dairy_revenue_protection/SET_RAW_WHAT_IF')<any>(),
  setIndemnity: createAction('@dairy_revenue_protection/SET_INDEMNITY')<Indemnity>(),
  setActualInputs: {
    butterfat: createAction('@drp_actual_inputs/SET_BUTTERFAT')<number>(),
    protein: createAction('@drp_actual_inputs/SET_PROTEIN')<number>(),
    otherSolids: createAction('@drp_actual_inputs/SET_OTHER_SOLIDS')<number>(),
    nonfatSolids: createAction('@drp_actual_inputs/SET_NONFAT_SOLIDS')<number>(),
    classIII: createAction('@drp_actual_inputs/SET_CLASS_III')<number>(),
    classIV: createAction('@drp_actual_inputs/SET_CLASS_IV')<number>()
  },
  resetActual: createAction('@drp_inputs/RESET_ACTUAL')(),
  historical: {
    setFrom: createAction('@drp_historical/SET_FROM')<number>(),
    setTo: createAction('@drp_historical/SET_TO')<number>(),
    setRecords: createAction('@drp_historical/SET_RECORDS')<HistoricalAnalysis>(),
    setIsClassChartSelected: createAction('@drp_historical/SET_IS_CLASS_CHART_SELECTED')<boolean>(),
    setIsDataAvailable: createAction('@drp_historical/SET_IS_DATA_AVAILABLE')<boolean>()
  },
  allQuarters: {
    getAllQuarters: createAction('@drp_all_quarters/GET')(),
    updateAllQuarters: createAction('@drp_all_quarters/UPDATE')(),
    saveAllQuarters: createAction('@drp_all_quarters/SAVE')<AllQuarters[]>(),
    saveIndividualQuarter: createAction('@drp_all_quarters/SAVE_INDIVIDUAL')<AllQuarters[]>(),
    setBasisComponent: createAction('@drp_all_quarters/SET_BASIS_COMPONENT')<
      [number, number],
      { index: number; practiceCode: number }
    >(),
    setBasisClass: createAction('@drp_all_quarters/SET_BASIS_CLASS')<
      [number, number],
      { index: number; practiceCode: number }
    >(),
    setBasisCwtComponent: createAction('@drp_all_quarters/SET_BASIS_CWT_COMPONENT')<
      [number, number],
      { index: number; practiceCode: number }
    >(),
    setBasisCwtClass: createAction('@drp_all_quarters/SET_BASIS_CWT_CLASS')<
      [number, number],
      { index: number; practiceCode: number }
    >()
  },
  initPractice: createAction('@drp_settings/INIT_PRACTICE')(),
  priceReports: {
    get: createAction('@price_reports/GET')<Date, Dispatch<SetStateAction<boolean>>>(),
    update: createAction('@price_reports/UPDATE')<PriceReport>()
  }
};

export type DairyRevenueProtectionActions = ActionType<typeof dairyRevenueProtectionActions>;
