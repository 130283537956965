import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import OperationContact from '../../types/Operation/OperationContact';
import { OperationFiltersActions, operationFiltersActions } from './actions';

interface InitialState {
  sort: string;
  isDemo: boolean;
  filteredAgents: OperationContact[];
  filteredState: { stateCode: string; stateName: string }[];
  filteredInsurance: string[];
  filteredAip: string[];
}

const initialState: InitialState = {
  sort: 'All',
  isDemo: true,
  filteredAgents: [],
  filteredState: [],
  filteredInsurance: [],
  filteredAip: []
};

export const operationFiltersReducer = (state = initialState, action: OperationFiltersActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(operationFiltersActions.changeSort): {
        draft.sort = action.payload;
        return;
      }
      case getType(operationFiltersActions.markAsDemo): {
        draft.isDemo = action.payload;
        return;
      }
      case getType(operationFiltersActions.clearAgentFilter): {
        draft.filteredAgents = [];
        return;
      }
      case getType(operationFiltersActions.addAgentFilter): {
        draft.filteredAgents.push(action.payload);
        return;
      }
      case getType(operationFiltersActions.removeAgentFilter): {
        const index = state.filteredAgents.findIndex(
          agent => agent.userId === action.payload.userId
        );
        draft.filteredAgents.splice(index, 1);
        return;
      }
      case getType(operationFiltersActions.clearStateFilter): {
        draft.filteredState = [];
        return;
      }
      case getType(operationFiltersActions.addStateFilter): {
        draft.filteredState.push(action.payload);
        return;
      }
      case getType(operationFiltersActions.removeStateFilter): {
        const index = state.filteredState.findIndex(
          state => state.stateName === action.payload.stateName
        );
        draft.filteredState.splice(index, 1);
        return;
      }
      case getType(operationFiltersActions.clearInsuranceFilter): {
        draft.filteredInsurance = [];
        return;
      }
      case getType(operationFiltersActions.addInsuranceFilter): {
        draft.filteredInsurance.push(action.payload);
        return;
      }
      case getType(operationFiltersActions.removeInsuranceFilter): {
        const index = state.filteredInsurance.findIndex(state => state === action.payload);
        draft.filteredInsurance.splice(index, 1);
        return;
      }
      case getType(operationFiltersActions.clearAipFilter): {
        draft.filteredAip = [];
        return;
      }
      case getType(operationFiltersActions.addAipFilter): {
        draft.filteredAip.push(action.payload);
        return;
      }
      case getType(operationFiltersActions.removeAipFilter): {
        const index = state.filteredAip.findIndex(state => state === action.payload);
        draft.filteredAip.splice(index, 1);
        return;
      }
    }
  });
