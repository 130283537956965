import * as React from 'react';
import { FaCheckSquare, FaRegSquare } from 'react-icons/fa';

interface Props extends React.SVGAttributes<SVGElement> {
  selected?: boolean;
  size?: string | number;
  color?: string;
}

const CheckboxIcon: React.FC<Props> = ({ selected = false, ...props }) =>
  selected ? <FaCheckSquare {...props} /> : <FaRegSquare {...props} />;

export default CheckboxIcon;
