import { AxiosResponse } from 'axios';
import { addMonths } from 'date-fns';

import { createApiInstance } from '../ducks/authentication/sagas';
import LgmEstimatedDto from '../types/Lgm/LgmEstimatedDto';
import { LgmEstimatedPayload } from '../types/Lgm/LgmEstimatedPayload';
import { LgmRow } from '../types/Lgm/LgmRow';
import { LgmWhatIfPayload } from '../types/Lgm/LgmWhatIfPayload';
import { LgmPremiumDto } from '../types/dto/LgmPremiumDto';
import { LgmRowDto } from '../types/dto/LgmRowDto';

export const api = createApiInstance({
  baseURL: `${import.meta.env.VITE_APP_INSURANCE_URL}`,
  timeout: 20000
});

const milk = [16.91, 17.39, 17.96, 18.42, 18.63, 18.68, 18.58, 18.38, 18.22, 18.12, 17.26, 17.57];
const corn = [7.22, 7.23, 7.23, 7.19, 7.15, 6.62, 6.09, 6.02, 5.94, 5.87, 6.92, 6.89];
const sbm = [
  415.87, 413.65, 411.43, 409.07, 406.7, 396.83, 381.17, 363.87, 363.5, 363.13, 432.14, 431.67
];

const lgmApi = {
  getSales: (year: number, month: number): LgmRowDto[] =>
    new Array(10).fill(undefined).map((_, index) => ({
      date: addMonths(new Date(year, month + 1), index),
      expectedPrice: milk[index] || 0,
      corn: {
        min: 3.66,
        default: 14,
        high: 38,
        expectedPrice: corn[index] || 0
      },
      sbm: {
        min: 0.807,
        default: 2,
        high: 12,
        expectedPrice: sbm[index] || 0
      }
    })),

  getWhatIfBySalesEffectiveDate: (salesEffectiveDate: Date): LgmRow[] => {
    const year = salesEffectiveDate.getFullYear();
    const month = salesEffectiveDate.getMonth();
    return new Array(10).fill(undefined).map((_, index) => ({
      id: index,
      date: addMonths(new Date(year, month + 2), index),
      prices: {
        milk: milk[index] || 0,
        corn: corn[index] || 0,
        sbm: sbm[index] || 0
      },
      quantity: {
        milk: milk[index] || 0,
        corn: corn[index] || 0,
        sbm: sbm[index] || 0
      }
    }));
  },

  getLgmEstimated: async (
    lgmEstimatedPayload: LgmEstimatedPayload
  ): Promise<AxiosResponse<LgmEstimatedDto>> => {
    return await api.post('lgm/dairy/estimated', lgmEstimatedPayload);
  },

  getLgmPremium: async (
    lgmEstimatedPayload: LgmEstimatedPayload
  ): Promise<AxiosResponse<LgmPremiumDto>> => {
    return await api.post('lgm/dairy/premium', lgmEstimatedPayload);
  },

  getLgmWhatIfActual: async (salesDate: string) =>
    await api.get(`lgm/dairy/whatif/actual?salesDate=${salesDate}`),

  getLgmWhatIfPremium: async (lgmWhatIfPayload: LgmWhatIfPayload) =>
    await api.post('lgm/dairy/whatif/premium', lgmWhatIfPayload),

  getLgmEstimatedPriceAvailability: async (dateString: string) => 
    await api.get(`udm/lgm/estimated-prices/check?salesDate=${dateString}`)
};

export default lgmApi;
