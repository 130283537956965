import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import SortDirection from '../../../enums/SortDirection';
import insuranceAPI from '../../../services/insuranceService';
import { Agency } from '../../../types/Agency';
import sortObjectByKey from '../../../utils/sortObjectByKey';
import { adminPageActions } from '../../adminPage/actions';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { agencyActions } from '../../agency/actions';
import { getAgency } from '../../agency/selectors';
import { agencyLogoActions } from '../../agencyLogo/actions';
import { AuthState } from '../../authentication/reducer';
import { getAuthState } from '../../authentication/selectors';
import { loadersActions } from '../../loaders/actions';
import { adminActions } from '../actions';
import { getInsuranceAgencies } from '../selectors';

export function* getAllInsuranceAgencies() {
  try {
    yield put(loadersActions.adminAgencies.activate());
    const authState: AuthState = yield select(getAuthState);
    const agencies: AxiosResponse<Agency[]> = yield call(insuranceAPI.getAgencies);
    if (agencies.data.length > 0) {
      let cachedAgency: Agency | null = null;
      if (authState === AuthState.globalAdministrator) {
        cachedAgency = JSON.parse(yield call([localStorage, 'getItem'], 'agency'));
      }
      if (!cachedAgency) {
        yield put(adminPageActions.setAgency(agencies.data[0]));
        yield put(agencyActions.setAgency(agencies.data[0]));
      } else if (authState === AuthState.globalAdministrator) {
        yield put(adminPageActions.setAgency(cachedAgency));
        const agency = agencies.data.find(e => e.name === cachedAgency?.name);
        if (agency) {
          yield put(agencyActions.setAgency(agency));
        }
      }
    }
    yield put(adminActions.insuranceAgencies.addAllInsuranceAgencies(agencies.data));
    yield put(loadersActions.adminAgencies.disable());

    yield put(
      adminActions.insuranceAgencies.sort({ sort: 'fullName', direction: SortDirection.ASCENDING })
    );
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* addNewInsuranceAgency(
  action: ActionType<typeof adminActions.insuranceAgencies.addInsuranceAgency>
) {
  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.createAgency, action.payload);
    yield put(adminActions.insuranceAgencies.newInsuranceAgency(action.payload));
    yield put(loadersActions.spinner.disable());
    yield action.meta();
    yield toast.success('Insurance Agency Created');
  } catch (error: any) {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* removeInsuranceAgency(
  action: ActionType<typeof adminActions.insuranceAgencies.removeInsuranceAgency>
) {
  yield put(loadersActions.spinner.activate());
  const agency: Agency = yield select(getAdminPageAgency);
  try {
    yield call(insuranceAPI.deleteAgency, action.payload);
    if (agency.name === action.payload) {
      yield put(adminPageActions.setAgency(null));
    }
    yield put(adminActions.insuranceAgencies.deleteInsuranceAgency(action.payload));
    yield toast.success('Insurance Agency Deleted');
  } catch (error: any) {
    toast.error(error.response.data, {
      position: 'top-right'
    });
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta();
  }
}

export function* editInsuranceAgency(
  action: ActionType<typeof adminActions.insuranceAgencies.editInsuranceAgency>
) {
  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.editAgency, action.meta.name, action.payload);
    yield put(
      adminActions.insuranceAgencies.updateInsuranceAgency(action.payload, action.meta.name)
    );

    const agency: Agency = yield select(getAgency);
    if (agency.name === action.payload.name) {
      yield put(agencyActions.setAgency(action.payload));
    }
    yield put(loadersActions.spinner.disable());
    yield action.meta.cb();
    yield toast.success('Agency successfully edited.');
  } catch (error: any) {
    yield put(loadersActions.spinner.disable());
    action.meta.cb();
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* setAgencyToLocalStorageAndFetchLogo(
  action: ActionType<typeof adminPageActions.setAgency>
) {
  try {
    const authState: AuthState = yield select(getAuthState);
    if (action.payload) {
      yield call([localStorage, 'setItem'], 'agency', JSON.stringify(action.payload));
      if (authState === AuthState.globalAdministrator) {
        yield put(agencyLogoActions.getAdminAgencyLogo(action.payload.name));
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function* sortAgencies({
  payload: { sort, direction }
}: ActionType<typeof adminActions.insuranceAgencies.sort>) {
  const agencies: Agency[] = yield select(getInsuranceAgencies);
  const sortedAgencies = [...agencies].sort(sortObjectByKey(sort as keyof Agency, direction));

  yield put(adminActions.insuranceAgencies.addAllInsuranceAgencies(sortedAgencies));
}

export function* editAgencyInsuranceProgram(
  action: ActionType<typeof adminActions.insuranceAgencies.editAgencyInsuranceProgram>
) {
  try {
    const selectedAgency: Agency = yield select(getAgency);
    const agency: Agency = action.payload;
    if (selectedAgency.fullName === agency.fullName) {
      yield put(agencyActions.setAgency(agency));
    }
    yield call(insuranceAPI.editAgency, action.meta.name, action.payload);
    yield action.meta.cb();
    yield put(
      adminActions.insuranceAgencies.updateInsuranceAgency(action.payload, action.meta.name)
    );
    if (
      agency &&
      agency.insurancePrograms.includes('LGM') !== action.payload.insurancePrograms.includes('LGM')
    ) {
      yield toast.success(
        `Livestock Gross Margin - Dairy ${
          action.payload.insurancePrograms.includes('LGM') ? 'enabled' : 'disabled'
        } for ${agency.fullName}.`
      );
    }
    if (
      agency &&
      agency.insurancePrograms.includes('DRP') !== action.payload.insurancePrograms.includes('DRP')
    ) {
      yield toast.success(
        `Dairy Revenue Protection ${
          action.payload.insurancePrograms.includes('DRP') ? 'enabled' : 'disabled'
        } for ${agency.fullName}.`
      );
    }
    if (
      agency &&
      agency.insurancePrograms.includes('LRP') !== action.payload.insurancePrograms.includes('LRP')
    ) {
      yield toast.success(
        `Livestock Risk Protection ${
          action.payload.insurancePrograms.includes('LRP') ? 'enabled' : 'disabled'
        } for ${agency.fullName}.`
      );
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* insuranceAgenciesSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.insuranceAgencies.getAllInsuranceAgencies),
      getAllInsuranceAgencies
    ),
    takeLatest(getType(adminActions.insuranceAgencies.addInsuranceAgency), addNewInsuranceAgency),
    takeLatest(
      getType(adminActions.insuranceAgencies.removeInsuranceAgency),
      removeInsuranceAgency
    ),
    takeLatest(getType(adminActions.insuranceAgencies.editInsuranceAgency), editInsuranceAgency),
    takeLatest(
      getType(adminActions.insuranceAgencies.editAgencyInsuranceProgram),
      editAgencyInsuranceProgram
    ),
    takeLatest(getType(adminActions.insuranceAgencies.sort), sortAgencies),
    takeLatest(getType(adminPageActions.setAgency), setAgencyToLocalStorageAndFetchLogo)
  ]);
}
