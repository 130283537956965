import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import County from '../../types/County/County';
import State from '../../types/State/State';
import { AreasActions, areasActions } from './actions';

export interface AreasState {
  states: State[];
  counties: { [key: string]: County[] };
}

const initialState: AreasState = {
  states: [],
  counties: {}
};

export const areasReducer = (state = initialState, action: AreasActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(areasActions.setStates): {
        draft.states = action.payload;
        return;
      }
      case getType(areasActions.setCounties): {
        draft.counties = action.payload;
        return;
      }
    }
  });
