import { AxiosResponse } from 'axios';
import { addQuarters, compareAsc, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, debounce, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import insuranceAPI from '../../services/insuranceService';
import AlertDto from '../../types/dto/AlertDto';
import {
  HistoricalAnalysisExpectedDto,
  HistoricalAnalysisPriceFloorDto
} from '../../types/dto/HistoricalAnalysisExpectedDto';
import alertProducts from '../../utils/alertProducts';
import sortObjectByKey from '../../utils/sortObjectByKey';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { alertActions } from './actions';
import { getAlerts as getSelectorAlerts } from './selectors';

function* getAlerts(action: any) {
  try {
    const operationName: string = yield select(getOperationName);
    const expired: boolean = action.payload;
    yield put(loadersActions.alerts.activate());
    const alerts: AxiosResponse<AlertDto[]> = yield call(
      insuranceAPI.getAlerts,
      operationName,
      expired
    );
    yield put(alertActions.saveAll(alerts.data.map(a => ({ ...a, displayType: '' }))));
    yield put(alertActions.sort({ sort: 'date', direction: SortDirection.ASCENDING }));
  } catch (e) {
    yield put(alertActions.saveAll([]));
    console.error(e);
  } finally {
    yield put(loadersActions.alerts.disable());
  }
}

function* getPriceTrendExpected(action: ActionType<typeof alertActions.getAlertPrices>) {
  try {
    yield put(loadersActions.spinner.activate());
    const { year, quarter, product, type, month } = action.payload;
    let alertPrices: AxiosResponse<HistoricalAnalysisExpectedDto>;
    if (type === 'Expected') {
      alertPrices = yield call(
        insuranceAPI.getHistoricalAnalysisExpectedRMA,
        year,
        quarter,
        product
      );
    } else {
      alertPrices = yield call(
        insuranceAPI.getHistoricalAnalysisPriceFutures,
        year,
        quarter,
        product,
        month ? month : 1
      );
    }
    yield put(alertActions.saveAlertPrices(alertPrices.data));
    yield put(alertActions.setIsProductSupported(true));
  } catch (error: any) {
    if (error.message === 'Request failed with status code 400') {
      yield put(alertActions.setIsProductSupported(false));
      toast.error(error.message);
    }
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* createAlert(action: ActionType<typeof alertActions.create>) {
  try {
    const operationName: string = yield select(getOperationName);
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.putAlert, operationName, action.payload);
    toast.success('Alert Created');
    yield getAlerts({ payload: false });
  } catch (e) {
    toast.error('Failed to create Alert');
    console.error(e);
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
  }
}

function* editAlert(action: ActionType<typeof alertActions.edit>) {
  try {
    const operationName: string = yield select(getOperationName);
    yield call(insuranceAPI.editAlert, operationName, action.payload);
    yield put(alertActions.update(action.payload));
    toast.success('Alert Edited');
  } catch (e) {
    console.error(e);
  }
}

function* deleteAlert(action: ActionType<typeof alertActions.delete>) {
  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.deleteAlert, action.payload);
    yield put(alertActions.remove(action.payload));
    toast.success('Alert Deleted');
  } catch (e) {
    console.error(e);
  } finally {
    yield action.meta();
    yield put(loadersActions.spinner.disable());
  }
}

function* getAlertScenarioExpectedPrices(
  action: ActionType<typeof alertActions.getAlertScenarioExpectedPrices>
) {
  const operationName: string = yield select(getOperationName);
  const {
    year,
    quarter,
    measureType,
    priceType,
    classPriceWeightingFactor,
    componentPriceWeightingFactor,
    coverageLevel,
    proteinTest,
    butterfatTest
  } = action.payload;
  yield put(loadersActions.spinner.activate());
  try {
    const scenarioAlertExpectedPrices: AxiosResponse<HistoricalAnalysisExpectedDto> = yield call(
      insuranceAPI.getScenarioAlertExpectedPrices,
      operationName,
      year,
      quarter,
      priceType,
      measureType,
      classPriceWeightingFactor,
      componentPriceWeightingFactor,
      coverageLevel,
      proteinTest,
      butterfatTest
    );
    yield put(alertActions.saveAlertPrices(scenarioAlertExpectedPrices.data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* getAlertPriceFloorExpectedPrices(
  action: ActionType<typeof alertActions.getAlertPriceFloorExpectedPrices>
) {
  const operationName: string = yield select(getOperationName);
  const {
    year,
    quarter,
    salesEffectiveDate,
    measureType,
    priceType,
    classPriceWeightingFactor,
    componentPriceWeightingFactor,
    coverageLevel,
    proteinTest,
    butterfatTest,
    protectionFactor,
    basis
  } = action.payload;
  yield put(loadersActions.spinner.activate());
  try {
    const scenarioAlertPriceFloor: AxiosResponse<HistoricalAnalysisPriceFloorDto> = yield call(
      insuranceAPI.getScenarioAlertPriceFloor,
      operationName,
      year,
      quarter,
      salesEffectiveDate,
      priceType,
      measureType,
      classPriceWeightingFactor,
      componentPriceWeightingFactor,
      coverageLevel,
      proteinTest,
      butterfatTest,
      protectionFactor,
      basis
    );
    yield put(alertActions.saveAlertPriceFloorPrices(scenarioAlertPriceFloor.data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* getAlertTableRowValues(action: ActionType<typeof alertActions.getAlertTableRowValues>) {
  const operationName: string = yield select(getOperationName);
  const {
    year,
    quarter,
    salesEffectiveDate,
    measureType,
    priceType,
    classPriceWeightingFactor,
    componentPriceWeightingFactor,
    coverageLevel,
    proteinTest,
    butterfatTest,
    protectionFactor,
    basis
  } = action.payload;
  try {
    const scenarioAlertPriceFloor: AxiosResponse<HistoricalAnalysisPriceFloorDto> = yield call(
      insuranceAPI.getScenarioAlertPriceFloor,
      operationName,
      year,
      quarter,
      salesEffectiveDate,
      priceType,
      measureType,
      classPriceWeightingFactor,
      componentPriceWeightingFactor,
      coverageLevel,
      proteinTest,
      butterfatTest,
      protectionFactor,
      basis
    );
    action.meta.setCalculatedDataFloorValues(scenarioAlertPriceFloor.data);
  } catch (e) {
    console.error(e);
  }
  yield action.meta.setIsLoading(false);
}

function* sortAlerts(action: ActionType<typeof alertActions.sort>) {
  try {
    const alerts: AlertDto[] = yield select(getSelectorAlerts);
    const { sort, direction } = action.payload;
    const sortedAlerts = [...alerts].map(alert => ({
      ...alert,
      displayType:
        alert.priceType === 'Component'
          ? `BF: ${alert.butterfatTest.toLocaleString('en-US', {
              minimumFractionDigits: 1,
              maximumFractionDigits: 2
            })},
        PR: ${alert.proteinTest.toLocaleString('en-US', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 2
        })}${
          alert.measureType === 'RevenueGuarantee' ? `, CL: ${alert.coverageLevel * 100}%` : ''
        } WF: ${alert.componentPriceWeightingFactor * 100}%`
          : alert.priceType === 'Class'
          ? `Class - III: ${alert.classPriceWeightingFactor * 100}%, IV: ${
              100 - Math.floor(alert.classPriceWeightingFactor * 100)
            }%${
              alert.measureType === 'RevenueGuarantee' ? `, CL: ${alert.coverageLevel * 100}%` : ''
            }`
          : alertProducts[alert.product]
    }));
    if (sort === 'series') {
      sortedAlerts.sort((a, b) => a.displayType.localeCompare(b.displayType));
    }
    if (sort === 'type') {
      sortedAlerts.sort((dataA, dataB) => {
        const a =
          dataA.priceType !== 'Component' && dataA.priceType !== 'Class'
            ? dataA.priceType
            : dataA.measureType;
        const b =
          dataB.priceType !== 'Component' && dataB.priceType !== 'Class'
            ? dataB.priceType
            : dataB.measureType;

        if (a === null) {
          return 1;
        }
        if (b === null) {
          return -1;
        }
        if (a === b) {
          return 0;
        }
        return a < b ? -1 : 1;
      });
    }
    if (sort === 'active') {
      sortedAlerts.sort((dataA, dataB) => {
        if (dataA.active === dataB.active) {
          return 0;
        }
        return dataA.active ? -1 : 1;
      });
    }
    if (sort === 'trigger' || sort === 'condition') {
      sortedAlerts.sort(sortObjectByKey(sort, SortDirection.ASCENDING));
    }
    if (sort === 'date') {
      sortedAlerts.sort((a, b) => {
        let dateA = new Date(a.year, (a.month ?? 1) - 1);
        let dateB = new Date(b.year, (b.month ?? 1) - 1);

        if (!a.month) {
          dateA = subDays(addQuarters(dateA, a.quarter), 1);
        }
        if (!b.month) {
          dateB = subDays(addQuarters(dateB, b.quarter), 1);
        }

        return compareAsc(dateA, dateB);
      });
    }
    if (direction === SortDirection.DESCENDING) {
      sortedAlerts.reverse();
    }
    yield put(alertActions.saveAll(sortedAlerts));
  } catch (e) {
    console.log(e);
  }
}

export function* alertsSagaWatcher() {
  yield all([
    takeLatest(getType(alertActions.get), getAlerts),
    takeLatest(getType(alertActions.getAlertPrices), getPriceTrendExpected),
    takeEvery(getType(alertActions.getAlertTableRowValues), getAlertTableRowValues),
    takeLatest(getType(alertActions.create), createAlert),
    takeLatest(getType(alertActions.edit), editAlert),
    takeLatest(getType(alertActions.delete), deleteAlert),
    takeLatest(getType(alertActions.sort), sortAlerts),
    debounce(
      800,
      getType(alertActions.getAlertScenarioExpectedPrices),
      getAlertScenarioExpectedPrices
    ),
    debounce(
      800,
      getType(alertActions.getAlertPriceFloorExpectedPrices),
      getAlertPriceFloorExpectedPrices
    )
  ]);
}
