import { ActionType, createAction } from 'typesafe-actions';

import { Tag } from '../../scenes/common/Tags/types/Tag';

export const tagActions = {
  tags: {
    set: createAction('@tags_action/SET')<Tag[]>(),
    fetch: createAction('@tags_action/FETCH')<{ operationName: string; manageLoader?: boolean }>(),
    push: createAction('@tags_action/PUSH')<{ tag: Tag }>(),
    edit: createAction('@tags_action/EDIT')<{ tag: Tag }>(),
    delete: createAction('@tags_action/DELETE')<{ operationName: string; tagName: string }>(),
    merge: createAction('@tags_action/MERGE')<{
      operationName: string;
      tagName: string;
      selectedTags: string[];
    }>()
  },

  tagLoader: {
    activate: createAction('@tagLoader/ACTIVATE')(),
    disable: createAction('@tagLoader/DISABLE')()
  }
};

export type TagActions = ActionType<typeof tagActions>;
