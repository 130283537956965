import { AuthState } from '../authentication/reducer';

class operationsGetParameters {
  agencyName: string;
  bindingRequirements = false;
  userName: string | null = null;
  userRole: string | null = null;

  constructor(agencyName: string) {
    this.agencyName = agencyName;
  }
}

function agencyOperationsParams(agencyName: string) {
  return new operationsGetParameters(agencyName);
}

function agencyOperationsWithBindingRequirementsParams(agencyName: string) {
  const parameters = agencyOperationsParams(agencyName);
  parameters.bindingRequirements = true;
  return parameters;
}

function agencyOperationsWithUserParams(agencyName: string, userName: string, userRole: AuthState) {
  const parameters = agencyOperationsParams(agencyName);
  parameters.userName = userName;
  parameters.userRole = userRole;
  return parameters;
}

function agencyOperationsWithUserBindingRequirementsParams(
  agencyName: string,
  userName: string,
  userRole: AuthState
) {
  const parameters = agencyOperationsWithBindingRequirementsParams(agencyName);
  parameters.userName = userName;
  parameters.userRole = userRole;
  return parameters;
}

export {
  operationsGetParameters,
  agencyOperationsParams,
  agencyOperationsWithBindingRequirementsParams,
  agencyOperationsWithUserParams,
  agencyOperationsWithUserBindingRequirementsParams
};
