import * as React from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FormattedNumberInput } from '@/shared/components/common/NumberInput';

import { QuarterListItem } from '@/ducks/dairyRevenueProtection/settings/reducer';

import Loader from '../../components/shared/ContentLoaders/ContentLoaderRectangle';
import Column from '../../components/whatif/Column/Column';
import ColumnLabel from '../../components/whatif/ColumnLabel/ColumnLabel';
import Table from '../../components/whatif/Table/Table';
import TableHeader from '../../components/whatif/TableHeader/TableHeader';
import { dairyRevenueProtectionActions } from '../../ducks/dairyRevenueProtection/actions';
import {
  getAllPriceComponents,
  getDeclaredButterfatTest,
  getDeclaredClassPriceWeightingFactor,
  getDeclaredMilkProduction,
  getDeclaredProteinTest,
  getPremiumQuarter,
  getQuarter,
  getQuarterList,
  getSalesEffectiveDate
} from '../../ducks/dairyRevenueProtection/selectors';
import { getDairyRevenueProtectionLoaderState } from '../../ducks/loaders/selectors';
import { RootState } from '../../ducks/store';
import color from '../../utils/color';
import { formatDateYearMonthDay } from '../../utils/dateFormatter';
import { Unit } from './PremiumsContainer';

const SalesDateMessage = styled(Column)`
  color: ${color.NEGATIVE};
  font-size: 12px;
  font-weight: 600;
`;

interface OwnProps {
  useBasis: boolean;
  useSubsidy: boolean;
  useProbability: boolean;
  useProtectionFactorAnalysis: boolean;
  unit: Unit;
  salesEffectiveDate: Date;
}

type Props = OwnProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const SinglePremiumQuarter: React.FC<Props> = ({
  values,
  isLoading,
  unit,
  setBasisClass,
  setBasisComponent,
  setBasisCwtClass,
  setBasisCwtComponent,
  classPriceWeightingFactor,
  butterfatTest,
  proteinTest,
  priceComponents,
  salesEffectiveDate
}) => {
  const declaredCoveredMilk: number = useSelector(getDeclaredMilkProduction);
  const practiceCode = useSelector(getQuarter);
  const practiceCodes = useSelector(getQuarterList);

  const selectedQuarter = practiceCodes.find(e => e.practiceCode === practiceCode) as
    | QuarterListItem
    | undefined;
  const renderDeviationValue = (type: 'class' | 'component') => (children: React.ReactNode) =>
    selectedQuarter?.deviation?.[type] ? 'N/A' : children;
  const componentDeviation = renderDeviationValue('component');
  const classDeviation = renderDeviationValue('class');

  const expectedYield = React.useMemo(() => {
    if (!selectedQuarter?.deviation?.component && priceComponents.expectedYield.component !== 0) {
      return componentDeviation(priceComponents.expectedYield.component.toLocaleString());
    } else {
      return classDeviation(priceComponents.expectedYield.class.toLocaleString());
    }
  }, [
    priceComponents.expectedYield.class,
    priceComponents.expectedYield.component,
    selectedQuarter
  ]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>
            {salesEffectiveDate.getTime() !== 0
              ? `Sales Effective Date: ${formatDateYearMonthDay(salesEffectiveDate)}`
              : 'Sales Effective Date not available'}
          </TableHeader>
          <TableHeader>
            Components ({butterfatTest}% BF, {proteinTest}% PR)
          </TableHeader>
          <TableHeader>
            Class Blend ({Math.floor(classPriceWeightingFactor * 100)}% Class III,{' '}
            {100 - Math.floor(classPriceWeightingFactor * 100)}% Class IV)
          </TableHeader>
        </tr>
      </thead>
      <tbody>
        {values
          .filter(e => e.name !== 'Expected yield')
          .map(({ name, component, class: classValue }) => {
            if (name !== 'Expected Basis') {
              return (
                <tr key={name} style={{ borderBottom: `solid 1px ${color.NEUTRAL}` }}>
                  <ColumnLabel>{name}</ColumnLabel>
                  <Column>
                    {isLoading ? (
                      <Loader width={100} height={17} />
                    ) : priceComponents.premiumBeforeSubsidy.component !== 0 ? (
                      componentDeviation(component)
                    ) : (
                      ''
                    )}
                  </Column>
                  <Column>
                    {isLoading ? (
                      <Loader width={100} height={17} />
                    ) : priceComponents.premiumBeforeSubsidy.class !== 0 ? (
                      classDeviation(classValue)
                    ) : (
                      ''
                    )}
                  </Column>
                </tr>
              );
            }

            return (
              <tr key={name} style={{ borderBottom: `solid 1px ${color.NEUTRAL}` }}>
                <ColumnLabel>{name}</ColumnLabel>
                <Column>
                  {isLoading ? (
                    <Loader width={100} height={17} />
                  ) : priceComponents.premiumBeforeSubsidy.component === 0 ? (
                    ''
                  ) : (
                    componentDeviation(
                      <FormattedNumberInput
                        value={Number(component)}
                        decimals={unit === Unit.DOLLAR ? 0 : 2}
                        onChange={value => {
                          if (unit === Unit.DOLLAR) {
                            setBasisComponent([value, declaredCoveredMilk]);
                          } else {
                            setBasisCwtComponent([value, declaredCoveredMilk]);
                          }
                        }}
                      />
                    )
                  )}
                </Column>
                <Column>
                  {isLoading ? (
                    <Loader width={100} height={17} />
                  ) : priceComponents.premiumBeforeSubsidy.class === 0 ? (
                    ''
                  ) : (
                    classDeviation(
                      <FormattedNumberInput
                        value={Number(classValue)}
                        decimals={unit === Unit.DOLLAR ? 0 : 2}
                        onChange={value => {
                          if (unit === Unit.DOLLAR) {
                            setBasisClass([value, declaredCoveredMilk]);
                          } else {
                            setBasisCwtClass([value, declaredCoveredMilk]);
                          }
                        }}
                      />
                    )
                  )}
                </Column>
              </tr>
            );
          })}
        <tr style={{ borderBottom: `solid 1px ${color.NEUTRAL}`, backgroundColor: '#F3F3F8' }}>
          <ColumnLabel>Expected yield: {expectedYield}</ColumnLabel>
          <ColumnLabel></ColumnLabel>
          <ColumnLabel></ColumnLabel>
        </tr>
        <tr>
          <Column />
          {priceComponents.premiumBeforeSubsidy.component === 0 && !isLoading ? (
            <SalesDateMessage>*Sales Date not available for Component</SalesDateMessage>
          ) : (
            <td />
          )}
          {priceComponents.premiumBeforeSubsidy.class === 0 && !isLoading ? (
            <SalesDateMessage>*Sales Date not available for Class</SalesDateMessage>
          ) : (
            <td />
          )}
        </tr>
      </tbody>
    </Table>
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  values: getPremiumQuarter(state, props),
  priceComponents: getAllPriceComponents(state),
  classPriceWeightingFactor: getDeclaredClassPriceWeightingFactor(state),
  butterfatTest: getDeclaredButterfatTest(state),
  proteinTest: getDeclaredProteinTest(state),
  isLoading: getDairyRevenueProtectionLoaderState(state),
  salesEffectiveDate: getSalesEffectiveDate(state)
});

const mapDispatchToProps = {
  setBasisComponent: dairyRevenueProtectionActions.dairyComponents.setBasisComponent,
  setBasisClass: dairyRevenueProtectionActions.dairyComponents.setBasisClass,
  setBasisCwtComponent: dairyRevenueProtectionActions.dairyComponents.setBasisCwtComponent,
  setBasisCwtClass: dairyRevenueProtectionActions.dairyComponents.setBasisCwtClass
};

export default connect(mapStateToProps, mapDispatchToProps)(SinglePremiumQuarter);
