import { getQuarter } from 'date-fns';

import { reviveDate } from '@/utils/dateFormatter';

import ScenarioAnalysisWhatIfPayload from '../../../types/ScenarioWhatIf/ScenarioAnalysisWhatIfPayload';
import {
  ScenarioWhatIfRowWithoutYear,
  ScenarioWhatIfTableData
} from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';

interface MonthPrices {
  date: string;
  expectedPrice: number;
  actualPrice: number;
}

const monthMapper = (
  data: ScenarioWhatIfTableData,
  key: keyof ScenarioWhatIfRowWithoutYear,
  quarter: number
) => {
  const prices = data.monthPrices.map(e => {
    const monthPrice = e[key];
    const isMonthInQuarter =
      monthPrice !== undefined ? getQuarter(reviveDate(monthPrice.date)) === quarter : false;
    if (isMonthInQuarter) {
      return {
        date: monthPrice !== undefined ? monthPrice.date : '',
        expectedPrice: monthPrice !== undefined ? monthPrice.expectedPrice : 0,
        actualPrice: monthPrice !== undefined ? monthPrice.actualPrice : 0
      };
    }
  });
  const filteredPrices: MonthPrices[] = [];
  prices.forEach(e => {
    if (e !== undefined) {
      filteredPrices.push(e);
    }
  });
  return filteredPrices;
};

const quarterMapper = (data: ScenarioWhatIfTableData, key: keyof ScenarioWhatIfRowWithoutYear) => {
  return data.quarterPrices.map(e => {
    const quarterPrice = e[key];

    return {
      quarter: e.quarter,
      expectedPrice: quarterPrice !== undefined ? quarterPrice.expectedPrice : 0,
      actualPrice: quarterPrice !== undefined ? quarterPrice.actualPrice : 0,
      monthPrices: monthMapper(data, key, e.quarter)
    };
  });
};

const yearMapper = (data: ScenarioWhatIfTableData, key: keyof ScenarioWhatIfRowWithoutYear) => {
  const yearPrice = data.yearPrices[key];
  return {
    quarters: quarterMapper(data, key),
    expectedPrice: yearPrice !== undefined ? yearPrice.expectedPrice : 0,
    actualPrice: yearPrice !== undefined ? yearPrice.actualPrice : 0
  };
};

const mapScenarioAnalysisWhatIfPayload = (
  data: ScenarioWhatIfTableData
): ScenarioAnalysisWhatIfPayload => ({
  classIIIPrice: yearMapper(data, 'classIIIPrice'),
  classIVPrice: yearMapper(data, 'classIVPrice'),
  butterPrice: yearMapper(data, 'butterPrice'),
  cheesePrice: yearMapper(data, 'cheesePrice'),
  dryWheyPrice: yearMapper(data, 'dryWheyPrice'),
  butterfatPrice: yearMapper(data, 'butterfatPrice'),
  proteinPrice: yearMapper(data, 'proteinPrice'),
  otherSolidsPrice: yearMapper(data, 'otherSolidsPrice'),
  nonfatDryMilkPrice: yearMapper(data, 'nonfatDryMilkPrice'),
  nonfatSolidsPrice: yearMapper(data, 'nonfatSolidsPrice'),
  milkProduction: yearMapper(data, 'milkProduction')
});

export default mapScenarioAnalysisWhatIfPayload;
