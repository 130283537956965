import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { TagType } from '@/containers/AdminPanelContainers/TagTypes/types/TagType';

import { TagTypeActions, tagTypeActions } from './actions';

const initialState: Array<TagType> = [];

const tagTypeReducer = (state = initialState, action: TagTypeActions) =>
  produce(state, _draft => {
    switch (action.type) {
      case getType(tagTypeActions.tagTypes.set): {
        return action.payload;
      }
      case getType(tagTypeActions.tagTypes.delete): {
        return;
      }
    }
  });

export default tagTypeReducer;
