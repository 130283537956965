import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useAppSelector } from '@/hooks/redux-hooks';

import { Spinner } from '../../components/Spinner/Spinner';
import Tooltip from '../../components/Tooltip/Tooltip';
import Radio from '../../components/shared/Radio/Radio';
import Table from '../../components/whatif/Table/Table';
import { dairyRevenueProtectionActions } from '../../ducks/dairyRevenueProtection/actions';
import {
  getAllQuartersData,
  getCoverageLevel,
  getDeclaredClassPriceWeightingFactor,
  getDeclaredComponentPriceWeightingFactor,
  getDeclaredMilkProduction,
  getProtectionFactor,
  getQuarter,
  getQuarterList,
  getQuarterSelectList,
  getShowAllQuarters
} from '../../ducks/dairyRevenueProtection/selectors';
import {
  getAllQuartersInitialLoaderState,
  getAllQuartersUpdateLoaderState
} from '../../ducks/loaders/selectors';
import { RootState } from '../../ducks/store';
import usePrevious from '../../hooks/usePrevious';
import color from '../../utils/color';
import { formatDateYearMonthDay } from '../../utils/dateFormatter';
import AllPremiumQuartersRow from './AllPremiumQuartersRow';
import { Unit } from './PremiumsContainer';

const Root = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 400px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const RadioText = styled.span<{ disabled?: boolean }>`
  margin-left: 5px;
  color: ${({ disabled }) => (disabled ? color.NEUTRAL_DARK : color.DRP_INPUT)};
  white-space: nowrap;
`;

const TableHead = styled.thead`
  background-color: ${color.PRIMARY}23;
  border-bottom: 2px solid ${color.PRIMARY};
`;

const Th = styled.th`
  padding: 10px;
  font-size: 15px;
  white-space: nowrap;
  &:first-child {
    text-align: left;
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  align-items: center;
  position: relative;
  height: 400px;
  width: 100%;
  z-index: 1;
  border-bottom: 2px solid ${color.NEUTRAL};
  border-top: 1px solid ${color.NEUTRAL};
  margin-bottom: 30px;
`;
const UpdateSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const UnavailableSalesDate = styled.span`
  font-size: 14px;
  text-align: right;
  font-weight: 600;
`;

const Td = styled.td<{
  color?: string;
  align: 'right' | 'left' | 'center' | 'justify';
  maxWidth?: number;
}>`
  color: ${({ color }) => color};
  border-bottom: 1px solid ${color.NEUTRAL};
  vertical-align: middle;
  text-align: ${({ align }) => align};
  padding: 10px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
  background-color: #f3f3f8;
  max-width: 100%;
  white-space: nowrap;
`;

interface OwnProps {
  unit: Unit;
  salesEffectiveDate: Date;
}

type Props = OwnProps;

/**
 * Sonar checks are disabled here due to rendering issues,
 * bypassing React's optimizations that cause table display problems.
 */

const AllPremiumQuarters: React.FC<Props> = ({ unit, salesEffectiveDate }) => {
  const [selectedPracticeCode, setSelectedPracticeCode] = React.useState(0);
  const [hoverText, setHoverText] = React.useState('');
  const dispatch = useDispatch();

  const quarterList = useAppSelector(getQuarterSelectList);
  const practiceCode = useSelector(getQuarter);
  const protectionFactor = useSelector(getProtectionFactor);
  const weightingFactor = useSelector(getDeclaredClassPriceWeightingFactor);
  const coverageLevel = useSelector(getCoverageLevel);
  const declaredMilkProduction = useSelector(getDeclaredMilkProduction);
  const allQuartersCheckboxState = useSelector(getShowAllQuarters);
  const practiceCodes = useSelector(getQuarterList);
  const loaderInitial = useSelector(getAllQuartersInitialLoaderState);
  const loaderUpdate = useSelector(getAllQuartersUpdateLoaderState);
  const declaredComponentPriceWeightingFactor = useSelector(
    getDeclaredComponentPriceWeightingFactor
  );
  const data = useSelector((state: RootState) =>
    getAllQuartersData(state, {
      unit: unit
    })
  );
  const previousAllQuartersCheckboxState = usePrevious(allQuartersCheckboxState);

  React.useEffect(() => {
    if (!previousAllQuartersCheckboxState) {
      dispatch(dairyRevenueProtectionActions.allQuarters.getAllQuarters());
    }
  }, [allQuartersCheckboxState, practiceCodes]);

  React.useEffect(() => {
    if (previousAllQuartersCheckboxState) {
      dispatch(dairyRevenueProtectionActions.allQuarters.updateAllQuarters());
    }
  }, [
    protectionFactor,
    weightingFactor,
    coverageLevel,
    declaredMilkProduction,
    declaredComponentPriceWeightingFactor
  ]);

  React.useEffect(() => {
    setSelectedPracticeCode(practiceCode);
  }, [practiceCode]);

  const onQuarterChange = (practiceCode: number) => () => {
    dispatch(
      dairyRevenueProtectionActions.settings.setQuarterDeviation({
        practiceCode: selectedPracticeCode,
        deviation: {
          class: false,
          component: false
        }
      })
    );

    dispatch(dairyRevenueProtectionActions.settings.setQuarter(practiceCode));
  };

  const onHover = (quarterIndex: number) => {
    setHoverText(`Sales Not Available for ${quarterList[quarterIndex].name}`);
  };

  if (loaderInitial) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    );
  }

  const expectedYield = data.filter(e => e.measure === 'Expected yield');
  const results: any[] = [];
  expectedYield[0].measureValues.forEach((componentYield, index) => {
    const classYield = expectedYield[1].measureValues[index];
    if (componentYield !== null) {
      results.push(componentYield);
    } else {
      results.push(classYield);
    }
  });

  return (
    <Root>
      {loaderUpdate ? (
        <UpdateSpinnerContainer>
          <Spinner />
        </UpdateSpinnerContainer>
      ) : null}
      <Table>
        <TableHead>
          <tr>
            <Th colSpan={2}>
              {salesEffectiveDate.getTime() !== 0
                ? `Sales Effective Date: ${formatDateYearMonthDay(salesEffectiveDate)}`
                : 'Sales Effective Date not available'}
            </Th>
            {quarterList.map(({ name: quarterName, id: practiceCode }) => (
              <Th key={quarterName}>
                <RadioContainer onClick={practiceCode ? onQuarterChange(practiceCode) : () => {}}>
                  <Radio active={practiceCode === selectedPracticeCode} disabled={!practiceCode} />
                  <RadioText disabled={!practiceCode}>{quarterName}</RadioText>
                </RadioContainer>
              </Th>
            ))}
          </tr>
        </TableHead>
        <Tooltip id="unavailableSalesDate">
          <UnavailableSalesDate>{hoverText}</UnavailableSalesDate>
        </Tooltip>
        <tbody style={{ borderBottom: `solid 2px ${color.NEUTRAL}` }}>
          {data.map(({ type, index, rowSpan, measure, measureValues }, dataIndex) =>
            measure === 'Expected yield' ? null : (
              <AllPremiumQuartersRow
                unit={unit}
                type={type}
                index={index}
                rowspan={rowSpan}
                measure={measure}
                measureValues={measureValues}
                key={dataIndex} // NOSONAR
                onHoverTextChange={onHover}
              />
            )
          )}
          <tr>
            <Td align="left">Expected milk per cow</Td>
            <Td align="left"></Td>
            {results.length
              ? results.map((value, dataIndex) => {
                  return (
                    <Td key={dataIndex} align="right"> {/** NOSONAR */}
                      {value}
                    </Td>
                  );
                })
              : null}
          </tr>
        </tbody>
      </Table>
    </Root>
  );
};

export default AllPremiumQuarters;
