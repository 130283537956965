import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { utcDateToLocalDate } from '@/utils/utcDateToLocalDate';

import { LgmWhatIfActual } from '../../../types/Lgm/LgmWhatIfActual';
import LgmWhatIfPremium from '../../../types/Lgm/LgmWhatIfPremium';
import { formatDateYearMonth } from '../../../utils/dateFormatter';
import { LGMWhatIfActions, lgmWhatIfActions } from './actions';

interface LGMWhatIfState {
  actualPrices: LgmWhatIfActual[];
  premiumPrices: LgmWhatIfPremium[];
}

const initialState: LGMWhatIfState = {
  actualPrices: [],
  premiumPrices: []
};

const lgmWhatIfReducer = (state = initialState, action: LGMWhatIfActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(lgmWhatIfActions.saveLgmWhatIfActual): {
        draft.actualPrices = action.payload;
        return;
      }
      case getType(lgmWhatIfActions.saveLgmWhatIfActualRow): {
        const index = state.actualPrices.findIndex(
          e => formatDateYearMonth(utcDateToLocalDate(e.date)) === action.meta
        );
        if (index !== -1) {
          draft.actualPrices[index] = {
            date: state.actualPrices[index].date,
            actualMilkPrice: action.payload.milk,
            actualCornPrice: action.payload.corn,
            actualSoybeanPrice: action.payload.sbm
          };
        }
        return;
      }
      case getType(lgmWhatIfActions.saveLgmWhatIfPremium): {
        draft.premiumPrices = action.payload;
        return;
      }
    }
  });

export default lgmWhatIfReducer;
