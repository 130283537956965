import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Agent } from '../../types/Agent/Agent';
import County from '../../types/County/County';
import OperationReportData from '../../types/ManagementReports/OperationReport';
import { SmsUsageDto } from '../../types/SmsSettings';
import State from '../../types/State/State';
import { ManagementReportActions, managementReportActions } from './actions';

interface InitialState {
  operationReports: OperationReportData;
  filterParamaters: {
    agentList: Agent[];
    stateList: State[];
    countyList: County[];
  };
  smsCulmulativeUsageData: SmsUsageDto[];
  smsDetailedUsageData: SmsUsageDto[];
  smsSettings: { startDate: string; endDate: string; agency: string };
}

const initialState: InitialState = {
  operationReports: {
    mainReport: [],
    subtotalByState: [],
    subtotalByCounty: [],
    subtotalByAgent: [],
    grandTotal: {
      name: '',
      fullName: '',
      stateCode: '',
      stateName: '',
      numberOfEndorsements: 0,
      declaredCoveredMilkProduction: 0,
      effectiveCoveredMilkProduction: 0,
      totalMilkProduction: 0,
      totalPremiumAmount: 0,
      producerPremiumAmount: 0,
      estimatedIndemnity: 0,
      netIndemnity: 0
    }
  },
  filterParamaters: {
    agentList: [],
    stateList: [],
    countyList: []
  },
  smsCulmulativeUsageData: [],
  smsDetailedUsageData: [],
  smsSettings: {
    startDate: '',
    endDate: '',
    agency: ''
  }
};

export const managementReportsReducer = (state = initialState, action: ManagementReportActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(managementReportActions.saveOperationReports): {
        draft.operationReports = action.payload;
        return;
      }
      case getType(managementReportActions.filter.setState): {
        const stateIndex = state.filterParamaters.stateList.findIndex(
          s => s.stateCode === action.payload.stateCode
        );
        if (stateIndex !== -1) {
          draft.filterParamaters.stateList.splice(stateIndex, 1);
        } else {
          draft.filterParamaters.stateList.push(action.payload);
        }
        return;
      }
      case getType(managementReportActions.filter.setCounty): {
        const countyIndex = state.filterParamaters.countyList.findIndex(
          s => s.countyCode === action.payload.countyCode
        );
        if (countyIndex !== -1) {
          draft.filterParamaters.countyList.splice(countyIndex, 1);
        } else {
          draft.filterParamaters.countyList.push(action.payload);
        }
        return;
      }
      case getType(managementReportActions.filter.setAgent): {
        const agentIndex = state.filterParamaters.agentList.findIndex(
          a => a.email === action.payload.email
        );
        if (agentIndex !== -1) {
          draft.filterParamaters.agentList.splice(agentIndex, 1);
        } else {
          draft.filterParamaters.agentList.push(action.payload);
        }
        return;
      }
      case getType(managementReportActions.filter.clearAllStates): {
        draft.filterParamaters.stateList = [];
        draft.filterParamaters.countyList = [];
        return;
      }
      case getType(managementReportActions.filter.clearAllCounties): {
        draft.filterParamaters.countyList = [];
        return;
      }
      case getType(managementReportActions.filter.clearAllAgents): {
        draft.filterParamaters.agentList = [];
        return;
      }
      case getType(managementReportActions.saveSmsCulmulativeUsageData): {
        draft.smsCulmulativeUsageData = action.payload;
        return;
      }
      case getType(managementReportActions.saveSmsDetailedUsageData): {
        draft.smsDetailedUsageData = action.payload;
        return;
      }
      case getType(managementReportActions.saveSmsSettings): {
        draft.smsSettings = action.payload;
        return;
      }
    }
  });
