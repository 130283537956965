import * as React from 'react';
import Switch from 'react-switch';
import styled from 'styled-components/macro';

import color from '../../utils/color';
import Tooltip from '../Tooltip/Tooltip';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const LabelOn = styled.span<{
  active: boolean;
  labelOnColor: string;
  labelOffColor: string;
  isStatic: boolean;
  padding?: number;
}>`
  padding-right: ${({ padding }) => (padding ? padding / 2 + 'px' : '10px')};
  padding-left: ${({ padding }) => (padding ? padding / 2 + 'px' : '0px')};
  color: ${({ active, labelOnColor, labelOffColor, isStatic }) =>
    isStatic ? labelOffColor : active ? labelOffColor : labelOnColor};
`;

const LabelOff = styled.span<{
  active: boolean;
  labelOffColor: string;
  labelOnColor: string;
  isStatic: boolean;
  padding?: number;
}>`
  padding-right: ${({ padding }) => (padding ? padding / 2 + 'px' : '0px')};
  padding-left: ${({ padding }) => (padding ? padding / 2 + 'px' : '10px')};
  color: ${({ active, labelOffColor, labelOnColor, isStatic }) =>
    isStatic ? labelOnColor : active ? labelOffColor : labelOnColor};
`;

interface Props {
  onClick: (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => void;
  active: boolean;
  labelOn: string;
  labelOff: string;
  onColor?: string;
  labelOnColor?: string;
  labelOffColor?: string;
  offColor?: string;
  disabled?: boolean;
  isLabelColorStatic?: boolean;
  tooltipOn?: React.ReactNode;
  tooltipOff?: React.ReactNode;
  padding?: number;
  dataCy?: string;
}

const BooleanPicker: React.FC<Props> = ({
  onClick,
  active,
  labelOff,
  labelOn,
  onColor = color.PRIMARY,
  offColor = color.NEUTRAL,
  isLabelColorStatic = false,
  labelOnColor = color.PRIMARY,
  labelOffColor = color.TEXT_PRIMARY,
  disabled = false,
  tooltipOn,
  tooltipOff,
  padding,
  dataCy
}) => (
  <Container data-cy={dataCy}>
    <Tooltip id={labelOn}>{tooltipOn}</Tooltip>
    <LabelOn
      active={active}
      labelOnColor={labelOnColor}
      labelOffColor={labelOffColor}
      isStatic={isLabelColorStatic}
      data-tooltip-content=""
      data-tooltip-id={labelOn}
      padding={padding}
      data-cy={dataCy + '_toggle'}
    >
      {labelOn}
    </LabelOn>
    <Switch
      checked={active}
      onChange={onClick}
      onColor={onColor}
      disabled={disabled}
      offColor={offColor}
      checkedIcon={false}
      uncheckedIcon={false}
      height={20}
      width={40}
      data-cy={dataCy + '_switch'}
    />
    <Tooltip id={labelOff}>{tooltipOff}</Tooltip>
    <LabelOff
      active={!active}
      labelOffColor={labelOffColor}
      labelOnColor={labelOnColor}
      isStatic={isLabelColorStatic}
      data-tooltip-content=""
      data-tooltip-id={labelOff}
      padding={padding}
    >
      {labelOff}
    </LabelOff>
  </Container>
);

export default BooleanPicker;
