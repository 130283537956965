import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import County from '../../types/County/County';
import State from '../../types/State/State';
import { areasActions } from './actions';

const createCountyState = (counties: County[]): { [key: string]: County[] } => {
  const mappedCounties: { [key: string]: County[] } = {};
  counties.forEach(c => {
    if (mappedCounties[c.stateCode] !== undefined) {
      mappedCounties[c.stateCode].push(c);
    } else {
      mappedCounties[c.stateCode] = [c];
    }
  });
  return mappedCounties;
};

function* getStates() {
  try {
    const states: AxiosResponse<{ stateList: State[] }> = yield call(insuranceAPI.getStates);
    yield put(areasActions.setStates(states.data.stateList));
  } catch (e) {
    console.error(e);
  }
}

function* getCounties() {
  try {
    const counties: AxiosResponse<County[]> = yield call(insuranceAPI.getCounties);
    const mappedCounties = createCountyState(counties.data);
    yield put(areasActions.setCounties(mappedCounties));
  } catch (e) {
    console.error(e);
  }
}

function* areasSagaWatcher() {
  yield all([
    takeLatest(getType(areasActions.getStates), getStates),
    takeLatest(getType(areasActions.getCounties), getCounties)
  ]);
}

export default areasSagaWatcher;
