import { getType } from 'typesafe-actions';

import { PriceReport } from '../../../types/PriceReport';
import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

interface DairyRevenueProtectionMarketReportsState {
  data: PriceReport;
  fistDate: Date;
}

const cmeDefault = {
  dayPrice: 0,
  dayPriceChange: 0,
  dayTrades: 0,
  weekPrice: 0,
  weekPriceBeforeLast: 0,
  weekPriceChange: 0,
  weekPricePrevious: 0
};

const initialState: DairyRevenueProtectionMarketReportsState = {
  data: {
    salesEffectiveDate: '',
    calendarEvent: null,
    practices: [],
    classIII: [],
    classIV: [],
    butter: [],
    cheese: [],
    dryWhey: [],
    nonfatDryMilk: [],
    nonfatSolids: [],
    otherSolids: [],
    butterfat: [],
    protein: [],
    yield: [],
    cmeBlockCheese: cmeDefault,
    cmeBarrelCheese: cmeDefault,
    cmeNonfatDryMilk: cmeDefault,
    cmeButter: cmeDefault,
    cmeDryWhey: cmeDefault,
    cmeClassIII: cmeDefault,
    cmeClassIV: cmeDefault
  },
  fistDate: new Date()
};

export const dairyRevenueProtectionMarketReportsReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => {
  switch (action.type) {
    case getType(dairyRevenueProtectionActions.priceReports.update):
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
