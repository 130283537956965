import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { getLrpAnalysisHistoricalPricesTableData } from '../../../../../ducks/lrp/selectors';
import { RootState } from '../../../../../ducks/store';
import color from '../../../../../utils/color';
import { formatDateMonth } from '../../../../../utils/dateFormatter';
import { LrpAnalysisFilterSettings } from '../../../types/LrpAnalysis';
import { renderTable } from './renderHistoricalPricesTable';

const historicalPricesHeaders: { name: string; id: string }[] = [
  { name: '$', id: 'year' },
  { name: 'Jan', id: '1' },
  { name: 'Feb', id: '2' },
  { name: 'Mar', id: '3' },
  { name: 'Apr', id: '4' },
  { name: 'May', id: '5' },
  { name: 'Jun', id: '6' },
  { name: 'Jul', id: '7' },
  { name: 'Aug', id: '8' },
  { name: 'Sep', id: '9' },
  { name: 'Oct', id: '10' },
  { name: 'Nov', id: '11' },
  { name: 'Dec', id: '12' }
];

export enum Unit {
  CWT,
  HEAD,
  TOTAL
}

interface Props {
  settings?: LrpAnalysisFilterSettings;
  unit: number;
  selectedMeasure: { name: string; id: string };
}

const HistoricalPricesTable: React.FC<Props> = ({ settings, unit, selectedMeasure }) => {
  const [lrpOffersDate, setLrpOffersDate] = React.useState<Date>(new Date());
  const monthNumber = settings?.salesEffectiveDate ? settings.salesEffectiveDate.getMonth() + 1 : 0;
  const historicaPriceData = useSelector((state: RootState) =>
    getLrpAnalysisHistoricalPricesTableData(state, {
      selectedMeasure: selectedMeasure.id,
      unit
    })
  );

  const setHeader = (column: string) => {
    if (column === '$') {
      if (unit === Unit.CWT) {
        return '$/cwt';
      } else if (unit === Unit.HEAD) {
        return '$/head';
      } else return '$';
    } else return column;
  };

  React.useEffect(() => {
    if (settings && settings.salesEffectiveDate) {
      const date = new Date(settings.salesEffectiveDate.toString().slice(0, 19));
      setLrpOffersDate(date);
    }
  }, [settings]);

  return (
    <TableWrapper enableOverflow={historicaPriceData.length > 20}>
      <Table cellSpacing={0}>
        <TableHeader>
          Historical {selectedMeasure.name} for Endorsements Purchased in{' '}
          {formatDateMonth(lrpOffersDate)}
        </TableHeader>
        {historicaPriceData.length === 0 ? (
          <ErrorContainer>
            <span>{'No data available'}</span>
          </ErrorContainer>
        ) : (
          <SubTable cellSpacing={0}>
            <RowStyle>
              {(historicalPricesHeaders ?? []).map(columns => (
                <TableHeaderStyle key={columns.id}>{setHeader(columns.name)}</TableHeaderStyle>
              ))}
            </RowStyle>
            {historicaPriceData.map((row, index) => (
              <tr key={index}>{renderTable(row, monthNumber, unit)}</tr>
            ))}
          </SubTable>
        )}
      </Table>
    </TableWrapper>
  );
};

export default HistoricalPricesTable;

const TableWrapper = styled.div<{ enableOverflow: boolean }>`
  overflow-y: ${({ enableOverflow }) => (enableOverflow ? 'scroll' : 'hidden')};
  flex-grow: 1;
  max-height: 658px;
`;

const Table = styled.table`
  width: 100%;
  padding: 0;
  margin-top: 30px;
`;

const RowStyle = styled.tr`
  background-color: #6eb23f23;
  font-weight: bold;
  font-size: 12px;
  text-align: right;
`;

const TableHeaderStyle = styled.td`
  border-bottom: 2px solid #6eb23f;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 10px;
  min-width: 65px;
`;

const SubTable = styled.table`
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.PRIMARY};
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 3px;
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 20px;
  font-size: 25px;
`;
