import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import ScenarioWhatIfPrices from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { ScenarioAnalysisActions, scenarioAnalysisActions } from '../actions';

type InitialState = {
  whatIfPrices: ScenarioWhatIfPrices;
  isWhatIfChanged: boolean;
  isEditing: boolean;
};

const initialState: InitialState = {
  whatIfPrices: {
    butterfatYearPrice: [],
    proteinYearPrice: [],
    otherSolidsYearPrice: [],
    nonfatSolidsYearPrice: [],
    butterYearPrice: [],
    cheeseYearPrice: [],
    dryWheyYearPrice: [],
    nonfatDryMilkYearPrice: [],
    classIIIYearPrice: [],
    classIVYearPrice: [],
    milkProductionYear: [],

    butterfatQuarterPrice: [],
    proteinQuarterPrice: [],
    otherSolidsQuarterPrice: [],
    nonfatSolidsQuarterPrice: [],
    butterQuarterPrice: [],
    cheeseQuarterPrice: [],
    dryWheyQuarterPrice: [],
    nonfatDryMilkQuarterPrice: [],
    classIIIQuarterPrice: [],
    classIVQuarterPrice: [],
    milkProductionQuarter: [],

    butterfatPrice: [],
    proteinPrice: [],
    otherSolidsPrice: [],
    nonfatSolidsPrice: [],
    milkProduction: [],
    reinsuranceYear: new Date().getFullYear(),
    butterPrice: [],
    cheesePrice: [],
    dryWheyPrice: [],
    nonfatDryMilkPrice: [],
    classIIIPrice: [],
    classIVPrice: []
  },
  isWhatIfChanged: false,
  isEditing: false
};

export const scenarioWhatIfReducer = (state = initialState, action: ScenarioAnalysisActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(scenarioAnalysisActions.saveScenarioWhatIfPrices): {
        draft.whatIfPrices = { ...action.payload };
        return;
      }
      case getType(scenarioAnalysisActions.setRunScenarioButtonState): {
        draft.isWhatIfChanged = action.payload;
        return;
      }
      case getType(scenarioAnalysisActions.setWhatIfIsEditing): {
        draft.isEditing = action.payload;
      }
    }
  });
