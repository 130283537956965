import { ActionType, createAction } from 'typesafe-actions';

import { CalendarState } from './reducer';

export const calendarActions = {
  get: createAction('@calendar/GET')(),
  add: createAction('@calendar/ADD')<CalendarState>()
};

export type CalendarActions = ActionType<typeof calendarActions>;
