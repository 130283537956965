import { AxiosResponse } from 'axios';
import { SagaReturnType, all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { Agency } from '../../types/Agency';
import { Agent } from '../../types/Agent/Agent';
import Operation from '../../types/Operation/Operation';
import OperationContact from '../../types/Operation/OperationContact';
import { OperationContactDto } from '../../types/dto/OperationContactDto';
import sortObjectByKey from '../../utils/sortObjectByKey';
import { getAdminPageAgency } from '../adminPage/selectors';
import { adminActions } from '../adminPanel/actions';
import { getInsuranceAgents, getOperations } from '../adminPanel/selectors';
import { AuthState } from '../authentication/reducer';
import { getAuthState, getEmail } from '../authentication/selectors';
import { loadersActions } from '../loaders/actions';
import { operationContactActions } from './actions';
import { mapOperationContactDtoToOperationContact } from './mapOperationContactDtoToOperationContact';
import { getAllOperationContactOperations } from './selectors';

function* getAgentOperations(
  action: ActionType<typeof operationContactActions.getAgentOperations>
) {
  try {
    const operations: AxiosResponse<OperationContactDto[]> = yield call(
      insuranceAPI.getAgentOperationContacts,
      action.payload,
      action.meta
    );
    const operationContact = mapOperationContactDtoToOperationContact(operations.data);
    yield put(operationContactActions.addAllOperationContact(operationContact));
  } catch (e) {
    console.log(e);
  }
}

function* getOperationAgents(
  action: ActionType<typeof operationContactActions.getAgentOperations>
) {
  try {
    yield put(loadersActions.spinner.activate());
    if (action.payload.length) {
      const agents: AxiosResponse<OperationContactDto[]> = yield call(
        insuranceAPI.getOperationAgentContacts,
        action.payload
      );
      const operationContact = mapOperationContactDtoToOperationContact(agents.data);
      yield put(operationContactActions.addAllOperationContact(operationContact));
      yield put(loadersActions.spinner.disable());
    }
  } catch (e) {
    yield put(loadersActions.spinner.disable());
    console.log(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* updateAgentOperationContact(
  action: ActionType<typeof operationContactActions.updateAgentContact>
) {
  try {
    const data = {
      agentName: action.payload.userId,
      notification: action.payload.sendEndorsementNotification,
      primaryContact: action.payload.primaryContact
    };
    if (action.meta) {
      yield call(insuranceAPI.updateAgentOperationContact, action.payload.operationName, data);
    } else {
      yield call(insuranceAPI.newAgentOperationContact, action.payload.operationName, data);
    }
    yield put(operationContactActions.addOperationContact(action.payload));
  } catch (e) {
    console.log(e);
  }
}

function* deleteAgentOperationContact(
  action: ActionType<typeof operationContactActions.deleteAgentContact>
) {
  try {
    yield call(insuranceAPI.deleteOperationAgentContact, action.payload, action.meta);
    yield put(operationContactActions.removeAgentContact(action.payload, action.meta));
  } catch (e) {
    console.log(e);
  }
}

function* getAgencyOperationsContacts() {
  try {
    const authState: AuthState = yield select(getAuthState);
    const agency: Agency | null = yield select(getAdminPageAgency);

    if (authState === AuthState.producer) {
      const producerEmail: string = yield select(getEmail);
      const agentOperationContacts: AxiosResponse<OperationContactDto[]> = yield call(
        insuranceAPI.getAgencyProducerOperationsContacts,
        producerEmail
      );
      const operationContact = mapOperationContactDtoToOperationContact(
        agentOperationContacts.data
      );
      yield put(operationContactActions.addAllAgencyOperationContact(operationContact));
    } else if (agency) {
      const agentOperationContacts: AxiosResponse<OperationContactDto[]> = yield call(
        insuranceAPI.getAgencyAgentOperationsContacts,
        agency.name
      );
      const operationContact = mapOperationContactDtoToOperationContact(
        agentOperationContacts.data
      );
      yield put(operationContactActions.addAllAgencyOperationContact(operationContact));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* sortAgentOperations({
  payload: { sort, direction },
  meta
}: ActionType<typeof operationContactActions.sortAgentOperations>): any {
  const operations: Operation[] = yield select(getOperations);
  const operationContacts = yield select(getAllOperationContactOperations);

  const mappedOperations = operations.map((operation: Operation) => {
    const isContact = !!(
      operationContacts[operation.name] && operationContacts[operation.name].agents[meta]
    );
    if (isContact) {
      return {
        ...operation,
        primary: operationContacts[operation.name].agents[meta].primaryContact,
        notifications: operationContacts[operation.name].agents[meta].sendEndorsementNotification,
        isContact: true
      };
    }
    return { ...operation, primary: false, notifications: false, isContact: false };
  });

  mappedOperations.sort(sortObjectByKey(sort, direction));

  yield put(
    adminActions.operations.addAllOperations(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars -- refactor later
      mappedOperations.map(({ primary, notifications, isContact, ...operation }: any) => operation)
    )
  );
}

type OperationContactType = SagaReturnType<typeof getAllOperationContactOperations>;
export function* sortOperationAgents({
  payload: { sort, direction },
  meta
}: ActionType<typeof operationContactActions.sortOperationAgents>) {
  const agents: Agent[] = yield select(getInsuranceAgents);
  const operationContacts: OperationContactType = yield select(getAllOperationContactOperations);

  let operationContactList: OperationContact[] = [];
  if (operationContacts[meta.toLowerCase()]) {
    operationContactList = Object.values(operationContacts[meta.toLowerCase()].agents);
  }

  const sortedAgents: Array<
    Agent & { sendEndorsementNotification: boolean; primaryContact: boolean; isContact: boolean }
  > = [];

  if (agents && operationContactList) {
    agents.forEach((agent: Agent) => {
      const existingAgent = operationContactList.find(
        operationAgent => operationAgent.userId === agent.userId
      );
      if (existingAgent) {
        sortedAgents.push({
          ...agent,
          sendEndorsementNotification: existingAgent.sendEndorsementNotification,
          primaryContact: existingAgent.primaryContact,
          isContact: true
        });
      } else {
        sortedAgents.push({
          ...agent,
          sendEndorsementNotification: false,
          primaryContact: false,
          isContact: false
        });
      }
    });
  }

  sortedAgents.sort(sortObjectByKey(sort, direction));

  const agency: Agency | null = yield select(getAdminPageAgency);
  yield put(
    adminActions.insuranceAgents.addAllInsuranceAgents(
      sortedAgents.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars -- refactor later
        ({ sendEndorsementNotification, primaryContact, isContact, ...agent }) => agent as Agent
      ),
      agency?.name ?? ''
    )
  );
}

export default function* operationContactRootSaga() {
  yield all([
    takeLatest(getType(operationContactActions.getAgentOperations), getAgentOperations),
    takeLatest(getType(operationContactActions.getOperationAgents), getOperationAgents),
    takeLatest(getType(operationContactActions.updateAgentContact), updateAgentOperationContact),
    takeLatest(getType(operationContactActions.deleteAgentContact), deleteAgentOperationContact),
    takeLatest(
      getType(operationContactActions.getAgencyOperationsContacts),
      getAgencyOperationsContacts
    ),
    takeLatest(getType(operationContactActions.sortAgentOperations), sortAgentOperations),
    takeLatest(getType(operationContactActions.sortOperationAgents), sortOperationAgents)
  ]);
}
