import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import AllQuarters from '../../../types/AllQuarters';
import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

const initialState: Array<AllQuarters> = [];

const allQuartersReducer = (state = initialState, action: DairyRevenueProtectionActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(dairyRevenueProtectionActions.allQuarters.saveAllQuarters): {
        const data = [...action.payload];
        return data;
      }
      case getType(dairyRevenueProtectionActions.allQuarters.saveIndividualQuarter): {
        const index = draft.findIndex(
          quarter => quarter.practiceCode === action.payload[0].practiceCode
        );
        if (index !== -1) {
          const data = { ...action.payload[0] };
          draft[index] = { ...data };
        }
        return;
      }
      case getType(dairyRevenueProtectionActions.allQuarters.setBasisCwtClass): {
        const index = draft.findIndex(
          allQuarterData => allQuarterData.practiceCode === action.meta.practiceCode
        );
        if (index !== -1) {
          draft[index].expectedBasisCwt.class = action.payload[0];
          draft[index].expectedBasis.class = (action.payload[0] * action.payload[1]) / 100;
          draft[index].expectedPriceFloorNetCwt.class =
            draft[index].revenueGuaranteeCwt.class -
            draft[index].producerPremiumAmountCwt.class +
            action.payload[0];
          draft[index].expectedPriceFloorNet.class =
            draft[index].revenueGuarantee.class -
            draft[index].producerPremiumAmount.class +
            (action.payload[0] * action.payload[1]) / 100;
        }
        return;
      }
      case getType(dairyRevenueProtectionActions.allQuarters.setBasisCwtComponent): {
        const index = draft.findIndex(
          allQuarterData => allQuarterData.practiceCode === action.meta.practiceCode
        );
        if (index !== -1) {
          draft[index].expectedBasisCwt.component = action.payload[0];
          draft[index].expectedBasis.component = (action.payload[0] * action.payload[1]) / 100;
          draft[index].expectedPriceFloorNetCwt.component =
            draft[index].revenueGuaranteeCwt.component -
            draft[index].producerPremiumAmountCwt.component +
            action.payload[0];
          draft[index].expectedPriceFloorNet.component =
            draft[index].revenueGuarantee.component -
            draft[index].producerPremiumAmount.component +
            (action.payload[0] * action.payload[1]) / 100;
        }
        return;
      }
      case getType(dairyRevenueProtectionActions.allQuarters.setBasisClass): {
        const index = draft.findIndex(
          allQuarterData => allQuarterData.practiceCode === action.meta.practiceCode
        );
        if (index !== -1) {
          draft[index].expectedBasisCwt.class = action.payload[0] / (action.payload[1] / 100);
          draft[index].expectedBasis.class = action.payload[0];
          draft[index].expectedPriceFloorNetCwt.class =
            draft[index].revenueGuaranteeCwt.class -
            draft[index].producerPremiumAmountCwt.class +
            action.payload[0] / (action.payload[1] / 100);
          draft[index].expectedPriceFloorNet.class =
            draft[index].revenueGuarantee.class -
            draft[index].producerPremiumAmount.class +
            action.payload[0];
        }
        return;
      }
      case getType(dairyRevenueProtectionActions.allQuarters.setBasisComponent): {
        const index = draft.findIndex(
          allQuarterData => allQuarterData.practiceCode === action.meta.practiceCode
        );
        if (index !== -1) {
          draft[index].expectedBasisCwt.component = action.payload[0] / (action.payload[1] / 100);
          draft[index].expectedBasis.component = action.payload[0];
          draft[index].expectedPriceFloorNetCwt.component =
            draft[index].revenueGuaranteeCwt.component -
            draft[index].producerPremiumAmountCwt.component +
            action.payload[0] / (action.payload[1] / 100);
          draft[index].expectedPriceFloorNet.component =
            draft[index].revenueGuarantee.component -
            draft[index].producerPremiumAmount.component +
            action.payload[0];
        }
        return;
      }
    }
  });

export default allQuartersReducer;
