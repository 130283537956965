import { combineReducers } from 'redux';

import aipReducer from './aipSync/reducer';
import insuranceAgenciesReducer from './insuranceAgencies/reducer';
import insuranceAgentsReducer from './insuranceAgents/reducer';
import operationsProducersReducer from './operationProducers/reducer';
import operationsReducer from './operations/reducer';
import { userPreferencesReducer } from './preferences/reducer';
import producerOperationReducer from './producerOperations/reducer';
import producersReducer from './producers/reducer';

export const adminRootReducer = combineReducers({
  insuranceAgencies: insuranceAgenciesReducer,
  aip: aipReducer,
  insuranceAgents: insuranceAgentsReducer,
  producers: producersReducer,
  operations: operationsReducer,
  operationsProducers: operationsProducersReducer,
  userPreferences: userPreferencesReducer,
  producerOperations: producerOperationReducer
});
