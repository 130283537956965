import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

type Field = { component: number; class: number };

export interface DairyRevenueProtectionPriceComponentsState {
  expectedRevenue: Field;
  revenueGuarantee: Field;
  revenueGuaranteeCwt: Field;
  premiumBeforeSubsidy: Field;
  premiumBeforeSubsidyCwt: Field;
  subsidy: Field;
  subsidyCwt: Field;
  producerPremiumAmount: Field;
  producerPremiumAmountCwt: Field;
  probabilityOfPositiveIndemnity: Field;
  probabilityOfPositiveNetBenefit: Field;
  expectedPriceFloorNet: Field;
  expectedPriceFloorNetCwt: Field;
  basis: Field;
  basisCwt: Field;
  costOfProduction: Field;
  costOfProductionCwt: Field;
  milkProductionEffective: Field;
  milkProductionEffectiveCwt: Field;
  expectedYield: Field;
  expectedPriceFloor: Field;
  expectedPriceFloorCwt: Field;
}

const initialState: DairyRevenueProtectionPriceComponentsState = {
  expectedRevenue: { component: 0, class: 0 },
  revenueGuarantee: { component: 0, class: 0 },
  revenueGuaranteeCwt: { component: 0, class: 0 },
  premiumBeforeSubsidy: { component: 0, class: 0 },
  premiumBeforeSubsidyCwt: { component: 0, class: 0 },
  subsidy: { component: 0, class: 0 },
  subsidyCwt: { component: 0, class: 0 },
  producerPremiumAmount: { component: 0, class: 0 },
  producerPremiumAmountCwt: { component: 0, class: 0 },
  probabilityOfPositiveIndemnity: { component: 0, class: 0 },
  probabilityOfPositiveNetBenefit: { component: 0, class: 0 },
  expectedPriceFloorNet: { component: 0, class: 0 },
  expectedPriceFloorNetCwt: { component: 0, class: 0 },
  basis: { component: 0, class: 0 },
  basisCwt: { component: 0, class: 0 },
  costOfProduction: { component: 0, class: 0 },
  costOfProductionCwt: { component: 0, class: 0 },
  milkProductionEffective: { component: 0, class: 0 },
  milkProductionEffectiveCwt: { component: 0, class: 0 },
  expectedYield: { component: 0, class: 0 },
  expectedPriceFloor: { component: 0, class: 0 },
  expectedPriceFloorCwt: { component: 0, class: 0 }
};

export const dairyRevenueProtectionPriceComponentsReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => {
  switch (action.type) {
    case getType(dairyRevenueProtectionActions.dairyComponents.all):
      return action.payload;
    case getType(dairyRevenueProtectionActions.dairyComponents.setBasisCwtClass):
      return {
        ...state,
        basisCwt: { component: state.basisCwt.component, class: action.payload[0] },
        basis: {
          component: state.basis.component,
          class: (action.payload[0] * action.payload[1]) / 100
        },
        expectedPriceFloorNetCwt: {
          component: state.expectedPriceFloorNetCwt.component,
          class:
            state.revenueGuaranteeCwt.class -
            state.producerPremiumAmountCwt.class +
            action.payload[0]
        },
        expectedPriceFloorNet: {
          component: state.expectedPriceFloorNet.component,
          class:
            state.revenueGuarantee.class -
            state.producerPremiumAmount.class +
            (action.payload[0] * action.payload[1]) / 100
        }
      };
    case getType(dairyRevenueProtectionActions.dairyComponents.setBasisCwtComponent):
      return {
        ...state,
        basisCwt: { component: action.payload[0], class: state.basisCwt.class },
        basis: {
          component: (action.payload[0] * action.payload[1]) / 100,
          class: state.basis.class
        },
        expectedPriceFloorNetCwt: {
          component:
            state.revenueGuaranteeCwt.component -
            state.producerPremiumAmountCwt.component +
            action.payload[0],
          class: state.expectedPriceFloorNetCwt.class
        },
        expectedPriceFloorNet: {
          component:
            state.revenueGuarantee.component -
            state.producerPremiumAmount.component +
            (action.payload[0] * action.payload[1]) / 100,
          class: state.expectedPriceFloorNet.class
        }
      };
    case getType(dairyRevenueProtectionActions.dairyComponents.setBasisClass):
      return {
        ...state,
        basis: { component: state.basis.component, class: action.payload[0] },
        basisCwt: {
          component: state.basisCwt.component,
          class: action.payload[0] / (action.payload[1] / 100)
        },
        expectedPriceFloorNet: {
          component: state.expectedPriceFloorNet.component,
          class:
            state.revenueGuarantee.class - state.producerPremiumAmount.class + action.payload[0]
        },
        expectedPriceFloorNetCwt: {
          component: state.expectedPriceFloorNetCwt.component,
          class:
            state.revenueGuaranteeCwt.class -
            state.producerPremiumAmountCwt.class +
            action.payload[0] / (action.payload[1] / 100)
        }
      };
    case getType(dairyRevenueProtectionActions.dairyComponents.setBasisComponent):
      return {
        ...state,
        basis: { component: action.payload[0], class: state.basis.class },
        basisCwt: {
          component: action.payload[0] / (action.payload[1] / 100),
          class: state.basisCwt.class
        },
        expectedPriceFloorNet: {
          component:
            state.revenueGuarantee.component -
            state.producerPremiumAmount.component +
            action.payload[0],
          class: state.expectedPriceFloorNet.class
        },
        expectedPriceFloorNetCwt: {
          component:
            state.revenueGuaranteeCwt.component -
            state.producerPremiumAmountCwt.component +
            action.payload[0] / (action.payload[1] / 100),
          class: state.expectedPriceFloorNetCwt.class
        }
      };
    default:
      return state;
  }
};
