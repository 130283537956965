import { addQuarters, getQuarter } from 'date-fns';
import { createSelector } from 'reselect';

import RevenueProjectionChartData from '../../types/Charts/RevenueProjectionChartData';
import { ProjectionAnalysis } from '../../types/ProjectionAnalysis';
import numberRange from '../../utils/numberRange';
import { getYearFromProps } from '../fromPropsSelector';
import { RootState } from '../store';

export const getProjectionData = (state: RootState): ProjectionAnalysis =>
  state.projectedData.table;

export const getProjectionDataSettings = (state: RootState) => state.projectedData.settings;

export const getProjectionNextYearData = (state: RootState): ProjectionAnalysis =>
  state.projectedData.nextYearTable;

export const getProjectionDataNextYearSettings = (state: RootState) =>
  state.projectedData.nextYearSettings;

export const getRevenueFloorData = createSelector(
  getProjectionData,
  getProjectionNextYearData,
  getYearFromProps,
  (current, future, selectedYear): RevenueProjectionChartData[] => {
    const startDate = new Date(selectedYear, 0, 1);

    return numberRange(0, 8).map(quarter => {
      const date = addQuarters(startDate, quarter);
      const year = date.getFullYear();
      const dateQuarter = getQuarter(date);

      type Quarter = 'q1' | 'q2' | 'q3' | 'q4';
      const quarterCode: Quarter = `q${dateQuarter}` as Quarter;
      const dataSource = selectedYear === year ? current : future;

      return {
        name: `Q${dateQuarter}-${year}`,

        revenueFloor: dataSource.revenueFloorProtected[quarterCode],
        revenueFloorCwt: dataSource.revenueFloorProtectedCwt[quarterCode],
        revenueFloorEffective: dataSource.revenueFloorProtectedEffective[quarterCode],
        revenueFloorEffectiveCwt: dataSource.revenueFloorProtectedEffectiveCwt[quarterCode],

        projectedRevenue: dataSource.projectedRevenue[quarterCode],
        projectedRevenueCwt: dataSource.projectedRevenueCwt[quarterCode],

        productionCost: dataSource.costOfProduction[quarterCode],
        productionCostCwt: dataSource.costOfProductionCwt[quarterCode],

        premium: Math.abs(dataSource.premium[quarterCode]),
        premiumCwt: Math.abs(dataSource.premiumCwt[quarterCode]),

        productionCostAndPremium:
          dataSource.costOfProduction[quarterCode] + Math.abs(dataSource.premium[quarterCode]),
        productionCostCwtAndPremiumCwt:
          dataSource.costOfProductionCwt[quarterCode] +
          Math.abs(dataSource.premiumCwt[quarterCode]),

        indemnityEstimate: dataSource.indemnityEstimate[quarterCode],
        indemnityEstimateCwt: dataSource.indemnityEstimateCwt[quarterCode]
      };
    });
  }
);

export const getProjectedProductionData = createSelector(
  getProjectionData,
  getProjectionNextYearData,
  getYearFromProps,
  (current, future, selectedYear) => {
    const startDate = new Date(selectedYear, 0, 1);

    return numberRange(0, 8).map(quarter => {
      const date = addQuarters(startDate, quarter);
      const year = date.getFullYear();
      const dateQuarter = getQuarter(date);

      type Quarter = 'q1' | 'q2' | 'q3' | 'q4';
      const quarterCode: Quarter = `q${dateQuarter}` as Quarter;
      const dataSource = selectedYear === year ? current : future;

      return {
        name: `Q${dateQuarter}-${year}`,
        projectedProduction: dataSource.milkProduction[quarterCode],
        protectedProduction: dataSource.milkProductionProtected[quarterCode],
        protectedProductionEffective: dataSource.milkProductionProtectedEffective[quarterCode],
        excessProtected: dataSource.excessProtectedProduction[quarterCode],
        excessProtectedEffective: dataSource.excessProtectedProductionEffective[quarterCode]
      };
    });
  }
);
