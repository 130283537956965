import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import reduxSaga from 'redux-saga';
import { StateType } from 'typesafe-actions';

import { unsubscribeReducer } from '../components/UnsubscribeNewsletter/reducer';
import whatsNewReducer from '../scenes/whatsnew/ducks/reducers';
import accountPreferencesReducer from './accountPreferences/reducer';
import { adminPageReducer } from './adminPage/reducers';
import { adminRootReducer } from './adminPanel/reducers';
import { agencyReducer } from './agency/reducer';
import { agencyLogoReducer } from './agencyLogo/reducer';
import { alertsReducer } from './alerts/reducer';
import { areasReducer } from './areas/reducer';
import { authenticationPageReducer } from './authentication/reducer';
import { calendarReducer } from './calendar/reducer';
import { dairyRevenueProtectionRootReducer } from './dairyRevenueProtection/reducers';
import salesStatisticsReducer from './drpSalesStatistics/reducer';
import educationModuleReducer from './educationModule/reducer';
import { endorsementRootReducer } from './endorsement/reducers';
import { historicalDataReducer } from './historicalData/reducer';
import { insurancePlanReducer } from './insurancePlan/reducer';
import { lgmRootReducer } from './lgm/reducer';
import { loadersReducer } from './loaders/reducer';
import lrpReducer from './lrp/reducer';
import { managementReportsReducer } from './managementReports/reducers';
import { operationContactReducer } from './operationContact/reducer';
import { operationFiltersReducer } from './operationFilters/reducer';
import { operationPageReducer } from './operationPage/reducer';
import { operationPreferencesReducer } from './operationPreferences/reducer';
import { priceReportReducer } from './priceReport/reducer';
import { priceTrendsReducer } from './priceTrends/reducer';
import { projectionDataReducer } from './projectionData/reducer';
import rootSaga from './rootSaga';
import { scenarioReducer } from './scenario/reducer';
import { scenarioAnalysisReducer } from './scenarioAnalysis/reducers';
import tagTypeReducer from './tagTypes/reducer';
import tagReducer from './tags/reducer';
import { userAgentReducer } from './userAgent/reducer';

const sagaMiddleware = reduxSaga();

const middleware = [sagaMiddleware];

export const rootReducer = combineReducers({
  areas: areasReducer,
  agency: agencyReducer,
  authentication: authenticationPageReducer,
  dairyRevenueProtection: dairyRevenueProtectionRootReducer,
  adminPage: adminPageReducer,
  adminPanel: adminRootReducer,
  loaders: loadersReducer,
  scenario: scenarioReducer,
  salesStatistics: salesStatisticsReducer,
  educationModule: educationModuleReducer,
  endorsement: endorsementRootReducer,
  operationPage: operationPageReducer,
  historicalData: historicalDataReducer,
  operationContact: operationContactReducer,
  agencyLogo: agencyLogoReducer,
  operationFilters: operationFiltersReducer,
  accountPreferences: accountPreferencesReducer,
  operationPreferences: operationPreferencesReducer,
  priceReport: priceReportReducer,
  priceTrends: priceTrendsReducer,
  alerts: alertsReducer,
  calendar: calendarReducer,
  projectedData: projectionDataReducer,
  lgm: lgmRootReducer,
  lrp: lrpReducer,
  scenarioAnalysis: scenarioAnalysisReducer,
  managementReports: managementReportsReducer,
  insurancePlan: insurancePlanReducer,
  whatsNew: whatsNewReducer,
  tags: tagReducer,
  tagTypes: tagTypeReducer,
  unsubscribe: unsubscribeReducer,
  userAgent: userAgentReducer
});

export type RootState = StateType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const store = createStore(
  rootReducer,
  process.env.NODE_ENV === 'production'
    ? applyMiddleware(...middleware)
    : composeEnhancers(applyMiddleware(...middleware))
);

export default store;

sagaMiddleware.run(rootSaga);
