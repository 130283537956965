import { formatISO } from 'date-fns';
import { DateTime } from 'luxon';

import { Language } from './language';
import { utcDateToLocalDate } from './utcDateToLocalDate';

type DateInput = string | Date | null | undefined;

const format = (dateInput: DateInput, options: Intl.DateTimeFormatOptions = {}): string => {
  const date = parseDateInput(dateInput);
  if (!date) {
    return '';
  }
  return new Intl.DateTimeFormat(Language.DEFAULT, options).format(date);
};

const parseDateInput = (input: DateInput): Date | null => {
  if (!input) {
    return null;
  }
  return typeof input === 'string' ? new Date(input) : input;
};

const formatDateYear = (dateInput: DateInput): string =>
  format(dateInput, {
    year: 'numeric'
  });

const formatDateMonth = (dateInput: DateInput): string =>
  format(dateInput, {
    month: 'long'
  });

const formatDateMonthShort = (dateInput: DateInput): string =>
  format(dateInput, {
    month: 'short'
  });

const formatDateDay = (dateInput: DateInput): string => format(dateInput, { day: 'numeric' });

const formatDateFullMonthDayYear = (dateInput: DateInput): string =>
  format(dateInput, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });

const formatDateYearMonth = (dateInput: DateInput): string =>
  format(dateInput, {
    year: 'numeric',
    month: 'short'
  });

const formatDateYearFullMonth = (dateInput: DateInput): string =>
  format(dateInput, {
    year: 'numeric',
    month: 'long'
  });

const formatDateMonthDay = (dateInput: DateInput): string =>
  format(dateInput, {
    month: 'short',
    day: 'numeric'
  });

const formatDateYearMonthDay = (dateInput: DateInput): string =>
  format(dateInput, {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

const formatDateYearMonthDayTime = (dateInput: DateInput, timeZone?: string): string =>
  format(dateInput, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZone
  });

const formatDateISO = (dateInput: DateInput): string => {
  const parsedDate = parseDateInput(dateInput);
  if (!parsedDate) return '';

  return formatISO(parsedDate, { representation: 'date' });
};

/**
 * Asserts wether if a given value is a string.
 *
 * @param {unknown} value - The value to be checked.
 * @return {boolean} Returns true if the value is a string, false otherwise.
 */
export const isString = (value: unknown): value is string => {
  return {}.toString.call(value) === '[object String]';
};

/**
 * Checks if a value is of type Date.
 *
 * @param {any} value - The value to be checked.
 * @return {boolean} Returns true if the value is an instance of Date, otherwise returns false.
 */
function isDate(value: any): value is Date {
  return value instanceof Date;
}

/**
 * Checks if the given value is a serialized date.
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} Returns true if the value is a serialized date, otherwise false.
 */
const isSerializedDate = (value: unknown): boolean => {
  const datePattern = /^\d{4}-\d{2}-\d{2}(T0{2}:0{2}:0{2,3}(.0+)?Z?)?$/;
  return isString(value) && datePattern.test(value);
};

/**
 * Checks if the given value is a serialized date and time string.
 *
 * @param {unknown} value - The value to check.
 * @return {boolean} Returns true if the value is a serialized date and time string, otherwise false.
 */
const isSerializedDateTime = (value: unknown): boolean => {
  const datePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2,3}(.\d+)?Z?)$/;
  return isString(value) && datePattern.test(value);
};

/**
 * A reviver function that converts serialized dates and date-times
 * to native JavaScript Date objects.
 *
 * @param {any} value - The JSON string to revive.
 * @return {any} - The revived date object.
 */
function reviveDate(value: any) {
  return JSON.parse(JSON.stringify(value), function (_key: string, value: unknown) {
    if (isSerializedDate(value)) {
      return utcDateToLocalDate(value as string);
    }
    if (isSerializedDateTime(value)) {
      return new Date(value as string);
    }
    return value;
  });
}

/**
 * Checks if the given value represents a time at midnight.
 *
 * @param {Date} value - The value to check.
 * @return {boolean} Returns true if the value represents a time at midnight, false otherwise.
 */
function isTimeAtMidnight(value: Date): boolean {
  return value.getHours() === 0 && value.getMinutes() === 0 && value.getSeconds() === 0;
}

/**
 * Sanitizes a given date by formatting it into the "YYYY-MM-DD" format.
 *
 * @param {Date} date - The date to be sanitized.
 * @return {string} The sanitized date in the "YYYY-MM-DD" format.
 */
function sanitizeDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
}

/**
 * Serializes a date object into a JSON string.
 *
 * @param {unknown} data - The date object to be serialized.
 * @return {unknown} The serialized JSON string.
 */
function serializeDate(data: unknown) {
  return JSON.parse(
    JSON.stringify(data, function (this: any, key: string, value: any): any {
      if (isDate(this[key])) {
        return isTimeAtMidnight(this[key]) ? sanitizeDate(this[key]) : this[key].toISOString();
      }
      return value;
    })
  );
}

const getCurrDateString = (time: string) => {
  const curDate = new Date();
  return `${curDate.getFullYear()}-${(curDate.getMonth() + 1).toString().padStart(2, '0')}-${curDate
    .getDate()
    .toString()
    .padStart(2, '0')}T${time}`;
};

const convertUTCToCentralTime = (time: string) => {
  const utcDateTime = DateTime.fromISO(getCurrDateString(time), { zone: 'UTC' });
  const centralTime = utcDateTime.setZone('America/Chicago');
  return centralTime.toFormat('HH:mm:ss');
};

const convertCentralTimeToUTC = (time: string) => {
  const centralDateTime = DateTime.fromISO(getCurrDateString(time), { zone: 'America/Chicago' });
  const utcTime = centralDateTime.setZone('UTC');
  return utcTime.toFormat('HH:mm:ss');
};

export {
  formatDateYear,
  formatDateDay,
  formatDateMonth,
  formatDateMonthShort,
  formatDateMonthDay,
  formatDateYearMonth,
  formatDateYearFullMonth,
  formatDateFullMonthDayYear,
  formatDateYearMonthDay,
  formatDateYearMonthDayTime,
  formatDateISO,
  isSerializedDate,
  reviveDate,
  serializeDate,
  sanitizeDate,
  convertUTCToCentralTime,
  convertCentralTimeToUTC
};
