import { isString } from '@/utils/dateFormatter';

import InsurancePlan from '../dto/InsurancePlan';

interface Operation {
  agencyName?: string;
  stateCode: string | null;
  countyCode: string | null;
  name: string;
  fullName: string;
  public: boolean;
  demo: boolean;
  isPublic: boolean;
  starred: boolean;
  isInMgmtReports: boolean;
  defaultInsuranceProgram: string;
  insurancePrograms: string[];
  bindRequirements?: BindRequirements;
}

export interface BindRequirements {
  canBind?: boolean;
  agencyBindingEnabled?: boolean;
  producerEligibleForBinding?: boolean;
  insurancePlanRequirements: InsurancePlanRequirements[];
}
export interface InsurancePlanRequirements {
  aipCode: string;
  agentEligibleForBinding: boolean;
  aipLinked: boolean;
  canBind: boolean;
  insurancePlan: string;
  isValidOperationEntityType: boolean;
  signatureText?: string;
  policyStateCode?: string;
}

export const operationCanBind = (operation: Operation, insurancePlan: InsurancePlan): boolean => {
  const insurancePlanRequirements = operationInsurancePlanRequirements(operation, insurancePlan);
  return (insurancePlanRequirements?.canBind && operation.bindRequirements?.canBind) ?? false;
};

export const operationInsurancePlanRequirements = (
  operation: Operation,
  insurancePlan: InsurancePlan
) => {
  let plan: string | undefined = undefined;
  if (typeof insurancePlan === 'number') {
    plan = InsurancePlan[insurancePlan].toLocaleLowerCase();
  }

  if (isString(insurancePlan)) {
    plan = (insurancePlan as string).toLocaleLowerCase();
  }

  const insurancePlanRequirements = operation.bindRequirements?.insurancePlanRequirements?.find(
    obj => {
      return obj.insurancePlan.toLowerCase() === plan;
    }
  );
  return insurancePlanRequirements;
};

export default Operation;
