import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { HistoricalData } from '../../types/HistoricalData';
import { OperationPageActions, operationPageActions } from '../operationPage/actions';
import { HistoricalDataActions, historicalDataActions } from './actions';

const initialState: HistoricalData[] = [];

export const historicalDataReducer = (
  state = initialState,
  action: HistoricalDataActions | OperationPageActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(historicalDataActions.addAll): {
        return action.payload;
      }
      case getType(operationPageActions.setOperationName): {
        return [];
      }
      case getType(historicalDataActions.addNewRows): {
        const records = [...state];
        action.payload.forEach(record => {
          const recordIndex = records.findIndex(
            r => r.year === record.year && record.month === r.month
          );
          if (recordIndex === -1) {
            records.push(record);
          } else {
            records[recordIndex] = { ...records[recordIndex], isEditing: true };
          }
        });
        draft = records;
        return draft;
      }
      case getType(historicalDataActions.remove): {
        const elementIndex = state.findIndex(
          e => e.year === action.payload.year && e.month === action.payload.month
        );
        if (elementIndex !== -1) {
          draft.splice(elementIndex, 1);
        }
        return;
      }
      case getType(historicalDataActions.clear): {
        const elementIndex = state.findIndex(
          e => e.year === action.payload.year && e.month === action.payload.month
        );
        if (elementIndex !== -1) {
          draft.splice(elementIndex, 1);
        }
        return;
      }
      case getType(historicalDataActions.updateRecord): {
        const elementIndex = state.findIndex(
          e => e.year === action.payload.year && e.month === action.payload.month
        );
        if (elementIndex !== -1) {
          draft[elementIndex] = action.payload;
        } else {
          draft.push(action.payload);
        }
        return;
      }
    }
  });
