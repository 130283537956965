import * as React from 'react';
import styled from 'styled-components/macro';

import { Units } from '../../../../../types/Units';
import color from '../../../../../utils/color';
import { formatNumber } from '../../../../../utils/numberFormatter';
import numberRange from '../../../../../utils/numberRange';
import { Unit } from './HistoricalPricesTable';

export enum PriceType {
  GLOBAL_MIN,
  MIN,
  NEUTRAL,
  MAX,
  GLOBAL_MAX
}

export const renderTable = (row: any, monthNumber: number, unit: number) => {
  let isGray = false;
  const renderElements = [];
  const scale = unit === Unit.TOTAL ? 0 : 2;
  renderElements.push(
    <TDYear key={'year-index'}>{row.year !== undefined ? row.year : ' '}</TDYear>
  );
  numberRange(1, 13).forEach(monthIncrement => {
    isGray = false;

    const isMonthNumberEleven =
      monthNumber === 11 &&
      (monthIncrement === 11 || monthIncrement === 12 || monthIncrement === 1);
    const isMonthNumberTwelve =
      monthNumber === 12 && (monthIncrement === 12 || monthIncrement === 1 || monthIncrement === 2);
    const isMonthOther = monthIncrement >= monthNumber && monthIncrement <= monthNumber + 2;

    if (isMonthNumberEleven) {
      isGray = true;
    } else if (isMonthNumberTwelve) {
      isGray = true;
    } else if (isMonthOther) {
      isGray = true;
    }

    renderElements.push(
      <React.Fragment>
        <TD
          style={{ backgroundColor: `${isGray ? '#f7f7f7' : '#ffffff'}` }}
          type={row[monthIncrement].type}
          key={`${monthIncrement}-index`}
          data-cy={'renderHistoricalPricesRow_' + row.year + '_' + monthIncrement}
        >
          {row[monthIncrement].price !== undefined && row[monthIncrement].price !== 0
            ? formatNumber(scale)(Number(row[monthIncrement].price), Units.DOLLAR)
            : isGray
            ? ''
            : 'N/A'}
        </TD>
      </React.Fragment>
    );
  });
  return renderElements;
};

const TD = styled.td<{ type: PriceType }>`
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 10px;
  text-align: right;
  font-size: 12px;
  font-weight: ${({ type }) =>
    type === PriceType.GLOBAL_MIN || type === PriceType.GLOBAL_MAX ? 600 : 400};
  text-decoration: ${({ type }) =>
    type === PriceType.GLOBAL_MIN || type === PriceType.GLOBAL_MAX ? 'underline' : 'none'};
  color: ${({ type }) => {
    switch (type) {
      case PriceType.GLOBAL_MAX:
      case PriceType.MAX:
        return color.PRIMARY;
      case PriceType.GLOBAL_MIN:
      case PriceType.MIN:
        return color.NEGATIVE;
      default:
        return color.TEXT_PRIMARY;
    }
  }};
`;

const TDYear = styled.td`
  padding-right: 10px;
  padding-top: 10px;
  background-color: #efefef;
  text-align: right;
  font-weight: 600;
  font-size: 12px;
`;
