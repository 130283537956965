import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { SagaReturnType, all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { reviveDate } from '@/utils/dateFormatter';

import insuranceAPI from '../../../services/insuranceService';
import { Endorsement } from '../../../types/Endorsement/Endorsement';
import ScenarioWhatIfPrices from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { DrpEndorsementDto } from '../../../types/dto/EndorsementDto';
import { EndorsementSummaryDto } from '../../../types/dto/EndorsementSummaryDto';
import { mapEndorsementSummaryDtoToEndorsementSummary } from '../../endorsement/utils/mapEndorsementSummaryDtoToEndorsementSummary';
import { loadersActions } from '../../loaders/actions';
import { getOperationName } from '../../operationPage/selectors';
import { scenarioAnalysisActions } from '../actions';
import { getCurrentWhatIfScenario, getScenarioWhatIfPrices } from '../selectors';

function* getScenarioEndorsementSummary(): any {
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);

  try {
    yield put(loadersActions.endorsementSummary.activate());
    const data: AxiosResponse<EndorsementSummaryDto[]> = yield call(
      insuranceAPI.getScenarioEndorsementSummary,
      operation,
      scenarioName
    );
    const mappedData = mapEndorsementSummaryDtoToEndorsementSummary(data);
    yield put(scenarioAnalysisActions.saveScenarioEndorsementsSummary(mappedData));
  } catch (error) {
    console.error(error);
    yield put(scenarioAnalysisActions.saveScenarioEndorsementsSummary([]));
  } finally {
    yield put(loadersActions.endorsementSummary.disable());
  }
}

function* getScenarioEndorsementsByQuarter(
  action: ActionType<typeof scenarioAnalysisActions.getScenarioEndorsementsByQuarter>
): any {
  const operationName = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  yield put(loadersActions.endorsementDetailedView.activate());

  try {
    const endorsements: AxiosResponse<DrpEndorsementDto[]> = yield call(
      insuranceAPI.getScenarioEndorsementsByQuarter,
      operationName,
      scenarioName,
      action.payload.year,
      action.payload.quarter
    );

    yield put(
      scenarioAnalysisActions.saveScenarioEndorsmentsByQuarter(reviveDate(endorsements.data))
    );
  } catch (e) {
    console.error(e);
    yield put(scenarioAnalysisActions.saveScenarioEndorsmentsByQuarter([]));
  } finally {
    yield put(loadersActions.endorsementDetailedView.disable());
  }
}

function* createEndorsement(
  action: ActionType<typeof scenarioAnalysisActions.createEndorsement>
): any {
  yield put(loadersActions.spinner.activate());
  const { endorsement, quarter, reinsuranceYear } = action.payload;
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);

  try {
    yield call(insuranceAPI.createScenarioEndorsement, operation, scenarioName, endorsement);
    yield all([
      put(scenarioAnalysisActions.getScenarioEndorsementsSummary()),
      put(
        scenarioAnalysisActions.getScenarioEndorsementsByQuarter({ year: reinsuranceYear, quarter })
      )
    ]);
    yield action.meta();
    toast.success('Scenario Endorsement Added');
  } catch (error: any) {
    if (error.message === 'Request failed with status code 409') {
      toast.error(
        'An endorsement with the same Quarter, Sales Effective Date and Type already exists.'
      );
    } else {
      yield action.meta();
      console.log(error);
    }
  } finally {
    yield put(loadersActions.spinner.disable());
  }

  yield put(
    scenarioAnalysisActions.recalculateScenarioEndorsements({ year: reinsuranceYear, quarter })
  );
}

function* editEndorsement(action: ActionType<typeof scenarioAnalysisActions.editEndorsement>): any {
  const operation = yield select(getOperationName);
  const { data, id, reinsuranceYear, quarter } = action.payload;
  const scenarioName = yield select(getCurrentWhatIfScenario);

  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.updateScenarioEndorsement, operation, scenarioName, id, data);
    action.meta();
    yield put(
      scenarioAnalysisActions.getScenarioEndorsementsByQuarter({ year: reinsuranceYear, quarter })
    );
    yield put(scenarioAnalysisActions.getScenarioEndorsementsSummary());
    toast.success('Endorsement Edited');
  } catch (error) {
    console.error(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* deleteEndorsement(
  action: ActionType<typeof scenarioAnalysisActions.deleteEndorsement>
): any {
  const id = action.payload;
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.deleteScenarioEndorsement, operation, scenarioName, id);
    yield put(scenarioAnalysisActions.removeEndorsement(id));
    yield put(scenarioAnalysisActions.getScenarioEndorsementsSummary());
    toast.success('Endorsement Deleted');
  } catch (error) {
    console.error(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta();
  }
}

type OperationType = SagaReturnType<typeof getOperationName>;
type ScenarioNameType = SagaReturnType<typeof getCurrentWhatIfScenario>;
function* initEditEndorsement(
  action: ActionType<typeof scenarioAnalysisActions.endorsementModal.editInit>
) {
  const operation: OperationType = yield select(getOperationName);
  const scenarioName: ScenarioNameType = yield select(getCurrentWhatIfScenario);
  try {
    const endorsementData: AxiosResponse<Endorsement> = yield call(
      insuranceAPI.getScenarioEndorsement,
      operation,
      scenarioName,
      action.payload
    );
    yield put(
      scenarioAnalysisActions.endorsementModal.populateStateWithExisitingEndorsement(
        endorsementData.data
      )
    );
    yield put(
      scenarioAnalysisActions.endorsementModal.setModalCalendarDate(
        reviveDate(endorsementData.data.salesEffectiveDate)
      )
    );
  } catch (error: unknown) {
    console.error(error);
  }
}

function* resetWhatIf(action: ActionType<typeof scenarioAnalysisActions.resetWhatIf>): any {
  const operationName = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  const { resetEndorsements, resetPrices, resetData, reinsuranceYear, quarter, callback } =
    action.payload;
  try {
    yield call(
      insuranceAPI.resetWhatIf,
      operationName,
      scenarioName,
      resetEndorsements,
      resetPrices,
      resetData
    );
    if (resetPrices === true) {
      const whatIfPrices: AxiosResponse<ScenarioWhatIfPrices> = yield call(
        insuranceAPI.getWhatIfScenario,
        operationName,
        scenarioName
      );
      yield put(scenarioAnalysisActions.saveScenarioWhatIfPrices(whatIfPrices.data));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield callback();
  }
  yield put(
    scenarioAnalysisActions.recalculateScenarioEndorsements({ year: reinsuranceYear, quarter })
  );
}

function* recalculateScenarioEndorsements(
  action: ActionType<typeof scenarioAnalysisActions.recalculateScenarioEndorsements>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operationName = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);

  const whatIfPrices = yield select(getScenarioWhatIfPrices);
  const newWhatIfPrices = {
    butterfatPrice: whatIfPrices.butterfatPrice,
    proteinPrice: whatIfPrices.proteinPrice,
    otherSolidsPrice: whatIfPrices.otherSolidsPrice,
    nonfatSolidsPrice: whatIfPrices.nonfatSolidsPrice,
    milkProduction: whatIfPrices.milkProduction,
    reinsuranceYear: whatIfPrices.reinsuranceYear,
    butterPrice: whatIfPrices.butterPrice,
    cheesePrice: whatIfPrices.cheesePrice,
    dryWheyPrice: whatIfPrices.dryWheyPrice,
    nonfatDryMilkPrice: whatIfPrices.nonfatDryMilkPrice,
    classIIIPrice: whatIfPrices.classIIIPrice,
    classIVPrice: whatIfPrices.classIVPrice
  };
  try {
    yield call(insuranceAPI.putWhatIfScenario, operationName, scenarioName, newWhatIfPrices);
    toast.success('Scenario Saved');
  } catch (e) {
    console.error(e);
  }

  try {
    yield call(insuranceAPI.recalculateScenarioEndorsements, operationName, scenarioName);
    yield all([
      put(scenarioAnalysisActions.getScenarioEndorsementsSummary()),
      put(scenarioAnalysisActions.getScenarioEndorsementsByQuarter(action.payload)),
      put(scenarioAnalysisActions.projectedRevenue.get(action.payload.year, {}))
    ]);
  } catch (e) {
    console.error(e);
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(false));
    yield put(loadersActions.scenarioAnalysis.disable());
    toast.success('Recalculated Scenario');
  }
}

function* scenarioAnalysisEndorsementSagaWatcher() {
  yield all([
    takeLatest(
      getType(scenarioAnalysisActions.getScenarioEndorsementsSummary),
      getScenarioEndorsementSummary
    ),
    takeLatest(getType(scenarioAnalysisActions.createEndorsement), createEndorsement),
    takeLatest(getType(scenarioAnalysisActions.editEndorsement), editEndorsement),
    takeLatest(getType(scenarioAnalysisActions.deleteEndorsement), deleteEndorsement),
    takeLatest(getType(scenarioAnalysisActions.endorsementModal.editInit), initEditEndorsement),
    takeLatest(
      getType(scenarioAnalysisActions.getScenarioEndorsementsByQuarter),
      getScenarioEndorsementsByQuarter
    ),
    takeLatest(getType(scenarioAnalysisActions.resetWhatIf), resetWhatIf),
    takeLatest(
      getType(scenarioAnalysisActions.recalculateScenarioEndorsements),
      recalculateScenarioEndorsements
    )
  ]);
}

export default scenarioAnalysisEndorsementSagaWatcher;
