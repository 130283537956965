import * as ModalPrimitive from '@radix-ui/react-dialog';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components/macro';

import color from '@/utils/color';

const Modal: typeof ModalPrimitive.Root = props => (
  <ModalProvider isInsideDialog={true}>
    <ModalPrimitive.Root {...props} />
  </ModalProvider>
);

type ModalContextValue = {
  isInsideDialog: boolean;
};

const ModalContext = React.createContext<ModalContextValue>({
  isInsideDialog: false
});

const ModalProvider = ({
  children,
  isInsideDialog
}: React.PropsWithChildren<ModalContextValue>) => {
  const value = React.useMemo(() => ({ isInsideDialog }), []);
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

const ModalTrigger = ModalPrimitive.Trigger;
const ModalPortal = ModalPrimitive.Portal;
const ModalClose = ModalPrimitive.Close;

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ ...props }, ref) => <StyledModalOverlay ref={ref} {...props} />);
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName;

const StyledModalOverlay = styled(ModalPrimitive.Overlay)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(2px);

  &[data-state='open'] {
    animation: animateIn 0.3s ease-in-out;
    opacity: 1;
  }

  &[data-state='closed'] {
    animation: animateOut 0.3s ease-in-out;
    opacity: 0;
  }
`;

const ModalContent = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content>
>(({ children, ...props }, ref) => (
  <ModalPortal>
    <ModalOverlay />
    <StyledModalContent ref={ref} onPointerDownOutside={e => e.preventDefault()} {...props}>
      {children}
      <StyledModalClose>
        <FaTimes style={{ width: '1rem', height: '1rem' }} />
      </StyledModalClose>
    </StyledModalContent>
  </ModalPortal>
));
ModalContent.displayName = ModalPrimitive.Content.displayName;

const StyledModalContent = styled(ModalPrimitive.Content)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
  z-index: 9999;
  display: grid;
  width: 100%;
  max-width: 32rem;
  gap: 1rem;
  border: 1px solid ${color.NEUTRAL};
  background-color: #fff;
  padding: 1.5rem;
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition-duration: 200ms;

  &[data-state='open'] {
    animation: animateIn 0.3s ease-in-out;
    opacity: 1;
    transform: scale(1);
  }

  &[data-state='closed'] {
    animation: animateOut 0.3s ease-in-out;
    opacity: 0;
    transform: scale(0.95);
  }
`;

const StyledModalClose = styled(ModalPrimitive.Close)`
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 0.125rem;
  opacity: 0.7;
  transition: opacity 0.15s ease-in-out;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    pointer-events: none;
  }
`;

const ModalHeader = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <StyledHeaderDiv {...props} />
);
ModalHeader.displayName = 'ModalHeader';

const StyledHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  text-align: left;
`;

const ModalFooter = ({ ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <StyledFooterDiv {...props} />
);
ModalFooter.displayName = 'ModalFooter';

const StyledFooterDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ ...props }, ref) => <StyledModalTitle ref={ref} {...props} />);
ModalTitle.displayName = ModalPrimitive.Title.displayName;

const StyledModalTitle = styled(ModalPrimitive.Title)`
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  letter-spacing: -0.025em;
`;

const ModalDescription = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ ...props }, ref) => <StyledModalDescription ref={ref} {...props} />);
ModalDescription.displayName = ModalPrimitive.Description.displayName;

const StyledModalDescription = styled(ModalPrimitive.Description)`
  font-size: 0.875rem;
  color: #a0aec0;
`;

export {
  Modal,
  ModalContext,
  ModalPortal,
  ModalOverlay,
  ModalClose,
  ModalTrigger,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalDescription
};
