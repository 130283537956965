import { AxiosResponse } from 'axios';

import { PreviousOrCurrent } from '../../types/WhatIfBody';
import { InsuranceDto, MonthPrice } from '../../types/dto/InsuranceDto';

const mapExpectedMonthPrices = ({ date, expectedPrice }: MonthPrice) => ({
  date: new Date(date),
  value: expectedPrice
});
const mapActualMonthPrices = ({ actualPrice }: MonthPrice) => actualPrice;

export const mapWhatIfPricesDto = ({
  classIIIPrice,
  classIVPrice,
  butterPrice,
  cheesePrice,
  dryWheyPrice,
  butterfatPrice,
  proteinPrice,
  otherSolidsPrice,
  nonfatDryMilkPrice,
  nonfatSolidsPrice
}: PreviousOrCurrent) => ({
  whatIfDairyValues: {
    butter: butterPrice.monthPrices ? butterPrice.monthPrices.map(mapExpectedMonthPrices) : [],
    cheese: cheesePrice.monthPrices ? cheesePrice.monthPrices.map(mapExpectedMonthPrices) : [],
    dryWhey: dryWheyPrice.monthPrices ? dryWheyPrice.monthPrices.map(mapExpectedMonthPrices) : [],
    noFatDryMilk: nonfatDryMilkPrice.monthPrices
      ? nonfatDryMilkPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    classIIIMilk: classIIIPrice.monthPrices
      ? classIIIPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    classIVMilk: classIVPrice.monthPrices
      ? classIVPrice.monthPrices.map(mapExpectedMonthPrices)
      : []
  },
  whatIfInputs: {
    butter: butterPrice.monthPrices ? butterPrice.monthPrices.map(mapActualMonthPrices) : [],
    cheese: cheesePrice.monthPrices ? cheesePrice.monthPrices.map(mapActualMonthPrices) : [],
    dryWhey: dryWheyPrice.monthPrices ? dryWheyPrice.monthPrices.map(mapActualMonthPrices) : [],
    noFatDryMilk: nonfatDryMilkPrice.monthPrices
      ? nonfatDryMilkPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    classIIIMilk: classIIIPrice.monthPrices
      ? classIIIPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    classIVMilk: classIVPrice.monthPrices ? classIVPrice.monthPrices.map(mapActualMonthPrices) : []
  },
  whatIfExpectedActual: {
    butterfat: { expected: butterfatPrice.expectedPrice, actual: butterfatPrice.actualPrice },
    protein: { expected: proteinPrice.expectedPrice, actual: proteinPrice.actualPrice },
    otherSolidsPrice: {
      expected: otherSolidsPrice.expectedPrice,
      actual: otherSolidsPrice.actualPrice
    },
    nonfatSolidsPrice: nonfatSolidsPrice
      ? { expected: nonfatSolidsPrice.expectedPrice, actual: nonfatSolidsPrice.actualPrice }
      : null,
    classIIIPrice: { expected: classIIIPrice.expectedPrice, actual: classIIIPrice.actualPrice },
    classIVPrice: { expected: classIVPrice.expectedPrice, actual: classIVPrice.actualPrice }
  }
});

const mapInsuranceDto = ({
  data: { component, class: classData, whatIf, indemnity }
}: AxiosResponse<InsuranceDto>) => ({
  whatIfDairyValues: {
    butter: whatIf.butterPrice.monthPrices
      ? whatIf.butterPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    cheese: whatIf.cheesePrice.monthPrices
      ? whatIf.cheesePrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    dryWhey: whatIf.dryWheyPrice.monthPrices
      ? whatIf.dryWheyPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    noFatDryMilk: whatIf.nonfatDryMilkPrice.monthPrices
      ? whatIf.nonfatDryMilkPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    classIIIMilk: whatIf.classIIIPrice.monthPrices
      ? whatIf.classIIIPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    classIVMilk: whatIf.classIVPrice.monthPrices
      ? whatIf.classIVPrice.monthPrices.map(mapExpectedMonthPrices)
      : [],
    milkProductionPerCow: {
      date: whatIf.butterfatPrice.monthPrices
        ? whatIf.butterfatPrice.monthPrices[0].date
        : new Date(),
      value: whatIf.milkProduction.expectedPrice
    }
  },
  whatIfInputs: {
    butter: whatIf.butterPrice.monthPrices
      ? whatIf.butterPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    cheese: whatIf.cheesePrice.monthPrices
      ? whatIf.cheesePrice.monthPrices.map(mapActualMonthPrices)
      : [],
    dryWhey: whatIf.dryWheyPrice.monthPrices
      ? whatIf.dryWheyPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    noFatDryMilk: whatIf.nonfatDryMilkPrice.monthPrices
      ? whatIf.nonfatDryMilkPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    classIIIMilk: whatIf.classIIIPrice.monthPrices
      ? whatIf.classIIIPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    classIVMilk: whatIf.classIVPrice.monthPrices
      ? whatIf.classIVPrice.monthPrices.map(mapActualMonthPrices)
      : [],
    milkProductionPerCow: whatIf.milkProduction.actualPrice,
    actualButterfatTest: indemnity.butterfatTestActual,
    actualProteinTest: indemnity.proteinTestActual
  },
  whatIfExpectedActual: {
    butterfat: {
      expected: whatIf.butterfatPrice.expectedPrice,
      actual: whatIf.butterfatPrice.actualPrice
    },
    protein: {
      expected: whatIf.proteinPrice.expectedPrice,
      actual: whatIf.proteinPrice.actualPrice
    },
    otherSolidsPrice: {
      expected: whatIf.otherSolidsPrice.expectedPrice,
      actual: whatIf.otherSolidsPrice.actualPrice
    },
    nonfatSolidsPrice: whatIf.nonfatSolidsPrice
      ? {
          expected: whatIf.nonfatSolidsPrice.expectedPrice,
          actual: whatIf.nonfatSolidsPrice.actualPrice
        }
      : null,
    classIIIPrice: {
      expected: whatIf.classIIIPrice.expectedPrice,
      actual: whatIf.classIIIPrice.actualPrice
    },
    classIVPrice: {
      expected: whatIf.classIVPrice.expectedPrice,
      actual: whatIf.classIVPrice.actualPrice
    }
  },
  whatIfDairyComponents: {
    expectedDRPRevenueCwt: {
      component: component.expectedRevenueCwt,
      class: classData.expectedRevenueCwt
    },
    drpRevenueGuarantee: {
      component: component.revenueGuarantee,
      class: classData.revenueGuarantee
    },
    drpRevenueGuaranteeCwt: {
      component: component.revenueGuaranteeCwt,
      class: classData.revenueGuaranteeCwt
    },
    actualRevenue: { component: component.actualRevenue, class: classData.actualRevenue },
    actualRevenueCwt: { component: component.actualRevenueCwt, class: classData.actualRevenueCwt },
    grossIndemnity: { component: 1, class: 1 },
    grossIndemnityCwt: { component: 1, class: 1 }
  },
  priceComponents: {
    expectedRevenue: { component: component.expectedRevenue, class: classData.expectedRevenue },
    revenueGuarantee: { component: component.revenueGuarantee, class: classData.revenueGuarantee },
    revenueGuaranteeCwt: {
      component: component.revenueGuaranteeCwt,
      class: classData.revenueGuaranteeCwt
    },
    premiumBeforeSubsidy: { component: component.premium, class: classData.premium },
    premiumBeforeSubsidyCwt: { component: component.premiumCwt, class: classData.premiumCwt },
    subsidy: { component: component.subsidy, class: classData.subsidy },
    subsidyCwt: { component: component.subsidyCwt, class: classData.subsidyCwt },
    producerPremiumAmount: {
      component: component.producerPremium,
      class: classData.producerPremium
    },
    producerPremiumAmountCwt: {
      component: component.producerPremiumCwt,
      class: classData.producerPremiumCwt
    },
    expectedPriceFloorNetCwt: {
      component: component.expectedPriceFloorNetCwt,
      class: classData.expectedPriceFloorNetCwt
    },
    expectedPriceFloorNet: {
      component: component.expectedPriceFloorNet,
      class: classData.expectedPriceFloorNet
    },
    probabilityOfPositiveIndemnity: {
      component: component.probabilityOfPostiveIndemnity,
      class: classData.probabilityOfPostiveIndemnity
    },
    probabilityOfPositiveNetBenefit: {
      component: component.probabilityOfPositiveNetBenefit,
      class: classData.probabilityOfPositiveNetBenefit
    },
    expectedPriceFloor: {
      component: component.revenueGuarantee - component.producerPremium,
      class: classData.revenueGuarantee - classData.producerPremium
    },
    expectedPriceFloorCwt: {
      component: component.revenueGuaranteeCwt - component.producerPremiumCwt,
      class: classData.revenueGuaranteeCwt - classData.producerPremiumCwt
    },
    basis: { component: component.basis, class: classData.basis },
    basisCwt: { component: component.basisCwt, class: classData.basisCwt },
    costOfProduction: { component: component.costOfProduction, class: classData.costOfProduction },
    costOfProductionCwt: {
      component: component.costOfProductionCwt,
      class: classData.costOfProductionCwt
    },
    milkProductionEffective: {
      component: component.milkProductionEffective,
      class: classData.milkProductionEffective
    },
    milkProductionEffectiveCwt: {
      component: component.milkProductionEffectiveCwt,
      class: classData.milkProductionEffectiveCwt
    },
    expectedYield: { component: component.expectedYield, class: classData.expectedYield }
  },
  whatIf
});

export default mapInsuranceDto;
