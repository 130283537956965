import { getQuarter, parse } from 'date-fns';
import { createSelector } from 'reselect';

import { Unit } from '../../containers/PremiumsContainer/PremiumsContainer';
import { Endorsement, Production } from '../../types/Endorsement/EndorsementSummary';
import ScenarioAnalysisEndorsement from '../../types/ScenarioWhatIf/ScenarioAnalysisEndorsement';
import {
  ScenarioWhatIfPriceElement,
  ScenarioWhatIfTableData
} from '../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { Units } from '../../types/Units';
import { formatNumber } from '../../utils/numberFormatter';
import numberRange from '../../utils/numberRange';
import { getStatesList } from '../areas/selectors';
import { getAllEndorsementSummaries, getAllEndorsements } from '../endorsement/selectors';
import { getUnitFromProps, getYearFromProps } from '../fromPropsSelector';
import { getOperationName } from '../operationPage/selectors';
import { RootState } from '../store';

export const getScenarioEndorsementModalSettings = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal;
export const getScenarioWhatIfPrices = (state: RootState) =>
  state.scenarioAnalysis.scenarioWhatIf.whatIfPrices;
export const getWhatIfScenarios = (state: RootState) => state.scenarioAnalysis.scenarios.scenarios;
export const getCurrentWhatIfScenario = (state: RootState) =>
  state.scenarioAnalysis.scenarios.currentScenario;

export const getIsScenarioWhatIfPriceChanged = (state: RootState) =>
  state.scenarioAnalysis.scenarioWhatIf.isWhatIfChanged;
export const getIsWhatIfEditingActive = (state: RootState) =>
  state.scenarioAnalysis.scenarioWhatIf.isEditing;

export const getScenarioAnalysisData = (state: RootState) => state.scenarioAnalysis.data;

const scenarioSummaries = (state: RootState) => state.scenarioAnalysis.endorsementsData.summaries;
const scenarioEndorsements = (state: RootState) =>
  state.scenarioAnalysis.endorsementsData.endorsements;

export const getScenarioEndorsementState = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.state;
export const getScenarioEndorsementQuarter = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.quarter;
export const getScenarioEndorsementShare = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.share;
export const getScenarioEndorsementCanSell = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.canSell;
export const getScenarioEndorsementCanSellNote = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.canSellNote;
export const getScenarioEndorsementDeclaredMilkProduction = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.declaredMilkProduction;
export const getScenarioEndorsementSalesEffectiveDate = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.salesEffectiveDate;
export const getScenarioEndorsementCalendarDate = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.calendarDate;
export const getScenarioEndorsementCoverageLevel = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.coverageLevel;
export const getScenarioEndorsementDeclaredButterfatTest = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.declaredButterfatTest;
export const getScenarioEndorsementProtectionFactor = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.protectionFactor;
export const getScenarioEndorsementDeclaredProteinTest = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.declaredProteinTest;
export const getScenarioEndorsementDeclaredClassPriceWeightingFactor = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.declaredClassPriceWeightingFactor;
export const getScenarioEndorsementDeclaredComponentPriceWeightingFactor = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.declaredComponentPriceWeightingFactor;
export const getScenarioEndorsementQuarterList = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.quarterList;
export const getScenarioEndorsementIsSalesDateAvailable = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.isSalesDateAvailable;
export const getScenarioEndorsementReinsuranceYear = (state: RootState) =>
  state.scenarioAnalysis.endorsementModal.reinsuranceYear;

export const getScenarioEndorsementCalculatedValues = (state: RootState) => ({
  expectedRevenue: state.scenarioAnalysis.endorsementModal.expectedRevenue,
  revenueGuarantee: state.scenarioAnalysis.endorsementModal.revenueGuarantee,
  producerPremium: state.scenarioAnalysis.endorsementModal.producerPremium,
  expectedRevenueCwt: state.scenarioAnalysis.endorsementModal.expectedRevenueCwt,
  revenueGuaranteeCwt: state.scenarioAnalysis.endorsementModal.revenueGuaranteeCwt,
  producerPremiumCwt: state.scenarioAnalysis.endorsementModal.producerPremiumCwt
});

export const getScenarioEndorsementStateName = createSelector(
  getStatesList,
  getScenarioEndorsementState,
  (list, id) => {
    const state = list[list.findIndex(({ stateCode }) => Number(stateCode) === id)];
    if (state !== undefined) {
      return state.stateName;
    }
    return '';
  }
);

export const getScenarioEndorsementQuarterName = createSelector(
  getScenarioEndorsementQuarterList,
  getScenarioEndorsementQuarter,
  (list, id) => {
    if (list.length === 0) {
      return 'none';
    }
    const quarter = list[list.findIndex(({ quarter }) => quarter === id)];
    if (!quarter) {
      return 'none';
    }
    return quarter.quarter;
  }
);

export const getScenarioEndorsementQuarterSelectList = createSelector(
  getScenarioEndorsementQuarterList,
  list => list.map(({ quarter, practiceCode }) => ({ name: quarter, id: practiceCode }))
);

export const getWhatIfScenarioNames = createSelector(getWhatIfScenarios, scenarios =>
  scenarios.map(name => ({ id: name, name }))
);

export const getScenarioAnalysisSummary = createSelector(
  getAllEndorsementSummaries,
  scenarioSummaries,
  getOperationName,
  getYearFromProps,
  (endorsementSummaries, scenarioSummaries, operation, selectedYear) => {
    if (endorsementSummaries[operation] === undefined) {
      return [];
    }

    if (scenarioSummaries.length === 0 && endorsementSummaries[operation].length === 0) {
      return [];
    }

    return numberRange(1, 5)
      .map(quarter => {
        const actualIndex = endorsementSummaries[operation].findIndex(
          e => e.year === selectedYear && e.quarter === quarter
        );
        const scenarioIndex = scenarioSummaries.findIndex(
          e => e.year === selectedYear && e.quarter === quarter
        );
        return {
          year: selectedYear,
          quarter: quarter,
          endorsementSummary: {
            production:
              actualIndex !== -1 ? endorsementSummaries[operation][actualIndex].production : null,
            endorsement:
              actualIndex !== -1 ? endorsementSummaries[operation][actualIndex].endorsement : null
          },
          scenarioSummary: {
            production: scenarioIndex !== -1 ? scenarioSummaries[scenarioIndex].production : null,
            endorsement: scenarioIndex !== -1 ? scenarioSummaries[scenarioIndex].endorsement : null
          }
        };
      })
      .sort((s1, s2) => {
        if (s1.year < s2.year) {
          return -1;
        }
        if (s1.year > s2.year) {
          return 1;
        }
        if (s1.year === s2.year) {
          if (s1.quarter < s2.quarter) {
            return -1;
          }
          if (s1.quarter > s2.quarter) {
            return 1;
          }
          return 0;
        }
        return 0;
      });
  }
);

export const getScenarioAnalysisEndorsements = createSelector(
  getAllEndorsements,
  scenarioEndorsements,
  getOperationName,
  (realEndorsements, scenarioEndorsements, operationName) => {
    if (!realEndorsements[operationName]) {
      return [];
    }

    const scenarioEndorsementsNew = [...scenarioEndorsements];
    const allEndorsements: ScenarioAnalysisEndorsement[] = [];

    realEndorsements[operationName].forEach(e => {
      const index = scenarioEndorsementsNew.findIndex(
        s =>
          s.stateCode === e.stateCode &&
          s.practiceCode === e.practiceCode &&
          s.milkProduction === e.milkProduction &&
          s.salesEffectiveDate === e.salesEffectiveDate &&
          s.coverageLevel === e.coverageLevel &&
          s.butterfatTest === e.butterfatTest &&
          s.proteinTest === e.proteinTest &&
          s.protectionFactor === e.protectionFactor &&
          s.componentPriceWeightingFactor === e.componentPriceWeightingFactor &&
          s.classPriceWeightingFactor === e.classPriceWeightingFactor
      );
      if (index !== -1) {
        allEndorsements.push({
          realEndorsement: e,
          scenarioEndorsement: scenarioEndorsementsNew[index]
        });
        scenarioEndorsementsNew.splice(index, 1);
      } else {
        allEndorsements.push({ realEndorsement: e, scenarioEndorsement: null });
      }
    });

    scenarioEndorsementsNew.forEach(e =>
      allEndorsements.push({ realEndorsement: null, scenarioEndorsement: e })
    );

    return allEndorsements;
  }
);

export const getScenarioWhatIfTablePrices = createSelector(
  getScenarioWhatIfPrices,
  getYearFromProps,
  (whatIfPrices, year) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { reinsuranceYear, ...prices } = whatIfPrices;

    const sortedPrices: ScenarioWhatIfTableData = {
      yearPrices: {
        butterfatPrice: undefined,
        proteinPrice: undefined,
        otherSolidsPrice: undefined,
        nonfatSolidsPrice: undefined,
        milkProduction: undefined,
        butterPrice: undefined,
        cheesePrice: undefined,
        dryWheyPrice: undefined,
        nonfatDryMilkPrice: undefined,
        classIIIPrice: undefined,
        classIVPrice: undefined,
        year: 0
      },
      quarterPrices: [],
      monthPrices: []
    };

    const yearElementSelector = (element: ScenarioWhatIfPriceElement, year: number) => {
      const date = parse(element.date.slice(0, 10), 'yyyy-MM-dd', new Date());

      if (date.getFullYear() === year) {
        return element;
      }
    };

    const quarterElementSelector = (
      element: ScenarioWhatIfPriceElement,
      year: number,
      quarter: number
    ) => {
      const date = parse(element.date.slice(0, 10), 'yyyy-MM-dd', new Date());
      const dateQuarter = getQuarter(date);

      if (date.getFullYear() === year && dateQuarter === quarter) {
        return element;
      }
    };

    const monthElementSelector = (
      element: ScenarioWhatIfPriceElement,
      year: number,
      month: number
    ) => {
      const date = parse(element.date.slice(0, 10), 'yyyy-MM-dd', new Date());

      if (date.getFullYear() === year && date.getMonth() === month) {
        return element;
      }
    };

    sortedPrices.yearPrices = {
      butterfatPrice: prices.butterfatYearPrice.find(e => yearElementSelector(e, year)),
      proteinPrice: prices.proteinYearPrice.find(e => yearElementSelector(e, year)),
      otherSolidsPrice: prices.otherSolidsYearPrice.find(e => yearElementSelector(e, year)),
      nonfatSolidsPrice: prices.nonfatSolidsYearPrice.find(e => yearElementSelector(e, year)),
      milkProduction: prices.milkProductionYear.find(e => yearElementSelector(e, year)),
      butterPrice: prices.butterYearPrice.find(e => yearElementSelector(e, year)),
      cheesePrice: prices.cheeseYearPrice.find(e => yearElementSelector(e, year)),
      dryWheyPrice: prices.dryWheyYearPrice.find(e => yearElementSelector(e, year)),
      nonfatDryMilkPrice: prices.nonfatDryMilkYearPrice.find(e => yearElementSelector(e, year)),
      classIIIPrice: prices.classIIIYearPrice.find(e => yearElementSelector(e, year)),
      classIVPrice: prices.classIVYearPrice.find(e => yearElementSelector(e, year)),
      year
    };

    sortedPrices.quarterPrices = numberRange(1, 5).map(quarter => {
      return {
        butterfatPrice: prices.butterfatQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        proteinPrice: prices.proteinQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        otherSolidsPrice: prices.otherSolidsQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        nonfatSolidsPrice: prices.nonfatSolidsQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        milkProduction: prices.milkProductionQuarter.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        butterPrice: prices.butterQuarterPrice.find(e => quarterElementSelector(e, year, quarter)),
        cheesePrice: prices.cheeseQuarterPrice.find(e => quarterElementSelector(e, year, quarter)),
        dryWheyPrice: prices.dryWheyQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        nonfatDryMilkPrice: prices.nonfatDryMilkQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        classIIIPrice: prices.classIIIQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        classIVPrice: prices.classIVQuarterPrice.find(e =>
          quarterElementSelector(e, year, quarter)
        ),
        year,
        quarter
      };
    });

    sortedPrices.monthPrices = numberRange(0, 12).map(month => {
      return {
        butterfatPrice: prices.butterfatPrice.find(e => monthElementSelector(e, year, month)),
        proteinPrice: prices.proteinPrice.find(e => monthElementSelector(e, year, month)),
        otherSolidsPrice: prices.otherSolidsPrice.find(e => monthElementSelector(e, year, month)),
        nonfatSolidsPrice: prices.nonfatSolidsPrice.find(e => monthElementSelector(e, year, month)),
        milkProduction: prices.milkProduction.find(e => monthElementSelector(e, year, month)),
        butterPrice: prices.butterPrice.find(e => monthElementSelector(e, year, month)),
        cheesePrice: prices.cheesePrice.find(e => monthElementSelector(e, year, month)),
        dryWheyPrice: prices.dryWheyPrice.find(e => monthElementSelector(e, year, month)),
        nonfatDryMilkPrice: prices.nonfatDryMilkPrice.find(e =>
          monthElementSelector(e, year, month)
        ),
        classIIIPrice: prices.classIIIPrice.find(e => monthElementSelector(e, year, month)),
        classIVPrice: prices.classIVPrice.find(e => monthElementSelector(e, year, month)),
        year,
        month
      };
    });

    return sortedPrices;
  }
);

export const getEndorsementSummarySums = createSelector(
  getScenarioAnalysisSummary,
  getUnitFromProps,
  (summaries, unit) => {
    const actualSummariesProduction = summaries
      .map(s => s.endorsementSummary.production)
      .filter(e => e !== null && e !== undefined) as Production[];
    const actualSummariesEndorsement = summaries
      .map(s => s.endorsementSummary.endorsement)
      .filter(e => e !== null && e !== undefined) as Endorsement[];
    const scenarioSummariesProduction = summaries
      .map(s => s.scenarioSummary.production)
      .filter(e => e !== null && e !== undefined) as Production[];
    const scenarioSummariesEndorsement = summaries
      .map(s => s.scenarioSummary.endorsement)
      .filter(e => e !== null && e !== undefined) as Endorsement[];

    const actualMilkMarketingsSum = actualSummariesProduction.reduce(
      (acc, currentValue) => currentValue.milkProductionActual + acc,
      0
    );

    const actualButterfatData = actualSummariesProduction.reduce(
      (acc, currentValue) => ({
        count: currentValue.butterfatTestActual > 0 ? acc.count + 1 : acc.count,
        value: acc.value + currentValue.butterfatTestActual
      }),
      { count: 0, value: 0 }
    );
    const actualButterFatAverage = actualButterfatData.value / actualButterfatData.count;

    const actualProteinData = actualSummariesProduction.reduce(
      (acc, currentValue) => ({
        count: currentValue.proteinTestActual > 0 ? acc.count + 1 : acc.count,
        value: acc.value + currentValue.proteinTestActual
      }),
      { count: 0, value: 0 }
    );
    const actualProteinAverage = actualProteinData.value / actualProteinData.count;

    const actualMilkProductionSum = actualSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.milkProductionDeclared + acc,
      0
    );

    const actualProducerPremiumAmountSum = actualSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.premiumProducer + acc,
      0
    );

    const actualIndemnityAmountSum = actualSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.indemnity + acc,
      0
    );

    const scenarioMilkProductionSum = scenarioSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.milkProductionDeclared + acc,
      0
    );

    const scenarioButterfatData = scenarioSummariesProduction.reduce(
      (acc, currentValue) => ({
        count: currentValue.butterfatTestActual ? acc.count + 1 : acc.count,
        value: acc.value + currentValue.butterfatTestActual
      }),
      { count: 0, value: 0 }
    );
    const scenarioButterFatAverage = scenarioButterfatData.value / scenarioButterfatData.count;

    const scenarioProteinData = scenarioSummariesProduction.reduce(
      (acc, currentValue) => ({
        count: currentValue.proteinTestActual ? acc.count + 1 : acc.count,
        value: acc.value + currentValue.proteinTestActual
      }),
      { count: 0, value: 0 }
    );
    const scenarioProteinAverage = scenarioProteinData.value / scenarioProteinData.count;

    const scenarioMilkMarketingsSum = scenarioSummariesProduction.reduce(
      (acc, currentValue) => currentValue.milkProductionActual + acc,
      0
    );

    const scenarioProducerPremiumAmountSum = scenarioSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.premiumProducer + acc,
      0
    );

    const scenarioIndemnityAmountSum = scenarioSummariesEndorsement.reduce(
      (acc, currentValue) => currentValue.indemnity + acc,
      0
    );

    return {
      actualMilkMarketings: actualMilkMarketingsSum,
      actualButterFatAverage: isNaN(actualButterFatAverage)
        ? ''
        : formatNumber(2)(actualButterFatAverage),
      actualProteinAverage: isNaN(actualProteinAverage)
        ? ''
        : formatNumber(2)(actualProteinAverage),
      actualMilkProduction: actualMilkProductionSum,
      actualProducerPremiumAmount:
        actualMilkProductionSum > 0
          ? unit === Unit.DOLLAR
            ? formatNumber(0)(actualProducerPremiumAmountSum, Units.DOLLAR)
            : formatNumber(4)(
                actualProducerPremiumAmountSum / (actualMilkProductionSum / 100),
                Units.DOLLAR_PER_CWT
              )
          : '',
      actualIndemnityAmount:
        actualMilkProductionSum > 0
          ? unit === Unit.DOLLAR
            ? formatNumber(0)(actualIndemnityAmountSum, Units.DOLLAR)
            : formatNumber(2)(
                actualIndemnityAmountSum / (actualMilkProductionSum / 100),
                Units.DOLLAR_PER_CWT
              )
          : '',

      scenarioMilkMarketings: scenarioMilkMarketingsSum,
      scenarioButterFatAverage: isNaN(scenarioButterFatAverage)
        ? ''
        : formatNumber(2)(scenarioButterFatAverage),
      scenarioProteinAverage: isNaN(scenarioProteinAverage)
        ? ''
        : formatNumber(2)(scenarioProteinAverage),
      scenarioMilkProduction: scenarioMilkProductionSum,
      scenarioProducerPremiumAmount:
        scenarioMilkProductionSum > 0
          ? unit === Unit.DOLLAR
            ? formatNumber(0)(scenarioProducerPremiumAmountSum, Units.DOLLAR)
            : formatNumber(4)(
                scenarioProducerPremiumAmountSum / (scenarioMilkProductionSum / 100),
                Units.DOLLAR_PER_CWT
              )
          : '',
      scenarioIndemnityAmount:
        scenarioMilkProductionSum > 0
          ? unit === Unit.DOLLAR
            ? formatNumber(0)(scenarioIndemnityAmountSum, Units.DOLLAR)
            : formatNumber(2)(
                scenarioIndemnityAmountSum / (scenarioMilkProductionSum / 100),
                Units.DOLLAR_PER_CWT
              )
          : ''
    };
  }
);
