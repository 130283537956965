import { ActionType, createAction } from 'typesafe-actions';

import County from '../../types/County/County';
import State from '../../types/State/State';

export const areasActions = {
  getStates: createAction('@areas/GET_STATES')(),
  setStates: createAction('@areas/SET_STATES')<State[]>(),
  getCounties: createAction('@areas/GET_COUNTIES')(),
  setCounties: createAction('@areas/SET_COUNTIES')<{ [key: string]: County[] }>()
};

export type AreasActions = ActionType<typeof areasActions>;
