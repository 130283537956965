import { createSelector } from 'reselect';

import { getAccountPreferences } from '../accountPreferences/selectors';
import { getActivePageFromProps, getPerPageFromProps } from '../fromPropsSelector';
import { RootState } from '../store';

export const getOperationPageOperationData = (state: RootState) => state.operationPage;

export const getOperationName = (state: RootState) => state.operationPage.name;

export const getOperationFullName = (state: RootState) => state.operationPage.fullName;

export const getOperationPageStateCode = (state: RootState) => state.operationPage.stateCode;

export const getOperationPageOperations = (state: RootState) => state.operationPage.operations;

export const getOperationLGMActive = (state: RootState) =>
  state.operationPage.insurancePrograms.includes('LGM');

export const getOperationLRPActive = (state: RootState) =>
  state.operationPage.insurancePrograms.includes('LRP');

export const getOperationDRPActive = (state: RootState) =>
  state.operationPage.insurancePrograms.includes('DRP');

export const getOperationPrograms = (state: RootState) => state.operationPage.insurancePrograms;

export const getVisibleOperationPageOperations = createSelector(
  getOperationPageOperations,
  getAccountPreferences,
  (operations, { showStarredOperationsOnly }) =>
    showStarredOperationsOnly ? operations.filter(({ starred }) => starred) : operations
);

export const getPaginatedOperationPageOperations = createSelector(
  getVisibleOperationPageOperations,
  getPerPageFromProps,
  getActivePageFromProps,
  (operations, perPage, activePage) =>
    operations.slice((activePage - 1) * perPage, perPage * activePage)
);
