import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { AccountPreferencesDto } from '../../../types/dto/AccountPreferencesDto';
import { loadersActions } from '../../loaders/actions';
import { accountPreferencesActions } from './actions';

export function* fetchUserPreferences(
  action: ActionType<typeof accountPreferencesActions.userPreferences.fetchUserPreferences>
) {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    const preferences: AxiosResponse<AccountPreferencesDto> = yield call(
      insuranceAPI.getSpecifiedAccountPreferences,
      action.payload
    );
    yield put(accountPreferencesActions.userPreferences.setUserPreferences(preferences.data));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.accountPreferencesLoader.disable());
    action.meta.setSpinner(false);
    action.meta.redirect();
  }
}

export function* putUserPreferences(
  action: ActionType<typeof accountPreferencesActions.userPreferences.saveUserPreferences>
) {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    yield call(insuranceAPI.updateSpecifiedAccountPreferences, action.payload, action.meta);
    yield toast.success('Changes Saved!', {
      position: 'top-right'
    });
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  } finally {
    yield put(loadersActions.accountPreferencesLoader.disable());
  }
}

export default function* producerPreferencesSagaWatcher() {
  yield all([
    takeLatest(
      getType(accountPreferencesActions.userPreferences.fetchUserPreferences),
      fetchUserPreferences
    ),
    takeLatest(
      getType(accountPreferencesActions.userPreferences.saveUserPreferences),
      putUserPreferences
    )
  ]);
}
