import { getType } from 'typesafe-actions';

import { Agency } from '../../../types/Agency';
import { AdminActions, adminActions } from '../actions';

const initialState: Agency[] = [];

const insuranceAgenciesReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.insuranceAgencies.addAllInsuranceAgencies): {
      return action.payload;
    }
    case getType(adminActions.insuranceAgencies.newInsuranceAgency): {
      const newState = [...state];
      newState.push(action.payload);
      return newState;
    }
    case getType(adminActions.insuranceAgencies.updateInsuranceAgency): {
      const index = state.findIndex(({ name }) => name === action.meta);
      if (index === -1) {
        return state;
      }
      const newState = [...state];
      newState[index] = action.payload;
      return newState;
    }
    case getType(adminActions.insuranceAgencies.deleteInsuranceAgency): {
      const deleteIndex = state.findIndex(({ name }) => name === action.payload);
      if (deleteIndex === -1) {
        return state;
      }
      const newState = [...state];
      newState.splice(deleteIndex, 1);
      return newState;
    }
    default:
      return state;
  }
};

export default insuranceAgenciesReducer;
