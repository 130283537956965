import { getType } from 'typesafe-actions';

import { ProjectionAnalysis } from '../../types/ProjectionAnalysis';
import { ProjectionAnalysisParameters } from '../../types/ProjectionAnalysisParameters';
import { ProjectionDataActions, projectionDataActions } from './actions';

const defaultValues = { q1: 0, q2: 0, q3: 0, q4: 0, total: 0 };

interface ProjectionDataState {
  table: ProjectionAnalysis;
  settings: ProjectionAnalysisParameters | null;
  nextYearTable: ProjectionAnalysis;
  nextYearSettings: ProjectionAnalysisParameters | null;
}

const defaultTable: ProjectionAnalysis = {
  basisEditable: { q1: false, q2: false, q3: false, q4: false, total: false },
  projectionModel: 0.8,
  milkProduction: defaultValues,
  milkProductionProtected: defaultValues,
  milkProductionProtectedEffective: defaultValues,
  priceExpected: defaultValues,
  basisHistorical: defaultValues,
  milkPriceMailbox: defaultValues,
  projectedRevenue: defaultValues,
  projectedRevenueProtected: defaultValues,
  projectedRevenueProtectedEffective: defaultValues,
  projectedRevenueCwt: defaultValues,
  projectedRevenueProtectedCwt: defaultValues,
  projectedRevenueProtectedEffectiveCwt: defaultValues,
  costOfProduction: defaultValues,
  costOfProductionProtected: defaultValues,
  costOfProductionProtectedEffective: defaultValues,
  costOfProductionCwt: defaultValues,
  costOfProductionProtectedCwt: defaultValues,
  costOfProductionProtectedEffectiveCwt: defaultValues,
  hedgeRatio: defaultValues,
  hedgeRatioEffective: defaultValues,
  revenueExpectedMinimum: defaultValues,
  revenueExpectedMinimumCwt: defaultValues,
  revenueExpectedMinimumEffective: defaultValues,
  revenueExpectedMinimumEffectiveCwt: defaultValues,
  basisProjected: defaultValues,
  basisProjectedCwt: defaultValues,
  basisProjectedEffective: defaultValues,
  basisProjectedEffectiveCwt: defaultValues,
  basisAdjustmentProtected: defaultValues,
  basisAdjustmentProtectedEffective: defaultValues,
  basisAdjustmentCwt: defaultValues,
  basisAdjustmentProtectedCwt: defaultValues,
  basisAdjustmentProtectedEffectiveCwt: defaultValues,
  premium: defaultValues,
  premiumCwt: defaultValues,
  premiumProtectedCwt: defaultValues,
  premiumProtectedEffectiveCwt: defaultValues,
  revenueFloorProtected: defaultValues,
  revenueFloorProtectedCwt: defaultValues,
  revenueFloorProtectedEffective: defaultValues,
  revenueFloorProtectedEffectiveCwt: defaultValues,
  indemnityEstimate: defaultValues,
  indemnityEstimateCwt: defaultValues,
  indemnityEstimateProtectedCwt: defaultValues,
  indemnityEstimateProtectedEffectiveCwt: defaultValues,
  projectedTotalRevenue: defaultValues,
  projectedTotalRevenueProtected: defaultValues,
  projectedTotalRevenueProtectedEffective: defaultValues,
  projectedTotalRevenueCwt: defaultValues,
  projectedTotalRevenueProtectedCwt: defaultValues,
  projectedTotalRevenueProtectedEffectiveCwt: defaultValues,
  projectedNetIncome: defaultValues,
  projectedNetIncomeProtected: defaultValues,
  projectedNetIncomeProtectedEffective: defaultValues,
  projectedNetIncomeCwt: defaultValues,
  projectedNetIncomeProtectedCwt: defaultValues,
  projectedNeIncomeProtectedEffectiveCwt: defaultValues,
  excessProtectedProduction: defaultValues,
  excessProtectedProductionEffective: defaultValues
};

const initialState: ProjectionDataState = {
  table: defaultTable,
  settings: null,
  nextYearTable: defaultTable,
  nextYearSettings: null
};

export const projectionDataReducer = (state = initialState, action: ProjectionDataActions) => {
  switch (action.type) {
    case getType(projectionDataActions.save):
      return { ...state, table: action.payload };
    case getType(projectionDataActions.settings.save):
      return { ...state, settings: action.payload };
    case getType(projectionDataActions.nextYearSave):
      return { ...state, nextYearTable: action.payload };
    case getType(projectionDataActions.settings.nextYearSave):
      return { ...state, nextYearSettings: action.payload };
    default:
      return state;
  }
};
