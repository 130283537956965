import { ActionType, createAction } from 'typesafe-actions';

import { DuplicateCodeTypes } from '@/shared/constants/enums';

import {
  LrpAnalysisCurrentPrices,
  LrpAnalysisFilterSettings,
  LrpAnalysisHistoricalPrices,
  LrpPriceEvolutionAnalysis,
  LrpPriceEvolutionAnalysisSettings
} from '../../scenes/lrp/types/LrpAnalysis';
import {
  LrpDeleteEndorsement,
  LrpEndorsementDto,
  LrpEndorsementFilterResponseDto,
  LrpEndorsementSummaryDto,
  LrpUpdateEndorsement
} from '../../scenes/lrp/types/LrpEndorsement';
import {
  Commodity,
  LrpCoverageTableAllEndorsementLengthsDto,
  LrpCoverageTableDto,
  LrpCoverageTableHistoricaReducerlAll,
  LrpFiltersDto
} from '../../scenes/lrp/types/LrpFiltersDto';
import {
  LrpCalculationParameters,
  LrpCoverageRequestModel,
  LrpEndorsementDefinition
} from '../../scenes/lrp/types/LrpGetFilters';
import { PricePreferenceProcessedDto } from '../../scenes/lrp/types/PricePreferenceDto';
import PricePreferencePayload from '../../scenes/lrp/types/PricePreferencePayload';
import { QuoterPreferenceReducerDto } from '../../scenes/lrp/types/QuoterPreference';
import Producer from '../../types/Producer/Producer';

export const lrpActions = {
  getFilters: createAction('@lrp_actions/GET_FILTER')<
    Date | undefined,
    {
      setIsFiltersLoading: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    }
  >(),
  addRequestCoverage: createAction('@lrp_actions/REQUEST_COVERAGE')<
    LrpCoverageRequestModel,
    {
      setModifyingCoverageRequest: React.Dispatch<React.SetStateAction<boolean | null>> | undefined;
      handleResponseCode: (res: DuplicateCodeTypes[]) => void;
    }
  >(),
  // saveFilters: createAction('@lrp_actions/SAVE_FILTER')<LrpFiltersReducer>(),
  saveFilters: createAction('@lrp_actions/SAVE_FILTER')<LrpFiltersDto>(),
  saveFiltersCommodities: createAction('@lrp_actions/SAVE_FILTER_COMMODITIES')<
    { name: string; id: string }[]
  >(),
  saveSelectedCommodityData: createAction('@lrp_actions/SAVE_SELECTED_COMMODITY')<Commodity>(),
  resetFilters: createAction('@lrp_actions/RESET_FILTERS')(),
  saveCoveragePercentage: createAction('@lrp_actions/SAVE_FILTER_COVERAGE_PERCENT')<
    { name: string; id: string }[]
  >(),
  getCoverageTable: createAction('@lrp_actions/GET_COVERAGE_TABLE')<
    LrpCalculationParameters,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  getEndorsementCalculation: createAction('@lrp_actions/GET_ENDORSEMENT_CALCULATION')<
    LrpCalculationParameters,
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >(),
  setEndorsementCalculation: createAction(
    '@lrp_actions/SAVE_ENDORSEMENT_CALCULATION'
  )<LrpCoverageTableDto>(),
  getCoverageTableAllEndorsementLengths: createAction(
    '@lrp_actions/GET_COVERAGE_TABLE_ALL_LENGTHS'
  )<LrpCalculationParameters, React.Dispatch<React.SetStateAction<boolean>>>(),
  getCoverageTableHistorical: createAction('@lrp_actions/GET_COVERAGE_TABLE_HISTORICAL')<
    LrpCalculationParameters,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  getCoverageTableAccordion: createAction('@lrp_actions/GET_COVERAGE_TABLE_ACCORDION')<
    LrpCalculationParameters,
    (table: LrpCoverageTableAllEndorsementLengthsDto[]) => void
  >(),
  saveCoverageTable: createAction('@lrp_actions/SAVE_COVERAGE_TABLE')<LrpCoverageTableDto[]>(),
  saveCoverageTableAllEndorsementLengths: createAction(
    '@lrp_actions/SAVE_COVERAGE_TABLE_ALL_LENGTHS'
  )<LrpCoverageTableAllEndorsementLengthsDto[]>(),
  saveCoverageTableHistorical: createAction(
    '@lrp_actions/SAVE_COVERAGE_TABLE_HISTORICAL'
  )<LrpCoverageTableHistoricaReducerlAll>(),
  getQuoterPreferences: createAction('@lrp_actions/GET_QUOTER_PREFERENCES')<string>(),
  updateQuoterPreferences: createAction(
    '@lrp_actions/UPDATE_QUOTER_PREFERENCES'
  )<QuoterPreferenceReducerDto>(),
  getPricePreferences: createAction('@lrp_actions/GET_PRICE_PREFERENCES')(),
  saveQuoterPreferences: createAction(
    '@education_module/SAVE_QUOTER_PREFERENCES'
  )<QuoterPreferenceReducerDto>(),
  savePricePreferences: createAction('@education_module/SAVE_PRICE_PREFERENCES')<
    PricePreferenceProcessedDto[]
  >(),
  createPricePreference: createAction('@lrp_actions/ADD_PRICE_PREFERENCE')<
    PricePreferencePayload,
    () => void
  >(),
  deletePricePreference: createAction('@lrp_actions/DELETE_PRICE_PREFERENCE')<number, () => void>(),
  setPricePreferenceSpinner: createAction('@educationModule/SET_SPINNER')<boolean>(),
  isFiltersComplete: createAction('@lrp_actions/IS_FILTERS_COMPLETE')<boolean>(),
  isCoverageTableAvailable: createAction('@lrp_actions/IS_COVERAGE_AVAILABLE')<boolean>(),
  isCoverageTableAllLengthsAvailable: createAction(
    '@lrp_actions/IS_COVERAGE_AVAILABLE_ALL_LENGTHS'
  )<boolean>(),
  isCoverageTableHistoricalAvailable: createAction(
    '@lrp_actions/IS_COVERAGE_HISTORICAL_AVAILABLE'
  )<boolean>(),
  getLrpEndorsements: createAction('@lrp_actions/GET_LRP_ENDORSEMENTS')<
    LrpEndorsementSummaryDto,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  getLrpEndorsementSummaryTable: createAction('@lrp_actions/GET_LRP_ENDORSEMENT_SUMMARY_TABLE')<
    LrpEndorsementSummaryDto,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  addEndorsement: createAction('@lrp_actions/ADD_ENDORSEMENT')<
    LrpEndorsementDefinition,
    React.Dispatch<React.SetStateAction<boolean | null>>
  >(),
  setEndorsements: createAction('@lrp_actions/SET_ENDORSEMENTS')<LrpEndorsementDto[]>(),
  recalculateEndorsements: createAction('@lrp_actions/RECALCULATE_ENDORSEMENTS')<
    string,
    () => void
  >(),
  setEndorsementSummary: createAction('@lrp_actions/SET_ENDORSEMENT_SUMMARY_TABLE')<
    LrpEndorsementDto[]
  >(),
  deleteLrpEndorsement: createAction('@lrp_actions/DELETE_LRP_ENDORSEMENT')<
    LrpDeleteEndorsement,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  updateLrpEndorsement: createAction('@lrp_actions/UPDATE_LRP_ENDORSEMENT')<
    { operationName: string; data: LrpUpdateEndorsement },
    React.Dispatch<React.SetStateAction<boolean | null>>
  >(),
  getLrpEndorsementFilter: createAction('@lrp_actions/GET_LRP_ENDORSEMENT_FILTER')<string>(),
  saveLrpEndorsementFilter: createAction(
    '@lrp_actions/SAVE_ENDORSEMENT_Filter'
  )<LrpEndorsementFilterResponseDto>(),
  getLrpAnalysisCurrentPrices: createAction('@lrp_actions/GET_ANALYSIS_CURRENT_PRICES')<
    LrpAnalysisFilterSettings,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  saveLrpAnalysisCurrentPrices: createAction(
    '@lrp_actions/SAVE_ANALYSIS_CURRENT_PRICES'
  )<LrpAnalysisCurrentPrices>(),
  getLrpAnalysisHistoricalPrices: createAction('@lrp_actions/GET_ANALYSIS_HISTORICAL_PRICES')<
    LrpAnalysisFilterSettings,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  saveLrpAnalysisHistoricalPrices: createAction(
    '@lrp_actions/SAVE_ANALYSIS_HISTORICAL_PRICES'
  )<LrpAnalysisHistoricalPrices>(),
  getOperationProducer: createAction('@lrp_actions/GET_OPERATION_PRODUCER')<{
    operationName: string;
    includeBindingRequirements: boolean;
  }>(),
  saveOperationProducer: createAction('@lrp_actions/SAVE_OPERATION_PRODUCER')<Producer[]>(),
  resetOperationProducer: createAction('@lrp_actions/RESET_OPERATION_PRODUCER')(),
  getLrpPriceEvolutionAnalysis: createAction('@lrp_actions/GET_PRICE_EVOLUTION_ANALYSIS')<
    LrpPriceEvolutionAnalysisSettings,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  saveLrpPriceEvolutionAnalysis: createAction(
    '@lrp_actions/SAVE_PRICE_EVOLUTION_ANALYSIS'
  )<LrpPriceEvolutionAnalysis>()
};
export type LRPActions = ActionType<typeof lrpActions>;
