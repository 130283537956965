import { ActionType, createAction } from 'typesafe-actions';

import { OperationPreferencesDto } from '../../types/dto/OperationPreferencesDto';

export const operationPreferencesActions = {
  get: createAction('@operation_preferences/GET')<string>(),
  add: createAction('@operation_preferences/ADD')<OperationPreferencesDto>(),
  save: createAction('@operation_preferences/SAVE')<OperationPreferencesDto, string>()
};

export type OperationPreferencesActions = ActionType<typeof operationPreferencesActions>;
