import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import { Alert } from '../../types/Alert';
import {
  AlertPriceType,
  AlertScenarioExpectedPricesPayload,
  AlertScenarioPriceFloorPayload,
  AlertsPayload,
  RevenueAlertPayload
} from '../../types/Alerts';
import { AnalysisProduct } from '../../types/AnalysisProduct';
import {
  HistoricalAnalysisExpected,
  HistoricalAnalysisPriceFloor
} from '../../types/HistoricalAnalysisExpected';

export const alertActions = {
  get: createAction('@alerts/GET')<boolean>(),
  saveAll: createAction('@alerts/SAVE_ALL')<Alert[]>(),
  create: createAction('@alerts/CREATE')<AlertsPayload | RevenueAlertPayload, () => void>(),
  sort: createAction('@alerts/SORT')<{ sort: string; direction: SortDirection }>(),
  save: createAction('@alerts/SAVE')(),
  edit: createAction('@alerts/EDIT')<Alert>(),
  update: createAction('@alerts/UPDATE')<Alert>(),
  delete: createAction('@alerts/DELETE')<number, () => void>(),
  remove: createAction('@alerts/REMOVE')<number>(),
  getAlertPrices: createAction('@alerts/GET_ALERT_PRICES')<{
    type: AlertPriceType;
    year: number;
    quarter: number;
    product: AnalysisProduct;
    month?: number;
  }>(),
  saveAlertPrices: createAction('@alerts/SAVE_ALERT_PRICES')<HistoricalAnalysisExpected>(),
  saveAlertPriceFloorPrices: createAction(
    '@alerts/SAVE_ALERT_PRICE_FLOOR'
  )<HistoricalAnalysisPriceFloor>(),
  setIsProductSupported: createAction('@alerts/SET_IS_PRODUCT_SUPPORTED')<boolean>(),
  getAlertScenarioExpectedPrices: createAction(
    '@alerts/GET_SCENARIO_EXPECTED_PRICES'
  )<AlertScenarioExpectedPricesPayload>(),
  getAlertPriceFloorExpectedPrices: createAction(
    '@alerts/GET_SCENARIO_PRICE_FLOOR'
  )<AlertScenarioPriceFloorPayload>(),
  getAlertTableRowValues: createAction('@alerts/GET_ALERT_TABLE_ROW_VALUES')<
    AlertScenarioPriceFloorPayload,
    {
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
      setCalculatedDataFloorValues: React.Dispatch<
        React.SetStateAction<HistoricalAnalysisPriceFloor | undefined>
      >;
    }
  >()
};

export type AlertActions = ActionType<typeof alertActions>;
