import { getType } from 'typesafe-actions';

import FilteredOperationProducers from '../../../types/Producer/FilteredOperationProducers';
import { AdminActions, adminActions } from '../actions';

interface InitialState {
  [key: string]: {
    [key: string]: FilteredOperationProducers[];
  };
}

const initialState: InitialState = {};

const operationsProducersReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.operationsProducers.addAgencyOperationProducers): {
      const newState = { ...state };
      if (!newState[action.meta.agencyName]) {
        newState[action.meta.agencyName] = {};
      }
      newState[action.meta.agencyName][action.meta.operationName] = action.payload;
      return newState;
    }
    case getType(adminActions.operationsProducers.addAgencyProducerToOperation): {
      const newState = { ...state };
      const index = newState[action.payload.agencyName][action.payload.operationName].findIndex(
        user => user.userName === action.payload.user.userName
      );
      if (index === -1) {
        return state;
      }
      newState[action.payload.agencyName][action.payload.operationName][index] = {
        ...action.payload.user,
        isAdded: true
      };
      return newState;
    }
    case getType(adminActions.operationsProducers.deleteAgencyProducerFromOperation): {
      const newState = { ...state };
      const index = newState[action.payload.agencyName][action.payload.operationName].findIndex(
        user => user.userName === action.payload.userId
      );
      if (index !== -1) {
        newState[action.payload.agencyName][action.payload.operationName][index].isAdded = false;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default operationsProducersReducer;
