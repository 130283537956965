import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import whatsNewAPI from '../../../services/whatsNewService';
import { WhatsNewJson } from '../WhatsNew.types';
import { whatsNewActions } from './actions';

export function* setDataRequest() {
  try {
    const response: AxiosResponse<WhatsNewJson> = yield call(whatsNewAPI.getWhatsNew);
    yield put(whatsNewActions.setDataSuccess(response.data));
  } catch (error) {
    console.log(error);
  }
}

export default function* whatNewSagaWatcher() {
  yield all([takeLatest(getType(whatsNewActions.setDataRequest), setDataRequest)]);
}
