import { combineReducers } from 'redux';

import lgmLoaderReducer from './loaders/reducer';
import lgmReducer from './quoter/reducers';
import lgmSettingsReducer from './settings/reducer';
import lgmWhatIfReducer from './whatif/reducers';

export const lgmRootReducer = combineReducers({
  settings: lgmSettingsReducer,
  quoter: lgmReducer,
  whatIf: lgmWhatIfReducer,
  loaders: lgmLoaderReducer
});
