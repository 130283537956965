import { ActionType, createAction, getType } from 'typesafe-actions';

import { Agent } from '../../types/Agent/Agent';
import { RootState } from '../store';

export const userAgentActions = {
  getUserAgent: createAction('@agent/GET_USER_AGENT')<{ agentName: string; agencyName: string }>(),
  setUserAgent: createAction('@agent/SET_USER_AGENT')<Agent>()
};

type UserAgentActions = ActionType<typeof userAgentActions>;

interface UserAgentState {
  userAgent: Agent;
}

const initialState: UserAgentState = {
  userAgent: {} as Agent
};

export const userAgentReducer = (state = initialState, action: UserAgentActions) => {
  if (action.type === getType(userAgentActions.setUserAgent))
    return { ...state, userAgent: action.payload };
  return state;
};

export const getUserAgent = (state: RootState) => state.userAgent.userAgent;
