import { RootState } from '../store';

export const getAlerts = (state: RootState) => state.alerts.alerts;

export const getAlertPriceTrendExpected = (state: RootState) => state.alerts.priceTrendExpected;

export const getAlertPriceFloorTrendExpected = (state: RootState) =>
  state.alerts.priceFloorTrendExpected;

export const getIsProductSupported = (state: RootState) => state.alerts.isProductSupported;
