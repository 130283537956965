import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { OperationPageActions, operationPageActions } from '../../operationPage/actions';
import { ScenarioAnalysisActions, scenarioAnalysisActions } from '../actions';

type InitialState = {
  currentScenario: string;
  scenarios: string[];
};

const initialState: InitialState = {
  currentScenario: '',
  scenarios: []
};

export const scenariosReducer = (
  state = initialState,
  action: ScenarioAnalysisActions | OperationPageActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(scenarioAnalysisActions.saveWhatIfScenarios): {
        draft.scenarios = [...action.payload];
        return;
      }
      case getType(scenarioAnalysisActions.removeWhatIfScenario): {
        const index = state.scenarios.findIndex(scenarioName => scenarioName === action.payload);
        if (index !== -1) {
          draft.scenarios.splice(index, 1);
        }
        return;
      }
      case getType(scenarioAnalysisActions.saveCurrentScenarioName): {
        draft.currentScenario = action.payload;
        return;
      }
      case getType(scenarioAnalysisActions.saveCreatedWhatIfScenario): {
        const index = state.scenarios.findIndex(scenario => action.payload === scenario);
        if (index === -1) {
          draft.scenarios.push(action.payload);
        }
        return;
      }
      case getType(scenarioAnalysisActions.addWhatIfScenario): {
        draft.scenarios = [...state.scenarios, action.payload];
        return;
      }
      case getType(operationPageActions.setOperationName): {
        draft.scenarios = [];
        draft.currentScenario = '';
        return;
      }
    }
  });
