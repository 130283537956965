import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import {
  DrpStatisticsDto,
  GroupStatisticsAllDto,
  StatisticsBarChart,
  StatisticsHedgeRatio
} from '../../types/dto/DrpStatisticsDto';
import { getOperationName } from '../operationPage/selectors';
import { drpSalesStatisticsActions } from './actions';

function* getStatistics(action: ActionType<typeof drpSalesStatisticsActions.getStatistics>) {
  const {
    calendarYearFrom,
    calendarYearTo,
    quarterFrom,
    quarterTo,
    reinsuranceYearFrom,
    reinsuranceYearTo,
    salesEffectiveDateFrom,
    salesEffectiveDateTo,
    stateCode,
    countyCodes
  } = action.payload;
  try {
    const statistics: AxiosResponse<DrpStatisticsDto> = yield call(
      insuranceAPI.getDrpSalesStatistics,
      calendarYearFrom,
      calendarYearTo,
      quarterFrom,
      quarterTo,
      reinsuranceYearFrom,
      reinsuranceYearTo,
      salesEffectiveDateFrom,
      salesEffectiveDateTo,
      stateCode,
      countyCodes
    );
    if (statistics.data.hedgingHorizon.length > 5) {
      statistics.data.hedgingHorizon.splice(5, 1);
    }
    if (!stateCode) {
      yield put(drpSalesStatisticsActions.saveStatisticsUSA(statistics.data));
    }
  } catch (e) {
    console.error(e);
    toast.error('No data avaliable for selected criteria!');
  }
  yield action.meta(false);
}

function* getStateCountytatistics(
  action: ActionType<typeof drpSalesStatisticsActions.getStatistics>
) {
  const {
    calendarYearFrom,
    calendarYearTo,
    quarterFrom,
    quarterTo,
    reinsuranceYearFrom,
    reinsuranceYearTo,
    salesEffectiveDateFrom,
    salesEffectiveDateTo,
    stateCode,
    countyCodes
  } = action.payload;
  try {
    const statistics: AxiosResponse<DrpStatisticsDto> = yield call(
      insuranceAPI.getDrpSalesStatistics,
      calendarYearFrom,
      calendarYearTo,
      quarterFrom,
      quarterTo,
      reinsuranceYearFrom,
      reinsuranceYearTo,
      salesEffectiveDateFrom,
      salesEffectiveDateTo,
      stateCode,
      countyCodes
    );
    if (statistics.data.hedgingHorizon.length > 5) {
      statistics.data.hedgingHorizon.splice(5, 1);
    }
    if (stateCode && !countyCodes) {
      yield put(drpSalesStatisticsActions.saveStatisticsState(statistics.data));
    } else {
      yield put(drpSalesStatisticsActions.saveStatisticsCounties(statistics.data));
    }
  } catch (e) {
    console.error(e);
    toast.error('No data avaliable for selected criteria!');
  }
  yield action.meta(false);
}

function* getBarStatistics(action: ActionType<typeof drpSalesStatisticsActions.getBarStatistics>) {
  const {
    calendarYearFrom,
    calendarYearTo,
    quarterFrom,
    quarterTo,
    reinsuranceYearFrom,
    reinsuranceYearTo,
    salesEffectiveDateFrom,
    salesEffectiveDateTo,
    stateCode,
    countyCodes
  } = action.payload;
  try {
    const bar: AxiosResponse<StatisticsBarChart> = yield call(
      insuranceAPI.getDrpSalesStatisticsBar,
      calendarYearFrom,
      calendarYearTo,
      quarterFrom,
      quarterTo,
      reinsuranceYearFrom,
      reinsuranceYearTo,
      salesEffectiveDateFrom,
      salesEffectiveDateTo,
      stateCode,
      countyCodes
    );
    action.meta(bar.data);
  } catch (e) {
    console.error(e);
    toast.error('No data avaliable for selected criteria!');
  }
}

function* getHedgeRatio(action: ActionType<typeof drpSalesStatisticsActions.getHedgeRatio>): any {
  const { year, quarter, stateCodes } = action.payload;
  try {
    const operationName = yield select(getOperationName);
    const hedgeRatio: AxiosResponse<StatisticsHedgeRatio> = yield call(
      insuranceAPI.getDrpSalesStatisticsHedgeRatio,
      operationName,
      year,
      quarter,
      stateCodes
    );
    action.meta.setHedgeRatio(hedgeRatio.data);
  } catch (e) {
    console.error(e);
    toast.error('No data avaliable for selected criteria!');
  }
  yield action.meta.setIsLoading(false);
}

function* getGroupStatistics(
  action: ActionType<typeof drpSalesStatisticsActions.getGroupStatistics>
) {
  const {
    groupOptions,
    calendarYearFrom,
    calendarYearTo,
    quarterFrom,
    quarterTo,
    reinsuranceYearFrom,
    reinsuranceYearTo,
    salesEffectiveDateFrom,
    salesEffectiveDateTo,
    stateCode,
    countyCodes
  } = action.payload;
  yield action.meta.setCanExport(false);
  try {
    const groupStatistics: AxiosResponse<GroupStatisticsAllDto> = yield call(
      insuranceAPI.getDrpSalesStatisticsGroup,
      groupOptions,
      calendarYearFrom,
      calendarYearTo,
      quarterFrom,
      quarterTo,
      reinsuranceYearFrom,
      reinsuranceYearTo,
      salesEffectiveDateFrom,
      salesEffectiveDateTo,
      stateCode,
      countyCodes
    );
    yield put(drpSalesStatisticsActions.saveGroupStatistics(groupStatistics.data));
    yield action.meta.setFetchWorked(true);
  } catch (e) {
    console.error(e);
    toast.error('Group Statistics: No data avaliable for selected criteria!');
    yield action.meta.setFetchWorked(false);
  }
  yield action.meta.setCanExport(true);
}

function* exportGroupTableToExcel(
  action: ActionType<typeof drpSalesStatisticsActions.exportGroupTableToExcel>
): any {
  yield action.meta(true);
  try {
    const response = yield call(insuranceAPI.exportGroupTableToExcel, action.payload);

    const contentDisposition = response.headers['content-disposition'];
    const contentType = response.headers['content-type'];
    const filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();

    const blob = new Blob([response.data], { type: contentType });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
  } catch (e) {
    console.error(e);
  }
  yield action.meta(false);
}

function* salesStatisticsSagaWatcher() {
  yield all([
    takeLatest(getType(drpSalesStatisticsActions.getStatistics), getStatistics),
    takeLatest(
      getType(drpSalesStatisticsActions.getStateCountyStatistics),
      getStateCountytatistics
    ),
    takeEvery(getType(drpSalesStatisticsActions.getBarStatistics), getBarStatistics),
    takeLatest(getType(drpSalesStatisticsActions.getHedgeRatio), getHedgeRatio),
    takeLatest(getType(drpSalesStatisticsActions.getGroupStatistics), getGroupStatistics),
    takeLatest(getType(drpSalesStatisticsActions.exportGroupTableToExcel), exportGroupTableToExcel)
  ]);
}

export default salesStatisticsSagaWatcher;
