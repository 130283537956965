import { ActionType, createAction } from 'typesafe-actions';

import {
  OperationPriceReportDto,
  OperationPriceReportPayload
} from '../../types/dto/OperationPreferencesDto';

export const priceReportActions = {
  get: createAction('@price_report/GET')(),
  save: createAction('@price_report/SAVE')<OperationPriceReportDto[]>(),
  add: createAction('@price_report/ADD')<OperationPriceReportPayload, () => void>(),
  delete: createAction('@price_report/DELETE')<number, () => void>(),
  setPriceReportSpinner: createAction('@educationModule/SET_SPINNER')<boolean>()
};

export type PriceReportActions = ActionType<typeof priceReportActions>;
