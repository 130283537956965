import { getQuarter } from 'date-fns';
import { createSelector } from 'reselect';

import { PriceTrendsData } from '../../components/Charts/PriceTrend/types';
import { PriceType } from '../../scenes/lrp/containers/LrpMarketDynamics/PriceTrends/renderHistoricalPricesTable';
import { Values } from '../../types/PriceTrendsActual';
import { Quarter } from '../../types/Quarter';
import { Units } from '../../types/Units';
import { formatDateYearMonth, formatDateYearMonthDay } from '../../utils/dateFormatter';
import { formatNumber } from '../../utils/numberFormatter';
import numberRange from '../../utils/numberRange';
import priceTrendProductsOnHover from '../../utils/priceTrendProductsOnHover';
import {
  getFromYearFromProps,
  getIsPriceTrendExpectedDataAvailable,
  getPriceTrendProducts,
  getQuarterStringFromProps,
  getToYearFromProps
} from '../fromPropsSelector';
import { RootState } from '../store';
import {
  PriceTrendsChartData,
  createEmptyRecord,
  mergeWithMetaData,
  quarterMonths,
  sortObjectByDateKeys
} from './chartDataUtils';

const calculatePercentile = (values: Values[], latestPrice: number, quarter: Quarter) => {
  const valuesOfQuarter = values.filter(({ date }) => getQuarter(date) === quarter);
  const valuesOfQuarterLowerThanLatestPrice = valuesOfQuarter.filter(
    ({ price }) => price < latestPrice
  );
  if (valuesOfQuarterLowerThanLatestPrice.length !== 0) {
    return valuesOfQuarterLowerThanLatestPrice.length / valuesOfQuarter.length;
  }
  return 0;
};

export const getPriceTrends = (state: RootState) => state.priceTrends;

const quarterByIndex = (quarterIndex: number) => {
  switch (quarterIndex) {
    case 0:
      return 'Jan-Mar';
    case 1:
      return 'Apr-Jun';
    case 2:
      return 'Jul-Sep';
    case 3:
      return 'Oct-Dec';
    default:
      return 'quarterly';
  }
};

export const getActualHistoricalPrices = createSelector(
  getPriceTrends,
  getPriceTrendProducts,
  getFromYearFromProps,
  getToYearFromProps,
  getQuarterStringFromProps,
  getIsPriceTrendExpectedDataAvailable,
  (priceTrends, products, fromYear, toYear, quarter, isPriceTrendExpectedDataAvailable) => {
    type Values = { value: string; type: PriceType; tooltipID: string; tooltipText: string }[];
    const returnData: { name: string; values: Values[] }[] = [];

    if (
      products.some(product => !priceTrends[product]) ||
      products.some(product => !priceTrends[product].actual) ||
      products.some(product => !priceTrends[product].expected)
    ) {
      return returnData;
    }

    products.forEach(product => {
      const productData: { name: string; values: (string | number)[][] } = {
        name: product,
        values: []
      };

      const { actual, expected } = priceTrends[product];
      let currentExpectedPrice: string;
      if (product === 'ClassIIIMilk' || product === 'ClassIVMilk') {
        currentExpectedPrice = formatNumber(2)(
          expected.latest.dataPoint.price,
          Units.DOLLAR_PER_CWT
        );
      } else {
        if (product === 'Butterfat' || product === 'Protein') {
          currentExpectedPrice = formatNumber(4)(
            expected.latest.dataPoint.price,
            Units.DOLLAR_PER_LB
          );
        } else {
          currentExpectedPrice = formatNumber(2)(
            expected.latest.dataPoint.price,
            Units.DOLLAR_PER_LB
          );
        }
      }
      const currentExpectedPriceDate = formatDateYearMonthDay(expected.latest.dataPoint.date);
      const { yearPriceModel, quarterPriceModel } = actual;
      actual.values.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      quarterPriceModel.sort((a, b) => a.quarter - b.quarter);

      let year = 0;
      let element: (string | number)[] = [];
      // const isUnitCWT = product === ''

      const globalMin = Math.min(...actual.values.map(({ price }) => price));
      const globalMax = Math.max(...actual.values.map(({ price }) => price));
      const getPriceType = (value: number, min: number, max: number) => {
        switch (value) {
          case globalMin:
            return PriceType.GLOBAL_MIN;
          case globalMax:
            return PriceType.GLOBAL_MAX;
          case min:
            return PriceType.MIN;
          case max:
            return PriceType.MAX;
          default:
            return PriceType.NEUTRAL;
        }
      };

      const latestPrice = expected.latest.dataPoint.price;
      const q1Percentile = calculatePercentile(actual.values, latestPrice, 1);
      const q2Percentile = calculatePercentile(actual.values, latestPrice, 2);
      const q3Percentile = calculatePercentile(actual.values, latestPrice, 3);
      const q4Percentile = calculatePercentile(actual.values, latestPrice, 4);
      const allYearPercentile =
        actual.values.filter(({ price }) => price < latestPrice).length / actual.values.length;

      const percentiles = [
        q1Percentile,
        q2Percentile,
        q3Percentile,
        q4Percentile,
        allYearPercentile
      ];

      actual.values.forEach((value, index) => {
        const date = new Date(value.date);
        if (date.getFullYear() !== year) {
          const yearAverage = yearPriceModel.find(priceModel => priceModel.year === year);
          if (yearAverage) {
            element.push(yearAverage.price);
          }

          productData.values.push(element);
          element = [];
          year = date.getFullYear();
          element.push(String(year));
          element.push(value.price);
        } else {
          element.push(value.price);
        }

        if (index === actual.values.length - 1) {
          const setEmptyString = () => {
            numberRange(0, 5).forEach(index => {
              if (!element[index]) {
                element[index] = '';
              }
            });
          };

          if (element.length < 5) {
            setEmptyString();
          }
          const yearAverage = yearPriceModel.find(priceModel => priceModel.year === year);
          if (yearAverage) {
            element.push(yearAverage.price);
          }
          productData.values.push(element);
        }
      });
      const averages = quarterPriceModel.map(quarter => ({
        price: quarter.price,
        quantile: quarter.quantile
      }));
      averages.push(
        averages.reduce(
          (a, b) => ({ price: a.price + b.price / 4, quantile: a.quantile + b.quantile / 4 }),
          {
            price: 0,
            quantile: 0
          }
        )
      );

      const rowMax: number[] = [];
      const rowMin: number[] = [];
      productData.values.forEach(row => {
        row.forEach((value, index) => {
          if (typeof value === 'number') {
            if (rowMax[index] < value || !rowMax[index]) {
              rowMax[index] = value;
            }
            if (rowMin[index] > value || !rowMin[index]) {
              rowMin[index] = value;
            }
          }
        });
      });

      returnData.push({
        name: productData.name,
        values: [
          ...productData.values.map(arr =>
            arr.map((value, index) => {
              if (typeof value === 'number') {
                return {
                  value: formatNumber(2)(value, Units.DOLLAR),
                  type: getPriceType(value, rowMin[index], rowMax[index]),
                  tooltipID: '',
                  tooltipText: ''
                };
              } else {
                return { value, type: PriceType.NEUTRAL, tooltipID: '', tooltipText: '' };
              }
            })
          ),
          [
            {
              value: `${fromYear}-${year || toYear} Avg.`,
              type: PriceType.NEUTRAL,
              tooltipID: '',
              tooltipText: ''
            },
            ...averages.map(a => ({
              value: formatNumber(2)(a.price, Units.DOLLAR),

              type: PriceType.NEUTRAL,
              tooltipID: '',
              tooltipText: ''
            }))
          ],
          [
            {
              value: 'Expected Price Percentile',
              type: PriceType.NEUTRAL,
              tooltipID: '',
              tooltipText: ''
            },
            ...percentiles.map((percentile, index) => ({
              value: isPriceTrendExpectedDataAvailable
                ? `${formatNumber(0)(percentile, Units.PERCENT)}`
                : '',
              type: PriceType.NEUTRAL,
              tooltipID: isPriceTrendExpectedDataAvailable ? 'Expected Price Percentile' : '',
              tooltipText: isPriceTrendExpectedDataAvailable
                ? `Expected ${priceTrendProductsOnHover[product]} for ${quarter?.quarter},
                observed on ${currentExpectedPriceDate} was ${currentExpectedPrice}.
                This price was higher than ${formatNumber(0)(
                  percentile,
                  Units.PERCENT
                )} of historical actual
                ${quarterByIndex(index)} ${
                  priceTrendProductsOnHover[product]
                }s observed over ${fromYear} to ${toYear}.`
                : ''
            }))
          ]
        ]
      });
    });
    return returnData;
  }
);

export const getExpectedHistoricalPricesForQuarter = createSelector(
  getPriceTrends,
  getPriceTrendProducts,
  (priceTrends, priceTrendProducts) => {
    return priceTrendProducts.map(product => {
      return {
        name: product,
        values: priceTrends[product] ? priceTrends[product].expected : undefined
      };
    });
  }
);

export const getPriceTrendsChartData = createSelector(
  getPriceTrends,
  getPriceTrendProducts,
  (priceTrends, products) => {
    const result = {
      actual: [],
      expected: []
    } as PriceTrendsChartData;

    const actualDatesMap: { [date: string]: PriceTrendsData } = {};
    const expectedDatesMap: { [date: string]: PriceTrendsData } = {};

    products.forEach(product => {
      const currentProductData = priceTrends[product];
      if (currentProductData) {
        const { actual, expected } = currentProductData;

        if (actual?.values) {
          actual.values.forEach(({ date, price }) => {
            const dateString = formatDateYearMonth(date);
            if (!actualDatesMap[dateString]) {
              actualDatesMap[dateString] = mergeWithMetaData(createEmptyRecord(products), {
                collection: quarterMonths(date),
                name: dateString
              });
            }
            actualDatesMap[dateString][product] = price;
          });
        }

        if (expected?.values) {
          expected.values.forEach(({ date, price }) => {
            const dateString = formatDateYearMonthDay(date);
            if (!expectedDatesMap[dateString]) {
              expectedDatesMap[dateString] = mergeWithMetaData(createEmptyRecord(products), {
                collection: formatDateYearMonthDay(new Date()),
                name: dateString
              });
            }
            expectedDatesMap[dateString][product] = price;
          });
        }
      }
    });

    result.actual = Object.values(sortObjectByDateKeys(actualDatesMap));
    result.expected = Object.values(expectedDatesMap);

    return result;
  }
);
