import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { Agency } from '../../../types/Agency';
import FilteredOperationProducers from '../../../types/Producer/FilteredOperationProducers';
import Producer from '../../../types/Producer/Producer';
import { ProducerDto } from '../../../types/dto/ProducerDto';
import sortObjectByKey from '../../../utils/sortObjectByKey';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { loadersActions } from '../../loaders/actions';
import { adminActions } from '../actions';
import { mapProducerDtoToProducer } from '../producers/mapProducerDtoToProducer';
import { getOperationsProducers, getProducers } from '../selectors';

function* fetchAgencyOperationProducers(
  action: ActionType<typeof adminActions.operationsProducers.getAgencyOperationProducers>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const producers: Producer[] = yield select(getProducers);
    const operationProducers: AxiosResponse<ProducerDto[]> = yield call(
      insuranceAPI.getAgencyOperationProducers,
      action.payload,
      action.meta
    );
    const agencyOperationProducers: FilteredOperationProducers[] = [];
    if (operationProducers.data.length === 0) {
      producers.forEach(producer => agencyOperationProducers.push({ ...producer, isAdded: false }));
    } else if (producers && producers.length !== 0) {
      producers.forEach(producer => {
        const index = operationProducers.data.findIndex(
          operationProducer => operationProducer.userId === producer.userId
        );
        if (index !== -1) {
          agencyOperationProducers.push({ ...producer, isAdded: true });
        } else {
          agencyOperationProducers.push({ ...producer, isAdded: false });
        }
      });
    } else if (operationProducers.data.length !== 0) {
      mapProducerDtoToProducer(operationProducers.data).forEach(producer =>
        agencyOperationProducers.push({ ...producer, isAdded: true })
      );
    }
    yield put(
      adminActions.operationsProducers.addAgencyOperationProducers(agencyOperationProducers, {
        agencyName: action.payload,
        operationName: action.meta
      })
    );
    yield put(loadersActions.spinner.disable());
  } catch (error: any) {
    yield put(loadersActions.spinner.disable());
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* addProducerToOperation(
  action: ActionType<typeof adminActions.operationsProducers.assignAgencyProducerToOperation>
) {
  try {
    const { agencyName, operationName, user } = action.payload;
    yield call(insuranceAPI.createAgencyOperationProducer, agencyName, operationName, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      timeZone: user.timeZone,
      principalContactAgent: user.principalContactAgent
    });
    yield put(adminActions.operationsProducers.addAgencyProducerToOperation({ ...action.payload }));
    toast.success('Producer added to operation');
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* removeProducerFromOperation(
  action: ActionType<typeof adminActions.operationsProducers.removeAgencyProducerFromOperation>
) {
  try {
    const { agencyName, operationName, user } = action.payload;
    yield call(
      insuranceAPI.removeAgencyOperationProducer,
      agencyName,
      operationName,
      user.userName
    );
    yield put(
      adminActions.operationsProducers.deleteAgencyProducerFromOperation({
        agencyName,
        operationName,
        userId: user.userId
      })
    );
    toast.success('Producer removed from operation');
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

export function* sortOperationProducers({
  payload: { sort, direction },
  meta: { agencyName, operationName }
}: ActionType<typeof adminActions.operationsProducers.sort>): any {
  const operations = yield select(getOperationsProducers);
  const agency: Agency | null = yield select(getAdminPageAgency);

  if (
    !!agency &&
    !!operations &&
    !!operations[agency.name] &&
    !!operations[agency.name][operationName]
  ) {
    const sortedProducers = [...operations[agency.name][operationName]];
    sortedProducers.sort(sortObjectByKey(sort, direction));

    yield put(
      adminActions.operationsProducers.addAgencyOperationProducers(sortedProducers, {
        agencyName,
        operationName
      })
    );
  }
}

export function* operationProducersSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.operationsProducers.getAgencyOperationProducers),
      fetchAgencyOperationProducers
    ),
    takeLatest(
      getType(adminActions.operationsProducers.assignAgencyProducerToOperation),
      addProducerToOperation
    ),
    takeLatest(
      getType(adminActions.operationsProducers.removeAgencyProducerFromOperation),
      removeProducerFromOperation
    ),
    takeLatest(getType(adminActions.operationsProducers.sort), sortOperationProducers)
  ]);
}
