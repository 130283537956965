import { AxiosResponse } from 'axios';
import { isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { Practice } from '../../../types/Practice';
import { InsuranceDto } from '../../../types/dto/InsuranceDto';
import { PracticeDto } from '../../../types/dto/PracticeDto';
import mapInsuranceDto from '../../dairyRevenueProtection/mapInsuranceDto';
import mapPracticeDtoToPractice from '../../dairyRevenueProtection/mapPracticeDtoToPractice';
import {
  ClassPriceWeightingFactorRestrictedValue,
  ComponentPriceWeightingFactorRestrictedValue
} from '../../dairyRevenueProtection/settings/reducer';
import { loadersActions } from '../../loaders/actions';
import { getOperationName } from '../../operationPage/selectors';
import { scenarioAnalysisActions } from '../actions';
import {
  getScenarioEndorsementCalendarDate,
  getScenarioEndorsementCanSellNote,
  getScenarioEndorsementCoverageLevel,
  getScenarioEndorsementDeclaredButterfatTest,
  getScenarioEndorsementDeclaredClassPriceWeightingFactor,
  getScenarioEndorsementDeclaredComponentPriceWeightingFactor,
  getScenarioEndorsementDeclaredMilkProduction,
  getScenarioEndorsementDeclaredProteinTest,
  getScenarioEndorsementProtectionFactor,
  getScenarioEndorsementQuarter,
  getScenarioEndorsementQuarterList,
  getScenarioEndorsementReinsuranceYear,
  getScenarioEndorsementSalesEffectiveDate,
  getScenarioEndorsementShare,
  getScenarioEndorsementState
} from '../selectors';

function* initScenarioEndorsementModal(
  action: ActionType<typeof scenarioAnalysisActions.endorsementModal.init>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const { operationName, stateCode } = action.payload;
    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePractice,
      operationName,
      stateCode
    );
    const practice: Practice = mapPracticeDtoToPractice(practiceDto);
    yield put(scenarioAnalysisActions.endorsementModal.initModalPracticeData(practice));
    yield call(fetchScenarioEndorsementData);
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* fetchScenarioEndorsementData(): any {
  try {
    yield put(loadersActions.spinner.activate());
    const operationName = yield select(getOperationName);

    const stateCode = yield select(getScenarioEndorsementState);
    const quarterName = yield select(getScenarioEndorsementQuarter);
    const share: number = yield select(getScenarioEndorsementShare);

    const quarterList: Array<{
      quarter: string;
      practiceCode: number;
      proteinTest: number;
      butterfatTest: number;
      componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
      classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
    }> = yield select(getScenarioEndorsementQuarterList);
    let practiceCode: number;
    if (quarterList.findIndex(({ quarter }) => quarter === quarterName) === -1) {
      practiceCode = quarterList[0].practiceCode;
    } else {
      const index = quarterList.findIndex(({ quarter }) => quarter === quarterName);
      practiceCode = quarterList[index].practiceCode;
    }

    const coveredMilkProduction = yield select(getScenarioEndorsementDeclaredMilkProduction);
    const salesEffectiveDate = yield select(getScenarioEndorsementSalesEffectiveDate);
    let coverageLevel = yield select(getScenarioEndorsementCoverageLevel);
    let butterfatTest = yield select(getScenarioEndorsementDeclaredButterfatTest);
    const protectionFactor = yield select(getScenarioEndorsementProtectionFactor);
    let proteinTest = yield select(getScenarioEndorsementDeclaredProteinTest);
    const clsPriceWeightingFactor = yield select(
      getScenarioEndorsementDeclaredClassPriceWeightingFactor
    );
    const cmpPriceWeightingFactor = yield select(
      getScenarioEndorsementDeclaredComponentPriceWeightingFactor
    );
    const reinsuranceYear = yield select(getScenarioEndorsementReinsuranceYear);

    const calendarDate = yield select(getScenarioEndorsementCalendarDate);
    if (isBefore(calendarDate, new Date('July 1, 2019'))) {
      if (butterfatTest < 3.5) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredButterfatTest(3.5));
        butterfatTest = 3.5;
      }
      if (proteinTest < 3) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredProteinTest(3));
        proteinTest = 3;
      }
    }

    if (isBefore(calendarDate, new Date('July 1, 2020'))) {
      if (butterfatTest > 5) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredButterfatTest(5));
        butterfatTest = 5;
      }
      if (proteinTest > 4) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredProteinTest(4));
        proteinTest = 4;
      }
    }

    if (isAfter(calendarDate, new Date('July 1, 2019'))) {
      if (coverageLevel < 0.8) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalCoverageLevel(0.8));
        coverageLevel = 0.8;
      }
    }

    const practices: Array<{
      practiceCode: number;
      componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
      classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
    }> = yield select(getScenarioEndorsementQuarterList);
    const practiceIndex = practices.findIndex(practice => practice.practiceCode === practiceCode);
    const componentPriceWeightingFactor =
      practices[practiceIndex]?.componentPriceWeightingFactorRestrictedValue ||
      cmpPriceWeightingFactor;
    const classPriceWeightingFactor =
      practices[practiceIndex]?.classPriceWeightingFactorRestrictedValue || clsPriceWeightingFactor;

    const response: AxiosResponse<InsuranceDto> = yield call(
      insuranceAPI.getInsurance,
      {
        operationName,
        share,
        stateCode,
        practiceCode,
        salesEffectiveDate,
        butterfatTest,
        proteinTest,
        classPriceWeightingFactor,
        coverageLevel,
        coveredMilkProduction,
        protectionFactor,
        componentPriceWeightingFactor
      },
      reinsuranceYear
    );
    const mappedResponse = mapInsuranceDto(response);

    yield put(
      scenarioAnalysisActions.endorsementModal.setModalData({
        expectedRevenue: mappedResponse.priceComponents.expectedRevenue,
        revenueGuarantee: mappedResponse.priceComponents.revenueGuarantee,
        producerPremium: mappedResponse.priceComponents.producerPremiumAmount,
        expectedRevenueCwt: mappedResponse.whatIfDairyComponents.expectedDRPRevenueCwt,
        revenueGuaranteeCwt: mappedResponse.priceComponents.revenueGuaranteeCwt,
        producerPremiumCwt: mappedResponse.priceComponents.producerPremiumAmountCwt
      })
    );
    yield put(scenarioAnalysisActions.endorsementModal.setIsSalesDateAvailable(true));
  } catch (error: any) {
    if (error.response.status === 404 || error.response.status === 500) {
      const em = yield select(getScenarioEndorsementCanSellNote);
      yield put(scenarioAnalysisActions.endorsementModal.setIsSalesDateAvailable(false));
      toast.error(em ? em : 'Sales date not available', {
        position: 'top-right'
      });
    }
    console.error(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* updatePracticeAndModalData(
  action: ActionType<typeof scenarioAnalysisActions.endorsementModal.setModalCalendarDate>
): any {
  try {
    yield put(loadersActions.spinner.activate());
    const operationName = yield select(getOperationName);
    const stateCode = yield select(getScenarioEndorsementState);
    const salesDate = yield select(getScenarioEndorsementCalendarDate);

    const paddedStateCode = String(stateCode).padStart(2, '0');

    const butterfatTest = yield select(getScenarioEndorsementDeclaredButterfatTest);
    const proteinTest = yield select(getScenarioEndorsementDeclaredProteinTest);
    const coverageLevel = yield select(getScenarioEndorsementCoverageLevel);
    if (isBefore(action.payload, new Date('July 1, 2019'))) {
      if (butterfatTest < 3.5) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredButterfatTest(3.5));
      }
      if (proteinTest < 3) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredProteinTest(3));
      }
    }

    if (isBefore(action.payload, new Date('July 1, 2020'))) {
      if (butterfatTest > 5) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredButterfatTest(5));
      }
      if (proteinTest > 4) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalDeclaredProteinTest(4));
      }
    }

    if (isAfter(action.payload, new Date('July 1, 2019'))) {
      if (coverageLevel < 0.8) {
        yield put(scenarioAnalysisActions.endorsementModal.setModalCoverageLevel(0.8));
      }
    }

    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePracticeSales,
      operationName,
      paddedStateCode,
      salesDate
    );
    const practice: Practice = mapPracticeDtoToPractice(practiceDto);

    yield put(
      scenarioAnalysisActions.endorsementModal.setModalPracticeData({
        salesEffectiveDate: practice.salesEffectiveDate,
        canSell: practice.canSell,
        canSellNote: practice.canSellNote,
        quarterList: practice.practices,
        isSalesDateAvailable: practice.salesEffectiveDate !== null,
        reinsuranceYear: practice.reinsuranceYear
      })
    );

    if (practice.practices.length) {
      const code = yield select(getScenarioEndorsementQuarter);
      if (practice.practices.findIndex(({ quarter }) => quarter === code) === -1) {
        yield put(
          scenarioAnalysisActions.endorsementModal.setModalQuarter(practice.practices[0].quarter)
        );
      } else {
        yield put(scenarioAnalysisActions.endorsementModal.setModalQuarter(code));
      }
    }

    if (!practice.salesEffectiveDate) {
      yield put(scenarioAnalysisActions.endorsementModal.setIsSalesDateAvailable(false));
    }
    yield call(fetchScenarioEndorsementData);
  } catch (e) {
    yield put(scenarioAnalysisActions.endorsementModal.setIsSalesDateAvailable(false));
    console.error(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* scenarioAnalysisEndorsementSettingsSagaWatcher() {
  yield all([
    takeLatest(
      getType(scenarioAnalysisActions.endorsementModal.init),
      initScenarioEndorsementModal
    ),
    debounce(
      800,
      [
        getType(scenarioAnalysisActions.endorsementModal.setModalQuarter),
        getType(scenarioAnalysisActions.endorsementModal.setModalDeclaredMilkProduction),
        getType(scenarioAnalysisActions.endorsementModal.setModalCoverageLevel),
        getType(scenarioAnalysisActions.endorsementModal.setModalProtectionFactor),
        getType(scenarioAnalysisActions.endorsementModal.setModalDeclaredButterfatTest),
        getType(scenarioAnalysisActions.endorsementModal.setModalDeclaredProteinTest),
        getType(scenarioAnalysisActions.endorsementModal.setModalDeclaredClassPriceWeightingFactor),
        getType(
          scenarioAnalysisActions.endorsementModal.setModalDeclaredComponentPriceWeightingFactor
        ),
        getType(scenarioAnalysisActions.endorsementModal.setModalShare)
      ],
      fetchScenarioEndorsementData
    ),
    debounce(
      800,
      [getType(scenarioAnalysisActions.endorsementModal.setModalCalendarDate)],
      updatePracticeAndModalData
    )
  ]);
}

export default scenarioAnalysisEndorsementSettingsSagaWatcher;
