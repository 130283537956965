import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import ScenarioAnalysisWhatIfPayload from '../../../types/ScenarioWhatIf/ScenarioAnalysisWhatIfPayload';
import { loadersActions } from '../../loaders/actions';
import { scenarioAnalysisActions } from '../actions';
import { getScenarioWhatIfPrices } from '../selectors';
import mapScenarioAnalysisWhatIfDto from './mapScenarioAnalysisWhatIfDto';
import mapScenarioAnalysisWhatIfPayload from './mapScenarioAnalysisWhatIfPayload';
import updateWhatIfPrices from './updateWhatIfPrices';

function* updateScenarioAnalysisWhatIfPrices(
  action: ActionType<typeof scenarioAnalysisActions.updateScenarioWhatIfPrices>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const allWhatIfPrices = yield select(getScenarioWhatIfPrices);

  try {
    const whatIfOld = mapScenarioAnalysisWhatIfPayload(action.payload.whatIfOld);
    const whatIfNew = mapScenarioAnalysisWhatIfPayload(action.payload.whatIfNew);
    const whatIfPrices: AxiosResponse<ScenarioAnalysisWhatIfPayload> = yield call(
      insuranceAPI.updateScenarioAnalysisWhatIf,
      { whatIfOld, whatIfNew }
    );
    const mappedPrices = mapScenarioAnalysisWhatIfDto(
      whatIfPrices.data,
      action.payload.reinsuranceYear
    );
    const newPrices = updateWhatIfPrices(allWhatIfPrices, mappedPrices);
    yield put(scenarioAnalysisActions.saveScenarioWhatIfPrices(newPrices));
  } catch (e) {
    console.error(e);
    toast.error('Failed to update what if prices');
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(true));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* scenarioAnalysisWhatIfSagaWatcher() {
  yield all([
    takeLatest(
      scenarioAnalysisActions.updateScenarioWhatIfPrices,
      updateScenarioAnalysisWhatIfPrices
    )
  ]);
}

export default scenarioAnalysisWhatIfSagaWatcher;
