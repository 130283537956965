import { all } from 'redux-saga/effects';

import UnsubscribeSagaWatcher from '../components/UnsubscribeNewsletter/sagas';
import whatNewSagaWatcher from '../scenes/whatsnew/ducks/sagas';
import { accountPreferencesSagaWatcher } from './accountPreferences/sagas';
import adminRootSaga from './adminPanel/sagas';
import { agencyLogoSagaWatcher } from './agencyLogo/sagas';
import { alertsSagaWatcher } from './alerts/sagas';
import areasSagaWatcher from './areas/sagas';
import authSagaWatcher from './authentication/sagas';
import calendarSagaWatcher from './calendar/sagas';
import dairyRevenueProtectionSagaWatcher from './dairyRevenueProtection/sagas';
import salesStatisticsSagaWatcher from './drpSalesStatistics/sagas';
import educationModuleSagaWatcher from './educationModule/sagas';
import { endorsementSagaWatcher } from './endorsement/sagas';
import historicalDataSagaWatcher from './historicalData/sagas';
import { lgmRootSagas } from './lgm/sagas';
import lrpSagaWatcher from './lrp/sagas';
import managementReportSagaWatcher from './managementReports/sagas';
import operationContactRootSaga from './operationContact/sagas';
import { operationPageSagaWatcher } from './operationPage/sagas';
import { operationPreferencesSagaWatcher } from './operationPreferences/sagas';
import { priceReportSagaWatcher } from './priceReport/sagas';
import { priceTrendsSagaWatcher } from './priceTrends/sagas';
import projectionDataSagaWatcher from './projectionData/sagas';
import scenarioSagaWatcher from './scenario/sagas';
import scenarioAnalysisSagaWatcher from './scenarioAnalysis/sagas';
import { tagTypeSagaWatcher } from './tagTypes/sagas';
import { tagSagaWatcher } from './tags/sagas';
import userAgentSaga from './userAgent/sagas';

export default function* rootSaga() {
  yield all([
    authSagaWatcher(),
    managementReportSagaWatcher(),
    areasSagaWatcher(),
    dairyRevenueProtectionSagaWatcher(),
    adminRootSaga(),
    scenarioSagaWatcher(),
    salesStatisticsSagaWatcher(),
    lrpSagaWatcher(),
    educationModuleSagaWatcher(),
    endorsementSagaWatcher(),
    historicalDataSagaWatcher(),
    operationPageSagaWatcher(),
    agencyLogoSagaWatcher(),
    operationContactRootSaga(),
    accountPreferencesSagaWatcher(),
    operationPreferencesSagaWatcher(),
    priceReportSagaWatcher(),
    priceTrendsSagaWatcher(),
    alertsSagaWatcher(),
    calendarSagaWatcher(),
    lgmRootSagas(),
    projectionDataSagaWatcher(),
    scenarioAnalysisSagaWatcher(),
    whatNewSagaWatcher(),
    tagSagaWatcher(),
    tagTypeSagaWatcher(),
    UnsubscribeSagaWatcher(),
    userAgentSaga()
  ]);
}
