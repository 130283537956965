import { BindRequirements } from '../dto/AgentDto';
import InsurancePlan from '../dto/InsurancePlan';
import { UserDto } from '../dto/UserDto';

export interface Agent extends UserDto {
  isAdmin: boolean;
  organizationName: string;
  licences: AgentLicense[];
  primaryContact: boolean;
  bindRequirements?: BindRequirements[];
}

export interface AgentLicense {
  stateCode: string;
  stateName: string;
  licenceNumber: string;
}

export const agentCanBind = (agent: Agent, insurancePlan: InsurancePlan): boolean => {
  const insurancePlanRequirements = agentInsurancePlan(agent, insurancePlan);
  return (
    (insurancePlanRequirements !== undefined &&
      Boolean(agent.bindRequirements?.filter(obj => obj.canBind))) ??
    false
  );
};

export const agentInsurancePlan = (agent: Agent, insurancePlan: InsurancePlan) => {
  const insurancePlanRequirements = agent.bindRequirements?.find(x => {
    return x.insurancePlans?.find(y => {
      return y.toLowerCase() === InsurancePlan[insurancePlan].toLowerCase();
    });
  });
  return insurancePlanRequirements;
};

export default Agent;
