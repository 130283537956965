export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const mapQuarter = (quarter: number) => {
  switch (quarter) {
    case 1:
      return 'Jan-Mar';
    case 2:
      return 'Apr-Jun';
    case 3:
      return 'Jul-Sep';
    case 4:
      return 'Oct-Dec';
    default:
      return '';
  }
};

export const parseQuarter = (quarter: string) => {
  switch (quarter) {
    case 'Jan-Mar':
      return 1;
    case 'Apr-Jun':
      return 2;
    case 'Jul-Sep':
      return 3;
    case 'Oct-Dec':
      return 4;
    default:
      return 1;
  }
};

export const formatDateMMMyyyy = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth();
  const year = date.getFullYear();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  return months[month] + ' ' + year;
};
