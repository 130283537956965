import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { agencyLogoActions } from './actions';

export function* getAgencyLogo(
  action: ActionType<typeof agencyLogoActions.getAdminAgencyLogo>
): any {
  try {
    const logo = yield call(insuranceAPI.getAdminAgencyLogo, action.payload);
    yield put(agencyLogoActions.saveAgencyLogo(logo.data));
  } catch (error) {
    console.error(error);
  }
}

export function* getLogo() {
  try {
    const logo: AxiosResponse<string> = yield call(insuranceAPI.getAgencyLogo);
    yield put(agencyLogoActions.saveAgencyLogo(logo.data));
  } catch (error) {
    console.log(error);
  }
}

export function* agencyLogoSagaWatcher() {
  yield all([
    takeLatest(getType(agencyLogoActions.getAgencyLogo), getLogo),
    takeLatest(getType(agencyLogoActions.getAdminAgencyLogo), getAgencyLogo)
  ]);
}
