import Producer from '../../../types/Producer/Producer';
import { ProducerDto } from '../../../types/dto/ProducerDto';

export const mapProducerDtoToProducer = (data: ProducerDto[]): Producer[] =>
  data.map(
    ({
      email,
      emailConfirmed,
      firstName,
      isInvitationExpired,
      lastInviteOn,
      lastName,
      phoneNumber,
      timeZone,
      title,
      userId,
      userName,
      principalContactAgent,
      bindRequirements,
      hometown,
      lockoutEnabled,
      lockoutEnd,
      phoneNumberConfirmed,
      twoFactorEnabled
    }) => ({
      email: email,
      emailConfirmed: emailConfirmed,
      firstName: firstName,
      isInvitationExpired: isInvitationExpired,
      lastInviteOn: lastInviteOn,
      lastName: lastName,
      phoneNumber: phoneNumber,
      timeZone: timeZone,
      title: title ? title : '',
      userId: userId,
      userName: userName,
      principalContactAgent: principalContactAgent ? principalContactAgent : '',
      bindRequirements: bindRequirements,
      hometown: hometown,
      lockoutEnabled: lockoutEnabled,
      lockoutEnd: lockoutEnd,
      phoneNumberConfirmed: phoneNumberConfirmed,
      twoFactorEnabled: twoFactorEnabled
    })
  );
