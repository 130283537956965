import SortDirection from '../enums/SortDirection';

const sortObjectByKey =
  <T>(key: keyof T, sortDirection: SortDirection) =>
  (a: T, b: T) => {
    let firstValue: any = a[key];
    let secondValue: any = b[key];

    if (typeof firstValue === 'string' && typeof secondValue === 'string') {
      firstValue = firstValue.toLowerCase();
      secondValue = secondValue.toLowerCase();
    } else {
      firstValue = a[key];
      secondValue = b[key];
    }

    if (sortDirection === SortDirection.ASCENDING) {
      if (firstValue < secondValue) {
        return -1;
      }
      if (firstValue > secondValue) {
        return 1;
      }
      return 0;
    } else {
      if (secondValue < firstValue) {
        return -1;
      }
      if (secondValue > firstValue) {
        return 1;
      }
      return 0;
    }
  };

export default sortObjectByKey;
