import { combineReducers } from 'redux';

import { scenarioAnalysisDataReducer } from './analysisData/reducer';
import { endorsementSettingsReducer } from './endorsementSettings/reducer';
import { scenarioAnalysisProjectedRevenueReducer } from './projectedRevenue/reducer';
import { scenarioWhatIfReducer } from './scenarioWhatIf/reducer';
import { scenariosReducer } from './scenarios/reducer';
import { scenarioEndorsementDataReducer } from './summaries/reducer';

export const scenarioAnalysisReducer = combineReducers({
  endorsementModal: endorsementSettingsReducer,
  endorsementsData: scenarioEndorsementDataReducer,
  scenarioWhatIf: scenarioWhatIfReducer,
  scenarios: scenariosReducer,
  data: scenarioAnalysisDataReducer,
  projectedRevenue: scenarioAnalysisProjectedRevenueReducer
});
