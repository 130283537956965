import { createSelector } from 'reselect';

import OperationContact from '../../types/Operation/OperationContact';
import containsString from '../../utils/containsString';
import { getOperations } from '../adminPanel/selectors';
import { getStatesList } from '../areas/selectors';
import { getAgentModalSearchFromProps, getStateModalSearchFromProps } from '../fromPropsSelector';
import { getAgencyOperationsContacts } from '../operationContact/selectors';
import { RootState } from '../store';

export const getOperationFilterSort = (state: RootState) => state.operationFilters.sort;

export const getOperationFilterIsDemo = (state: RootState) => state.operationFilters.isDemo;

export const getFilteredAgents = (state: RootState) => state.operationFilters.filteredAgents;

export const getFilteredState = (state: RootState) => state.operationFilters.filteredState;

export const getFilteredInsurance = (state: RootState) => state.operationFilters.filteredInsurance;

export const getFilteredAip = (state: RootState) => state.operationFilters.filteredAip;

export const getFilteredModalAgents = createSelector(
  getAgencyOperationsContacts,
  getFilteredAgents,
  getAgentModalSearchFromProps,
  (operationContacts, filteredAgents, search) => {
    const searchedContacts = operationContacts.filter(
      ({ firstName, lastName }) =>
        containsString(firstName, search) || containsString(lastName, search)
    );
    const contacts: OperationContact[] = [];
    searchedContacts.forEach(pc => {
      if (contacts.findIndex(x => x.userId === pc.userId) < 0) {
        contacts.push(pc);
      }
    });

    if (!filteredAgents.length) {
      return contacts.map(operationContact => ({ ...operationContact, selected: false }));
    }

    return contacts.map(operationContact => {
      if (filteredAgents.find(agent => agent.userId === operationContact.userId)) {
        return { ...operationContact, selected: true };
      }
      return { ...operationContact, selected: false };
    });
  }
);

export const getFilteredStateList = createSelector(
  getOperations,
  getStatesList,
  getFilteredState,
  getStateModalSearchFromProps,
  (operations, stateList, filteredStates, search) => {
    const operationsStateList = operations.map(operation => operation.stateCode);
    const availableStateList = stateList.filter(state =>
      operationsStateList.find(stateCode => stateCode === state.stateCode)
    );

    const searchedStateList = availableStateList.filter(({ stateName }) =>
      containsString(stateName, search)
    );
    if (!filteredStates.length) {
      return searchedStateList.map(state => ({ ...state, selected: false }));
    }
    return searchedStateList.map(state => {
      if (filteredStates.find(filteredState => filteredState.stateName === state.stateName)) {
        return { ...state, selected: true };
      }
      return { ...state, selected: false };
    });
  }
);
