import { createSelector } from 'reselect';

import { getStateCodeFromProps } from '../fromPropsSelector';
import { RootState } from '../store';

export const getStatesList = (state: RootState) => state.areas.states;

export const getCountiesList = (state: RootState) => state.areas.counties;

export const getStatesSelectList = createSelector(getStatesList, list =>
  list.map(({ stateName, stateCode }) => ({ name: stateName, id: Number(stateCode) }))
);

export const getCountiesByStateCode = createSelector(
  getCountiesList,
  getStateCodeFromProps,
  (counties, stateCode) => {
    const stateCounties = counties[stateCode] ? counties[stateCode] : [];
    const countiesList: Array<{ id: number; name: string }> = stateCounties.map(county => ({
      id: Number(county.countyCode),
      name: county.countyName
    }));
    return countiesList;
  }
);
