import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { SagaReturnType, all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { Agency } from '../../../types/Agency';
import { ProducerDto } from '../../../types/dto/ProducerDto';
import { errorResponseStatusHandler } from '../../../utils/responseStatusHandler';
import sortObjectByKey from '../../../utils/sortObjectByKey';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { loadersActions } from '../../loaders/actions';
import { adminActions } from '../actions';
import { getProducers } from '../selectors';
import { mapProducerDtoToProducer } from './mapProducerDtoToProducer';

export function* getAllProducers(
  action: ActionType<typeof adminActions.producers.getAllProducers>
) {
  try {
    yield put(loadersActions.adminProducers.activate());
    const producers: AxiosResponse<ProducerDto[]> = yield call(
      insuranceAPI.getAgencyProducers,
      action.payload
    );
    const mappedProducers = mapProducerDtoToProducer(producers.data);
    yield put(adminActions.producers.addAllProducers(mappedProducers, action.payload));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.adminProducers.disable());
  }
}

export function* addProducer(action: ActionType<typeof adminActions.producers.addProducer>) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency | null = yield select(getAdminPageAgency);
    const agencyName = agency?.name ?? '';
    const { firstName, lastName, email, phoneNumber, timeZone, principalContactAgent, title } =
      action.payload.producer;
    const producerData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      timeZone,
      principalContactAgent,
      title
    };
    yield call(
      insuranceAPI.createAgencyOperationProducer,
      agencyName,
      action.payload.operation,
      producerData
    );
    yield put(adminActions.producers.getAllProducers(agencyName));
    yield toast.success('Producer created');
  } catch (error) {
    console.log(error);
  } finally {
    yield action.meta();
    yield put(loadersActions.spinner.disable());
  }
}

export function* editProducer(action: ActionType<typeof adminActions.producers.editProducer>) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency | null = yield select(getAdminPageAgency);
    const agencyName = agency?.name ?? '';
    const { firstName, lastName, email, phoneNumber, timeZone, principalContactAgent, title } =
      action.payload;
    const producerData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      timeZone,
      principalContactAgent,
      title
    };
    yield call(insuranceAPI.updateAgencyProducer, agencyName, action.meta.userId, producerData);
    yield put(
      adminActions.producers.updateProducer(action.payload, { ...action.meta, agency: agencyName })
    );
    yield toast.success('Producer updated');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta.cb();
  }
}

export function* deleteProducer(action: ActionType<typeof adminActions.producers.removeProducer>) {
  yield put(loadersActions.spinner.activate());
  try {
    const agency: Agency | null = yield select(getAdminPageAgency);
    const agencyName = agency?.name ?? '';
    yield call(insuranceAPI.deleteAgencyProducer, agencyName, action.payload);
    yield put(adminActions.producers.deleteProducer(action.payload, agencyName));
    toast.success('Producer deleted');
  } catch (error) {
    errorResponseStatusHandler(
      'User is connected to Request Coverages, and can&quot;t be deleted.'
    );
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
  }
}

export function* resendInvitation(
  action: ActionType<typeof adminActions.insuranceAgents.resendInvitation>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(insuranceAPI.resendProducerInvitation, agency?.name ?? '', action.payload);
    toast.success('Invitation sent to producer ' + action.payload);
  } catch (error) {
    console.log(error);
  } finally {
    yield action.meta();
    yield put(loadersActions.spinner.disable());
  }
}

type ProducersType = SagaReturnType<typeof getProducers>;
type ProducerKeyType = keyof ProducerDto;
export function* sortProducers({
  payload: { sort, direction },
  meta
}: ActionType<typeof adminActions.producers.sort>) {
  const producers: ProducersType = yield select(getProducers);
  if (producers) {
    const sortedProducers = [...producers].sort(
      sortObjectByKey(sort as ProducerKeyType, direction)
    );

    yield put(adminActions.producers.addAllProducers(sortedProducers, meta));
  }
}

export function* producersSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.producers.getAllProducers), getAllProducers),
    takeLatest(getType(adminActions.producers.addProducer), addProducer),
    takeLatest(getType(adminActions.producers.editProducer), editProducer),
    takeLatest(getType(adminActions.producers.removeProducer), deleteProducer),
    takeLatest(getType(adminActions.producers.resendInvitation), resendInvitation),
    takeLatest(getType(adminActions.producers.sort), sortProducers)
  ]);
}
