import { getType } from 'typesafe-actions';

import Producer from '../../../types/Producer/Producer';
import { AdminActions, adminActions } from '../actions';

interface InitialState {
  [key: string]: Producer[];
}

const initialState: InitialState = {};

const producersReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.producers.addAllProducers): {
      const newState = { ...state };
      newState[action.meta] = action.payload;
      return newState;
    }
    case getType(adminActions.producers.newProducer): {
      const newState = { ...state };
      if (newState[action.meta]) {
        newState[action.meta] = [...newState[action.meta], action.payload];
      } else {
        newState[action.meta] = [action.payload];
      }
      return newState;
    }
    case getType(adminActions.producers.updateProducer): {
      const newState = { ...state };
      const index = newState[action.meta.agency].findIndex(
        user => user.userId === action.meta.userId
      );
      if (index !== -1) {
        newState[action.meta.agency] = [...newState[action.meta.agency]];
        newState[action.meta.agency][index] = {
          ...newState[action.meta.agency][index],
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
          phoneNumber: action.payload.phoneNumber,
          timeZone: action.payload.timeZone,
          principalContactAgent: action.payload.principalContactAgent,
          title: action.payload.title ?? ''
        };
        return newState;
      }
      return state;
    }
    case getType(adminActions.producers.deleteProducer): {
      const newState = { ...state };
      const index = state[action.meta].findIndex(user => user.userId === action.payload);
      if (index !== -1) {
        if (index !== -1) {
          newState[action.meta] = [...newState[action.meta]];
          newState[action.meta].splice(index, 1);
          return newState;
        }
      }
      return state;
    }
    default:
      return state;
  }
};

export default producersReducer;
