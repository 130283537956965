import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import CardOptions from '../../types/CardOptions';
import { DrpStatisticsDto, GroupStatisticsAllDto } from '../../types/dto/DrpStatisticsDto';
import { DrpSalesStatisticsActions, drpSalesStatisticsActions } from './actions';

interface State {
  statisticsUSA: DrpStatisticsDto;
  statisticsState: DrpStatisticsDto;
  statisticsCounties: DrpStatisticsDto;
  groupStatistics: GroupStatisticsAllDto;
  cardOptions: CardOptions[];
}

const initialState: State = {
  statisticsUSA: {
    pricingOption: [],
    protectionFactor: [],
    classWeightingFactor: [],
    componentWeightingFactor: [],
    declaredButterfatTest: [],
    declaredProteinTest: [],
    hedgingHorizon: [],
    locations: []
  },
  statisticsState: {
    pricingOption: [],
    protectionFactor: [],
    classWeightingFactor: [],
    componentWeightingFactor: [],
    declaredButterfatTest: [],
    declaredProteinTest: [],
    hedgingHorizon: [],
    locations: []
  },
  statisticsCounties: {
    pricingOption: [],
    protectionFactor: [],
    classWeightingFactor: [],
    componentWeightingFactor: [],
    declaredButterfatTest: [],
    declaredProteinTest: [],
    hedgingHorizon: [],
    locations: []
  },
  groupStatistics: {
    groupedValues: [],
    total: {}
  },
  cardOptions: [
    {
      name: 'Pricing option',
      id: 'pricingOption',
      first: 'Class',
      second: 'Components',
      isSelected: true
    },
    {
      name: 'Protection factor',
      id: 'protectionFactor',
      first: '1.00',
      second: '1.50',
      third: 'Other',
      isSelected: true
    },
    {
      name: 'Class Weighting Factor',
      id: 'classWeight',
      first: 'ClassIII',
      second: 'ClassIV',
      third: 'Mixed',
      isSelected: true
    },
    {
      name: 'Component Weighting Factor',
      id: 'componentWeight',
      first: 'Protein & Other Solids',
      second: 'Nonfat Solids',
      third: 'Mixed',
      isSelected: true
    },
    {
      name: 'Declared butterfat test',
      id: 'butterfatTest',
      first: 'V1',
      second: 'V2',
      third: 'V3',
      isSelected: true
    },
    {
      name: 'Declared Protein test',
      id: 'proteinTest',
      first: 'V1',
      second: 'V2',
      third: 'V3',
      isSelected: false
    },
    { name: 'Hedging Horizon', id: 'hedgingHorizon', first: 'V1', second: 'V2', isSelected: false }
  ]
};

const salesStatisticsReducer = (state = initialState, action: DrpSalesStatisticsActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(drpSalesStatisticsActions.saveStatisticsUSA): {
        draft.statisticsUSA = action.payload;
        return;
      }
      case getType(drpSalesStatisticsActions.saveStatisticsState): {
        const payload = action.payload;
        if (!payload) {
          draft.statisticsState = initialState.statisticsState;
        } else {
          draft.statisticsState = payload;
        }
        return;
      }
      case getType(drpSalesStatisticsActions.saveStatisticsCounties): {
        draft.statisticsCounties = action.payload;
        return;
      }
      case getType(drpSalesStatisticsActions.saveGroupStatistics): {
        draft.groupStatistics = action.payload;
        return;
      }
      case getType(drpSalesStatisticsActions.updateCardOption): {
        const index = draft.cardOptions.findIndex(option => option.name === action.payload);
        draft.cardOptions[index].isSelected = !draft.cardOptions[index].isSelected;
        return;
      }
    }
  });

export default salesStatisticsReducer;
