import { RootState } from '../store';

export const getSpinnerLoaderState = (state: RootState) => state.loaders.spinner;

export const getDairyRevenueProtectionLoaderState = (state: RootState) =>
  state.loaders.dairyRevenueProtection;

export const getAdminPageAgenciesLoaderState = (state: RootState) => state.loaders.adminAgencies;

export const getAdminPageAgentsLoaderState = (state: RootState) => state.loaders.adminAgents;

export const getAdminPageOperationsLoaderState = (state: RootState) =>
  state.loaders.adminOperations;

export const getAdminPageProducersLoaderState = (state: RootState) => state.loaders.adminProducers;

export const getOperationListLoaderState = (state: RootState) => state.loaders.operationList;

export const getAdminPageAgentsModalLoaderState = (state: RootState) =>
  state.loaders.adminAgentsModal;

export const getAdminPageProducersModalLoaderState = (state: RootState) =>
  state.loaders.adminProducersModal;

export const getEndorsementSummaryLoaderState = (state: RootState) =>
  state.loaders.endorsementSummary;

export const getEndorsementDetailedViewLoaderState = (state: RootState) =>
  state.loaders.endorsementDetailedView;

export const getAccountPreferencesLoaderState = (state: RootState) =>
  state.loaders.accountPreferencesLoader;

export const getOperationPreferencesLoaderState = (state: RootState) =>
  state.loaders.operationPreferences;

export const getOperationOverviewDataTableLoaderState = (state: RootState) =>
  state.loaders.operationOverviewDataTable;

export const getDairyRevenueProtectionWhatIfLoaderState = (state: RootState) =>
  state.loaders.dairyRevenueWhatIf;

export const getPendingEndorsementTableLoaderState = (state: RootState) =>
  state.loaders.pendingEndorsementTable;

export const getAllQuartersInitialLoaderState = (state: RootState) =>
  state.loaders.allQuartersInitial;

export const getAllQuartersUpdateLoaderState = (state: RootState) =>
  state.loaders.allQuartersUpdate;

export const getAlertsLoaderState = (state: RootState) => state.loaders.alerts;

export const getRevenueHistoricalChartLoaderState = (state: RootState) =>
  state.loaders.revenueHistoricalChart;

export const getRevenueProjectionLoaderState = (state: RootState) =>
  state.loaders.revenueProjection;

export const getScenarioAnalysisLoaderState = (state: RootState) => state.loaders.scenarioAnalysis;

export const getSpinnerLrpEndorsementModal = (state: RootState) =>
  state.loaders.spinnerLrpEndorsementModal;

export const getSpinnerCoverageRequestHistory = (state: RootState) =>
  state.loaders.spinnerCoverageRequestHistory;

export const getSpinnerCoverageRequestFromMail = (state: RootState) =>
  state.loaders.spinnerCoverageRequestFromMail;
