import { getType } from 'typesafe-actions';

import { Indemnity } from '../../../types/dto/WhatIfDto';
import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

const initialState: Indemnity = {
  class: {
    finalRevenue: 1,
    finalRevenueCwt: 1,
    finalRevenueGuarantee: 1,
    finalRevenueGuaranteeCwt: 1,
    actualRevenue: 1,
    actualRevenueCwt: 1,
    grossIndemnity: 1,
    grossIndemnityCwt: 1
  },
  component: {
    finalRevenue: 1,
    finalRevenueCwt: 1,
    finalRevenueGuarantee: 1,
    finalRevenueGuaranteeCwt: 1,
    actualRevenue: 1,
    actualRevenueCwt: 1,
    grossIndemnity: 1,
    grossIndemnityCwt: 1
  },
  totalActualMilkProduction: 1,
  totalFinalMilkProduction: 1,
  butterfatTestActual: 1,
  proteinTestActual: 1,
  butterfatTestFinal: 1,
  proteinTestFinal: 1
};

export const dairyRevenueProtectionIndemnityReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => (action.type === getType(dairyRevenueProtectionActions.setIndemnity) ? action.payload : state);
