import React, { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import color from '@/utils/color';

import NumberInput from './NumberInput';

interface Props {
  value: number;
  decimals?: number;
  onChange: (value: number) => void;
  onBlur?: (value: number) => void;
  onFocus?: (isFocused: boolean) => void;
  minimum?: number;
  maximum?: number;
  width?: number;
  loading?: boolean;
  disabled?: boolean;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  marginTop?: number;
  paddingTop?: number;
  paddingBottom?: number;
  height?: number;
  backgroundColor?: string;
  name?: string;
  digits?: number;
}

const FormattedNumberInput: React.FC<Props> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  decimals = 4,
  minimum,
  maximum,
  loading,
  disabled,
  digits,
  name,
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [focusState, setFocusState] = useState({ allSelected: false, isInFocus: false });

  useEffect(() => {
    if (focusState.isInFocus && loading) {
      const { selectionStart = 0, selectionEnd = 0, value = '' } = ref.current ?? {};
      setFocusState({
        allSelected: selectionStart === 0 && selectionEnd === value.length,
        isInFocus: true
      });
    } else if (focusState.allSelected) {
      ref.current?.focus();
      ref.current?.setSelectionRange(0, ref.current?.value.length);
      setFocusState({ allSelected: false, isInFocus: true });
    }
  }, [loading]);

  const onFocusHandler = (event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(true);
    setFocusState({ allSelected: false, isInFocus: true });
    event.target.setSelectionRange(0, event.target.value.length);
  };

  const onBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    onFocus?.(false);
    setFocusState({ allSelected: false, isInFocus: false });

    let value = Number(event.target.value.replace(/,/g, ''));
    if (minimum !== undefined && value < minimum) {
      value = minimum;
    }
    if (maximum !== undefined && value > maximum) {
      value = maximum;
    }
    if (digits !== undefined && value.toString().length > digits) {
      value = Number(value.toFixed(digits));
    }
    onBlur?.(value);
  };

  const keyPressHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (['ArrowUp', 'ArrowDown'].includes(event.key)) event.preventDefault();
    if (['Enter'].includes(event.key)) (event.target as HTMLElement).blur();
  };

  return (
    <StyledInput
      ref={ref}
      separatorType="us"
      value={value}
      decimal={decimals}
      onValueChange={onChange}
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
      onKeyDown={keyPressHandler}
      disabled={loading ?? disabled}
      loading={loading}
      name={name}
      {...rest}
    />
  );
};

export default FormattedNumberInput;

const StyledInput = styled(NumberInput)`
  color: ${color.DRP_INPUT};
  background-color: ${({ backgroundColor }) => backgroundColor ?? color.BACKGROUND_SECONDARY};
  border-radius: 6px;
  line-height: 27px;
  font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
  font-size: ${({ fontSize }) => fontSize ?? '16'}px;
  font-family: ${({ fontFamily }) => fontFamily ?? 'inherit'};
  border: none;
  padding-right: 5px;
  padding-left: 5px;
  text-align: right;
  -moz-appearance: textfield;
  appearance: textfield;

  width: ${({ width = 200 }) => Number(width) - 5}px;
  height: ${({ height }) => height}px;
  margin-top: ${({ marginTop }) => marginTop}px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}px;

  &:disabled {
    cursor: ${({ loading }) => (loading ? 'progress' : 'not-allowed')};
    background-color: ${({ loading }) =>
      loading ? color.BACKGROUND_SECONDARY : color.NEUTRAL_LIGHT};
  }
`;
