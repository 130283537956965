import axios, { AxiosResponse } from 'axios';

import { TagTypeRequestBody } from '../containers/AdminPanelContainers/TagTypes/types/TagType';
import { InsuranceAgentPayload } from '../ducks/adminPanel/actions';
import { createApiInstance } from '../ducks/authentication/sagas';
import { ProjectionDataQuery } from '../ducks/projectionData/actions';
import { TagRequestBody } from '../scenes/common/Tags/types/Tag';
import {
  LrpAnalysisFilterSettings,
  LrpPriceEvolutionAnalysisSettings
} from '../scenes/lrp/types/LrpAnalysis';
import {
  LrpDeleteEndorsement,
  LrpEndorsementSummaryDto,
  LrpUpdateEndorsement
} from '../scenes/lrp/types/LrpEndorsement';
import {
  LrpCalculationParameters,
  LrpCoverageRequestModel,
  LrpCoverageRequestUpdateModel,
  LrpEndorsementDefinition
} from '../scenes/lrp/types/LrpGetFilters';
import PricePreferencePayload from '../scenes/lrp/types/PricePreferencePayload';
import { AccountPreferencesPassword } from '../types/AccountPreferences/AccountPreferencesPassword';
import { AccountPreferencesSendReport } from '../types/AccountPreferences/AccountPreferencesSendReport';
import { AccountPreferencesUpdatePayload } from '../types/AccountPreferences/AccountPreferencesUpdatePayload';
import { Agency } from '../types/Agency';
import { AgentLicense } from '../types/Agent/Agent';
import {
  AlertMeasureType,
  AlertPriceType,
  AlertsPayload,
  RevenueAlertPayload
} from '../types/Alerts';
import { AnalysisProduct } from '../types/AnalysisProduct';
import CreateEducationVideoData from '../types/EducationModule/CreateEducationVideoData';
import EducationVideo from '../types/EducationModule/EducationVideo';
import {
  EndorsementPayload,
  EndorsementPayloadBinding
} from '../types/Endorsement/EndorsementPayload';
import { HistoricalDataEssentials } from '../types/HistoricalDataEssentials';
import { OperationReport } from '../types/ManagementReports/OperationReport';
import Operation from '../types/Operation/Operation';
import { ProducerAddData, ProducerEditData } from '../types/Producer/ProducerModalData';
import { ProducerPreferencesUpdateData } from '../types/Producer/ProducerPreferencesUpdateData';
import { Quarter } from '../types/Quarter';
import { Scenario } from '../types/Scenario';
import { ScenarioAnalysisWhatIfPost } from '../types/ScenarioWhatIf/ScenarioAnalysisWhatIfPayload';
import { ScenarioWhatIfInitialPrices } from '../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { WhatIfBody } from '../types/WhatIfBody';
import { AccountPreferencesDto } from '../types/dto/AccountPreferencesDto';
import AgentDto from '../types/dto/AgentDto';
import { AipDrpEndorsement, AipDtoMatching, AipLrpEndorsement } from '../types/dto/AipOperationDto';
import AlertDto from '../types/dto/AlertDto';
import {
  BindingRequestDto,
  CoverageRequestDto,
  CoverageRequestEndorsementDefinition,
  DrpCoverageRequestUpdateModel
} from '../types/dto/CoverageRequest';
import { GroupStatisticsAllExcel } from '../types/dto/DrpStatisticsDto';
import { DrpEndorsementDto, EndorsementDrp, EndorsementLrp } from '../types/dto/EndorsementDto';
import { EndorsementSummaryDto } from '../types/dto/EndorsementSummaryDto';
import { HistoricalAnalysisActualDto } from '../types/dto/HistoricalAnalysisActualDto';
import { HistoricalAnalysisDto } from '../types/dto/HistoricalAnalysisDto';
import {
  HistoricalAnalysisExpectedDto,
  HistoricalAnalysisPriceFloorDto
} from '../types/dto/HistoricalAnalysisExpectedDto';
import { HistoricalDataDto } from '../types/dto/HistoricalDataDto';
import InsurancePlan from '../types/dto/InsurancePlan';
import { OperationContactDto } from '../types/dto/OperationContactDto';
import { OperationDto } from '../types/dto/OperationDto';
import {
  OperationPreferencesDto,
  OperationPriceReportPayload
} from '../types/dto/OperationPreferencesDto';
import { PracticeDto } from '../types/dto/PracticeDto';
import { ProducerDto } from '../types/dto/ProducerDto';
import { ProjectionAnalysisDataDto } from '../types/dto/ProjectionAnalysisDataDto';
import { ProjectionAnalysisParametersDto } from '../types/dto/ProjectionAnalysisParametersDto';
import { ScenarioDto } from '../types/dto/ScenarioDto';
import { formatDateISO } from '../utils/dateFormatter';

export const api = createApiInstance({
  baseURL: `${import.meta.env.VITE_APP_INSURANCE_URL}`,
  timeout: 120000
});

export const DRPPriceReportAPI = createApiInstance({
  baseURL: `${import.meta.env.VITE_APP_INSURANCE_URL}`,
  timeout: 120000
});

export const addQueryParameter = (
  currentParams: string,
  query: string,
  param: string | number | boolean // | number[] | string[]
): string => {
  const params = currentParams ? `${currentParams}&` : '';
  const queryString = `${params}${query}=${param}`;
  return queryString;
};

export const addQueryParameterArray = (
  currentParams: string,
  query: string,
  params: string[]
): string => {
  if (!params?.length) {
    return currentParams;
  }
  return params.reduce((result, param) => {
    return addQueryParameter(result, query, param);
  }, currentParams);
};

interface InsuranceService {
  operationName: string;
  stateCode: number;
  practiceCode: number;
  salesEffectiveDate: Date;
  butterfatTest: number;
  proteinTest: number;
  classPriceWeightingFactor: number;
  componentPriceWeightingFactor: number;
  coverageLevel: number;
  coveredMilkProduction: number;
  protectionFactor: number;
  share: number;
}

const insuranceAPI = {
  getInsurance: async (
    {
      operationName,
      stateCode,
      practiceCode,
      salesEffectiveDate,
      componentPriceWeightingFactor, // query
      butterfatTest,
      proteinTest,
      classPriceWeightingFactor,
      coverageLevel,
      coveredMilkProduction,
      protectionFactor,
      share
    }: InsuranceService,
    reinsuranceYear: number
  ) => {
    const salesDate = formatDateISO(salesEffectiveDate);
    const state = String(stateCode).padStart(2, '0');

    let query = '';
    query = addQueryParameter(query, 'ButterfatTest', butterfatTest);
    query = addQueryParameter(query, 'ProteinTest', proteinTest);
    query = addQueryParameter(query, 'ClassPriceWeightingFactor', classPriceWeightingFactor);
    if (reinsuranceYear > 2020) {
      query = addQueryParameter(
        query,
        'ComponentPriceWeightingFactor',
        componentPriceWeightingFactor
      );
    }
    query = addQueryParameter(query, 'CoverageLevel', coverageLevel);
    query = addQueryParameter(query, 'CoveredMilkProduction', coveredMilkProduction);
    query = addQueryParameter(query, 'ProtectionFactor', protectionFactor);
    query = addQueryParameter(query, 'Share', share);
    return await api.get(
      `drp/operation/${operationName}/scenario/simulate/${state}/${practiceCode}/${salesDate}${
        query ? '?' + query : ''
      }`
    );
  },

  updateWhatIf: async (
    operationName: string,
    stateCode: string,
    practiceCode: string,
    salesEffectiveDate: string,
    data: WhatIfBody
  ) =>
    await api.post(
      `/drp/operation/${operationName}/scenario/simulate/${stateCode}/${practiceCode}/${salesEffectiveDate}/whatif`,
      data
    ),

  updateIndemnity: async (
    operationName: string,
    stateCode: string,
    practiceCode: string,
    salesEffectiveDate: string,
    data: WhatIfBody
  ) =>
    await api.post(
      `/drp/operation/${operationName}/scenario/simulate/${stateCode}/${practiceCode}/${salesEffectiveDate}/indemnity`,
      data
    ),

  getInsurancePractice: async (
    operationName: string,
    stateCode: string
  ): Promise<AxiosResponse<PracticeDto>> =>
    await api.get(`operation/${operationName}/scenario/practice/${stateCode}`),

  getInsurancePracticeSales: async (
    operationName: string,
    stateCode: string,
    salesDate: Date
  ): Promise<AxiosResponse<PracticeDto>> =>
    await api.get(
      `drp/operation/${operationName}/scenario/practice/${stateCode}/${formatDateISO(salesDate)}`
    ),

  getStates: async () => await api.get(`/udm/states?year=${new Date().getFullYear()}`),

  getCounties: async (stateCode?: string) =>
    await api.get(stateCode ? `/county?stateCode=${stateCode}` : '/county'),

  // Agencies
  getAgencies: async (): Promise<AxiosResponse<Agency[]>> => await api.get('agency'),

  createAgency: async (data: Agency) => await api.post('agency', data),

  editAgency: async (agencyName: string, data: Agency) =>
    await api.put(`agency/${agencyName}`, data),

  deleteAgency: async (agencyName: string) => await api.delete(`agency/${agencyName}`),

  // Operations
  getAgencyOperations: async (
    agencyName: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<OperationDto[]>> =>
    await api.get(
      `agency/${agencyName}/operation${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  getAgentOperations: async (
    agent: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<OperationDto[]>> =>
    await api.get(
      `/agent/${agent}/operation${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  getAgencyOperation: async (
    agencyName: string,
    operationName: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<OperationDto>> =>
    await api.get(
      `/agency/${agencyName}/operation/${operationName}${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  editAgencyOperation: async (agencyName: string, operationName: string, data: Operation) =>
    await api.put(`agency/${agencyName}/operation/${operationName}`, data),

  createAgencyOperation: async (agencyName: string, data: Omit<Operation, 'name'>) =>
    await api.post(`agency/${agencyName}/operation`, data),

  deleteAgencyOperation: async (agencyName: string, operationName: string) =>
    await api.delete(`/agency/${agencyName}/operation/${operationName}`),

  // Agents
  getAgencyAgents: async (
    agencyName: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<AgentDto[]>> =>
    await api.get(
      `agency/${agencyName}/agent${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  getAgencyAgent: async (
    agencyName: string,
    agentName: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<AgentDto>> =>
    await api.get(
      `agency/${agencyName}/agent/${agentName}${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  createAgencyAgent: async (agencyName: string, data: InsuranceAgentPayload) =>
    await api.post(`/agency/${agencyName}/agent`, data),

  deleteAgencyAgent: async (agencyName: string, userName: string) =>
    await api.delete(`/agency/${agencyName}/agent/${userName}`),

  updateAgencyAgent: (
    agencyName: string,
    agentName: string,
    data: {
      firstName: string;
      lastName: string;
      email: string;
    }
  ) => api.put(`/agency/${agencyName}/agent/${agentName}`, data),

  getAgentLicenses: async (
    agencyName: string,
    agentName: string
  ): Promise<AxiosResponse<AgentLicense[]>> =>
    await api.get(`agency/${agencyName}/agent/${agentName}/licence`),

  createAgentLicense: async (agencyName: string, agentName: string, data: AgentLicense) =>
    await api.post(`/agency/${agencyName}/agent/${agentName}/licence`, data),

  deleteAgentLicense: async (agencyName: string, agentName: string, stateCode: string) =>
    await api.delete(`/agency/${agencyName}/agent/${agentName}/licence/${stateCode}`),

  resendAgentInvitation: (agencyName: string, agentName: string) =>
    api.get(`/agency/${agencyName}/agent/${encodeURIComponent(agentName)}/verification/resend`),

  // AIPSync
  getAipStatus: async (agencyName: string) => await api.get(`aip/${agencyName}/status`),

  getAipOperations: async (data: {
    agencyName: string;
    insurancePlan?: string;
    includeProbableMatches?: boolean;
    reinsuranceYear?: number;
  }) => {
    const insurancePlanPath = data.insurancePlan ? `/${data.insurancePlan}` : '';
    const includeProbableMatchesQuery = `includeProbableMatches=${
      data.includeProbableMatches ?? false
    }`;
    const reinsuranceYearQuery = data.reinsuranceYear
      ? `&reinsuranceYear=${data.reinsuranceYear}`
      : '';
    const url = `aip/${data.agencyName}/operation${insurancePlanPath}?${includeProbableMatchesQuery}${reinsuranceYearQuery}`;

    return await api.get(url);
  },

  getAipEndorsements: async (agencyName: string, operationName: string, insurancePlan: string) =>
    await api.get(`aip/${agencyName}/operation/${operationName}/${insurancePlan}`),

  matchAipOperation: async (agencyName: string, operationName: string, data: AipDtoMatching) =>
    await api.put(`aip/${agencyName}/operation/${operationName}`, data),

  unmatchOperation: async (
    operationToUnMatch: AipDtoMatching,
    data: {
      agencyName: string;
      insurancePlan?: string | undefined;
    }
  ) => await api.delete(`aip/${data.agencyName}/operation`, { data: operationToUnMatch }),

  importAipEndorsement: async (
    endorsementToUnmatch: AipDrpEndorsement | AipLrpEndorsement,
    agencyName: string,
    operationName: string,
    id: string,
    insurancePlan: string
  ) =>
    await api.put(
      `aip/${agencyName}/operation/${operationName}/${insurancePlan}/${id}`,
      endorsementToUnmatch
    ),

  ignoreAipDiscrepancy: async (
    operationName: string,
    agencyName: string,
    id: string,
    aipIgnore: boolean,
    insurancePlan: string
  ) =>
    await api.put(
      `aip/${agencyName}/operation/${operationName}/${insurancePlan}/${id}/AipIgnore?aipIgnore=${aipIgnore}`
    ),

  deleteAipEndorsement: async (
    endorsementToUnmatch: AipDrpEndorsement | AipLrpEndorsement,
    agencyName: string,
    operationName: string,
    insurancePlan: string
  ) =>
    await api.delete(`aip/${agencyName}/operation/${operationName}/${insurancePlan}`, {
      data: endorsementToUnmatch
    }),

  // Producers
  getAgencyProducers: async (
    agencyName: string,
    includeBindingRequirements = true
  ): Promise<AxiosResponse<ProducerDto[]>> =>
    await api.get(`/agency/${agencyName}/producer`, {
      params: {
        includeBindingRequirements
      }
    }),

  deleteAgencyProducer: async (agencyName: string, producerName: string) =>
    await api.delete(`/agency/${agencyName}/producer/${producerName}`),

  updateAgencyProducer: async (agencyName: string, producerName: string, data: ProducerEditData) =>
    await api.put(`/agency/${agencyName}/producer/${producerName}`, data),

  resendProducerInvitation: (agencyName: string, producerName: string) =>
    api.get(
      `/agency/${agencyName}/producer/${encodeURIComponent(producerName)}/verification/resend`
    ),

  // Operation Producers
  getAgencyOperationProducers: async (
    agencyName: string,
    operationName: string
  ): Promise<AxiosResponse<ProducerDto[]>> =>
    await api.get(`agency/${agencyName}/operation/${operationName}/producer`),

  createAgencyOperationProducer: async (
    agencyName: string,
    operationName: string,
    data: ProducerAddData
  ) => await api.post(`agency/${agencyName}/operation/${operationName}/producer`, data),

  removeAgencyOperationProducer: async (
    agencyName: string,
    operationName: string,
    username: string
  ) => await api.delete(`agency/${agencyName}/operation/${operationName}/producer/${username}`),

  getProducerOperations: async (
    producer: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<OperationDto[]>> =>
    await api.get(
      `/producer/${producer}/operation${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  getProducerOperation: async (
    producer: string,
    operationName: string,
    includeBindingRequirements?: boolean
  ): Promise<AxiosResponse<OperationDto>> =>
    await api.get(
      `/producer/${producer}/operation/${operationName}${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }`
    ),

  setProducerFavoriteOperation: async (producer: string, operationName: string, stared: boolean) =>
    await api.put(`/producer/${producer}/producer/${operationName}?starred=${stared}`),

  // Account Preference
  getAccountPreferences: async (): Promise<AxiosResponse<AccountPreferencesDto>> =>
    await api.get('/account/preferences'),

  updateAccountPreferences: async (account: AccountPreferencesUpdatePayload) =>
    await api.put('/account/preferences', account),

  updateAccountStarredPreferences: async (showStarredOperationsOnly: boolean) =>
    await api.put(
      `/account/preferences/star?showStarredOperationsOnly=${showStarredOperationsOnly}`
    ),

  updateAccountPreferencesAndPassword: async (account: AccountPreferencesPassword) =>
    await api.put('/account/preferences', account),

  getSpecifiedAccountPreferences: async (
    userName: string
  ): Promise<AxiosResponse<AccountPreferencesDto>> =>
    await api.get(`/account/${userName}/preferences`),

  updateSpecifiedAccountPreferences: async (
    userName: string,
    preferences: ProducerPreferencesUpdateData
  ): Promise<AxiosResponse<AccountPreferencesDto>> =>
    await api.put(`/account/${userName}/preferences`, preferences),

  // Scenario
  getScenario: async (operationName: string): Promise<AxiosResponse<string[]>> =>
    await api.get(`/drp/operation/${operationName}/scenario`),

  getScenarioByName: async (
    name: string,
    operationName: string
  ): Promise<AxiosResponse<ScenarioDto[]>> =>
    await api.get(`/drp/operation/${operationName}/scenario/name?name=${encodeURIComponent(name)}`),

  // TODO: change Scenario to ScenarioDto
  putScenario: async (operationName: string, name: string, data: Scenario) =>
    await api.put(
      `/drp/operation/${operationName}/scenario?name=${encodeURIComponent(name)}`,
      data
    ),

  deleteScenario: async (name: string, operationName: string) =>
    await api.delete(`/drp/operation/${operationName}/scenario?name=${encodeURIComponent(name)}`),

  // Endorsements
  getEndorsements: async (operationName: string): Promise<AxiosResponse<DrpEndorsementDto[]>> =>
    await api.get(`drp/${operationName}/endorsement`),

  getCoverageRequests: async (
    operationName?: string,
    insurancePlan?: InsurancePlan
  ): Promise<AxiosResponse<CoverageRequestDto[]>> => {
    if (!operationName) {
      return await api.get('/coverage/request');
    }

    const insurancePlanParameter = insurancePlan
      ? '?insurancePlan=' + InsurancePlan[insurancePlan]
      : '';
    return await api.get(`operation/${operationName}/coverage/request${insurancePlanParameter}`);
  },

  getCoverageRequest: async (
    operationName: string,
    referenceIdentifier: string
  ): Promise<AxiosResponse<CoverageRequestDto>> => {
    return await api.get(`/operation/${operationName}/coverage/request/${referenceIdentifier}`);
  },

  updateCoverageRequest: async (
    operationName: string,
    referenceIdentifier: string,
    data: { status: string } | LrpCoverageRequestUpdateModel | DrpCoverageRequestUpdateModel,
    insurancePlan: InsurancePlan
  ) => {
    if ('definitions' in data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- refactor later
      const endorsementDefinitions: any = [];
      data.definitions.forEach(
        (
          endorsementDefinition:
            | CoverageRequestEndorsementDefinition<LrpEndorsementDefinition>
            | CoverageRequestEndorsementDefinition<EndorsementPayload>
        ) => {
          if ('calculationParameters' in endorsementDefinition.definition) {
            endorsementDefinitions.push({
              id: endorsementDefinition.id,
              definition: {
                ...endorsementDefinition.definition,
                stateCode: endorsementDefinition.definition.stateCode.padStart(2, '0'),
                calculationParameters: {
                  ...endorsementDefinition.definition.calculationParameters,
                  salesEffectiveDate: formatDateISO(
                    endorsementDefinition.definition.calculationParameters.salesEffectiveDate
                  )
                },
                tags: endorsementDefinition.definition.tags
              }
            });
          } else if ('parameters' in endorsementDefinition.definition) {
            endorsementDefinitions.push({
              id: endorsementDefinition.id,
              definition: {
                ...endorsementDefinition.definition,
                stateCode: endorsementDefinition.definition.stateCode.padStart(2, '0'),
                calculationParameters: {
                  ...endorsementDefinition.definition.parameters
                },
                salesEffectiveDate: formatDateISO(
                  endorsementDefinition.definition.salesEffectiveDate
                ),
                tags: endorsementDefinition.definition.tags
              }
            });
          }
        }
      );
      data = {
        ...data,
        definitions: endorsementDefinitions
      };
    }

    await api.put(
      `${InsurancePlan[insurancePlan]}/${operationName}/endorsement/request/${referenceIdentifier}`,
      data
    );
  },

  getCoverageRequestHistory: async (
    operationName: string,
    referenceIdentifier: string
  ): Promise<AxiosResponse<BindingRequestDto[]>> =>
    await api.get(`operation/${operationName}/coverage/request/${referenceIdentifier}/history`),

  getEndorsementsByCoverageRequest: async (
    operationName: string,
    referenceIdentifier: string,
    insurancePlan: string
  ): Promise<AxiosResponse<EndorsementDrp[] | EndorsementLrp[]>> =>
    await api.get(`${insurancePlan}/${operationName}/endorsement/request/${referenceIdentifier}`),

  getEndorsementsWithFilter: async (
    operationName: string,
    reinsuranceYear: number,
    quarter: number,
    tagNames: string[]
  ): Promise<AxiosResponse<DrpEndorsementDto[]>> => {
    const query = addQueryParameterArray('', 'TagNames', tagNames);
    return await api.get(
      `drp/${operationName}/endorsement/calendar/${reinsuranceYear}/${quarter}${
        query ? '?' + query : ''
      }`
    );
  },

  createDrpEndorsement: async (operationName: string, data: EndorsementPayload) =>
    await api.post(`drp/${operationName}/endorsement`, data),

  createEndorsementBinding: async (operationName: string, data: EndorsementPayloadBinding) =>
    await api.post(`drp/${operationName}/endorsement/request`, data),

  updateOperationBindingRequestStatus: async (
    operationName: string,
    referenceIdentifier: string,
    status: string,
    insurancePlan: InsurancePlan
  ) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    await api.put(
      `${InsurancePlan[insurancePlan]}/${operationName}/coverage/request/${referenceIdentifier}/status`,
      status,
      config
    );
  },

  downloadForm: async (
    operationName: string,
    referenceIdentifier: string,
    insurancePlan: InsurancePlan
  ) => {
    return await api.get(
      `${InsurancePlan[insurancePlan]}/${operationName}/coverage/request/${referenceIdentifier}/form`,
      {
        responseType: 'blob'
      }
    );
  },

  updateEndorsement: async (operationName: string, id: number, data: EndorsementPayload) =>
    await api.put(`drp/${operationName}/endorsement/${id}`, data),

  deleteEndorsement: async (operationName: string, id: number) =>
    await api.delete(`drp/${operationName}/endorsement/${id}`),

  exportEndorsementIndemnityToExcel: async (operationName: string, id: number) =>
    await api.get(`/drp/${operationName}/endorsement/${id}/export`, { responseType: 'blob' }),

  getEndorsementSummary: async (
    operationName: string,
    tagNames: string[]
  ): Promise<AxiosResponse<EndorsementSummaryDto[]>> => {
    const query = addQueryParameterArray('', 'TagNames', tagNames);
    return await api.get(
      `drp/operation/${operationName}/endorsement/summary${query ? '?' + query : ''}`
    );
  },

  recalculateEndorsements: async (operationName: string) =>
    await api.get(`/drp/${operationName}/endorsement/update`),

  getEndorsementBasisCalculation: async (operationName: string, id: number) =>
    await api.get(`drp/${operationName}/endorsement/${id}/basis`),

  // historicalData aka OperationProduction
  getHistoricalData: async (operationName: string): Promise<AxiosResponse<HistoricalDataDto[]>> =>
    await api.get(`/operation/${operationName}/production`),

  downloadHistoricalData: async (operationName: string) =>
    await api.get(`/operation/${operationName}/production/download`),

  downloadProjectionTable: async (data: ProjectionAnalysisDataDto, projectionModel: number) =>
    await api.post(
      '/drp/analysis/projected/revenue/export/excel',
      { ...data, projectionModel },
      { responseType: 'blob' }
    ),

  editHistoricalRecord: async (operationName: string, data: HistoricalDataEssentials) =>
    await api.put(`/operation/${operationName}/production`, [data]),

  deleteHistoricalRecord: async (operationName: string, year: number, month: number) =>
    await api.delete(`/operation/${operationName}/production/${year}/${month}`),

  saveAllHistoricalRecords: async (operationName: string, data: HistoricalDataEssentials[]) =>
    await api.put(`/operation/${operationName}/production`, data),

  // operationContact
  getOperationAgentContacts: async (
    operationName: string
  ): Promise<AxiosResponse<OperationContactDto[]>> =>
    await api.get(`/operation/${operationName}/agent`),

  getAgentOperationContacts: async (
    agent: string,
    agencyName: string
  ): Promise<AxiosResponse<OperationContactDto[]>> =>
    await api.get(`/agency/${agencyName}/agent/${encodeURI(agent)}/operation/contact`),

  newAgentOperationContact: async (
    operationName: string,
    data: { agentName: string; notification: boolean; primaryContact: boolean }
  ) => await api.post(`/operation/${operationName}/agent`, data),

  updateAgentOperationContact: async (
    operationName: string,
    data: { agentName: string; notification: boolean; primaryContact: boolean }
  ) => await api.put(`/operation/${operationName}/agent`, data),

  deleteOperationAgentContact: async (agent: string, operationName: string) =>
    await api.delete(`/operation/${operationName}/agent/${agent}`),

  operationProductionUpload: async (operationName: string) =>
    await api.get(`/operation/${operationName}/production/upload/${Date.now()}/uri`),

  operationProductionUploadStatus: async (operationName: string, guid: string) =>
    await api.get(`operation/${operationName}/production/upload/${guid}`),

  getAgencyAgentOperationsContacts: async (
    agencyName: string
  ): Promise<AxiosResponse<OperationContactDto[]>> =>
    await api.get(`/agency/${agencyName}/operation/agent`),

  getAgencyProducerOperationsContacts: async (
    producerMail: string
  ): Promise<AxiosResponse<OperationContactDto[]>> =>
    await api.get(`/producer/${producerMail}/operation/agent`),

  setAgentFavoriteOperation: async (producer: string, operationName: string, stared: boolean) =>
    await api.put(`/agent/${producer}/${operationName}?starred=${stared}`),

  getAgencyLogo: async (): Promise<AxiosResponse<string>> => await api.get('/agency/logo'),

  getAdminAgencyLogo: async (agency: string): Promise<AxiosResponse<string>> =>
    await api.get(`/agency/${agency}/logo`),

  // Historical Analysis/Price Trends
  getHistoricalAnalysis: async (
    salesEffectiveDate: Date,
    yearFrom: number,
    yearTo: number,
    practiceCode: number,
    data: {
      calculationParameters: {
        butterfatTest: number;
        proteinTest: number;
        classPriceWeightingFactor: number;
        componentPriceWeightingFactor: number;
        coverageLevel: number;
        coveredMilkProduction: number;
        protectionFactor: number;
        share: number;
      };
      producerPremiumCwt: { class: 0; component: 0 };
    }
  ): Promise<AxiosResponse<HistoricalAnalysisDto[]>> => {
    const salesDate = formatDateISO(salesEffectiveDate);
    return await api.post(
      `/drp/analysis/historical?salesEffectiveDate=${salesDate}&yearFrom=${yearFrom}&yearTo=${yearTo}&practiceCode=${practiceCode}`,
      data
    );
  },

  getHistoricalAnalysisPriceFutures: async (
    year: number,
    quarter: number,
    product: AnalysisProduct,
    month: number
  ) =>
    await api.get<HistoricalAnalysisExpectedDto>(
      `/drp/analysis/price/futures?year=${year}&quarter=${quarter}&product=${product}&month=${month}`
    ),

  getHistoricalAnalysisExpectedFutures: async (
    year: number,
    quarter: number,
    product: AnalysisProduct
  ) =>
    await api.get<HistoricalAnalysisExpectedDto>(
      `/drp/analysis/historical/price/expected/futures?year=${year}&quarter=${quarter}&product=${product}`
    ),

  getHistoricalAnalysisActual: async (yearFrom: number, yearTo: number, product: AnalysisProduct) =>
    await api.get<HistoricalAnalysisActualDto>(
      `/drp/analysis/historical/price/actual?yearFrom=${yearFrom}&yearTo=${yearTo}&product=${product}`
    ),

  getHistoricalAnalysisExpectedRMA: async (
    year: number,
    quarter: number,
    product: AnalysisProduct
  ) =>
    await api.get<HistoricalAnalysisExpectedDto>(
      `/drp/analysis/historical/price/expected/rma?year=${year}&quarter=${quarter}&product=${product}`
    ),

  getScenarioAlertExpectedPrices: async (
    operationName: string,
    year: number,
    quarter: Quarter,
    priceType: AlertPriceType,
    measureType: AlertMeasureType,
    classPriceWeightingFactor: number,
    componentPriceWeightingFactor: number | null,
    coverageLevel: number,
    proteinTest: number,
    butterfatTest: number
  ) => {
    let query = '';
    query = addQueryParameter(query, 'year', year);
    query = addQueryParameter(query, 'quarter', quarter);
    query = addQueryParameter(query, 'priceType', priceType);
    query = addQueryParameter(query, 'measureType', measureType);
    query = addQueryParameter(query, 'classPriceWeightingFactor', classPriceWeightingFactor);
    if (componentPriceWeightingFactor !== null) {
      query = addQueryParameter(
        query,
        'componentPriceWeightingFactor',
        componentPriceWeightingFactor
      );
    }
    query = addQueryParameter(query, 'coverageLevel', coverageLevel);
    query = addQueryParameter(query, 'butterfatTest', butterfatTest);
    query = addQueryParameter(query, 'proteinTest', proteinTest);

    return await api.get<HistoricalAnalysisExpectedDto>(
      `/drp/analysis/scenario/${operationName}${query ? '?' + query : ''}`
    );
  },

  getScenarioAlertPriceFloor: async (
    operationName: string,
    year: number,
    quarter: Quarter,
    salesEffectiveDate: Date,
    priceType: AlertPriceType,
    measureType: AlertMeasureType,
    classPriceWeightingFactor: number,
    componentPriceWeightingFactor: number | null,
    coverageLevel: number,
    proteinTest: number,
    butterfatTest: number,
    protectionFactor?: number,
    basis?: number
  ) => {
    const salesDate = formatDateISO(salesEffectiveDate);
    let query = '';
    query = addQueryParameter(query, 'year', year);
    query = addQueryParameter(query, 'quarter', quarter);
    query = addQueryParameter(query, 'salesEffectiveDate', salesDate);
    query = addQueryParameter(query, 'priceType', priceType);
    query = addQueryParameter(query, 'measureType', measureType);
    query = addQueryParameter(query, 'classPriceWeightingFactor', classPriceWeightingFactor);
    if (componentPriceWeightingFactor !== null) {
      query = addQueryParameter(
        query,
        'componentPriceWeightingFactor',
        componentPriceWeightingFactor
      );
    }
    query = addQueryParameter(query, 'coverageLevel', coverageLevel);
    query = addQueryParameter(query, 'butterfatTest', butterfatTest);
    query = addQueryParameter(query, 'proteinTest', proteinTest);
    query = protectionFactor
      ? addQueryParameter(query, 'protectionFactor', protectionFactor)
      : query;
    query = basis ? addQueryParameter(query, 'basis', basis) : query;

    return await api.get<HistoricalAnalysisPriceFloorDto>(
      `/drp/analysis/scenario/price/floor/${operationName}${query ? '?' + query : ''}`
    );
  },

  // operation preferences
  getOperationPreferences: async (
    operationName: string
  ): Promise<AxiosResponse<OperationPreferencesDto>> =>
    await api.get(`/operation/${operationName}/preferences`),

  operationPreferencesAverages: async (operationName: string) =>
    await api.get(
      `/operation/${operationName}/production/averages?yearFrom=${
        new Date().getFullYear() - 5
      }&yearTo=${new Date().getFullYear()}`
    ),

  postOperationPreferences: async (operationName: string, data: OperationPreferencesDto) =>
    await api.post(`/operation/${operationName}/preferences`, data),

  getOperationPriceReport: async (operationName: string) =>
    await api.get(`/operation/${operationName}/preferences/reports`),

  putOperationPriceReport: async (operationName: string, data: OperationPriceReportPayload) =>
    await api.put(`/operation/${operationName}/preferences/reports`, data),

  deleteOperationPriceReport: async (operationName: string, id: number) =>
    await api.delete(`/operation/${operationName}/preferences/reports?id=${id}`),

  // Education
  getEducationVideos: async (agencyName: string): Promise<AxiosResponse<EducationVideo[]>> =>
    await api.get(`/education/video/agency/${agencyName}`),

  getEducationVideoUrl: async (guid: string): Promise<AxiosResponse<EducationVideo>> =>
    await api.get(`/education/video/${guid}`),

  getRecommendedEducationVideos: async (guid: string): Promise<AxiosResponse<EducationVideo[]>> =>
    await api.get(`/education/video/recommended?guid=${guid}`),

  uploadEducationVideo: async (
    fileName: string,
    data: CreateEducationVideoData
  ): Promise<AxiosResponse<{ guid: string; uploadUri: string }>> =>
    await api.post(`/education/video/upload/${fileName}/uri`, data),

  completeUploadEducationVideo: async (guid: string) =>
    await api.get(`/education/video/${guid}/upload/complete`),

  uploadEducationVideoAttachment: async (
    videoGuid: string,
    fileName: string
  ): Promise<AxiosResponse<{ guid: string; uploadUri: string }>> =>
    await api.post(`/education/video/${videoGuid}/attachment/upload/${fileName}/uri`),

  completeUploadEducationVideoAttachment: async (guid: string) =>
    await api.get(`/education/video/attachment/${guid}/upload/complete`),

  uploadEducationVideoThumbnail: async (
    videoGuid: string,
    fileName: string
  ): Promise<AxiosResponse<{ guid: string; uploadUri: string }>> =>
    await api.post(`/education/video/${videoGuid}/thumbnail/upload/${fileName}/uri`),

  completeUploadEducationVideoThumbnail: async (guid: string) =>
    await api.get(`/education/video/thumbnail/${guid}/upload/complete`),

  editEducationVideo: async (data: EducationVideo) => await api.put('/education/video', data),

  editEducationVideos: async (data: EducationVideo[]) =>
    await api.put('/education/video/list', data),

  deleteEducationVideo: async (guid: string) => await api.delete(`/education/video/${guid}`),

  deleteEducationVideoAttachment: async (guid: string) =>
    await api.delete(`/education/video/attachment/${guid}`),

  deleteEducationVideoThumbnail: async (guid: string) =>
    await api.delete(`/education/video/thumbnail/${guid}`),

  getEducationVideoAttachments: async (guid: string) =>
    await api.get(`/education/video/${guid}/attachments`),

  getEducationVideoThumbnail: async (guid: string) =>
    await api.get(`education/video/${guid}/thumbnail`),

  getEducationVideoAttachmentData: async (url: string) =>
    await axios.get(url, { responseType: 'blob' }),

  sendNewsletterToUser: async (data: AccountPreferencesSendReport) =>
    await DRPPriceReportAPI.post(
      `newsletter/price/${formatDateISO(new Date())}/notify/${data.userEmail}`,
      {
        type: data.type ? data.type : 'DrpPriceReport'
      }
    ),

  // operationProducer
  getOperationProducer: async (operationName: string, includeBindingRequirements = false) =>
    await api.get(
      `/operation/${operationName}/producer${
        includeBindingRequirements ? '?includeBindingRequirements=true' : ''
      }
    `
    ),

  // Alerts
  getAlerts: async (operationName: string, expired: boolean): Promise<AxiosResponse<AlertDto[]>> =>
    await api.get(`alerts/${operationName}/alert?expired=${expired}`),

  putAlert: async (operationName: string, data: AlertsPayload | RevenueAlertPayload) =>
    await api.put(`alerts?operationName=${operationName}`, data),

  editAlert: async (operationName: string, data: AlertDto) =>
    await api.put(`alerts?operationName=${operationName}`, data),

  deleteAlert: async (id: number) => await api.delete(`alerts/${id}`),

  getProjectedAnalysis: async (operationName: string, year: number, data: ProjectionDataQuery) => {
    let query = '';
    type QueryType =
      | 'projectionModel'
      | 'q1Basis'
      | 'q2Basis'
      | 'q3Basis'
      | 'q4Basis'
      | 'q1AdjustedBasisCwt'
      | 'q2AdjustedBasisCwt'
      | 'q3AdjustedBasisCwt'
      | 'q4AdjustedBasisCwt';
    (
      [
        'projectionModel',
        'q1Basis',
        'q2Basis',
        'q3Basis',
        'q4Basis',
        'q1AdjustedBasisCwt',
        'q2AdjustedBasisCwt',
        'q3AdjustedBasisCwt',
        'q4AdjustedBasisCwt'
      ] as QueryType[]
    ).forEach(q => {
      const value = data[q];
      if (value !== undefined && value !== null) query = addQueryParameter(query, q, value);
    });
    return await api.get<ProjectionAnalysisDataDto>(
      `/drp/analysis/projected/revenue/${operationName}/${year}${query ? '?' + query : ''}`
    );
  },

  getProjectedAnalysisSettings: async (operationName: string, year: number) =>
    await api.get(`/drp/analysis/projected/revenue/${operationName}/${year}/parameters`),

  updateProjectedAnalysisSettings: async (
    operationName: string,
    data: ProjectionAnalysisParametersDto
  ) =>
    await api.put(`/drp/analysis/projected/revenue/${operationName}/${data.year}/parameters`, data),

  getPriceReports: async (calendarDate: Date, operationName: string) =>
    await api.get(`/drp/analysis/historical/price/${formatDateISO(calendarDate)}/${operationName}`),

  getCMEPriceReports: async (calendarDate: Date) =>
    await api.get(`/drp/analysis/historical/price/cme/${formatDateISO(calendarDate)}`),

  // Scenario What If Endorsements Endpoints

  getScenarioEndorsementSummary: async (operationName: string, scenarioName: string) =>
    await api.get(`/drp/operation/${operationName}/endorsement/summary/whatif/${scenarioName}`),

  getScenarioEndorsementsByQuarter: async (
    operationName: string,
    scenarioName: string,
    year: number,
    quarter: number
  ) =>
    await api.get(
      `/drp/${operationName}/endorsement/whatif/${scenarioName}/calendar/${year}/${quarter}`
    ),

  getScenarioEndorsement: async (operationName: string, scenarioName: string, id: number) =>
    await api.get(`drp/${operationName}/endorsement/whatif/${scenarioName}/${id}`),

  createScenarioEndorsement: async (
    operationName: string,
    scenarioName: string,
    data: EndorsementPayload
  ) => await api.post(`drp/operation/${operationName}/endorsement/whatif/${scenarioName}`, data),

  updateScenarioEndorsement: async (
    operationName: string,
    scenarioName: string,
    id: number,
    data: EndorsementPayload
  ) => await api.put(`/drp/${operationName}/endorsement/whatif/${scenarioName}/${id}`, data),

  deleteScenarioEndorsement: async (operationName: string, scenarioName: string, id: number) =>
    await api.delete(`/drp/${operationName}/endorsement/whatif/${scenarioName}/${id}`),

  // Scenario What If Scenario Endpoints

  getWhatIfScenarios: async (operationName: string) =>
    await api.get<string[]>(`/drp/operation/${operationName}/whatif`),

  getWhatIfScenario: async (operationName: string, scenarioName: string) =>
    await api.get(`/drp/operation/${operationName}/whatif/name?name=${scenarioName}`),

  putWhatIfScenario: async (
    operationName: string,
    scenarioName: string,
    data: ScenarioWhatIfInitialPrices
  ) => await api.put(`/drp/operation/${operationName}/whatif?name=${scenarioName}`, data),

  deleteWhatIfScenario: async (operationName: string, scenarioName: string) =>
    await api.delete(`/drp/operation/${operationName}/whatif?name=${scenarioName}`),

  recalculateScenarioEndorsements: async (operationName: string, scenarioName: string) =>
    await api.get(`/drp/${operationName}/endorsement/whatif/${scenarioName}/update`),

  exportScenarioEndorsementIndemnityToExcel: async (
    operationName: string,
    scenarioName: string,
    id: number
  ) =>
    await api.get(`/drp/${operationName}/endorsement/whatif/${scenarioName}/${id}/export`, {
      responseType: 'blob'
    }),

  // LRP

  getQuoterPreferences: async (operationName: string) =>
    await api.get(`/lrp/${operationName}/preferences`),

  getPricePreferences: async (operationName: string) =>
    await api.get(`/lrp/${operationName}/preferences/reports`),

  updateQuoterPreference: async (
    operationName: string,
    commodity: string,
    coveragePercentageRank: number,
    commodityType: string,
    numberOfHead: number,
    targetWeight: number,
    insuredSharedPercent: number,
    conservationCompliance: number,
    isBeginner: boolean,
    monthSelection: string,
    basisSelection: string,
    strikeSelection: string,
    showAllEndorsementLengths: boolean,
    showCMEPutComparison: boolean,
    showSubsidyOverview: boolean
  ) => {
    let query = '';
    query = commodity ? addQueryParameter(query, 'Commodity', commodity) : query;
    query = coveragePercentageRank
      ? addQueryParameter(query, 'CoveragePercentageRank', coveragePercentageRank)
      : query;
    query = commodityType ? addQueryParameter(query, 'CommodityType', commodityType) : query;
    query = numberOfHead ? addQueryParameter(query, 'NumberOfHead', numberOfHead) : query;
    query = targetWeight ? addQueryParameter(query, 'TargetWeight', targetWeight) : query;
    query = insuredSharedPercent
      ? addQueryParameter(query, 'InsuredSharedPercent', insuredSharedPercent)
      : query;
    query =
      conservationCompliance !== undefined
        ? addQueryParameter(query, 'ConservationCompliance', conservationCompliance)
        : query;
    query = isBeginner
      ? addQueryParameter(query, 'IsBeginner', 'true')
      : addQueryParameter(query, 'IsBeginner', 'false');
    query = monthSelection ? addQueryParameter(query, 'MonthSelection', monthSelection) : query;
    query = basisSelection ? addQueryParameter(query, 'BasisSelection', basisSelection) : query;
    query = strikeSelection ? addQueryParameter(query, 'StrikeSelection', strikeSelection) : query;
    query = showAllEndorsementLengths
      ? addQueryParameter(query, 'ShowAllEndorsementLengths', 'true')
      : addQueryParameter(query, 'ShowAllEndorsementLengths', 'false');
    query = showCMEPutComparison
      ? addQueryParameter(query, 'ShowCMEPutComparison', 'true')
      : addQueryParameter(query, 'ShowCMEPutComparison', 'false');
    query = showSubsidyOverview
      ? addQueryParameter(query, 'ShowSubsidyOverview', 'true')
      : addQueryParameter(query, 'ShowSubsidyOverview', 'false');

    return await api.post(`/lrp/${operationName}/preferences${query ? '?' + query : ''}`);
  },

  createPricePreference: async (operationName: string, data: PricePreferencePayload) =>
    await api.post(`/lrp/${operationName}/preferences/reports`, data),

  deletePricePreference: async (operationName: string, id: number) =>
    await api.delete(`/lrp/${operationName}/preferences/reports/${id}`),

  getFilter: async (salesEffectiveDate?: Date) => {
    const salesDate = formatDateISO(salesEffectiveDate);

    let query = '';
    query = salesEffectiveDate ? addQueryParameter(query, 'salesEffectiveDate', salesDate) : query;

    return await api.get(`/lrp/filters${query ? '?' + query : ''}`);
  },

  requestCoverage: async (operationName: string, data: LrpCoverageRequestModel) => {
    const salesDate = formatDateISO(data.salesEffectiveDate);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- refactor later
    const newDefinitions: any = [];
    data.definitions.forEach(element => {
      const newCalculationParameters = {
        ...element.calculationParameters,
        salesEffectiveDate: salesDate
      };
      newDefinitions.push({
        ...element,
        calculationParameters: newCalculationParameters
      });
    });
    const newData = {
      producerUserName: data.producerUserName,
      agentUserName: data.agentUserName,
      signatureText: data.signatureText,
      salesEffectiveDate: salesDate,
      definitions: newDefinitions,
      ignoreDuplicateEndorsements: data.ignoreDuplicateEndorsements,
      ignoreDuplicateRequests: data.ignoreDuplicateRequests
    };

    await api.post(`/lrp/${operationName}/endorsement/request`, newData);
  },

  lrpCoverageQuery: (params: LrpCalculationParameters): string => {
    const salesDate = formatDateISO(params.salesEffectiveDate);

    let query = '';
    query = params.commodity ? addQueryParameter(query, 'Commodity', params.commodity) : query;
    query = params.coveragePercentageRank
      ? addQueryParameter(query, 'CoveragePercentageRank', params.coveragePercentageRank)
      : query;
    query = params.typeCode ? addQueryParameter(query, 'TypeCode', params.typeCode) : query;
    query = params.salesEffectiveDate
      ? addQueryParameter(query, 'SalesEffectiveDate', salesDate)
      : query;
    query = params.endorsementLength
      ? addQueryParameter(query, 'EndorsementLength', params.endorsementLength)
      : query;
    query = params.numberOfHead
      ? addQueryParameter(query, 'NumberOfHead', params.numberOfHead)
      : query;
    query = params.targetWeight
      ? addQueryParameter(query, 'TargetWeight', params.targetWeight)
      : query;
    query = params.insuredSharePercent
      ? addQueryParameter(query, 'InsuredSharePercent', params.insuredSharePercent)
      : query;
    query =
      params.conservationCompliance !== undefined
        ? addQueryParameter(query, 'ConservationCompliance', params.conservationCompliance)
        : query;
    query = params.isBeginner
      ? addQueryParameter(query, 'IsBeginner', 'true')
      : addQueryParameter(query, 'IsBeginner', 'false');
    query = params.putMonthSelection
      ? addQueryParameter(query, 'PutMonthSelection', params.putMonthSelection)
      : query;
    query = params.putBasisSelection
      ? addQueryParameter(query, 'PutBasisSelection', params.putBasisSelection)
      : query;
    query = params.putStrikeSelection
      ? addQueryParameter(query, 'PutStrikeSelection', params.putStrikeSelection)
      : query;

    return query;
  },

  getCoverageTable: async (params: LrpCalculationParameters) => {
    const query = insuranceAPI.lrpCoverageQuery(params);
    return await api.get(`/lrp/coverage${query ? '?' + query : ''}`);
  },

  getCoverageTableAllEndorsementLengths: async (params: LrpCalculationParameters) => {
    const query = insuranceAPI.lrpCoverageQuery(params);
    return await api.get(`/lrp/coverage/period${query ? '?' + query : ''}`);
  },

  getCoverageTableHistorical: async (params: LrpCalculationParameters) => {
    const query = insuranceAPI.lrpCoverageQuery(params);
    return await api.get(`/lrp/coverage/historic${query ? '?' + query : ''}`);
  },

  // Sales Statistics
  getDrpSalesStatistics: async (
    calendarYearFrom?: number,
    calendarYearTo?: number,
    quarterFrom?: number,
    quarterTo?: number,
    reinsuranceYearFrom?: number,
    reinsuranceYearTo?: number,
    salesEffectiveDateFrom?: Date,
    salesEffectiveDateTo?: Date,
    stateCode?: string,
    countyCodes?: string[]
  ) => {
    let query = '';
    query = calendarYearFrom
      ? addQueryParameter(query, 'calendarYearFrom', calendarYearFrom)
      : query;
    query = calendarYearTo ? addQueryParameter(query, 'calendarYearTo', calendarYearTo) : query;
    query = quarterFrom ? addQueryParameter(query, 'quarterFrom', quarterFrom) : query;
    query = quarterTo ? addQueryParameter(query, 'quarterTo', quarterTo) : query;
    query = reinsuranceYearFrom
      ? addQueryParameter(query, 'reinsuranceYearFrom', reinsuranceYearFrom)
      : query;
    query = reinsuranceYearTo
      ? addQueryParameter(query, 'reinsuranceYearTo', reinsuranceYearTo)
      : query;
    query = salesEffectiveDateFrom
      ? addQueryParameter(query, 'salesEffectiveDateFrom', formatDateISO(salesEffectiveDateFrom))
      : query;
    query = salesEffectiveDateTo
      ? addQueryParameter(query, 'salesEffectiveDateTo', formatDateISO(salesEffectiveDateTo))
      : query;
    query = stateCode ? addQueryParameter(query, 'stateCode', stateCode) : query;
    const countyCodesString = countyCodes?.map(e => 'CountyCodes=' + e);

    return await api.get(
      `/statistics${query ? '?' + query : ''}${
        countyCodesString ? '&' + countyCodesString.join('&') : ''
      }`
    );
  },

  getDrpSalesStatisticsBar: async (
    calendarYearFrom?: number,
    calendarYearTo?: number,
    quarterFrom?: number,
    quarterTo?: number,
    reinsuranceYearFrom?: number,
    reinsuranceYearTo?: number,
    salesEffectiveDateFrom?: Date,
    salesEffectiveDateTo?: Date,
    stateCode?: string,
    countyCodes?: string[]
  ) => {
    let query = '';
    query = calendarYearFrom
      ? addQueryParameter(query, 'calendarYearFrom', calendarYearFrom)
      : query;
    query = calendarYearTo ? addQueryParameter(query, 'calendarYearTo', calendarYearTo) : query;
    query = quarterFrom ? addQueryParameter(query, 'quarterFrom', quarterFrom) : query;
    query = quarterTo ? addQueryParameter(query, 'quarterTo', quarterTo) : query;
    query = reinsuranceYearFrom
      ? addQueryParameter(query, 'reinsuranceYearFrom', reinsuranceYearFrom)
      : query;
    query = reinsuranceYearTo
      ? addQueryParameter(query, 'reinsuranceYearTo', reinsuranceYearTo)
      : query;
    query = salesEffectiveDateFrom
      ? addQueryParameter(query, 'salesEffectiveDateFrom', formatDateISO(salesEffectiveDateFrom))
      : query;
    query = salesEffectiveDateTo
      ? addQueryParameter(query, 'salesEffectiveDateTo', formatDateISO(salesEffectiveDateTo))
      : query;
    query = stateCode ? addQueryParameter(query, 'stateCode', stateCode) : query;
    const countyCodesString = countyCodes?.map(e => 'CountyCodes=' + e);

    return await api.get(
      `/statistics/bar${query ? '?' + query : ''}${
        countyCodesString ? '&' + countyCodesString.join('&') : ''
      }`
    );
  },

  getDrpSalesStatisticsHedgeRatio: async (
    operationName: string,
    year?: number,
    quarter?: number,
    stateCodes?: string[]
  ) => {
    let query = '';
    query = year ? addQueryParameter(query, 'year', year) : query;
    query = quarter ? addQueryParameter(query, 'quarter', quarter) : query;
    const stateCodesString = stateCodes?.map(e => 'stateCodes=' + e);

    return await api.get(
      `/statistics/hedge/${operationName}${query ? '?' + query : ''}${
        stateCodesString ? '&' + stateCodesString.join('&') : ''
      }`
    );
  },

  getDrpSalesStatisticsGroup: async (
    groupOptions?: string[],
    calendarYearFrom?: number,
    calendarYearTo?: number,
    quarterFrom?: number,
    quarterTo?: number,
    reinsuranceYearFrom?: number,
    reinsuranceYearTo?: number,
    salesEffectiveDateFrom?: Date,
    salesEffectiveDateTo?: Date,
    stateCode?: string,
    countyCodes?: string[]
  ) => {
    let query = '';
    const groupOptionsString = groupOptions?.map(e => 'GroupOptions=' + e);
    query = calendarYearFrom
      ? addQueryParameter(query, 'CalendarYearFrom', calendarYearFrom)
      : query;
    query = calendarYearTo ? addQueryParameter(query, 'CalendarYearTo', calendarYearTo) : query;
    query = quarterFrom ? addQueryParameter(query, 'quarterFrom', quarterFrom) : query;
    query = quarterTo ? addQueryParameter(query, 'quarterTo', quarterTo) : query;
    query = reinsuranceYearFrom
      ? addQueryParameter(query, 'reinsuranceYearFrom', reinsuranceYearFrom)
      : query;
    query = reinsuranceYearTo
      ? addQueryParameter(query, 'reinsuranceYearTo', reinsuranceYearTo)
      : query;
    query = salesEffectiveDateFrom
      ? addQueryParameter(query, 'salesEffectiveDateFrom', formatDateISO(salesEffectiveDateFrom))
      : query;
    query = salesEffectiveDateTo
      ? addQueryParameter(query, 'salesEffectiveDateTo', formatDateISO(salesEffectiveDateTo))
      : query;
    query = stateCode ? addQueryParameter(query, 'stateCode', stateCode) : query;
    const countyCodesString = countyCodes?.map(e => 'CountyCodes=' + e);

    return await api.get(
      `/statistics/table${groupOptionsString ? '?' + groupOptionsString.join('&') : ''}${
        query ? '&' + query : ''
      }${countyCodesString ? '&' + countyCodesString.join('&') : ''}`
    );
  },

  exportGroupTableToExcel: async (groupTableData: GroupStatisticsAllExcel) =>
    await api.post('/statistics/export/excel', groupTableData, { responseType: 'blob' }),

  // Scenario What If Operation Data Endpoints

  getScenarioWhatIfData: async (
    operationName: string,
    scenarioName: string
  ): Promise<AxiosResponse<HistoricalDataDto[]>> =>
    await api.get(`/operation/${operationName}/production/whatif/${scenarioName}`),

  editScenarioWhatIfDataRecord: async (
    operationName: string,
    scenarioName: string,
    data: HistoricalDataEssentials
  ) => await api.put(`/operation/${operationName}/production/whatif/${scenarioName}`, [data]),

  deleteScenarioWhatIfDataRecord: async (
    operationName: string,
    scenarioName: string,
    year: number,
    month: number
  ) =>
    await api.delete(
      `/operation/${operationName}/production/whatif/${scenarioName}/${year}/${month}`
    ),

  saveAllScenarioWhatIfDataRecords: async (
    operationName: string,
    scenarioName: string,
    data: HistoricalDataEssentials[]
  ) => await api.put(`/operation/${operationName}/production/whatif/${scenarioName}`, data),

  downloadScenarioWhatIfData: async (operationName: string, scenarioName: string) =>
    await api.get(`/operation/${operationName}/production/whatif/${scenarioName}/download`),

  resetWhatIf: async (
    operationName: string,
    scenarioName: string,
    resetEndorsements: boolean,
    resetPrices: boolean,
    resetOperationData: boolean
  ) => {
    let query = '';
    query = addQueryParameter(query, 'name', scenarioName);
    query = addQueryParameter(query, 'endorsements', resetEndorsements);
    query = addQueryParameter(query, 'prices', resetPrices);
    query = addQueryParameter(query, 'operationData', resetOperationData);

    return await api.get(`/drp/operation/${operationName}/whatif/reset${query ? '?' + query : ''}`);
  },

  updateScenarioAnalysisWhatIf: async (data: ScenarioAnalysisWhatIfPost) =>
    await api.post('/whatif', data),

  getScenarioAnalysisProjectedRevenue: async (
    operationName: string,
    year: number,
    scenarioName: string,
    data: ProjectionDataQuery
  ) => {
    let query = '';
    type QueryType =
      | 'projectionModel'
      | 'q1Basis'
      | 'q2Basis'
      | 'q3Basis'
      | 'q4Basis'
      | 'q1AdjustedBasisCwt'
      | 'q2AdjustedBasisCwt'
      | 'q3AdjustedBasisCwt'
      | 'q4AdjustedBasisCwt';
    (
      [
        'projectionModel',
        'q1Basis',
        'q2Basis',
        'q3Basis',
        'q4Basis',
        'q1AdjustedBasisCwt',
        'q2AdjustedBasisCwt',
        'q3AdjustedBasisCwt',
        'q4AdjustedBasisCwt'
      ] as QueryType[]
    ).forEach(q => {
      const value = data[q];
      if (value !== undefined && value !== null) query = addQueryParameter(query, q, value);
    });
    return await api.get<ProjectionAnalysisDataDto>(
      `drp/analysis/projected/revenue/${operationName}/${year}/whatif/${scenarioName}${
        query ? '?' + query : ''
      }`
    );
  },

  getOperationReports: async (
    agencyName: string,
    calendarYearFrom?: number,
    calendarYearTo?: number,
    quarterFrom?: number,
    quarterTo?: number,
    reinsuranceYearFrom?: number,
    reinsuranceYearTo?: number
  ) => {
    let query = '';
    query = calendarYearFrom
      ? addQueryParameter(query, 'calendarYearFrom', calendarYearFrom)
      : query;
    query = calendarYearTo ? addQueryParameter(query, 'calendarYearTo', calendarYearTo) : query;
    query = quarterFrom ? addQueryParameter(query, 'quarterFrom', quarterFrom) : query;
    query = quarterTo ? addQueryParameter(query, 'quarterTo', quarterTo) : query;
    query = reinsuranceYearFrom
      ? addQueryParameter(query, 'reinsuranceYearFrom', reinsuranceYearFrom)
      : query;
    query = reinsuranceYearTo
      ? addQueryParameter(query, 'reinsuranceYearTo', reinsuranceYearTo)
      : query;

    return await api.get(
      `/management/${agencyName}/report/drp/operation${query ? '?' + query : ''}`
    );
  },

  getSmsUsage: async (
    startDate?: Date,
    endDate?: Date,
    _agency?: string,
    groupByMonth?: boolean
  ) => {
    let query = '';
    query = startDate ? addQueryParameter(query, 'DateFrom', formatDateISO(startDate)) : query;
    query = endDate ? addQueryParameter(query, 'DateTo', formatDateISO(endDate)) : query;
    query =
      groupByMonth !== undefined ? addQueryParameter(query, 'groupByMonth', groupByMonth) : query;

    return await api.get(`/sms/usage${query ? '?' + query : ''}`);
  },

  getSmsUsageForAgency: async (
    agencyName: string,
    startDate?: Date,
    endDate?: Date,
    _agency?: string,
    groupByMonth?: boolean
  ) => {
    let query = '';
    query = startDate ? addQueryParameter(query, 'DateFrom', formatDateISO(startDate)) : query;
    query = endDate ? addQueryParameter(query, 'DateTo', formatDateISO(endDate)) : query;
    query =
      groupByMonth !== undefined ? addQueryParameter(query, 'groupByMonth', groupByMonth) : query;

    return await api.get(`/sms/${agencyName}/usage${query ? '?' + query : ''}`);
  },

  downloadSmsUsageTable: async (startDate?: Date, endDate?: Date, agencyName?: string) => {
    let query = '';
    query = startDate ? addQueryParameter(query, 'DateFrom', formatDateISO(startDate)) : query;
    query = endDate ? addQueryParameter(query, 'DateTo', formatDateISO(endDate)) : query;
    query = agencyName ? addQueryParameter(query, 'AgencyName', agencyName) : query;

    return await api.get(`/sms/excel${query ? '?' + query : ''}`, { responseType: 'blob' });
  },

  getLrpEndorsements: async (operationName: string, data: LrpEndorsementSummaryDto) => {
    let query = '';

    query = data.commodity ? addQueryParameter(query, 'Commodity', data.commodity) : query;
    query = data.typeCode ? addQueryParameter(query, 'TypeCode', data.typeCode) : query;
    query = data.reinsuranceYearFrom
      ? addQueryParameter(query, 'ReinsuranceYearFrom', data.reinsuranceYearFrom)
      : query;
    query = data.reinsuranceYearTo
      ? addQueryParameter(query, 'ReinsuranceYearTo', data.reinsuranceYearTo)
      : query;
    query = data.calendarYearFrom
      ? addQueryParameter(query, 'CalendarYearFrom', data.calendarYearFrom)
      : query;
    query = data.calendarYearTo
      ? addQueryParameter(query, 'CalendarYearTo', data.calendarYearTo)
      : query;
    query = data.salesEffectiveDateFrom
      ? addQueryParameter(
          query,
          'SalesEffectiveDateFrom',
          formatDateISO(data.salesEffectiveDateFrom)
        )
      : query;
    query = data.salesEffectiveDateTo
      ? addQueryParameter(query, 'SalesEffectiveDateTo', formatDateISO(data.salesEffectiveDateTo))
      : query;
    query = data.endDateFrom
      ? addQueryParameter(query, 'EndDateFrom', formatDateISO(data.endDateFrom))
      : query;
    query = data.endDateTo
      ? addQueryParameter(query, 'EndDateTo', formatDateISO(data.endDateTo))
      : query;
    query = data.topCondition ? addQueryParameter(query, 'TopCondition', data.topCondition) : query;
    query = data.groupByEndMonth
      ? addQueryParameter(query, 'GroupByEndMonth', data.groupByEndMonth)
      : query;
    query = addQueryParameterArray(query, 'TagNames', data.tagNames ?? []);

    if (data?.actualPriceDate) {
      query = addQueryParameter(query, 'actualPriceDate', data?.actualPriceDate);
    }
    return await api.get(`/lrp/${operationName}/endorsement${query ? '?' + query : ''}`);
  },

  getLrpEndorsementFilter: async (operationName: string) =>
    await api.get(`/lrp/${operationName}/endorsement/filter`),

  getLrpEndorsementSummaryTable: async (operationName: string, data: LrpEndorsementSummaryDto) => {
    let query = '';
    query = data.commodity ? addQueryParameter(query, 'commodity', data.commodity) : query;
    query = data.typeCode ? addQueryParameter(query, 'typeCode', data.typeCode) : query;
    query = data.reinsuranceYearFrom
      ? addQueryParameter(query, 'reinsuranceYearFrom', data.reinsuranceYearFrom)
      : query;
    query = data.reinsuranceYearTo
      ? addQueryParameter(query, 'reinsuranceYearTo', data.reinsuranceYearTo)
      : query;
    query = data.calendarYearFrom
      ? addQueryParameter(query, 'calendarYearFrom', data.calendarYearFrom)
      : query;
    query = data.calendarYearTo
      ? addQueryParameter(query, 'calendarYearTo', data.calendarYearTo)
      : query;
    query = data.salesEffectiveDateFrom
      ? addQueryParameter(
          query,
          'salesEffectiveDateFrom',
          formatDateISO(data.salesEffectiveDateFrom)
        )
      : query;
    query = data.salesEffectiveDateTo
      ? addQueryParameter(query, 'salesEffectiveDateTo', formatDateISO(data.salesEffectiveDateTo))
      : query;
    query = data.endDateFrom
      ? addQueryParameter(query, 'endDateFrom', formatDateISO(data.endDateFrom))
      : query;
    query = data.endDateTo
      ? addQueryParameter(query, 'endDateTo', formatDateISO(data.endDateTo))
      : query;
    query = data.topCondition ? addQueryParameter(query, 'topCondition', data.topCondition) : query;
    query = data.groupByEndMonth
      ? addQueryParameter(query, 'groupByEndMonth', data.groupByEndMonth)
      : query;
    query = addQueryParameterArray(query, 'TagNames', data.tagNames ?? []);
    if (data?.actualPriceDate) {
      query = addQueryParameter(query, 'actualPriceDate', data?.actualPriceDate);
    }
    return await api.get(`/lrp/${operationName}/endorsement/summary${query ? '?' + query : ''}`);
  },

  recalculateLrpEndorsements: async (operationName: string) => {
    return await api.get(`/lrp/${operationName}/endorsement/update`);
  },

  createLrpEndorsement: async (operationName: string, data: LrpEndorsementDefinition) => {
    const newData = {
      ...data,
      calculationParameters: {
        ...data.calculationParameters,
        salesEffectiveDate: formatDateISO(data.calculationParameters.salesEffectiveDate)
      }
    };
    return await api.post(`/lrp/${operationName}/endorsement`, newData);
  },

  deleteLrpEndorsement: async (data: LrpDeleteEndorsement) => {
    return await api.delete(`/lrp/${data.operationName}/endorsement/${data.id}`);
  },

  updateLrpEndorsement: async (operationName: string, data: LrpUpdateEndorsement) => {
    const newData = {
      ...data,
      calculationParameters: {
        ...data.calculationParameters,
        salesEffectiveDate: formatDateISO(data.calculationParameters.salesEffectiveDate)
      }
    };
    return await api.put(`/lrp/${operationName}/endorsement/${data.id}`, newData);
  },

  getLrpAnalysisCurrentPrices: async (data: LrpAnalysisFilterSettings) => {
    let query = '';
    query = data.salesEffectiveDate
      ? addQueryParameter(query, 'Date', formatDateISO(data.salesEffectiveDate))
      : query;
    query = data.filterComponent.commodity
      ? addQueryParameter(query, 'Commodity', data.filterComponent.commodity.id)
      : query;
    query = data.filterComponent.commodityType
      ? addQueryParameter(query, 'CommodityType', data.filterComponent.commodityType.id)
      : query;
    query = data.filterComponent.deductible
      ? addQueryParameter(query, 'Deductible', data.filterComponent.deductible.id)
      : query;
    query = data.filterComponent.numberOfHead
      ? addQueryParameter(query, 'NumberOfHead', data.filterComponent.numberOfHead)
      : query;
    query = data.filterComponent.targetWeight
      ? addQueryParameter(query, 'TargetWeight', data.filterComponent.targetWeight)
      : query;
    query = data.yearRange.yearTo
      ? addQueryParameter(query, 'YearTo', data.yearRange.yearTo)
      : query;
    query = data.yearRange.yearFrom
      ? addQueryParameter(query, 'YearFrom', data.yearRange.yearFrom)
      : query;

    return await api.get(`/lrp/analysis/monthly${query ? '?' + query : ''}`);
  },

  getLrpAnalysisHistoricalPrices: async (data: LrpAnalysisFilterSettings) => {
    let query = '';
    query = data.salesEffectiveDate
      ? addQueryParameter(query, 'Date', formatDateISO(data.salesEffectiveDate))
      : query;
    query = data.filterComponent.commodity
      ? addQueryParameter(query, 'Commodity', data.filterComponent.commodity.id)
      : query;
    query = data.filterComponent.commodityType
      ? addQueryParameter(query, 'CommodityType', data.filterComponent.commodityType.id)
      : query;
    query = data.filterComponent.deductible
      ? addQueryParameter(query, 'Deductible', data.filterComponent.deductible.id)
      : query;
    query = data.filterComponent.numberOfHead
      ? addQueryParameter(query, 'NumberOfHead', data.filterComponent.numberOfHead)
      : query;
    query = data.filterComponent.targetWeight
      ? addQueryParameter(query, 'TargetWeight', data.filterComponent.targetWeight)
      : query;
    query = data.yearRange.yearTo
      ? addQueryParameter(query, 'YearTo', data.yearRange.yearTo)
      : query;
    query = data.yearRange.yearFrom
      ? addQueryParameter(query, 'YearFrom', data.yearRange.yearFrom)
      : query;

    return await api.get(`/lrp/analysis/yearly${query ? '?' + query : ''}`);
  },
  getLrpPriceEvolutionAnalysis: async (data: LrpPriceEvolutionAnalysisSettings) => {
    let query = '';
    query =
      data.endMonth && data.year
        ? addQueryParameter(query, 'Date', data.year + '-' + data.endMonth.id + '-15')
        : query;
    query = data.filterComponent.commodity
      ? addQueryParameter(query, 'Commodity', data.filterComponent.commodity.id)
      : query;
    query = data.filterComponent.commodityType
      ? addQueryParameter(query, 'CommodityType', data.filterComponent.commodityType.id)
      : query;
    query = data.filterComponent.deductible
      ? addQueryParameter(query, 'Deductible', data.filterComponent.deductible.id)
      : query;
    query = data.filterComponent.numberOfHead
      ? addQueryParameter(query, 'NumberOfHead', data.filterComponent.numberOfHead)
      : query;
    query = data.filterComponent.targetWeight
      ? addQueryParameter(query, 'TargetWeight', data.filterComponent.targetWeight)
      : query;
    query = data.yearRange.yearTo
      ? addQueryParameter(query, 'YearTo', data.yearRange.yearTo)
      : query;
    query = data.yearRange.yearFrom
      ? addQueryParameter(query, 'YearFrom', data.yearRange.yearFrom)
      : query;

    return await api.get(`/lrp/analysis/historic/${data.measure.id}${query ? '?' + query : ''}`);
  },

  downloadManagementReportTable: async (agencyName: string, payload: OperationReport[]) =>
    await api.post(`/management/${agencyName}/report/drp/operation/export/excel`, payload, {
      responseType: 'blob'
    }),

  getTags: async (operationName: string) => await api.get(`/operation/${operationName}/tag`),

  createTag: async (operationName: string, requestBody: TagRequestBody) =>
    await api.post(`/operation/${operationName}/tag`, requestBody),

  editTag: async (operationName: string, requestBody: TagRequestBody, tagName: string) =>
    await api.put(`/operation/${operationName}/tag/${tagName}`, requestBody),

  deleteTag: async (operationName: string, tagName: string) =>
    await api.delete(`/operation/${operationName}/tag/${tagName}`),

  mergeTags: async (operationName: string, tagName: string, selectedTags: string[]) =>
    await api.put(`/operation/${operationName}/tag/${tagName}/tags`, selectedTags),

  getTagTypes: async (agencyName: string) => await api.get(`agency/${agencyName}/tagType`),

  createTagType: async (agencyName: string, tagTypeRequestBody: TagTypeRequestBody) =>
    await api.post(`/agency/${agencyName}/tagType`, tagTypeRequestBody),

  editTagType: async (
    agencyName: string,
    tagTypeRequestBody: TagTypeRequestBody,
    tagType: string
  ) => await api.put(`/agency/${agencyName}/tagType/${tagType}`, tagTypeRequestBody),

  deleteTagType: async (agencyName: string, tagType: string) =>
    await api.delete(`/agency/${agencyName}/tagType/${tagType}`),

  getUnsubscribed: async (
    unsubscribeToken: string,
    endpoint: string,
    queryParamName: string,
    queryParamValue: string
  ) => {
    if (queryParamName) {
      return await api.get(
        `${endpoint}?unsubscribeToken=${unsubscribeToken}&${queryParamName}=${queryParamValue}`
      );
    }

    return await api.get(`${endpoint}?unsubscribeToken=${unsubscribeToken}`);
  }
};

export default insuranceAPI;
