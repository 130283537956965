import { ActionType, createAction } from 'typesafe-actions';

import { AuthState } from './reducer';

export const authActions = {
  signIn: createAction('@auth/SIGN_IN_REQUEST')(),
  signOut: createAction('@auth/SIGN_OUT_REQUEST')(),
  token: {
    set: createAction('@auth_token/SET')(),
    expired: createAction('@auth_token/EXPIRED')(),
    expiring: createAction('@auth_token/EXPIRING')(),
    update: createAction('@auth_token/UPDATED')()
  },
  get: {
    authState: createAction('@auth_get/AUTHENTICATION_STATE')()
  },
  set: {
    authState: createAction('@auth_set/AUTHENTICATION_STATE')<AuthState>(),
    expiresAt: createAction('@auth_set/EXPIRES_AT')<number | undefined>(),
    email: createAction('@auth_set/EMAIL')<string>()
  }
};

export type AuthActions = ActionType<typeof authActions>;
