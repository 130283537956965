import { getType } from 'typesafe-actions';

import { AuthActions, authActions } from './actions';

interface AuthenticationState {
  authState: AuthState;
  expiresAt: number | undefined;
  email: string;
  isTokenSet: boolean;
}

export enum AuthState {
  initialization = 'initialization',
  guest = 'guest',
  producer = 'producer',
  insuranceAgent = 'insuranceAgent',
  insuranceAgentAdministrator = 'insuranceAgentAdministrator',
  globalAdministrator = 'globalAdministrator',
  expired = 'expired'
}

const initialState: AuthenticationState = {
  authState: AuthState.initialization,
  expiresAt: undefined,
  email: '',
  isTokenSet: false
};

export const authenticationPageReducer = (state = initialState, action: AuthActions) => {
  switch (action.type) {
    case getType(authActions.set.authState):
      return { ...state, authState: action.payload };
    case getType(authActions.set.expiresAt):
      return { ...state, expiresAt: action.payload };
    case getType(authActions.set.email):
      return { ...state, email: action.payload };
    case getType(authActions.token.set): {
      return { ...state, isTokenSet: true };
    }
    default:
      return state;
  }
};
