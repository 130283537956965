import { AxiosResponse } from 'axios';
import { compareAsc, endOfQuarter, startOfQuarter } from 'date-fns';

import { Quarter } from '../../types/Calendar/Calendar';
import { CalendarQuarterDto } from '../../types/dto/CalendarQuarterDto';
import { formatDateMonth, formatDateYearMonth } from '../../utils/dateFormatter';

const mapCalendarQuarterDtoToCalendarQuarter = ({
  data
}: AxiosResponse<CalendarQuarterDto>): Quarter[] =>
  data
    .value!.filter(({ Active }) => Active)
    .map(v => {
      const quarterDate = new Date(v.QuarterDate);
      return {
        quarterDate,
        quarterName: `${formatDateMonth(startOfQuarter(quarterDate)).slice(
          0,
          3
        )} - ${formatDateYearMonth(endOfQuarter(quarterDate))}`,
        salesBeginDate: new Date(v.SalesBeginDate),
        salesEndDate: new Date(v.SalesEndDate),
        firstReinsuranceYear: v.FirstReinsuranceYear,
        lastReinsuranceYear: v.LastReinsuranceYear,
        actualDataReleaseDate: v.ActualDataReleaseDate
          ? new Date(v.ActualDataReleaseDate)
          : undefined,
        isApproximate: v.IsActualDataReleaseDateApproximate
      };
    })
    .sort((a, b) => compareAsc(a.quarterDate, b.quarterDate));

export default mapCalendarQuarterDtoToCalendarQuarter;
