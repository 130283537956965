import { AxiosResponse } from 'axios';

import { Scenario } from '../../types/Scenario';
import { ScenarioDto } from '../../types/dto/ScenarioDto';

const priceMapper = ({
  date,
  expectedPrice,
  actualPrice
}: {
  date: string;
  expectedPrice: number;
  actualPrice: number;
}) => ({
  expectedPrice,
  actualPrice,
  date: new Date(date)
});

const mapScenarioDtoToScenario = ({ data }: AxiosResponse<ScenarioDto>): Scenario => ({
  ...data,
  salesEffectiveDate: new Date(data.salesEffectiveDate),
  butterPrice: data.butterPrice.map(priceMapper),
  cheesePrice: data.cheesePrice.map(priceMapper),
  dryWheyPrice: data.dryWheyPrice.map(priceMapper),
  nonfatDryMilkPrice: data.nonfatDryMilkPrice.map(priceMapper),
  classIIIPrice: data.classIIIPrice.map(priceMapper),
  classIVPrice: data.classIVPrice.map(priceMapper)
});

export default mapScenarioDtoToScenario;
