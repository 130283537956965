import { all, call, put, select, takeLatest } from '@redux-saga/core/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import Operation from '../../../types/Operation/Operation';
import { OperationDto } from '../../../types/dto/OperationDto';
import sortObjectByKey from '../../../utils/sortObjectByKey';
import { loadersActions } from '../../loaders/actions';
import { adminActions } from '../actions';
import { getOperations, getProducerOperations } from '../selectors';

function* fetchProducerOperations(
  action: ActionType<typeof adminActions.producerOperations.getProducerOperations>
): any {
  try {
    const operations = yield call(insuranceAPI.getProducerOperations, action.payload);
    yield put(
      adminActions.producerOperations.addProducerOperations(operations.data, action.payload)
    );
  } catch (e) {
    console.log(e);
  }
}

function* assignProducerToOperation(
  action: ActionType<typeof adminActions.producerOperations.assignOperation>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const { operation, agencyName, producer } = action.payload;
    yield call(insuranceAPI.createAgencyOperationProducer, agencyName, operation.name, {
      firstName: producer.firstName,
      lastName: producer.lastName,
      email: producer.email,
      phoneNumber: producer.phoneNumber,
      timeZone: producer.timeZone,
      principalContactAgent: producer.principalContactAgent
    });
    yield put(
      adminActions.producerOperations.saveAssignedOperation({
        operation,
        producerId: producer.userId
      })
    );
    yield put(loadersActions.spinner.disable());
  } catch (e) {
    yield put(loadersActions.spinner.disable());
    console.error(e);
  }
}

function* removeProducerOperation(
  action: ActionType<typeof adminActions.producerOperations.removeAssignedOperation>
) {
  const { producerId, operationName, agencyName } = action.payload;
  try {
    yield put(loadersActions.spinner.activate());
    yield call(insuranceAPI.removeAgencyOperationProducer, agencyName, operationName, producerId);
    yield put(
      adminActions.producerOperations.deleteAssignedOperation({ producerId, operationName })
    );
    yield put(loadersActions.spinner.disable());
  } catch (e) {
    yield put(loadersActions.spinner.disable());
    console.error(e);
  }
}

export function* producerOperationsSort({
  payload: { sort, direction },
  meta
}: ActionType<typeof adminActions.producerOperations.sort>): any {
  const operations: Operation[] = yield select(getOperations);
  const producerOperations = yield select(getProducerOperations);

  const operationProducers = operations.map(operation => {
    if (producerOperations[meta]) {
      if (producerOperations[meta].some(({ name }: OperationDto) => name === operation.name)) {
        return { ...operation, isAdded: true };
      }
    }
    return { ...operation, isAdded: false };
  });

  operationProducers.sort(sortObjectByKey(sort, direction));

  yield put(
    adminActions.operations.addAllOperations(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      operationProducers.map(({ isAdded, ...operation }) => operation)
    )
  );
}

export function* producerOperationsSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.producerOperations.getProducerOperations),
      fetchProducerOperations
    ),
    takeLatest(getType(adminActions.producerOperations.assignOperation), assignProducerToOperation),
    takeLatest(
      getType(adminActions.producerOperations.removeAssignedOperation),
      removeProducerOperation
    ),
    takeLatest(getType(adminActions.producerOperations.sort), producerOperationsSort)
  ]);
}
