import { ParametricSelector } from 'reselect';

import { SelectOption } from '../components/shared/MultiSelect/MultiSelect';
import { Unit } from '../containers/PremiumsContainer/PremiumsContainer';
import EducationVideoCategory from '../enums/EducationVideoCategory';
import SortDirection from '../enums/SortDirection';
import { LrpEndorsementDto } from '../scenes/lrp/types/LrpEndorsement';
import { AnalysisProduct } from '../types/AnalysisProduct';
import EducationVideo from '../types/EducationModule/EducationVideo';
import { OperationReport } from '../types/ManagementReports/OperationReport';
import Operation from '../types/Operation/Operation';
import { SmsUsageDto } from '../types/SmsSettings';
import { AipDto } from '../types/dto/AipOperationDto';
import { GroupStatisticsDto } from '../types/dto/DrpStatisticsDto';

export const getIdFromProps: ParametricSelector<{}, { id: string | null }, string | undefined> = (
  _,
  props
) => props.id ?? undefined;

export const getSortFromProps: ParametricSelector<{}, { sort: string }, string> = (_, props) =>
  props.sort;

export const getDirectionFromProps: ParametricSelector<{}, { direction: string }, string> = (
  _,
  props
) => props.direction;

export const getActivePageFromProps: ParametricSelector<{}, { activePage: number }, number> = (
  _,
  props
) => props.activePage;

export const getPerPageFromProps: ParametricSelector<{}, { perPage: number }, number> = (
  _,
  props
) => props.perPage;

interface EditAllOperationSortProps {
  key: keyof Operation;
  direction: SortDirection;
}

export const getEditOperationSortFromProps: ParametricSelector<
  {},
  EditAllOperationSortProps,
  EditAllOperationSortProps
> = (_, sortProps) => sortProps;

export const getSelectedAgencyFromProps: ParametricSelector<
  {},
  { selectedAgency: string },
  string
> = (_, props) => props.selectedAgency;

export const getInsurancePlanConverterFromProps: ParametricSelector<
  {},
  { insurancePlanConverter: { id: string; name: string }[] },
  { id: string; name: string }[]
> = (_, props) => props.insurancePlanConverter;

export const getSearchFromProps: ParametricSelector<{}, { search: string }, string> = (_, props) =>
  props.search;

interface SmsUsageSortProps {
  sortKey: keyof SmsUsageDto;
  direction: SortDirection;
}

export const getSmsUsageSortKeyFromProps: ParametricSelector<
  {},
  SmsUsageSortProps,
  SmsUsageSortProps
> = (_, sortProps) => sortProps;

export const getSmsUsageKeyFromProps: ParametricSelector<
  {},
  { key: keyof SmsUsageDto },
  keyof SmsUsageDto
> = (_, props) => props.key;

export const getSmsMessageTypeFromProps: ParametricSelector<
  {},
  { messageType: string[] },
  string[]
> = (_, props) => props.messageType;

interface AipSortProps {
  sortKey: keyof AipDto;
  direction: SortDirection;
}

export const getAipSortKeyFromProps: ParametricSelector<{}, AipSortProps, AipSortProps> = (
  _,
  sortProps
) => sortProps;

export const getAipKeyFromProps: ParametricSelector<{}, { key: keyof AipDto }, keyof AipDto> = (
  _,
  props
) => props.key;

export const getAipEndorsementYearFromProps: ParametricSelector<{}, { year: number }, number> = (
  _,
  props
) => props.year;

interface PremiumProps {
  useBasis: boolean;
  useSubsidy: boolean;
  useProbability: boolean;
  useProtectionFactorAnalysis: boolean;
  unit: Unit;
}
export const getPremiumPropsFromProps: ParametricSelector<{}, PremiumProps, PremiumProps> = (
  _,
  props
) => ({
  ...props
});

interface LgmPremiumProps {
  subsidy: boolean;
  probability: boolean;
  unit: Unit;
}
export const getLgmPremiumPropsFromProps: ParametricSelector<
  {},
  LgmPremiumProps,
  LgmPremiumProps
> = (_, props) => ({
  ...props
});

export const getOperationFromProps: ParametricSelector<{}, { operation: string }, string> = (
  _,
  props
) => props.operation;

export const getScenarioNameFromProps: ParametricSelector<{}, { scenarioName: string }, string> = (
  _,
  props
) => props.scenarioName;

export const getProgramNameFromProps: ParametricSelector<{}, { program: string }, string> = (
  _,
  props
) => props.program;

export const getRouteComponentPropsFromProps: ParametricSelector<{}, { name: string }, string> = (
  _,
  props
) => props.name;

export const getReferenceIdentifierFromProps: ParametricSelector<
  {},
  { refIdentifier: string },
  string
> = (_, props) => props.refIdentifier;

export const getUserIdPropsFromProps: ParametricSelector<{}, { userId: string }, string> = (
  _,
  props
) => props.userId;

export const getAgentModalSearchFromProps: ParametricSelector<
  {},
  { agentModalSearch: string },
  string
> = (_, props) => props.agentModalSearch;

export const getStateModalSearchFromProps: ParametricSelector<
  {},
  { stateModalSearch: string },
  string
> = (_, props) => props.stateModalSearch;

export const getPriceTrendProducts: ParametricSelector<
  {},
  { products: AnalysisProduct[] },
  AnalysisProduct[]
> = (_, props) => props.products;

export const getAlphabetSortFromProps: ParametricSelector<{}, { alphabetSort: string }, string> = (
  _,
  props
) => props.alphabetSort;

export const getUnitFromProps: ParametricSelector<{}, { unit: Unit }, Unit> = (_, props) =>
  props.unit;

export const getFromYearFromProps: ParametricSelector<{}, { fromYear: number }, number> = (
  _,
  props
) => props.fromYear;

export const getToYearFromProps: ParametricSelector<{}, { toYear: number }, number> = (_, props) =>
  props.toYear;

export const getReinsuranceYearFromProps: ParametricSelector<
  {},
  { reinsuranceYear: number },
  number
> = (_, props) => props.reinsuranceYear;

export const getYearFromProps: ParametricSelector<{}, { year: number }, number> = (_, props) =>
  props.year;

export const getDateFromProps: ParametricSelector<{}, { date: Date }, Date> = (_, props) =>
  props.date;

export const getQuarterStringFromProps: ParametricSelector<
  {},
  { quarter: string; actualPriceDate?: string | null },
  { quarter: string; actualPriceDate?: string | null }
> = (_, props) => props;

export const getIsPriceTrendExpectedDataAvailable: ParametricSelector<
  {},
  { isPriceTrendExpectedDataAvailable: boolean },
  boolean
> = (_, props) => props.isPriceTrendExpectedDataAvailable;

interface AgentModalSortProps {
  selectedOperation: string;
  activePage: number;
  perPage: number;
}

export const getAgentModalSelectorProps: ParametricSelector<
  {},
  AgentModalSortProps,
  AgentModalSortProps
> = (_, sortProps) => sortProps;

export const getEmailFromProps: ParametricSelector<{}, { email: string }, string> = (_, props) =>
  props.email;

interface ProducerModalSortProps {
  producerId: string;
  activePage: number;
  perPage: number;
  search: string;
}

export const getProducerModalSelectorProps: ParametricSelector<
  {},
  ProducerModalSortProps,
  ProducerModalSortProps
> = (_, sortProps) => sortProps;

interface OperationModalSortProps {
  selectedOperation: string;
  activePage: number;
  perPage: number;
  search: string;
}

export const getOperationModalSelectorProps: ParametricSelector<
  {},
  OperationModalSortProps,
  OperationModalSortProps
> = (_, sortProps) => sortProps;

export const getDeductibleFromProps: ParametricSelector<{}, { deductible: number }, number> = (
  _,
  props
) => props.deductible;

export const getStateCodeFromProps: ParametricSelector<{}, { stateCode: string }, string> = (
  _,
  props
) => props.stateCode;

interface OperationReportSortProps {
  key: keyof OperationReport;
  type: SortDirection;
}

export const getOperationReportSortProps: ParametricSelector<
  {},
  OperationReportSortProps,
  OperationReportSortProps
> = (_, sortProps) => sortProps;

export const getEducationVideoCategoryFromProps: ParametricSelector<
  {},
  { category: EducationVideoCategory },
  EducationVideoCategory
> = (_, props) => props.category;

export const getEducationVideoSearchFromProps: ParametricSelector<
  {},
  { search: string },
  string
> = (_, props) => props.search;

interface EducationVideoSortProps {
  key: keyof EducationVideo;
  direction: SortDirection;
}

export const getEducationVideoSortProps: ParametricSelector<
  {},
  EducationVideoSortProps,
  EducationVideoSortProps
> = (_, sortProps) => sortProps;

interface GroupStatisticsSortProps {
  key: keyof GroupStatisticsDto;
  direction: SortDirection;
}

export const getGroupStatisticsSortProps: ParametricSelector<
  {},
  GroupStatisticsSortProps,
  GroupStatisticsSortProps
> = (_, sortProps) => sortProps;

export const getStateFiltersFromProps: ParametricSelector<
  {},
  { stateFilters: string[] },
  string[]
> = (_, props) => props.stateFilters;

export const getCountyFiltersFromProps: ParametricSelector<
  {},
  { countyFilters: string[] },
  string[]
> = (_, props) => props.countyFilters;
interface LrpEndorsementSortProps {
  sortKey: keyof LrpEndorsementDto;
  direction: SortDirection;
}

export const getLrpEndorsementSortKeyFromProps: ParametricSelector<
  {},
  LrpEndorsementSortProps,
  LrpEndorsementSortProps
> = (_, sortProps) => sortProps;
interface LrpEndorsementSummaryProps {
  year: number;
  month: number;
  commodity: string;
  actualPriceDate?: string | null;
}

export const getSelectedLrpEndorsementSummaryFromProps: ParametricSelector<
  {},
  LrpEndorsementSummaryProps,
  LrpEndorsementSummaryProps
> = (_, sortProps) => sortProps;

export const getPriceTrendsMeasureFromProps: ParametricSelector<
  {},
  { selectedMeasure: string },
  string
> = (_, props) => props.selectedMeasure;

export const getPriceTrendsUnitFromProps: ParametricSelector<{}, { unit: number }, number> = (
  _,
  props
) => props.unit;

export const getFilteredOperationEndorsementsProps: ParametricSelector<
  {},
  { actualPriceDate?: string },
  string
> = (_, props) => props.actualPriceDate ?? '';

export const getYearsFromProps: ParametricSelector<
  {},
  { years: SelectOption[] | SelectOption | undefined },
  SelectOption[]
> = (_, props) => (Array.isArray(props.years) ? props.years : returnIfNotArray(props.years));

const returnIfNotArray = (years: SelectOption | undefined) => {
  return years ? [years] : [];
};
