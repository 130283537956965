import { ActionType, createAction } from 'typesafe-actions';

import { DrpStatistics } from '../../types/DrpStatistics';
import { DrpStatisticsGroup } from '../../types/DrpStatisticsGroup';
import {
  DrpStatisticsDto,
  GroupStatisticsAllDto,
  GroupStatisticsAllExcel,
  StatisticsBarChart,
  StatisticsHedgeRatio
} from '../../types/dto/DrpStatisticsDto';

export const drpSalesStatisticsActions = {
  getStatistics: createAction('@drp_sales_statistics/GET_STATISTICS')<
    DrpStatistics,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  getStateCountyStatistics: createAction('@drp_sales_statistics/GET_STATE_COUNTY_STATISTICS')<
    DrpStatistics,
    React.Dispatch<React.SetStateAction<boolean>>
  >(),
  saveStatisticsUSA: createAction('@drp_sales_statistics/SAVE_STATISTICS_USA')<DrpStatisticsDto>(),
  saveStatisticsState: createAction('@drp_sales_statistics/SAVE_STATISTICS_STATE')<
    DrpStatisticsDto | undefined
  >(),
  saveStatisticsCounties: createAction(
    '@drp_sales_statistics/SAVE_STATISTICS_COUNTIES'
  )<DrpStatisticsDto>(),
  getBarStatistics: createAction('@drp_sales_statistics/GET_BAR')<
    DrpStatistics,
    React.Dispatch<React.SetStateAction<StatisticsBarChart | undefined>>
  >(),
  getHedgeRatio: createAction('@drp_sales_statistics/GET_HEDGE_RATIO')<
    { year?: number; quarter?: number; stateCodes?: string[] },
    {
      setHedgeRatio: React.Dispatch<React.SetStateAction<StatisticsHedgeRatio | undefined>>;
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    }
  >(),
  updateCardOption: createAction('@drp_sales_statistics/SET_CARD_OPTIONS')<string>(),
  getGroupStatistics: createAction('@drp_sales_statistics/GET_GROUP_STATISTICS')<
    DrpStatisticsGroup,
    {
      setFetchWorked: React.Dispatch<React.SetStateAction<boolean>>;
      setCanExport: React.Dispatch<React.SetStateAction<boolean>>;
    }
  >(),
  saveGroupStatistics: createAction(
    '@drp_sales_statistics/SAVE_GROUP_STATISTICS'
  )<GroupStatisticsAllDto>(),
  exportGroupTableToExcel: createAction('@drp_sales_statistics/EXPORT_TABLE_EXCEL_STATISTICS')<
    GroupStatisticsAllExcel,
    React.Dispatch<React.SetStateAction<boolean>>
  >()
};

export type DrpSalesStatisticsActions = ActionType<typeof drpSalesStatisticsActions>;
