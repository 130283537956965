import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { getType } from 'typesafe-actions';

import calendarAPI from '../../services/calendarService';
import { CalendarDto } from '../../types/dto/CalendarDto';
import { CalendarQuarterDto } from '../../types/dto/CalendarQuarterDto';
import { utcDateToLocalDate } from '../../utils/utcDateToLocalDate';
import { calendarActions } from './actions';
import mapCalendarDtoToCalendar from './mapCalendarDtoToCalendar';
import mapCalendarQuarterDtoToCalendarQuarter from './mapCalendarQuarterDtoToCalendarQuarter';

function* getCalendar() {
  try {
    const calendarDto: AxiosResponse<CalendarDto> = yield call(calendarAPI.getCalendar);
    const quarterDto: AxiosResponse<CalendarQuarterDto> = yield call(calendarAPI.getQuarters);

    const { dates, events, calendarInfo } = mapCalendarDtoToCalendar(calendarDto);
    const quarters = mapCalendarQuarterDtoToCalendarQuarter(quarterDto);

    const mappedQuarters = quarters.map(quarter => {
      return {
        ...quarter,
        salesBeginDate: utcDateToLocalDate(quarter.salesBeginDate),
        salesEndDate: utcDateToLocalDate(quarter.salesEndDate),
        actualDataReleaseDate:
          quarter.actualDataReleaseDate && utcDateToLocalDate(quarter.actualDataReleaseDate)
      };
    });

    yield put(calendarActions.add({ dates, events, quarters: mappedQuarters, info: calendarInfo }));
  } catch (e) {
    console.log(e);
  }
}

function* calendarSagaWatcher() {
  yield all([takeLatest(getType(calendarActions.get), getCalendar)]);
}

export default calendarSagaWatcher;
