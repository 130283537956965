import { getType } from 'typesafe-actions';

import Operation from '../../../types/Operation/Operation';
import { AdminActions, adminActions } from '../actions';

const initialState: Operation[] = [];

const operationsReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.operations.addAllOperations): {
      return action.payload;
    }
    case getType(adminActions.operations.newOperation): {
      const newState = [...state];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { primaryContact, ...newOperation } = action.payload;
      newState.push(newOperation);
      return newState;
    }
    case getType(adminActions.operations.updateOperation): {
      const newState = [...state];
      const index = newState.findIndex(operation => operation.name === action.meta);
      if (index !== -1) {
        newState[index] = action.payload;
      }
      return newState;
    }
    case getType(adminActions.operations.updateStarredOperation): {
      const newState = [...state];
      const index = newState.findIndex(operation => operation.name === action.payload);
      if (index !== -1) {
        newState[index].starred = action.meta;
      }
      return newState;
    }
    case getType(adminActions.operations.removeOperation): {
      const newState = [...state];
      const index = newState.findIndex(operation => operation.name === action.payload);
      if (index !== -1) {
        newState.splice(index, 1);
      }
      return newState;
    }
    default:
      return state;
  }
};

export default operationsReducer;
