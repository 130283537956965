import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import Operation from '../../types/Operation/Operation';
import OperationWithPrimaryContact from '../../types/Operation/OperationWithPrimaryContact';

export const operationPageActions = {
  setOperation: createAction('operationPage/SET_OPERATION')<string>(),
  setOperationName: createAction('operationPage/SET_OPERATION_NAME')<string>(),
  getOperation: createAction('operationPage/GET_OPERATION')<{
    operationName: string;
    bindingRequirements: boolean;
  }>(),
  saveOperation: createAction('operationPage/SAVE_OPERATION')<Operation>(),
  sortOperations: createAction('operationPage/SORT_OPERATIONS')<{
    sort: keyof OperationWithPrimaryContact;
    direction: SortDirection;
    isDemo: boolean;
  }>(),
  saveSortedOperations: createAction('operationPage/SAVE_SORTED_OPERATIONS')<
    OperationWithPrimaryContact[]
  >(),
  setStaredOperation: createAction('operationPage/SET_STARED_OPERATION')<string, boolean>(),
  updateStaredOperation: createAction('operationPage/UPDATE_STARED_OPERATION')<string, boolean>(),
  getDefaultOperationProgram: createAction('operationPage/GET_DEFAULT_OPERATION_PROGRAM')<
    string,
    {
      setDefaultProgram: React.Dispatch<React.SetStateAction<string | null>>;
    }
  >()
};

export type OperationPageActions = ActionType<typeof operationPageActions>;
