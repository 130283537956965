import { ActionType, createAction } from 'typesafe-actions';

import {
  TagType,
  TagTypeRequestBody
} from '../../containers/AdminPanelContainers/TagTypes/types/TagType';

export const tagTypeActions = {
  tagTypes: {
    set: createAction('@tagTypes_action/SET')<TagType[]>(),
    fetch: createAction('@tagTypes_action/FETCH')<{ agencyName: string; manageLoader?: boolean }>(),
    push: createAction('@tagTypes_action/PUSH')<TagTypeRequestBody>(),
    edit: createAction('@tagTypes_action/EDIT')<TagType>(),
    delete: createAction('@tagTypes_action/DELETE')<{ agencyName: string; tagType: string }>()
  },
  tagTypeLoader: {
    activate: createAction('@tagTypeLoader/ACTIVATE')(),
    disable: createAction('@tagTypeLoader/DISABLE')()
  }
};

export type TagTypeActions = ActionType<typeof tagTypeActions>;
