import { createSelector } from 'reselect';

import { Signer } from '../../scenes/lrp/containers/LrpPremiumQuoter/RequestCoverageModal/RequestCoverageModal.types';
import { agentCanBind } from '../../types/Agent/Agent';
import { operationCanBind } from '../../types/Operation/Operation';
import OperationAgentContactData from '../../types/Operation/OperationAgentContactData';
import OperationContact from '../../types/Operation/OperationContact';
import InsurancePlan from '../../types/dto/InsurancePlan';
import containsString from '../../utils/containsString';
import { getAdminPageAgency } from '../adminPage/selectors';
import { getAllInsuranceAgents, getInsuranceAgents, getOperations } from '../adminPanel/selectors';
import {
  getActivePageFromProps,
  getAgentModalSelectorProps,
  getOperationFromProps,
  getPerPageFromProps,
  getSearchFromProps,
  getUserIdPropsFromProps
} from '../fromPropsSelector';
import { getOperationName, getOperationPageOperationData } from '../operationPage/selectors';
import { RootState } from '../store';

export const getAllOperationContactOperations = (state: RootState) =>
  state.operationContact.operations;
export const getAllOperationContactOperationList = (state: RootState) =>
  state.operationContact.allOperations;
export const getAgencyOperationsContacts = (state: RootState) =>
  state.operationContact.agencyOperationsContacts;

export const getOperationAgentsNameFromProps = createSelector(
  getAllOperationContactOperations,
  getOperationFromProps,
  (operationContacts, operation) => {
    if (!operationContacts[operation]) {
      return [];
    }
    return operationContacts[operation].allAgents.map(name => {
      return operationContacts[operation].agents[name];
    });
  }
);

export const getOperationAgents = createSelector(
  getAllOperationContactOperations,
  getOperationName,
  (operationContacts, operation) => {
    if (!operationContacts[operation]) {
      return [];
    }
    return operationContacts[operation].allAgents.map(name => {
      return operationContacts[operation].agents[name];
    });
  }
);

export const getOperationAgentsSignerCanBindOperationNameFromProps = createSelector(
  getAllOperationContactOperations,
  getAllInsuranceAgents,
  getOperationFromProps,
  (operationContacts, agents, operation) => {
    const operationAgents: any[] = [];

    if (operationContacts[operation]) {
      operationContacts[operation].allAgents.forEach(name => {
        operationAgents.push(operationContacts[operation].agents[name]);
      });
    }

    const agentsSigners: Signer[] = [];
    let agentsFound: any = [];

    if (!operationAgents) {
      return agentsSigners;
    }
    if (agents) {
      Object.keys(agents).forEach((key: any) => {
        agentsFound = agents[key];
      });
    }

    if (agentsFound) {
      operationAgents.forEach((e: any) => {
        const agentFound = agentsFound.find(
          (el: any) => el.email === e.email && el.bindRequirements && el.bindRequirements.canBind
        );

        if (agentFound) {
          agentsSigners.push({
            id: e.userId,
            name: e.firstName + ' ' + e.lastName,
            title: e.title
          });
        }
      });
    }

    return agentsSigners;
  }
);

export const getOperationAgentSigners = (insurancePlan: InsurancePlan) =>
  createSelector(
    getOperationAgents,
    getAllInsuranceAgents,
    getOperationPageOperationData,
    (operationAgents, allAgents, operation) => {
      if (!operation.agencyName || !operationAgents) return [];

      const agencyAgents = allAgents[operation.agencyName];
      if (!agencyAgents) return [];

      const canOperationBind = insurancePlan && operationCanBind(operation, insurancePlan);
      const agents = agencyAgents.filter(agent => {
        const found = operationAgents.some(operationAgent => operationAgent.email === agent.email);
        if (canOperationBind) {
          return found && agentCanBind(agent, insurancePlan);
        } else return found;
      });
      return agents
        .filter(
          agent =>
            !!agent.bindRequirements?.find(requirement => requirement.canBind || !canOperationBind)
        )
        .map(x => {
          return {
            id: x.email,
            name: x.firstName + ' ' + x.lastName,
            title: x.title
          } as Signer;
        });
    }
  );

export const getOperationAgentsSignerCannotBind = createSelector(
  getOperationAgents,
  getAllInsuranceAgents,
  getAdminPageAgency,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- refactor later
  (operationAgents, agents, _agency) => {
    const agentsSigners: Signer[] = [];
    let agentsFound: any = [];

    if (!operationAgents) {
      return agentsSigners;
    }
    if (agents) {
      Object.keys(agents).forEach((key: any) => {
        agentsFound = agents[key];
      });
    }

    if (agentsFound) {
      operationAgents.forEach((e: any) => {
        const agentFound = agentsFound.find((el: any) => el.email === e.email);

        if (agentFound) {
          agentsSigners.push({
            id: e.userId,
            name: e.firstName + ' ' + e.lastName,
            title: e.title
          });
        }
      });
    }

    return agentsSigners;
  }
);

export const getAgentOperationContact = createSelector(
  getOperations,
  getAllOperationContactOperations,
  getUserIdPropsFromProps,
  (operations, operationContacts, agent) =>
    operations.map(operation => {
      const isContact = !!operationContacts[operation.name]?.agents[agent];
      if (isContact) {
        return {
          name: operation.name,
          primary: operationContacts[operation.name].agents[agent].primaryContact,
          notifications:
            operationContacts[operation.name].agents[agent].sendEndorsementNotification,
          isContact: true
        };
      }
      return { name: operation.name, primary: false, notifications: false, isContact: false };
    })
);

export const getFilteredAgentOperationContact = createSelector(
  getOperations,
  getAgentOperationContact,
  getSearchFromProps,
  (operations, agentOperations, search) => {
    if (!operations && agentOperations) {
      return [];
    }
    const mappedAgentOperations = agentOperations.map(agentOperation => {
      const op = operations.find(operation => operation.name === agentOperation.name);
      if (op) {
        return { ...agentOperation, operationFullName: op.fullName };
      }
      return { ...agentOperation, operationFullName: '' };
    });

    return mappedAgentOperations.filter(
      ({ name, operationFullName }) =>
        containsString(name, search) || containsString(operationFullName, search)
    );
  }
);

export const getAgentOperationList = createSelector(
  getFilteredAgentOperationContact,
  getActivePageFromProps,
  getPerPageFromProps,
  (agents, activePage, perPage) => agents.slice((activePage - 1) * perPage, perPage * activePage)
);

export const getFilteredOperationModalAgents = createSelector(
  getInsuranceAgents,
  getSearchFromProps,
  (agents, search) => {
    if (!agents?.length) {
      return [];
    }
    return agents.filter(
      ({ firstName, lastName }) =>
        containsString(firstName, search) || containsString(lastName, search)
    );
  }
);

export const getOperationModalAgents = createSelector(
  getFilteredOperationModalAgents,
  getAdminPageAgency,
  getAllOperationContactOperations,
  getAgentModalSelectorProps,
  (agents, _agency, operationContactInformation, sortProps) => {
    const { selectedOperation, activePage, perPage } = sortProps;

    const lowerCasedOperation = selectedOperation.toLowerCase();

    let operationContacts: OperationContact[] = [];
    if (operationContactInformation[lowerCasedOperation]) {
      operationContacts = Object.values(operationContactInformation[lowerCasedOperation].agents);
    }
    const mappedAgents: OperationAgentContactData[] = [];

    if (agents && operationContacts) {
      agents.forEach(agent => {
        const existingAgent = operationContacts.find(
          operationAgent => operationAgent.userId === agent.userId
        );
        if (existingAgent) {
          mappedAgents.push({ ...existingAgent, isContact: true });
        } else {
          mappedAgents.push({
            userId: agent.userId,
            firstName: agent.firstName,
            lastName: agent.lastName,
            operationName: lowerCasedOperation,
            operationFullName: '',
            title: agent.title,
            phone: agent.phoneNumber,
            email: agent.email,
            sendEndorsementNotification: false,
            primaryContact: false,
            isContact: false
          });
        }
      });
    }

    return mappedAgents.slice((activePage - 1) * perPage, perPage * activePage);
  }
);
