import { PriceType } from '../containers/LrpMarketDynamics/PriceTrends/renderHistoricalPricesTable';

export interface LrpAnalysisFilterSettings {
  salesEffectiveDate?: Date;
  filterComponent: LrpAnalysisFilterComponent;
  yearRange: LrpAnalysisYearRange;
}

export interface LrpAnalysisCurrentPrices {
  months: [
    {
      month: number;
      endDate: Date;
      numberOfHead: number;
      targetWeight: number;
      actualPrice: number;
      actualPriceHead: number;
      actualPriceCwt: number;
      expectedPrice: number;
      expectedPriceHead: number;
      expectedPriceCwt: number;
      producerPremium: number;
      producerPremiumHead: number;
      producerPremiumCwt: number;
      netGuarantee: number;
      netGuaranteeHead: number;
      netGuaranteeCwt: number;
      coveragePrice: number;
      coveragePriceHead: number;
      coveragePriceCwt: number;
      grossIndemnity: number;
      grossIndemnityCwt: number;
      grossIndemnityHead: number;
    }
  ];
}

export interface LrpAnalysisHistoricalPrices {
  years: [
    {
      year: number;
      months: [
        {
          month: number;
          endDate: Date;
          numberOfHead: number;
          targetWeight: number;
          actualPrice: number;
          actualPriceHead: number;
          actualPriceCwt: number;
          expectedPrice: number;
          expectedPriceHead: number;
          expectedPriceCwt: number;
          producerPremium: number;
          producerPremiumHead: number;
          producerPremiumCwt: number;
          netGuarantee: number;
          netGuaranteeHead: number;
          netGuaranteeCwt: number;
          coveragePrice: number;
          coveragePriceHead: number;
          coveragePriceCwt: number;
          grossIndemnity: number;
          grossIndemnityCwt: number;
          grossIndemnityHead: number;
        }
      ];
    }
  ];
  yearFrom: number;
  yearTo: number;
  average: [
    {
      month: number;
      endDate: Date;
      numberOfHead: number;
      targetWeight: number;
      actualPrice: number;
      actualPriceHead: number;
      actualPriceCwt: number;
      expectedPrice: number;
      expectedPriceHead: number;
      expectedPriceCwt: number;
      producerPremium: number;
      producerPremiumHead: number;
      producerPremiumCwt: number;
      netGuarantee: number;
      netGuaranteeHead: number;
      netGuaranteeCwt: number;
      coveragePrice: number;
      coveragePriceHead: number;
      coveragePriceCwt: number;
      yearFrom: number;
      yearTo: number;
      itemCount: number;
      grossIndemnity: number;
      grossIndemnityCwt: number;
      grossIndemnityHead: number;
    }
  ];
}
export interface LrpAnalysisCurrentPricesMonths {
  month: number;
  endDate: Date | undefined;
  expectedPrice: number | undefined;
  coveragePrice: number | undefined;
  producerPremium: number | undefined;
  netGuarantee: number | undefined;
}
export interface LrpAnalysisCurrentPriceAverage {
  month: number;
  value: number | undefined;
}
export interface LrpAnalysisCurrentPriceDifference {
  month: number;
  value: number | undefined;
}

export interface LrpAnalysisHistoricalPricesData {
  year: number;
  [key: number]: { price: number; type: PriceType };
}

export interface LrpAnalysisPriceTrendsChartData {
  name: string;
  endDate: string;
  endMonth: string;
  measure: string;
  yearInterval: string;
  currentPrice: number | undefined;
  historicalPrice: number | undefined;
  undefinedPrice?: number | undefined;

  prices?: { [key: string]: number }[];
}

export interface LrpPriceEvolutionAnalysisSettings {
  endMonth?: { id: string; name: string };
  year: string;
  measure: { id: string; name: string };
  filterComponent: LrpAnalysisFilterComponent;
  salesEffectiveDate: any;
  yearRange: LrpAnalysisYearRange;
}

export interface LrpAnalysisFilterComponent {
  commodity: { id: string; name: string };
  commodityType: { id: string; name: string };
  deductible: { id: string; name: string };
  numberOfHead: number;
  targetWeight: number;
}

export interface LrpAnalysisYearRange {
  yearFrom?: number;
  yearTo?: number;
}

export class DataPoint {
  date: Date;
  total: number;
  cwt: number;
  head: number;
  constructor() {
    this.date = new Date();
    this.total = 0;
    this.cwt = 0;
    this.head = 0;
  }
}
export class DataPointQuantile {
  dataPoint: DataPoint;
  quantile: number;
  constructor() {
    this.dataPoint = new DataPoint();
    this.quantile = 0;
  }
}

export class DataPointMonth {
  date: Date;
  total: number;
  cwt: number;
  head: number;
  month: number;
  endorsementLength: number;
  constructor() {
    this.date = new Date();
    this.total = 0;
    this.cwt = 0;
    this.head = 0;
    this.month = 0;
    this.endorsementLength = 0;
  }
}

export class yearActual {
  date: Date;
  total: number;
  cwt: number;
  head: number;
  year: number;
  constructor() {
    this.date = new Date();
    this.total = 0;
    this.cwt = 0;
    this.head = 0;
    this.year = 0;
  }
}
export interface LrpPriceEvolutionAnalysis {
  actual: DataPoint;
  dataPoints: DataPoint[];
  latest: DataPointQuantile;
  previous: DataPointQuantile;
  days7: {
    average: DataPointQuantile;
    low: DataPointQuantile;
    high: DataPointQuantile;
    earliestDate: Date;
    latestDate: Date;
  };
  days30: {
    average: DataPointQuantile;
    low: DataPointQuantile;
    high: DataPointQuantile;
    earliestDate: Date;
    latestDate: Date;
  };
  overall: {
    average: DataPointQuantile;
    low: DataPointQuantile;
    high: DataPointQuantile;
    earliestDate: Date;
    latestDate: Date;
  };
  historicOverview: {
    yearMonthDataPoints: {
      year: number;
      endDate: Date;
      monthDataPoints: DataPointMonth[];
    }[];
    yearActuals: yearActual[];
    monthAveages: DataPointMonth[];
  };
}

export interface LrpPriceEvolutionAnalysisChartData {
  name: string;
  date: Date;
  value: number | undefined;
  month?: number;

  [key: string]: any;
}

export interface LrpPriceEvolutionAnalysisHistoricalHeader {
  endorsementLength: number;
  month: string;
}

export interface LrpPriceEvolutionAnalysisHistoricalData {
  price: number;
  actualPrice: number;
  endorsementLength: number;
  year: number;
  month: string;
  type: PriceType;
}

export enum Unit {
  CWT,
  HEAD,
  TOTAL
}
