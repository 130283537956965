import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { TagType } from '../../containers/AdminPanelContainers/TagTypes/types/TagType';
import insuranceService from '../../services/insuranceService';
import { RootState } from '../store';
import { tagTypeActions } from './actions';

const selectTagTypes = (state: RootState) => state.tagTypes;

export function* getTagTypes(action: ActionType<typeof tagTypeActions.tagTypes.fetch>) {
  const { agencyName } = action.payload;
  try {
    yield put(tagTypeActions.tagTypeLoader.activate());
    const tagTypes: AxiosResponse<TagType[]> = yield call(insuranceService.getTagTypes, agencyName);

    if (tagTypes.data.length) {
      yield put(tagTypeActions.tagTypes.set(tagTypes.data));
    } else {
      yield put(tagTypeActions.tagTypes.set([]));
    }
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagTypeActions.tagTypeLoader.disable());
  }
}

export function* createTagType(action: ActionType<typeof tagTypeActions.tagTypes.push>) {
  const { ...requestBody } = action.payload;
  try {
    yield put(tagTypeActions.tagTypeLoader.activate());
    const tagTypes: ReturnType<typeof selectTagTypes> = yield select(selectTagTypes);
    const typeExists = tagTypes?.some(existingType => existingType.title === action.payload.title);
    if (typeExists) {
      toast.warn('Type already exists', { position: 'top-right' });
      return;
    }
    yield call(insuranceService.createTagType, requestBody.agencyName, requestBody);
    yield toast.success('Type created successfully!', {
      position: 'top-right'
    });
    const { agencyName } = action.payload;
    yield call(getTagTypes, tagTypeActions.tagTypes.fetch({ agencyName, manageLoader: false }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagTypeActions.tagTypeLoader.disable());
  }
}

export function* editTagType(action: ActionType<typeof tagTypeActions.tagTypes.edit>) {
  const { name, ...requestBody } = action.payload;
  try {
    yield put(tagTypeActions.tagTypeLoader.activate());
    const tagTypes: ReturnType<typeof selectTagTypes> = yield select(selectTagTypes);
    const tagTypeExists = tagTypes?.some(existingType => existingType.title === requestBody.title);

    if (action.payload.title?.length === 0) {
      toast.warn('Type cannot be empty', { position: 'top-right' });
      return;
    }

    if (tagTypeExists) {
      toast.warn('Type already exists', { position: 'top-right' });
      return;
    }
    yield call(insuranceService.editTagType, requestBody.agencyName, requestBody, name);
    yield toast.success('Type updated successfully!', {
      position: 'top-right'
    });
    const { agencyName } = action.payload;
    yield call(getTagTypes, tagTypeActions.tagTypes.fetch({ agencyName, manageLoader: false }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagTypeActions.tagTypeLoader.disable());
  }
}

export function* deleteTagType(action: ActionType<typeof tagTypeActions.tagTypes.delete>) {
  const { agencyName, tagType } = action.payload;
  try {
    yield put(tagTypeActions.tagTypeLoader.activate());
    yield call(
      { context: insuranceService, fn: insuranceService.deleteTagType },
      agencyName,
      tagType
    );
    yield toast.success('Type deleted successfully!', {
      position: 'top-right'
    });
    yield call(getTagTypes, tagTypeActions.tagTypes.fetch({ agencyName, manageLoader: false }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagTypeActions.tagTypeLoader.disable());
  }
}

export function* tagTypeSagaWatcher() {
  yield all([
    takeLatest(getType(tagTypeActions.tagTypes.fetch), getTagTypes),
    takeLatest(getType(tagTypeActions.tagTypes.push), createTagType),
    takeLatest(getType(tagTypeActions.tagTypes.edit), editTagType),
    takeLatest(getType(tagTypeActions.tagTypes.delete), deleteTagType)
  ]);
}
