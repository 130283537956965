import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import SortDirection from '../../../enums/SortDirection';
import insuranceAPI from '../../../services/insuranceService';
import { HistoricalData } from '../../../types/HistoricalData';
import { HistoricalDataEssentials } from '../../../types/HistoricalDataEssentials';
import { HistoricalDataDto } from '../../../types/dto/HistoricalDataDto';
import { mapAllHistoricalDataDtoToHistoricalData } from '../../historicalData/mapHistoricalDataDtoToHistoricalData';
import { loadersActions } from '../../loaders/actions';
import { getOperationName } from '../../operationPage/selectors';
import { scenarioAnalysisActions } from '../actions';
import { getCurrentWhatIfScenario, getScenarioAnalysisData } from '../selectors';

const sortHistoricalDataByDate = (data: HistoricalData[], direction: SortDirection) => {
  if (direction === SortDirection.ASCENDING) {
    return [...data].sort((a, b) => {
      const date1 = new Date(a.year, a.month - 1);
      const date2 = new Date(b.year, b.month - 1);
      return date1.getTime() - date2.getTime();
    });
  } else {
    return [...data].sort((a, b) => {
      const date1 = new Date(a.year, a.month - 1);
      const date2 = new Date(b.year, b.month - 1);
      return date2.getTime() - date1.getTime();
    });
  }
};

function* getAllHistoricalRecords(
  _action: ActionType<typeof scenarioAnalysisActions.dataActions.getAll>
): any {
  const operationName = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  yield put(loadersActions.scenarioAnalysis.activate());

  try {
    const historicalDataDto: AxiosResponse<HistoricalDataDto[]> = yield call(
      insuranceAPI.getScenarioWhatIfData,
      operationName,
      scenarioName
    );
    if (historicalDataDto.data.length) {
      const historicalData = mapAllHistoricalDataDtoToHistoricalData(historicalDataDto);
      const sortedData = sortHistoricalDataByDate(historicalData, SortDirection.DESCENDING);
      yield put(scenarioAnalysisActions.dataActions.addAll(sortedData));
    } else {
      yield put(scenarioAnalysisActions.dataActions.addAll([]));
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* deleteHistoricalRecord(
  action: ActionType<typeof scenarioAnalysisActions.dataActions.delete>
): any {
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  yield put(loadersActions.scenarioAnalysis.activate());

  try {
    yield call(
      insuranceAPI.deleteScenarioWhatIfDataRecord,
      operation,
      scenarioName,
      action.payload.year,
      action.payload.month
    );
    action.payload.callback();
    yield put(scenarioAnalysisActions.dataActions.remove(action.payload));
  } catch (e) {
    console.log(e);
  } finally {
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* editRecord(
  action: ActionType<typeof scenarioAnalysisActions.dataActions.editRecord>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);

  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isNew, isEditing, ...essentials } = action.payload;
    yield call(insuranceAPI.editScenarioWhatIfDataRecord, operation, scenarioName, essentials);
    yield action.meta();
    yield put(scenarioAnalysisActions.dataActions.updateRecord(action.payload));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* sortHistoricalData(
  action: ActionType<typeof scenarioAnalysisActions.dataActions.sortHistoricalData>
): any {
  try {
    const data = yield select(getScenarioAnalysisData);
    yield put(
      scenarioAnalysisActions.dataActions.addAll(sortHistoricalDataByDate(data, action.payload))
    );
  } catch (e) {
    console.log(e);
  }
}

function* saveAllHistoricalRecords(
  action: ActionType<typeof scenarioAnalysisActions.dataActions.saveAll>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operation = yield select(getOperationName);
  const scenarioName = yield select(getCurrentWhatIfScenario);
  const records: HistoricalDataEssentials[] = action.payload.map(r => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isNew, isEditing, ...record } = r;
    return record;
  });
  try {
    yield call(insuranceAPI.saveAllScenarioWhatIfDataRecords, operation, scenarioName, records);
  } catch (e) {
    console.log(e);
  } finally {
    const mappedRecords = action.payload.map(r => ({ ...r, isNew: false, isEditing: false }));
    yield action.meta([...mappedRecords]);
    yield put(scenarioAnalysisActions.dataActions.addAll(mappedRecords));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* scenarioAnalysisDataSagaWatcher() {
  yield all([
    takeLatest(getType(scenarioAnalysisActions.dataActions.getAll), getAllHistoricalRecords),
    takeLatest(getType(scenarioAnalysisActions.dataActions.saveAll), saveAllHistoricalRecords),
    takeLatest(getType(scenarioAnalysisActions.dataActions.editRecord), editRecord),
    takeLatest(getType(scenarioAnalysisActions.dataActions.delete), deleteHistoricalRecord),
    takeLatest(getType(scenarioAnalysisActions.dataActions.sortHistoricalData), sortHistoricalData)
  ]);
}

export default scenarioAnalysisDataSagaWatcher;
