import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  DataPoint,
  DataPointMonth,
  DataPointQuantile,
  LrpAnalysisCurrentPrices,
  LrpAnalysisHistoricalPrices,
  LrpPriceEvolutionAnalysis,
  yearActual
} from '../../scenes/lrp/types/LrpAnalysis';
import {
  LrpEndorsementDto,
  LrpEndorsementFilterResponseDto
} from '../../scenes/lrp/types/LrpEndorsement';
import {
  Commodity,
  LrpCoverageTableAllEndorsementLengthsDto,
  LrpCoverageTableDto,
  LrpCoverageTableHistoricaReducerlAll,
  LrpFiltersDto
} from '../../scenes/lrp/types/LrpFiltersDto';
import { PricePreferenceProcessedDto } from '../../scenes/lrp/types/PricePreferenceDto';
import { QuoterPreferenceReducerDto } from '../../scenes/lrp/types/QuoterPreference';
import Producer from '../../types/Producer/Producer';
import { LRPActions, lrpActions } from './actions';

interface State {
  filters: LrpFiltersDto;
  filtersCommodities: { name: string; id: string }[];
  selectedCommodityData: Commodity;
  coveragePercentage: { name: string; id: string }[];
  isFiltersComplete: boolean;
  coverageTable: LrpCoverageTableDto[];
  lrpEndorsementCalculation: LrpCoverageTableDto;
  lrpEndorsements: LrpEndorsementDto[];
  lrpEndorsementSummaryTable: LrpEndorsementDto[];
  LrpEndorsementFilter: LrpEndorsementFilterResponseDto;
  coverageTableAllEndorsementLengths: LrpCoverageTableAllEndorsementLengthsDto[];
  coverageTableHistorical: LrpCoverageTableHistoricaReducerlAll;
  isCoverageTableAvailable: boolean;
  isCoverageTableAllLengthsAvailable: boolean;
  isCoverageTableHistoricalAvailable: boolean;
  quoterPreferences: QuoterPreferenceReducerDto;
  pricePreferences: PricePreferenceProcessedDto[];
  spinner: boolean;
  lrpAnalysisCurrentPrices: LrpAnalysisCurrentPrices;
  lrpAnalysisHistoricalPrices: LrpAnalysisHistoricalPrices;
  operationProducer: Producer[];
  lrpPriceEvolutionAnalysis: LrpPriceEvolutionAnalysis;
}

export const initialState: State = {
  filters: {
    salesEffectiveDate: undefined,
    commodities: [],
    reinsuranceYear: 0,
    canSell: false,
    canSellReason: '',
    timeZoneId: ''
  },
  filtersCommodities: [{ name: 'No data available', id: 'no' }],
  selectedCommodityData: { commodity: '', name: '', types: [] },
  coveragePercentage: [{ name: 'No data available', id: 'no' }],
  isFiltersComplete: false,
  coverageTable: [
    {
      coveragePercent: 0,
      coveragePriceCwt: 0,
      coveragePriceHead: 0,
      liability: 0,
      coveragePrice: 0,
      producerPremium: 0,
      producerPremiumCwt: 0,
      producerPremiumHead: 0,
      netGuarantee: 0,
      netGuaranteeHead: 0,
      netGuaranteeCwt: 0,
      cmePutComparisonStrikePrice: 0,
      cmePutPremiumPrice: 0,
      lrpPremium: 0,
      totalPremium: 0,
      totalPremiumCwt: 0,
      totalPremiumHead: 0,
      expectedPrice: 0,
      expectedPriceCwt: 0,
      expectedPriceHead: 0,
      subsidyAmount: 0,
      subsidyAmountCwt: 0,
      subsidyAmountHead: 0,
      comparisonMonth: new Date(),
      endDate: new Date(),
      coverageMinusFuturesPriceCwt: 0,
      grossIndemnity: 0,
      grossIndemnityCwt: 0,
      grossIndemnityHead: 0,
      actualPrice: 0,
      actualPriceHead: 0,
      forecastedIndemnityCwt: 0,
      forecastedIndemnityHead: 0,
      forecastedIndemnity: 0,
      actualIndemnityCwt: 0,
      actualIndemnityHead: 0,
      actualIndemnity: 0,
      changeInActualPriceCwt: 0,
      changeInActualPriceHead: 0,
      changeInActualPrice: 0,
      practiceCode: '',
      salesEffectiveDate: new Date(),
      actualPriceCwt: 0,
      estimatedActualPriceCwt: 0,
      estimatedActualPriceHead: 0,
      estimatedActualPrice: 0,
      targetWeight: 0,
      numberOfHead: 0,
      subsidyPercent: 0,
      endorsementLength: 0
    }
  ],
  lrpEndorsementCalculation: {
    coveragePercent: 0,
    coveragePriceCwt: 0,
    coveragePriceHead: 0,
    liability: 0,
    coveragePrice: 0,
    producerPremium: 0,
    producerPremiumCwt: 0,
    producerPremiumHead: 0,
    netGuarantee: 0,
    netGuaranteeHead: 0,
    netGuaranteeCwt: 0,
    cmePutComparisonStrikePrice: 0,
    cmePutPremiumPrice: 0,
    lrpPremium: 0,
    totalPremium: 0,
    totalPremiumCwt: 0,
    totalPremiumHead: 0,
    expectedPrice: 0,
    expectedPriceCwt: 0,
    expectedPriceHead: 0,
    subsidyAmount: 0,
    subsidyAmountCwt: 0,
    subsidyAmountHead: 0,
    comparisonMonth: new Date(),
    endDate: new Date(),
    coverageMinusFuturesPriceCwt: 0,
    grossIndemnity: 0,
    grossIndemnityCwt: 0,
    grossIndemnityHead: 0,
    actualPrice: 0,
    actualPriceHead: 0,
    forecastedIndemnityCwt: 0,
    forecastedIndemnityHead: 0,
    forecastedIndemnity: 0,
    actualIndemnityCwt: 0,
    actualIndemnityHead: 0,
    actualIndemnity: 0,
    changeInActualPriceCwt: 0,
    changeInActualPriceHead: 0,
    changeInActualPrice: 0,
    practiceCode: '',
    salesEffectiveDate: new Date(),
    actualPriceCwt: 0,
    estimatedActualPriceCwt: 0,
    estimatedActualPriceHead: 0,
    estimatedActualPrice: 0,
    targetWeight: 0,
    numberOfHead: 0,
    subsidyPercent: 0,
    endorsementLength: 0
  },
  lrpEndorsements: [],
  lrpEndorsementSummaryTable: [],
  LrpEndorsementFilter: {
    startDate: undefined,
    endDate: undefined
  },
  coverageTableAllEndorsementLengths: [
    {
      endorsementLengthCount: 0,
      endorsementLength: 0,
      endDate: new Date(),
      expectedPrice: 0,
      expectedPriceCwt: 0,
      expectedPriceHead: 0,
      coverageMinusFuturesPrice: 0,
      comparisonMonth: new Date(),
      futuresPrice: 0,
      coveragePercent: 0,
      coveragePrice: 0,
      coveragePriceCwt: 0,
      coveragePriceHead: 0,
      liability: 0,
      producerPremium: 0,
      producerPremiumCwt: 0,
      producerPremiumHead: 0,
      netGuarantee: 0,
      netGuaranteeHead: 0,
      netGuaranteeCwt: 0,
      cmePutComparisonStrikePrice: 0,
      cmePutPremiumPrice: 0,
      lrpPremium: 0,
      totalPremium: 0,
      totalPremiumCwt: 0,
      totalPremiumHead: 0,
      subsidyAmount: 0,
      subsidyAmountCwt: 0,
      subsidyAmountHead: 0
    }
  ],
  coverageTableHistorical: {
    historicData: [
      {
        endDate: new Date(),
        expectedPriceTotal: 0,
        expectedPriceCwt: 0,
        expectedPriceHead: 0,
        coveragePercent: 0,
        coveragePriceCwt: 0,
        coveragePriceHead: 0,
        insuredValueTotal: 0,
        producerPremiumTotal: 0,
        producerPremiumCwt: 0,
        producerPremiumHead: 0,
        netGuaranteeHead: 0,
        netGuaranteeCwt: 0,
        netGuaranteeTotal: 0,
        salesEffectiveDate: new Date(),
        actualEndingValueCwt: 0,
        actualEndingValueHead: 0,
        actualEndingValueTotal: 0,
        grossIndemnityCwt: 0,
        grossIndemnityHead: 0,
        grossIndemnityTotal: 0
      }
    ],
    historicDataAvg: {
      expectedPriceTotal: 0,
      expectedPriceCwt: 0,
      expectedPriceHead: 0,
      coveragePercent: 0,
      coveragePriceCwt: 0,
      coveragePriceHead: 0,
      insuredValueTotal: 0,
      producerPremiumTotal: 0,
      producerPremiumCwt: 0,
      producerPremiumHead: 0,
      netGuaranteeHead: 0,
      netGuaranteeCwt: 0,
      netGuaranteeTotal: 0,
      salesEffectiveDate: new Date(),
      actualEndingValueCwt: 0,
      actualEndingValueHead: 0,
      actualEndingValueTotal: 0,
      grossIndemnityCwt: 0,
      grossIndemnityHead: 0,
      grossIndemnityTotal: 0
    },
    historicDataAvgYear5: {
      expectedPriceTotal: 0,
      expectedPriceCwt: 0,
      expectedPriceHead: 0,
      coveragePercent: 0,
      coveragePriceCwt: 0,
      coveragePriceHead: 0,
      insuredValueTotal: 0,
      producerPremiumTotal: 0,
      producerPremiumCwt: 0,
      producerPremiumHead: 0,
      netGuaranteeHead: 0,
      netGuaranteeCwt: 0,
      netGuaranteeTotal: 0,
      salesEffectiveDate: new Date(),
      actualEndingValueCwt: 0,
      actualEndingValueHead: 0,
      actualEndingValueTotal: 0,
      grossIndemnityCwt: 0,
      grossIndemnityHead: 0,
      grossIndemnityTotal: 0
    }
  },
  isCoverageTableAvailable: false,
  isCoverageTableAllLengthsAvailable: false,
  isCoverageTableHistoricalAvailable: false,
  pricePreferences: [],
  quoterPreferences: {
    commodity: 'FeederCattle',
    coveragePercentageRank: 1,
    commodityType: '810',
    numberOfHead: 100,
    targetWeight: 7,
    insuredSharedPercent: 1,
    conservationCompliance: 1,
    isBeginner: false,
    monthSelection: 'FirstAfter',
    basisSelection: 'LrpCoveragePrice',
    strikeSelection: 'CoveragePercentage',
    showAllEndorsementLengths: false,
    showCMEPutComparison: false,
    showSubsidyOverview: false
  },
  spinner: false,
  lrpAnalysisCurrentPrices: {
    months: [
      {
        month: 0,
        endDate: new Date(),
        numberOfHead: 0,
        targetWeight: 0,
        actualPrice: 0,
        actualPriceHead: 0,
        actualPriceCwt: 0,
        expectedPrice: 0,
        expectedPriceHead: 0,
        expectedPriceCwt: 0,
        producerPremium: 0,
        producerPremiumHead: 0,
        producerPremiumCwt: 0,
        netGuarantee: 0,
        netGuaranteeHead: 0,
        netGuaranteeCwt: 0,
        coveragePrice: 0,
        coveragePriceHead: 0,
        coveragePriceCwt: 0,
        grossIndemnity: 0,
        grossIndemnityCwt: 0,
        grossIndemnityHead: 0
      }
    ]
  },
  lrpAnalysisHistoricalPrices: {
    years: [
      {
        year: 0,
        months: [
          {
            month: 0,
            endDate: new Date(),
            numberOfHead: 0,
            targetWeight: 0,
            actualPrice: 0,
            actualPriceHead: 0,
            actualPriceCwt: 0,
            expectedPrice: 0,
            expectedPriceHead: 0,
            expectedPriceCwt: 0,
            producerPremium: 0,
            producerPremiumHead: 0,
            producerPremiumCwt: 0,
            netGuarantee: 0,
            netGuaranteeHead: 0,
            netGuaranteeCwt: 0,
            coveragePrice: 0,
            coveragePriceHead: 0,
            coveragePriceCwt: 0,
            grossIndemnity: 0,
            grossIndemnityCwt: 0,
            grossIndemnityHead: 0
          }
        ]
      }
    ],
    yearFrom: 0,
    yearTo: 0,
    average: [
      {
        month: 0,
        endDate: new Date(),
        numberOfHead: 0,
        targetWeight: 0,
        actualPrice: 0,
        actualPriceHead: 0,
        actualPriceCwt: 0,
        expectedPrice: 0,
        expectedPriceHead: 0,
        expectedPriceCwt: 0,
        producerPremium: 0,
        producerPremiumHead: 0,
        producerPremiumCwt: 0,
        netGuarantee: 0,
        netGuaranteeHead: 0,
        netGuaranteeCwt: 0,
        coveragePrice: 0,
        coveragePriceHead: 0,
        coveragePriceCwt: 0,
        yearFrom: 0,
        yearTo: 0,
        itemCount: 0,
        grossIndemnity: 0,
        grossIndemnityCwt: 0,
        grossIndemnityHead: 0
      }
    ]
  },
  operationProducer: [
    {
      firstName: '',
      lastName: '',
      hometown: '',
      phoneNumber: '',
      email: '',
      title: '',
      timeZone: 'America/Chicago',
      userId: '',
      lockoutEnd: '',
      lockoutEnabled: false,
      twoFactorEnabled: false,
      phoneNumberConfirmed: false,
      emailConfirmed: false,
      userName: '',
      lastInviteOn: '',
      isInvitationExpired: false,
      principalContactAgent: ''
    }
  ],

  lrpPriceEvolutionAnalysis: {
    actual: new DataPoint(),
    dataPoints: [new DataPoint()],
    latest: new DataPointQuantile(),
    previous: new DataPointQuantile(),
    days7: {
      average: new DataPointQuantile(),
      low: new DataPointQuantile(),
      high: new DataPointQuantile(),
      earliestDate: new Date(),
      latestDate: new Date()
    },
    days30: {
      average: new DataPointQuantile(),
      low: new DataPointQuantile(),
      high: new DataPointQuantile(),
      earliestDate: new Date(),
      latestDate: new Date()
    },
    overall: {
      average: new DataPointQuantile(),
      low: new DataPointQuantile(),
      high: new DataPointQuantile(),
      earliestDate: new Date(),
      latestDate: new Date()
    },
    historicOverview: {
      yearMonthDataPoints: [
        {
          year: 0,
          endDate: new Date(),
          monthDataPoints: [new DataPointMonth()]
        }
      ],
      yearActuals: [new yearActual()],
      monthAveages: [new DataPointMonth()]
    }
  }
};

const lrpReducer = (state = initialState, action: LRPActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(lrpActions.saveFilters): {
        draft.filters = action.payload;
        return;
      }
      case getType(lrpActions.saveFiltersCommodities): {
        draft.filtersCommodities = action.payload;
        return;
      }
      case getType(lrpActions.saveSelectedCommodityData): {
        draft.selectedCommodityData = action.payload;
        return;
      }
      case getType(lrpActions.resetFilters): {
        draft.filters = {
          salesEffectiveDate: undefined,
          commodities: [],
          reinsuranceYear: 0,
          canSell: false,
          canSellReason: '',
          timeZoneId: ''
        };
        return;
      }
      case getType(lrpActions.saveCoveragePercentage): {
        draft.coveragePercentage = action.payload;
        return;
      }
      case getType(lrpActions.isFiltersComplete): {
        draft.isFiltersComplete = action.payload;
        return;
      }
      case getType(lrpActions.saveCoverageTable): {
        draft.coverageTable = action.payload;
        return;
      }
      case getType(lrpActions.setEndorsementCalculation): {
        draft.lrpEndorsementCalculation = action.payload;
        return;
      }
      case getType(lrpActions.setEndorsements): {
        draft.lrpEndorsements = action.payload;
        return;
      }
      case getType(lrpActions.setEndorsementSummary): {
        draft.lrpEndorsementSummaryTable = action.payload;
        return;
      }
      case getType(lrpActions.saveLrpEndorsementFilter): {
        draft.LrpEndorsementFilter = action.payload;
        return;
      }
      case getType(lrpActions.saveCoverageTableAllEndorsementLengths): {
        draft.coverageTableAllEndorsementLengths = action.payload;
        return;
      }
      case getType(lrpActions.saveCoverageTableHistorical): {
        draft.coverageTableHistorical = action.payload;
        return;
      }
      case getType(lrpActions.isCoverageTableAvailable): {
        draft.isCoverageTableAvailable = action.payload;
        return;
      }
      case getType(lrpActions.isCoverageTableAllLengthsAvailable): {
        draft.isCoverageTableAllLengthsAvailable = action.payload;
        return;
      }
      case getType(lrpActions.isCoverageTableHistoricalAvailable): {
        draft.isCoverageTableHistoricalAvailable = action.payload;
        return;
      }
      case getType(lrpActions.saveQuoterPreferences): {
        draft.quoterPreferences = action.payload;
        return;
      }
      case getType(lrpActions.savePricePreferences): {
        draft.pricePreferences = action.payload;
        return;
      }
      case getType(lrpActions.setPricePreferenceSpinner): {
        draft.spinner = action.payload;
        return;
      }
      case getType(lrpActions.saveLrpAnalysisCurrentPrices): {
        draft.lrpAnalysisCurrentPrices = action.payload;
        return;
      }
      case getType(lrpActions.saveLrpAnalysisHistoricalPrices): {
        draft.lrpAnalysisHistoricalPrices = action.payload;
        return;
      }
      case getType(lrpActions.saveOperationProducer): {
        draft.operationProducer = action.payload;
        return;
      }
      case getType(lrpActions.resetOperationProducer): {
        draft.operationProducer = [];
        return;
      }
      case getType(lrpActions.saveLrpPriceEvolutionAnalysis): {
        draft.lrpPriceEvolutionAnalysis = action.payload;
        return;
      }
    }
  });

export default lrpReducer;
