import { Language } from '../../../utils/language';

export function getMonthName(monthNumber: number) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString(Language.DEFAULT, { month: 'short' });
}
export function getFullMonthName(monthNumber: number) {
  const date = new Date();
  date.setMonth(monthNumber - 1);
  return date.toLocaleString(Language.DEFAULT, { month: 'long' });
}
