
      document.addEventListener('DOMContentLoaded', function () {
        if (import.meta.env.VITE_APP_CLIENT_NAME === 'lia-fcs') {
          document.title = 'Farm Credit Livestock Insurance Risk Analyzer';
          document.getElementById('favicon').setAttribute('href', '/favicon.ico');
        } else if (import.meta.env.VITE_APP_CLIENT_NAME === 'lia-leap') {
          document.title = 'Livestock Entity Ag Platform';
          document.getElementById('favicon').setAttribute('href', '/favicon_leap.ico');
        }
      });
    