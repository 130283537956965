import { AxiosResponse } from 'axios';

import { HistoricalData } from '../../types/HistoricalData';
import { HistoricalDataDto } from '../../types/dto/HistoricalDataDto';

const mapHistoricalDataDtoToHistoricalData = ({
  year,
  month,
  butterfatTest,
  proteinTest,
  milkProduction,
  milkPrice,
  productionCost,
  projected
}: HistoricalDataDto): HistoricalData => ({
  isEditing: false,
  isNew: false,
  year,
  month,
  butterfatTest,
  proteinTest,
  milkProduction,
  milkPrice,
  productionCost,
  projected
});

export default mapHistoricalDataDtoToHistoricalData;

const sortHistoricalDataByDate = <T extends { month: number; year: number }>(data: T[]) =>
  [...data].sort((a, b) => {
    if (a.year > b.year) return -1;
    if (a.year < b.year) return 1;

    if (a.month > b.month) return -1;
    if (a.month < b.month) return 1;

    return 0;
  });

export const mapAllHistoricalDataDtoToHistoricalData = ({
  data
}: AxiosResponse<HistoricalDataDto[]>): HistoricalData[] => {
  const historicalData = sortHistoricalDataByDate(data);
  return historicalData.map(record => {
    const {
      year,
      month,
      butterfatTest,
      proteinTest,
      milkProduction,
      milkPrice,
      productionCost,
      projected
    } = record;
    return {
      year,
      month,
      butterfatTest,
      proteinTest,
      milkProduction,
      milkPrice,
      productionCost,
      projected,
      isEditing: false,
      isNew: false
    };
  });
};
