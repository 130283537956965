import { createSelector } from 'reselect';

import { Signer } from '../../scenes/lrp/containers/LrpPremiumQuoter/RequestCoverageModal/RequestCoverageModal.types';
import { Agent } from '../../types/Agent/Agent';
import { operationCanBind } from '../../types/Operation/Operation';
import InsurancePlan from '../../types/dto/InsurancePlan';
import containsString from '../../utils/containsString';
import sortObjectByKey from '../../utils/sortObjectByKey';
import { getAdminPageAgency, getAdminPageSearch } from '../adminPage/selectors';
import {
  getActivePageFromProps,
  getAipEndorsementYearFromProps,
  getAipKeyFromProps,
  getAipSortKeyFromProps,
  getAlphabetSortFromProps,
  getEditOperationSortFromProps,
  getEmailFromProps,
  getInsurancePlanConverterFromProps,
  getOperationModalSelectorProps,
  getPerPageFromProps,
  getProducerModalSelectorProps,
  getSearchFromProps,
  getSelectedAgencyFromProps
} from '../fromPropsSelector';
import { getOperationProducers } from '../lrp/selectors';
import { getOperationPageOperationData } from '../operationPage/selectors';
import { RootState } from '../store';

export const getInsuranceAgencies = (state: RootState) => state.adminPanel.insuranceAgencies;

export const getAllInsuranceAgents = (state: RootState) => state.adminPanel.insuranceAgents;

export const getInsuranceAgents = (state: RootState) =>
  state.adminPanel.insuranceAgents[state.adminPage.agency?.name ?? ''];

export const getProducers = (state: RootState) =>
  state.adminPanel.producers[state.adminPage.agency?.name ?? ''];

export const getOperations = (state: RootState) => state.adminPanel.operations;

export const getProducerOperations = (state: RootState) => state.adminPanel.producerOperations;

export const getOperationsProducers = (state: RootState) => state.adminPanel.operationsProducers;

export const getPreferencesUser = (state: RootState) => state.adminPanel.userPreferences.user;

export const getPreferencesUserAuthState = (state: RootState) =>
  state.adminPanel.userPreferences.userAuthState;

export const getPreferencesUserPreferences = (state: RootState) =>
  state.adminPanel.userPreferences.preferences;

export const getAllAipEndorsements = (state: RootState) => state.adminPanel.aip.endorsements;

export const getAllAipOperations = (state: RootState) => state.adminPanel.aip.aipOperations;

export const getAipStatus = (state: RootState) => state.adminPanel.aip.aipStatus;

export const getAipMatchedOperation = createSelector(getAipStatus, aipOperation => {
  if (!aipOperation) {
    return [];
  }
  return aipOperation.filter(element => element.operation.operationName);
});

export const getTotalUnmatchedEndorsementsCount = createSelector(getAipStatus, aipStatus => {
  const operationsWithUnmatched: string[] = [];
  let operationsFiltered: string[] = [];
  let counter = 0;

  for (const element of aipStatus) {
    if (
      element.operation.operationName &&
      element.isPrimaryContact &&
      element.unmatchedEndorsementsCount > 0
    ) {
      operationsWithUnmatched.push(element.operation.operationName);
    }
  }

  if (operationsWithUnmatched.length) {
    operationsFiltered = operationsWithUnmatched.filter(e =>
      operationsFiltered.includes(e) ? false : (operationsFiltered.push(e), true)
    );

    if (operationsFiltered.length) {
      counter = operationsFiltered.length;
    }
  }

  return counter;
});

export const getFilteredAgencies = createSelector(
  getInsuranceAgencies,
  getAdminPageSearch,
  (agencies, search) => {
    if (!agencies) {
      return [];
    }
    return agencies.filter(({ name }) => containsString(name, search));
  }
);

export const getAdminPanelAgencies = createSelector(
  getFilteredAgencies,
  getActivePageFromProps,
  getPerPageFromProps,
  (agencies, activePage, perPage) =>
    agencies.slice((activePage - 1) * perPage, perPage * activePage)
);

export const getAgencyEnabledPrograms = createSelector(
  getInsuranceAgencies,
  getSelectedAgencyFromProps,
  getInsurancePlanConverterFromProps,
  (agencies, selectedAgency, insurancePlanConverter) => {
    const agency = agencies.find(a => a.name === selectedAgency);
    const agencyInsurancePlanList: { name: string; id: string }[] = [];
    if (!agency) {
      return [];
    } else {
      const enabledPrograms = agency.insurancePrograms;
      for (const enabledProgram of enabledPrograms) {
        for (const insurancePlan of insurancePlanConverter) {
          if (enabledProgram === insurancePlan.id) {
            agencyInsurancePlanList.push({
              id: insurancePlan.id,
              name: insurancePlan.name
            });
          }
        }
      }
    }
    return agencyInsurancePlanList;
  }
);

export const getFilteredAgents = createSelector(
  getInsuranceAgents,
  getAdminPageSearch,
  getAlphabetSortFromProps,
  (agents, search, alphabetSort) => {
    if (!agents) {
      return [];
    }

    let filtered: Agent[] = [];
    if (alphabetSort !== 'All') {
      filtered = agents.filter(agent =>
        agent.lastName.toLowerCase().startsWith(alphabetSort.toLowerCase())
      );
    } else {
      filtered = agents;
    }

    return filtered.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    );
  }
);

export const getAdminPanelAgents = createSelector(
  getFilteredAgents,
  getActivePageFromProps,
  getPerPageFromProps,
  (agents, activePage, perPage) => agents.slice((activePage - 1) * perPage, perPage * activePage)
);

export const getFilteredOperations = createSelector(
  getOperations,
  getAdminPageSearch,
  (operations, search) => {
    if (!operations) {
      return [];
    }

    return operations.filter(
      ({ name, fullName }) => containsString(name, search) || containsString(fullName, search)
    );
  }
);

export const getAdminPanelSortedOperations = createSelector(
  getFilteredOperations,
  getAlphabetSortFromProps,
  (operations, alphabetSort) => {
    let sortedOperations = [];
    if (alphabetSort !== 'All') {
      sortedOperations = operations.filter(operation =>
        operation.fullName.toLowerCase().startsWith(alphabetSort.toLowerCase())
      );
    } else {
      sortedOperations = operations;
    }

    return sortedOperations;
  }
);

export const getAdminPanelOperations = createSelector(
  getAdminPanelSortedOperations,
  getActivePageFromProps,
  getPerPageFromProps,
  (operations, activePage, perPage) =>
    operations.slice((activePage - 1) * perPage, perPage * activePage)
);

export const getFilteredProducers = createSelector(
  getProducers,
  getAdminPageSearch,
  getAlphabetSortFromProps,
  (producers, search, alphabetSort) => {
    if (!producers) {
      return [];
    }

    let sortedProducers = [];
    if (alphabetSort !== 'All') {
      sortedProducers = producers.filter(producer =>
        producer.lastName.toLowerCase().startsWith(alphabetSort.toLowerCase())
      );
    } else {
      sortedProducers = producers;
    }

    return sortedProducers.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    );
  }
);

export const getAdminPanelProducers = createSelector(
  getFilteredProducers,
  getActivePageFromProps,
  getPerPageFromProps,
  (producers, activePage, perPage) =>
    producers.slice((activePage - 1) * perPage, perPage * activePage)
);

export const getDataLength = createSelector(
  getInsuranceAgencies,
  getInsuranceAgents,
  getProducers,
  getOperations,
  getAdminPageSearch,
  (agencies, agents, producers, operations, search) => {
    return {
      agenciesLength: agencies
        ? agencies.filter(({ name }) => containsString(name, search)).length
        : 0,
      agentsLength: agents
        ? agents.filter(
            ({ firstName, lastName, email }) =>
              containsString(firstName, search) ||
              containsString(lastName, search) ||
              containsString(email, search)
          ).length
        : 0,
      producersLength: producers
        ? producers.filter(
            ({ firstName, lastName, email }) =>
              containsString(firstName, search) ||
              containsString(lastName, search) ||
              containsString(email, search)
          ).length
        : 0,
      operationsLength: operations
        ? operations.filter(
            ({ name, fullName }) => containsString(name, search) || containsString(fullName, search)
          ).length
        : 0
    };
  }
);

export const getOperationProducersModal = createSelector(
  getOperationsProducers,
  getAdminPageAgency,
  getOperationModalSelectorProps,
  (operationsProducers, agency, sortProps) => {
    const { selectedOperation, activePage, perPage, search } = sortProps;

    const noProducerListData = {
      length: 0,
      producerList: []
    };

    if (!operationsProducers) {
      return noProducerListData;
    }

    if (!operationsProducers[agency?.name ?? '']) {
      return noProducerListData;
    }

    const producers = operationsProducers[agency?.name ?? ''][selectedOperation];
    if (!producers) {
      return noProducerListData;
    }

    const sortedProducers = [...producers].filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    );

    return {
      length: sortedProducers.length,
      producerList: sortedProducers.slice((activePage - 1) * perPage, perPage * activePage)
    };
  }
);

export const getFilteredAgencyAgents = createSelector(
  getAllInsuranceAgents,
  getSearchFromProps,
  getSelectedAgencyFromProps,
  (insuranceAgents, search, selectedAgency) => {
    if (!insuranceAgents) {
      return [];
    }

    if (!insuranceAgents[selectedAgency]) {
      return [];
    }

    const agents = insuranceAgents[selectedAgency];

    if (!agents) {
      return [];
    }

    return agents.filter(
      ({ firstName, lastName, email }) =>
        containsString(firstName, search) ||
        containsString(lastName, search) ||
        containsString(email, search)
    );
  }
);

export const getFilteredProducerOperations = createSelector(
  getProducerOperations,
  getEmailFromProps,
  (
    operations,
    userEmail
  ): Array<{ name: string; fullName: string; insurancePrograms: string[] }> => {
    if (!userEmail || !operations[userEmail]) {
      return [];
    }

    return [...operations[userEmail]]
      .filter(({ isPublic, demo }) => !(isPublic && demo))
      .map(({ name, fullName, insurancePrograms }) => ({ name, fullName, insurancePrograms }));
  }
);

export const getSearchFilteredOperations = createSelector(
  getOperations,
  getSearchFromProps,
  (operations, search) => {
    if (!operations || !operations.length) {
      return [];
    }
    return operations.filter(({ name }) => containsString(name, search));
  }
);

export const getModalFilteredOperationsForProducer = createSelector(
  getSearchFilteredOperations,
  getProducerOperations,
  getProducerModalSelectorProps,
  (operations, producerOperations, { producerId, activePage, perPage }) => {
    if (!operations || !producerId.length) {
      return [];
    }
    if (!producerOperations[producerId]) {
      return operations.map(operation => ({ ...operation, isAdded: false }));
    }

    const producerOperationList = producerOperations[producerId].map(operation => operation.name);

    const mappedOperations = operations.map(operation => {
      const addedOperationIndex = producerOperationList.findIndex(id => id === operation.name);
      if (addedOperationIndex !== -1) {
        return { ...operation, isAdded: true };
      } else {
        return { ...operation, isAdded: false };
      }
    });

    return mappedOperations.slice((activePage - 1) * perPage, perPage * activePage);
  }
);

export const getEditAllOperations = createSelector(
  getOperations,
  getSearchFromProps,
  (operations, search) => {
    return operations.filter(({ fullName }) => containsString(fullName, search));
  }
);

export const getAipOperationsUnmatched = createSelector(getAllAipOperations, list => {
  return list.filter(({ operationName }) => !operationName);
});

export const getEditAllPaginatedOperations = createSelector(
  getEditAllOperations,
  getActivePageFromProps,
  getPerPageFromProps,
  getEditOperationSortFromProps,
  (operations, activePage, perPage, sortProps) => {
    const { key, direction } = sortProps;
    const sortedOperations = [...operations].sort(sortObjectByKey(key, direction));
    return sortedOperations.slice((activePage - 1) * perPage, perPage * activePage);
  }
);

export const getAllPaginatedAipOperations = createSelector(
  getAllAipOperations,
  getActivePageFromProps,
  getPerPageFromProps,
  getAipSortKeyFromProps,
  getSearchFromProps,
  getAipKeyFromProps,
  (operations, activePage, perPage, sortProps, search, key) => {
    const extendedOperations = operations.map(o => ({
      ...o,
      sortOperationNameValue: o.matches.length
        ? o.matches[0].operation.fullName
        : 'No data available',
      isMatched: o.operationName ? true : false
    }));
    const { sortKey, direction } = sortProps;
    const sortedOperations = [...extendedOperations].sort(sortObjectByKey(sortKey, direction));
    if (key === 'operationName') {
      const filteredOperations = sortedOperations.filter(({ operationName }) =>
        containsString(operationName, search)
      );
      return {
        paginatedOperations: [...filteredOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...filteredOperations].length
      };
    }
    if (key === 'policyNumber') {
      const filteredOperations = sortedOperations.filter(({ policyNumber }) =>
        containsString(policyNumber, search)
      );
      return {
        paginatedOperations: [...filteredOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...filteredOperations].length
      };
    }
    if (key === 'businessName') {
      const filteredOperations = sortedOperations.filter(({ businessName }) =>
        containsString(businessName.replace(/\s+/g, ' '), search.replace(/\s+/g, ' '))
      );
      return {
        paginatedOperations: [...filteredOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...filteredOperations].length
      };
    }
    if (key === 'aipCode') {
      const filteredOperations = sortedOperations.filter(({ aipCode }) =>
        containsString(aipCode, search)
      );
      return {
        paginatedOperations: [...filteredOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...filteredOperations].length
      };
    }
    if (key === 'matches') {
      const filteredOperations = sortedOperations.filter(({ sortOperationNameValue }) =>
        containsString(sortOperationNameValue, search)
      );
      return {
        paginatedOperations: [...filteredOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...filteredOperations].length
      };
    }
    if (key === 'isMatched') {
      if (search === 'Assigned') {
        const filteredOperations = sortedOperations.filter(({ isMatched }) => isMatched);
        return {
          paginatedOperations: [...filteredOperations].slice(
            (activePage - 1) * perPage,
            perPage * activePage
          ),
          operationsLength: [...filteredOperations].length
        };
      }
      if (search === 'Unassigned') {
        const filteredOperations = sortedOperations.filter(({ isMatched }) => !isMatched);
        return {
          paginatedOperations: [...filteredOperations].slice(
            (activePage - 1) * perPage,
            perPage * activePage
          ),
          operationsLength: [...filteredOperations].length
        };
      } else {
        return {
          paginatedOperations: [...sortedOperations].slice(
            (activePage - 1) * perPage,
            perPage * activePage
          ),
          operationsLength: [...sortedOperations].length
        };
      }
    } else {
      return {
        paginatedOperations: [...sortedOperations].slice(
          (activePage - 1) * perPage,
          perPage * activePage
        ),
        operationsLength: [...sortedOperations].length
      };
    }
  }
);

export const getFilteredAipEndorsements = createSelector(
  getAllAipEndorsements,
  getAipEndorsementYearFromProps,
  (endorsements, year) => {
    if (!endorsements) {
      return [];
    }
    const bothEndorsements = endorsements.filter(
      e => e.aipEndorsement !== undefined && e.endorsement !== undefined
    );
    const aipEndorsements = endorsements.filter(
      e => e.aipEndorsement !== undefined && e.endorsement === undefined
    );
    const drpEndorsements = endorsements.filter(
      e => e.aipEndorsement === undefined && e.endorsement !== undefined
    );

    const bothFiltered = [...bothEndorsements].filter(
      ({ aipEndorsement }) => aipEndorsement?.reinsuranceYear === year
    );
    const aipFiltered = [...aipEndorsements].filter(
      ({ aipEndorsement }) => aipEndorsement?.reinsuranceYear === year
    );
    const drpFiltered = [...drpEndorsements].filter(
      ({ endorsement }) => endorsement?.reinsuranceYear === year
    );

    return [...bothFiltered, ...aipFiltered, ...drpFiltered];
  }
);

export const getPaginatedAipEndorsements = createSelector(
  getFilteredAipEndorsements,
  getActivePageFromProps,
  getPerPageFromProps,
  (endorsements, activePage, perPage) =>
    endorsements
      .filter(x => x.endorsement?.status === 'Purchased')
      .slice((activePage - 1) * perPage, perPage * activePage)
);

export const getAipUnmatchedStatus = createSelector(getAipStatus, operations => {
  const unmatchedOperations = operations.filter(o => o.operation.operationName === undefined);
  return unmatchedOperations;
});

export const getOperationProducerSigners = (insurancePlan: InsurancePlan) =>
  createSelector(
    getOperationProducers,
    getOperationPageOperationData,
    (operationProducers, operation) => {
      if (!operationProducers) return [];
      if (!operation.agencyName) return [];

      const canOperationBind = insurancePlan && operationCanBind(operation, insurancePlan);
      return operationProducers
        .filter(producer => {
          return canOperationBind ? producer.bindRequirements?.canBind : true;
        })
        .map(x => {
          return {
            id: x.email,
            name: x.firstName + ' ' + x.lastName,
            title: x.title
          } as Signer;
        });
    }
  );
