import { addMonths, isAfter, isBefore } from 'date-fns';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { LrpEndorsementType } from '@/shared/api/lrpClient/lrpClient';
import { useLrpClient } from '@/shared/api/lrpClient/useLrpClient';
import { groupByKey } from '@/shared/utils/groupByKey';
import { getFirstOrCurrent, getLatestOrCurrent } from '@/shared/utils/objectArrayUtils';

import { LrpEndorsementFilterSettings } from '@/scenes/lrp/types/LrpEndorsement';

import { LrpEndorsementChartData } from '@/components/Charts/LrpEndorsementChart';

import { serializeDate } from '@/utils/dateFormatter';
import { invariant } from '@/utils/invariant';
import { getDateIndexKey, getDateKey } from '@/utils/months';
import numberRange from '@/utils/numberRange';
import sortObjectByKey from '@/utils/sortObjectByKey';

import SortDirection from '@/enums/SortDirection';

import {
  LrpDetailGroupWithTotals,
  LrpEndorsementsWithWeightClass,
  LrpPDFFormOptionsType,
  LrpReportDetailData,
  LrpReportSummaryData,
  LrpSummaryGroupWithTotals,
  WeightGroupKeys,
  detailTotals,
  summaryTotals,
  weightGroups
} from '../types';

export type LrpPDFTableData = {
  endorsementDetailData: LrpReportDetailData;
  endorsementSummaryData: LrpReportSummaryData;
};

export const useLrpPDFReport = () => {
  const { search } = useLocation();

  const query = useMemo(() => new URLSearchParams(search), [search]);
  const params = useParams<{ name: string }>();

  invariant(params.name, `Params error: operationName is expected, but received ${params.name}`);
  const { name: operationName } = params;
  const [settings, setSettings] = useState<LrpEndorsementFilterSettings | undefined>(undefined);
  const [reportType, setReportType] = useState<LrpPDFFormOptionsType>({ option: 'detail' });
  const [actualPriceDate, setActualPriceDate] = useState<string | undefined>(undefined);
  const [filterByDate, setFilterByDate] = useState(true);

  const [selectedMonth, setSelectedMonth] = useState<number | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);

  const [showExpiredEndorsements, setShowExpiredEndorsements] = useState(
    !!query.get('actualPriceDate')
  );
  const { lrpApi } = useLrpClient();

  const onToggleFilterByDateOrMonth = () => {
    const [year, month] = actualPriceDate?.split('-') ?? [];
    if (Number(year) !== selectedYear || Number(month) - 1 !== selectedMonth) {
      if (filterByDate && actualPriceDate) {
        setSelectedMonth(Number(month) - 1);
        setSelectedYear(Number(year));
        setActualPriceDate(undefined);
      } else {
        const date = new Date();
        setSelectedMonth(undefined);
        setSelectedYear(undefined);

        setActualPriceDate(
          serializeDate(
            new Date(
              selectedYear ?? date.getFullYear(),
              selectedMonth ?? date.getMonth(),
              date.getDate()
            )
          ).split('T')[0]
        );
      }
    }
    setFilterByDate(prev => !prev);
  };

  const onDateChange = (date: Date) => {
    if (filterByDate) {
      setActualPriceDate(serializeDate(date).split('T')[0]);
    } else {
      setSelectedMonth(date.getMonth());
      setSelectedYear(date.getFullYear());
    }
  };

  const queries = useMemo(
    () => ({
      Commodity: settings?.commodity,
      TypeCode: settings?.typeCode,
      ReinsuranceYearFrom: settings?.reinsuranceYearFrom,
      ReinsuranceYearTo: settings?.reinsuranceYearTo,
      CalendarYearFrom: settings?.calendarYearFrom,
      CalendarYearTo: settings?.calendarYearTo,
      SalesEffectiveDateFrom: settings?.salesEffectiveDateFrom,
      SalesEffectiveDateTo: settings?.salesEffectiveDateTo,
      EndDateFrom: settings?.endDateFrom,
      EndDateTo: settings?.endDateTo,
      TopCondition: settings?.topCondition,
      GroupByEndMonth: settings?.groupByEndMonth,
      TagNames: settings?.tagNames,
      ...(showExpiredEndorsements && actualPriceDate && { actualPriceDate: actualPriceDate })
    }),
    [settings, showExpiredEndorsements, actualPriceDate]
  );

  const { data: lrpEndorsements, isLoading: isLrpEndorsementsLoading } = lrpApi.useGetEndorsements(
    {
      params: {
        operationName: operationName
      },
      queries
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!settings?.endDateFrom && !!settings?.endDateTo
    }
  );

  const { data: lrpEndorsementSummariesResponseData, isLoading: isLrpEndorsementSummariesLoading } =
    lrpApi.useGetEndorsementSummaries(
      {
        params: {
          operationName: operationName
        },
        queries
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: !!settings?.endDateFrom && !!settings?.endDateTo
      }
    );

  const {
    data: allLrpEndorsementSummariesResponseData,
    isLoading: isAllLrpEndorsementSummariesLoading
  } = lrpApi.useGetEndorsementSummaries(
    {
      params: {
        operationName: operationName
      },
      queries: {
        reinsuranceYearFrom: settings?.reinsuranceYearFrom,
        reinsuranceYearTo: settings?.reinsuranceYearTo,
        calendarYearFrom: settings?.calendarYearFrom,
        calendarYearTo: settings?.calendarYearTo,
        salesEffectiveDateFrom: settings?.salesEffectiveDateFrom,
        salesEffectiveDateTo: settings?.salesEffectiveDateTo,
        endDateFrom: settings?.endDateFrom,
        endDateTo: settings?.endDateTo,
        topCondition: settings?.topCondition,
        groupByEndMonth: true,
        tagNames: settings?.tagNames,
        ...(settings?.commodity && { Commodity: settings?.commodity }),
        ...(settings?.typeCode && { TypeCode: settings?.typeCode })
      }
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!settings?.endDateFrom && !!settings?.endDateTo
    }
  );

  const lrpEndorsementSummaries: LrpEndorsementType[] = (lrpEndorsementSummariesResponseData ?? [])
    .slice()
    .sort((a, b) => {
      if (a.endDate && b.endDate)
        if (a.endDate < b.endDate) {
          return -1;
        }
      return 1;
    });

  const endorsementObjDefaultValues = {
    coveragePrice: 0,
    netGuarantee: 0,
    producerPremium: 0,
    changeInActualPrice: 0,
    forecastedIndemnity: 0
  };

  type EndorsementsObjBase = {
    coveragePrice: number;
    netGuarantee: number;
    producerPremium: number;
    changeInActualPrice: number;
    forecastedIndemnity: number;
    endMonth: string;
    name: string;
  };

  type EndorsementsObjCwt = EndorsementsObjBase & { totalWeight: number };
  type EndorsementsObjHead = EndorsementsObjBase & { numberOfHead: number };

  const getMonthDifference = <T extends Date | undefined | null>(startDate: T, endDate: T) => {
    if (!startDate || !endDate) return 0;
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  };
  const filteredLrpEndorsements = useMemo(() => {
    const isPurchased = (endorsement: LrpEndorsementType) => endorsement.status === 'Purchased';
    const isFilteringByMonth =
      selectedMonth !== undefined && selectedYear !== undefined && showExpiredEndorsements;
    const isInSelectedMonth = (endorsement: LrpEndorsementType) =>
      endorsement.endDate?.getFullYear() === selectedYear &&
      selectedMonth !== undefined &&
      endorsement.endDate?.getMonth() === selectedMonth;

    return (lrpEndorsements ?? []).filter(
      endorsement =>
        isPurchased(endorsement) && (!isFilteringByMonth || isInSelectedMonth(endorsement))
    );
  }, [JSON.stringify(lrpEndorsements), filterByDate, showExpiredEndorsements, selectedMonth]);

  const endorsementChartData = useMemo(() => {
    const endorsements = [...filteredLrpEndorsements].sort(
      sortObjectByKey('endDate', SortDirection.ASCENDING)
    );
    if (endorsements.length === 0) {
      return { cwt: [], head: [] };
    }

    const lrpEndorsementChartDataCwt: LrpEndorsementChartData[] = [];
    const lrpEndorsementChartDataHead: LrpEndorsementChartData[] = [];

    const endorsementsObjCwt: Record<string, EndorsementsObjCwt> = {};
    const endorsementsObjHead: Record<string, EndorsementsObjHead> = {};

    endorsements.forEach(endorsement => {
      const dateKey = getDateKey(endorsement.endDate);
      if (!endorsementsObjCwt[dateKey]) {
        endorsementsObjCwt[dateKey] = {
          endMonth: getDateIndexKey(endorsement.endDate),
          name: dateKey,
          totalWeight: 0,
          ...endorsementObjDefaultValues
        };
      }

      endorsementsObjCwt[dateKey].coveragePrice += endorsement.coveragePriceHead ?? 0;
      endorsementsObjCwt[dateKey].netGuarantee += endorsement.netGuaranteeHead ?? 0;
      endorsementsObjCwt[dateKey].producerPremium += endorsement.premiumProducerHead ?? 0;
      endorsementsObjCwt[dateKey].changeInActualPrice += endorsement.changeInActualPriceHead ?? 0;
      endorsementsObjCwt[dateKey].forecastedIndemnity += endorsement.indemnityEstimateHead ?? 0;
      endorsementsObjCwt[dateKey].totalWeight += endorsement.targetWeight ?? 0;

      if (!endorsementsObjHead[dateKey]) {
        endorsementsObjHead[dateKey] = {
          endMonth: getDateIndexKey(endorsement.endDate),
          name: dateKey,
          numberOfHead: 0,
          ...endorsementObjDefaultValues
        };
      }

      endorsementsObjHead[dateKey].coveragePrice += endorsement.coveragePrice ?? 0;
      endorsementsObjHead[dateKey].netGuarantee += endorsement.netGuarantee ?? 0;
      endorsementsObjHead[dateKey].producerPremium += endorsement.premiumProducer ?? 0;
      endorsementsObjHead[dateKey].changeInActualPrice += endorsement.changeInActualPrice ?? 0;
      endorsementsObjHead[dateKey].forecastedIndemnity += endorsement.indemnityEstimate ?? 0;
      endorsementsObjHead[dateKey].numberOfHead += endorsement.numberOfHead ?? 0;
    });

    const firstEndDate = endorsements[0].endDate ?? new Date();
    const startDate = new Date(firstEndDate.getFullYear(), firstEndDate.getMonth(), 1);

    const monthDifference = getMonthDifference(
      endorsements[0].endDate,
      endorsements[endorsements.length - 1].endDate
    );
    numberRange(0, monthDifference + 1).forEach(monthIncrement => {
      const date = addMonths(startDate, monthIncrement);
      const dateKey = getDateKey(date);
      if (!endorsementsObjCwt[dateKey]) {
        endorsementsObjCwt[dateKey] = {
          endMonth: getDateIndexKey(date),
          name: dateKey,
          totalWeight: 0,
          ...endorsementObjDefaultValues
        };
      }
      if (endorsementsObjCwt[dateKey].totalWeight > 0) {
        endorsementsObjCwt[dateKey].coveragePrice =
          endorsementsObjCwt[dateKey].coveragePrice / endorsementsObjCwt[dateKey].totalWeight;
        endorsementsObjCwt[dateKey].netGuarantee =
          endorsementsObjCwt[dateKey].netGuarantee / endorsementsObjCwt[dateKey].totalWeight;
        endorsementsObjCwt[dateKey].producerPremium =
          endorsementsObjCwt[dateKey].producerPremium / endorsementsObjCwt[dateKey].totalWeight;
        endorsementsObjCwt[dateKey].changeInActualPrice =
          endorsementsObjCwt[dateKey].changeInActualPrice / endorsementsObjCwt[dateKey].totalWeight;
        endorsementsObjCwt[dateKey].forecastedIndemnity =
          endorsementsObjCwt[dateKey].forecastedIndemnity / endorsementsObjCwt[dateKey].totalWeight;
      }
      lrpEndorsementChartDataCwt.push(endorsementsObjCwt[dateKey]);

      if (!endorsementsObjHead[dateKey]) {
        endorsementsObjHead[dateKey] = {
          endMonth: getDateIndexKey(date),
          name: dateKey,
          numberOfHead: 0,
          ...endorsementObjDefaultValues
        };
      }

      if (endorsementsObjHead[dateKey].numberOfHead > 0) {
        endorsementsObjHead[dateKey].coveragePrice =
          endorsementsObjHead[dateKey].coveragePrice / endorsementsObjHead[dateKey].numberOfHead;
        endorsementsObjHead[dateKey].netGuarantee =
          endorsementsObjHead[dateKey].netGuarantee / endorsementsObjHead[dateKey].numberOfHead;
        endorsementsObjHead[dateKey].producerPremium =
          endorsementsObjHead[dateKey].producerPremium / endorsementsObjHead[dateKey].numberOfHead;
        endorsementsObjHead[dateKey].changeInActualPrice =
          endorsementsObjHead[dateKey].changeInActualPrice /
          endorsementsObjHead[dateKey].numberOfHead;
        endorsementsObjHead[dateKey].forecastedIndemnity =
          endorsementsObjHead[dateKey].forecastedIndemnity /
          endorsementsObjHead[dateKey].numberOfHead;
      }
      lrpEndorsementChartDataHead.push(endorsementsObjHead[dateKey]);
    });

    return { cwt: lrpEndorsementChartDataCwt, head: lrpEndorsementChartDataHead };
  }, [JSON.stringify(filteredLrpEndorsements)]);

  const sortEndorsementsByWeight = (
    endorsements: LrpEndorsementType[] | undefined
  ): LrpEndorsementsWithWeightClass[] =>
    (endorsements ?? [])
      .filter(d => d.status !== 'PurchaseRequest')
      .sort((a, b) => {
        if (
          a.endDate &&
          b.endDate &&
          a.typeCode &&
          a.typeCode in weightGroups &&
          b.typeCode &&
          b.typeCode in weightGroups
        ) {
          if (a.endDate < b.endDate) {
            return -1;
          } else if (a.endDate > b.endDate) {
            return 1;
          }
          if (
            weightGroups[a.typeCode as WeightGroupKeys] <
            weightGroups[b.typeCode as WeightGroupKeys]
          ) {
            return -1;
          } else {
            return 1;
          }
        }
        return 0;
      })
      .map(endorsement => ({
        ...endorsement,
        weightClass: weightGroups[endorsement.typeCode as WeightGroupKeys]
      }));

  const groupEndorsementsByMonthAndWeight = (
    endorsements: LrpEndorsementsWithWeightClass[]
  ): LrpDetailGroupWithTotals[] => {
    const groupedEndorsements: LrpDetailGroupWithTotals[] = [];

    const valueOrNull = (value: number | null | undefined) => value ?? 0;

    endorsements.forEach(endorsement => {
      const month = endorsement.endDate ? endorsement.endDate.getMonth() + 1 : 1;
      const weight = endorsement.weightClass;

      const existingGroup = groupedEndorsements.find(
        group => group.month === month && group.weightClass === weight
      );

      if (existingGroup) {
        existingGroup.totals.numberOfHead += valueOrNull(endorsement.numberOfHead);
        existingGroup.totals.targetWeightAverage +=
          valueOrNull(endorsement.targetWeight) * valueOrNull(endorsement.numberOfHead);
        existingGroup.totals.producerPremium += valueOrNull(endorsement.premiumProducer);
        existingGroup.totals.netGuarantee += valueOrNull(endorsement.netGuarantee);
        existingGroup.totals.forecastedNetIndemnity += valueOrNull(
          endorsement.netEstimatedIndemnity
        );
        existingGroup.totals.forecastedActualPrice += endorsement.actualPriceCwt
          ? valueOrNull(endorsement.actualPrice)
          : 0;
        existingGroup.endorsements.push(endorsement);
      } else if (month && weight) {
        const totals: detailTotals = {
          numberOfHead: valueOrNull(endorsement.numberOfHead),
          targetWeightAverage:
            valueOrNull(endorsement.targetWeight) * valueOrNull(endorsement.numberOfHead),
          producerPremium: valueOrNull(endorsement.premiumProducer),
          netGuarantee: valueOrNull(endorsement.netGuarantee),
          forecastedNetIndemnity: valueOrNull(endorsement.netEstimatedIndemnity),
          forecastedActualPrice: valueOrNull(endorsement.actualPriceCwt)
            ? valueOrNull(endorsement.actualPrice)
            : 0
        };

        groupedEndorsements.push({
          month,
          endorsements: [endorsement],
          weightClass: weight,
          totals
        });
      }
    });

    groupedEndorsements.sort((a, b) => {
      if (a.month === b.month) {
        return a.weightClass - b.weightClass;
      }
      return a.month - b.month;
    });

    return groupedEndorsements;
  };

  const groupEndorsementsByMonth = (
    endorsements: LrpEndorsementType[]
  ): LrpSummaryGroupWithTotals[] => {
    const groupedEndorsements: LrpSummaryGroupWithTotals[] = [];

    endorsements.forEach(endorsement => {
      const month = endorsement.endDate ? endorsement.endDate.getMonth() + 1 : 1;

      const existingGroup = groupedEndorsements.find(group => group.month === month);
      if (existingGroup) {
        existingGroup.totals.numberOfHead += endorsement.numberOfHead ?? 0;
        existingGroup.totals.grossEstimated += endorsement.grossEstimatedIndemnity ?? 0;
        existingGroup.totals.producerPremium += endorsement.premiumProducer ?? 0;
        existingGroup.totals.netEstimated += endorsement.netEstimatedIndemnity ?? 0;
        existingGroup.endorsements.push(endorsement);
      } else if (month) {
        const totals: summaryTotals = {
          numberOfHead: endorsement.numberOfHead ?? 0,
          grossEstimated: endorsement.grossEstimatedIndemnity ?? 0,
          producerPremium: endorsement.premiumProducer ?? 0,
          netEstimated: endorsement.netEstimatedIndemnity ?? 0
        };
        groupedEndorsements.push({ month, endorsements: [endorsement], totals });
      }
    });

    return groupedEndorsements.sort((a, b) => a.month - b.month);
  };

  const pdfTableData: LrpPDFTableData = useMemo(() => {
    const endorsementsGroupedByYear = groupByKey(
      sortEndorsementsByWeight(filteredLrpEndorsements),
      'endDate',
      value => value?.getFullYear().toString() ?? ''
    );

    const endorsementDetailData: LrpReportDetailData = {};
    for (const [year, endorsements] of Object.entries(endorsementsGroupedByYear)) {
      endorsementDetailData[year] = groupEndorsementsByMonthAndWeight(endorsements);
    }

    const endorsementSummaryData: LrpReportSummaryData = {};
    for (const [year, endorsements] of Object.entries(endorsementsGroupedByYear)) {
      endorsementSummaryData[year] = groupEndorsementsByMonth(endorsements);
    }

    return { endorsementDetailData, endorsementSummaryData };
  }, [JSON.stringify(filteredLrpEndorsements)]);

  const { minDate, maxDate, endorsementsExistInDateRange } = useMemo(() => {
    const filteredAllEndorsementData = allLrpEndorsementSummariesResponseData
      ?.filter(d => d.status !== 'PurchaseRequest')
      .slice()
      .sort((a, b) => {
        if (a.endYear && b.endYear && a.endMonth && b.endMonth) {
          if (a.endYear < b.endYear) {
            return -1;
          }
          if (a.endYear === b.endYear && a.endMonth < b.endMonth) {
            return -1;
          }
        }
        return 1;
      });
    const endorsementsExistInDateRange =
      filteredAllEndorsementData?.length && filteredAllEndorsementData?.length > 0;
    const maxDate = endorsementsExistInDateRange
      ? getLatestOrCurrent(filteredAllEndorsementData)
      : new Date();
    const minDate = endorsementsExistInDateRange
      ? getFirstOrCurrent(filteredAllEndorsementData)
      : new Date();
    return {
      minDate: minDate,
      maxDate: maxDate,
      endorsementsExistInDateRange: endorsementsExistInDateRange
    };
  }, [JSON.stringify(allLrpEndorsementSummariesResponseData)]);

  useEffect(() => {
    if (endorsementsExistInDateRange && query.get('actualPriceDate') && filterByDate) {
      const [year, month, day] = query.get('actualPriceDate')?.split('-') ?? [0, 0, 0];
      const queryDate = new Date(Number(year), Number(month) - 1, Number(day));
      if (isAfter(queryDate, maxDate)) {
        onDateChange(maxDate);
      } else if (isBefore(queryDate, minDate)) {
        onDateChange(minDate);
      } else {
        setActualPriceDate(query.get('actualPriceDate') ?? serializeDate(new Date()).split('T')[0]);
      }
    } else if (isAfter(new Date(), minDate) && isBefore(new Date(), maxDate)) {
      onDateChange(new Date());
    } else {
      onDateChange(maxDate);
    }
  }, [query.get('actualPriceDate'), JSON.stringify(allLrpEndorsementSummariesResponseData)]);

  const value = useMemo(
    () => ({
      lrpEndorsements,
      endorsementChartData,
      lrpEndorsementSummaries,
      lrpEndorsementSummariesResponseData,
      isLrpEndorsementSummariesLoading,
      isLrpEndorsementsLoading,
      settings,
      setSettings,
      actualPriceDate,
      setActualPriceDate,
      showExpiredEndorsements,
      setShowExpiredEndorsements,
      reportType,
      setReportType,
      pdfTableData,
      filterByDate,
      setFilterByDate,
      selectedMonth,
      setSelectedMonth,
      selectedYear,
      setSelectedYear,
      onToggleFilterByDateOrMonth,
      onDateChange,
      minDate,
      maxDate,
      endorsementsExistInDateRange,
      isAllLrpEndorsementSummariesLoading
    }),
    [
      lrpEndorsements,
      endorsementChartData,
      lrpEndorsementSummaries,
      lrpEndorsementSummariesResponseData,
      isLrpEndorsementSummariesLoading,
      isLrpEndorsementsLoading,
      settings,
      setSettings,
      actualPriceDate,
      setActualPriceDate,
      showExpiredEndorsements,
      setShowExpiredEndorsements,
      reportType,
      setReportType,
      pdfTableData,
      filterByDate,
      setFilterByDate,
      selectedMonth,
      setSelectedMonth,
      selectedYear,
      setSelectedYear,
      onToggleFilterByDateOrMonth,
      onDateChange,
      minDate,
      maxDate,
      endorsementsExistInDateRange,
      isAllLrpEndorsementSummariesLoading
    ]
  );

  return value;
};

export type LrpPDFReportCtxType = ReturnType<typeof useLrpPDFReport>;
const LrpPDFReportCtx = createContext({} as LrpPDFReportCtxType);

export const LrpPDFReportCtxProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const value = useLrpPDFReport();
  return <LrpPDFReportCtx.Provider value={value}>{children}</LrpPDFReportCtx.Provider>;
};

export function useLrpPDFReportCtx() {
  return useContext(LrpPDFReportCtx);
}
