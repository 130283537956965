import { WeightedValue } from '@/utils/statistics';

import { Endorsement } from '@/types/Endorsement/Endorsement';
import { ReportTableData } from '@/types/Endorsement/EndorsementReport';
import { shareEnum, typeCodeEnum } from '@/types/Endorsement/constants';

export const getEffectiveValue = (
  value: number | undefined,
  milkProduction: number | undefined
) => {
  return value && milkProduction ? value / (milkProduction / 100) : 0;
};

const getClassEquivalent = (
  endorsements: Endorsement[],
  typeCode: typeCodeEnum,
  share: shareEnum,
  equivalent: 'declared' | 'effective'
): number => {
  const filterFn: (endorsement: Endorsement) => boolean = endorsement =>
    endorsement.typeCode === typeCode;

  const mapFn: (endorsement: Endorsement) => number = endorsement => {
    const priceWeightingFactor =
      typeCode === typeCodeEnum.CLASS
        ? endorsement.classPriceWeightingFactor
        : endorsement.componentPriceWeightingFactor;
    return (
      (share === shareEnum.CLASS_III ? priceWeightingFactor : 1 - priceWeightingFactor) *
      endorsement.milkProduction *
      (equivalent === 'effective' ? endorsement.protectionFactor : 1)
    );
  };

  return (endorsements ?? [])
    .filter(filterFn)
    .map(mapFn)
    .reduce((acc, cur) => acc + cur, 0);
};

export const getHedgeRatioEquivalents = (data: ReportTableData): ReportTableData => {
  for (const year in data) {
    data[year].forEach((quarter, idx) => {
      const totalMilkMarketings = quarter?.production?.milkProductionActual;
      if (!totalMilkMarketings) {
        return;
      }

      const classIIIDeclared = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.CLASS,
        shareEnum.CLASS_III,
        'declared'
      );

      const classIIICompDeclared = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.COMPONENT,
        shareEnum.CLASS_III,
        'declared'
      );

      const classIVDeclared = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.CLASS,
        shareEnum.CLASS_IV,
        'declared'
      );

      const classIVCompDeclared = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.COMPONENT,
        shareEnum.CLASS_IV,
        'declared'
      );

      const classIIIEffective = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.CLASS,
        shareEnum.CLASS_III,
        'effective'
      );

      const classIIICompEffective = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.COMPONENT,
        shareEnum.CLASS_III,
        'effective'
      );

      const classIVEffective = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.CLASS,
        shareEnum.CLASS_IV,
        'effective'
      );

      const classIVCompEffective = getClassEquivalent(
        quarter?.operationEndorsements,
        typeCodeEnum.COMPONENT,
        shareEnum.CLASS_IV,
        'effective'
      );

      const declaredClassIIIEquivalent =
        (classIIIDeclared + classIIICompDeclared) / totalMilkMarketings;
      const declaredClassIVEquivalent =
        (classIVDeclared + classIVCompDeclared) / totalMilkMarketings;
      const effectiveClassIIIEquivalent =
        (classIIIEffective + classIIICompEffective) / totalMilkMarketings;
      const effectiveClassIVEquivalent =
        (classIVEffective + classIVCompEffective) / totalMilkMarketings;

      data[year][idx] = {
        ...quarter,
        hedgeRatioEquivalent: {
          declared: {
            classIIIEquivalent: declaredClassIIIEquivalent,
            classIVEquivalent: declaredClassIVEquivalent,
            total: declaredClassIIIEquivalent + declaredClassIVEquivalent
          },
          effective: {
            classIIIEquivalent: effectiveClassIIIEquivalent,
            classIVEquivalent: effectiveClassIVEquivalent,
            total: effectiveClassIIIEquivalent + effectiveClassIVEquivalent
          }
        }
      };
    });
  }

  return data;
};

export function getWeightedValues(arrayFirst: number[], arraySecond: number[]): WeightedValue[] {
  return arrayFirst.map((value, index) => ({
    weight: value,
    value: arraySecond[index]
  }));
}
