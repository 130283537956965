import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

type ButtonVariant =
  | 'default'
  | 'primary'
  | 'link'
  | 'destructive'
  | 'outline'
  | 'select'
  | 'icon'
  | 'secondary'
  | 'filter'
  | 'education'
  | 'clear'
  | 'pending';

type ButtonVariantStyles = Record<string, string | number>;

const buttonVariants: Record<ButtonVariant, ButtonVariantStyles> = {
  default: {
    backgroundColor: color.PRIMARY,
    color: '#fff',
    hoverOpacity: 0.9,
    hoverBoxShadow: '2px 2px 2px rgba(128, 128, 128, 0.35)'
  },
  primary: {
    backgroundColor: color.DRP_INPUT,
    color: '#fff',
    hoverOpacity: 0.9,
    hoverBoxShadow: '2px 2px 2px rgba(128, 128, 128, 0.35)',
    border: 'none'
  },
  secondary: {
    backgroundColor: color.BACKGROUND_SECONDARY,
    color: color.TEXT_PRIMARY,
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '6px',
    border: 'none',
    padding: '0.8rem 1rem',
    height: '40px',
    minWidth: '100px'
  },
  link: {
    backgroundColor: 'transparent',
    color: color.DRP_INPUT,
    hoverOpacity: 0.8,
    border: 'none',
    fontWeight: 600,
    padding: 0
  },
  destructive: {
    backgroundColor: color.NEGATIVE,
    color: '#fff',
    hoverOpacity: 0.9,
    hoverBoxShadow: '2px 2px 2px rgba(128, 128, 128, 0.35)'
  },
  outline: {
    backgroundColor: 'transparent',
    color: color.DRP_INPUT,
    border: `1px solid ${color.DRP_INPUT}`,
    hoverOpacity: 0.9,
    hoverBoxShadow: '2px 2px 2px rgba(128, 128, 128, 0.35)'
  },
  select: {
    backgroundColor: color.BACKGROUND_SECONDARY,
    color: color.DRP_INPUT,
    border: 'none'
  },
  icon: {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: color.DRP_INPUT,
    padding: 0
  },
  clear: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: color.DRP_INPUT,
    backgroundColor: 'transparent',
    padding: 0,
    border: 'none'
  },
  filter: {
    borderRadius: '50px',
    fontWeight: '600',
    marginRight: '0.35rem',
    fontSize: '9px',
    width: 'auto',
    padding: '0.2rem',
    height: '20px',
    marginBottom: '0.2rem',
    border: '1px solid'
  },
  education: {
    backgroundColor: '#9394B8',
    color: color.BACKGROUND,
    border: `1px solid ${color.BACKGROUND}`,
    height: '34px',
    width: '230px',
    fontSize: '1rem'
  },
  pending: {
    backgroundColor: color.SECONDARY,
    color: '#ffffff',
    border: 'none'
  }
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  asChild?: boolean;
  dataCy?: string;
  active?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, dataCy = 'button', ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return <Comp ref={ref} data-cy={dataCy} {...props} />;
  }
);
Button.displayName = 'Button';

const bgColor = (variant: ButtonVariant, active?: boolean) => {
  if (variant === 'filter') {
    return active ? color.DRP_INPUT : color.ACCORDION;
  }
  return buttonVariants[variant].backgroundColor;
};

const fontColor = (variant: ButtonVariant, active?: boolean) => {
  if (variant === 'filter') {
    return active ? 'white' : color.DRP_INPUT;
  }
  return buttonVariants[variant].color;
};

const StyledButton = styled(Button)<ButtonProps>`
  ${({ theme, variant = 'default', active }) => {
    const {
      border,
      hoverOpacity,
      hoverBoxShadow,
      fontWeight,
      padding,
      display,
      alignItems,
      justifyContent,
      fontSize,
      all,
      borderRadius,
      height,
      width,
      minWidth,
      marginBottom
    } = theme.components.button[variant];
    return css`
      cursor: pointer;

      all: ${all};
      display: ${display};
      align-items: ${alignItems};
      justify-content: ${justifyContent};
      font-size: ${fontSize};
      font-weight: ${fontWeight ?? 500};
      color: ${fontColor(variant, active)};
      padding: ${padding ?? '0.5rem 1rem'};
      background-color: ${bgColor(variant, active)};
      border: ${border};
      border-radius: ${borderRadius ?? ''};
      height: ${height ?? 'auto'};
      width: ${width ?? 'auto'};
      min-width: ${minWidth ?? 'auto'};
      margin-bottom: ${marginBottom ?? '0'};

      &:hover {
        opacity: ${hoverOpacity};
        box-shadow: ${hoverBoxShadow};
        transition-duration: 200ms;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `;
  }}
`;

export { StyledButton as Button, buttonVariants };
export type { ButtonVariant, ButtonVariantStyles };
