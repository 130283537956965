import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Scenario } from '../../types/Scenario';
import createUniqueArray from '../../utils/createUniqueArray';
import { ScenarioActions, scenarioActions } from './actions';

interface ScenarioState {
  operationNames: string[];
  allOperations: {
    [operatio: string]: {
      scenarioNames: string[];
      allScenarios: {
        [name: string]: Scenario;
      };
    };
  };
}

const initialState: ScenarioState = {
  operationNames: [],
  allOperations: {}
};

export const scenarioReducer = (state = initialState, action: ScenarioActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(scenarioActions.addAllNames):
        if (!draft.allOperations[action.meta]) {
          draft.operationNames.push(action.meta);
          draft.allOperations[action.meta] = { scenarioNames: action.payload, allScenarios: {} };
        } else {
          draft.allOperations[action.meta].scenarioNames = createUniqueArray([
            ...draft.allOperations[action.meta].scenarioNames,
            ...action.payload
          ]);
        }
        return;
      case getType(scenarioActions.add):
        if (!draft.allOperations[action.meta.operation]) {
          draft.operationNames.push(action.meta.name);
          draft.allOperations[action.meta.operation] = {
            scenarioNames: [action.meta.name],
            allScenarios: { [action.meta.name]: action.payload }
          };
        } else {
          draft.allOperations[action.meta.operation].scenarioNames = createUniqueArray([
            ...draft.allOperations[action.meta.operation].scenarioNames,
            action.meta.name
          ]);
          draft.allOperations[action.meta.operation].allScenarios[action.meta.name] =
            action.payload;
        }
        return;
      case getType(scenarioActions.remove):
        if (draft.allOperations[action.meta]) {
          draft.allOperations[action.meta].scenarioNames = draft.allOperations[
            action.meta
          ].scenarioNames.filter(name => name !== action.payload);
          if (draft.allOperations[action.meta].allScenarios[action.payload]) {
            delete draft.allOperations[action.meta].allScenarios[action.payload];
          }
        }
        return;
    }
  });
