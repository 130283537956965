import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Endorsement } from '../../../types/Endorsement/Endorsement';
import { EndorsementSummary } from '../../../types/Endorsement/EndorsementSummary';
import { OperationPageActions, operationPageActions } from '../../operationPage/actions';
import { ScenarioAnalysisActions, scenarioAnalysisActions } from '../actions';

type InitialState = {
  summaries: EndorsementSummary[];
  endorsements: Endorsement[];
};

const initialState: InitialState = {
  summaries: [],
  endorsements: []
};

export const scenarioEndorsementDataReducer = (
  state = initialState,
  action: ScenarioAnalysisActions | OperationPageActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(scenarioAnalysisActions.saveScenarioEndorsementsSummary): {
        draft.summaries = action.payload;
        return;
      }
      case getType(scenarioAnalysisActions.saveScenarioEndorsmentsByQuarter): {
        draft.endorsements = action.payload;
        return;
      }
      case getType(scenarioAnalysisActions.removeEndorsement): {
        const index = draft.endorsements.findIndex(e => e.id === action.payload);
        if (index !== -1) {
          draft.endorsements.splice(index, 1);
        }
        return;
      }
      case getType(operationPageActions.setOperationName): {
        draft.endorsements = [];
        draft.summaries = [];
        return;
      }
    }
  });
