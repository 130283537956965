import React from 'react';
import CacheBuster from 'react-cache-buster';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line no-restricted-imports
import { ThemeProvider } from 'styled-components';

import theme from '@/shared/theme';

import packageInfo from '../package.json';
import './App.css';
import store from './ducks/store';
import AppRouter from './router/AppRouter';

const App: React.FC = () => {
  console.log(import.meta.env.VITE_APP_DISABLE_CACHEBUSTER);
  let enableCacheBuster = true;
  if (import.meta.env.VITE_APP_DISABLE_CACHEBUSTER == 'true') {
    enableCacheBuster = false;
  }

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={true} // If false, the library is disabled.
      isVerboseMode={enableCacheBuster} // If true, the library writes verbose logs to console.
      metaFileDirectory={'.'} // If public assets are hosted somewhere other than root on your server.
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
        <ToastContainer theme="colored" style={{ zIndex: 99999 }} />
      </Provider>
    </CacheBuster>
  );
};

export default App;
