import { getType } from 'typesafe-actions';

import { Agency } from '../../types/Agency';
import { AdminPageActions, adminPageActions } from './actions';

interface AdminPageState {
  search: string;
  agency: Agency | null;
}

const initialState: AdminPageState = {
  search: '',
  agency: null
};

export const adminPageReducer = (state = initialState, action: AdminPageActions) => {
  switch (action.type) {
    case getType(adminPageActions.setSearch):
      return { ...state, search: action.payload };
    case getType(adminPageActions.setAgency):
      return { ...state, agency: action.payload };
    default:
      return state;
  }
};
