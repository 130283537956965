import { ActionType, createAction } from 'typesafe-actions';

import CreateEducationVideoPayload from '../../types/EducationModule/CreateEducationVideoPayload';
import EducationVideo from '../../types/EducationModule/EducationVideo';
import EducationVideoAttachment from '../../types/EducationModule/EducationVideoAttachment';

export const educationModuleActions = {
  getEducationVideos: createAction('@education_module/GET_EDUCATION_VIDEOS')<string>(),
  saveEducationVideos: createAction('@education_module/SAVE_EDUCATION_VIDEOS')<EducationVideo[]>(),
  editEducationVideoOrder: createAction('@education_module/EDIT_EDUCATION_VIDEP_ORDER')<{
    video: EducationVideo[];
    agencyName: string;
  }>(),
  deleteEducationVideo: createAction('@education_module/DELETE_EDUCATION_VIDEO')<
    string,
    () => void
  >(),
  removeEducationVideo: createAction('@education_module/REMOVE_EDUCATION_VIDEO')<string>(),
  getEducationVideoRecommendedVideos: createAction('@education_module/GET_RECOMMENDED_VIDEOS')<
    string,
    React.Dispatch<React.SetStateAction<EducationVideo[]>>
  >(),
  getEducationVideoUrl: createAction('@education_module/GET_VIDEO_URL')<
    string,
    React.Dispatch<React.SetStateAction<string>>
  >(),
  setEducationVideo: createAction('@education_module/SET_EDUCATION_VIDEO')<EducationVideo | null>(),
  getEducationVideoAttachments: createAction('@education_module/GET_VIDEO_ATTACHMENTS')<
    string,
    React.Dispatch<React.SetStateAction<EducationVideoAttachment[]>>
  >(),
  createEducationVideo: createAction('@education_module/CREATE_EDUCATION_VIDEO')<
    CreateEducationVideoPayload,
    () => void
  >(),
  editEducationVideo: createAction('@education_module/EDIT_EDUCATION_VIDEO')<
    {
      video: EducationVideo;
      attachments?: File[];
      thumbnailFile?: File;
      deletedAttachments?: EducationVideoAttachment[];
      agencyName: string;
    },
    () => void
  >(),
  setEducationModuleSpinner: createAction('@educationModule/SET_SPINNER')<boolean>()
};

export type EducationModuleActions = ActionType<typeof educationModuleActions>;
