import { ActionType, createAction } from 'typesafe-actions';

export const unsubscribeNewsletterActions = {
  getUnsubscribed: createAction('@UnsubscribeNewsletter/GET_UNSUBSCRIBE')<{
    unsubscribeToken: string;
    newsletterType?: string;
    expiredEndorsementType?: string;
    lrpAlert?: boolean;
  }>(),
  setUnsubscribed: createAction('@UnsubscribeNewsletter/SET_UNSUBSCRIBED')<boolean>()
};

export type UnsubscribeNewsletterActions = ActionType<typeof unsubscribeNewsletterActions>;
