import { parse } from 'date-fns';

import ScenarioWhatIfPrices from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';

const updateWhatIfPrices = (
  oldWhatIfPrices: ScenarioWhatIfPrices,
  newWhatIfPrices: ScenarioWhatIfPrices
): ScenarioWhatIfPrices => {
  const updatedPrices = { ...oldWhatIfPrices };
  const keys = Object.keys(oldWhatIfPrices) as (keyof ScenarioWhatIfPrices)[];

  keys.forEach(key => {
    if (key !== 'reinsuranceYear') {
      const property = oldWhatIfPrices[key];
      const filteredPrices = property.filter(
        p =>
          parse(p.date.slice(0, 10), 'yyyy-MM-dd', new Date()).getFullYear() !==
          newWhatIfPrices.reinsuranceYear
      );
      const newPrices = [...filteredPrices, ...newWhatIfPrices[key]];
      newPrices.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
      updatedPrices[key] = newPrices;
    }
  });

  return updatedPrices;
};

export default updateWhatIfPrices;
