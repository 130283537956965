import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import EducationVideo from '../../types/EducationModule/EducationVideo';
import { EducationModuleActions, educationModuleActions } from './actions';

interface State {
  educationVideos: EducationVideo[];
  educationVideo: EducationVideo | null;
  spinner: boolean;
}

const initialState: State = {
  educationVideos: [],
  educationVideo: null,
  spinner: false
};

const educationModuleReducer = (state = initialState, action: EducationModuleActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(educationModuleActions.saveEducationVideos): {
        draft.educationVideos = action.payload;
        return;
      }
      case getType(educationModuleActions.removeEducationVideo): {
        const index = state.educationVideos.findIndex(v => v.id === action.payload);
        if (index !== -1) {
          draft.educationVideos.splice(index, 1);
        }
        return;
      }
      case getType(educationModuleActions.setEducationVideo): {
        draft.educationVideo = action.payload;
        return;
      }
      case getType(educationModuleActions.setEducationModuleSpinner): {
        draft.spinner = action.payload;
        return;
      }
    }
  });

export default educationModuleReducer;
