import { ActionType, createAction } from 'typesafe-actions';

export const loadersActions = {
  spinner: {
    activate: createAction('@loaders_spinner/ACTIVATE')(),
    disable: createAction('@loaders_spinner/DISABLE')()
  },
  dairyRevenueProtection: {
    activate: createAction('@loaders_dairyRevenueProtection/ACTIVATE')(),
    disable: createAction('@loaders_dairyRevenueProtection/DISABLE')()
  },
  adminAgencies: {
    activate: createAction('@loaders_adminAgencies/ACTIVATE')(),
    disable: createAction('@loaders_adminAgencies/DISABLE')()
  },
  adminAgents: {
    activate: createAction('@loaders_adminAgents/ACTIVATE')(),
    disable: createAction('@loaders_adminAgents/DISABLE')()
  },
  adminAgentsModal: {
    activate: createAction('@loaders_adminAgentsModal/ACTIVATE')(),
    disable: createAction('@loaders_adminAgentsModal/DISABLE')()
  },
  adminOperations: {
    activate: createAction('@loaders_adminOperations/ACTIVATE')(),
    disable: createAction('@loaders_adminOperations/DISABLE')()
  },
  adminProducers: {
    activate: createAction('@loaders_adminProducers/ACTIVATE')(),
    disable: createAction('@loaders_adminProducers/DISABLE')()
  },
  adminProducersModal: {
    activate: createAction('@loaders_adminProducersModal/ACTIVATE')(),
    disable: createAction('@loaders_adminProducersModal/DISABLE')()
  },
  operationList: {
    activate: createAction('@loaders_operationList/ACTIVATE')(),
    disable: createAction('@loaders_operationList/DISABLE')()
  },
  endorsementSummary: {
    activate: createAction('@loaders_endorsementSummary/ACTIVATE')(),
    disable: createAction('@loaders_endorsementSummary/DISABLE')()
  },
  endorsementDetailedView: {
    activate: createAction('@loaders_endorsementDetailedView/ACTIVATE')(),
    disable: createAction('@loaders_endorsementDetailedView/DISABLE')()
  },
  showSalesError: {
    activate: createAction('@loaders_showSalesError/ACTIVATE')(),
    disable: createAction('@loader_showSalesError/DISABLE')()
  },
  accountPreferencesLoader: {
    activate: createAction('@loaders_accountPreferencesLoader/ACTIVATE')(),
    disable: createAction('@loaders_accountPreferencesLoader/DISABLE')()
  },
  operationPreferences: {
    activate: createAction('@loaders_operationPreferencesLoader/ACTIVATE')(),
    disable: createAction('@loaders_operationPreferencesLoader/DISABLE')()
  },
  operationOverviewDataTable: {
    activate: createAction('@loaders_operationOverviewDataTablesLoader/ACTIVATE')(),
    disable: createAction('@loaders_operationOverviewDataTablesLoader/DISABLE')()
  },
  dairyRevenueWhatIf: {
    activate: createAction('@loaders_dairyRevenueWhatIf/ACTIVATE')(),
    disable: createAction('@loaders_dairyRevenueWhatIf/DISABLE')()
  },
  pendingEndorsementTable: {
    activate: createAction('@loaders_pendingEndorsementTable/ACTIVATE')(),
    disable: createAction('@loaders_pendingEndorsementTable/DISABLE')()
  },
  allQuartersInitial: {
    activate: createAction('@loaders_allQuartersInitial/ACTIVATE')(),
    disable: createAction('@loaders_allQuartersInitial/DISABLE')()
  },
  allQuartersUpdate: {
    activate: createAction('@loaders_allQuartersUpdate/ACTIVATE')(),
    disable: createAction('@loaders_allQuartersUpdate/DISABLE')()
  },
  alerts: {
    activate: createAction('@loaders_alerts/ACTIVATE')(),
    disable: createAction('@loaders_alerts/DISABLE')()
  },
  revenueHistoricalChart: {
    activate: createAction('@loaders_revenueHistoricalChart/ACTIVATE')(),
    disable: createAction('@loaders_revenueHistoricalChart/DISABLE')()
  },
  revenueProjection: {
    activate: createAction('@loaders_revenueProjection/ACTIVATE')(),
    disable: createAction('@loaders_revenueProjection/DISABLE')()
  },
  scenarioAnalysis: {
    activate: createAction('@loaders_scenarioAnalysis/ACTIVATE')(),
    disable: createAction('@loaders_scenarioAnalysis/DISABLE')()
  },
  spinnerLrpEndorsementModal: {
    activate: createAction('@loaders_lrpEndorsementModal/ACTIVATE')(),
    disable: createAction('@loaders_lrpEndorsementModal/DISABLE')()
  },
  spinnerCoverageRequestHistory: {
    activate: createAction('@loaders_coverageRequestHistory/ACTIVATE')(),
    disable: createAction('@loaders_coverageRequestHistory/DISABLE')()
  },
  spinnerCoverageRequestFromMail: {
    activate: createAction('@loaders_coverageRequestFromMail/ACTIVATE')(),
    disable: createAction('@loaders_coverageRequestFromMail/DISABLE')()
  }
};

export type LoadersActions = ActionType<typeof loadersActions>;
