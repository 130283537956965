import { AxiosResponse } from 'axios';

import { PriceReport } from '../../../types/PriceReport';
import { CMEPriceReportsDto } from '../../../types/dto/CMEPriceReportsDto';
import { PriceReportsDto, ReportsDto } from '../../../types/dto/PriceReportsDto';
import { formatDateMonth, formatDateYearMonthDay } from '../../../utils/dateFormatter';

const mapValue = (data: PriceReportsDto, key: Exclude<keyof ReportsDto, 'practice'>) =>
  data.quarters ? data.quarters.map(value => value[key]) : [];

const mapPriceReportsDtoToPriceReports = (
  { data: report }: AxiosResponse<PriceReportsDto>,
  { data: cme }: AxiosResponse<CMEPriceReportsDto>
): PriceReport => ({
  salesEffectiveDate: formatDateYearMonthDay(report.date),
  calendarEvent: report.calendarEvent,
  practices: report.quarters
    ? report.quarters.map(
        ({ practice: { startDate, endDate, year } }) =>
          `${formatDateMonth(startDate)}-${formatDateMonth(endDate)}, ${year}`
      )
    : [],
  classIII: mapValue(report, 'classIII'),
  classIV: mapValue(report, 'classIV'),
  butter: mapValue(report, 'butter'),
  cheese: mapValue(report, 'cheese'),
  dryWhey: mapValue(report, 'dryWhey'),
  nonfatDryMilk: mapValue(report, 'nonfatDryMilk'),
  nonfatSolids: mapValue(report, 'nonfatSolids'),
  otherSolids: mapValue(report, 'otherSolids'),
  butterfat: mapValue(report, 'butterfat'),
  protein: mapValue(report, 'protein'),
  yield: mapValue(report, 'yield'),
  cmeBlockCheese: cme.blockCheesePrice,
  cmeBarrelCheese: cme.barrelCheesePrice,
  cmeNonfatDryMilk: cme.nonfatDryMilkPrice,
  cmeButter: cme.butterPrice,
  cmeDryWhey: cme.dryWheyPrice,
  cmeClassIII: cme.classIIIPrice,
  cmeClassIV: cme.classIVPrice
});

export default mapPriceReportsDtoToPriceReports;
