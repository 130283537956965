import OperationContact from '../../types/Operation/OperationContact';
import { OperationContactDto } from '../../types/dto/OperationContactDto';

export const mapOperationContactDtoToOperationContact = (
  data: OperationContactDto[]
): OperationContact[] =>
  data.map(contactDto => ({
    userId: contactDto.user.userId,
    firstName: contactDto.user.firstName,
    lastName: contactDto.user.lastName,
    title: contactDto.user.title,
    phone: contactDto.user.phoneNumber,
    email: contactDto.user.email,
    operationName: contactDto.operationName,
    operationFullName: contactDto.organization ? contactDto.organization.fullName : '',
    sendEndorsementNotification: contactDto.notification,
    primaryContact: contactDto.primaryContact
  }));
