import { useSelector } from 'react-redux';

import { AuthState } from '../ducks/authentication/reducer';
import { getAuthState } from '../ducks/authentication/selectors';

const permissions = {
  agentOrAdmin: [
    AuthState.globalAdministrator,
    AuthState.insuranceAgentAdministrator,
    AuthState.insuranceAgent
  ],
  admins: [AuthState.globalAdministrator, AuthState.insuranceAgentAdministrator],
  agent: [AuthState.insuranceAgent],
  globalAdmin: [AuthState.globalAdministrator],
  agentAdmin: [AuthState.insuranceAgentAdministrator],
  loggedIn: [
    AuthState.globalAdministrator,
    AuthState.insuranceAgentAdministrator,
    AuthState.insuranceAgent,
    AuthState.producer,
    AuthState.expired
  ],
  producer: [AuthState.producer]
};

/**
 * Centralized place to handle permissions
 *
 * @param permissionKey
 * @returns boolean
 *
 * @example
 * const { hasPermission } = usePermission();
 * if (hasPermission('permissionKey')) {...}
 *
 * Adding a permission key to permissions object:
 *
 * const permissions = {
 *  producer: [ AuthState.producer ]
 * }
 */
const usePermission = () => {
  const authState = useSelector(getAuthState);

  const hasPermission = (permissionKey: keyof typeof permissions): boolean => {
    return permissions[permissionKey].includes(authState);
  };

  return { hasPermission };
};

export default usePermission;
