import * as Azure from '@azure/storage-blob';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import EducationVideo from '../../types/EducationModule/EducationVideo';
import EducationVideoAttachment from '../../types/EducationModule/EducationVideoAttachment';
import { educationModuleActions } from './actions';

function* getEducationVideos(action: ActionType<typeof educationModuleActions.getEducationVideos>) {
  yield put(educationModuleActions.setEducationModuleSpinner(true));
  try {
    const agencyName = action.payload;
    if (!agencyName) return;
    const educationVideos: AxiosResponse<EducationVideo[]> = yield call(
      insuranceAPI.getEducationVideos,
      agencyName
    );
    yield put(educationModuleActions.saveEducationVideos(educationVideos.data));
  } catch (e) {
    console.error(e);
    toast.error('Failed to get education videos');
  } finally {
    yield put(educationModuleActions.setEducationModuleSpinner(false));
  }
}

function* deleteEducationVideo(
  action: ActionType<typeof educationModuleActions.deleteEducationVideo>
) {
  try {
    yield call(insuranceAPI.deleteEducationVideo, action.payload);
    yield put(educationModuleActions.removeEducationVideo(action.payload));
  } catch (e) {
    console.error();
    toast.error('Failed to delete video');
  } finally {
    action.meta();
  }
}

function* getEducationVideoRecommendedVideos(
  action: ActionType<typeof educationModuleActions.getEducationVideoRecommendedVideos>
) {
  try {
    const recommendedVideos: AxiosResponse<EducationVideo[]> = yield call(
      insuranceAPI.getRecommendedEducationVideos,
      action.payload
    );
    action.meta(recommendedVideos.data);
  } catch (e) {
    console.error(e);
    toast.error('Failed to get recommended videos');
  }
}

function* editEducationVideoOrder(
  action: ActionType<typeof educationModuleActions.editEducationVideoOrder>
) {
  try {
    const { agencyName, video } = action.payload;
    yield call(insuranceAPI.editEducationVideos, video);
    yield call(getEducationVideos, educationModuleActions.getEducationVideos(agencyName));
    toast.success('Reordered videos');
  } catch (e) {
    console.error(e);
    toast.error('Failed to reorder videos');
  }
}

function* getEducationVideoUrl(
  action: ActionType<typeof educationModuleActions.getEducationVideoUrl>
) {
  try {
    const videoUrl: AxiosResponse<EducationVideo> = yield call(
      insuranceAPI.getEducationVideoUrl,
      action.payload
    );
    action.meta(videoUrl.data.uploadUri);
  } catch (e) {
    console.error(e);
    toast.error('Cannot get video');
  }
}

function* getEducationVideoAttachments(
  action: ActionType<typeof educationModuleActions.getEducationVideoAttachments>
) {
  try {
    const attachments: AxiosResponse<EducationVideoAttachment[]> = yield call(
      insuranceAPI.getEducationVideoAttachments,
      action.payload
    );
    action.meta(attachments.data);
  } catch (e) {
    console.error(e);
    toast.error('Failed to get video attachments');
  }
}

async function storeBlockBlob(blockBlobClient: Azure.BlockBlobClient, file: File) {
  return await blockBlobClient.upload(file, file.size);
}

function* uploadEducationVideo(
  videoFile: File,
  credential: Azure.AnonymousCredential,
  uploadUri: string,
  guid: string
) {
  try {
    const blockBlobClient = new Azure.BlockBlobClient(uploadUri, credential);
    storeBlockBlob(blockBlobClient, videoFile);
    yield call(insuranceAPI.completeUploadEducationVideo, guid);
  } catch (e) {
    console.error(e);
    toast.error('Failed to upload video');
  }
}

function* uploadThumbnail(
  thumbnailFile: File,
  credential: Azure.AnonymousCredential,
  guid: string
): any {
  try {
    const response = yield call(
      insuranceAPI.uploadEducationVideoThumbnail,
      guid,
      thumbnailFile.name
    );
    const blockBlobClient = new Azure.BlockBlobClient(response.data.uploadUri, credential);
    storeBlockBlob(blockBlobClient, thumbnailFile);
    yield call(insuranceAPI.completeUploadEducationVideoThumbnail, response.data.guid);
  } catch (e) {
    console.error(e);
    toast.error('Failed to upload thumbnail');
  }
}

function* uploadAttachment(
  attachment: File,
  credential: Azure.AnonymousCredential,
  guid: string
): any {
  try {
    const response = yield call(insuranceAPI.uploadEducationVideoAttachment, guid, attachment.name);
    const blockBlobClient = new Azure.BlockBlobClient(response.data.uploadUri, credential);
    storeBlockBlob(blockBlobClient, attachment);
    yield call(insuranceAPI.completeUploadEducationVideoAttachment, response.data.guid);
  } catch (e) {
    console.error(e);
    toast.error('Failed to upload attachment');
  }
}

function* createEducationVideo(
  action: ActionType<typeof educationModuleActions.createEducationVideo>
): any {
  const { data, videoFile, thumbnailFile, attachmentList, agencyName } = action.payload;
  try {
    const response = yield call(insuranceAPI.uploadEducationVideo, videoFile.name, data);

    const credential = new Azure.AnonymousCredential();

    const guid = response.data.guid;

    const attachmentCallList = attachmentList?.map(attachment =>
      call(uploadAttachment, attachment, credential, guid)
    );

    yield all([
      call(uploadEducationVideo, videoFile, credential, response.data.uploadUri, guid),
      call(uploadThumbnail, thumbnailFile, credential, guid),
      all(attachmentCallList ? [...attachmentCallList] : [])
    ]);

    put(educationModuleActions.getEducationVideos(agencyName));

    toast.success('Uploaded Education Video');
  } catch (e) {
    console.error(e);
    toast.error('Failed to upload education video');
  } finally {
    yield call(getEducationVideos, educationModuleActions.getEducationVideos(agencyName));
    action.meta();
  }
}

function* deleteEducationVideoThumbnail(guid: string): any {
  try {
    const response = yield call(insuranceAPI.getEducationVideoThumbnail, guid);
    yield call(insuranceAPI.deleteEducationVideoThumbnail, response.data.id);
  } catch (e) {
    console.error('Failed to deleted video thumbnail');
  }
}

function* deleteEducationVideoAttachment(guid: string) {
  try {
    yield call(insuranceAPI.deleteEducationVideoAttachment, guid);
  } catch (e) {
    console.error('Failed to deleted video attachment');
  }
}

function* editEducationVideo(action: ActionType<typeof educationModuleActions.editEducationVideo>) {
  const { video, thumbnailFile, attachments, deletedAttachments, agencyName } = action.payload;
  const credential = new Azure.AnonymousCredential();

  try {
    yield call(insuranceAPI.editEducationVideo, video);
    yield all([
      thumbnailFile ? uploadThumbnail(thumbnailFile, credential, video.id) : null,
      thumbnailFile ? deleteEducationVideoThumbnail(video.id) : null,
      all(
        attachments
          ? attachments.map(attachment => uploadAttachment(attachment, credential, video.id))
          : []
      ),
      all(
        deletedAttachments
          ? deletedAttachments.map(attachment => deleteEducationVideoAttachment(attachment.id))
          : []
      )
    ]);
  } catch (e) {
    console.error(e);
    toast.error('Failed to edit video data');
  } finally {
    action.meta();
  }

  yield call(getEducationVideos, educationModuleActions.getEducationVideos(agencyName));
}

function* educationModuleSagaWatcher() {
  yield all([
    takeLatest(getType(educationModuleActions.getEducationVideos), getEducationVideos),
    takeLatest(getType(educationModuleActions.deleteEducationVideo), deleteEducationVideo),
    takeLatest(
      getType(educationModuleActions.getEducationVideoRecommendedVideos),
      getEducationVideoRecommendedVideos
    ),
    takeLatest(getType(educationModuleActions.editEducationVideoOrder), editEducationVideoOrder),
    takeLatest(getType(educationModuleActions.getEducationVideoUrl), getEducationVideoUrl),
    takeLatest(
      getType(educationModuleActions.getEducationVideoAttachments),
      getEducationVideoAttachments
    ),
    takeLatest(getType(educationModuleActions.createEducationVideo), createEducationVideo),
    takeLatest(getType(educationModuleActions.editEducationVideo), editEducationVideo)
  ]);
}

export default educationModuleSagaWatcher;
