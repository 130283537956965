import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import InsurancePlan, { getInsurancePlanEnum } from '../../types/dto/InsurancePlan';
import { InsurancePlanActions, insurancePlanActions } from './actions';

const insurancePlanLocalStorage = localStorage.getItem('insurancePlan');
const initialInsurancePlan = insurancePlanLocalStorage
  ? getInsurancePlanEnum(insurancePlanLocalStorage)
  : undefined;

const initialState = {
  plan: initialInsurancePlan
};

export const insurancePlanReducer = (state = initialState, action: InsurancePlanActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(insurancePlanActions.setInsurancePlan): {
        if (action.payload) localStorage.setItem('insurancePlan', InsurancePlan[action.payload]);
        draft.plan = action.payload;
        return;
      }
    }
  });
