import axios from 'axios';
import { toast } from 'react-toastify';

export const errorResponseStatusHandler = (
  response: unknown,
  customMessageConfig?: { status: number; message: string },
  ignoreHttpStatus?: [number]
) => {
  console.error('Error', response);

  if (!response) return;

  if (typeof response === 'string') {
    toast.error(response, {
      position: 'top-right',
      type: 'error'
    });
    return;
  }

  let data: any;
  let status: number | undefined;
  if (axios.isAxiosError(response)) {
    data = response.response?.data;
    status = response.response?.status;
  } else if (response && typeof response === 'object' && 'data' in response) {
    data = (response as any).data;
    status = (response as any).status;
  }
  if (status) {
    if (ignoreHttpStatus?.find(x => x === status)) return;

    raiseNotification(
      { status, data },
      customMessageConfig?.status === status ? customMessageConfig.message : undefined
    );
  }
};

const raiseNotification = (
  { status, data }: { status: number; data: any },
  customMessage?: string | undefined
) => {
  switch (status) {
    case 400:
    case 409: {
      if (typeof data === 'object' && 'Definitions' in data) {
        /** If Custom Error Definitions is provided skip notification */
        return;
      }

      const errors = data.errors;
      if (!errors) {
        toast.error(customMessage ?? extractMessage(data, data), {
          position: 'top-right',
          type: 'warning'
        });
      }

      const fromObjectToArray = Object.keys(errors ?? {}).map(key => errors[key]);
      const mergeArraysOfArrays = [].concat(...fromObjectToArray);

      for (const errorMessage of mergeArraysOfArrays) {
        toast.error(errorMessage, {
          position: 'top-right',
          type: 'warning'
        });
      }

      break;
    }
    case 500:
      toast.error(extractMessage(data, customMessage ?? 'Error fetching data.'), {
        position: 'top-right',
        type: 'error'
      });
      break;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const extractMessage = (data: any, fallbackValue: any): any => {
  if (data.detail) return data.detail;
  if (data.title) return data.title;

  return fallbackValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const responseStatusHandler = (response: any) => {
  if (response?.status >= 400) {
    errorResponseStatusHandler(response);
  }

  toast.success('Success!', {
    position: 'top-right',
    type: 'success'
  });
};
