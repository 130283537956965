import { createSelector } from 'reselect';

import SortDirection from '../../enums/SortDirection';
import { commodityTypes } from '../../scenes/common/types/Commodity';
import { Unit } from '../../scenes/lrp/containers/LrpMarketDynamics/PriceTrends/CurrentPricesTable';
import { PriceType } from '../../scenes/lrp/containers/LrpMarketDynamics/PriceTrends/renderHistoricalPricesTable';
import {
  DataPointMonth,
  LrpAnalysisCurrentPriceAverage,
  LrpAnalysisCurrentPriceDifference,
  LrpAnalysisCurrentPricesMonths,
  LrpAnalysisHistoricalPricesData,
  LrpAnalysisPriceTrendsChartData,
  LrpPriceEvolutionAnalysisChartData,
  LrpPriceEvolutionAnalysisHistoricalData,
  LrpPriceEvolutionAnalysisHistoricalHeader
} from '../../scenes/lrp/types/LrpAnalysis';
import { LrpEndorsementDto, LrpSummaryTableData } from '../../scenes/lrp/types/LrpEndorsement';
import {
  LrpCoverageTableDto,
  LrpCoverageTableHistoricalProcessedAll
} from '../../scenes/lrp/types/LrpFiltersDto';
import { getMonthName } from '../../scenes/lrp/utils/getMonthName';
import { Units } from '../../types/Units';
import { addTypeToValues } from '../../utils/addTypeToValues';
import { formatDateYearMonth, formatDateYearMonthDay } from '../../utils/dateFormatter';
import filterObjectsByYear, {
  filterObjectsByYearAnalysis
} from '../../utils/filterGraphDataByYears';
import { Language } from '../../utils/language';
import { monthSelectData } from '../../utils/months';
import { formatNumber } from '../../utils/numberFormatter';
import numberRange from '../../utils/numberRange';
import sortObjectByKey from '../../utils/sortObjectByKey';
import { utcDateToLocalDate } from '../../utils/utcDateToLocalDate';
import {
  getLrpEndorsementSortKeyFromProps,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  getSelectedLrpEndorsementSummaryFromProps,
  getYearsFromProps
} from '../fromPropsSelector';
import { RootState } from '../store';

export const getLrpFilters = (state: RootState) => state.lrp.filters;
export const getLrpFiltersCommodities = (state: RootState) => state.lrp.filtersCommodities;
export const getLrpCoveragePercentage = (state: RootState) => state.lrp.coveragePercentage;
export const getIsFiltersComplete = (state: RootState) => state.lrp.isFiltersComplete;
export const getCoverageTable = (state: RootState) => state.lrp.coverageTable;
export const getLrpEndorsementCalculation = (state: RootState) =>
  state.lrp.lrpEndorsementCalculation;
export const getCoverageTableAllEndorsementLengths = (state: RootState) =>
  state.lrp.coverageTableAllEndorsementLengths;
export const getCoverageTableHistorical = (state: RootState) => state.lrp.coverageTableHistorical;
export const getIsCoverageTableAvailable = (state: RootState) => state.lrp.isCoverageTableAvailable;
export const getIsCoverageTableAllLengthsAvailable = (state: RootState) =>
  state.lrp.isCoverageTableAllLengthsAvailable;
export const getIsCoverageTableHistoricalAvailable = (state: RootState) =>
  state.lrp.isCoverageTableHistoricalAvailable;
export const getQuoterPreferences = (state: RootState) => state.lrp.quoterPreferences;
export const getPricePreferences = (state: RootState) => state.lrp.pricePreferences;
export const getLrpEndorsements = (state: RootState) => state.lrp.lrpEndorsements;

export const getLrpEndorsementFilter = (state: RootState) => state.lrp.LrpEndorsementFilter;
export const getLrpAnalysisCurrentPrices = (state: RootState) => state.lrp.lrpAnalysisCurrentPrices;
export const getLrpAnalysisHistoricalPrices = (state: RootState) =>
  state.lrp.lrpAnalysisHistoricalPrices;
export const getOperationProducers = (state: RootState) => state.lrp.operationProducer;
export const getLrpPriceEvolutionAnalysis = (state: RootState) =>
  state.lrp.lrpPriceEvolutionAnalysis;

export const getLrpEndorsementSummaryTable = (state: RootState) =>
  (state.lrp.lrpEndorsementSummaryTable ?? []).map<LrpSummaryTableData>(summary => ({
    ...summary,
    netCwt: summary.forecastedIndemnityCwt - summary.producerPremiumCwt,
    netHead: summary.forecastedIndemnityHead - summary.producerPremiumHead,
    netTotal: summary.forecastedIndemnity - summary.premiumProducer
  }));

export const getLrpEndorsementsSorted = createSelector(
  getLrpEndorsements,
  getLrpEndorsementSortKeyFromProps,
  getSelectedLrpEndorsementSummaryFromProps,
  (endorsements, sortProps, selectedRow) => {
    let data = [...endorsements];
    const { sortKey, direction } = sortProps;
    const { year, month, commodity } = selectedRow;

    data = data.filter(element => {
      const selectedDate = element.endDate.split('-');
      const selectedYear = selectedDate[0];
      const selectedMonth = selectedDate[1];
      return (
        element.commodity === commodity &&
        selectedYear === year.toString() &&
        selectedMonth === month.toString().padStart(2, '0')
      );
    });

    const sortObjectByTypeCode = () => (a: LrpEndorsementDto, b: LrpEndorsementDto) => {
      const optionA = commodityTypes.find(o => o.label === a.typeCode);
      const optionB = commodityTypes.find(o => o.label === b.typeCode);
      const firstValue: any = optionA?.name;
      const secondValue: any = optionB?.name;

      if (direction === SortDirection.ASCENDING) {
        if (firstValue < secondValue) {
          return -1;
        }
        if (firstValue > secondValue) {
          return 1;
        }
        return 0;
      } else {
        if (secondValue < firstValue) {
          return -1;
        }
        if (secondValue > firstValue) {
          return 1;
        }
        return 0;
      }
    };

    if (sortKey === 'typeCode') {
      data.sort(sortObjectByTypeCode());
    } else {
      data.sort(sortObjectByKey(sortKey, direction));
    }

    return data;
  }
);

export const getCoverageTableOrdered = createSelector(getCoverageTable, coverageTable => {
  const sortKey: keyof LrpCoverageTableDto = 'coveragePercent';

  const coverageTableProcessed = [...coverageTable]
    .sort(sortObjectByKey(sortKey, SortDirection.DESCENDING))
    .map(e => ({
      ...e,
      coveragePercent:
        e.coveragePercent !== undefined
          ? `${formatNumber(2)(e.coveragePercent, Units.PERCENT)}`
          : '',
      coveragePriceCwt: formatNumber(2)(e.coveragePriceCwt, Units.DOLLAR),
      coveragePriceHead: formatNumber(2)(e.coveragePriceHead, Units.DOLLAR),
      insuredValueTotal: formatNumber(0)(e.liability, Units.DOLLAR),
      producerPremiumTotal: formatNumber(0)(e.producerPremium, Units.DOLLAR),
      producerPremiumCwt: formatNumber(2)(e.producerPremiumCwt, Units.DOLLAR),
      producerPremiumHead: formatNumber(2)(e.producerPremiumHead, Units.DOLLAR),
      netGuaranteeTotal: formatNumber(0)(e.netGuarantee, Units.DOLLAR),
      netGuaranteeHead: formatNumber(2)(e.netGuaranteeHead, Units.DOLLAR),
      netGuaranteeCwt: formatNumber(2)(e.netGuaranteeCwt, Units.DOLLAR),
      cmePutComparisonStrikePrice: formatNumber(0)(e.cmePutComparisonStrikePrice, Units.DOLLAR),

      cmePutPremiumPrice: formatNumber(2)(e.cmePutPremiumPrice, Units.DOLLAR),
      lrpPremium: formatNumber(0)(e.lrpPremium / 100, Units.PERCENT),
      totalPremiumTotal: formatNumber(0)(e.totalPremium, Units.DOLLAR),
      totalPremiumCwt: formatNumber(2)(e.totalPremiumCwt, Units.DOLLAR),
      totalPremiumHead: formatNumber(2)(e.totalPremiumHead, Units.DOLLAR),
      subsidyAmountTotal: formatNumber(0)(e.subsidyAmount, Units.DOLLAR),
      subsidyAmountCwt: formatNumber(2)(e.subsidyAmountCwt, Units.DOLLAR),
      subsidyAmountHead: formatNumber(2)(e.subsidyAmountHead, Units.DOLLAR),
      expectedPriceTotal: formatNumber(0)(e.expectedPrice, Units.DOLLAR),
      expectedPriceCwt: formatNumber(2)(e.expectedPriceCwt, Units.DOLLAR),
      expectedPriceHead: formatNumber(2)(e.expectedPriceHead, Units.DOLLAR),
      coverageMinusFuturesPrice: formatNumber(2)(e.coverageMinusFuturesPriceCwt, Units.DOLLAR),
      futuresPrice: formatNumber(2)(e.futuresPriceCwt, Units.DOLLAR),
      comparisonMonth: formatDateYearMonth(e.comparisonMonth)
    }));
  return coverageTableProcessed;
});

export const getCoverageTableAllEndorsementLengthsProcessed = createSelector(
  getCoverageTableAllEndorsementLengths,
  coverageTable => {
    const coverageTableProcessed = coverageTable.map(e => ({
      ...e,
      endorsementLengthCount:
        e.endorsementLength !== undefined ? e.endorsementLength + ' Weeks' : '',
      endDate: formatDateYearMonthDay(e.endDate),
      totalPremiumTotal: formatNumber(0)(e.totalPremium, Units.DOLLAR),
      totalPremiumCwt: formatNumber(2)(e.totalPremiumCwt, Units.DOLLAR),
      totalPremiumHead: formatNumber(2)(e.totalPremiumHead, Units.DOLLAR),

      subsidyAmountTotal: formatNumber(0)(e.subsidyAmount, Units.DOLLAR),

      subsidyAmountCwt: formatNumber(2)(e.subsidyAmountCwt, Units.DOLLAR),

      subsidyAmountHead: formatNumber(2)(e.subsidyAmountHead, Units.DOLLAR),
      expectedPriceTotal: formatNumber(0)(e.expectedPrice, Units.DOLLAR),
      expectedPriceCwt: formatNumber(2)(e.expectedPriceCwt, Units.DOLLAR),
      expectedPriceHead: formatNumber(2)(e.expectedPriceHead, Units.DOLLAR),
      coverageMinusFuturesPrice: formatNumber(2)(e.coverageMinusFuturesPriceCwt, Units.DOLLAR),
      futuresPrice: formatNumber(2)(e.futuresPriceCwt, Units.DOLLAR),
      comparisonMonth: formatDateYearMonth(e.comparisonMonth),
      coveragePercent: formatNumber(2)(e.coveragePercent, Units.PERCENT),

      coveragePriceTotal: formatNumber(2)(e.coveragePrice, Units.DOLLAR),

      coveragePriceCwt: formatNumber(2)(e.coveragePriceCwt, Units.DOLLAR),

      coveragePriceHead: formatNumber(2)(e.coveragePriceHead, Units.DOLLAR),
      insuredValueTotal: formatNumber(0)(e.liability, Units.DOLLAR),
      producerPremiumTotal: formatNumber(0)(e.producerPremium, Units.DOLLAR),
      producerPremiumCwt: formatNumber(2)(e.producerPremiumCwt, Units.DOLLAR),
      producerPremiumHead: formatNumber(2)(e.producerPremiumHead, Units.DOLLAR),
      netGuaranteeTotal: formatNumber(0)(e.netGuarantee, Units.DOLLAR),
      netGuaranteeHead: formatNumber(2)(e.netGuaranteeHead, Units.DOLLAR),
      netGuaranteeCwt: formatNumber(2)(e.netGuaranteeCwt, Units.DOLLAR),
      cmePutComparisonStrikePrice: formatNumber(0)(e.cmePutComparisonStrikePrice, Units.DOLLAR),
      cmePutPremiumPrice: formatNumber(2)(e.cmePutPremiumPrice, Units.DOLLAR),
      lrpPremium: `${formatNumber(0)(e.lrpPremium / 100, Units.PERCENT)}`
    }));

    return coverageTableProcessed;
  }
);

export const getCoverageTableHistoricaProcessed = createSelector(
  getCoverageTableHistorical,
  coverageTable => {
    const yearsAverageEnd = (
      coverageTable.historicDataAvg.salesEffectiveDate.getFullYear() - 1
    ).toString();
    const yearsAverageStart = Math.min(
      ...coverageTable.historicData.map(e => new Date(e.salesEffectiveDate).getFullYear())
    ).toString();

    const coverageTableProcessed: LrpCoverageTableHistoricalProcessedAll = {
      historicData: coverageTable.historicData.map(e => ({
        salesEffectiveDate: formatDateYearMonthDay(e.salesEffectiveDate),
        endDate: formatDateYearMonthDay(e.endDate),
        expectedPriceTotal: formatNumber(0)(e.expectedPriceTotal, Units.DOLLAR),
        expectedPriceCwt: formatNumber(2)(e.expectedPriceCwt, Units.DOLLAR),
        expectedPriceHead: formatNumber(2)(e.expectedPriceHead, Units.DOLLAR),
        coveragePercent: formatNumber(2)(e.coveragePercent, Units.PERCENT),
        coveragePriceCwt: formatNumber(2)(e.coveragePriceCwt, Units.DOLLAR),
        coveragePriceHead: formatNumber(2)(e.coveragePriceHead, Units.DOLLAR),
        insuredValueTotal: formatNumber(0)(e.insuredValueTotal, Units.DOLLAR),
        producerPremiumTotal: formatNumber(0)(e.producerPremiumTotal, Units.DOLLAR),
        producerPremiumCwt: formatNumber(2)(e.producerPremiumCwt, Units.DOLLAR),
        producerPremiumHead: formatNumber(2)(e.producerPremiumHead, Units.DOLLAR),
        netGuaranteeTotal: formatNumber(0)(e.netGuaranteeTotal, Units.DOLLAR),
        netGuaranteeHead: formatNumber(2)(e.netGuaranteeHead, Units.DOLLAR),
        netGuaranteeCwt: formatNumber(2)(e.netGuaranteeCwt, Units.DOLLAR),
        actualEndingValueCwt: formatNumber(2)(e.actualEndingValueCwt, Units.DOLLAR),
        actualEndingValueHead: formatNumber(2)(e.actualEndingValueHead, Units.DOLLAR),
        actualEndingValueTotal: formatNumber(0)(e.actualEndingValueTotal, Units.DOLLAR),
        grossIndemnityCwt: formatNumber(2)(e.grossIndemnityCwt, Units.DOLLAR),
        grossIndemnityHead: formatNumber(2)(e.grossIndemnityHead, Units.DOLLAR),
        grossIndemnityTotal: formatNumber(0)(e.grossIndemnityTotal, Units.DOLLAR)
      })),
      historicDataAvg: {
        salesEffectiveDate:
          coverageTable.historicDataAvg.salesEffectiveDate !== undefined
            ? yearsAverageStart + '-' + yearsAverageEnd + ' Avg'
            : '',
        expectedPriceTotal: formatNumber(0)(
          coverageTable.historicDataAvg.expectedPriceTotal,
          Units.DOLLAR
        ),
        expectedPriceCwt: formatNumber(2)(
          coverageTable.historicDataAvg.expectedPriceCwt,
          Units.DOLLAR
        ),
        expectedPriceHead: formatNumber(2)(
          coverageTable.historicDataAvg.expectedPriceHead,
          Units.DOLLAR
        ),
        coveragePercent: formatNumber(2)(
          coverageTable.historicDataAvg.coveragePercent,
          Units.PERCENT
        ),
        coveragePriceCwt: formatNumber(2)(
          coverageTable.historicDataAvg.coveragePriceCwt,
          Units.DOLLAR
        ),
        coveragePriceHead: formatNumber(2)(
          coverageTable.historicDataAvg.coveragePriceHead,
          Units.DOLLAR
        ),
        insuredValueTotal: formatNumber(0)(
          coverageTable.historicDataAvg.insuredValueTotal,
          Units.DOLLAR
        ),
        producerPremiumTotal: formatNumber(0)(
          coverageTable.historicDataAvg.producerPremiumTotal,
          Units.DOLLAR
        ),
        producerPremiumCwt: formatNumber(2)(
          coverageTable.historicDataAvg.producerPremiumCwt,
          Units.DOLLAR
        ),
        producerPremiumHead: formatNumber(2)(
          coverageTable.historicDataAvg.producerPremiumHead,
          Units.DOLLAR
        ),
        netGuaranteeTotal: formatNumber(0)(
          coverageTable.historicDataAvg.netGuaranteeTotal,
          Units.DOLLAR
        ),
        netGuaranteeHead: formatNumber(2)(
          coverageTable.historicDataAvg.netGuaranteeHead,
          Units.DOLLAR
        ),
        netGuaranteeCwt: formatNumber(2)(
          coverageTable.historicDataAvg.netGuaranteeCwt,
          Units.DOLLAR
        ),
        actualEndingValueCwt: formatNumber(2)(
          coverageTable.historicDataAvg.actualEndingValueCwt,
          Units.DOLLAR
        ),
        actualEndingValueHead: formatNumber(2)(
          coverageTable.historicDataAvg.actualEndingValueHead,
          Units.DOLLAR
        ),

        actualEndingValueTotal: formatNumber(0)(
          coverageTable.historicDataAvg.actualEndingValueTotal,
          Units.DOLLAR
        ),

        grossIndemnityCwt: formatNumber(2)(
          coverageTable.historicDataAvg.grossIndemnityCwt,
          Units.DOLLAR
        ),
        grossIndemnityHead: formatNumber(2)(
          coverageTable.historicDataAvg.grossIndemnityHead,
          Units.DOLLAR
        ),
        grossIndemnityTotal: formatNumber(0)(
          coverageTable.historicDataAvg.grossIndemnityTotal,
          Units.DOLLAR
        )
      },
      historicDataAvgYear5: {
        salesEffectiveDate:
          coverageTable.historicDataAvgYear5.salesEffectiveDate !== undefined ? '5-Year Avg' : '',
        expectedPriceTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.expectedPriceTotal,
          Units.DOLLAR
        ),
        expectedPriceCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.expectedPriceCwt,
          Units.DOLLAR
        ),
        expectedPriceHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.expectedPriceHead,
          Units.DOLLAR
        ),
        coveragePercent: `${formatNumber(2)(
          coverageTable.historicDataAvgYear5.coveragePercent,
          Units.PERCENT
        )}`,
        coveragePriceCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.coveragePriceCwt,
          Units.DOLLAR
        ),
        coveragePriceHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.coveragePriceHead,
          Units.DOLLAR
        ),
        insuredValueTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.insuredValueTotal,
          Units.DOLLAR
        ),

        producerPremiumTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.producerPremiumTotal,
          Units.DOLLAR
        ),
        producerPremiumCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.producerPremiumCwt,
          Units.DOLLAR
        ),
        producerPremiumHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.producerPremiumHead,
          Units.DOLLAR
        ),
        netGuaranteeTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.netGuaranteeTotal,
          Units.DOLLAR
        ),
        netGuaranteeHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.netGuaranteeHead,
          Units.DOLLAR
        ),
        netGuaranteeCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.netGuaranteeCwt,
          Units.DOLLAR
        ),

        actualEndingValueCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.actualEndingValueCwt,
          Units.DOLLAR
        ),

        actualEndingValueHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.actualEndingValueHead,
          Units.DOLLAR
        ),

        actualEndingValueTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.actualEndingValueTotal,
          Units.DOLLAR
        ),
        grossIndemnityCwt: formatNumber(2)(
          coverageTable.historicDataAvgYear5.grossIndemnityCwt,
          Units.DOLLAR
        ),
        grossIndemnityHead: formatNumber(2)(
          coverageTable.historicDataAvgYear5.grossIndemnityHead,
          Units.DOLLAR
        ),
        grossIndemnityTotal: formatNumber(0)(
          coverageTable.historicDataAvgYear5.grossIndemnityTotal,
          Units.DOLLAR
        )
      }
    };

    return coverageTableProcessed;
  }
);

export const getLrpAnalysisCurrentPricesTableData = createSelector(
  getLrpAnalysisCurrentPrices,
  getLrpAnalysisHistoricalPrices,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  (currentPrices, historicalPrices, measure, unit) => {
    const lrpAnalysisCurrentPricesDataMonths: LrpAnalysisCurrentPricesMonths[] = [];
    const lrpAnalysisCurrentPricesDataAverage: LrpAnalysisCurrentPriceAverage[] = [];
    const lrpAnalysisCurrentPricesDataDifference: LrpAnalysisCurrentPriceDifference[] = [];

    const yearFrom = historicalPrices.yearFrom.toString();
    const yearTo = historicalPrices.yearTo.toString();
    const yearInterval = yearFrom + ' - ' + yearTo;

    const currentPricesObjDefaultValues = {
      endDate: undefined,
      expectedPrice: undefined,
      coveragePrice: undefined,
      producerPremium: undefined,
      netGuarantee: undefined
    };

    const currentPricesObjMonths: {
      [key: number]: {
        month: number;
        endDate: Date | undefined;
        expectedPrice: number | undefined;
        coveragePrice: number | undefined;
        producerPremium: number | undefined;
        netGuarantee: number | undefined;
      };
    } = {};

    const currentPricesObjAverage: {
      [key: string]: {
        month: number;
        value: number | undefined;
      };
    } = {};

    const currentPricesObjDifference: {
      [key: string]: {
        month: number;
        value: number | undefined;
      };
    } = {};

    currentPrices.months.forEach(currentPrice => {
      const dateKey = currentPrice.month;

      currentPricesObjMonths[dateKey] = {
        month: dateKey,
        ...currentPricesObjDefaultValues
      };

      currentPricesObjMonths[dateKey].endDate = currentPrice.endDate;
      if (unit === Unit.CWT) {
        currentPricesObjMonths[dateKey].expectedPrice = currentPrice.expectedPriceCwt;
        currentPricesObjMonths[dateKey].coveragePrice = currentPrice.coveragePriceCwt;
        currentPricesObjMonths[dateKey].producerPremium = currentPrice.producerPremiumCwt;
        currentPricesObjMonths[dateKey].netGuarantee = currentPrice.netGuaranteeCwt;
      } else if (unit === Unit.HEAD) {
        currentPricesObjMonths[dateKey].expectedPrice = currentPrice.expectedPriceHead;
        currentPricesObjMonths[dateKey].coveragePrice = currentPrice.coveragePriceHead;
        currentPricesObjMonths[dateKey].producerPremium = currentPrice.producerPremiumHead;
        currentPricesObjMonths[dateKey].netGuarantee = currentPrice.netGuaranteeHead;
      } else {
        currentPricesObjMonths[dateKey].expectedPrice = currentPrice.expectedPrice;
        currentPricesObjMonths[dateKey].coveragePrice = currentPrice.coveragePrice;
        currentPricesObjMonths[dateKey].producerPremium = currentPrice.producerPremium;
        currentPricesObjMonths[dateKey].netGuarantee = currentPrice.netGuarantee;
      }
    });

    numberRange(1, 13).forEach(monthIncrement => {
      const date = monthIncrement;
      const dateKey = Number(date);

      if (!currentPricesObjMonths[dateKey]) {
        currentPricesObjMonths[dateKey] = {
          month: dateKey,
          ...currentPricesObjDefaultValues
        };
      }

      lrpAnalysisCurrentPricesDataMonths.push(currentPricesObjMonths[dateKey]);
    });

    historicalPrices.average.forEach(currentPrice => {
      const dateKey = currentPrice.month;

      currentPricesObjAverage[dateKey] = {
        month: dateKey,
        value: undefined
      };

      if (unit === Unit.CWT) {
        if (measure === 'actualPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.actualPriceCwt;
        } else if (measure === 'expectedPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.expectedPriceCwt;
        } else if (measure === 'producerPremium') {
          currentPricesObjAverage[dateKey].value = currentPrice.producerPremiumCwt;
        } else if (measure === 'netGuarantee') {
          currentPricesObjAverage[dateKey].value = currentPrice.netGuaranteeCwt;
        } else if (measure === 'coveragePrice') {
          currentPricesObjAverage[dateKey].value = currentPrice.coveragePriceCwt;
        } else {
          currentPricesObjAverage[dateKey].value = currentPrice.grossIndemnityCwt;
        }
      } else if (unit === Unit.HEAD) {
        if (measure === 'actualPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.actualPriceHead;
        } else if (measure === 'expectedPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.expectedPriceHead;
        } else if (measure === 'producerPremium') {
          currentPricesObjAverage[dateKey].value = currentPrice.producerPremiumHead;
        } else if (measure === 'netGuarantee') {
          currentPricesObjAverage[dateKey].value = currentPrice.netGuaranteeHead;
        } else if (measure === 'coveragePrice') {
          currentPricesObjAverage[dateKey].value = currentPrice.coveragePriceHead;
        } else {
          currentPricesObjAverage[dateKey].value = currentPrice.grossIndemnityHead;
        }
      } else {
        if (measure === 'actualPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.actualPrice;
        } else if (measure === 'expectedPrices') {
          currentPricesObjAverage[dateKey].value = currentPrice.expectedPrice;
        } else if (measure === 'producerPremium') {
          currentPricesObjAverage[dateKey].value = currentPrice.producerPremium;
        } else if (measure === 'netGuarantee') {
          currentPricesObjAverage[dateKey].value = currentPrice.netGuarantee;
        } else if (measure === 'coveragePrice') {
          currentPricesObjAverage[dateKey].value = currentPrice.coveragePrice;
        } else {
          currentPricesObjAverage[dateKey].value = currentPrice.grossIndemnity;
        }
      }
    });

    numberRange(1, 13).forEach(monthIncrement => {
      const date = monthIncrement;
      const dateKey = Number(date);

      if (!currentPricesObjAverage[dateKey]) {
        currentPricesObjAverage[dateKey] = {
          month: dateKey,
          value: undefined
        };
      }

      lrpAnalysisCurrentPricesDataAverage.push(currentPricesObjAverage[dateKey]);
    });

    lrpAnalysisCurrentPricesDataAverage.forEach((element1, index) => {
      const date = element1.month;
      const dateKey = Number(date);

      currentPricesObjDifference[dateKey] = {
        month: dateKey,
        value: undefined
      };

      if (measure === 'actualPrices') {
        currentPricesObjDifference[dateKey].value = 0;
      } else if (measure === 'expectedPrices') {
        const element2 = lrpAnalysisCurrentPricesDataMonths[index].expectedPrice;
        const difference = Number(element2) - Number(element1.value);
        currentPricesObjDifference[dateKey].value = difference ?? 0;
      } else if (measure === 'producerPremium') {
        const element2 = lrpAnalysisCurrentPricesDataMonths[index].producerPremium;
        const difference = Number(element2) - Number(element1.value);
        currentPricesObjDifference[dateKey].value = difference ?? 0;
      } else if (measure === 'netGuarantee') {
        const element2 = lrpAnalysisCurrentPricesDataMonths[index].netGuarantee;
        const difference = Number(element2) - Number(element1.value);
        currentPricesObjDifference[dateKey].value = difference ?? 0;
      } else if (measure === 'coveragePrice') {
        const element2 = lrpAnalysisCurrentPricesDataMonths[index].coveragePrice;
        const difference = Number(element2) - Number(element1.value);
        currentPricesObjDifference[dateKey].value = difference ?? 0;
      } else {
        currentPricesObjDifference[dateKey].value = 0;
      }

      if (!currentPricesObjDifference[dateKey]) {
        currentPricesObjDifference[dateKey] = {
          month: dateKey,
          value: undefined
        };
      }

      lrpAnalysisCurrentPricesDataDifference.push(currentPricesObjDifference[dateKey]);
    });

    return {
      months: lrpAnalysisCurrentPricesDataMonths,
      average: lrpAnalysisCurrentPricesDataAverage,
      difference: lrpAnalysisCurrentPricesDataDifference,
      yearInterval: yearInterval
    };
  }
);

export const getLrpAnalysisHistoricalPricesTableData = createSelector(
  getLrpAnalysisHistoricalPrices,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  (historicalPrices, measure, unit) => {
    if (!historicalPrices || historicalPrices.years.length < 1) {
      return [];
    }

    const lrpAnalysisHistoricalPricesData: LrpAnalysisHistoricalPricesData[] = [];
    interface monthObject {
      [key: number]: { price: number | undefined; type: PriceType };
    }

    historicalPrices.years.forEach(historicalPrice => {
      const dateKey = historicalPrice.year;
      const monthObject: monthObject = {};
      numberRange(1, 13).forEach(monthIncrement => {
        const foundMonth = historicalPrice.months.find(month => month.month === monthIncrement);
        if (unit === Unit.CWT) {
          if (measure === 'actualPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.actualPriceCwt : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'expectedPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.expectedPriceCwt : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'producerPremium') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.producerPremiumCwt : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'netGuarantee') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.netGuaranteeCwt : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'coveragePrice') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.coveragePriceCwt : undefined,
              type: PriceType.NEUTRAL
            };
          } else {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.grossIndemnityCwt : undefined,
              type: PriceType.NEUTRAL
            };
          }
        } else if (unit === Unit.HEAD) {
          if (measure === 'actualPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.actualPriceHead : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'expectedPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.expectedPriceHead : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'producerPremium') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.producerPremiumHead : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'netGuarantee') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.netGuaranteeHead : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'coveragePrice') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.coveragePriceHead : undefined,
              type: PriceType.NEUTRAL
            };
          } else {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.grossIndemnityHead : undefined,
              type: PriceType.NEUTRAL
            };
          }
        } else {
          if (measure === 'actualPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.actualPrice : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'expectedPrices') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.expectedPrice : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'producerPremium') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.producerPremium : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'netGuarantee') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.netGuarantee : undefined,
              type: PriceType.NEUTRAL
            };
          } else if (measure === 'coveragePrice') {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.coveragePrice : undefined,
              type: PriceType.NEUTRAL
            };
          } else {
            monthObject[monthIncrement] = {
              price: foundMonth ? foundMonth.grossIndemnity : undefined,
              type: PriceType.NEUTRAL
            };
          }
        }
      });

      lrpAnalysisHistoricalPricesData.push({ year: dateKey, ...monthObject });
    });

    addTypeToValues(lrpAnalysisHistoricalPricesData);
    return lrpAnalysisHistoricalPricesData;
  }
);

const getDateFormatted = (dateString: Date) => {
  const startYearKey = dateString.toString().split('-')[0];
  const startMonthKey = dateString.toString().split('-')[1];
  const dateKey =
    monthSelectData.find(m => m.id === Number(startMonthKey) - 1)?.name + ', ' + startYearKey;
  return dateKey;
};

const getNameFormatted = (dateString: Date) => {
  const startYearKey = dateString.toString().split('-')[0];
  const startMonthKey = dateString.toString().split('-')[1];
  const monthName = monthSelectData
    .find(m => m.id === Number(startMonthKey) - 1)
    ?.name?.substring(0, 3);
  const dateKey = monthName + ',' + startYearKey;
  return dateKey;
};

const getNameFormattedForAverage = (dateString: string) => {
  const monthName = monthSelectData
    .find(m => m.id === Number(dateString) - 1)
    ?.name?.substring(0, 3);
  const dateKey = monthName + ',';
  return dateKey;
};

const getCurrentPriceValue = (monthObject: any, measure: string, unit: number) => {
  if (!monthObject) {
    return 0;
  } else {
    if (unit === Unit.CWT) {
      if (measure === 'actualPrices') {
        return monthObject.actualPriceCwt;
      } else if (measure === 'expectedPrices') {
        return monthObject.expectedPriceCwt;
      } else if (measure === 'producerPremium') {
        return monthObject.producerPremiumCwt;
      } else if (measure === 'netGuarantee') {
        return monthObject.netGuaranteeCwt;
      } else if (measure === 'coveragePrice') {
        return monthObject.coveragePriceCwt;
      } else {
        return monthObject.grossIndemnityCwt;
      }
    } else if (unit === Unit.HEAD) {
      if (measure === 'actualPrices') {
        return monthObject.actualPriceHead;
      } else if (measure === 'expectedPrices') {
        return monthObject.expectedPriceHead;
      } else if (measure === 'producerPremium') {
        return monthObject.producerPremiumHead;
      } else if (measure === 'netGuarantee') {
        return monthObject.netGuaranteeHead;
      } else if (measure === 'coveragePrice') {
        return monthObject.coveragePriceHead;
      } else {
        return monthObject.grossIndemnityHead;
      }
    } else if (unit === Unit.TOTAL) {
      if (measure === 'actualPrices') {
        return monthObject.actualPrice;
      } else if (measure === 'expectedPrices') {
        return monthObject.expectedPrice;
      } else if (measure === 'producerPremium') {
        return monthObject.producerPremium;
      } else if (measure === 'netGuarantee') {
        return monthObject.netGuarantee;
      } else if (measure === 'coveragePrice') {
        return monthObject.coveragePrice;
      } else {
        return monthObject.grossIndemnity;
      }
    }
  }
};

const getHistoricalPriceValue = (averageObject: any, measure: string, unit: number) => {
  if (!averageObject) {
    return 0;
  } else {
    if (unit === Unit.CWT) {
      if (measure === 'actualPrices') {
        return averageObject.actualPriceCwt;
      } else if (measure === 'expectedPrices') {
        return averageObject.expectedPriceCwt;
      } else if (measure === 'producerPremium') {
        return averageObject.producerPremiumCwt;
      } else if (measure === 'netGuarantee') {
        return averageObject.netGuaranteeCwt;
      } else if (measure === 'coveragePrice') {
        return averageObject.coveragePriceCwt;
      } else {
        return averageObject.grossIndemnityCwt;
      }
    } else if (unit === Unit.HEAD) {
      if (measure === 'actualPrices') {
        return averageObject.actualPriceHead;
      } else if (measure === 'expectedPrices') {
        return averageObject.expectedPriceHead;
      } else if (measure === 'producerPremium') {
        return averageObject.producerPremiumHead;
      } else if (measure === 'netGuarantee') {
        return averageObject.netGuaranteeHead;
      } else if (measure === 'coveragePrice') {
        return averageObject.coveragePriceHead;
      } else {
        return averageObject.grossIndemnityHead;
      }
    } else if (unit === Unit.TOTAL) {
      if (measure === 'actualPrices') {
        return averageObject.actualPrice;
      } else if (measure === 'expectedPrices') {
        return averageObject.expectedPrice;
      } else if (measure === 'producerPremium') {
        return averageObject.producerPremium;
      } else if (measure === 'netGuarantee') {
        return averageObject.netGuarantee;
      } else if (measure === 'coveragePrice') {
        return averageObject.coveragePrice;
      } else {
        return averageObject.grossIndemnity;
      }
    }
  }
};

export const getLrpAnalysisPriceTrendsChartData = createSelector(
  getLrpAnalysisCurrentPrices,
  getLrpAnalysisHistoricalPrices,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  getYearsFromProps,
  (currentPrices, historicalPrices, measure, unit, years) => {
    const yearFrom = historicalPrices.yearFrom.toString();
    const yearTo = historicalPrices.yearTo.toString();
    const yearInterval = yearFrom + ' - ' + yearTo;

    const lrpAnalysisPriceTrendsChartData: LrpAnalysisPriceTrendsChartData[] = [];
    const averageSorted = [...historicalPrices.average];
    const monthsSorted = [...currentPrices.months];

    averageSorted.sort(sortObjectByKey('endDate', SortDirection.ASCENDING));
    monthsSorted.sort(sortObjectByKey('endDate', SortDirection.ASCENDING));

    const currentPricesSorted = {
      ...currentPrices,
      average: { average: averageSorted },
      months: monthsSorted
    };

    if (!currentPricesSorted.months.length) {
      numberRange(1, 13).forEach(monthIncrement => {
        const currentMonth = monthIncrement;

        const averageObject = currentPricesSorted.average.average.find(
          month => month.month === currentMonth
        );

        if (!averageObject) {
          lrpAnalysisPriceTrendsChartData.push({
            name: getNameFormattedForAverage(currentMonth.toString()),
            endDate: '',
            endMonth: '',
            measure: '',
            yearInterval: '',
            currentPrice: undefined,
            historicalPrice: undefined
          });
        } else {
          const historicalPrice = getHistoricalPriceValue(averageObject, measure, unit);
          lrpAnalysisPriceTrendsChartData.push({
            name: getNameFormattedForAverage(currentMonth.toString()),
            endDate: '',
            endMonth: currentMonth.toString(),
            measure: measure,
            yearInterval: yearInterval,
            currentPrice: undefined,
            historicalPrice: historicalPrice !== 0 ? historicalPrice : undefined
          });
        }
      });

      return { data: lrpAnalysisPriceTrendsChartData, yearInterval: yearInterval };
    }

    const startYear = Number(currentPricesSorted.months[0].endDate.toString().split('-')[0]);
    const startMonth = Number(currentPricesSorted.months[0].endDate.toString().split('-')[1]);

    const selectedYearsToDisplay = filterObjectsByYear({
      historicalData: historicalPrices,
      yearsRange: years
    });

    numberRange(0, 12).forEach(monthIncrement => {
      const currentMonth =
        startMonth + monthIncrement <= 12
          ? startMonth + monthIncrement
          : monthIncrement - (12 - startMonth);
      const currentyear = 12 - startMonth >= monthIncrement ? startYear : startYear + 1;

      const monthObject = currentPricesSorted.months.find(
        month =>
          month.month === currentMonth &&
          month.endDate.toString().split('-')[0] === currentyear.toString()
      );
      const averageObject = currentPricesSorted.average.average.find(
        month => month.month === currentMonth
      );

      const customValues: Record<string, number> = {};
      selectedYearsToDisplay.forEach(year => {
        const sortedMonths = [...year.months].sort((a, b) => a.month - b.month);
        const currentPriceValue = getCurrentPriceValue(
          sortedMonths[currentMonth - 1],
          measure,
          unit
        );
        customValues[year.year + 'custom'] =
          currentPriceValue === 0 ? undefined : currentPriceValue;
      });

      if (!monthObject) {
        if (!averageObject) {
          lrpAnalysisPriceTrendsChartData.push({
            name: '',
            endDate: '',
            endMonth: '',
            measure: '',
            yearInterval: '',
            currentPrice: undefined,
            historicalPrice: undefined,
            ...customValues
          });
        } else {
          const historicalPrice = getHistoricalPriceValue(averageObject, measure, unit);
          const currentName =
            monthSelectData.find(m => m.id === Number(currentMonth) - 1)?.name?.substring(0, 3) +
            ',' +
            currentyear;
          const currentDate =
            monthSelectData.find(m => m.id === Number(currentMonth) - 1)?.name + ', ' + currentyear;

          lrpAnalysisPriceTrendsChartData.push({
            name: currentName,
            endDate: currentDate,
            endMonth: currentMonth.toString() + '-' + currentyear.toString(),
            measure: measure,
            yearInterval: yearInterval,
            currentPrice: undefined,
            historicalPrice: historicalPrice !== 0 ? historicalPrice : undefined,
            ...customValues
          });
        }
      } else {
        const historicalPrice = getHistoricalPriceValue(averageObject, measure, unit);
        lrpAnalysisPriceTrendsChartData.push({
          name: getNameFormatted(monthObject.endDate),
          endDate: getDateFormatted(monthObject.endDate),
          endMonth: currentMonth.toString() + '-' + currentyear.toString(),
          measure: measure,
          yearInterval: yearInterval,
          currentPrice: getCurrentPriceValue(monthObject, measure, unit),
          historicalPrice: historicalPrice !== 0 ? historicalPrice : undefined,
          ...customValues
        });
      }
    });

    return { data: lrpAnalysisPriceTrendsChartData, yearInterval: yearInterval };
  }
);

export const getLrpAnalysisPriceEvolutionChartData = createSelector(
  getLrpPriceEvolutionAnalysis,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  getYearsFromProps,
  (lrpData, _measure, unit, years) => {
    const chartData: LrpPriceEvolutionAnalysisChartData[] = [];

    const historicData = lrpData.historicOverview.yearMonthDataPoints;

    const selectedYearsToDisplay = filterObjectsByYearAnalysis({
      historicalData: historicData,
      yearsRange: years
    });

    const mappedData = selectedYearsToDisplay.map(year => {
      return {
        year: year.year,
        months: year.monthDataPoints.map(month => {
          return {
            month: month.month,
            value: getCorrectValue(month, unit)
          };
        })
      };
    });

    if (lrpData.dataPoints.length) {
      lrpData.dataPoints.forEach(dataPoint => {
        const date = new Date(dataPoint.date);
        const shortMonth = date.toLocaleString(Language.DEFAULT, { month: 'short' });
        const shortYear = date.getFullYear().toString().substr(-2);

        let value = dataPoint.cwt;
        if (unit === Unit.HEAD) {
          value = dataPoint.head;
        } else if (unit === Unit.TOTAL) {
          value = dataPoint.total;
        }

        chartData.push({
          name: shortMonth + '-' + shortYear,
          date: dataPoint.date,
          value: value,
          month: date.getMonth() + 1
        });
      });
    }

    const updatedChartData = updateSecondArray(mappedData, chartData);

    return updatedChartData.sort(sortObjectByKey('date', SortDirection.ASCENDING));
  }
);

const getCorrectValue = (value: DataPointMonth | undefined, unit: number) => {
  if (!value) return undefined;
  switch (unit) {
    case Unit.CWT:
      return value.cwt;
    case Unit.HEAD:
      return value.head;
    case Unit.TOTAL:
      return value.total;
  }
};

function updateSecondArray(
  firstArray: {
    year: number;
    months: {
      month: number;
      value: number | undefined;
    }[];
  }[],
  secondArray: LrpPriceEvolutionAnalysisChartData[]
) {
  const firstArrayMap = new Map<number, Record<string, number | undefined>>();

  firstArray.forEach(obj => {
    obj.months.forEach(monthObj => {
      const year = obj.year.toString();
      if (!firstArrayMap.has(monthObj.month)) {
        firstArrayMap.set(monthObj.month, {});
      }
      firstArrayMap.get(monthObj.month)![year + 'custom'] = monthObj.value;
    });
  });

  secondArray.forEach(item => {
    const month = item.month;
    if (!month) {
      return;
    }
    const yearValues = firstArrayMap.get(month);
    if (yearValues !== undefined) {
      Object.assign(item, yearValues);
    }
  });

  return secondArray;
}

export const getLrpPriceEvolutionAnalysisPriceOverviewData = createSelector(
  getLrpPriceEvolutionAnalysis,
  getPriceTrendsMeasureFromProps,
  (priceOverviewData, _measure) => {
    return priceOverviewData;
  }
);

export const getLrpPriceEvolutionAnalysisHistoricalData = createSelector(
  getLrpPriceEvolutionAnalysis,
  getPriceTrendsMeasureFromProps,
  getPriceTrendsUnitFromProps,
  (priceOverviewData, _measure, unit) => {
    const historicalTableData: LrpPriceEvolutionAnalysisHistoricalData[][] = [];

    const endorsementLengthValues = priceOverviewData.historicOverview.monthAveages
      .map(item => item.endorsementLength)
      .filter((value, index, self) => self.indexOf(value) === index);

    const endorsementLengthValuesWithMonth: LrpPriceEvolutionAnalysisHistoricalHeader[] = [];
    endorsementLengthValues.forEach(endorsement => {
      const matchEndorsementMonth = priceOverviewData.historicOverview.monthAveages.find(
        item => item.endorsementLength === endorsement
      );
      const endorsementMonth = matchEndorsementMonth
        ? getMonthName(matchEndorsementMonth?.month)
        : '';
      endorsementLengthValuesWithMonth.push({
        endorsementLength: endorsement,
        month: endorsementMonth
      });
    });

    const years = priceOverviewData.historicOverview.yearActuals
      .map(item => item.year)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => b - a);

    const historicalAverageData = priceOverviewData.historicOverview.monthAveages.map(item => {
      let averagePrice = 0;
      if (unit === Unit.CWT) averagePrice = item.cwt;
      else if (unit === Unit.HEAD) averagePrice = item.head;
      else if (unit === Unit.TOTAL) averagePrice = item.total;
      return { price: averagePrice, endorsementLength: item.endorsementLength };
    });
    numberRange(0, years.length).forEach(yearIncrement => {
      const foundYear = priceOverviewData.historicOverview.yearMonthDataPoints.filter(
        item => item.year === years[yearIncrement]
      );

      const currentYear = foundYear[0].year;
      const endDate = utcDateToLocalDate(foundYear[0].endDate);
      const currentMonth = getMonthName(endDate.getMonth() + 1);

      const currentActualPrice = priceOverviewData.historicOverview.yearActuals.find(
        item => item.year === currentYear
      );
      let actualPrice = currentActualPrice ? currentActualPrice?.cwt : 0;
      if (unit === Unit.HEAD) actualPrice = currentActualPrice ? currentActualPrice?.head : 0;
      else if (unit === Unit.TOTAL)
        actualPrice = currentActualPrice ? currentActualPrice?.total : 0;

      const dataForYear = foundYear[0].monthDataPoints.map(item => {
        let price = item.cwt;
        if (unit === Unit.HEAD) {
          price = item.head;
        } else if (unit === Unit.TOTAL) {
          price = item.total;
        }
        return {
          price: price,
          actualPrice: actualPrice,
          endorsementLength: item.endorsementLength,
          year: currentYear,
          month: currentMonth,
          type: PriceType.NEUTRAL
        };
      });
      endorsementLengthValues.forEach(element => {
        const hasEndorsementLength = dataForYear.find(item => item.endorsementLength === element);
        if (!hasEndorsementLength)
          dataForYear.push({
            price: 0,
            actualPrice: actualPrice,
            endorsementLength: element,
            year: currentYear,
            month: currentMonth,
            type: PriceType.NEUTRAL
          });
      });

      const sortedDataForYear = dataForYear
        .slice()
        .sort((a, b) => b.endorsementLength - a.endorsementLength);

      historicalTableData.push(sortedDataForYear);
    });

    addTypeToValues(historicalTableData);

    const sortedHeaderData = endorsementLengthValuesWithMonth
      .slice()
      .sort((a, b) => b.endorsementLength - a.endorsementLength);

    const sortedAverage = historicalAverageData
      .slice()
      .sort((a, b) => b.endorsementLength - a.endorsementLength);

    return {
      data: historicalTableData,
      headerData: sortedHeaderData,
      average: sortedAverage
    };
  }
);
