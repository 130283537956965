import * as React from 'react';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components/macro';

import color from '../../utils/color';

interface Props extends ITooltip {
  id: string;
  width?: number;
  backgroundColor?: string;
}

const Tooltip: React.FC<Props> = ({
  id,
  width,
  children,
  backgroundColor = color.BACKGROUND_SECONDARY,
  ...restProps
}) => (
  <Root id={id} width={width} place="top" backgroundColor={backgroundColor} {...restProps}>
    {children}
  </Root>
);

export default Tooltip;

const Root = styled(ReactTooltip)<{ width: number | undefined; backgroundColor: string }>`
  z-index: 110;
  width: ${({ width }) => width}px !important;
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor} !important;
  opacity: 1 !important;
  color: black !important;
  font-size: 12px !important;
  border: 1px solid ${color.PRIMARY} !important;
  border-radius: 8px !important;

  &.place-top {
    &:after {
      border-top-color: ${color.BACKGROUND_SECONDARY} !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
`;
