import { getType } from 'typesafe-actions';

import { OperationDto } from '../../../types/dto/OperationDto';
import { AdminActions, adminActions } from '../actions';

interface ProducerOperationState {
  [user: string]: OperationDto[];
}

const initialState: ProducerOperationState = {};

const producerOperationReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.producerOperations.addProducerOperations): {
      return { ...state, [action.meta]: action.payload };
    }
    case getType(adminActions.producerOperations.saveAssignedOperation): {
      const newState = { ...state };
      if (!newState[action.payload.producerId]) {
        newState[action.payload.producerId] = [action.payload.operation];
      } else {
        newState[action.payload.producerId].push(action.payload.operation);
      }
      return newState;
    }
    case getType(adminActions.producerOperations.deleteAssignedOperation): {
      const newState = { ...state };
      const index = newState[action.payload.producerId].findIndex(
        operation => operation.name === action.payload.operationName
      );
      if (index !== -1) {
        newState[action.payload.producerId].splice(index, 1);
      }
      return newState;
    }
    default:
      return state;
  }
};

export default producerOperationReducer;
