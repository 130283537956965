export const groupByKey = <T, K extends keyof T>(
  objects: T[],
  key: K,
  callbackFn?: (value: T[K]) => string
) =>
  (objects ?? []).reduce(
    (acc, obj) => {
      const value = callbackFn ? callbackFn(obj[key]) : (obj[key] as string);
      if (!acc[value]) {
        acc[value] = [];
      }
      acc[value].push(obj);
      return acc;
    },
    {} as Record<string, T[]>
  );
