import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Alert } from '../../types/Alert';
import {
  HistoricalAnalysisExpected,
  HistoricalAnalysisPriceFloor
} from '../../types/HistoricalAnalysisExpected';
import { AlertActions, alertActions } from './actions';

interface AlertState {
  alerts: Alert[];
  priceTrendExpected: HistoricalAnalysisExpected;
  isProductSupported: boolean;
  priceFloorTrendExpected: HistoricalAnalysisPriceFloor;
}

const initialState: AlertState = {
  alerts: [],
  priceTrendExpected: {
    latest: {
      quantile: 0,
      dataPoint: {
        date: new Date(),
        price: 0,
        value: 0
      }
    },
    previous: {
      quantile: 0,
      dataPoint: {
        date: new Date(),
        price: 0,
        value: 0
      }
    },
    days7: {
      earliestDate: new Date(),
      latestDate: new Date(),
      average: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      low: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      high: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      }
    },
    overall: {
      earliestDate: new Date(),
      latestDate: new Date(),
      average: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      low: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      high: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      }
    },
    days30: {
      earliestDate: new Date(),
      latestDate: new Date(),
      average: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      low: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      },
      high: {
        quantile: 0,
        dataPoint: {
          date: new Date(),
          price: 0,
          value: 0
        }
      }
    },
    values: []
  },

  isProductSupported: true,
  priceFloorTrendExpected: {
    latest: 0,
    lastSalesEffectiveDate: '',
    revenueGuarantee: 0,
    premium: 0,
    basis: 0,
    revenueFloor: 0
  }
};

export const alertsReducer = (state = initialState, action: AlertActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(alertActions.saveAll): {
        draft.alerts = action.payload;
        return;
      }
      case getType(alertActions.update): {
        const index = state.alerts.findIndex(alert => alert.id === action.payload.id);
        if (index !== -1) {
          draft.alerts[index] = action.payload;
        }
        return;
      }
      case getType(alertActions.remove): {
        const index = state.alerts.findIndex(alert => alert.id === action.payload);
        if (index !== -1) {
          draft.alerts.splice(index, 1);
        }
        return;
      }
      case getType(alertActions.saveAlertPrices): {
        draft.priceTrendExpected = action.payload;
        return;
      }
      case getType(alertActions.saveAlertPriceFloorPrices): {
        draft.priceFloorTrendExpected = action.payload;
        return;
      }
      case getType(alertActions.setIsProductSupported): {
        draft.isProductSupported = action.payload;
        return;
      }
    }
  });
