import { ActionType, createAction } from 'typesafe-actions';

import OperationContact from '../../types/Operation/OperationContact';

export const operationFiltersActions = {
  changeSort: createAction('operationFilter/CHANGE_SORT')<string>(),
  markAsDemo: createAction('operationFilter/CHANGE_DEMO_FILTER')<boolean>(),
  clearAgentFilter: createAction('operationFilter/CLEAR_AGENT_FILTER')(),
  addAgentFilter: createAction('operationFilter/ADD_AGENT_FILTER')<OperationContact>(),
  removeAgentFilter: createAction('operationFilter/REMOVE_AGENT_FILTER')<OperationContact>(),
  clearStateFilter: createAction('operationFilter/CLEAR_STATE_FILTER')(),
  addStateFilter: createAction('operationFilter/ADD_STATE_FILTER')<{
    stateCode: string;
    stateName: string;
  }>(),
  removeStateFilter: createAction('operationFilter/REMOVE_STATE_FILTER')<{
    stateCode: string;
    stateName: string;
  }>(),
  addInsuranceFilter: createAction('operationFilter/ADD_INSURANCE_FILTER')<string>(),
  removeInsuranceFilter: createAction('operationFilter/REMOVE_INSURANCE_FILTER')<string>(),
  clearInsuranceFilter: createAction('operationFilter/CLEAR_INSURANCE_FILTER')(),
  addAipFilter: createAction('operationFilter/ADD_AIP_FILTER')<string>(),
  removeAipFilter: createAction('operationFilter/REMOVE_AIP_FILTER')<string>(),
  clearAipFilter: createAction('operationFilter/CLEAR_AIP_FILTER')(),
  addOperationFilter: createAction('operationFilter/ADD_OPERATION_FILTER')<string>(),
  removeOperationFilter: createAction('operationFilter/REMOVE_OPERATION_FILTER')<string>(),
  clearOperationFilter: createAction('operationFilter/CLEAR_OPERATION_FILTER')()
};

export type OperationFiltersActions = ActionType<typeof operationFiltersActions>;
