import { EndorsementChartData } from '../../../components/Charts/EndorsementChart';
import { Endorsement } from '../../../types/Endorsement/Endorsement';
import { EndorsementSummary } from '../../../types/Endorsement/EndorsementSummary';
import numberRange from '../../../utils/numberRange';

export type EndorsementSummaryWithActiveEndorsements = EndorsementSummary & {
  operationEndorsements: Endorsement[];
};

export function getProjectedProductionDataLbs(
  yearlySummaries: EndorsementSummaryWithActiveEndorsements[]
) {
  const year = yearlySummaries[0].year;

  return numberRange(1, 5).map(quarterNum => {
    const row = yearlySummaries.find(row => row.quarter === quarterNum);

    const indemnity = row?.endorsement
      ? row.endorsement.indemnity / (row.endorsement.milkProductionDeclared / 100)
      : 0;
    const revenueChange = row?.endorsement
      ? row.endorsement.revenueChange / (row.endorsement.milkProductionDeclared / 100)
      : 0;
    const premiumProducer = row?.endorsement
      ? row.endorsement.premiumProducer / (row.endorsement.milkProductionDeclared / 100)
      : 0;
    const hedgeRatio = formatHedgeRatio(row?.hedgeRatio?.hedgeRatioEffective);

    return {
      name: `Q${quarterNum} ${year}`,
      quarter: quarterNum,
      indemnity,
      revenueChange,
      premiumProducer,
      hedgeRatio
    } as EndorsementChartData;
  });
}

export function getProjectedProductionDataDollar(
  yearlySummaries: EndorsementSummaryWithActiveEndorsements[]
) {
  const year = yearlySummaries[0].year;

  return numberRange(1, 5).map(quarterNum => {
    const row = yearlySummaries.find(row => row.quarter === quarterNum);

    const indemnity = row?.endorsement ? row.endorsement.indemnity : 0;
    const revenueChange = row?.endorsement ? row.endorsement.revenueChange : 0;
    const premiumProducer = row?.endorsement ? row.endorsement.premiumProducer : 0;
    const hedgeRatio = formatHedgeRatio(row?.hedgeRatio?.hedgeRatioEffective);

    return {
      name: `Q${quarterNum} ${year}`,
      quarter: quarterNum,
      indemnity,
      revenueChange,
      premiumProducer,
      hedgeRatio
    } as EndorsementChartData;
  });
}

export function formatHedgeRatio(hedgeRatio: number | null | undefined) {
  if (!hedgeRatio) return null;
  return hedgeRatio * 100;
}
