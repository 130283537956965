import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import { Endorsement } from '../../types/Endorsement/Endorsement';
import EndorsementModalPracticeData from '../../types/Endorsement/EndorsementModalPracticeData';
import EndorsementModalUpdateData from '../../types/Endorsement/EndorsementModalUpdateData';
import { EndorsementPayload } from '../../types/Endorsement/EndorsementPayload';
import { EndorsementSummary } from '../../types/Endorsement/EndorsementSummary';
import { HistoricalData } from '../../types/HistoricalData';
import { Practice } from '../../types/Practice';
import { ProjectionAnalysis } from '../../types/ProjectionAnalysis';
import { ProjectionAnalysisParameters } from '../../types/ProjectionAnalysisParameters';
import ScenarioWhatIfPrices, {
  ScenarioWhatIfTableData
} from '../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { ProjectionAnalysisParametersDto } from '../../types/dto/ProjectionAnalysisParametersDto';
import { ProjectionDataQuery } from '../projectionData/actions';

export const scenarioAnalysisActions = {
  initWhatIfScenario: createAction('@scenarioAnalysis/INIT_WHAT_IF_SCENARIO')(),
  getScenarioWhatIfPrices: createAction('@scenarioAnalysis/GET_WHAT_IF')(),
  saveScenarioWhatIfPrices: createAction('@scenarioAnalysis/SAVE_WHAT_IF')<ScenarioWhatIfPrices>(),
  updateScenarioWhatIfPrices: createAction('@scenarioAnalysis/UPDATE_WHAT_IF')<{
    whatIfOld: ScenarioWhatIfTableData;
    whatIfNew: ScenarioWhatIfTableData;
    reinsuranceYear: number;
  }>(),
  getScenarioEndorsementsSummary: createAction(
    '@scenarioAnalysis/GET_SCENARIO_ENDORSEMENTS_SUMMARY'
  )(),
  saveScenarioEndorsementsSummary: createAction(
    '@scenarioAnalysis/SAVE_SCENARIO_ENDORSEMENTS_SUMMARY'
  )<EndorsementSummary[]>(),
  getScenarioEndorsementsByQuarter: createAction(
    '@scenarioAnalysis/GET_SCENARIO_ENDORSEMENTS_BY_QUARTER'
  )<{
    year: number;
    quarter: number;
  }>(),
  saveScenarioEndorsmentsByQuarter: createAction(
    '@scenarioAnalysis/SAVE_SCENARIO_ENDORSEMENTS_BY_QUARTER'
  )<Endorsement[]>(),
  createEndorsement: createAction('@scenarioAnalysis/CREATE_ENDORSEMENT')<
    { endorsement: EndorsementPayload; reinsuranceYear: number; quarter: number },
    () => void
  >(),
  editEndorsement: createAction('@scenarioAnalysis/EDIT_ENDORSEMENT')<
    { data: EndorsementPayload; id: number; reinsuranceYear: number; quarter: number },
    () => void
  >(),
  deleteEndorsement: createAction('@scenarioAnalysis/DELETE_ENDORSEMENT')<number, () => void>(),
  removeEndorsement: createAction('@scenarioAnalysis/REMOVE_ENDORSEMENT')<number>(),
  saveCurrentScenarioName: createAction('@scenarioAnalysis/SAVE_CURRENT_SCENARIO_NAME')<string>(),
  getWhatIfScenario: createAction('@scenarioAnalysis/GET_WHAT_IF_SCENARIO')<
    { scenarioName: string; year: number; quarter: number },
    () => void
  >(),
  createWhatIfScenario: createAction('@scenarioAnalysis/CREATE_SCENARIO')<string>(),
  saveCreatedWhatIfScenario: createAction('@scenarioAnaltsis/SAVE_CREATED_SCENARIO')<string>(),
  addWhatIfScenario: createAction('@scenarioAnalysis/ADD_WHAT_IF_SCENARIO')<string>(),
  getWhatIfScenarios: createAction('@scenarioAnalysis/GET_WHAT_IF_SCENARIOS')(),
  saveWhatIfScenarios: createAction('@scenarioAnalysis/SAVE_SCENARIOS')<string[]>(),
  saveWhatIfScenario: createAction('@scenarioAnalysis/SAVE_WHAT_IF_SCENARIO')<string>(),
  deleteWhatIfScenario: createAction('@scenarioAnalysis/DELETE_WHAT_IF_SCENARIO')<{
    scenarioName: string;
    year: number;
    quarter: number;
  }>(),
  removeWhatIfScenario: createAction('@scenarioAnalysis/REMOTE_WHAT_IF_SCENARIO')<string>(),
  resetWhatIf: createAction('@scenarioAnalysis/RESET_WHAT_IF')<{
    resetEndorsements: boolean;
    resetPrices: boolean;
    resetData: boolean;
    reinsuranceYear: number;
    quarter: number;
    callback: () => void;
  }>(),
  setRunScenarioButtonState: createAction(
    '@scenarionAnalysis/SET_RUN_SCENARIO_BUTTON_STATE'
  )<boolean>(),
  setWhatIfIsEditing: createAction('@scenarioAnalysis/SET_WHAT_IF_IS_EDITING')<boolean>(),
  recalculateScenarioEndorsements: createAction(
    '@scenarioAnalysis/RECALCULATE_SCENARIO_ENDORSEMENTS'
  )<{
    year: number;
    quarter: number;
  }>(),
  endorsementModal: {
    init: createAction('@scenarioAnalysisEndorsement/INIT_PRACTICE')<{
      operationName: string;
      stateCode: string;
    }>(),
    editInit: createAction('@scenarioAnalysisEndorsement/EDIT_ENDORSEMENT_INIT')<number>(),
    populateStateWithExisitingEndorsement: createAction(
      '@scenarioAnalysisEndorsement/POPULATE_EXISITING'
    )<Endorsement>(),
    setModalData: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_DATA'
    )<EndorsementModalUpdateData>(),
    initModalPracticeData: createAction(
      '@scenarioAnalysisEndorsement/INIT_MODAL_PRACTICE_DATA'
    )<Practice>(),
    setModalPracticeData: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_PRACTICE_DATA'
    )<EndorsementModalPracticeData>(),
    setModalState: createAction('@scenarioAnalysisEndorsement/SET_MODAL_STATE')<number>(),
    setModalQuarter: createAction('@scenarioAnalysisEndorsement/SET_MODAL_QUARTER')<string>(),
    setScenarioEndorsementQuarter: createAction(
      '@scenarioAnalysisEndorsement/SET_ENDORSEMENT_QUARTER'
    )<string>(),
    setModalDeclaredMilkProduction: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_DMP'
    )<number>(),
    setModalShare: createAction('@scenarioAnalysisEndorsement/SET_MODAL_SHARE')<number>(),
    setModalCalendarDate: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_CALENDAR_DATE'
    )<Date>(),
    setModalCoverageLevel: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_COVERAGE'
    )<number>(),
    setModalProtectionFactor: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_PROTECTION'
    )<number>(),
    setModalDeclaredButterfatTest: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_D_BUTTERFAT'
    )<number>(),
    setModalDeclaredProteinTest: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_D_PROTEIN'
    )<number>(),
    setModalDeclaredClassPriceWeightingFactor: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_DCPWF'
    )<number>(),
    setModalDeclaredComponentPriceWeightingFactor: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_DCompPWF'
    )<number>(),
    setModalSalesEffectiveDateWithoutPracticeFetch: createAction(
      '@scenarioAnalysisEndorsement/SET_MODAL_SED_WITHOUT_FETCH'
    )<Date>(),
    setIsSalesDateAvailable: createAction(
      '@scenarioAnalysisEndorsement/SET_IS_SALES_DATE_AVAILABLE'
    )<boolean>(),
    setShare: createAction('@scenarioAnalysisEndorsement/SET_SHARE')<number>()
  },
  dataActions: {
    getAll: createAction('@scenarioAnalysisData/GET_ALL')(),
    saveAll: createAction('@scenarioAnalysisData/SAVE_ALL')<
      HistoricalData[],
      React.Dispatch<React.SetStateAction<HistoricalData[]>>
    >(),
    addAll: createAction('@scenarioAnalysisData/ADD_ALL')<HistoricalData[]>(),
    addNewRows: createAction('@scenarioAnalysisData/ADD_NEW_ROWS')<HistoricalData[]>(),
    sortHistoricalData: createAction('@scenarioAnalysisData/SORT')<SortDirection>(),
    editRecord: createAction('@scenarioAnalysisData/EDIT')<HistoricalData, () => void>(),
    updateRecord: createAction('@scenarioAnalysisData/UPDATE')<HistoricalData>(),
    clear: createAction('@scenarioAnalysisData/CLEAR')<{ year: number; month: number }>(),
    delete: createAction('@scenarioAnalysisData/DELETE')<{
      year: number;
      month: number;
      callback: () => void;
    }>(),
    remove: createAction('@scenarioAnalysisData/REMOVE')<{ year: number; month: number }>()
  },
  projectedRevenue: {
    getProjectionModel: createAction('@scenarioAnalysisProjectedRevenue/GET_PROJECTION_MODEL')<
      number,
      number
    >(),
    get: createAction('@scenarioAnalysisProjectedRevenue/GET')<number, ProjectionDataQuery>(),
    getOnBlur: createAction('@scenarioAnalysisProjectedRevenue/GET_ON_BLUR')<
      number,
      ProjectionDataQuery
    >(),
    save: createAction('@scenarioAnalysisProjectedRevenue/SAVE')<{
      scenarioTable: ProjectionAnalysis;
      actualTable: ProjectionAnalysis;
    }>(),
    settings: {
      get: createAction('@scenarioAnalysisProjectedRevenue/SETTINGS_GET')<number>(),
      save: createAction(
        '@scenarioAnalysisProjectedRevenue/SETTINGS_SAVE'
      )<ProjectionAnalysisParameters | null>(),
      update: createAction(
        '@scenarioAnalysisProjectedRevenue/SETTINGS_UPDATE'
      )<ProjectionAnalysisParametersDto>()
    }
  }
};

export type ScenarioAnalysisActions = ActionType<typeof scenarioAnalysisActions>;
