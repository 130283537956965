import { ZodiosHooks } from '@zodios/react';

import { createApiClient } from '@/shared/api/lrpClient/lrpClient';
import { useApi } from '@/shared/api/useApi';

export const useLrpClient = () => {
  const { createApiInstance } = useApi();

  const apiClient = createApiClient(import.meta.env.VITE_APP_INSURANCE_URL, {
    axiosInstance: createApiInstance()
  });

  const lrpApi = new ZodiosHooks('lrpApi', apiClient);
  return { lrpApi };
};
