import { ActionType, createAction } from 'typesafe-actions';

import { AccountPreferences } from '../../../types/AccountPreferences/AccountPrefereces';
import { AccountPreferencesUpdatePayload } from '../../../types/AccountPreferences/AccountPreferencesUpdatePayload';
import { UserDto } from '../../../types/dto/UserDto';
import { AuthState } from '../../authentication/reducer';

export const accountPreferencesActions = {
  userPreferences: {
    fetchUserPreferences: createAction('@specified_account_preferences/GET')<
      string,
      { setSpinner: React.Dispatch<React.SetStateAction<boolean>>; redirect: () => void }
    >(),
    saveUserPreferences: createAction('@specified_account_preferences/UPDATE')<
      string,
      AccountPreferencesUpdatePayload
    >(),
    setUser: createAction('@specified_account_preferences/SET_USER')<UserDto, AuthState>(),
    setUserPreferences: createAction(
      '@specified_account_preferences/SET_PREFERENCES'
    )<AccountPreferences>()
  }
};

export type accountPreferencesActions = ActionType<typeof accountPreferencesActions>;
