export const getErrorMessage = (error: unknown, defaultMsg?: string) => {
  if (typeof error === 'string') {
    return error;
  }

  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }

  return defaultMsg ?? 'Unknown error';
};

/**
 * Provide a condition and if that condition is falsy, this throws an error with the provided message.
 *
 * @example
 * invariant(typeof value === 'string', 'value must be a string')
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 *
 * @throws {Error} if condition is falsy
 */
export function invariant<TCondition>(
  condition: TCondition,
  message: string | (() => string)
): asserts condition {
  if (!condition) {
    throw new Error(typeof message === 'function' ? message() : message);
  }
}

/**
 * Provide a condition and if that condition is falsy, this throws a 400 Response with the provided message.
 *
 * @example
 * invariant(typeof value === 'string', 'value must be a string')
 *
 * @param condition The condition to check
 * @param message The message to throw (or a callback to generate the message)
 * @param responseInit Additional response init options if a response is thrown
 *
 * @throws {Response} if condition is falsy
 */
export function invariantResponse<TCondition>(
  condition: TCondition,
  message: string | (() => string),
  responseInit?: ResponseInit
): asserts condition {
  if (!condition) {
    throw new Response(typeof message === 'function' ? message() : message, {
      status: 400,
      ...responseInit
    });
  }
}
