import { ActionType, createAction } from 'typesafe-actions';

import { AccountPreferences } from '../../types/AccountPreferences/AccountPrefereces';
import { AccountPreferencesPassword } from '../../types/AccountPreferences/AccountPreferencesPassword';
import { AccountPreferencesSendReport } from '../../types/AccountPreferences/AccountPreferencesSendReport';
import { AccountPreferencesUpdatePayload } from '../../types/AccountPreferences/AccountPreferencesUpdatePayload';

export const accountPreferences = {
  getAccountPreferences: createAction('@account_preferences/GET')(),
  addAccountPreferences: createAction('@account_preferences/ADD')<AccountPreferences>(),
  updateAccountPreferences: createAction(
    '@account_preferences/UPDATE'
  )<AccountPreferencesUpdatePayload>(),
  saveUpdatedAccountPreferences: createAction(
    '@account_preferences/SAVE'
  )<AccountPreferencesUpdatePayload>(),
  updateAccountAndPassword: createAction(
    '@account_preferences/UPDATE_ACCOUNT_WITH_PASSWORD'
  )<AccountPreferencesPassword>(),
  sendDrpPriceReportEmail: createAction(
    '@account_preferences/SEND_DRP_PRICE_REPORT_EMAIL'
  )<AccountPreferencesSendReport>(),
  setStarredOperations: createAction('@account_preferences/SET_STARRED_OPERATIONS')<boolean>(),
  saveUpdatedStarredOperations: createAction(
    '@account_preferences/SAVE_UPDATED_STARRED_OPERATIONS'
  )<boolean>()
};

export type AccountPreferencesActions = ActionType<typeof accountPreferences>;
