import { all, call, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { HistoricalAnalysisExpectedDto } from '../../types/dto/HistoricalAnalysisExpectedDto';
import { loadersActions } from '../loaders/actions';
import { priceTrendsActions } from './actions';
import { mapHistoricalAnalysisActual } from './mapHistoricalAnalysisActual';
import { mapHistoricalAnalysisExpected } from './mapHistoricalAnalysisExpected';
import { getPriceTrends } from './selectors';

function* getPriceExpectedTrends(
  action: ActionType<typeof priceTrendsActions.getPriceExpectedTrends>
): any {
  yield put(loadersActions.spinner.activate());
  const { year, quarter, isFutures, previousIsFutures } = action.payload;
  const priceTrends = yield select(getPriceTrends);
  for (const product of action.meta) {
    if (
      !priceTrends[product]?.expected ||
      isFutures !== previousIsFutures ||
      priceTrends[product].expected.year !== year ||
      priceTrends[product].expected.quarter !== quarter
    ) {
      try {
        let expected: AxiosResponse<HistoricalAnalysisExpectedDto>;
        if (isFutures) {
          expected = yield call(
            insuranceAPI.getHistoricalAnalysisExpectedFutures,
            year,
            quarter,
            product
          );
        } else {
          expected = yield call(
            insuranceAPI.getHistoricalAnalysisExpectedRMA,
            year,
            quarter,
            product
          );
        }
        yield put(
          priceTrendsActions.setPriceExpectedTrends(
            mapHistoricalAnalysisExpected(expected.data, year, quarter),
            product
          )
        );
        yield action.payload.successCallback();
      } catch (e) {
        yield action.payload.failureCallback();
      }
    }
  }
  yield put(loadersActions.spinner.disable());
}

function* getPriceActualTrends(
  action: ActionType<typeof priceTrendsActions.getPriceActualTrends>
): any {
  yield put(loadersActions.spinner.activate());
  const { fromYear, toYear } = action.payload;
  const priceTrends = yield select(getPriceTrends);
  for (const product of action.meta) {
    if (
      !priceTrends[product]?.actual ||
      priceTrends[product].actual.fromYear !== fromYear ||
      priceTrends[product].actual.toYear !== toYear
    ) {
      try {
        const actual = yield call(
          insuranceAPI.getHistoricalAnalysisActual,
          fromYear,
          toYear,
          product
        );
        yield put(
          priceTrendsActions.setPriceActualTrends(
            mapHistoricalAnalysisActual(actual.data, fromYear, toYear),
            product
          )
        );
        yield action.payload.successCallback();
      } catch (e) {
        yield action.payload.failureCallback();
      }
    }
  }
  yield put(loadersActions.spinner.disable());
}

export function* priceTrendsSagaWatcher() {
  yield all([
    takeLatest(getType(priceTrendsActions.getPriceExpectedTrends), getPriceExpectedTrends),
    takeLatest(getType(priceTrendsActions.getPriceActualTrends), getPriceActualTrends)
  ]);
}
