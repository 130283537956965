import { AxiosResponse } from 'axios';

import AllQuarters from '../../types/AllQuarters';
import { InsuranceDto } from '../../types/dto/InsuranceDto';

const mapAllQuartersDtoToAllQuarters = (
  practiceData: Array<AxiosResponse<InsuranceDto>>,
  practices: Array<{
    quarter: string;
    practiceCode: number;
    proteinTest: number;
    butterfatTest: number;
  }>
): AllQuarters[] =>
  practiceData.map(({ data }, index) => ({
    practiceCode: practices[index].practiceCode,
    declaredButterfatTest: practices[index].butterfatTest,
    declaredProteinTest: practices[index].proteinTest,
    nonfatSolidsTest: data.component.recomendedNonfatSolidsTest || 0,
    expectedRevenue: {
      component: data.component.expectedRevenue,
      class: data.class.expectedRevenue
    },
    expectedRevenueCwt: {
      component: data.component.expectedRevenueCwt,
      class: data.class.expectedRevenueCwt
    },
    revenueGuarantee: {
      component: data.component.revenueGuarantee,
      class: data.class.revenueGuarantee
    },
    revenueGuaranteeCwt: {
      component: data.component.revenueGuaranteeCwt,
      class: data.class.revenueGuaranteeCwt
    },
    expectedBasis: { component: data.component.basis, class: data.class.basis },
    expectedBasisCwt: { component: data.component.basisCwt, class: data.class.basisCwt },
    premiumBeforeSubsidy: { component: data.component.premium, class: data.class.premium },
    premiumBeforeSubsidyCwt: { component: data.component.premiumCwt, class: data.class.premiumCwt },
    subsidy: { component: data.component.subsidy, class: data.class.subsidy },
    subsidyCwt: { component: data.component.subsidyCwt, class: data.class.subsidyCwt },
    producerPremiumAmount: {
      component: data.component.producerPremium,
      class: data.class.producerPremium
    },
    producerPremiumAmountCwt: {
      component: data.component.producerPremiumCwt,
      class: data.class.producerPremiumCwt
    },
    expectedPriceFloorNet: {
      component: data.component.expectedPriceFloorNet,
      class: data.class.expectedPriceFloorNet
    },
    expectedPriceFloorNetCwt: {
      component: data.component.expectedPriceFloorNetCwt,
      class: data.class.expectedPriceFloorNetCwt
    },
    expectedPriceFloor: {
      component: data.component.revenueGuarantee - data.component.producerPremium,
      class: data.class.revenueGuarantee - data.class.producerPremium
    },
    expectedPriceFloorCwt: {
      component: data.component.revenueGuaranteeCwt - data.component.producerPremiumCwt,
      class: data.class.revenueGuaranteeCwt - data.class.producerPremiumCwt
    },
    probabilityOfPositiveIndemnity: {
      component: data.component.probabilityOfPostiveIndemnity,
      class: data.class.probabilityOfPostiveIndemnity
    },
    probabilityOfPositiveNetIndemnity: {
      component: data.component.probabilityOfPositiveNetBenefit,
      class: data.class.probabilityOfPositiveNetBenefit
    },
    milkProductionEffective: {
      component: data.component.milkProductionEffective,
      class: data.class.milkProductionEffective
    },
    milkProductionEffectiveCwt: {
      component: data.component.milkProductionEffectiveCwt,
      class: data.class.milkProductionEffectiveCwt
    },
    costOfProduction: {
      component: data.component.costOfProduction,
      class: data.class.costOfProduction
    },
    costOfProductionCwt: {
      component: data.component.costOfProductionCwt,
      class: data.class.costOfProductionCwt
    },
    expectedYield: { component: data.component.expectedYield, class: data.class.expectedYield }
  }));

export default mapAllQuartersDtoToAllQuarters;
