import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { Tag } from '../../scenes/common/Tags/types/Tag';
import insuranceService from '../../services/insuranceService';
import { RootState } from '../store';
import { tagActions } from './actions';

const selectTags = (state: RootState) => state.tags;

export function* getTags(action: ActionType<typeof tagActions.tags.fetch>) {
  const { operationName } = action.payload;
  try {
    yield put(tagActions.tagLoader.activate());
    const tags: AxiosResponse<Tag[]> = yield call(insuranceService.getTags, operationName);
    if (tags.data.length) {
      yield put(tagActions.tags.set(tags.data));
    } else {
      yield put(tagActions.tags.set([]));
    }
  } catch (e: any) {
    console.log(e);
  } finally {
    yield put(tagActions.tagLoader.disable());
  }
}

export function* createTag(action: ActionType<typeof tagActions.tags.push>) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { name, ...requestBody } = action.payload.tag;
    yield put(tagActions.tagLoader.activate());
    yield call(insuranceService.createTag, requestBody.operationName, requestBody);

    yield toast.success('Tag created successfully!', {
      position: 'top-right'
    });
    const { operationName } = action.payload.tag;
    yield call(getTags, tagActions.tags.fetch({ operationName, manageLoader: true }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagActions.tagLoader.disable());
  }
}

export function* editTag(action: ActionType<typeof tagActions.tags.edit>): any {
  try {
    yield put(tagActions.tagLoader.activate());
    const { name, ...requestBody } = action.payload.tag;
    const tags: ReturnType<typeof selectTags> = yield select(selectTags);

    const tagExists = tags.tags.some(existingTag => existingTag.title === requestBody.title);

    if (tagExists) {
      toast.warn('Tag already exists', { position: 'top-right' });
      return;
    }

    yield call(insuranceService.editTag, requestBody.operationName, requestBody, name);
    yield toast.success('Tag updated successfully!', {
      position: 'top-right'
    });
    const { operationName } = action.payload.tag;
    yield call(getTags, tagActions.tags.fetch({ operationName, manageLoader: true }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagActions.tagLoader.disable());
  }
}

export function* deleteTag(action: ActionType<typeof tagActions.tags.delete>) {
  const { operationName, tagName } = action.payload;
  try {
    yield put(tagActions.tagLoader.activate());
    yield call(
      { context: insuranceService, fn: insuranceService.deleteTag },
      operationName,
      tagName
    );
    yield toast.success('Tag deleted successfully!', {
      position: 'top-right'
    });
    yield call(getTags, tagActions.tags.fetch({ operationName, manageLoader: false }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagActions.tagLoader.disable());
  }
}

export function* mergeTags(action: ActionType<typeof tagActions.tags.merge>) {
  const { operationName, tagName, selectedTags } = action.payload;
  try {
    yield put(tagActions.tagLoader.activate());
    yield call(insuranceService.mergeTags, operationName, tagName, selectedTags);
    yield toast.success('Tags merged successfully!', {
      position: 'top-right'
    });
    yield call(getTags, tagActions.tags.fetch({ operationName, manageLoader: false }));
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  } finally {
    yield put(tagActions.tagLoader.disable());
  }
}

export function* tagSagaWatcher() {
  yield all([
    takeLatest(getType(tagActions.tags.fetch), getTags),
    takeLatest(getType(tagActions.tags.push), createTag),
    takeLatest(getType(tagActions.tags.edit), editTag),
    takeLatest(getType(tagActions.tags.delete), deleteTag),
    takeLatest(getType(tagActions.tags.merge), mergeTags)
  ]);
}
