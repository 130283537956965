import { ProjectionAnalysis } from '../../../types/ProjectionAnalysis';
import { RootState } from '../../store';

export const getScenarioAnalysisScenarioProjectionData = (state: RootState): ProjectionAnalysis =>
  state.scenarioAnalysis.projectedRevenue.scenarioTable;

export const getScenarioAnalysisActualProjectionData = (state: RootState): ProjectionAnalysis =>
  state.scenarioAnalysis.projectedRevenue.actualTable;

export const getScenarioAnalysisProjectionDataSettings = (state: RootState) =>
  state.scenarioAnalysis.projectedRevenue.settings;

export const getScenarioAnalysisData = (state: RootState) => state.scenarioAnalysis.data;
