import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';

import BooleanPicker from '../../components/BooleanPicker/BooleanPicker';
import Checkbox from '../../components/shared/Checkbox/Checkbox';
import Radio from '../../components/shared/Radio/Radio';
import { dairyRevenueProtectionActions } from '../../ducks/dairyRevenueProtection/actions';
import {
  getSalesEffectiveDate,
  getShowAllQuarters,
  getShowBasis,
  getShowIndemnity,
  getShowProtectionFactorAnalysis,
  getShowSubsidy
} from '../../ducks/dairyRevenueProtection/selectors';
import { RootState } from '../../ducks/store';
import color from '../../utils/color';
import AllPremiumQuarters from './AllPremiumQuarters';
import SinglePremiumQuarter from './SinglePremiumQuarter';

const SettingsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const SelectFields = styled.div`
  display: flex;

  & > div {
    margin-right: 20px;
  }
`;

const RadioFields = styled.div`
  display: flex;
  color: ${color.DRP_INPUT};

  & > div {
    margin-left: 20px;
  }
`;

const Field = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Space = styled.div`
  width: 3px;
`;

export enum Unit {
  DOLLAR,
  CWT,
  LBS
}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const PremiumsContainer: React.FC<Props> = ({
  showBasis,
  showIndemnity,
  showSubsidy,
  setShowBasis,
  setShowIndemnity,
  showProtectionFactorAnalysis,
  setShowSubsidy,
  showAllQuarters,
  setShowAllQuarters,
  setShowProtectionFactorAnalysis,
  salesEffectiveDate
}) => {
  const [isSelectedBasis, setBasisSelection] = React.useState(showBasis);
  const [isSelectedSubsidy, setSubsidySelection] = React.useState(showSubsidy);
  const [isSelectedProbability, setProbabilitySelection] = React.useState(showIndemnity);
  const [isSelectedAllQuarters, setAllQuartersSelection] = React.useState(showAllQuarters);
  const [isSelectedProtectionFactorAnalysis, setProtectionFactorAnalysis] = React.useState(
    showProtectionFactorAnalysis
  );
  const [selectedUnit, setUnit] = React.useState(Unit.CWT);

  React.useEffect(() => {
    setBasisSelection(showBasis);
    setSubsidySelection(showSubsidy);
    setProbabilitySelection(showIndemnity);
    setAllQuartersSelection(showAllQuarters);
    setProtectionFactorAnalysis(showProtectionFactorAnalysis);
  }, [showBasis, showIndemnity, showSubsidy, showAllQuarters, showProtectionFactorAnalysis]);

  const onShowBasisChange = () => {
    setBasisSelection(!isSelectedBasis);
    setShowBasis(!isSelectedBasis);
  };

  const onShowIndemnityChange = () => {
    setProbabilitySelection(!isSelectedProbability);
    setShowIndemnity(!isSelectedProbability);
  };

  const onShowSubsidyChange = () => {
    setSubsidySelection(!isSelectedSubsidy);
    setShowSubsidy(!isSelectedSubsidy);
  };

  const onShowAllQuartersChange = () => {
    setAllQuartersSelection(!isSelectedAllQuarters);
    setShowAllQuarters(!isSelectedAllQuarters);
  };

  const onShowProtectionFactorAnalysis = () => {
    setProtectionFactorAnalysis(!isSelectedProtectionFactorAnalysis);
    setShowProtectionFactorAnalysis(!isSelectedProtectionFactorAnalysis);
  };

  const props = {
    useBasis: isSelectedBasis,
    useSubsidy: isSelectedSubsidy,
    useProbability: isSelectedProbability,
    useProtectionFactorAnalysis: isSelectedProtectionFactorAnalysis,
    unit: selectedUnit,
    salesEffectiveDate
  };

  return (
    <>
      <SettingsContainer>
        <SelectFields>
          <Field onClick={onShowBasisChange}>
            <Checkbox selected={isSelectedBasis} />
            <Space />
            Basis Analysis
          </Field>
          <Field onClick={onShowSubsidyChange}>
            <Checkbox selected={isSelectedSubsidy} />
            <Space />
            Subsidy Overview
          </Field>
          <Field onClick={onShowIndemnityChange}>
            <Checkbox selected={isSelectedProbability} />
            <Space />
            Probability of Indemnity
          </Field>
          <Field onClick={onShowProtectionFactorAnalysis}>
            <Checkbox selected={isSelectedProtectionFactorAnalysis} />
            <Space />
            Protection Factor Analysis
          </Field>
          <BooleanPicker
            onClick={onShowAllQuartersChange}
            active={isSelectedAllQuarters}
            labelOnColor={color.TEXT_PRIMARY}
            labelOff="Show All Quarters"
            labelOn=""
            onColor={color.DRP_INPUT}
          />
        </SelectFields>
        <RadioFields>
          <Field
            onClick={() => {
              setUnit(Unit.DOLLAR);
            }}
          >
            <Radio active={selectedUnit === Unit.DOLLAR} />
            <Space />$
          </Field>
          <Field
            onClick={() => {
              setUnit(Unit.CWT);
            }}
          >
            <Radio active={selectedUnit === Unit.CWT} />
            <Space />
            per cwt
          </Field>
        </RadioFields>
      </SettingsContainer>
      {isSelectedAllQuarters ? (
        <AllPremiumQuarters {...props} />
      ) : (
        <SinglePremiumQuarter {...props} />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  showBasis: getShowBasis(state),
  showSubsidy: getShowSubsidy(state),
  showIndemnity: getShowIndemnity(state),
  showAllQuarters: getShowAllQuarters(state),
  showProtectionFactorAnalysis: getShowProtectionFactorAnalysis(state),
  salesEffectiveDate: getSalesEffectiveDate(state)
});

const mapDispatchToProps = {
  setShowBasis: dairyRevenueProtectionActions.settings.setShowBasis,
  setShowIndemnity: dairyRevenueProtectionActions.settings.setShowIndemnity,
  setShowSubsidy: dairyRevenueProtectionActions.settings.setShowSubsidy,
  setShowAllQuarters: dairyRevenueProtectionActions.settings.setShowAllQuarters,
  setShowProtectionFactorAnalysis:
    dairyRevenueProtectionActions.settings.setShowProtectionFactorAnalysis
};

export default connect(mapStateToProps, mapDispatchToProps)(PremiumsContainer);
