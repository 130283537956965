export const SEPARATOR = {
  us: 'us',
  eu: 'eu'
} as const;

export type SeparatorType = keyof typeof SEPARATOR;

export const getNumberAndDecimalSeparators = (separatorType: SeparatorType): [string, string] => [
  separatorType === SEPARATOR['us'] ? ',' : '.',
  separatorType === SEPARATOR['us'] ? '.' : ','
];

export const findDecimalSeparatorIndex = (value: string, separatorType: SeparatorType) => {
  const [, decimalSeparator] = getNumberAndDecimalSeparators(separatorType);
  return value.indexOf(decimalSeparator);
};

const getValueAsStrings = (
  value: number,
  separatorType: string,
  minDigits = 0,
  maxDigits = 0
): string => {
  if (minDigits >= maxDigits) {
    minDigits = maxDigits;
  }

  const decimalLength = value % 1 === 0 ? 0 : (String(value).split('.')[1] ?? '').length;
  let valueAsString: string;

  if (decimalLength < minDigits || minDigits === maxDigits) {
    valueAsString = value.toFixed(minDigits);
  } else if (decimalLength > maxDigits) {
    valueAsString = value.toFixed(maxDigits);
  } else {
    valueAsString = String(value);
  }

  if (separatorType === 'us') {
    return valueAsString.replace(',', '.');
  }

  return valueAsString;
};

const sanitizeDecimalNumber = (value: string, minDigits = 0, maxDigits = 0) => {
  if (!value) {
    return minDigits ? ''.padEnd(minDigits, '0') : '';
  }

  if (value.length > maxDigits) {
    return value.slice(0, maxDigits);
  }

  if (value.length < minDigits) {
    return value.padEnd(minDigits, '0');
  }

  return value;
};

export const formatInputValue = (
  value: number | string,
  separatorType: SeparatorType,
  minDigits = 0,
  maxDigits = 0
) => {
  const [numberSeparator, decimalSeparator] = getNumberAndDecimalSeparators(separatorType);

  if (typeof value === 'number') {
    value = getValueAsStrings(value, separatorType, minDigits, maxDigits);
  }

  const [wholeNumber, decimalNumber] = value.split(decimalSeparator);
  const sanitizedWholeNumber = wholeNumber.replace(/^(?=[-])+|[^0-9]+/g, ''); // NOSONAR - regex works as intended
  const sanitizedDecimalNumber = sanitizeDecimalNumber(decimalNumber, minDigits, maxDigits);

  const formattedWholeNumber = sanitizedWholeNumber.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${numberSeparator}`
  );

  if (!sanitizedDecimalNumber) {
    return formattedWholeNumber;
  }

  return formattedWholeNumber.concat(decimalSeparator, sanitizedDecimalNumber);
};

export const fixFirstNumber = (value: string, separatorType: SeparatorType): [string, boolean] => {
  const [, decimalSeparator] = getNumberAndDecimalSeparators(separatorType);
  const isPatternMatched = (index: number) =>
    value[index] === decimalSeparator || (value[index] === undefined && value.length === index + 1);

  if (value.startsWith('0') && value[1] !== decimalSeparator && isPatternMatched(2)) {
    return [value.substring(1), true];
  }
  if (value.startsWith('-') && value[1] === '0' && isPatternMatched(3)) {
    return [`-${value.substring(2)}`, true];
  }
  return [value, false];
};

const findIndexesOf = (word: string, searchElement: string): number[] => {
  return word.split('').reduce((indexes, letter, index) => {
    if (letter === searchElement) {
      indexes.push(index);
    }
    return indexes;
  }, [] as number[]);
};

export const getValidSelectionPosition = (
  position: number,
  beforeValue: string,
  afterValue: string,
  separatorType: SeparatorType
) => {
  const [numberSeparator] = getNumberAndDecimalSeparators(separatorType);

  const calculateIndexes = (value: string) =>
    findIndexesOf(value, numberSeparator).filter(index => position >= index);

  const beforeIndexes = calculateIndexes(beforeValue);
  const afterIndexes = calculateIndexes(afterValue);

  const offset =
    afterIndexes.length - beforeIndexes.length + (beforeIndexes.includes(position) ? 1 : 0);

  return Math.max(0, position + offset);
};

export const getValueAsNumber = (value: string, separatorType: SeparatorType) => {
  const [, decimalSeparator] = getNumberAndDecimalSeparators(separatorType);
  const [wholeNumber, decimalNumber = '0'] = value.split(decimalSeparator);
  const sanitizedWholeNumber = wholeNumber.replace(/^(?=[-])+|[^0-9]+/g, ''); // NOSONAR - regex woks ad intended
  return Number(`${sanitizedWholeNumber}.${decimalNumber}`);
};

export const isCursorOnSeparator = (
  value: string,
  cursorLocation: number,
  separatorType: SeparatorType
) => {
  const [numberSeparator] = getNumberAndDecimalSeparators(separatorType);
  return findIndexesOf(value, numberSeparator).includes(cursorLocation);
};
