import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { OperationPreferencesDto } from '../../types/dto/OperationPreferencesDto';
import { loadersActions } from '../loaders/actions';
import { operationPreferencesActions } from './actions';

export function* getOperationPreferences(
  action: ActionType<typeof operationPreferencesActions.get>
) {
  try {
    yield put(loadersActions.operationPreferences.activate());
    const operationPreferences: AxiosResponse<OperationPreferencesDto> = yield call(
      insuranceAPI.getOperationPreferences,
      action.payload
    );
    yield put(operationPreferencesActions.add(operationPreferences.data));
    yield put(loadersActions.operationPreferences.disable());
  } catch (e) {
    yield put(loadersActions.operationPreferences.disable());
    console.log(e);
  }
}

export function* updateOperationPreferences(
  action: ActionType<typeof operationPreferencesActions.save>
) {
  try {
    yield put(loadersActions.operationPreferences.activate());
    yield call(insuranceAPI.postOperationPreferences, action.meta, action.payload);
    yield put(operationPreferencesActions.add(action.payload));
    yield put(loadersActions.operationPreferences.disable());
    toast.success('Preferences saved successfully.');
  } catch (e) {
    yield put(loadersActions.operationPreferences.disable());
    console.log(e);
  }
}

export function* operationPreferencesSagaWatcher() {
  yield all([
    takeLatest(getType(operationPreferencesActions.get), getOperationPreferences),
    takeLatest(getType(operationPreferencesActions.save), updateOperationPreferences)
  ]);
}
