import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { OperationPreferencesDto } from '../../types/dto/OperationPreferencesDto';
import { OperationPreferencesActions, operationPreferencesActions } from './actions';

const initialState: OperationPreferencesDto = {
  quarterPreferences: [
    {
      quarter: 0,
      butterfatTest: 0,
      proteinTest: 0
    }
  ],
  stateCode: '',
  preferredCoverageLevel: 0,
  preferredProtectionLevel: 0,
  preferredClassPriceWeightingFactor: 0,
  preferredComponentPriceWeightingFactor: 0,
  showBasis: false,
  showSubsidy: false,
  showIndemnity: false,
  showAllQuarters: false,
  showProtectionFactorAnalysis: false,
  preferredInsuredShare: 1
};

export const operationPreferencesReducer = (
  state = initialState,
  action: OperationPreferencesActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(operationPreferencesActions.add): {
        draft.quarterPreferences = action.payload.quarterPreferences;
        draft.stateCode = action.payload.stateCode;
        draft.preferredCoverageLevel = action.payload.preferredCoverageLevel;
        draft.preferredProtectionLevel = action.payload.preferredProtectionLevel;
        draft.preferredClassPriceWeightingFactor =
          action.payload.preferredClassPriceWeightingFactor;
        draft.preferredComponentPriceWeightingFactor =
          action.payload.preferredComponentPriceWeightingFactor;
        draft.showBasis = action.payload.showBasis;
        draft.showSubsidy = action.payload.showSubsidy;
        draft.showSubsidy = action.payload.showSubsidy;
        draft.showIndemnity = action.payload.showIndemnity;
        draft.showAllQuarters = action.payload.showAllQuarters;
        draft.showProtectionFactorAnalysis = action.payload.showProtectionFactorAnalysis;
        draft.preferredInsuredShare = action.payload.preferredInsuredShare;
        return;
      }
    }
  });
