import { getType } from 'typesafe-actions';

import { LgmLoaderActions, lgmLoaderActions } from './actions';

interface InitialState {
  premiumLoader: boolean;
  estimatedLoader: boolean;
  lgmPremiumsLoader: boolean;
  lgmActualLoader: boolean;
}

const initialState: InitialState = {
  premiumLoader: true,
  estimatedLoader: true,
  lgmPremiumsLoader: true,
  lgmActualLoader: true
};

const lgmLoaderReducer = (state = initialState, action: LgmLoaderActions) => {
  switch (action.type) {
    case getType(lgmLoaderActions.setLgmEstimatedLoader): {
      return { ...state, estimatedLoader: action.payload };
    }
    case getType(lgmLoaderActions.setLgmPremiumLoader): {
      return { ...state, premiumLoader: action.payload };
    }
    case getType(lgmLoaderActions.setLgmWhatIfActualLoader): {
      return { ...state, lgmActualLoader: action.payload };
    }
    case getType(lgmLoaderActions.setLgmWhatIfPremiumLoader): {
      return { ...state, lgmPremiumsLoader: action.payload };
    }
    default:
      return state;
  }
};

export default lgmLoaderReducer;
