import { CommodityType as TypeOfCommodity } from '@/shared/api/codebook/useGetCommodities';

import { SelectOption } from '../../lrp/components/SelectDropDownFilter';

export const commodityOptions: CommodityOption[] = [
  { name: 'Feeder Cattle', id: 'FeederCattle' },
  { name: 'Fed Cattle', id: 'FedCattle' },
  { name: 'Swine', id: 'Swine' }
];

export const commodityTypes: CommodityType[] = [
  { name: 'Steers Weight 1', id: 'SteersWeight1', label: '809' },
  { name: 'Steers Weight 2', id: 'SteersWeight2', label: '810' },
  { name: 'Heifers Weight 1', id: 'HeifersWeight1', label: '811' },
  { name: 'Heifers Weight 2', id: 'HeifersWeight2', label: '812' },
  { name: 'Brahman Weight 1', id: 'BrahmanWeight1', label: '813' },
  { name: 'Brahman Weight 2', id: 'BrahmanWeight2', label: '814' },
  { name: 'Dairy Weight 1', id: 'DairyWeight1', label: '815' },
  { name: 'Dairy Weight 2', id: 'DairyWeight2', label: '816' },
  { name: 'Steers & Heifers', id: 'SteersHeifers', label: '820' },
  { name: 'No Type Specified', id: 'NoTypeSpecified', label: '997' },
  { name: 'Unborn Swine', id: 'UnbornSwine', label: '821' },
  { name: 'Unborn Steers & Heifers', id: 'UnbornSteersHeifers', label: '817' },
  { name: 'Unborn Brahman', id: 'UnbornBrahman', label: '818' },
  { name: 'Unborn Dairy', id: 'UnbornDairy', label: '819' },
  { name: 'All Types', id: 'AllTypes', label: '' }
];

export const commodities: TypeOfCommodity[] = [
  { label: 'Feeder Cattle', value: '801' },
  { label: 'Fed Cattle', value: '802' },
  { label: 'Swine', value: '815' }
];

export const deductibleOptions: SelectOption[] = [
  { name: '1st ($0-$2)', id: '1' },
  { name: '2nd ($2-$4)', id: '2' },
  { name: '3rd ($4-$6)', id: '3' },
  { name: '4th ($6-$8)', id: '4' },
  { name: '5th ($8-$10)', id: '5' },
  { name: '6th ($10-$12)', id: '6' },
  { name: '7th ($12-$14)', id: '7' },
  { name: '8th ($14-$16)', id: '8' },
  { name: '9th ($16-$18)', id: '9' },
  { name: '10th ($18-$20)', id: '10' }
];

export type CommodityOption = SelectOption;

export interface CommodityType extends SelectOption {
  label: string;
}
