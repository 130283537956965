import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { unsubscribeNewsletterActions } from './actions';

function* getUnsubscribed(action: ActionType<typeof unsubscribeNewsletterActions.getUnsubscribed>) {
  try {
    const { unsubscribeToken, newsletterType, expiredEndorsementType, lrpAlert } = action.payload;
    let endpoint, queryParamName, queryParamValue;
    if (newsletterType) {
      endpoint = '/newsletter/price/unsubscribe';
      queryParamName = 'newsletterType';
      queryParamValue = newsletterType;
    } else if (expiredEndorsementType) {
      endpoint = '/endorsement/unsubscribe';
      queryParamName = 'expiredEndorsementType';
      queryParamValue = expiredEndorsementType;
    } else if (lrpAlert) {
      endpoint = '/alerts/lrp/unsubscribe';
      queryParamName = '';
      queryParamValue = '';
    } else {
      endpoint = '/alerts/unsubscribe';
      queryParamName = '';
      queryParamValue = '';
    }
    const response: AxiosResponse = yield call(
      insuranceAPI.getUnsubscribed,
      unsubscribeToken,
      endpoint,
      queryParamName,
      queryParamValue
    );
    yield put(
      unsubscribeNewsletterActions.setUnsubscribed(response.status >= 200 && response.status < 300)
    );
  } catch (e) {
    yield put(unsubscribeNewsletterActions.setUnsubscribed(false));
    console.error(e);
  }
}
function* UnsubscribeSagaWatcher() {
  yield all([takeLatest(getType(unsubscribeNewsletterActions.getUnsubscribed), getUnsubscribed)]);
}
export default UnsubscribeSagaWatcher;
