import AsyncLock from 'async-lock';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { User } from 'oidc-client-ts';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import userManager from '../../services/userManager';
import { errorResponseStatusHandler } from '../../utils/responseStatusHandler';
import { authActions } from './actions';
import { AuthState } from './reducer';

export const getUser = async () => await userManager.getUser();

let signInPromise: Promise<void> | null;
export function signIn(): Promise<void> {
  if (signInPromise == null) {
    signInPromise = userManager.signinRedirect().then(() => {
      signInPromise = null;
    });
  }

  return signInPromise;
}

let signOutPromise: Promise<void> | null;
export function signOut(): Promise<void> {
  if (signOutPromise == null) {
    localStorage.removeItem('organizationName');
    localStorage.removeItem('agency');
    localStorage.removeItem('insurancePlan');
    signOutPromise = userManager.signoutRedirect().then(() => {
      signOutPromise = null;
    });
  }
  return signOutPromise;
}

let refreshTokenPromise: Promise<void | User | null> | null;
export function refreshToken(): Promise<void | User | null> {
  if (refreshTokenPromise == null) {
    refreshTokenPromise = userManager
      .signinSilent({})
      .then((user: User | null) => {
        refreshTokenPromise = null;
        return user;
      })
      .catch(async (reason: any) => {
        console.log('refreshToken-error');
        console.log(reason);
        await signOut();
      });
  }
  return refreshTokenPromise;
}

export function* updateToken() {
  const user: User | null = yield call(getUser);
  if (user) {
    yield put(authActions.get.authState());
    yield put(authActions.set.expiresAt(user.expires_at));
    yield put(authActions.set.email(user.profile.preferred_username ?? ''));
    yield put(authActions.token.set());
  }
}

export function* getAuthState() {
  const user: User | null = yield call(getUser);
  if (user) {
    if (
      (user.profile.role &&
        typeof user.profile.role === 'string' &&
        user.profile.role === 'Administrator') ||
      (Array.isArray(user.profile.role) &&
        user.profile.role.findIndex((role: string) => role === 'Administrator') !== -1)
    ) {
      yield put(authActions.set.authState(AuthState.globalAdministrator));
    } else if (
      (user.profile.role &&
        typeof user.profile.role === 'string' &&
        user.profile.role === 'Moderator') ||
      (Array.isArray(user.profile.role) &&
        user.profile.role.findIndex((role: string) => role === 'Moderator') !== -1)
    ) {
      yield put(authActions.set.authState(AuthState.insuranceAgentAdministrator));
    } else if (
      (user.profile.role &&
        typeof user.profile.role === 'string' &&
        user.profile.role === 'Agent') ||
      (Array.isArray(user.profile.role) &&
        user.profile.role.findIndex((role: string) => role === 'Agent') !== -1)
    ) {
      yield put(authActions.set.authState(AuthState.insuranceAgent));
    } else {
      yield put(authActions.set.authState(AuthState.producer));
    }
  } else {
    yield put(authActions.set.authState(AuthState.guest));
  }
}

function* authSagaWatcher() {
  yield all([
    takeLatest(getType(authActions.signIn), signIn),
    takeLatest(getType(authActions.signOut), signOut),
    takeLatest(getType(authActions.token.expired), refreshToken),
    takeLatest(getType(authActions.token.update), updateToken),
    takeLatest(getType(authActions.get.authState), getAuthState)
  ]);
}

const lock = new AsyncLock();
export const getAuthenticatedUser = async (): Promise<User | void> => {
  return lock.acquire('auth-token-check', async (): Promise<User | void> => {
    let user: User | null | void = await userManager.getUser();
    if (!user || !user.access_token) {
      console.log('getAuthenticatedUser:no-user');
      return signOut();
    }

    if (user.expires_in && user.expires_in <= 15) {
      user = await refreshToken();
      if (!user || !user.access_token) {
        console.log('getAuthenticatedUser:no-user-after-refresh');
        return signOut();
      }
    }

    return user;
  });
};

export function createApiInstance(config?: AxiosRequestConfig | undefined): AxiosInstance {
  const instance = axios.create(config);

  instance.interceptors.request.use(async config => {
    const user = await getAuthenticatedUser();
    if (!user) return config;
    config.headers['Authorization'] = `${user.token_type} ${user.access_token}`;
    return config;
  });

  instance.interceptors.response.use(
    async response => {
      return response;
    },
    async function (error) {
      if (error.response) {
        const request = error.config;
        const response = error.response;

        if (response.status === 401 && !request._retry) {
          request._retry = true;
          const user = await getAuthenticatedUser();
          if (user == null) {
            return Promise.reject(error);
          }

          axios.defaults.headers.common[
            'Authorization'
          ] = `${user.token_type} ${user.access_token}`;
          return instance(request);
        }

        errorResponseStatusHandler(response);
      }

      return Promise.reject(error);
    }
  );
  return instance;
}

export default authSagaWatcher;
