import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

type Field = { date: Date; value: number };

export interface DairyRevenueProtectionWhatIfValuesState {
  butter: [Field, Field, Field];
  cheese: [Field, Field, Field];
  dryWhey: [Field, Field, Field];
  noFatDryMilk: [Field, Field, Field];
  classIIIMilk: [Field, Field, Field];
  classIVMilk: [Field, Field, Field];
  milkProductionPerCow: Field;
}

const initialState: DairyRevenueProtectionWhatIfValuesState = {
  butter: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  cheese: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  dryWhey: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  noFatDryMilk: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  classIIIMilk: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  classIVMilk: [
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 },
    { date: new Date(), value: 0 }
  ],
  milkProductionPerCow: { date: new Date(), value: 0 }
};

export const dairyRevenueProtectionWhatIfValuesReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) =>
  action.type === getType(dairyRevenueProtectionActions.setWhatIfValues)
    ? { ...state, ...action.payload }
    : state;
