import { ActionType, createAction } from 'typesafe-actions';

import LgmEstimatedDto from '../../../types/Lgm/LgmEstimatedDto';
import { LgmPremium } from '../../../types/Lgm/LgmPremium';
import { LgmRow } from '../../../types/Lgm/LgmRow';

export const lgmActions = {
  initialization: createAction('@lgm_quoter/INITIALIZATION')(),
  addRows: createAction('@lgm_quoter/ADD_ROWS')<LgmRow[]>(),
  changeRow: createAction('@lgm_quoter/CHANGE_ROWS')<
    { milk: number; corn: number; sbm: number },
    number
  >(),
  updateRow: createAction('@lgm_quoter/UPDATE_ROWS')<{
    values: { milk: number; corn: number; sbm: number };
    id: number;
  }>(),
  getLgmEstimated: createAction('@lgm_quoter/GET_ESTIMATED')(),
  saveLgmEstimated: createAction('@lgm_quoter/SAVE_ESTIMATED')<LgmEstimatedDto>(),
  getLgmPremium: createAction('@lgm_quoter/GET_LGM_PREMIUM')(),
  saveLgmPremium: createAction('@lgm_quoter/SAVE_LGM_PREMIUM')<LgmPremium>()
};

export type LGMActions = ActionType<typeof lgmActions>;
