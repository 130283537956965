import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { UnsubscribeNewsletterActions, unsubscribeNewsletterActions } from './actions';

interface InitialState {
  unsubscribed: boolean | undefined;
}

const initialState: InitialState = {
  unsubscribed: undefined
};

export const unsubscribeReducer = (state = initialState, action: UnsubscribeNewsletterActions) =>
  produce(state, draft => {
    if (getType(unsubscribeNewsletterActions.setUnsubscribed) == action.type) {
      draft.unsubscribed = action.payload;
    }
  });
