import { getType } from 'typesafe-actions';

import { AgencyLogoActions, agencyLogoActions } from './actions';

interface InitialState {
  agencyLogoURL: string;
}

const initialState: InitialState = {
  agencyLogoURL: ''
};

export const agencyLogoReducer = (state = initialState, action: AgencyLogoActions) => {
  switch (action.type) {
    case getType(agencyLogoActions.saveAgencyLogo): {
      const newState = { ...state };
      newState.agencyLogoURL = action.payload;
      return newState;
    }
    default:
      return state;
  }
};
