import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

export type QuarterListItem = {
  quarter: string;
  practiceCode: number;
  proteinTest: number;
  butterfatTest: number;
  componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
  classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
  deviation?: Record<'class' | 'component', boolean>;
};
export type QuarterList = Array<QuarterListItem>;
export type ComponentPriceWeightingFactorRestrictedValue = (1 | 0) | null;
export type ClassPriceWeightingFactorRestrictedValue = (1 | 0) | null;
export interface DairyRevenueProtectionSettingsState {
  state: number;
  quarter: number;
  canSell: boolean;
  canSellNote: string;
  declaredMilkProduction: number;
  salesEffectiveDate: Date;
  calendarDate: Date;
  coverageLevel: number;
  declaredButterfatTest: number;
  protectionFactor: number;
  declaredProteinTest: number;
  declaredClassPriceWeightingFactor: number;
  declaredComponentPriceWeightingFactor: number;
  showBasis: boolean;
  showIndemnity: boolean;
  showSubsidy: boolean;
  showAllQuarters: boolean;
  showProtectionFactorAnalysis: boolean;
  quarterList: QuarterList;
  isSalesDateAvailable: boolean;
  reinsuranceYear: number;
  share: number;
}

export const initialState: DairyRevenueProtectionSettingsState = {
  state: 6,
  quarter: 0,
  canSell: false,
  canSellNote: '',
  declaredMilkProduction: 1000000,
  salesEffectiveDate: new Date(),
  calendarDate: new Date(),
  coverageLevel: 0.95,
  declaredButterfatTest: 3.9,
  protectionFactor: 1,
  declaredProteinTest: 3.2,
  declaredClassPriceWeightingFactor: 1,
  declaredComponentPriceWeightingFactor: 1,
  quarterList: [],
  showBasis: false,
  showIndemnity: false,
  showSubsidy: false,
  showAllQuarters: false,
  showProtectionFactorAnalysis: false,
  // TODO: get list from API
  isSalesDateAvailable: true,
  reinsuranceYear: 0,
  share: 1
};

export const dairyRevenueProtectionSettingsReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => {
  switch (action.type) {
    case getType(dairyRevenueProtectionActions.settings.setCoverageLevel):
      return { ...state, coverageLevel: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest):
      return { ...state, declaredButterfatTest: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setDeclaredClassPriceWeightingFactor):
      return { ...state, declaredClassPriceWeightingFactor: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setDeclaredComponentPriceWeightingFactor):
      return { ...state, declaredComponentPriceWeightingFactor: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setDeclaredMilkProduction):
      return { ...state, declaredMilkProduction: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setDeclaredProteinTest):
      return { ...state, declaredProteinTest: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setProtectionFactor):
      return { ...state, protectionFactor: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setCanSell):
      return {
        ...state,
        canSell: action.payload,
        canSellNote: action.meta ? action.meta : state.canSellNote
      };
    case getType(dairyRevenueProtectionActions.settings.setQuarter): {
      const allQuarterData = [...state.quarterList];
      const defaultQuarterData = allQuarterData.find(q => q.practiceCode === action.payload);
      return {
        ...state,
        declaredButterfatTest: defaultQuarterData
          ? defaultQuarterData.butterfatTest
          : state.declaredButterfatTest,
        declaredProteinTest: defaultQuarterData
          ? defaultQuarterData.proteinTest
          : state.declaredProteinTest,
        quarter: action.payload
      };
    }
    case getType(dairyRevenueProtectionActions.settings.setCalendarDate):
      return { ...state, calendarDate: action.payload, canSell: false, canSellNote: '' };
    case getType(dairyRevenueProtectionActions.settings.setState):
      return { ...state, state: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShare):
      return { ...state, share: action.payload };
    case getType(dairyRevenueProtectionActions.startSave): {
      const defaults = action.payload.practices.length
        ? action.payload.practices[0]
        : {
            butterfatTest: 3.9,
            proteinTest: 3.2,
            practiceCode: 0,
            componentPriceWeightingFactorRestrictedValue: null,
            classPriceWeightingFactorRestrictedValue: null
          };

      const componentWeightingFactor =
        defaults.componentPriceWeightingFactorRestrictedValue ??
        action.payload.preferredComponentPriceWeightingFactor;

      const classPriceWeightingFactor =
        defaults.classPriceWeightingFactorRestrictedValue ??
        action.payload.preferredClassPriceWeightingFactor;

      return {
        ...state,
        coverageLevel: action.payload.preferredCoverageLevel || 0.95,
        declaredButterfatTest: defaults.butterfatTest || 3.9,
        protectionFactor: action.payload.preferredProtectionLevel || 1,
        declaredProteinTest: defaults.proteinTest || 3.2,
        declaredClassPriceWeightingFactor: classPriceWeightingFactor,
        declaredComponentPriceWeightingFactor: componentWeightingFactor,
        state: Number(action.payload.stateCode),

        canSell: action.payload.canSell,
        canSellNote: action.payload.canSellNote,
        salesEffectiveDate: action.payload.salesEffectiveDate,
        calendarDate: action.payload.calendarDate,
        quarterList: action.payload.practices,
        quarter: defaults.practiceCode,
        reinsuranceYear: action.payload.reinsuranceYear,

        showBasis: action.payload.showBasis || false,
        showIndemnity: action.payload.showIndemnity || false,
        showSubsidy: action.payload.showSubsidy || false,
        showAllQuarters: action.payload.showAllQuarters || false,
        showProtectionFactorAnalysis: action.payload.showProtectionFactorAnalysis || false,
        share: action.payload.share || 1
      };
    }
    case getType(dairyRevenueProtectionActions.settings.updatePractice): {
      const practiceIndex = action.payload.practices.findIndex(
        p => p.practiceCode === state.quarter
      );
      const practice = action.payload.practices[practiceIndex];
      const isComponentPriceWeightingFactorRestricted =
        practiceIndex !== -1 &&
        practice.componentPriceWeightingFactorRestrictedValue !== null &&
        practice.componentPriceWeightingFactorRestrictedValue !== undefined;
      const isClassPriceWeightingFactorRestricted =
        practiceIndex !== -1 &&
        practice.classPriceWeightingFactorRestrictedValue !== null &&
        practice.classPriceWeightingFactorRestrictedValue !== undefined;

      return {
        ...state,
        salesEffectiveDate: action.payload.salesEffectiveDate,
        quarterList: action.payload.practices,
        canSell: action.payload.canSell,
        canSellNote: action.payload.canSellNote,
        reinsuranceYear: action.payload.reinsuranceYear,
        declaredComponentPriceWeightingFactor: isComponentPriceWeightingFactorRestricted
          ? practice.componentPriceWeightingFactorRestrictedValue !== null
            ? practice.componentPriceWeightingFactorRestrictedValue
            : state.declaredComponentPriceWeightingFactor
          : state.declaredComponentPriceWeightingFactor,
        declaredClassPriceWeightingFactor: isClassPriceWeightingFactorRestricted
          ? practice.classPriceWeightingFactorRestrictedValue !== null
            ? practice.classPriceWeightingFactorRestrictedValue
            : state.declaredClassPriceWeightingFactor
          : state.declaredClassPriceWeightingFactor
      };
    }
    case getType(dairyRevenueProtectionActions.settings.updatePracticeQuarter):
      return { ...state, quarter: action.payload };
    case getType(dairyRevenueProtectionActions.settings.updateAll):
      return { ...state, ...action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShowBasis):
      return { ...state, showBasis: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShowIndemnity):
      return { ...state, showIndemnity: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShowSubsidy):
      return { ...state, showSubsidy: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShowAllQuarters):
      return { ...state, showAllQuarters: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setShowProtectionFactorAnalysis):
      return { ...state, showProtectionFactorAnalysis: action.payload };
    case getType(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable): {
      return { ...state, isSalesDateAvailable: action.payload };
    }
    case getType(dairyRevenueProtectionActions.settings.setQuarterDeviation): {
      const { practiceCode, deviation } = action.payload;
      const quarterList = state.quarterList.map(quarter => {
        if (quarter.practiceCode === practiceCode) {
          return { ...quarter, deviation };
        }
        return quarter;
      });
      return { ...state, quarterList };
    }
    default:
      return state;
  }
};
