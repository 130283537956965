import * as React from 'react';
import styled from 'styled-components/macro';

interface Props {
  height: number | string;
  width: number | string;
}

const Loader = styled.svg<{ width: number | string; height: number | string }>`
  width: ${({ width }) => (width ? width : '100px')};
  height: ${({ height }) => (height ? height : '30px')};
  border-radius: 5px;
  background-image: linear-gradient(
    90deg,
    #f3f3f3,
    #f3f3f3,
    #f3f3f3,
    #f3f3f3,
    #ecebeb,
    #f3f3f3,
    #f3f3f3,
    #f3f3f3,
    #f3f3f3
  );
  background-size: 300% 100%;
  animation: progress 1s ease-out infinite;

  @keyframes progress {
    100% {
      background-position: 0 20%;
    }
    75% {
      background-position: 20% 40%;
    }
    50% {
      background-position: 40% 60%;
    }
    25% {
      background-position: 60% 80%;
    }
    0% {
      background-position: 80% 100%;
    }
  }
`;

const ContentLoaderRectangle: React.FC<Props> = ({ height, width }) => (
  <Loader width={width} height={height} />
);

export default ContentLoaderRectangle;
