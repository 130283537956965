import { ActionType, createAction } from 'typesafe-actions';

import { DuplicateCodeTypes } from '@/shared/constants/enums';

import { LrpCoverageRequestUpdateModel } from '../../scenes/lrp/types/LrpGetFilters';
import { CalculatedValuesEndorsementBinding } from '../../types/Endorsement/CalculatedValuesEndorsementBinding';
import {
  CoverageParameters,
  CoverageParametersReviewRequest
} from '../../types/Endorsement/CoverageParameters';
import { Endorsement } from '../../types/Endorsement/Endorsement';
import EndorsementModalPracticeData from '../../types/Endorsement/EndorsementModalPracticeData';
import EndorsementModalUpdateData from '../../types/Endorsement/EndorsementModalUpdateData';
import {
  EndorsementPayload,
  EndorsementPayloadBinding
} from '../../types/Endorsement/EndorsementPayload';
import { EndorsementSummary } from '../../types/Endorsement/EndorsementSummary';
import { Practice, PracticeName } from '../../types/Practice';
import {
  CoverageRequestDto,
  CoverageRequestHistoryDto,
  DrpCoverageRequestUpdateModel
} from '../../types/dto/CoverageRequest';
import { EndorsementDrp, EndorsementLrp } from '../../types/dto/EndorsementDto';
import InsurancePlan from '../../types/dto/InsurancePlan';

export const endorsementActions = {
  getEndorsements: createAction('@endorsement/GET_ENDORSEMENTS')(),
  getCoverageRequest: createAction('@endorsement/GET_COVERAGEREQUEST')<
    { operationName: string; refId: string },
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >(),
  getCoverageRequests: createAction('@endorsement/GET_COVERAGEREQUESTS')<
    {
      operationName: string;
      insurancePlan: InsurancePlan | undefined;
    },
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >(),
  setCoverageRequestFetchTimeStamp: createAction(
    '@endorsement/SET_COVERAGEREQUESTSFETCHTIMESTAMP'
  )(),
  setCoverageRequest: createAction('@endorsement/SET_COVERAGEREQUEST')<
    CoverageRequestDto | undefined
  >(),
  setCoverageRequests: createAction('@endorsement/SET_COVERAGEREQUESTS')<CoverageRequestDto[]>(),
  resetCoverageRequests: createAction('@endorsement/RESET_COVERAGEREQUESTS')(),
  getCoverageRequestHistory: createAction('@endorsement/GET_COVERAGE_REQUEST_HISTORY')<{
    referenceIdentifier: string;
    operationName: string;
  }>(),
  saveCoverageRequestHistory: createAction('@endorsement/SAVE_COVERAGE_REQUEST_HISTORY')<
    CoverageRequestHistoryDto[]
  >(),
  getEndorsementsByCoverageRequest: createAction('@endorsement/GET_COVERAGEREQUESTREFIDENTIFIER')<
    {
      operationName: string;
      refId: string;
      insurancePlan: InsurancePlan;
    },
    ((e: boolean) => void) | undefined
  >(),
  setCoverageRequestEndorsements: createAction('@endorsement/SAVE_COVERAGEREQUESTENDORSEMENTS')<
    EndorsementDrp[] | EndorsementLrp[]
  >(),
  saveQuarterListCoverageRequestByRefIdentifier: createAction('@endorsement/SAVE_QUARTERLIST')<
    PracticeName[]
  >(),
  addAllEndorsements: createAction('@endorsement/ADD_ALL_ENDORSEMENTS')<string, Endorsement[]>(),
  createEndorsement: createAction('@endorsement/CREATE_ENDORSEMENT')<
    {
      endorsement: EndorsementPayload;
      isAgentAdd: boolean;
      reinsuranceYear?: number;
      quarter?: number;
    },
    () => void
  >(),
  createEndorsementBinding: createAction('@endorsement/CREATE_ENDORSEMENT_BINDING')<
    { endorsement: EndorsementPayloadBinding },
    {
      onAddCallback: () => void;
      handleResponseCode: (res: DuplicateCodeTypes[]) => void;
    }
  >(),
  updateOperationBindingRequestStatus: createAction(
    '@endorsement/UPDATE_OPERATION_BINDING_REQUEST_STATUS'
  )<
    {
      operationName: string;
      referenceIdentifier: string;
      status: string;
      insurancePlan: InsurancePlan;
    },
    React.Dispatch<React.SetStateAction<boolean | undefined>>
  >(),
  getEndorsement: createAction('@endorsement/GET_ENDORSEMENT')<string, string>(),
  addEndorsement: createAction('@endorsement/ADD_ENDORSEMENT')<string, Endorsement>(),
  getFilteredEndorsements: createAction('@endorsement/GET_FILTERED_ENDORSEMENTS')<{
    reinsuranceYear: number;
    quarter: number;
    tagNames: string[];
  }>(),
  addFilteredEndorsements: createAction('@endorsement/ADD_FILTERED_ENDORSEMENTS')<
    string,
    Endorsement[]
  >(),
  getEndorsementSummary: createAction(
    '@endorsement/GET_ENDORSEMENTS_SUMMARY',
    (defaultValue: string[] = []) => defaultValue
  )(),
  addAllEndorsementSummaries: createAction('@endorsement/ADD_ALL_ENDORSEMENT_SUMMARIES')<
    string,
    EndorsementSummary[]
  >(),
  updateEndorsement: createAction('@endorsement/UPDATE_ENDORSEMENT')<{
    operation: string;
    id: number;
    data: Endorsement;
  }>(),
  editPendingEndorsement: createAction('@endorsement/EDIT_PENDING_ENDORSEMENT')<
    { data: EndorsementPayload; id: number },
    () => void
  >(),
  editEndorsement: createAction('@endorsement/EDIT_ENDORSEMENT')<
    { data: EndorsementPayload; id: number; reinsuranceYear: number; quarter: number },
    () => void
  >(),
  deleteEndorsement: createAction('@endorsement/DELETE_PRODUCER_ENDORSEMENT')<number>(),
  removeEndorsement: createAction('@endorsement/REMOVE_ENDORSEMENT')<{
    operation: string;
    id: number;
  }>(),
  recalculateEndorsements: createAction('@endorsement/RECALCULATE_ENDORSEMENTS')<{
    reinsuranceYear: number;
    quarter: number;
  }>(),
  endorsementModalInit: createAction('@endorsement/ENDORSEMENT_MODAL_INIT')<{
    operationName: string;
    stateCode: string;
  }>(),
  calculateSingleCoverageEndorsementBinding: createAction(
    '@endorsement/CALCULATE_SINGLE_COVERAGE'
  )<{
    parameters: CoverageParameters;
    callback: (calculation: CalculatedValuesEndorsementBinding) => void;
  }>(),
  calculateSingleCoverageEndorsementBindingReviewCoverage: createAction(
    '@endorsement/CALCULATE_SINGLE_COVERAGEREVIEW'
  )<{
    operationName: string;
    parameters: CoverageParametersReviewRequest;
    callback: (calculation: CalculatedValuesEndorsementBinding) => void;
  }>(),
  changeStatusRequestCoverage: createAction('@endorsement/CHANGE_STATUS_REQUEST_COVERAGE')<
    {
      operationName: string;
      insurancePlan: InsurancePlan;
      refIdentifier: string;
      status: string;
    },
    React.Dispatch<React.SetStateAction<boolean | null>> | undefined
  >(),
  updateRequestCoverage: createAction('@endorsement/UPDATE_REQUEST_COVERAGE')<
    {
      operationName: string;
      insurancePlan: InsurancePlan;
      refIdentifier: string;
      data: LrpCoverageRequestUpdateModel | DrpCoverageRequestUpdateModel;
    },
    {
      setModifyingCoverageRequest?: React.Dispatch<React.SetStateAction<boolean | null>>;
      handleResponseCode?: (res: DuplicateCodeTypes[]) => void;
    }
  >(),
  modal: {
    setModalData: createAction('@endorsement/SET_MODAL_DATA')<EndorsementModalUpdateData>(),
    initModalPracticeData: createAction('@endorsement/INIT_MODAL_PRACTICE_DATA')<Practice>(),
    setModalPracticeData: createAction(
      '@endorsement/SET_MODAL_PRACTICE_DATA'
    )<EndorsementModalPracticeData>(),
    setModalState: createAction('@endorsement/SET_MODAL_STATE')<number>(),
    setModalQuarter: createAction('@endorsement/SET_MODAL_QUARTER')<number>(),
    setModalDeclaredMilkProduction: createAction('@endorsement/SET_MODAL_DMP')<number>(),
    setModalCalendarDate: createAction('@endorsement/SET_MODAL_CALENDAR_DATE')<Date>(),
    setModalCoverageLevel: createAction('@endorsement/SET_MODAL_COVERAGE')<number>(),
    setModalProtectionFactor: createAction('@endorsement/SET_MODAL_PROTECTION')<number>(),
    setModalDeclaredButterfatTest: createAction('@endorsement/SET_MODAL_D_BUTTERFAT')<number>(),
    setModalDeclaredProteinTest: createAction('@endorsement/SET_MODAL_D_PROTEIN')<number>(),
    setModalDeclaredClassPriceWeightingFactor: createAction(
      '@endorsement/SET_MODAL_DCPWF'
    )<number>(),
    setModalDeclaredComponentPriceWeightingFactor: createAction(
      '@endorsement/SET_MODAL_DCompPWF'
    )<number>(),
    setModalSalesEffectiveDateWithoutPracticeFetch: createAction(
      '@endorsement/SET_MODAL_SED_WITHOUT_FETCH'
    )<Date>(),
    setIsSalesDateAvailable: createAction('@endorsement/SET_IS_SALES_DATE_AVAILABLE')<boolean>(),
    setModalShare: createAction('@endorsement/SET_MODAL_SHARE')<number>()
  }
};

export type EndorsementActions = ActionType<typeof endorsementActions>;
