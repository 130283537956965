import { all, call, debounce, put, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { ProjectionAnalysisDataDto } from '../../types/dto/ProjectionAnalysisDataDto';
import { ProjectionAnalysisParametersDto } from '../../types/dto/ProjectionAnalysisParametersDto';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { projectionDataActions } from './actions';
import { getProjectionDataNextYearSettings } from './selectors';

function* getProjectionDataProjectionModel(
  action: ActionType<typeof projectionDataActions.getProjectionModel>
): any {
  try {
    yield put(loadersActions.revenueProjection.activate());

    const operationName = yield select(getOperationName);
    const response: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getProjectedAnalysis,
      operationName,
      action.payload,
      { projectionModel: action.meta }
    );

    toast.success(
      'You changed Revenue Projection Model. The basis is calculated based on historical data. If you want to save this data select Save.',
      { position: 'top-center' }
    );

    yield put(projectionDataActions.save(response.data));
    yield put(loadersActions.revenueProjection.disable());
  } catch (error: any) {
    toast.error(error.message);
  }
}

function* getProjectionData(action: ActionType<typeof projectionDataActions.get>): any {
  try {
    yield put(loadersActions.revenueProjection.activate());
    const operationName = yield select(getOperationName);
    const response: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getProjectedAnalysis,
      operationName,
      action.payload,
      action.meta
    );

    const nextYearSettings = yield select(getProjectionDataNextYearSettings);
    const nextYearResponse: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getProjectedAnalysis,
      operationName,
      action.payload + 1,
      nextYearSettings || {}
    );

    yield put(projectionDataActions.save(response.data));
    yield put(projectionDataActions.nextYearSave(nextYearResponse.data));
    yield put(loadersActions.revenueProjection.disable());
  } catch (error: any) {
    toast.error(error.message);
  }
}

function* getProjectionDataForSpecificYear(
  action: ActionType<typeof projectionDataActions.getOnBlur>
): any {
  try {
    yield put(loadersActions.revenueProjection.activate());
    const operationName = yield select(getOperationName);
    const response: AxiosResponse<ProjectionAnalysisDataDto> = yield call(
      insuranceAPI.getProjectedAnalysis,
      operationName,
      action.payload,
      action.meta
    );

    yield put(projectionDataActions.save(response.data));
    yield put(loadersActions.revenueProjection.disable());
  } catch (error: any) {
    toast.error(error.message);
  }
}

function* getProjectionSettings(
  action: ActionType<typeof projectionDataActions.settings.get>
): any {
  try {
    yield put(loadersActions.revenueProjection.activate());

    const operationName = yield select(getOperationName);

    const nextYearResponse: AxiosResponse<ProjectionAnalysisParametersDto> = yield call(
      insuranceAPI.getProjectedAnalysisSettings,
      operationName,
      action.payload + 1
    );
    if (nextYearResponse.status === 204) {
      yield put(projectionDataActions.settings.nextYearSave(null));
    } else {
      yield put(projectionDataActions.settings.nextYearSave(nextYearResponse.data));
    }

    const response: AxiosResponse<ProjectionAnalysisParametersDto> = yield call(
      insuranceAPI.getProjectedAnalysisSettings,
      operationName,
      action.payload
    );
    if (response.status === 204) {
      yield put(projectionDataActions.settings.save(null));
      yield put(projectionDataActions.get(action.payload, {}));
    } else {
      const { year, ...params } = response.data;
      yield put(projectionDataActions.settings.save({ year, ...params }));
      yield put(projectionDataActions.get(year, params));
    }
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* updateProjectionSettings(
  action: ActionType<typeof projectionDataActions.settings.update>
): any {
  try {
    const operationName = yield select(getOperationName);
    yield call(insuranceAPI.updateProjectedAnalysisSettings, operationName, action.payload);

    const { year, ...params } = action.payload;
    yield put(projectionDataActions.settings.save({ year, ...params }));

    yield toast.success('Changes Saved!', {
      position: 'top-right'
    });

    yield put(projectionDataActions.get(year, params));
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* projectionDataSagaWatcher() {
  yield all([
    debounce(
      800,
      getType(projectionDataActions.getProjectionModel),
      getProjectionDataProjectionModel
    ),
    takeLatest(getType(projectionDataActions.get), getProjectionData),
    takeLatest(getType(projectionDataActions.getOnBlur), getProjectionDataForSpecificYear),
    takeLatest(getType(projectionDataActions.settings.get), getProjectionSettings),
    takeLatest(getType(projectionDataActions.settings.update), updateProjectionSettings)
  ]);
}

export default projectionDataSagaWatcher;
