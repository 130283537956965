import React from 'react';

import { SeparatorType } from '@/shared/components/common/NumberInput/utils';

import useNumberInput from '../hooks/useNumberInput';

export interface NumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChange?: (value: number) => void;
  decimal?: number;
  separatorType?: SeparatorType;
  width?: number | string;
  loading?: boolean;
  disabled?: boolean;
  fontSize?: number;
  fontWeight?: number;
  fontFamily?: string;
  marginTop?: number;
  paddingTop?: number;
  paddingBottom?: number;
  height?: number | string;
  backgroundColor?: string;
  dataCy?: string;
}

const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      value: valueProp,
      decimal = 0,
      separatorType = 'us',
      onValueChange,
      onKeyDown,
      onClick,
      dataCy,
      ...props
    },
    ref: React.Ref<HTMLInputElement | null>
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle(ref, () => inputRef.current);

    const { value, handleChange, handleClick, handleKeyDown } = useNumberInput({
      inputRef,
      valueProp,
      decimal,
      separatorType,
      onValueChange,
      onKeyDown,
      onClick
    });

    return (
      <input
        type="text"
        ref={inputRef}
        value={value}
        data-cy={dataCy}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        {...props}
      />
    );
  }
);

NumberInput.displayName = 'NumberInput';
export default NumberInput;
