import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { SagaReturnType, all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import sortObjectByKey from '@/utils/sortObjectByKey';

import { loadersActions } from '@/ducks/loaders/actions';

import AgentDto from '@/types/dto/AgentDto';

import SortDirection from '../../../enums/SortDirection';
import insuranceAPI from '../../../services/insuranceService';
import organizationAPI from '../../../services/organizationService';
import { Agency } from '../../../types/Agency';
import Agent, { AgentLicense } from '../../../types/Agent/Agent';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { adminActions } from '../actions';
import { getAllInsuranceAgents } from '../selectors';

export function* getAgencyInsuranceAgents(
  action: ActionType<typeof adminActions.insuranceAgents.getAllAgencyInsuranceAgents>
) {
  try {
    yield put(loadersActions.adminAgents.activate());
    yield put(loadersActions.adminAgentsModal.activate());
    const agents: AxiosResponse<AgentDto[]> = yield call(
      insuranceAPI.getAgencyAgents,
      action.payload.agencyName,
      action.payload.bindingRequirements
    );

    yield put(
      adminActions.insuranceAgents.addAllInsuranceAgents(agents.data, action.payload.agencyName)
    );
    yield put(
      adminActions.insuranceAgents.sort(
        { sort: 'firstName', direction: SortDirection.ASCENDING },
        action.payload.agencyName
      )
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.adminAgents.disable());
    yield put(loadersActions.adminAgentsModal.disable());
  }
}

export function* addInsuranceAgent(
  action: ActionType<typeof adminActions.insuranceAgents.addInsuranceAgent>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(insuranceAPI.createAgencyAgent, agency.name, action.payload);
    const newUser: AxiosResponse<AgentDto> = yield call(
      insuranceAPI.getAgencyAgent,
      agency.name,
      action.payload.email
    );

    yield put(adminActions.insuranceAgents.newInsuranceAgent(newUser.data, agency.name));
    yield toast.success('Insurance Agent Created');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
  }
}

export function* removeInsuranceAgent(
  action: ActionType<typeof adminActions.insuranceAgents.removeInsuranceAgent>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(insuranceAPI.deleteAgencyAgent, agency.name, action.payload);
    yield put(adminActions.insuranceAgents.deleteInsuranceAgent(action.payload, agency.name));
    yield toast.success('Successfully deleted agent');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
  }
}

export function* editInsuranceAgent(
  action: ActionType<typeof adminActions.insuranceAgents.editInsuranceAgent>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(insuranceAPI.updateAgencyAgent, agency.name, action.meta.userId, action.payload);
    const { licences } = action.payload;

    const { oldLicenses } = action.meta;
    const newLicenses = licences.filter(license => !oldLicenses.includes(license));

    if (oldLicenses.length > 0) {
      for (const license of oldLicenses) {
        if (!licences.includes(license)) {
          yield call(
            insuranceAPI.deleteAgentLicense,
            agency.name,
            action.payload.email,
            license.stateCode
          );
        }
      }
    }
    if (newLicenses.length > 0) {
      for (const license of newLicenses) {
        yield call(insuranceAPI.createAgentLicense, agency.name, action.payload.email, license);
      }
    }

    yield put(
      adminActions.insuranceAgents.updateInsuranceAgent(action.payload, {
        ...action.meta,
        agency: agency.name
      })
    );

    const agents: AxiosResponse<AgentDto[]> = yield call(
      insuranceAPI.getAgencyAgents,
      agency.name,
      true
    );

    yield put(adminActions.insuranceAgents.addAllInsuranceAgents(agents.data, agency.name));

    yield put(
      adminActions.insuranceAgents.sort(
        { sort: 'firstName', direction: SortDirection.ASCENDING },
        agency.name
      )
    );

    yield toast.success('Insurance Agent Updated');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta.cb();
  }
}

export function* addAgentRole(
  action: ActionType<typeof adminActions.insuranceAgents.addAgentRole>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const { organizationName, userName, role } = action.payload;
    yield call(organizationAPI.addUserRole, organizationName, userName, role);
    yield put(adminActions.insuranceAgents.addAdminAgentRole({ organizationName, userName, role }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta();
  }
}

export function* removeAgentRole(
  action: ActionType<typeof adminActions.insuranceAgents.removeAgentRole>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const { organizationName, userName, role } = action.payload;
    yield call(organizationAPI.removeUserRole, organizationName, userName, role);
    yield put(adminActions.insuranceAgents.removeAdminAgentRole({ organizationName, userName }));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    action.meta();
  }
}

export function* resendInvitation(
  action: ActionType<typeof adminActions.insuranceAgents.resendInvitation>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(insuranceAPI.resendAgentInvitation, agency.name, action.payload);
    toast.success('Invitation sent to agent ' + action.payload);
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta();
  }
}

type AllAgentsType = SagaReturnType<typeof getAllInsuranceAgents>;
export function* sortAgencyAgents({
  payload: { sort, direction },
  meta
}: ActionType<typeof adminActions.insuranceAgents.sort>) {
  const allAgents: AllAgentsType = yield select(getAllInsuranceAgents);
  if (allAgents[meta]) {
    const sortedAgents = [...allAgents[meta]].sort(sortObjectByKey(sort as keyof Agent, direction));

    yield put(adminActions.insuranceAgents.addAllInsuranceAgents(sortedAgents, meta));
  }
}

export function* getAgentLicenses(
  action: ActionType<typeof adminActions.insuranceAgents.getAgentLicenses>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    const licenses: AxiosResponse<AgentLicense[]> = yield call(
      insuranceAPI.getAgentLicenses,
      agency.name,
      action.payload.userId
    );
    yield put(
      adminActions.insuranceAgents.setAgentLicenses({
        userId: action.payload.userId,
        organizationName: agency.name,
        licenses: licenses.data
      })
    );
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* createAgentLicense(
  action: ActionType<typeof adminActions.insuranceAgents.createLicense>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    const { license, userId, oldLicense } = action.payload;
    let updated = false;
    if (oldLicense) {
      yield call(insuranceAPI.deleteAgentLicense, agency.name, userId, oldLicense.stateCode);
      yield put(
        adminActions.insuranceAgents.removeLicense({
          organizationName: agency.name,
          userId,
          licenseId: oldLicense.stateCode
        })
      );
      updated = true;
    }
    yield call(insuranceAPI.createAgentLicense, agency.name, userId, license);
    yield put(
      adminActions.insuranceAgents.addLicense({
        organizationName: agency.name,
        userId,
        license
      })
    );
    yield toast.success(updated ? 'License updated' : 'License created');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* deleteAgentLicense(
  action: ActionType<typeof adminActions.insuranceAgents.deleteLicense>
) {
  try {
    yield put(loadersActions.spinner.activate());
    const agency: Agency = yield select(getAdminPageAgency);
    yield call(
      insuranceAPI.deleteAgentLicense,
      agency.name,
      action.payload.userId,
      action.payload.licenseId
    );
    yield put(
      adminActions.insuranceAgents.removeLicense({
        organizationName: agency.name,
        userId: action.payload.userId,
        licenseId: action.payload.licenseId
      })
    );
    yield toast.success('License deleted');
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* insuranceAgentsSagaWatcher() {
  yield all([
    takeLatest(
      getType(adminActions.insuranceAgents.getAllAgencyInsuranceAgents),
      getAgencyInsuranceAgents
    ),
    takeLatest(getType(adminActions.insuranceAgents.addInsuranceAgent), addInsuranceAgent),
    takeLatest(getType(adminActions.insuranceAgents.removeInsuranceAgent), removeInsuranceAgent),
    takeLatest(getType(adminActions.insuranceAgents.editInsuranceAgent), editInsuranceAgent),
    takeLatest(getType(adminActions.insuranceAgents.addAgentRole), addAgentRole),
    takeLatest(getType(adminActions.insuranceAgents.removeAgentRole), removeAgentRole),
    takeLatest(getType(adminActions.insuranceAgents.resendInvitation), resendInvitation),
    takeLatest(getType(adminActions.insuranceAgents.sort), sortAgencyAgents),
    takeLatest(getType(adminActions.insuranceAgents.getAgentLicenses), getAgentLicenses),
    takeLatest(getType(adminActions.insuranceAgents.createLicense), createAgentLicense),
    takeLatest(getType(adminActions.insuranceAgents.deleteLicense), deleteAgentLicense)
  ]);
}
