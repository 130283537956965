import axios, { AxiosResponse } from 'axios';

import { WhatsNewJson } from '../scenes/whatsnew/WhatsNew.types';

const whatsNewAPI = {
  getWhatsNew: async (): Promise<AxiosResponse<WhatsNewJson>> => {
    return await axios.get('https://docs.bozic.io/feed.json');
  },
  loadWebpage: async (url: string): Promise<AxiosResponse<string>> => {
    return await axios.get(url);
  }
};

export default whatsNewAPI;
