import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { OperationPriceReportDto } from '../../types/dto/OperationPreferencesDto';
import { PriceReportActions, priceReportActions } from './actions';

interface State {
  priceReport: OperationPriceReportDto[];
  spinner: boolean;
}

const initialState: State = {
  priceReport: [],
  spinner: false
};

export const priceReportReducer = (state = initialState, action: PriceReportActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(priceReportActions.save): {
        draft.priceReport = action.payload;
        return;
      }
      case getType(priceReportActions.setPriceReportSpinner): {
        draft.spinner = action.payload;
        return;
      }
    }
  });
