import { getType } from 'typesafe-actions';

import { Agent } from '../../../types/Agent/Agent';
import { AdminActions, adminActions } from '../actions';

interface InitialState {
  [key: string]: Agent[];
}

const initialState: InitialState = {};

const insuranceAgentsReducer = (state = initialState, action: AdminActions) => {
  switch (action.type) {
    case getType(adminActions.insuranceAgents.addAllInsuranceAgents): {
      return {
        ...state,
        [action.meta]: action.payload
      };
    }
    case getType(adminActions.insuranceAgents.newInsuranceAgent): {
      const { meta, payload } = action;
      return {
        ...state,
        [meta]: [...state[meta], payload]
      };
    }
    case getType(adminActions.insuranceAgents.updateInsuranceAgent): {
      const { meta, payload } = action;
      const { agency, userId } = meta;
      const agents = state[agency];
      const updatedAgents = agents.map(user => {
        if (user.userId === userId) {
          return {
            ...user,
            firstName: payload.firstName,
            lastName: payload.lastName,
            email: payload.email,
            title: payload.title,
            phoneNumber: payload.phoneNumber,
            licences: payload.licences,
            timeZone: payload.timeZone
          };
        }
        return user;
      });
      return {
        ...state,
        [agency]: updatedAgents
      };
    }
    case getType(adminActions.insuranceAgents.deleteInsuranceAgent): {
      const { meta, payload } = action;
      const agents = state[meta];
      const updatedAgents = agents.filter(user => user.userId !== payload);
      return {
        ...state,
        [meta]: updatedAgents
      };
    }
    case getType(adminActions.insuranceAgents.addAdminAgentRole): {
      const { userName, organizationName, role } = action.payload;
      return {
        ...state,
        [organizationName]: state[organizationName]?.map(user => {
          if (user.userName === userName) {
            return {
              ...user,
              isAdmin: role === 'admin'
            };
          }
          return user;
        })
      };
    }
    case getType(adminActions.insuranceAgents.removeAgentRole): {
      const { userName, organizationName } = action.payload;
      return {
        ...state,
        [organizationName]: state[organizationName]?.map(user => {
          if (user.userName === userName) {
            return {
              ...user,
              isAdmin: false
            };
          }
          return user;
        })
      };
    }
    case getType(adminActions.insuranceAgents.setAgentLicenses): {
      const { licenses, organizationName, userId } = action.payload;
      return {
        ...state,
        [organizationName]: state[organizationName]?.map(user => {
          if (user.userName === userId) {
            return {
              ...user,
              licences: licenses
            };
          }
          return user;
        })
      };
    }
    case getType(adminActions.insuranceAgents.removeLicense): {
      const { organizationName, userId, licenseId } = action.payload;
      return {
        ...state,
        [organizationName]: state[organizationName]?.map(user => {
          if (user.userName === userId) {
            return {
              ...user,
              licences: user.licences.filter(license => license.stateCode !== licenseId)
            };
          }
          return user;
        })
      };
    }
    case getType(adminActions.insuranceAgents.addLicense): {
      const { organizationName, userId, license } = action.payload;
      return {
        ...state,
        [organizationName]: state[organizationName]?.map(user => {
          if (user.userName === userId) {
            return {
              ...user,
              licences: [license, ...user.licences]
            };
          }
          return user;
        })
      };
    }
    default:
      return state;
  }
};

export default insuranceAgentsReducer;
