import { createApiInstance } from '../ducks/authentication/sagas';

export const api = createApiInstance({
  baseURL: `${import.meta.env.VITE_APP_CALENDAR_URL}`,
  timeout: 20000
});

const calendarAPI = {
  getCalendar: async () => await api.get('UDM_Calendar_Dairy_Revenue_Protection_Sales_Dates'),
  getQuarters: async () =>
    await api.get('UDM_Calendar_Dairy_Revenue_Protection_Quarterly_Insurance_Periods')
};

export default calendarAPI;
