import { PriceTrendsExpected } from '../../types/PriceTrendsExpected';
import { HistoricalAnalysisExpectedDto } from '../../types/dto/HistoricalAnalysisExpectedDto';

export const mapHistoricalAnalysisExpected = (
  { previous, latest, overall, values, days7, days30 }: HistoricalAnalysisExpectedDto,
  year: number,
  quarter: number
): PriceTrendsExpected => ({
  days7,
  days30,
  previous,
  latest,
  overall,
  values: values.map(({ date, price, value }) => ({
    price,
    date: new Date(date + 'Z'),
    value: value
  })),
  year,
  quarter
});
