import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

const initialState: any = {};

export const dairyRevenueProtectionWhatIfRawReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) => (action.type === getType(dairyRevenueProtectionActions.setRawWhatIf) ? action.payload : state);
