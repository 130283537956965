enum color {
  PRIMARY = '#6EB23F',
  PRIMARY_FINAL = '#5E9732',
  PRIMARY_LIGHT = '#6eb23f80',
  NEW_PRIMARY = '#003359',
  SECONDARY = '#F8B335',
  NEW_SECONDARY = '#C75B12',
  NEGATIVE = '#FF0033',
  PROJECTED = '#31BE82',

  DRP_INPUT = '#749CFD',
  ACCORDION = '#D4DEFA',
  CALENDARTIMEBOX = '#DAE2FB',

  NEUTRAL_DARK = '#909090',
  NEUTRAL_MEDIUM = '#A1A1AA',
  NEUTRAL = '#CCCCCC',
  NEUTRAL_LIGHT = '#DDDDDD',
  BACKGROUND = '#FFFFFF',
  BACKGROUND_SECONDARY = '#F3F4F8',
  BACKGROUND_TABLE_HEADER = '#D5EBC6',
  BACKGROUND_TABLE_GROUP = '#E2F0D8',
  TEXT_PRIMARY = '#333333',
  ICONS_PRIMARY = '#DADCE7',
  ICONS_HEADER = '#707070',

  MAP_CHART_HIGH = '#278B2B',
  MAP_CHART_LOW = '#C8F4CA',

  BAR_CHART_HIGH = '#DBBB0D',
  BAR_CHART_LOW = '#F8E683',

  NOTE = '#FBF3D5',

  ENDORSEMENT_CHART_PREMIUM_PRODUCER = '#FFD700',
  ENDORSEMENT_CHART_REVENUE_CHANGE = '#FFF2CC',
  ENDORSEMENT_CHART_HEDGE_RATIO = '#111111',

  ANNOUNCEMENT_AGENT_BG = '#E9EEFC',
  ANNOUNCEMENT_PRODUCER_BG = '#EAF5E2',
  PDF_TABLE_BORDER = '#D8D8D8',

  DRP_BADGE_TXT = '#8c6',
  DRP_BADGE_BG = '#dfc',
  LRP_BADGE_TXT = '#fb4',
  LRP_BADGE_BG = '#ffd',
  LGM_BADGE_TXT = '#7af',
  LGM_BADGE_BG = '#eef',
  NOT_MATCHED_BADGE_BG = '#FF0000'
}

export default color;
