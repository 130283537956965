import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import insuranceAPI from '../../services/insuranceService';
import { HistoricalData } from '../../types/HistoricalData';
import { HistoricalDataEssentials } from '../../types/HistoricalDataEssentials';
import { HistoricalDataDto } from '../../types/dto/HistoricalDataDto';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { historicalDataActions } from './actions';
import { mapAllHistoricalDataDtoToHistoricalData } from './mapHistoricalDataDtoToHistoricalData';
import { getHistoricalData } from './selectors';

export const sortHistoricalDataByDate = (data: HistoricalData[], direction: SortDirection) => {
  if (direction === SortDirection.ASCENDING) {
    return [...data].sort((a, b) => {
      const date1 = new Date(a.year, a.month - 1);
      const date2 = new Date(b.year, b.month - 1);
      return date1.getTime() - date2.getTime();
    });
  } else {
    return [...data].sort((a, b) => {
      const date1 = new Date(a.year, a.month - 1);
      const date2 = new Date(b.year, b.month - 1);
      return date2.getTime() - date1.getTime();
    });
  }
};

function* getAllHistoricalRecords(action: ActionType<typeof historicalDataActions.getAll>) {
  try {
    yield put(loadersActions.operationOverviewDataTable.activate());
    const historicalDataDto: AxiosResponse<HistoricalDataDto[]> = yield call(
      insuranceAPI.getHistoricalData,
      action.payload
    );
    if (historicalDataDto.data.length) {
      const historicalData = mapAllHistoricalDataDtoToHistoricalData(historicalDataDto);
      yield put(historicalDataActions.addAll(historicalData));
    } else {
      yield put(historicalDataActions.addAll([]));
    }
    yield put(loadersActions.operationOverviewDataTable.disable());
  } catch (e) {
    yield put(loadersActions.operationOverviewDataTable.disable());
    console.log(e);
  }
}

function* deleteHistoricalRecord(action: ActionType<typeof historicalDataActions.delete>): any {
  const operation = yield select(getOperationName);
  try {
    yield put(loadersActions.operationOverviewDataTable.activate());
    yield call(
      insuranceAPI.deleteHistoricalRecord,
      operation,
      action.payload.year,
      action.payload.month
    );
    action.payload.callback();
    yield put(historicalDataActions.remove(action.payload));
    yield put(loadersActions.operationOverviewDataTable.disable());
  } catch (e) {
    yield put(loadersActions.operationOverviewDataTable.disable());
    console.log(e);
  }
}

function* editRecord(action: ActionType<typeof historicalDataActions.editRecord>): any {
  yield put(loadersActions.operationOverviewDataTable.activate());
  const operation = yield select(getOperationName);
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isNew, isEditing, ...essentials } = action.payload;
    yield call(insuranceAPI.editHistoricalRecord, operation, essentials);
    yield action.meta();
    yield put(historicalDataActions.updateRecord(action.payload));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(loadersActions.operationOverviewDataTable.disable());
  }
}

function* sortHistoricalData(
  action: ActionType<typeof historicalDataActions.sortHistoricalData>
): any {
  try {
    const data = yield select(getHistoricalData);
    yield put(historicalDataActions.addAll(sortHistoricalDataByDate(data, action.payload)));
  } catch (e) {
    console.log(e);
  }
}

function* saveAllHistoricalRecords(action: ActionType<typeof historicalDataActions.saveAll>): any {
  yield put(loadersActions.operationOverviewDataTable.activate());
  const operation = yield select(getOperationName);
  const records: HistoricalDataEssentials[] = action.payload.map(r => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { isNew, isEditing, ...record } = r;
    return record;
  });
  try {
    yield call(insuranceAPI.saveAllHistoricalRecords, operation, records);
  } catch (e) {
    console.log(e);
  } finally {
    const mappedRecords = action.payload.map(r => ({ ...r, isNew: false, isEditing: false }));
    yield action.meta([...mappedRecords]);
    yield put(historicalDataActions.addAll(mappedRecords));
    yield put(loadersActions.operationOverviewDataTable.disable());
  }
}

function* historicalDataSagaWatcher() {
  yield all([
    takeLatest(getType(historicalDataActions.getAll), getAllHistoricalRecords),
    takeLatest(getType(historicalDataActions.saveAll), saveAllHistoricalRecords),
    takeLatest(getType(historicalDataActions.editRecord), editRecord),
    takeLatest(getType(historicalDataActions.delete), deleteHistoricalRecord),
    takeLatest(getType(historicalDataActions.sortHistoricalData), sortHistoricalData)
  ]);
}

export default historicalDataSagaWatcher;
