export const coveragePercentageOptions: { name: string; id: string }[] = [
  { name: '1st (100%)', id: '1' },
  { name: '2nd (99%)', id: '2' },
  { name: '3rd (98%)', id: '3' },
  { name: '4th (97%)', id: '4' },
  { name: '5th (96%)', id: '5' },
  { name: '6th (95%)', id: '6' },
  { name: '7th (92.5%)', id: '7' },
  { name: '8th (90%)', id: '8' },
  { name: '9th (87.5%)', id: '9' },
  { name: '10th (85%)', id: '10' },
  { name: '11th (80%)', id: '11' },
  { name: '12th (75%)', id: '12' }
];
