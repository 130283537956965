import React from 'react';

import {
  SeparatorType,
  findDecimalSeparatorIndex,
  fixFirstNumber,
  formatInputValue,
  getNumberAndDecimalSeparators,
  getValidSelectionPosition,
  getValueAsNumber,
  isCursorOnSeparator
} from '@/shared/components/common/NumberInput/utils';

interface Props {
  inputRef: React.RefObject<HTMLInputElement>;
  valueProp: string | number | readonly string[] | undefined;
  separatorType: SeparatorType;
  decimal?: number;
  onValueChange?: (value: number) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
}

const useNumberInput = ({
  inputRef,
  valueProp,
  separatorType,
  decimal,
  onValueChange,
  onKeyDown,
  onClick
}: Props) => {
  const [value, setValue] = React.useState(
    formatInputValue(Number(valueProp ?? 0), separatorType, decimal, decimal)
  );
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let selectionStart = e.target.selectionStart ?? 0;
    const selectionEnd = e.target.selectionEnd ?? 0;

    setSelectionStart(selectionStart);
    setSelectionEnd(selectionEnd);

    const regexMatch = e.target.value.match(/^(?=[-])|[^0-9.,]/g); // NOSONAR - regex woks as intended
    if (regexMatch === null || (regexMatch.length === 1 && regexMatch[0].length === 0)) {
      const [fixedNumber, fixCursor] = fixFirstNumber(e.target.value, separatorType);
      if (fixCursor) {
        selectionStart--;
      }

      const formattedValue = formatInputValue(fixedNumber, separatorType, decimal, decimal);
      const validSelection = getValidSelectionPosition(
        selectionStart,
        value,
        formattedValue,
        separatorType
      );
      setSelectionStart(validSelection);
      setSelectionEnd(validSelection);

      e.target.value = formattedValue;
      e.target.setSelectionRange(validSelection, validSelection);
      setValue(formattedValue);

      if (onValueChange) {
        onValueChange(getValueAsNumber(formattedValue, separatorType));
      }
    } else {
      e.target.value = value;
      e.target.setSelectionRange(selectionStart - 1, selectionEnd - 1);
    }
  };

  const handleSeparatorKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const input = inputRef.current;
    if (!input) return;

    const isAllSelected = input.selectionStart === 0 && input.value.length === input.selectionEnd;
    const [, decimalSeparator] = getNumberAndDecimalSeparators(separatorType);
    const isOnlyMinus =
      input.selectionStart === 1 &&
      input.value.startsWith('-') &&
      (input.value[1] === decimalSeparator || input.value[1] === undefined);

    if (key !== decimalSeparator) {
      e.preventDefault();
    }

    if (isOnlyMinus && !isAllSelected) {
      input.value = `-0${input.value.slice(2)}`;
      input.setSelectionRange(2, 2);
      return;
    }

    if (isAllSelected) {
      input.value = '0';
      return;
    }

    e.preventDefault();

    if (input.value.startsWith(decimalSeparator) && selectionStart === 0) {
      input.value = `0${input.value}`;
      input.setSelectionRange(2, 2);
      setSelectionStart(2);
      setSelectionEnd(2);
    } else if (value.indexOf(decimalSeparator) === selectionStart) {
      input.setSelectionRange(selectionStart + 1, selectionEnd + 1);
      setSelectionStart(selectionStart + 1);
      setSelectionEnd(selectionEnd + 1);
    }
  };

  const handleBackspaceKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const index = findDecimalSeparatorIndex(value, separatorType);
    if (
      isCursorOnSeparator(value, selectionStart - 1, separatorType) &&
      selectionStart === selectionEnd
    ) {
      inputRef.current?.setSelectionRange(selectionStart - 1, selectionEnd - 1);
    }
    if (selectionStart === index + 1 && index !== -1 && selectionStart === selectionEnd) {
      setSelectionStart(selectionStart - 1);
      setSelectionEnd(selectionEnd - 1);
      inputRef.current?.setSelectionRange(selectionStart - 1, selectionEnd - 1);
      e.preventDefault();
    }
  };

  const handleDeleteKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const index = findDecimalSeparatorIndex(value, separatorType);
    if (isCursorOnSeparator(value, selectionStart, separatorType)) {
      inputRef.current?.setSelectionRange(selectionStart + 1, selectionEnd + 1);
    }
    if (selectionStart === index && selectionStart === selectionEnd) {
      setSelectionStart(selectionStart + 1);
      setSelectionEnd(selectionEnd + 1);
      inputRef.current?.setSelectionRange(selectionStart + 1, selectionEnd + 1);
      e.preventDefault();
    }
  };

  const handleArrowKeyDown = (direction: 'left' | 'right') => {
    setSelectionStart(prev => prev + (direction === 'left' ? -1 : 1));
    setSelectionEnd(prev => prev + (direction === 'left' ? -1 : 1));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    switch (key) {
      case '.':
      case ',':
        handleSeparatorKeyDown(e);
        break;
      case 'Backspace':
        handleBackspaceKeyDown(e);
        break;
      case 'Delete':
        handleDeleteKeyDown(e);
        break;
      case 'ArrowLeft':
        if (selectionStart > 0) {
          handleArrowKeyDown('left');
        }
        break;
      case 'ArrowRight':
        if (selectionEnd < value.length) {
          handleArrowKeyDown('right');
        }
        break;
      default:
        break;
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (inputRef.current) {
      setSelectionStart(inputRef.current.selectionStart ?? 0);
      setSelectionEnd(inputRef.current.selectionEnd ?? 0);
    }
    if (onClick) {
      onClick(e);
    }
  };

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value;
      inputRef.current.setSelectionRange(selectionStart, selectionEnd);
    }
  }, []);

  React.useEffect(() => {
    if (getValueAsNumber(value, separatorType) !== valueProp) {
      const formattedValue = formatInputValue(Number(valueProp ?? 0), separatorType, decimal, decimal);
      if (inputRef.current) {
        inputRef.current.value = formattedValue;
        inputRef.current.setSelectionRange(selectionStart, selectionEnd);
      }
      setValue(formattedValue);
    }
  }, [valueProp]);

  return { value, handleChange, handleKeyDown, handleClick };
};

export default useNumberInput;
