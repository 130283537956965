import { Notifications, WhatsNew, WhatsNewJson } from '../WhatsNew.types';

export const formatNotificationForPopup = (unformattedNotifications: Notifications[]) => {
  if (!unformattedNotifications) return;
  const notifications: Notifications[] = [];

  for (const element of unformattedNotifications) {
    const notification = element;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(notification.unformatted_html, 'text/html');

    const paragraph: string[] = [];

    const elements = xmlDoc.getElementsByTagName('body')[0].getElementsByTagName('p');

    for (let i = 0; i < elements.length; i++) {
      paragraph.push(elements[i].innerHTML + ' ');
    }

    notifications.push({ ...notification, paragraph: paragraph });
  }
  notifications.length = 3;
  return notifications;
};

export const formatNotificationData = (data: WhatsNewJson) => {
  const parser = new DOMParser();
  const whatsNewCookie = localStorage.getItem('whats-new');
  const notifications = data.items;
  const newNotifications: Notifications[] = [];
  let noOfNewNotifications = 0;
  let seenNotificationsIds = [];

  if (whatsNewCookie) {
    seenNotificationsIds = JSON.parse(whatsNewCookie);
  }

  for (const notification of notifications) {
    if (!notification.tags.includes('lia')) continue;

    const dateObj = new Date(notification.date_published);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }).format(dateObj);

    const htmlDocument = parser.parseFromString(notification.content_html, 'text/html');
    const link = htmlDocument.getElementsByTagName('body')[0].getElementsByTagName('a')[0] + '';

    const newElement = {
      id: notification.id,
      isRead: false,
      date: formattedDate,
      title: notification.title,
      unformatted_html: notification.content_html,
      tags: notification.tags,
      link: link
    };

    if (seenNotificationsIds.includes(notification.id)) {
      newElement.isRead = true;
    } else {
      noOfNewNotifications++;
    }
    newNotifications.push(newElement);
  }

  const newWhatsNew: WhatsNew = {
    noOfNewNotifications: noOfNewNotifications,
    notifications: newNotifications
  };

  return newWhatsNew;
};

export const markAllNotificationsAsRead = (data: WhatsNew) => {
  data.notifications.forEach(notification => {
    notification.isRead = true;
  });

  const newNotificationIds: string[] = data.notifications.map(notification => notification.id);
  const seenNotificationIds: string[] = JSON.parse(localStorage.getItem('whats-new') ?? '[]');
  const mergedNotificationIds: string[] = seenNotificationIds.concat(
    newNotificationIds.filter(notification => seenNotificationIds.indexOf(notification) === -1)
  );
  localStorage.setItem('whats-new', JSON.stringify(mergedNotificationIds));

  data.noOfNewNotifications = 0;
};

export const markNotificationAsRead = (data: WhatsNew, id: string) => {
  const seenNotificationIds: string[] = JSON.parse(localStorage.getItem('whats-new') ?? '[]');
  if (seenNotificationIds.includes(id)) return data;
  seenNotificationIds.push(id);
  localStorage.setItem('whats-new', JSON.stringify(seenNotificationIds));

  const notification = data.notifications.find(n => n.id === id);
  if (notification) {
    notification.isRead = true;
  }
  data.noOfNewNotifications -= 1;
};
