import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { Scenario } from '../../types/Scenario';
import { InsuranceDto } from '../../types/dto/InsuranceDto';
import { PracticeDto } from '../../types/dto/PracticeDto';
import { dairyRevenueProtectionActions } from '../dairyRevenueProtection/actions';
import mapInsuranceDto from '../dairyRevenueProtection/mapInsuranceDto';
import mapPracticeDtoToPractice from '../dairyRevenueProtection/mapPracticeDtoToPractice';
import { updateWhatIf } from '../dairyRevenueProtection/sagas';
import {
  getActualClassIIIPrice,
  getActualClassIVPrice,
  getAllRawWhatIfDairyValues,
  getButter,
  getCheese,
  getCoverageLevel,
  getDeclaredButterfatTest,
  getDeclaredClassPriceWeightingFactor,
  getDeclaredMilkProduction,
  getDeclaredProteinTest,
  getDryWhey,
  getMilkProductionPerCow,
  getNoFatDryMilk,
  getProtectionFactor,
  getQuarter,
  getReinsuranceYear,
  getSalesEffectiveDate,
  getShare,
  getState
} from '../dairyRevenueProtection/selectors';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { scenarioActions } from './actions';
import mapScenarioDtoToScenario from './mapScenarioDtoToScenario';
import { getScenarioValues, isScenarioAvailable } from './selectors';

function* getScenarios(action: ActionType<typeof scenarioActions.getAll>): any {
  try {
    const scenariosDto = yield call(insuranceAPI.getScenario, action.payload);
    yield put(scenarioActions.addAllNames(scenariosDto.data, action.payload));
  } catch (error: any) {
    console.log(error);
  }
}

function* saveScenario(action: ActionType<typeof scenarioActions.save>): any {
  try {
    const stateCode = yield select(getState);
    const practiceCode = yield select(getQuarter);

    const coveredMilkProduction = yield select(getDeclaredMilkProduction);
    const salesEffectiveDatef = yield select(getSalesEffectiveDate);

    const salesEffectiveDate = new Date(
      salesEffectiveDatef.getFullYear(),
      salesEffectiveDatef.getMonth(),
      salesEffectiveDatef.getDate(),
      2,
      0,
      0,
      0
    );

    const coverageLevel = yield select(getCoverageLevel);
    const butterfatTest = yield select(getDeclaredButterfatTest);
    const protectionFactor = yield select(getProtectionFactor);
    const proteinTest = yield select(getDeclaredProteinTest);
    const classPriceWeightingFactor = yield select(getDeclaredClassPriceWeightingFactor);
    const share: number = yield select(getShare);

    const expectedValues = yield select(getAllRawWhatIfDairyValues);

    const butterInput = yield select(getButter);
    const cheeseInput = yield select(getCheese);
    const dryWheyInput = yield select(getDryWhey);
    const noFatDryMilkInput = yield select(getNoFatDryMilk);
    const classIIIMilk = yield select(getActualClassIIIPrice);
    const classIVMilk = yield select(getActualClassIVPrice);
    const milkProductionPerCowInput = yield select(getMilkProductionPerCow);

    const dataMapper =
      (array: number[]) =>
      ({ date, value }: { date: Date; value: number }, index: number) => ({
        date: date,
        expectedPrice: value,
        actualPrice: array[index]
      });

    const data: Scenario = {
      reinsuranceYear: 2019,
      stateCode: String(stateCode).padStart(2, '0'),
      practiceCode: String(practiceCode),
      calculationParameters: {
        butterfatTest: butterfatTest,
        proteinTest: proteinTest,
        classPriceWeightingFactor: classPriceWeightingFactor,
        coverageLevel: coverageLevel,
        coveredMilkProduction: coveredMilkProduction,
        protectionFactor: protectionFactor,
        share
      },
      salesEffectiveDate: salesEffectiveDate,
      milkProductionExpected: expectedValues.milkProductionPerCow.value,
      milkProductionActual: milkProductionPerCowInput,
      butterPrice: expectedValues.butter.map(dataMapper(butterInput)),
      cheesePrice: expectedValues.cheese.map(dataMapper(cheeseInput)),
      dryWheyPrice: expectedValues.dryWhey.map(dataMapper(dryWheyInput)),
      nonfatDryMilkPrice: expectedValues.noFatDryMilk.map(dataMapper(noFatDryMilkInput)),
      classIIIPrice: expectedValues.classIIIMilk.map(dataMapper(classIIIMilk)),
      classIVPrice: expectedValues.classIVMilk.map(dataMapper(classIVMilk))
    };

    yield call(insuranceAPI.putScenario, action.meta, action.payload, data);

    yield put(scenarioActions.add(data, { name: action.payload, operation: action.meta }));
    yield toast.success('Scenario is saved.');
  } catch (e) {
    console.log(e);
  }
}

function* loadScenario(action: ActionType<typeof scenarioActions.load>): any {
  try {
    const isAvailable = yield select(isScenarioAvailable, {
      scenarioName: action.payload.scenarioName,
      operation: action.payload.operation
    });
    const operationName = yield select(getOperationName);
    let scenario: Scenario;
    if (!isAvailable) {
      yield put(loadersActions.dairyRevenueProtection.activate());
      const scenariosDto = yield call(
        insuranceAPI.getScenarioByName,
        action.payload.scenarioName,
        action.payload.operation
      );
      scenario = mapScenarioDtoToScenario(scenariosDto);
      yield put(
        scenarioActions.add(scenario, {
          name: action.payload.scenarioName,
          operation: action.payload.operation
        })
      );
    } else {
      scenario = yield select(getScenarioValues, {
        scenarioName: action.payload.scenarioName,
        operation: action.payload.operation
      });
    }

    const reinsuranceYear = yield select(getReinsuranceYear);

    // TODO: optimize this
    // TODO: add componentPriceWeightingFactor
    const response: AxiosResponse<InsuranceDto> = yield call(
      insuranceAPI.getInsurance,
      {
        operationName,
        share: scenario.calculationParameters.share,
        stateCode: Number(scenario.stateCode),
        practiceCode: Number(scenario.practiceCode),
        salesEffectiveDate: scenario.salesEffectiveDate,
        butterfatTest: scenario.calculationParameters.butterfatTest,
        proteinTest: scenario.calculationParameters.proteinTest,
        classPriceWeightingFactor: scenario.calculationParameters.classPriceWeightingFactor,
        coverageLevel: scenario.calculationParameters.coverageLevel,
        coveredMilkProduction: scenario.calculationParameters.coveredMilkProduction,
        protectionFactor: scenario.calculationParameters.protectionFactor,
        componentPriceWeightingFactor: 1
      },
      reinsuranceYear
    );
    const mappedResponse = mapInsuranceDto(response);
    yield put(dairyRevenueProtectionActions.dairyComponents.all(mappedResponse.priceComponents));
    yield put(
      dairyRevenueProtectionActions.setWhatIfValues(mappedResponse.whatIfDairyValues as any)
    );
    yield put(
      dairyRevenueProtectionActions.setWhatIfExpectedActual(mappedResponse.whatIfExpectedActual)
    );
    yield put(
      dairyRevenueProtectionActions.setWhatIfComponents(mappedResponse.whatIfDairyComponents)
    );

    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePracticeSales,
      operationName,
      scenario.stateCode,
      scenario.salesEffectiveDate
    );
    const practice = mapPracticeDtoToPractice(practiceDto);

    const mapActualMonthPrices = ({
      actualPrice
    }: {
      date: Date;
      expectedPrice: number;
      actualPrice: number;
    }) => actualPrice;
    const scenarioWhatIfInputs = {
      butter: scenario.butterPrice.map(mapActualMonthPrices),
      cheese: scenario.cheesePrice.map(mapActualMonthPrices),
      dryWhey: scenario.dryWheyPrice.map(mapActualMonthPrices),
      noFatDryMilk: scenario.nonfatDryMilkPrice.map(mapActualMonthPrices),
      classIIIMilk: scenario.classIIIPrice.map(mapActualMonthPrices),
      classIVMilk: scenario.classIVPrice.map(mapActualMonthPrices),
      milkProductionPerCow: scenario.milkProductionActual
    };

    yield put(dairyRevenueProtectionActions.setWhatIfInputs(scenarioWhatIfInputs as any));
    //  IS OKAY! (or not)
    yield put(
      dairyRevenueProtectionActions.settings.updateAll({
        state: Number(scenario.stateCode),
        quarter: Number(scenario.practiceCode),
        declaredMilkProduction: scenario.calculationParameters.coveredMilkProduction,
        salesEffectiveDate: scenario.salesEffectiveDate,
        coverageLevel: scenario.calculationParameters.coverageLevel,
        declaredButterfatTest: scenario.calculationParameters.butterfatTest,
        protectionFactor: scenario.calculationParameters.protectionFactor,
        declaredProteinTest: scenario.calculationParameters.proteinTest,
        declaredClassPriceWeightingFactor: scenario.calculationParameters.classPriceWeightingFactor,
        // declaredComponentPriceWeightingFactor: scenario.calculationParameters.componentPriceWeightingFactor,
        quarterList: practice.practices
      })
    );

    if (!isAvailable) {
      yield put(loadersActions.dairyRevenueProtection.disable());
    }

    yield put(dairyRevenueProtectionActions.settings.setCalendarDate(scenario.salesEffectiveDate));

    yield toast.success('Scenario is loaded');
    yield call(updateWhatIf);
    action.meta();
  } catch (e) {
    toast.error('Sales date not available');
    console.log(e);
  }
}

function* deleteScenario(action: ActionType<typeof scenarioActions.delete>) {
  try {
    yield call(insuranceAPI.deleteScenario, action.payload, action.meta);
    yield put(scenarioActions.remove(action.payload, action.meta));

    yield toast.success('Delete successful');
  } catch (e) {
    console.log(e);
  }
}

function* scenarioSagaWatcher() {
  yield all([
    takeLatest(getType(scenarioActions.getAll), getScenarios),
    takeLatest(getType(scenarioActions.save), saveScenario),
    takeLatest(getType(scenarioActions.load), loadScenario),
    takeLatest(getType(scenarioActions.delete), deleteScenario)
  ]);
}

export default scenarioSagaWatcher;
