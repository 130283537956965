import React, { useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components/macro';

import Tooltip from '@/components/Tooltip/Tooltip';

import { useAppSelector } from '@/hooks/redux-hooks';
import usePermission from '@/hooks/usePermission';

import color from '@/utils/color';
import { InsurancePlanType } from '@/utils/insuranceUtils';

import { getAipMatchedOperation } from '@/ducks/adminPanel/selectors';
import { getAgency } from '@/ducks/agency/selectors';
import { getOperationFilterIsDemo } from '@/ducks/operationFilters/selectors';

import OperationWithPrimaryContact from '@/types/Operation/OperationWithPrimaryContact';
import { AipStatus } from '@/types/dto/AipOperationDto';

type AipStatusUnion = 'matched' | 'matchedPendingEndorsement' | 'notMatched';
type InsuranceBadgeVariant = InsurancePlanType | AipStatusUnion;

export type InsuranceBadgeVariantStyles = Record<InsuranceBadgeVariant, React.CSSProperties>;

export const insuranceBadgeVariants: InsuranceBadgeVariantStyles = {
  DRP: {
    color: color.DRP_BADGE_TXT,
    backgroundColor: color.DRP_BADGE_BG
  },
  LRP: {
    color: color.LRP_BADGE_TXT,
    backgroundColor: color.LRP_BADGE_BG
  },
  LGM: {
    color: color.LGM_BADGE_TXT,
    backgroundColor: color.LGM_BADGE_BG
  },
  notMatched: {
    color: color.LGM_BADGE_BG,
    backgroundColor: color.NOT_MATCHED_BADGE_BG
  },
  matched: {
    color: color.LGM_BADGE_BG,
    backgroundColor: color.DRP_INPUT
  },
  matchedPendingEndorsement: {
    color: color.LGM_BADGE_BG,
    backgroundColor: color.SECONDARY
  }
};

interface InsurancePlanBadgeProps {
  insurancePlan: string;
  operation: OperationWithPrimaryContact;
  onClick?: () => void;
}

export const InsurancePlanBadge: React.FC<InsurancePlanBadgeProps> = ({
  insurancePlan,
  operation,
  onClick
}) => {
  const agency = useAppSelector(getAgency);
  const isDemo = useAppSelector(getOperationFilterIsDemo);
  const { hasPermission } = usePermission();
  const aipMatchedOperation = useAppSelector(getAipMatchedOperation);

  const isProducer = hasPermission('producer');

  const getMatchedOperation = useCallback(
    (operations: AipStatus[]) =>
      operations.find(
        operationObj =>
          operationObj.operation.operationName === operation.name &&
          operationObj.operation.insurancePlan?.toUpperCase() === insurancePlan
      ),
    [insurancePlan, operation.name]
  );

  const variant: InsuranceBadgeVariant = useMemo(() => {
    const aipEnabled = !isProducer && agency?.agencyCodes && !isDemo;
    if (!aipEnabled) {
      return insurancePlan as InsurancePlanType;
    }
    const matchedOperationObj = getMatchedOperation(aipMatchedOperation);
    if (matchedOperationObj && matchedOperationObj.unmatchedEndorsementsCount == 0) {
      return 'matched';
    } else if (matchedOperationObj && matchedOperationObj.unmatchedEndorsementsCount > 0) {
      return 'matchedPendingEndorsement';
    } else {
      return 'notMatched';
    }
  }, [insurancePlan, operation, aipMatchedOperation]);

  const tooltipNode = useMemo((): React.ReactNode | undefined => {
    if (variant !== 'matchedPendingEndorsement') {
      return undefined;
    }

    const matchedOperationObj = getMatchedOperation(aipMatchedOperation);
    if (matchedOperationObj && matchedOperationObj.unmatchedEndorsementsCount > 0) {
      const count = matchedOperationObj.unmatchedEndorsementsCount;
      return (
        <span>
          There {count === 1 ? 'is' : 'are'} <strong>{count} pending</strong> Endorsement match
          {count === 1 ? '' : 's'}.
        </span>
      );
    }

    return undefined;
  }, [aipMatchedOperation]);

  return (
    <Root>
      {tooltipNode && (
        <Tooltip id={`${operation.name}-${insurancePlan}-pending-endorsements-tooltip`} width={170}>
          {tooltipNode}
        </Tooltip>
      )}
      <InsuranceBadge
        variant={variant}
        onClick={onClick}
        data-tooltip-content={''}
        data-tooltip-id={`${operation.name}-${insurancePlan}-pending-endorsements-tooltip`}
      >
        {insurancePlan}
      </InsuranceBadge>
    </Root>
  );
};

const Root = styled.div`
  & span {
    font-size: 0.875rem;
    font-weight: 500;
  }
`;

const InsuranceBadge = styled.div<{ variant: InsuranceBadgeVariant }>`
  ${({ theme, variant }) => {
    const { backgroundColor, color } = theme.components.insuranceBadge[variant];

    return css`
      background-color: ${backgroundColor};
      color: ${color};
      padding: 5px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      margin: 2px;
      font-weight: bold;
      cursor: pointer;
    `;
  }}
`;
