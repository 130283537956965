import { type DialogProps } from '@radix-ui/react-dialog';
import { Command as CommandPrimitive } from 'cmdk';
import React from 'react';
import { LuSearch } from 'react-icons/lu';
import styled from 'styled-components/macro';

import { Modal, ModalContent } from '@/shared/components/ui/modal';

import color from '@/utils/color';

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ ...props }, ref) => <StyledCommandPrimitive ref={ref} {...props} />);
Command.displayName = CommandPrimitive.displayName;

interface CommandModalProps extends DialogProps {}

const CommandModal = ({ children, ...props }: CommandModalProps) => {
  return (
    <Modal {...props}>
      <StyledModalContent>
        <StyledCommand>{children}</StyledCommand>
      </StyledModalContent>
    </Modal>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ ...props }, ref) => (
  <StyledInputWrapper cmdk-input-wrapper="">
    <Search />
    <StyledCommandInput ref={ref} {...props} />
  </StyledInputWrapper>
));
CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ ...props }, ref) => <StyledCommandList ref={ref} {...props} />);
CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => <StyledCommandEmpty ref={ref} {...props} />);
CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ ...props }, ref) => <StyledCommandGroup ref={ref} {...props} />);
CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ ...props }, ref) => <StyledCommandSeparator ref={ref} {...props} />);
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ ...props }, ref) => <StyledCommandItem ref={ref} {...props} />);
CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({ ...props }: React.HTMLAttributes<HTMLSpanElement>) => {
  return <StyledCommandShortcut {...props} />;
};
CommandShortcut.displayName = 'CommandShortcut';

export {
  Command,
  CommandModal,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator
};

const StyledCommandPrimitive = styled(CommandPrimitive)`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0.375rem;
  background-color: ${color.BACKGROUND_SECONDARY};
  color: ${color.TEXT_PRIMARY};
`;

const StyledModalContent = styled(ModalContent)`
  overflow: hidden;
  padding: 0;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

const StyledCommand = styled(Command)`
  &[cmdk-group-heading] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-weight: medium;
    color: ${color.TEXT_PRIMARY};
  }

  &[cmdk-group]:not([hidden]) ~ [cmdk-group] {
    padding-top: 0;
  }

  &[cmdk-group] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &[cmdk-input-wrapper] svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  &[cmdk-input] {
    height: 3rem;
  }

  &[cmdk-item] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  &[cmdk-item] svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${color.BACKGROUND};
  padding-left: 0.75rem;
  padding-right: 0.75rem;
`;

const StyledCommandInput = styled(CommandPrimitive.Input)`
  display: flex;
  height: 2.125rem;
  width: 100%;
  border-radius: 0.375rem;
  background-color: transparent;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  outline: none;
  color: inherit;
  border: none;

  &::placeholder {
    color: ${color.TEXT_PRIMARY} / 50;
    font-weight: 300;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Search = styled(LuSearch)`
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  opacity: 0.5;
`;

const StyledCommandList = styled(CommandPrimitive.List)`
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledCommandEmpty = styled(CommandPrimitive.Empty)`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  text-align: center;
  font-size: 0.875rem;
`;

const StyledCommandGroup = styled(CommandPrimitive.Group)`
  overflow: hidden;
  padding: 0.25rem;
  color: ${color.TEXT_PRIMARY};

  &[cmdk-group-heading] {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: ${color.TEXT_PRIMARY} / 50;
  }
`;

const StyledCommandSeparator = styled(CommandPrimitive.Separator)`
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  height: 1px;
  background-color: #ddd;
`;

const StyledCommandItem = styled(CommandPrimitive.Item)`
  position: relative;
  display: flex;
  cursor: default;
  user-select: none;
  align-items: center;
  border-radius: 0.125rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 0.875rem;
  outline: none;
  pointer-events: auto;
  opacity: 1;
  background-color: ${color.BACKGROUND_SECONDARY};
  color: ${color.TEXT_PRIMARY};

  &[aria-selected] {
    background-color: ${color.DRP_INPUT};
    color: ${color.BACKGROUND_SECONDARY};
  }

  &[data-disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const StyledCommandShortcut = styled.span`
  margin-left: auto;
  font-size: 0.75rem;
  letter-spacing: 0.1em;
  color: ${color.TEXT_PRIMARY} / 50;
`;
