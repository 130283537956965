import { parse } from 'date-fns';

import ScenarioAnalysisWhatIfPayload from '../../../types/ScenarioWhatIf/ScenarioAnalysisWhatIfPayload';
import ScenarioWhatIfPrices, {
  ScenarioWhatIfPriceElement
} from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { formatDateISO } from '../../../utils/dateFormatter';

const yearMapper = (
  data: ScenarioAnalysisWhatIfPayload,
  key: keyof ScenarioAnalysisWhatIfPayload,
  reinsuranceYear: number
) => [
  {
    actualPrice: data[key].actualPrice,
    expectedPrice: data[key].expectedPrice,
    date: formatDateISO(new Date(reinsuranceYear, 0))
  }
];

const quarterMapper = (
  data: ScenarioAnalysisWhatIfPayload,
  key: keyof ScenarioAnalysisWhatIfPayload,
  reinsuranceYear: number
) =>
  data[key].quarters.map(q => ({
    actualPrice: q.actualPrice,
    expectedPrice: q.expectedPrice,
    date: formatDateISO(parse(`${reinsuranceYear}-${q.quarter}`, 'yyyy-Q', new Date()))
  }));

const monthMapper = (
  data: ScenarioAnalysisWhatIfPayload,
  key: keyof ScenarioAnalysisWhatIfPayload
) => {
  const monthPrices: ScenarioWhatIfPriceElement[] = [];
  data[key].quarters.forEach(q => {
    q.monthPrices.forEach(m => {
      monthPrices.push({
        actualPrice: m.actualPrice,
        expectedPrice: m.expectedPrice,
        date: m.date
      });
    });
  });
  return monthPrices;
};

const mapScenarioAnalysisWhatIfDto = (
  data: ScenarioAnalysisWhatIfPayload,
  reinsuranceYear: number
): ScenarioWhatIfPrices => ({
  butterfatYearPrice: yearMapper(data, 'butterfatPrice', reinsuranceYear),
  proteinYearPrice: yearMapper(data, 'proteinPrice', reinsuranceYear),
  otherSolidsYearPrice: yearMapper(data, 'otherSolidsPrice', reinsuranceYear),
  nonfatSolidsYearPrice: yearMapper(data, 'nonfatSolidsPrice', reinsuranceYear),
  butterYearPrice: yearMapper(data, 'butterPrice', reinsuranceYear),
  cheeseYearPrice: yearMapper(data, 'cheesePrice', reinsuranceYear),
  dryWheyYearPrice: yearMapper(data, 'dryWheyPrice', reinsuranceYear),
  nonfatDryMilkYearPrice: yearMapper(data, 'nonfatDryMilkPrice', reinsuranceYear),
  classIIIYearPrice: yearMapper(data, 'classIIIPrice', reinsuranceYear),
  classIVYearPrice: yearMapper(data, 'classIVPrice', reinsuranceYear),
  milkProductionYear: yearMapper(data, 'milkProduction', reinsuranceYear),

  butterfatQuarterPrice: quarterMapper(data, 'butterfatPrice', reinsuranceYear),
  proteinQuarterPrice: quarterMapper(data, 'proteinPrice', reinsuranceYear),
  otherSolidsQuarterPrice: quarterMapper(data, 'otherSolidsPrice', reinsuranceYear),
  nonfatSolidsQuarterPrice: quarterMapper(data, 'nonfatSolidsPrice', reinsuranceYear),
  butterQuarterPrice: quarterMapper(data, 'butterPrice', reinsuranceYear),
  cheeseQuarterPrice: quarterMapper(data, 'cheesePrice', reinsuranceYear),
  dryWheyQuarterPrice: quarterMapper(data, 'dryWheyPrice', reinsuranceYear),
  nonfatDryMilkQuarterPrice: quarterMapper(data, 'nonfatDryMilkPrice', reinsuranceYear),
  classIIIQuarterPrice: quarterMapper(data, 'classIIIPrice', reinsuranceYear),
  classIVQuarterPrice: quarterMapper(data, 'classIVPrice', reinsuranceYear),
  milkProductionQuarter: quarterMapper(data, 'milkProduction', reinsuranceYear),

  butterfatPrice: monthMapper(data, 'butterfatPrice'),
  proteinPrice: monthMapper(data, 'proteinPrice'),
  otherSolidsPrice: monthMapper(data, 'otherSolidsPrice'),
  nonfatSolidsPrice: monthMapper(data, 'nonfatSolidsPrice'),
  milkProduction: monthMapper(data, 'milkProduction'),
  reinsuranceYear,
  butterPrice: monthMapper(data, 'butterPrice'),
  cheesePrice: monthMapper(data, 'cheesePrice'),
  dryWheyPrice: monthMapper(data, 'dryWheyPrice'),
  nonfatDryMilkPrice: monthMapper(data, 'nonfatDryMilkPrice'),
  classIIIPrice: monthMapper(data, 'classIIIPrice'),
  classIVPrice: monthMapper(data, 'classIVPrice')
});

export default mapScenarioAnalysisWhatIfDto;
