import Theme from '@/shared/@types/theme.d';
import { buttonVariants } from '@/shared/components/ui/button';
import { comboboxTriggerVariants } from '@/shared/components/ui/combobox';
import { labelVariants } from '@/shared/components/ui/label';
import { navigationTabsVariants } from '@/shared/components/ui/navigation-tabs';
import { switchVariants } from '@/shared/components/ui/switch';

import { insuranceBadgeVariants } from '@/containers/OperationListContainer/InsurancePlanBadge';

import { tableVariants } from './components/common/Table/components/tableStyles';
import { accordionVariants } from './components/ui/accordion';
import { alertVariants } from './components/ui/alert';

const theme: Theme = {
  components: {
    label: labelVariants,
    button: buttonVariants,
    navigationTabs: navigationTabsVariants,
    switch: switchVariants,
    table: tableVariants,
    combobox: comboboxTriggerVariants,
    accordion: accordionVariants,
    alert: alertVariants,
    insuranceBadge: insuranceBadgeVariants
  }
};

export default theme;
