import { getType } from 'typesafe-actions';

import { LoadersActions, loadersActions } from './actions';

interface LoadersState {
  spinner: boolean;
  dairyRevenueProtection: boolean;
  adminAgencies: boolean;
  adminAgents: boolean;
  adminAgentsModal: boolean;
  adminOperations: boolean;
  adminProducers: boolean;
  adminProducersModal: boolean;
  operationList: boolean;
  endorsementSummary: boolean;
  endorsementDetailedView: boolean;
  showSalesError: boolean;
  accountPreferencesLoader: boolean;
  operationPreferences: boolean;
  operationOverviewDataTable: boolean;
  dairyRevenueWhatIf: boolean;
  pendingEndorsementTable: boolean;
  allQuartersInitial: boolean;
  allQuartersUpdate: boolean;
  alerts: boolean;
  revenueHistoricalChart: boolean;
  revenueProjection: boolean;
  scenarioAnalysis: boolean;
  spinnerLrpEndorsementModal: boolean;
  spinnerCoverageRequestHistory: boolean;
  spinnerCoverageRequestFromMail: boolean;
}

const initialState: LoadersState = {
  spinner: false,
  dairyRevenueProtection: true,
  adminAgencies: true,
  adminAgents: true,
  adminAgentsModal: true,
  adminOperations: true,
  adminProducers: true,
  adminProducersModal: true,
  operationList: true,
  endorsementSummary: true,
  endorsementDetailedView: false,
  showSalesError: false,
  accountPreferencesLoader: false,
  operationPreferences: false,
  operationOverviewDataTable: false,
  dairyRevenueWhatIf: false,
  pendingEndorsementTable: false,
  allQuartersInitial: true,
  allQuartersUpdate: false,
  alerts: true,
  revenueHistoricalChart: true,
  revenueProjection: true,
  scenarioAnalysis: false,
  spinnerLrpEndorsementModal: false,
  spinnerCoverageRequestHistory: false,
  spinnerCoverageRequestFromMail: false
};

export const loadersReducer = (state = initialState, action: LoadersActions) => {
  switch (action.type) {
    case getType(loadersActions.spinner.activate):
      return { ...state, spinner: true };
    case getType(loadersActions.spinner.disable):
      return { ...state, spinner: false };
    case getType(loadersActions.dairyRevenueProtection.activate):
      return { ...state, dairyRevenueProtection: true };
    case getType(loadersActions.dairyRevenueProtection.disable):
      return { ...state, dairyRevenueProtection: false };
    case getType(loadersActions.adminAgencies.activate):
      return { ...state, adminAgencies: true };
    case getType(loadersActions.adminAgencies.disable):
      return { ...state, adminAgencies: false };
    case getType(loadersActions.adminAgents.activate):
      return { ...state, adminAgents: true };
    case getType(loadersActions.adminAgents.disable):
      return { ...state, adminAgents: false };
    case getType(loadersActions.adminAgentsModal.activate):
      return { ...state, adminAgentsModal: true };
    case getType(loadersActions.adminAgentsModal.disable):
      return { ...state, adminAgentsModal: false };
    case getType(loadersActions.adminOperations.activate):
      return { ...state, adminOperations: true };
    case getType(loadersActions.adminOperations.disable):
      return { ...state, adminOperations: false };
    case getType(loadersActions.adminProducers.activate):
      return { ...state, adminProducers: true };
    case getType(loadersActions.adminProducers.disable):
      return { ...state, adminProducers: false };
    case getType(loadersActions.adminProducersModal.activate):
      return { ...state, adminProducersModal: true };
    case getType(loadersActions.adminProducersModal.disable):
      return { ...state, adminProducersModal: false };
    case getType(loadersActions.operationList.activate):
      return { ...state, operationList: true };
    case getType(loadersActions.operationList.disable):
      return { ...state, operationList: false };
    case getType(loadersActions.endorsementSummary.activate):
      return { ...state, endorsementSummary: true };
    case getType(loadersActions.endorsementSummary.disable):
      return { ...state, endorsementSummary: false };
    case getType(loadersActions.endorsementDetailedView.activate):
      return { ...state, endorsementDetailedView: true };
    case getType(loadersActions.endorsementDetailedView.disable):
      return { ...state, endorsementDetailedView: false };
    case getType(loadersActions.showSalesError.activate):
      return { ...state, showSalesError: true };
    case getType(loadersActions.showSalesError.disable):
      return { ...state, showSalesError: false };
    case getType(loadersActions.accountPreferencesLoader.activate):
      return { ...state, accountPreferencesLoader: true };
    case getType(loadersActions.accountPreferencesLoader.disable):
      return { ...state, accountPreferencesLoader: false };
    case getType(loadersActions.operationPreferences.activate):
      return { ...state, operationPreferences: true };
    case getType(loadersActions.operationPreferences.disable):
      return { ...state, operationPreferences: false };
    case getType(loadersActions.operationOverviewDataTable.activate):
      return { ...state, operationOverviewDataTable: true };
    case getType(loadersActions.operationOverviewDataTable.disable):
      return { ...state, operationOverviewDataTable: false };
    case getType(loadersActions.dairyRevenueWhatIf.activate):
      return { ...state, dairyRevenueWhatIf: true };
    case getType(loadersActions.dairyRevenueWhatIf.disable):
      return { ...state, dairyRevenueWhatIf: false };
    case getType(loadersActions.pendingEndorsementTable.activate):
      return { ...state, pendingEndorsementTable: true };
    case getType(loadersActions.pendingEndorsementTable.disable):
      return { ...state, pendingEndorsementTable: false };
    case getType(loadersActions.allQuartersInitial.activate):
      return { ...state, allQuartersInitial: true };
    case getType(loadersActions.allQuartersInitial.disable):
      return { ...state, allQuartersInitial: false };
    case getType(loadersActions.allQuartersUpdate.activate):
      return { ...state, allQuartersUpdate: true };
    case getType(loadersActions.allQuartersUpdate.disable):
      return { ...state, allQuartersUpdate: false };
    case getType(loadersActions.alerts.activate):
      return { ...state, alerts: true };
    case getType(loadersActions.alerts.disable):
      return { ...state, alerts: false };
    case getType(loadersActions.revenueHistoricalChart.activate):
      return { ...state, revenueHistoricalChart: true };
    case getType(loadersActions.revenueHistoricalChart.disable):
      return { ...state, revenueHistoricalChart: false };
    case getType(loadersActions.revenueProjection.activate):
      return { ...state, revenueProjection: true };
    case getType(loadersActions.revenueProjection.disable):
      return { ...state, revenueProjection: false };
    case getType(loadersActions.scenarioAnalysis.activate):
      return { ...state, scenarioAnalysis: true };
    case getType(loadersActions.scenarioAnalysis.disable):
      return { ...state, scenarioAnalysis: false };
    case getType(loadersActions.spinnerLrpEndorsementModal.activate):
      return { ...state, spinnerLrpEndorsementModal: true };
    case getType(loadersActions.spinnerLrpEndorsementModal.disable):
      return { ...state, spinnerLrpEndorsementModal: false };
    case getType(loadersActions.spinnerCoverageRequestHistory.activate):
      return { ...state, spinnerCoverageRequestHistory: true };
    case getType(loadersActions.spinnerCoverageRequestHistory.disable):
      return { ...state, spinnerCoverageRequestHistory: false };
    case getType(loadersActions.spinnerCoverageRequestFromMail.activate):
      return { ...state, spinnerCoverageRequestFromMail: true };
    case getType(loadersActions.spinnerCoverageRequestFromMail.disable):
      return { ...state, spinnerCoverageRequestFromMail: false };
    default:
      return state;
  }
};
