import { createSelector } from 'reselect';

import { getOperationFromProps, getScenarioNameFromProps } from '../fromPropsSelector';
import { RootState } from '../store';

export const getScenarioOperationNames = (state: RootState) => state.scenario.operationNames;

export const getAllOperationScenarios = (state: RootState) => state.scenario.allOperations;

export const getScenariosNames = createSelector(
  getAllOperationScenarios,
  getOperationFromProps,
  (operationScenarios, operationName) => {
    const scenarios = operationScenarios[operationName];
    if (!scenarios) {
      return [];
    }
    return scenarios.scenarioNames;
  }
);

export const getSelectScenarioNames = createSelector(getScenariosNames, scenarios =>
  scenarios.map(name => ({ id: name, name }))
);

export const isScenarioAvailable = createSelector(
  getAllOperationScenarios,
  getOperationFromProps,
  getScenarioNameFromProps,
  (operationScenarios, operationName, scenarioName) => {
    const scenarios = operationScenarios[operationName];
    if (!scenarios) {
      return false;
    }
    return scenarios.allScenarios[scenarioName] !== undefined;
  }
);

export const getScenarioValues = createSelector(
  getAllOperationScenarios,
  getOperationFromProps,
  getScenarioNameFromProps,
  (operationScenarios, operationName, scenarioName) => {
    const scenarios = operationScenarios[operationName];
    if (!scenarios) {
      return null;
    }
    return scenarios.allScenarios[scenarioName];
  }
);
