import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { OperationPriceReportDto } from '../../types/dto/OperationPreferencesDto';
import { getOperationName } from '../operationPage/selectors';
import { priceReportActions } from './actions';

function* getPriceReport(_action: ActionType<typeof priceReportActions.get>) {
  yield put(priceReportActions.setPriceReportSpinner(true));
  try {
    const operationName: string = yield select(getOperationName);
    const pricereport: AxiosResponse<OperationPriceReportDto[]> = yield call(
      insuranceAPI.getOperationPriceReport,
      operationName
    );
    yield put(priceReportActions.save(pricereport.data));
  } catch (e) {
    console.error(e);
    toast.error('Failed to fetch categories');
  } finally {
    yield put(priceReportActions.setPriceReportSpinner(false));
  }
}

function* createPriceReport(action: ActionType<typeof priceReportActions.add>) {
  yield put(priceReportActions.setPriceReportSpinner(true));
  const operationName: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.putOperationPriceReport, operationName, action.payload);
    put(priceReportActions.get());
    toast.success('Category saved successfully');
  } catch (error: any) {
    if (error.response.data) {
      toast.error(error.response.data);
    } else {
      toast.error('Failed to create category');
    }
  } finally {
    yield call(getPriceReport, priceReportActions.get());
    yield put(priceReportActions.setPriceReportSpinner(false));
    action.meta();
  }
}

function* deletePriceReport(action: ActionType<typeof priceReportActions.delete>) {
  yield put(priceReportActions.setPriceReportSpinner(true));
  const operationName: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.deleteOperationPriceReport, operationName, action.payload);
    put(priceReportActions.get());
  } catch (e) {
    console.error(e);
    toast.error('Failed to delete preference');
  } finally {
    yield call(getPriceReport, priceReportActions.get());
    yield put(priceReportActions.setPriceReportSpinner(false));
    action.meta();
  }
}

export function* priceReportSagaWatcher() {
  yield all([
    takeLatest(getType(priceReportActions.get), getPriceReport),
    takeLatest(getType(priceReportActions.add), createPriceReport),
    takeLatest(getType(priceReportActions.delete), deletePriceReport)
  ]);
}
