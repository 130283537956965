import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';
import styled from 'styled-components/macro';

import color from '@/utils/color';

import { ModalContext } from './modal';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
    align?: 'center' | 'start' | 'end';
    sideOffset?: number;
  }
>(({ align = 'center', sideOffset = 4, ...props }, ref) => {
  const isInsideModal = React.useContext(ModalContext);
  const shouldRenderWithoutPortal = isInsideModal;

  const content = (
    <StyledPopoverContent ref={ref} align={align} sideOffset={sideOffset} {...props} />
  );

  if (shouldRenderWithoutPortal) return content;
  return <PopoverPrimitive.Portal>{content}</PopoverPrimitive.Portal>;
});

PopoverContent.displayName = PopoverPrimitive.Content.displayName;
export { Popover, PopoverTrigger, PopoverContent };

const StyledPopoverContent = styled(PopoverPrimitive.Content)`
  position: relative;
  z-index: 50;
  width: 18rem;
  border-radius: 0.375rem;
  border: 1px solid;
  background-color: ${color.BACKGROUND};
  padding: 1rem;
  color: ${color.TEXT_PRIMARY};
  box-shadow:
    0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  outline: none;

  &[data-state='open'] {
    animation:
      animateIn 0.5s ease,
      fadeIn 0.5s ease;
  }

  &[data-state='closed'] {
    animation:
      fadeOut 0.5s ease,
      animateOut 0.5s ease;
  }

  &[data-side='bottom'] {
    animation: slide-in-from-top 0.5s ease;
  }

  &[data-side='top'] {
    animation: slide-in-from-bottom 0.5s ease;
  }
`;
