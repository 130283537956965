import { ActionType, createAction } from 'typesafe-actions';

import { FeedType } from './reducer';

export const lgmSettingsActions = {
  setCalendarDate: createAction('@lgm_settings/SET_CALENDAR_DATE')<Date>(),
  setDeductible: createAction('@lgm_settings/SET_DEDUCTIBLE')<number>(),
  setFeedType: createAction('@lgm_settings/SET_FEED_TYPE')<FeedType>(),
  setActualPrices: createAction('@lgm_settings/SET_ACTUAL_PRICES')<boolean>()
};

export type LGMSettingsActions = ActionType<typeof lgmSettingsActions>;
