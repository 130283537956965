import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import LgmEstimatedDto from '../../../types/Lgm/LgmEstimatedDto';
import { LgmPremium } from '../../../types/Lgm/LgmPremium';
import { LgmRow } from '../../../types/Lgm/LgmRow';
import { LGMActions, lgmActions } from './actions';

interface LGMState {
  rows: LgmRow[];
  estimated: LgmEstimatedDto;
  premium: LgmPremium;
}

export const lgmDefaultPremium = {
  premium: 0,
  premiumCwt: 0,
  totalExpectedGrossMargin: 0,
  totalExpectedGrossMarginCwt: 0,
  grossMarginGuarantee: 0,
  grossMarginGuaranteeCwt: 0,
  subsidy: 0,
  subsidyCwt: 0,
  producerPremium: 0,
  producerPremiumCwt: 0
};

const initialState: LGMState = {
  rows: [],
  estimated: {
    monthValues: [],
    coveredMilkCwtTotal: 0,
    coveredCornTotal: 0,
    coveredSoybeanMealTotal: 0,
    weightedAvgMilkPrice: 0,
    weightedAvgCornPrice: 0,
    weightedAvgSoybeanMealPrice: 0,
    expectedGrossMarginTotal: 0,
    grossMarginGuaranteeTotal: 0
  },
  premium: {
    custom: lgmDefaultPremium,
    default: lgmDefaultPremium,
    lowest: lgmDefaultPremium,
    highest: lgmDefaultPremium
  }
};

const lgmReducer = (state = initialState, action: LGMActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(lgmActions.addRows):
        draft.rows = action.payload;
        return;
      case getType(lgmActions.changeRow):
        const index = draft.rows.findIndex(({ id }) => id === action.meta);
        if (index !== -1) {
          draft.rows[index].quantity = action.payload;
        }
        return;
      case getType(lgmActions.saveLgmEstimated): {
        draft.estimated = action.payload;
        return;
      }
      case getType(lgmActions.saveLgmPremium): {
        draft.premium = action.payload;
        return;
      }
    }
  });

export default lgmReducer;
