import * as LabelPrimitive from '@radix-ui/react-label';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

type LabelVariant = 'default' | 'active' | 'error' | 'small' | 'smallInline' | 'tableEntry';

type LabelVariantStyles = {
  fontSize: string;
  fontWeight: number | string;
  color: string;
  display: 'inline' | 'block';
  marginBottom?: string;
};

const labelVariants: Record<LabelVariant, LabelVariantStyles> = {
  default: {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#333',
    display: 'inline'
  },
  active: {
    fontSize: '1rem',
    fontWeight: 400,
    color: color.DRP_INPUT,
    display: 'inline'
  },
  error: {
    fontSize: '1rem',
    fontWeight: 400,
    color: color.NEGATIVE,
    display: 'inline'
  },
  small: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#000',
    display: 'block',
    marginBottom: '10px'
  },
  tableEntry: {
    fontSize: '0.875rem',
    fontWeight: 600,
    color: '#000',
    display: 'block',
    marginBottom: '10px'
  },
  smallInline: {
    fontSize: '0.625rem',
    fontWeight: 400,
    color: '#000',
    display: 'inline',
    marginBottom: '10px'
  }
};

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & { variant?: LabelVariant }
>(({ variant, ...props }, ref) => <StyledLabel variant={variant} ref={ref} {...props} />);

const StyledLabel = styled(LabelPrimitive.Root)<{ variant?: LabelVariant }>`
  padding: 0 10px;

  ${({ theme, variant = 'default' }) => {
    const { fontSize, color, fontWeight, display, marginBottom } = theme.components.label[variant];
    return css`
      font-size: ${fontSize};
      display: ${display};
      color: ${color};
      font-weight: ${fontWeight};
      margin-bottom: ${marginBottom ?? '0px'};
    `;
  }}
`;

Label.displayName = LabelPrimitive.Root.displayName;
export { Label, labelVariants };
export type { LabelVariant, LabelVariantStyles };
