import { all } from 'redux-saga/effects';

import aipSyncSagaWatcher from './aipSync/sagas';
import { insuranceAgenciesSagaWatcher } from './insuranceAgencies/sagas';
import { insuranceAgentsSagaWatcher } from './insuranceAgents/sagas';
import { operationProducersSagaWatcher } from './operationProducers/sagas';
import { operationsSagaWatcher } from './operations/sagas';
import producerPreferencesSagaWatcher from './preferences/sagas';
import { producerOperationsSagaWatcher } from './producerOperations/sagas';
import { producersSagaWatcher } from './producers/sagas';

export default function* adminRootSaga() {
  yield all([
    producersSagaWatcher(),
    operationsSagaWatcher(),
    aipSyncSagaWatcher(),
    insuranceAgenciesSagaWatcher(),
    insuranceAgentsSagaWatcher(),
    operationProducersSagaWatcher(),
    producerPreferencesSagaWatcher(),
    producerOperationsSagaWatcher()
  ]);
}
