import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { WhatsNew } from '../WhatsNew.types';
import {
  formatNotificationData,
  markAllNotificationsAsRead,
  markNotificationAsRead
} from '../renderer/NotificationPopupRender';
import { WhatsNewActions, whatsNewActions } from './actions';

const initialState: WhatsNew = {
  notifications: [
    {
      id: '',
      date: '',
      isRead: false,
      tags: [],
      title: '',
      unformatted_html: ''
    }
  ],
  noOfNewNotifications: 0
};

const whatsNewReducer = (state = initialState, action: WhatsNewActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(whatsNewActions.setDataSuccess): {
        draft = formatNotificationData(action.payload);
        return draft;
      }
      case getType(whatsNewActions.markAllAsRead): {
        markAllNotificationsAsRead(draft);
        break;
      }
      case getType(whatsNewActions.markAsRead): {
        markNotificationAsRead(draft, action.payload);
        break;
      }
    }
  });

export default whatsNewReducer;
