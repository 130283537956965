import { AxiosResponse } from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { Agency } from '../../types/Agency';
import Operation from '../../types/Operation/Operation';
import OperationContact from '../../types/Operation/OperationContact';
import OperationWithPrimaryContact from '../../types/Operation/OperationWithPrimaryContact';
import { AipStatus } from '../../types/dto/AipOperationDto';
import { OperationDto } from '../../types/dto/OperationDto';
import containsString from '../../utils/containsString';
import sortObjectByKey from '../../utils/sortObjectByKey';
import { getAdminPageSearch } from '../adminPage/selectors';
import { adminActions } from '../adminPanel/actions';
import { getAipMatchedOperation, getOperations } from '../adminPanel/selectors';
import { AuthState } from '../authentication/reducer';
import { getAuthState, getEmail } from '../authentication/selectors';
import { getAgencyOperationsContacts } from '../operationContact/selectors';
import {
  getFilteredAgents,
  getFilteredAip,
  getFilteredInsurance,
  getFilteredState,
  getOperationFilterSort
} from '../operationFilters/selectors';
import { operationPageActions } from './actions';

const filterOperationByContact = (
  operations: Operation[],
  contacts: OperationContact[],
  filteredContacts: OperationContact[]
) => {
  const operationsWithPrimaryContact = operations.map(operation => {
    const isContact = contacts.find(x => x.operationName === operation.name && x.primaryContact);
    if (isContact) {
      return {
        ...operation,
        primaryContact: `${isContact.firstName} ${isContact.lastName}`
      };
    }
    return { ...operation, primaryContact: '-' };
  });

  if (filteredContacts.length > 0) {
    return operationsWithPrimaryContact.filter(operation => {
      const isContact = filteredContacts.find(x => x.operationName === operation.name);
      if (isContact) {
        return { ...operation };
      }
      return false;
    });
  } else {
    return operationsWithPrimaryContact;
  }
};

const filterOperationsByAip = (
  filteredOperations: OperationWithPrimaryContact[],
  aipMatchedOperations: AipStatus[],
  filteredAip: string[]
) => {
  if (!filteredAip.length) {
    return filteredOperations;
  }

  return filteredOperations.filter(operation => {
    const foundAipOperation = aipMatchedOperations.find(aipMatchedOperation => {
      return operation.name === aipMatchedOperation.operation.operationName;
    });
    if (foundAipOperation) {
      if (foundAipOperation.unmatchedEndorsementsCount > 0) {
        return filteredAip.find(el => el === 'MatchedPending');
      }
      return filteredAip.find(el => el === 'Matched');
    }
    return filteredAip.find(el => el === 'NotMatched');
  });
};

export function* getOperation(action: ActionType<typeof operationPageActions.getOperation>) {
  try {
    const authState: AuthState = yield select(getAuthState);
    let agency: Agency | null = JSON.parse(yield call([localStorage, 'getItem'], 'agency'));
    if (!agency) {
      const agencies: AxiosResponse<Agency[]> = yield call(insuranceAPI.getAgencies);
      if (agencies.data[0]) {
        agency = agencies.data[0];
      }
    }

    let operation: Operation | null = null;
    const operationName = action.payload.operationName;
    if (operationName != '') {
      if (authState === AuthState.producer) {
        const producer: string = yield select(getEmail);
        const operationResponse: AxiosResponse<Operation> = yield call(
          insuranceAPI.getProducerOperation,
          producer,
          operationName,
          action.payload.bindingRequirements
        );
        operation = operationResponse.data;
      } else if (agency) {
        const operationResponse: AxiosResponse<OperationDto> = yield call(
          insuranceAPI.getAgencyOperation,
          agency.name,
          operationName,
          action.payload.bindingRequirements
        );
        operation = operationResponse.data;
      }
    }

    if (operation) {
      yield put(operationPageActions.saveOperation(operation));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* setFavoriteOperation({
  payload,
  meta
}: ActionType<typeof operationPageActions.setStaredOperation>) {
  try {
    const authState: AuthState = yield select(getAuthState);

    const user: string = yield select(getEmail);
    if (authState === AuthState.producer) {
      yield call(insuranceAPI.setProducerFavoriteOperation, user, payload, meta);
    } else {
      yield call(insuranceAPI.setAgentFavoriteOperation, user, payload, meta);
    }

    yield put(operationPageActions.updateStaredOperation(payload, meta));
    yield put(adminActions.operations.updateStarredOperation(payload, meta));
  } catch (e) {
    console.log(e);
  }
}

export function* sortOperations({
  payload: { sort, direction, isDemo }
}: ActionType<typeof operationPageActions.sortOperations>) {
  const agents: OperationContact[] = yield select(getFilteredAgents);
  const operations: Operation[] = yield select(getOperations);
  const operationContacts: OperationContact[] = yield select(getAgencyOperationsContacts);
  const aipMatchedOperations: AipStatus[] = yield select(getAipMatchedOperation);
  const states: { stateCode: string; stateName: string }[] = yield select(getFilteredState);
  const insurance: string[] = yield select(getFilteredInsurance);
  const aip: string[] = yield select(getFilteredAip);

  const letter: string = yield select(getOperationFilterSort);
  const search: string = yield select(getAdminPageSearch);

  const filteredStates = states;
  const filteredInsurance = insurance;
  const filteredAip = aip;
  let filteredOperations: OperationWithPrimaryContact[] = [];
  const filteredOperationContacts: OperationContact[] = [];

  agents.forEach(agent => {
    operationContacts.forEach(operationContact => {
      if (operationContact.userId === agent.userId) {
        filteredOperationContacts.push(operationContact);
      }
    });
  });

  filteredOperations = filterOperationByContact(
    operations,
    operationContacts,
    filteredOperationContacts
  );
  filteredOperations = filterOperationsByAip(filteredOperations, aipMatchedOperations, filteredAip);

  if (states.length) {
    filteredOperations = filteredOperations.filter(operation =>
      filteredStates.some(state => operation.stateCode === state.stateCode)
    );
  }

  if (filteredInsurance.length) {
    filteredOperations = filteredOperations.filter(operation => {
      let isValidInsurance = false;
      operation.insurancePrograms.forEach(ip => {
        if (filteredInsurance.indexOf(ip) !== -1) {
          isValidInsurance = true;
        }
      });
      return isValidInsurance;
    });
  }

  if (letter !== 'All') {
    filteredOperations = filteredOperations.filter(
      ({ fullName }) => fullName[0].toLocaleUpperCase() === letter
    );
  }

  if (search) {
    filteredOperations = filteredOperations.filter(({ fullName }) =>
      containsString(fullName, search)
    );
  }

  filteredOperations.sort(sortObjectByKey(sort, direction));
  filteredOperations = filteredOperations.filter(({ demo }) => demo === isDemo);
  yield put(operationPageActions.saveSortedOperations(filteredOperations));
}

export function* getDefaultOperationProgram(
  action: ActionType<typeof operationPageActions.getDefaultOperationProgram>
) {
  const authState: AuthState = yield select(getAuthState);
  if (authState === AuthState.producer) {
    try {
      const producer: string = yield select(getEmail);
      const operation: AxiosResponse<OperationDto> = yield call(
        insuranceAPI.getProducerOperation,
        producer,
        action.payload
      );
      action.meta.setDefaultProgram(operation.data.defaultInsuranceProgram);
    } catch (e) {
      console.log(e);
    }
  } else {
    try {
      const agencies: AxiosResponse<Agency[]> = yield call(insuranceAPI.getAgencies);
      try {
        if (agencies.data[0].name) {
          const operation: AxiosResponse<OperationDto> = yield call(
            insuranceAPI.getAgencyOperation,
            agencies.data[0].name,
            action.payload
          );
          action.meta.setDefaultProgram(operation.data.defaultInsuranceProgram);
        }
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
  }
}

export function* operationPageSagaWatcher() {
  yield all([
    takeLatest(getType(operationPageActions.getOperation), getOperation),
    takeLatest(getType(operationPageActions.setStaredOperation), setFavoriteOperation),
    takeLatest(getType(operationPageActions.sortOperations), sortOperations),
    takeLatest(getType(operationPageActions.getDefaultOperationProgram), getDefaultOperationProgram)
  ]);
}
