import { AxiosResponse } from 'axios';
import { isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import AllQuarters from '../../types/AllQuarters';
import { WhatIfBody } from '../../types/WhatIfBody';
import { HistoricalAnalysisDto } from '../../types/dto/HistoricalAnalysisDto';
import { InsuranceDto } from '../../types/dto/InsuranceDto';
import { PracticeDto } from '../../types/dto/PracticeDto';
import { Indemnity, WhatIfDto } from '../../types/dto/WhatIfDto';
import { formatDateISO, formatDateYearMonthDay } from '../../utils/dateFormatter';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { getOperationPreferencesStateCode } from '../operationPreferences/selectors';
import { dairyRevenueProtectionActions } from './actions';
import mapAllQuartersDtoToAllQuarters from './mapAllQuartersDtoToAllQuarters';
import mapHistoricalAnalysisDtoToHistoricalAnalysis from './mapHistoricalAnalysisDtoToHistoricalAnalysis';
import mapInsuranceDto, { mapWhatIfPricesDto } from './mapInsuranceDto';
import mapPracticeDtoToPractice from './mapPracticeDtoToPractice';
import mapPriceReportsDtoToPriceReports from './marketReports/mapPriceReportsDtoToPriceReports';
import {
  getActualButterfatTest,
  getActualMilkProduction,
  getActualProteinTest,
  getAllQuartersRaw,
  getAllWhatIfExpectedActual,
  getAllWhatIfInputs,
  getCalendarDate,
  getCoverageLevel,
  getDeclaredButterfatTest,
  getDeclaredClassPriceWeightingFactor,
  getDeclaredComponentPriceWeightingFactor,
  getDeclaredMilkProduction,
  getDeclaredProteinTest,
  getHistoricalTimeSpan,
  getMilkProductionPerCow,
  getProducerPremiumCwtFromPriceComponents,
  getProtectionFactor,
  getQuarter,
  getQuarterList,
  getReinsuranceYear,
  getSalesEffectiveDate,
  getShare,
  getShowAllQuarters,
  getState,
  getWhatIfRaw
} from './selectors';
import { QuarterList } from './settings/reducer';
import simulateWhatIfPreviousAndCurrentParametersFactory from './simulateWhatIfPreviousAndCurrentParametersFactory';

const errorHasMessage = (error: unknown): error is { message: string } =>
  typeof error === 'object' &&
  error !== null &&
  'message' in error &&
  typeof error.message === 'string';

const handleError = (error: unknown, showError: boolean = true) => {
  if (!showError) {
    console.error(error);
    return;
  }

  if (errorHasMessage(error)) {
    toast.error(error.message, {
      position: 'top-right'
    });
  } else {
    toast.error('Something went wrong', {
      position: 'top-right'
    });
  }
  console.error(error);
};

export function* fetchDRP() {
  try {
    yield put(loadersActions.dairyRevenueProtection.activate());
    yield put(loadersActions.allQuartersUpdate.activate());
    const operationName: string = yield select(getOperationName);

    const stateCode: number = yield select(getState);
    const practices: QuarterList = yield select(getQuarterList);
    const practiceCode: number = yield select(getQuarter);

    const coveredMilkProduction: number = yield select(getDeclaredMilkProduction);
    const salesEffectiveDate: Date = yield select(getSalesEffectiveDate);
    const coverageLevel: number = yield select(getCoverageLevel);
    const butterfatTest: number = yield select(getDeclaredButterfatTest);
    const protectionFactor: number = yield select(getProtectionFactor);
    const proteinTest: number = yield select(getDeclaredProteinTest);
    const clsPriceWeightingFactor: number = yield select(getDeclaredClassPriceWeightingFactor);
    const cmpPriceWeightingFactor: number = yield select(getDeclaredComponentPriceWeightingFactor);
    const reinsuranceYear: number = yield select(getReinsuranceYear);
    const share: number = yield select(getShare);

    const practiceIndex = practices.findIndex(practice => practice.practiceCode === practiceCode);
    const componentPriceWeightingFactor =
      practices[practiceIndex]?.componentPriceWeightingFactorRestrictedValue ??
      cmpPriceWeightingFactor;
    const classPriceWeightingFactor =
      practices[practiceIndex]?.classPriceWeightingFactorRestrictedValue ?? clsPriceWeightingFactor;
    const response: AxiosResponse<InsuranceDto> = yield call(
      insuranceAPI.getInsurance,
      {
        operationName,
        share,
        stateCode,
        practiceCode,
        salesEffectiveDate,
        butterfatTest,
        proteinTest,
        classPriceWeightingFactor,
        coverageLevel,
        coveredMilkProduction,
        protectionFactor,
        componentPriceWeightingFactor
      },
      reinsuranceYear
    );
    const mappedResponse = mapInsuranceDto(response);

    const isAllQuartersActive: boolean = yield select(getShowAllQuarters);
    if (isAllQuartersActive) {
      const selectedPractice = practices.find(practice => practice.practiceCode === practiceCode);
      if (selectedPractice) {
        const newPractice = { ...selectedPractice, butterfatTest, proteinTest };
        const quarterData = mapAllQuartersDtoToAllQuarters([response], [newPractice]);
        yield put(dairyRevenueProtectionActions.allQuarters.saveIndividualQuarter(quarterData));
      }
    }

    // set data to store
    yield put(dairyRevenueProtectionActions.dairyComponents.all(mappedResponse.priceComponents));
    yield put(
      dairyRevenueProtectionActions.setWhatIfValues(mappedResponse.whatIfDairyValues as any)
    );
    yield put(
      dairyRevenueProtectionActions.setWhatIfExpectedActual(mappedResponse.whatIfExpectedActual)
    );
    yield put(
      dairyRevenueProtectionActions.setWhatIfComponents(mappedResponse.whatIfDairyComponents)
    );
    yield put(dairyRevenueProtectionActions.setWhatIfInputs(mappedResponse.whatIfInputs as any));
    yield put(dairyRevenueProtectionActions.setRawWhatIf(mappedResponse.whatIf));
    yield put(dairyRevenueProtectionActions.setIndemnity(response.data.indemnity));

    yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(true));
    yield put(loadersActions.dairyRevenueProtection.disable());
    yield put(loadersActions.showSalesError.disable());
    yield put(loadersActions.allQuartersUpdate.disable());
  } catch (error: unknown) {
    if (errorHasMessage(error) && error.message === 'Request failed with status code 404') {
      yield put(loadersActions.showSalesError.activate());
      yield put(loadersActions.allQuartersUpdate.disable());
      yield put(dairyRevenueProtectionActions.settings.setCanSell(false, null));
      yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(false));
    }
    yield put(loadersActions.dairyRevenueProtection.disable());
  }
}

function* initDrp(action: ActionType<typeof dairyRevenueProtectionActions.start>) {
  try {
    const { operationName, stateCode } = action.payload;
    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePractice,
      operationName,
      stateCode
    );
    const practice = mapPracticeDtoToPractice(practiceDto);
    yield put(dairyRevenueProtectionActions.startSave(practice));
    yield put(dairyRevenueProtectionActions.startFetch());
  } catch (e) {
    console.log(e);
  } finally {
    action.meta();
  }
}

function* processCalendarDate(
  action: ActionType<typeof dairyRevenueProtectionActions.settings.setCalendarDate>
) {
  yield put(loadersActions.showSalesError.disable());
  try {
    const operationName: string = yield select(getOperationName);
    const butterfatTest: number = yield select(getDeclaredButterfatTest);
    const proteinTest: number = yield select(getDeclaredProteinTest);
    const coverageLevel: number = yield select(getCoverageLevel);
    let stateCode: number | string = yield select(getState);

    if (isBefore(action.payload, new Date('July 1, 2019'))) {
      if (butterfatTest < 3.5) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest(3.5));
      }
      if (proteinTest < 3) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredProteinTest(3));
      }
    }

    if (isBefore(action.payload, new Date('July 1, 2020'))) {
      if (butterfatTest > 5) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest(5));
      }
      if (proteinTest > 4) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredProteinTest(4));
      }
    }

    if (isAfter(action.payload, new Date('July 1, 2019'))) {
      if (coverageLevel < 0.8) {
        yield put(dairyRevenueProtectionActions.settings.setCoverageLevel(0.8));
      }
    }

    stateCode = String(stateCode).padStart(2, '0');

    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePracticeSales,
      operationName,
      stateCode,
      action.payload
    );
    const practice = mapPracticeDtoToPractice(practiceDto);

    yield put(
      dairyRevenueProtectionActions.settings.setCanSell(practice.canSell, practice.canSellNote)
    );

    if (!practice.practices.length) {
      const message = practiceDto.data.canSellReason
        ? practiceDto.data.canSellReason
        : 'Sales Date not available';
      yield put(dairyRevenueProtectionActions.settings.setCanSell(false, message));
      yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(false));
      yield put(loadersActions.showSalesError.activate());
    } else if (practice.practices.length) {
      yield put(dairyRevenueProtectionActions.settings.updatePractice(practice));
      const code: number = yield select(getQuarter);
      if (practice.practices.findIndex(({ practiceCode }) => practiceCode === code) === -1) {
        yield put(
          dairyRevenueProtectionActions.settings.updatePracticeQuarter(
            practice.practices[0].practiceCode
          )
        );
      }
    }

    const isPreferredState = !practiceDto.data.canSellReason?.includes('Default state');

    if (!practiceDto.data.canSell && isPreferredState) {
      const message = practiceDto.data.canSellReason
        ? practiceDto.data.canSellReason
        : 'Sales Date not available';
      toast.error(message);
    }

    if (practiceDto.data.salesEffectiveDate === null) {
      const message = practiceDto.data.canSellReason
        ? practiceDto.data.canSellReason
        : 'Sales Date not available';
      yield put(dairyRevenueProtectionActions.settings.setCanSell(false, message));
      yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(false));
      yield put(loadersActions.showSalesError.activate());
    } else if (practiceDto.data.salesEffectiveDate !== null && practice.practices.length) {
      yield put(dairyRevenueProtectionActions.fetchQuoter());
      const showAllQuarters: boolean = yield select(getShowAllQuarters);
      if (showAllQuarters) {
        yield put(dairyRevenueProtectionActions.allQuarters.getAllQuarters());
      }
    }
  } catch (error: unknown) {
    handleError(error, false);
  }
}

function* changeState(action: ActionType<typeof dairyRevenueProtectionActions.settings.setState>) {
  yield put(loadersActions.showSalesError.disable());
  try {
    const operationName: string = yield select(getOperationName);
    const calendarDate: Date = yield select(getCalendarDate);
    const stateCode = String(action.payload).padStart(2, '0');
    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePracticeSales,
      operationName,
      stateCode,
      calendarDate
    );
    const practice = mapPracticeDtoToPractice(practiceDto);

    if (!practice.practices.length) {
      yield put(loadersActions.showSalesError.activate());
      const message = practiceDto.data.canSellReason
        ? practiceDto.data.canSellReason
        : 'Sales date not available';
      yield put(dairyRevenueProtectionActions.settings.setCanSell(false, message));
      yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(false));
    } else if (practice.practices.length) {
      yield put(dairyRevenueProtectionActions.settings.updatePractice(practice));
      const code: number = yield select(getQuarter);
      if (practice.practices.findIndex(({ practiceCode }) => practiceCode === code) === -1) {
        yield put(
          dairyRevenueProtectionActions.settings.updatePracticeQuarter(
            practice.practices[0].practiceCode
          )
        );
      }
    }
    if (practiceDto.data.salesEffectiveDate === null) {
      const message = practiceDto.data.canSellReason
        ? practiceDto.data.canSellReason
        : 'Sales date not available';
      yield put(dairyRevenueProtectionActions.settings.setCanSell(false, message));
      yield put(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable(false));
      toast.error(message);
      yield put(loadersActions.showSalesError.activate());
    } else if (practiceDto.data.salesEffectiveDate !== null && practice.practices.length) {
      yield put(dairyRevenueProtectionActions.fetchQuoter());
      const showAllQuarters: boolean = yield select(getShowAllQuarters);
      if (showAllQuarters) {
        yield put(dairyRevenueProtectionActions.allQuarters.getAllQuarters());
      }
    }
  } catch (error: unknown) {
    handleError(error);
  }
}

function* createWhatIfBody(): any {
  const actualInputs = yield select(getAllWhatIfInputs);
  const expectedActual = yield select(getAllWhatIfExpectedActual);
  const raw = yield select(getWhatIfRaw);

  const coveredMilkProduction = yield select(getDeclaredMilkProduction);
  const coverageLevel = yield select(getCoverageLevel);
  const butterfatTest = yield select(getDeclaredButterfatTest);
  const protectionFactor = yield select(getProtectionFactor);
  const share: number = yield select(getShare);
  const proteinTest = yield select(getDeclaredProteinTest);
  const classPriceWeightingFactor = yield select(getDeclaredClassPriceWeightingFactor);
  const componentPriceWeightingFactor = yield select(getDeclaredComponentPriceWeightingFactor);
  const milkProductionPerCow = yield select(getMilkProductionPerCow);

  const milkProductionActual = yield select(getActualMilkProduction);
  const butterfatTestActual = yield select(getActualButterfatTest);
  const proteinTestActual = yield select(getActualProteinTest);

  const payload: WhatIfBody = {
    parameters: {
      butterfatTest,
      proteinTest,
      classPriceWeightingFactor,
      componentPriceWeightingFactor,
      coverageLevel,
      coveredMilkProduction,
      protectionFactor,
      share
    },
    milkProductionActual,
    butterfatTestActual,
    proteinTestActual,
    includeHistricData: false,
    ...simulateWhatIfPreviousAndCurrentParametersFactory(
      actualInputs,
      expectedActual,
      milkProductionPerCow,
      raw
    )
  };

  return payload;
}

export function* updateWhatIf(): any {
  try {
    yield put(loadersActions.dairyRevenueWhatIf.activate());
    const operationName = yield select(getOperationName);
    const stateCode = yield select(getState);
    const practiceCode = yield select(getQuarter);
    const salesEffectiveDate = yield select(getSalesEffectiveDate);
    const salesDate = formatDateYearMonthDay(salesEffectiveDate);
    const state = String(stateCode).padStart(2, '0');

    const payload = yield call(createWhatIfBody);
    const response: AxiosResponse<WhatIfDto> = yield call(
      insuranceAPI.updateWhatIf,
      operationName,
      state,
      practiceCode,
      salesDate,
      payload
    );
    const data = mapWhatIfPricesDto(response.data.prices);
    yield put(dairyRevenueProtectionActions.setWhatIfInputs(data.whatIfInputs as any));
    yield put(dairyRevenueProtectionActions.setWhatIfValues(data.whatIfDairyValues as any));
    yield put(dairyRevenueProtectionActions.setWhatIfExpectedActual(data.whatIfExpectedActual));
    yield put(dairyRevenueProtectionActions.setIndemnity(response.data.indemnity));
    yield put(dairyRevenueProtectionActions.setRawWhatIf(response.data.prices));
    yield put(loadersActions.dairyRevenueWhatIf.disable());
  } catch (error: any) {
    yield put(loadersActions.dairyRevenueWhatIf.disable());
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* updateIndemnity(): any {
  try {
    yield put(loadersActions.dairyRevenueWhatIf.activate());

    const operationName = yield select(getOperationName);
    const stateCode = yield select(getState);
    const practiceCode = yield select(getQuarter);
    const salesEffectiveDate = yield select(getSalesEffectiveDate);
    const salesDate = formatDateISO(salesEffectiveDate);
    const state = String(stateCode).padStart(2, '0');

    const payload = yield call(createWhatIfBody);
    delete payload.previous;
    const response: AxiosResponse<Indemnity> = yield call(
      insuranceAPI.updateIndemnity,
      operationName,
      state,
      practiceCode,
      salesDate,
      payload
    );

    yield put(dairyRevenueProtectionActions.setIndemnity(response.data));
    yield put(loadersActions.dairyRevenueWhatIf.disable());
  } catch (error: any) {
    yield put(loadersActions.dairyRevenueWhatIf.disable());
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* getHistoricalAnalysisData(): any {
  try {
    yield put(loadersActions.revenueHistoricalChart.activate());
    const salesEffectiveDate = yield select(getSalesEffectiveDate);
    const practiceCode = yield select(getQuarter);
    const { from, to } = yield select(getHistoricalTimeSpan);

    const coveredMilkProduction = yield select(getDeclaredMilkProduction);
    const coverageLevel = yield select(getCoverageLevel);
    const butterfatTest = yield select(getDeclaredButterfatTest);
    const protectionFactor = yield select(getProtectionFactor);
    const proteinTest = yield select(getDeclaredProteinTest);
    const classPriceWeightingFactor = yield select(getDeclaredClassPriceWeightingFactor);
    const componentPriceWeightingFactor = yield select(getDeclaredComponentPriceWeightingFactor);
    const producerPremiumAmountCwt = yield select(getProducerPremiumCwtFromPriceComponents);
    const share: number = yield select(getShare);

    const data = {
      calculationParameters: {
        butterfatTest,
        proteinTest,
        classPriceWeightingFactor,
        componentPriceWeightingFactor,
        coverageLevel,
        coveredMilkProduction,
        protectionFactor,
        share
      },
      producerPremiumCwt: {
        class: producerPremiumAmountCwt.class,
        component: producerPremiumAmountCwt.component
      }
    };

    const historicalDto: AxiosResponse<HistoricalAnalysisDto> = yield call(
      insuranceAPI.getHistoricalAnalysis,
      salesEffectiveDate,
      from,
      to,
      practiceCode,
      data
    );

    const historical = mapHistoricalAnalysisDtoToHistoricalAnalysis(historicalDto);
    yield put(dairyRevenueProtectionActions.historical.setRecords(historical));
    yield put(dairyRevenueProtectionActions.historical.setIsDataAvailable(true));
  } catch (error: any) {
    yield put(dairyRevenueProtectionActions.historical.setIsDataAvailable(false));
    toast.error(error.message, {
      position: 'top-right'
    });
  } finally {
    yield put(loadersActions.revenueHistoricalChart.disable());
  }
}

function* fetchDRPAndHistoricalAnalysisData(): any {
  try {
    const butterfatTest = yield select(getDeclaredButterfatTest);
    const proteinTest = yield select(getDeclaredProteinTest);
    const coverageLevel = yield select(getCoverageLevel);
    const calendarDate = yield select(getCalendarDate);

    if (isBefore(calendarDate, new Date('July 1, 2019'))) {
      if (butterfatTest < 3.5) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest(3.5));
      }
      if (proteinTest < 3) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredProteinTest(3));
      }
    }

    if (isBefore(calendarDate, new Date('July 1, 2020'))) {
      if (butterfatTest > 5) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest(5));
      }
      if (proteinTest > 4) {
        yield put(dairyRevenueProtectionActions.settings.setDeclaredProteinTest(4));
      }
    }

    if (isAfter(calendarDate, new Date('July 1, 2019'))) {
      if (coverageLevel < 0.8) {
        yield put(dairyRevenueProtectionActions.settings.setCoverageLevel(0.8));
      }
    }

    yield call(fetchDRP);
    yield call(getHistoricalAnalysisData);
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  }
}

function* getAllQuarters(): any {
  const isAllQuartersActive = yield select(getShowAllQuarters);
  if (isAllQuartersActive) {
    yield put(loadersActions.allQuartersInitial.activate());
    const practices: QuarterList = yield select(getQuarterList);
    const currentPractice = yield select(getQuarter);
    const operationName = yield select(getOperationName);
    const stateCode = yield select(getState);

    const coveredMilkProduction = yield select(getDeclaredMilkProduction);
    const salesEffectiveDate = yield select(getSalesEffectiveDate);
    const coverageLevel = yield select(getCoverageLevel);
    const protectionFactor = yield select(getProtectionFactor);
    const classPriceWeightingFactor = yield select(getDeclaredClassPriceWeightingFactor);
    const componentPriceWeightingFactor = yield select(getDeclaredComponentPriceWeightingFactor);
    const reinsuranceYear = yield select(getReinsuranceYear);
    const declaredButterfatTest = yield select(getDeclaredButterfatTest);
    const declaredProteinTest = yield select(getDeclaredProteinTest);
    const share: number = yield select(getShare);

    let quarters: Array<AxiosResponse<InsuranceDto>> = [];
    const updatedPractice = practices.map(practice => {
      if (practice.practiceCode === currentPractice) {
        return {
          ...practice,
          butterfatTest: declaredButterfatTest,
          proteinTest: declaredProteinTest
        };
      } else {
        return practice;
      }
    });

    try {
      quarters = yield all(
        updatedPractice.map(
          ({
            practiceCode,
            butterfatTest,
            proteinTest,
            componentPriceWeightingFactorRestrictedValue,
            classPriceWeightingFactorRestrictedValue
          }) =>
            call(
              insuranceAPI.getInsurance,
              {
                operationName,
                share,
                stateCode,
                practiceCode,
                salesEffectiveDate,
                butterfatTest,
                proteinTest,
                classPriceWeightingFactor:
                  classPriceWeightingFactorRestrictedValue ?? classPriceWeightingFactor,
                coverageLevel,
                coveredMilkProduction,
                protectionFactor,
                componentPriceWeightingFactor:
                  componentPriceWeightingFactorRestrictedValue ?? componentPriceWeightingFactor
              },
              reinsuranceYear
            )
        )
      );
    } catch (error: any) {
      yield put(loadersActions.allQuartersInitial.disable());
      toast.error(error.message, {
        position: 'top-right'
      });
    }
    const allQuarters = mapAllQuartersDtoToAllQuarters(quarters, updatedPractice);
    yield put(dairyRevenueProtectionActions.allQuarters.saveAllQuarters(allQuarters));
    yield put(loadersActions.allQuartersInitial.disable());
  }
}

function* updateAllQuarters(): any {
  yield put(loadersActions.allQuartersUpdate.activate());
  const practices: QuarterList = yield select(getQuarterList);
  const allQuarters: AllQuarters[] = yield select(getAllQuartersRaw);
  const allQuartersData = allQuarters.map(quarter => ({
    practiceCode: quarter.practiceCode,
    proteinTest: quarter.declaredProteinTest,
    butterfatTest: quarter.declaredButterfatTest,
    quarter: ''
  }));
  const operationName = yield select(getOperationName);

  const stateCode = yield select(getState);

  const coveredMilkProduction = yield select(getDeclaredMilkProduction);
  const salesEffectiveDate = yield select(getSalesEffectiveDate);
  const coverageLevel = yield select(getCoverageLevel);
  const protectionFactor = yield select(getProtectionFactor);
  const share: number = yield select(getShare);
  const classPriceWeightingFactor = yield select(getDeclaredClassPriceWeightingFactor);
  const cmpWeightingFactor = yield select(getDeclaredComponentPriceWeightingFactor);
  const reinsuranceYear = yield select(getReinsuranceYear);

  let quarters: Array<AxiosResponse<InsuranceDto>> = [];

  const quartersParams: Array<{
    practiceCode: number;
    butterfatTest: number;
    proteinTest: number;
    componentPriceWeightingFactor: number;
  }> = allQuartersData.map(({ practiceCode, butterfatTest, proteinTest }) => {
    const result = {
      practiceCode,
      butterfatTest,
      proteinTest,
      componentPriceWeightingFactor: cmpWeightingFactor
    };
    const index = practices.findIndex(p => p.practiceCode === practiceCode);
    if (
      index !== -1 &&
      practices[index].componentPriceWeightingFactorRestrictedValue !== null &&
      practices[index].componentPriceWeightingFactorRestrictedValue !== undefined
    ) {
      result.componentPriceWeightingFactor =
        practices[index].componentPriceWeightingFactorRestrictedValue;
    }
    if (
      index !== -1 &&
      practices[index].classPriceWeightingFactorRestrictedValue !== null &&
      practices[index].classPriceWeightingFactorRestrictedValue !== undefined
    ) {
      result.componentPriceWeightingFactor =
        practices[index].classPriceWeightingFactorRestrictedValue;
    }
    return result;
  });

  try {
    quarters = yield all(
      quartersParams.map(
        ({ practiceCode, butterfatTest, proteinTest, componentPriceWeightingFactor }) =>
          call(
            insuranceAPI.getInsurance,
            {
              operationName,
              share,
              stateCode,
              practiceCode,
              salesEffectiveDate,
              butterfatTest,
              proteinTest,
              classPriceWeightingFactor,
              coverageLevel,
              coveredMilkProduction,
              protectionFactor,
              componentPriceWeightingFactor
            },
            reinsuranceYear
          )
      )
    );
  } catch (error: any) {
    yield put(loadersActions.allQuartersUpdate.disable());
    toast.error(error.message, {
      position: 'top-right'
    });
  }
  const allQuartersUpdate = mapAllQuartersDtoToAllQuarters(quarters, allQuartersData);
  yield put(dairyRevenueProtectionActions.allQuarters.saveAllQuarters(allQuartersUpdate));
  yield put(loadersActions.allQuartersUpdate.disable());
}

function* initPractice(): any {
  try {
    yield put(loadersActions.spinner.activate());
    const operationName = yield select(getOperationName);
    const stateCode = yield select(getOperationPreferencesStateCode);
    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePractice,
      operationName,
      stateCode
    );
    const practice = mapPracticeDtoToPractice(practiceDto);
    yield put(dairyRevenueProtectionActions.startSave(practice));
  } catch (error: any) {
    toast.error(error.message, {
      position: 'top-right'
    });
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* getPriceReports(
  action: ActionType<typeof dairyRevenueProtectionActions.priceReports.get>
): any {
  action.meta(true);
  try {
    const operationName = yield select(getOperationName);
    const result = yield call(insuranceAPI.getPriceReports, action.payload, operationName);
    const cmeResult = yield call(insuranceAPI.getCMEPriceReports, action.payload);
    yield put(
      dairyRevenueProtectionActions.priceReports.update(
        mapPriceReportsDtoToPriceReports(result, cmeResult)
      )
    );
  } catch (e) {
    console.error(e);
  } finally {
    action.meta(false);
  }
}

function* dairyRevenueProtectionSagaWatcher() {
  yield all([
    takeLatest(getType(dairyRevenueProtectionActions.start), initDrp),
    takeLatest(
      getType(dairyRevenueProtectionActions.startFetch),
      fetchDRPAndHistoricalAnalysisData
    ),
    takeLatest(
      getType(dairyRevenueProtectionActions.settings.setCalendarDate),
      processCalendarDate
    ),
    takeLatest(getType(dairyRevenueProtectionActions.settings.setState), changeState),
    debounce(
      800,
      [
        getType(dairyRevenueProtectionActions.settings.setQuarter),
        getType(dairyRevenueProtectionActions.settings.setDeclaredMilkProduction),
        getType(dairyRevenueProtectionActions.fetchQuoter),
        getType(dairyRevenueProtectionActions.settings.setCoverageLevel),
        getType(dairyRevenueProtectionActions.settings.setProtectionFactor),
        getType(dairyRevenueProtectionActions.settings.setDeclaredButterfatTest),
        getType(dairyRevenueProtectionActions.settings.setDeclaredProteinTest),
        getType(dairyRevenueProtectionActions.settings.setDeclaredClassPriceWeightingFactor),
        getType(dairyRevenueProtectionActions.settings.setDeclaredComponentPriceWeightingFactor)
      ],
      fetchDRPAndHistoricalAnalysisData
    ),
    debounce(
      10,
      [
        getType(dairyRevenueProtectionActions.whatIfInputs.milkProductionPerCow),
        getType(dairyRevenueProtectionActions.whatIfInputs.noFatDryMilk),
        getType(dairyRevenueProtectionActions.whatIfInputs.dryWhey),
        getType(dairyRevenueProtectionActions.whatIfInputs.cheese),
        getType(dairyRevenueProtectionActions.whatIfInputs.butter),
        getType(dairyRevenueProtectionActions.whatIfInputs.classIIIMilk),
        getType(dairyRevenueProtectionActions.whatIfInputs.classIVMilk),
        getType(dairyRevenueProtectionActions.setActualInputs.butterfat),
        getType(dairyRevenueProtectionActions.setActualInputs.protein),
        getType(dairyRevenueProtectionActions.setActualInputs.otherSolids),
        getType(dairyRevenueProtectionActions.setActualInputs.nonfatSolids),
        getType(dairyRevenueProtectionActions.setActualInputs.classIII),
        getType(dairyRevenueProtectionActions.setActualInputs.classIV)
      ],
      updateWhatIf
    ),
    debounce(
      800,
      [
        getType(dairyRevenueProtectionActions.whatIfInputs.actualMilkProduction),
        getType(dairyRevenueProtectionActions.whatIfInputs.actualButterfatTest),
        getType(dairyRevenueProtectionActions.whatIfInputs.actualProteinTest)
      ],
      updateIndemnity
    ),
    debounce(
      1000,
      [
        getType(dairyRevenueProtectionActions.historical.setTo),
        getType(dairyRevenueProtectionActions.historical.setFrom)
      ],
      getHistoricalAnalysisData
    ),
    takeLatest(getType(dairyRevenueProtectionActions.resetActual), fetchDRP),
    debounce(
      800,
      [getType(dairyRevenueProtectionActions.allQuarters.getAllQuarters)],
      getAllQuarters
    ),
    debounce(
      800,
      getType(dairyRevenueProtectionActions.allQuarters.updateAllQuarters),
      updateAllQuarters
    ),
    takeLatest(dairyRevenueProtectionActions.initPractice, initPractice),
    takeLatest(dairyRevenueProtectionActions.priceReports.get, getPriceReports)
  ]);
}

export default dairyRevenueProtectionSagaWatcher;
