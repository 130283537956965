import { PriceType } from '../scenes/lrp/containers/LrpMarketDynamics/PriceTrends/renderHistoricalPricesTable';
import {
  LrpAnalysisHistoricalPricesData,
  LrpPriceEvolutionAnalysisHistoricalData
} from '../scenes/lrp/types/LrpAnalysis';

type Data = LrpPriceEvolutionAnalysisHistoricalData[][] | LrpAnalysisHistoricalPricesData[];

export const addTypeToValues = (data: Data) => {
  let isArray = false;

  let numColumns: number;
  let minMaxIndex: number;
  if (Array.isArray(data[0])) {
    numColumns = data[0].length;
    minMaxIndex = 0;
    isArray = true;
  } else {
    numColumns = Object.keys(data[0]).length - 1;
    minMaxIndex = 1;
  }

  const columnMin = Array(numColumns).fill(Infinity);
  const columnMax = Array(numColumns).fill(-Infinity);
  let globalMin = Infinity;
  let globalMax = -Infinity;

  // Find min/max within each column
  for (minMaxIndex; minMaxIndex < (isArray ? numColumns : numColumns + 1); minMaxIndex++) {
    let localMin = Infinity;
    let localMax = -Infinity;
    let nonZeroOrUndefinedPriceCount = 0;
    let nonZeroOrUndefinedPriceIndex = -1;

    for (let dataIndex = 0; dataIndex < data.length; dataIndex++) {
      const priceAnalysisData = data[dataIndex][minMaxIndex];
      if (priceAnalysisData?.price !== undefined && priceAnalysisData?.price !== 0) {
        if (priceAnalysisData?.price > localMax) {
          localMax = priceAnalysisData?.price;
        }
        if (priceAnalysisData?.price < localMin) {
          localMin = priceAnalysisData?.price;
        }
        nonZeroOrUndefinedPriceCount++;
        nonZeroOrUndefinedPriceIndex = dataIndex;
      }
    }

    // Check if there's only one non-zero and non-undefined price in the column
    if (nonZeroOrUndefinedPriceCount === 1) {
      data[nonZeroOrUndefinedPriceIndex][minMaxIndex].type = PriceType.NEUTRAL;
    } else {
      // Update column min/max and global min/max
      if (localMin < columnMin[minMaxIndex]) {
        columnMin[isArray ? minMaxIndex : minMaxIndex - 1] = localMin;
      }
      if (localMax > columnMax[minMaxIndex]) {
        columnMax[isArray ? minMaxIndex : minMaxIndex - 1] = localMax;
      }
      if (localMin < globalMin) {
        globalMin = localMin;
      }
      if (localMax > globalMax) {
        globalMax = localMax;
      }
    }
  }

  for (const [currentColumn, columnMinValue] of Array.from(columnMin.entries())) {
    for (const dataPoint of data) {
      const priceAnalysisData = dataPoint[isArray ? currentColumn : currentColumn + 1];

      if (
        !priceAnalysisData ||
        priceAnalysisData.price === undefined ||
        priceAnalysisData.price === 0
      ) {
        continue;
      }

      const { price } = priceAnalysisData;
      if (price === columnMinValue) {
        priceAnalysisData.type = PriceType.MIN;
      } else if (price === columnMax[currentColumn]) {
        priceAnalysisData.type = PriceType.MAX;
      } else {
        priceAnalysisData.type = PriceType.NEUTRAL;
      }

      if (price === globalMin) {
        priceAnalysisData.type = PriceType.GLOBAL_MIN;
      }

      if (price === globalMax) {
        priceAnalysisData.type = PriceType.GLOBAL_MAX;
      }
    }
  }
};
