import { Dispatch, SetStateAction } from 'react';
import { ActionType, createAction } from 'typesafe-actions';

import { Agent } from '../../types/Agent/Agent';
import County from '../../types/County/County';
import OperationReportData from '../../types/ManagementReports/OperationReport';
import { SmsSettings, SmsUsageDto } from '../../types/SmsSettings';
import State from '../../types/State/State';

export const managementReportActions = {
  getOperationReports: createAction('@managementReportActions/GET_OPERATION_REPORTS')<
    {
      startCalendarYear?: number;
      endCalendarYear?: number;
      startQuarter?: number;
      endQuarter?: number;
      startReinsuranceYear?: number;
      endReinsuranceYear?: number;
    },
    Dispatch<SetStateAction<boolean>>
  >(),
  saveOperationReports: createAction(
    '@managementReportActions/SAVE_OPERATION_REPORTS'
  )<OperationReportData>(),
  getSmsUsage: createAction('@managementReportActions/GET_SMS_USAGE')<SmsSettings>(),
  getSmsUsageForAgency: createAction('@managementReportActions/GET_SMS_USAGE_FOR_AGENCY')<{
    agencyName: string;
    settings: SmsSettings;
  }>(),
  getSmsDetailedUsage: createAction(
    '@managementReportActions/GET_SMS_DETAILED_USAGE'
  )<SmsSettings>(),
  getSmsDetailedUsageForAgency: createAction(
    '@managementReportActions/GET_SMS_DETAILED_USAGE_FOR_AGENCY'
  )<{
    agencyName: string;
    settings: SmsSettings;
  }>(),
  saveSmsCulmulativeUsageData: createAction('@managementReportActions/SAVE_SMS_CULMULATIVE_USAGE')<
    SmsUsageDto[]
  >(),
  saveSmsDetailedUsageData: createAction('@managementReportActions/SAVE_SMS_DETAILED_USAGE')<
    SmsUsageDto[]
  >(),
  saveSmsSettings: createAction('@managementReportActions/SAVE_SMS_SETTINGS')<{
    startDate: string;
    endDate: string;
    agency: string;
  }>(),

  filter: {
    setState: createAction('@managementReportActions/SET_STATE')<State>(),
    setCounty: createAction('@managementReportActions/SET_COUNTY')<County>(),
    setAgent: createAction('@managementReportActions/SET_AGENT')<Agent>(),
    clearAllStates: createAction('@managementReportActions/CLEAR_ALL_STATES')(),
    clearAllCounties: createAction('@managementReportActions/CLEAR_ALL_COUNTIES')(),
    clearAllAgents: createAction('@managementReportActions/CLEAR_ALL_AGENTS')()
  }
};

export type ManagementReportActions = ActionType<typeof managementReportActions>;
