import { ActionType, createAction } from 'typesafe-actions';

export const lgmLoaderActions = {
  setLgmEstimatedLoader: createAction('@llgm_loader/SET_ESTIMATED_LOADER')<boolean>(),
  setLgmPremiumLoader: createAction('@llgm_loader/SET_PREMIUM_LOADER')<boolean>(),
  setLgmWhatIfPremiumLoader: createAction('@lgm_loader/SET_WHAT_IF_PREMIUM_LOADER')<boolean>(),
  setLgmWhatIfActualLoader: createAction('@lgm_loader/SET_WHAT_IF_ACTUAL_LOADER')<boolean>()
};

export type LgmLoaderActions = ActionType<typeof lgmLoaderActions>;
