import { createSelector } from 'reselect';

import { Unit } from '../../../containers/PremiumsContainer/PremiumsContainer';
import { getLgmPremiumPropsFromProps } from '../../fromPropsSelector';
import { RootState } from '../../store';

export const getLgmRowData = (state: RootState) => state.lgm.quoter.rows;

export const getLgmPremium = (state: RootState) => state.lgm.quoter.premium;

export const getLgmTableDataRows = createSelector(
  getLgmPremium,
  getLgmPremiumPropsFromProps,
  (lgmPremium, lgmPremiumProps) => {
    const rows =
      lgmPremiumProps.unit === Unit.DOLLAR
        ? [
            {
              title: 'Expected Gross Margin',
              custom: lgmPremium.custom.totalExpectedGrossMargin,
              default: lgmPremium.default.totalExpectedGrossMargin,
              lowest: lgmPremium.lowest.totalExpectedGrossMargin,
              highest: lgmPremium.highest.totalExpectedGrossMargin,
              unit: '$',
              scale: 0
            },
            {
              title: 'Gross Margin Guarantee',
              custom: lgmPremium.custom.grossMarginGuarantee,
              default: lgmPremium.default.grossMarginGuarantee,
              lowest: lgmPremium.lowest.grossMarginGuarantee,
              highest: lgmPremium.highest.grossMarginGuarantee,
              unit: '$',
              scale: 0
            }
            // TODO: after RMA implementation (and on the end of page)
            // {title: 'RMA Sales Event Date', custom: 9.87, default: 8.88, lowest: 7.77, highest: 9.99, unit: '$', scale: 2},
            // {title: 'RMA Producer Premium Amount', custom: 9.87, default: 8.88, lowest: 7.77, highest: 9.99, unit: '$', scale: 2},
          ]
        : [
            {
              title: 'Expected Gross Margin',
              custom: lgmPremium.custom.totalExpectedGrossMarginCwt,
              default: lgmPremium.default.totalExpectedGrossMarginCwt,
              lowest: lgmPremium.lowest.totalExpectedGrossMarginCwt,
              highest: lgmPremium.highest.totalExpectedGrossMarginCwt,
              unit: '$',
              scale: 2
            },
            {
              title: 'Gross Margin Guarantee',
              custom: lgmPremium.custom.grossMarginGuaranteeCwt,
              default: lgmPremium.default.grossMarginGuaranteeCwt,
              lowest: lgmPremium.lowest.grossMarginGuaranteeCwt,
              highest: lgmPremium.highest.grossMarginGuaranteeCwt,
              unit: '$',
              scale: 2
            }
          ];

    if (lgmPremiumProps.subsidy) {
      if (lgmPremiumProps.unit === Unit.DOLLAR) {
        rows.push(
          {
            title: 'Premium Before Subsidy',
            custom: lgmPremium.custom.premium,
            default: lgmPremium.default.premium,
            lowest: lgmPremium.lowest.premium,
            highest: lgmPremium.highest.premium,
            unit: '$',
            scale: 0
          },
          {
            title: 'Subsidy',
            custom: lgmPremium.custom.subsidy,
            default: lgmPremium.default.subsidy,
            lowest: lgmPremium.lowest.subsidy,
            highest: lgmPremium.highest.subsidy,
            unit: '$',
            scale: 0
          }
        );
      } else {
        rows.push(
          {
            title: 'Premium Before Subsidy',
            custom: lgmPremium.custom.premiumCwt,
            default: lgmPremium.default.premiumCwt,
            lowest: lgmPremium.lowest.premiumCwt,
            highest: lgmPremium.highest.premiumCwt,
            unit: '$',
            scale: 2
          },
          {
            title: 'Subsidy',
            custom: lgmPremium.custom.subsidyCwt,
            default: lgmPremium.default.subsidyCwt,
            lowest: lgmPremium.lowest.subsidyCwt,
            highest: lgmPremium.highest.subsidyCwt,
            unit: '$',
            scale: 2
          }
        );
      }
    }

    if (lgmPremiumProps.unit === Unit.DOLLAR) {
      rows.push({
        title: 'Producer Premium Amount',
        custom: lgmPremium.custom.producerPremium,
        default: lgmPremium.default.producerPremium,
        lowest: lgmPremium.lowest.producerPremium,
        highest: lgmPremium.highest.producerPremium,
        unit: '$',
        scale: 0
      });
    } else {
      rows.push({
        title: 'Producer Premium Amount',
        custom: lgmPremium.custom.producerPremiumCwt,
        default: lgmPremium.default.producerPremiumCwt,
        lowest: lgmPremium.lowest.producerPremiumCwt,
        highest: lgmPremium.highest.producerPremiumCwt,
        unit: '$',
        scale: 4
      });
    }

    if (lgmPremiumProps.probability) {
      rows.push(
        {
          title: 'Probability of Positive Indemnity',
          custom: 0.4,
          default: 0.5,
          lowest: 0.3,
          highest: 0.8,
          unit: '%',
          scale: 0
        },
        {
          title: 'Probability of Positive Net Benefit',
          custom: 0.6,
          default: 0.7,
          lowest: 0.4,
          highest: 0.9,
          unit: '%',
          scale: 0
        }
      );
    }

    return rows;
  }
);
