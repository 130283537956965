import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Dates, Events, Quarter } from '../../types/Calendar/Calendar';
import { CalendarActions, calendarActions } from './actions';

export interface CalendarState {
  dates: Dates;
  events: Events;
  quarters: Quarter[];
  info: {
    firstDate?: Date;
    lastDate?: Date;
    firstReinsuranceYear?: number;
    lastReinsuranceYear?: number;
    currentReinsuranceYear: number;
  };
}

const initialState: CalendarState = {
  dates: {},
  events: [],
  quarters: [],
  info: { currentReinsuranceYear: new Date().getFullYear() }
};

export const calendarReducer = (state = initialState, action: CalendarActions) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  produce(state, _draft => {
    switch (action.type) {
      case getType(calendarActions.add):
        return action.payload;
    }
  });
