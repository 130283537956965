function numberRange(start = 0, end = 0): number[] {
  const positiveStart = start * -1;
  const positiveEnd = end * -1;
  if (start < 0 && end >= 0) {
    return new Array(end + positiveStart).fill(null).map((_d, i) => i + start);
  }
  if (start >= 0 && end < 0) {
    return new Array(start + positiveEnd)
      .fill(null)
      .map((_d, i) => i + end + 1)
      .reverse();
  }
  if (start < 0 && end < 0 && start <= end) {
    return new Array(positiveStart - positiveEnd).fill(null).map((_d, i) => i + start);
  }
  if (start < 0 && end < 0 && !(start <= end)) {
    return new Array(positiveEnd - positiveStart)
      .fill(null)
      .map((_d, i) => i + end + 1)
      .reverse();
  }
  if (start <= end) {
    return new Array(end - start).fill(null).map((_d, i) => i + start);
  }
  return new Array(start - end)
    .fill(null)
    .map((_d, i) => i + end + 1)
    .reverse();
}

export default numberRange;
