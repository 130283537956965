export default function quarterOfYear(date?: string) {
  if (!date) {
    return;
  }

  date = date.slice(0, 7);

  const convertedDate = new Date(date);
  return Math.ceil((convertedDate.getMonth() + 1) / 3);
}
