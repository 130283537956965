import { createApiInstance } from '../ducks/authentication/sagas';

export const api = createApiInstance({
  baseURL: `${import.meta.env.VITE_APP_ORGANIZATION_URL}`,
  timeout: 20000
});

const organizationAPI = {
  addUserRole: (organizationName: string, userName: string, role: string) =>
    api.post(`${organizationName}/users/${userName}/roles/${role}`),

  removeUserRole: (organizationName: string, userName: string, role: string) =>
    api.delete(`${organizationName}/users/${userName}/roles/${role}`),

  getOrganizationNameLogoUpload: async (organizationName: string) =>
    await api.get(`${organizationName}/logo/upload`)
};

export default organizationAPI;
