import * as React from 'react';
import styled from 'styled-components/macro';

import color from '../../../utils/color';

const Dot = styled.div<{ size: number }>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border-radius: 50%;
  border: ${color.NEUTRAL_DARK} solid 1px;
  cursor: pointer;
`;

const ActiveDot = styled.div<{ size: number; disabled: boolean }>`
  margin: 1px;
  height: ${({ size }) => size - 2}px;
  width: ${({ size }) => size - 2}px;
  border-radius: 50%;
  background-color: ${({ disabled }) => (disabled ? color.NEUTRAL_DARK : color.DRP_INPUT)};
  cursor: pointer;
`;

interface Props {
  active?: boolean;
  size?: number;
  disabled?: boolean;
}

const Radio: React.FC<Props> = ({ active = false, size = 13, disabled = false }) => (
  <Dot size={size}>{active && <ActiveDot size={size} disabled={disabled} />}</Dot>
);

export default Radio;
