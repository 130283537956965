import { getType } from 'typesafe-actions';

import Operation from '../../types/Operation/Operation';
import OperationWithPrimaryContact from '../../types/Operation/OperationWithPrimaryContact';
import { OperationPageActions, operationPageActions } from './actions';

export interface OperationPage extends Operation {
  operations: OperationWithPrimaryContact[];
}

const initialState: OperationPage = {
  agencyName: '',
  stateCode: null,
  countyCode: null,
  name: '',
  fullName: '',
  public: false,
  demo: true,
  isPublic: true,
  isInMgmtReports: false,
  starred: false,
  insurancePrograms: [''],
  operations: [],
  defaultInsuranceProgram: '',
  bindRequirements: {
    canBind: false,
    agencyBindingEnabled: false,
    producerEligibleForBinding: false,
    insurancePlanRequirements: []
  }
};

export const operationPageReducer = (state = initialState, action: OperationPageActions) => {
  switch (action.type) {
    case getType(operationPageActions.setOperation): {
      const newState = { ...state };
      newState.name = action.payload;
      return newState;
    }
    case getType(operationPageActions.setOperationName): {
      const newState = { ...state };
      newState.fullName = action.payload;
      return newState;
    }
    case getType(operationPageActions.saveOperation): {
      return { ...action.payload, operations: [] };
    }
    case getType(operationPageActions.saveSortedOperations): {
      const newState = { ...state };
      newState.operations = action.payload;
      return newState;
    }
    case getType(operationPageActions.updateStaredOperation): {
      const newState = { ...state };
      const index = newState.operations.findIndex(({ name }) => name === action.payload);
      if (index !== -1) {
        newState.operations[index].starred = action.meta;
        newState.operations = [...newState.operations];
      }
      return newState;
    }
    default:
      return state;
  }
};
