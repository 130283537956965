import axios, { AxiosHeaderValue, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { reviveDate, serializeDate } from '@/utils/dateFormatter';
import { errorResponseStatusHandler } from '@/utils/responseStatusHandler';

import { getAuthenticatedUser } from '@/ducks/authentication/sagas';

const reportContentTypes: AxiosHeaderValue[] = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

const isReportType = (response: AxiosResponse) =>
  reportContentTypes.includes(response.headers['content-type'] as AxiosHeaderValue);

export const useApi = () => {
  const createApiInstance = (
    config?: AxiosRequestConfig | undefined,
    skipDateRevive = false
  ): AxiosInstance => {
    const instance = axios.create(config);
    instance.interceptors.request.use(async config => {
      const user = await getAuthenticatedUser();
      if (!user) return config;

      config.headers['Authorization'] = `${user.token_type} ${user.access_token}`;
      if (config.data) {
        config.data = serializeDate(config.data);
      }
      if (config.params) {
        config.params = serializeDate(config.params);
      }
      return config;
    });

    instance.interceptors.response.use(
      async response => {
        if (response.data && !isReportType(response) && !skipDateRevive) {
          response.data = reviveDate(response.data);
        }
        return response;
      },
      async function (error) {
        if (!error.response) return Promise.reject(error);
        const { config: request, response } = error;

        if (response.status === 401 && !request._retry) {
          request._retry = true;
          const user = await getAuthenticatedUser();
          if (user == null) {
            return Promise.reject(error);
          }

          axios.defaults.headers.common[
            'Authorization'
          ] = `${user.token_type} ${user.access_token}`;
          return instance(request);
        }

        errorResponseStatusHandler(response);
      }
    );

    return instance;
  };

  return { createApiInstance };
};
