export const alertProducts: { [key: string]: string } = {
  ClassIIIMilk: 'Class III Milk',
  ClassIVMilk: 'Class IV Milk',
  Butter: 'Butter',
  Cheese: 'Cheese',
  DryWhey: 'Dry Whey',
  Butterfat: 'Butterfat',
  Protein: 'Protein',
  OtherSolids: 'Other Solids',
  NonfatDryMilk: 'Nonfat Dry Milk',
  NonfatSolids: 'Nonfat Solids'
};

export default alertProducts;
