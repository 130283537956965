import { ActionType, createAction } from 'typesafe-actions';

import { AnalysisProduct } from '../../types/AnalysisProduct';
import { PriceTrendsActual } from '../../types/PriceTrendsActual';
import { PriceTrendsExpected } from '../../types/PriceTrendsExpected';

export const priceTrendsActions = {
  getPriceActualTrends: createAction('priceTrends/GET_PRICE_ACTUAL_TRENDS')<
    { fromYear: number; toYear: number; successCallback: () => void; failureCallback: () => void },
    AnalysisProduct[]
  >(),
  getPriceExpectedTrends: createAction('priceTrends/GET_PRICE_EXPECTED_TRENDS')<
    {
      year: number;
      quarter: number;
      isFutures: boolean;
      previousIsFutures: boolean | undefined;
      successCallback: () => void;
      failureCallback: () => void;
    },
    AnalysisProduct[]
  >(),
  setPriceActualTrends: createAction('priceTrends/SET_PRICE_ACTUAL_TRENDS')<
    PriceTrendsActual,
    string
  >(),
  setPriceExpectedTrends: createAction('priceTrends/SET_PRICE_EXPECTED_TRENDS')<
    PriceTrendsExpected,
    string
  >()
};

export type PriceTrendsActions = ActionType<typeof priceTrendsActions>;
