import * as React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import color from '@/utils/color';
import { formatDateYearMonthDay } from '@/utils/dateFormatter';
import { formatNumber } from '@/utils/numberFormatter';
import { utcDateToLocalDate } from '@/utils/utcDateToLocalDate';

import { getLrpAnalysisCurrentPricesTableData } from '@/ducks/lrp/selectors';
import { RootState } from '@/ducks/store';

import { Units } from '@/types/Units';

import {
  LrpAnalysisCurrentPriceAverage,
  LrpAnalysisCurrentPriceDifference,
  LrpAnalysisCurrentPricesMonths,
  LrpAnalysisFilterSettings
} from '../../../types/LrpAnalysis';

export enum Unit {
  CWT,
  HEAD,
  TOTAL
}
interface Props {
  settings?: LrpAnalysisFilterSettings;
  unit: number;
  selectedMeasure: { name: string; id: string };
}

const CurrentPricesTable: React.FC<Props> = ({ settings, unit, selectedMeasure }) => {
  const [lrpOffersDate, setLrpOffersDate] = React.useState<Date>(new Date());

  const currentPriceMonths: LrpAnalysisCurrentPricesMonths[] = useSelector(
    (state: RootState) =>
      getLrpAnalysisCurrentPricesTableData(state, {
        selectedMeasure: selectedMeasure.id,
        unit
      }).months
  );

  const currentPriceAverage: LrpAnalysisCurrentPriceAverage[] = useSelector(
    (state: RootState) =>
      getLrpAnalysisCurrentPricesTableData(state, {
        selectedMeasure: selectedMeasure.id,
        unit
      }).average
  );

  const currentPriceDifference: LrpAnalysisCurrentPriceDifference[] = useSelector(
    (state: RootState) =>
      getLrpAnalysisCurrentPricesTableData(state, {
        selectedMeasure: selectedMeasure.id,
        unit
      }).difference
  );

  type measureType = {
    averageMeasure: number | undefined;
    measureDifference: number | undefined;
  };

  type combinedDataType = LrpAnalysisCurrentPricesMonths & measureType;

  const combinedData: combinedDataType[] = [];

  currentPriceMonths.forEach((element, index) => {
    combinedData[index] = {
      month: element.month,
      endDate: element?.endDate ? utcDateToLocalDate(element.endDate) : undefined,
      expectedPrice: element.expectedPrice,
      coveragePrice: element.coveragePrice,
      netGuarantee: element.netGuarantee,
      producerPremium: element.producerPremium,
      averageMeasure: currentPriceAverage[index].value,
      measureDifference: currentPriceDifference[index].value
    };
  });

  const yearInterval = useSelector(
    (state: RootState) =>
      getLrpAnalysisCurrentPricesTableData(state, {
        selectedMeasure: selectedMeasure.id,
        unit
      }).yearInterval
  );

  React.useEffect(() => {
    if (settings && settings.salesEffectiveDate) {
      const date = new Date(settings.salesEffectiveDate.toString().slice(0, 19));
      setLrpOffersDate(date);
    }
  }, [settings]);

  let arePricesEmpty = true;

  const checkIfMonthlyPricesEmpty = () => {
    currentPriceMonths.forEach(e => {
      Object.entries(e).forEach(([key, value]) => {
        if (key !== 'month' && value !== undefined) {
          arePricesEmpty = false;
          return;
        }
      });
    });
  };

  checkIfMonthlyPricesEmpty();

  return (
    <TableWrapper>
      <Table cellSpacing={0}>
        <TableHeader>LRP offers on {formatDateYearMonthDay(lrpOffersDate)}</TableHeader>
        <>
          <SubTable cellSpacing={0} cellPadding={0}>
            <RowStyle>
              <TableHeaderStyleUnit>
                {unit === Unit.CWT ? '$/cwt' : unit === Unit.HEAD ? '$/head' : '$'}
              </TableHeaderStyleUnit>
              <TableHeaderStyle>Jan</TableHeaderStyle>
              <TableHeaderStyle>Feb</TableHeaderStyle>
              <TableHeaderStyle>Mar</TableHeaderStyle>
              <TableHeaderStyle>Apr</TableHeaderStyle>
              <TableHeaderStyle>May</TableHeaderStyle>
              <TableHeaderStyle>Jun</TableHeaderStyle>
              <TableHeaderStyle>Jul</TableHeaderStyle>
              <TableHeaderStyle>Aug</TableHeaderStyle>
              <TableHeaderStyle>Sep</TableHeaderStyle>
              <TableHeaderStyle>Oct</TableHeaderStyle>
              <TableHeaderStyle>Nov</TableHeaderStyle>
              <TableHeaderStyle>Dec</TableHeaderStyle>
            </RowStyle>
            <tr>
              <AlignCenter>
                <GraySegmentDate>End Date</GraySegmentDate>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>Expected Price</GraySegment>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>Coverage Price</GraySegment>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>Producer Premium</GraySegment>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>Net Guarantee</GraySegment>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>
                  Average {selectedMeasure.name} {yearInterval}
                </GraySegment>
              </AlignCenter>
              <AlignCenter>
                <GraySegment>{selectedMeasure.name} Difference </GraySegment>
              </AlignCenter>
              {arePricesEmpty ? (
                <ErrorContainer>{'No data available'}</ErrorContainer>
              ) : (
                combinedData.map(row => {
                  return (
                    <td key={row.month}>
                      <tr>
                        <TDdate>{formatDateYearMonthDay(row.endDate)}</TDdate>
                      </tr>
                      <tr>
                        <TD>
                          {row.expectedPrice
                            ? formatNumber(2)(Number(row.expectedPrice), Units.DOLLAR)
                            : ' '}
                        </TD>
                      </tr>
                      <tr>
                        <TD>
                          {row.coveragePrice
                            ? formatNumber(2)(Number(row.coveragePrice), Units.DOLLAR)
                            : ' '}
                        </TD>
                      </tr>
                      <tr>
                        <TD>
                          {row.producerPremium
                            ? formatNumber(2)(Number(row.producerPremium), Units.DOLLAR)
                            : ' '}
                        </TD>
                      </tr>
                      <tr>
                        <TD>
                          {row.netGuarantee
                            ? formatNumber(2)(Number(row.netGuarantee), Units.DOLLAR)
                            : row.netGuarantee === 0
                            ? 'N/A'
                            : ' '}
                        </TD>
                      </tr>
                      <tr>
                        <TD>
                          {row.averageMeasure
                            ? formatNumber(2)(Number(row.averageMeasure), Units.DOLLAR)
                            : ''}
                        </TD>
                      </tr>
                      <tr>
                        <TDdiff isPositive={Number(row.measureDifference) > 0}>
                          {row.measureDifference
                            ? formatNumber(2)(Number(row.measureDifference), Units.DOLLAR)
                            : ''}
                        </TDdiff>
                      </tr>
                    </td>
                  );
                })
              )}
            </tr>
          </SubTable>
        </>
      </Table>
    </TableWrapper>
  );
};

export default CurrentPricesTable;

const TableWrapper = styled.div`
  overflow-y: hidden;
  flex-grow: 1;
  height: 100%;
`;

const Table = styled.table`
  width: 100%;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const RowStyle = styled.tr`
  background-color: #6eb23f23;
  font-weight: bold;
  font-size: 12px;
`;

const TableHeaderStyle = styled.td`
  border-bottom: 2px solid #6eb23f;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 10px;
  min-width: 60px;
  text-align: center;
`;

const TableHeaderStyleUnit = styled.td`
  border-bottom: 2px solid #6eb23f;
  padding-top: 15px;
  padding-bottom: 5px;
  padding-right: 10px;
  min-width: 50px;
  text-align: right;
`;

const AlignCenter = styled.tr`
  text-align: left;
  td:first-child {
    background-color: #efefef;
    width: calc(85% - 68%);
  }
`;

const SubTable = styled.table`
  width: 100%;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${color.PRIMARY};
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding: 3px;
`;

const GraySegment = styled.td`
  padding-right: 10px;

  background-color: #efefef;
  text-align: right;
  font-weight: 600;
  font-size: 12px;
  height: 30px;
`;

const GraySegmentDate = styled.td`
  padding-right: 10px;
  padding-top: 10px;
  background-color: #efefef;
  text-align: right;
  font-weight: 600;
  font-size: 12px;
`;

const TD = styled.td`
  padding-right: 5px;
  padding-left: 5px;
  font-size: 12px;
  position: relative;
  height: 30px;
  top: -18px;
  transform: translatex(0px) translatey(p0x);
`;

const TDdate = styled.td`
  padding-right: 0px;
  padding-left: 0px;
  padding-top: 17px;
  font-size: 10px;
  position: relative;
  top: -18px;
  font-weight: 700;
  height: 30px;
  transform: translatex(3px) translatey(3px);
`;

const TDdiff = styled.td<{ isPositive: boolean }>`
  padding-right: 5px;
  padding-left: 5px;

  font-size: 12px;
  position: relative;
  height: 30px;
  top: -18px;
  transform: translatex(0px) translatey(p0x);
  color: ${({ isPositive }) => (isPositive ? color.PRIMARY : color.NEGATIVE)};
`;

const ErrorContainer = styled.tr`
  position: absolute;
  transform: translatex(500px) translatey(-100px);
`;
