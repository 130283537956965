import { combineReducers } from 'redux';

import allQuartersReducer from './allQuarters/reducer';
import { dairyRevenueProtectionHistoricalReducer } from './historical/reducer';
import { dairyRevenueProtectionIndemnityReducer } from './indemnity/reducer';
import { dairyRevenueProtectionMarketReportsReducer } from './marketReports/reducer';
import { dairyRevenueProtectionPriceComponentsReducer } from './priceComponents/reducer';
import { dairyRevenueProtectionSettingsReducer } from './settings/reducer';
import { dairyRevenueProtectionWhatIfDairyComponentsReducer } from './whatIfDairyComponents/reducer';
import { dairyRevenueProtectionWhatIfValuesReducer } from './whatIfDairyValues/reducer';
import { dairyRevenueProtectionWhatIfExpectedActualReducer } from './whatIfExpectedActual/reducer';
import { dairyRevenueProtectionWhatIfInputsReducer } from './whatIfInputs/reducer';
import { dairyRevenueProtectionWhatIfRawReducer } from './whatIfRaw/reducer';

export const dairyRevenueProtectionRootReducer = combineReducers({
  settings: dairyRevenueProtectionSettingsReducer,
  whatIfInputs: dairyRevenueProtectionWhatIfInputsReducer,
  whatIfDairyValues: dairyRevenueProtectionWhatIfValuesReducer,
  whatIfExpectedActual: dairyRevenueProtectionWhatIfExpectedActualReducer,
  whatIfDairyComponents: dairyRevenueProtectionWhatIfDairyComponentsReducer,
  priceComponents: dairyRevenueProtectionPriceComponentsReducer,
  whatIfRaw: dairyRevenueProtectionWhatIfRawReducer,
  indemnity: dairyRevenueProtectionIndemnityReducer,
  historical: dairyRevenueProtectionHistoricalReducer,
  reports: dairyRevenueProtectionMarketReportsReducer,
  allQuarters: allQuartersReducer
});
