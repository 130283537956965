import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import { Agency } from '../../types/Agency';
import { Agent, AgentLicense } from '../../types/Agent/Agent';
import Operation from '../../types/Operation/Operation';
import OperationWithPrimaryContact from '../../types/Operation/OperationWithPrimaryContact';
import FilteredOperationProducers from '../../types/Producer/FilteredOperationProducers';
import Producer from '../../types/Producer/Producer';
import ProducerModalData from '../../types/Producer/ProducerModalData';
import {
  AipDrpEndorsement,
  AipDto,
  AipDtoEndorsement,
  AipDtoMatching,
  AipLrpEndorsement,
  AipStatus
} from '../../types/dto/AipOperationDto';
import InsurancePlan from '../../types/dto/InsurancePlan';
import { OperationDto } from '../../types/dto/OperationDto';
import TimeZoneDto from '../../types/dto/TimeZoneDto';
import { operationsGetParameters } from './types';

export interface InsuranceAgentPayload {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  title: string;
  licences: AgentLicense[];
  timeZone: TimeZoneDto;
}

export const adminActions = {
  insuranceAgencies: {
    getAllInsuranceAgencies: createAction('@admin_insuranceAgency/GET_ALL')(),
    addAllInsuranceAgencies: createAction('@admin_insuranceAgency/ADD_ALL')<Agency[]>(),
    addInsuranceAgency: createAction('@admin_insuranceAgency/ADD')<Agency, () => void>(),
    newInsuranceAgency: createAction('@admin_insuranceAgency/NEW')<Agency>(),
    removeInsuranceAgency: createAction('@admin_insuranceAgency/REMOVE')<string, () => void>(),
    deleteInsuranceAgency: createAction('@admin_insuranceAgency/DELETE')<string>(),
    updateInsuranceAgency: createAction('@admin_insuranceAgency/UPDATE')<Agency, string>(),
    editInsuranceAgency: createAction('@admin_insuranceAgency/EDIT')<
      Agency,
      { name: string; cb: () => void }
    >(),
    editAgencyInsuranceProgram: createAction('@admin_insuranceAgency/EDIT_INSURANCE_PROGRAM')<
      Agency,
      { name: string; cb: () => void }
    >(),
    sort: createAction('@admin_insuranceAgency/SORT')<{ sort: string; direction: SortDirection }>()
  },
  insuranceAgents: {
    getAllAgencyInsuranceAgents: createAction('@admin_insuranceAgents/GET_ALL')<{
      agencyName: string;
      bindingRequirements: boolean;
    }>(),
    addAllInsuranceAgents: createAction('@admin_insuranceAgents/ADD_ALL')<Agent[], string>(),
    addInsuranceAgent: createAction('@admin_insuranceAgents/ADD')<
      InsuranceAgentPayload,
      () => void
    >(),
    newInsuranceAgent: createAction('@admin_insuranceAgents/NEW')<Agent, string>(),
    removeInsuranceAgent: createAction('@admin_insuranceAgents/REMOVE')<string, () => void>(),
    deleteInsuranceAgent: createAction('@admin_insuranceAgents/DELETE')<string, string>(),
    updateInsuranceAgent: createAction('@admin_insuranceAgents/UPDATE')<
      InsuranceAgentPayload,
      { userId: string; agency: string }
    >(),
    editInsuranceAgent: createAction('@admin_insuranceAgents/EDIT')<
      InsuranceAgentPayload,
      { userId: string; cb: () => void; oldLicenses: AgentLicense[] }
    >(),
    addAgentRole: createAction('@admin_insuranceAgents/ADD_AGENT_ROLE')<
      { organizationName: string; userName: string; role: string },
      () => void
    >(),
    addAdminAgentRole: createAction('@admin_insuranceAgents/EDIT_AGENT_ROLE')<{
      organizationName: string;
      userName: string;
      role: string;
    }>(),
    removeAgentRole: createAction('@admin_insuranceAgents/REMOVE_AGENT_ROLE')<
      { organizationName: string; userName: string; role: string },
      () => void
    >(),
    removeAdminAgentRole: createAction('@admin_organization_users/REMOVE_ADMIN_USER_ROLE')<{
      organizationName: string;
      userName: string;
    }>(),
    resendInvitation: createAction('@admin_insuranceAgents/RESEND_INVITATION')<
      string,
      () => void
    >(),
    sort: createAction('@admin_insuranceAgents/SORT')<
      { sort: string; direction: SortDirection },
      string
    >(),
    getAgentLicenses: createAction('@admin_insuranceAgents/GET_AGENT_LICENSES')<{
      userId: string;
    }>(),
    setAgentLicenses: createAction('@admin_insuranceAgents/SET_AGENT_LICENSES')<{
      organizationName: string;
      userId: string;
      licenses: AgentLicense[];
    }>(),
    createLicense: createAction('@admin_insuranceAgents/CREATE_LICENSE')<{
      userId: string;
      license: AgentLicense;
      oldLicense: AgentLicense | null;
    }>(),
    deleteLicense: createAction('@admin_insuranceAgents/DELETE_LICENSE')<{
      userId: string;
      licenseId: string;
    }>(),
    addLicense: createAction('@admin_insuranceAgents/ADD_LICENSE')<{
      organizationName: string;
      userId: string;
      license: AgentLicense;
    }>(),
    removeLicense: createAction('@admin_insuranceAgents/REMOVE_LICENSE')<{
      organizationName: string;
      userId: string;
      licenseId: string;
    }>()
  },
  aipSync: {
    getStatus: createAction('@admin_aipSync/GET_STATUS')<string>(),
    saveStatus: createAction('@admin_aipSync/SAVE_STATUS')<AipStatus[]>(),
    getOperations: createAction('@admin_aipSync/GET_OPERATIONS')<{
      agencyName: string;
      insurancePlan?: string;
      includeProbableMatches?: boolean;
      reinsuranceYear?: number;
    }>(),
    saveOperations: createAction('@admin_aipSync/SAVE_OPERATIONS')<AipDto[]>(),
    matchOperation: createAction('@admin_aipSync/MATCH_OPERATION')<
      AipDtoMatching,
      {
        agencyName: string;
        operationName: string;
        insurancePlan: string | undefined;
        reinsuranceYear?: number;
      }
    >(),
    rematchOperation: createAction('@admin_aipSync/REMATCH_OPERATION')<
      AipDtoMatching,
      {
        agencyName: string;
        operationName: string;
        insurancePlan: string | undefined;
        reinsuranceYear?: number;
      }
    >(),
    unmatchOperation: createAction('@admin_aipSync/UNMATCH_OPERATION')<
      AipDtoMatching,
      { agencyName: string; insurancePlan: string | undefined; reinsuranceYear?: number }
    >(),
    fetchOperations: createAction('@admin_aipSync/FETCH_OPERATION')(),
    fetchAipEndorsements: createAction('@admin_aipSync/FETCH_ENDORSEMENTS')(),
    saveAipEndorsements: createAction('@admin_aipSync/SAVE_ENDORSEMENTS')<AipDtoEndorsement[]>(),
    importAipEndorsement: createAction('@admin_aipSync/IMPORT_ENDORSEMENTS')<
      AipDrpEndorsement | AipLrpEndorsement,
      { operationName: string; agencyName: string; id: string }
    >(),
    ignoreAipDiscrepancy: createAction('@admin_aipSync/IGNORE_AIP_DISCREPANCY')<{
      operationName: string;
      agencyName: string;
      id: string;
      aipIgnore: boolean;
      insurancePlan: InsurancePlan;
    }>(),
    deleteEndorsement: createAction('@admin_aipSync/DELETE_ENDORSEMENT')<number>(),
    unmatchAipEndorsement: createAction('@admin_aipSync/UNMATCH_ENDORSEMENTS')<
      AipDrpEndorsement | AipLrpEndorsement,
      { operationName: string; agencyName: string }
    >()
  },
  operations: {
    getAgencyOperations: createAction('@admin_operations/GET_ALL')<operationsGetParameters>(),
    addAllOperations: createAction('@admin_operations/ADD_ALL')<Operation[]>(),
    addOperation: createAction('@admin_operations/ADD')<
      Omit<OperationWithPrimaryContact, 'name'>,
      { agencyName: string; cb: () => void; sort?: string; direction?: SortDirection }
    >(),
    newOperation: createAction('@admin_operations/NEW')<OperationWithPrimaryContact>(),
    removeOperation: createAction('@admin_operations/REMOVE')<string>(),
    deleteOperation: createAction('@admin_operations/DELETE')<string, () => void>(),
    updateOperation: createAction('@admin_operations/UPDATE')<Operation, string>(),
    updateStarredOperation: createAction('@admin_operations/UPDATE_STARRED')<string, boolean>(),
    editOperation: createAction('@admin_operations/EDIT')<
      Operation,
      { name: string; cb: () => void }
    >(),
    editOperationInsuranceProgram: createAction('@admin_operations/EDIT_INSURANCE_PROGRAM')<
      Operation,
      { name: string; cb: () => void }
    >(),
    editAllOperations: createAction('@admin_operations/EDIT_ALL_OPERATIONS')<
      Operation[],
      () => void
    >(),
    sort: createAction('@admin_operations/SORT')<{ sort?: string; direction?: SortDirection }>()
  },
  producers: {
    getAllProducers: createAction('@admin_producers/GET_ALL')<string>(),
    addAllProducers: createAction('@admin_producers/ADD_ALL')<Producer[], string>(),
    addProducer: createAction('@admin_producers/ADD')<
      { producer: ProducerModalData; operation: string },
      () => void
    >(),
    newProducer: createAction('@admin_producers/NEW')<Producer, string>(),
    removeProducer: createAction('@admin_producers/REMOVE')<string, () => void>(),
    deleteProducer: createAction('@admin_producers/DELETE')<string, string>(),
    updateProducer: createAction('@admin_producers/UPDATE')<
      ProducerModalData,
      { userId: string; agency: string }
    >(),
    editProducer: createAction('@admin_producers/EDIT')<
      ProducerModalData,
      { userId: string; cb: () => void }
    >(),
    resendInvitation: createAction('@admin_producers/RESEND_INVITATION')<string, () => void>(),
    sort: createAction('@admin_producers/SORT')<
      { sort: string; direction: SortDirection },
      string
    >()
  },
  operationsProducers: {
    getAgencyOperationProducers: createAction('@admin_operations_producers/GET_ALL')<
      string,
      string
    >(),
    addAgencyOperationProducers: createAction('@admin_operations_producers/ADD_ALL')<
      FilteredOperationProducers[],
      { agencyName: string; operationName: string }
    >(),
    assignAgencyProducerToOperation: createAction('@admin_operations_producers/ASSIGN')<{
      agencyName: string;
      operationName: string;
      user: Producer;
    }>(),
    addAgencyProducerToOperation: createAction('@admin_operations_producers/ADD')<{
      agencyName: string;
      operationName: string;
      user: Producer;
    }>(),
    removeAgencyProducerFromOperation: createAction('@admin_operations_producers/REMOVE')<{
      agencyName: string;
      operationName: string;
      user: Producer;
    }>(),
    deleteAgencyProducerFromOperation: createAction('@admin_operations_producers/DELETE')<{
      agencyName: string;
      operationName: string;
      userId: string;
    }>(),
    sort: createAction('@admin_operations_producers/SORT')<
      { sort: string; direction: SortDirection },
      { agencyName: string; operationName: string }
    >()
  },
  producerOperations: {
    getProducerOperations: createAction('@producer_operations/GET')<string>(),
    addProducerOperations: createAction('@producer_operations/ADD')<OperationDto[], string>(),
    assignOperation: createAction('@admin_producersASSIGN_OPERATION')<{
      agencyName: string;
      producer: Producer;
      operation: Operation;
    }>(),
    saveAssignedOperation: createAction('@admin_producers/SAVE_ASSIGNED_OPERATION')<{
      producerId: string;
      operation: Operation;
    }>(),
    removeAssignedOperation: createAction('@admin_producers/REMOVE_ASSIGNED_OPERATION')<{
      agencyName: string;
      producerId: string;
      operationName: string;
    }>(),
    deleteAssignedOperation: createAction('@admin_producers/DELETE_ASSIGNED_OPERATION')<{
      producerId: string;
      operationName: string;
    }>(),
    sort: createAction('@producer_operations/SORT')<
      { sort: keyof Operation | 'isAdded'; direction: SortDirection },
      string
    >()
  }
};

export type AdminActions = ActionType<typeof adminActions>;
