import { ActionType, createAction } from 'typesafe-actions';

import SortDirection from '../../enums/SortDirection';
import { Agent } from '../../types/Agent/Agent';
import Operation from '../../types/Operation/Operation';
import OperationContact from '../../types/Operation/OperationContact';

export const operationContactActions = {
  getOperationAgents: createAction('@operation_contact/GET_OPERATION_AGENTS')<string>(),
  getAgentOperations: createAction('@operation_contact/GET_AGENT_OPERATIONS')<string, string>(),
  addAllOperationContact: createAction('@operation_contact/ADD_ALL_OPERATION_CONTACT')<
    OperationContact[]
  >(),
  addOperationContact: createAction('@operation_contact/ADD_OPERATION_CONTACT')<OperationContact>(),
  updateAgentContact: createAction('@operation_contact/UPDATE_AGENT_CONTACT')<
    OperationContact,
    boolean
  >(),
  deleteAgentContact: createAction('@operation_contact/DELETE_AGENT_CONTACT')<string, string>(),
  removeAgentContact: createAction('@operation_contact/REMOVE_AGENT_CONTACT')<string, string>(),
  getAgencyOperationsContacts: createAction('@operation_contact/GET_AGENCY_OPERATIONS_CONTACTS')(),
  addAllAgencyOperationContact: createAction('@operation_contact/ADD_ALL_AGENCY_OPERATION_CONTACT')<
    OperationContact[]
  >(),
  sortAgentOperations: createAction('@operation_contact/SORT_AGENT_OPERATIONS')<
    { sort: keyof Operation | 'primary' | 'isContact' | 'notifications'; direction: SortDirection },
    string
  >(),
  sortOperationAgents: createAction('@operation_contact/SORT_OPERATION_AGENTS')<
    {
      sort: keyof Agent | 'primaryContact' | 'sendEndorsementNotification' | 'isContact';
      direction: SortDirection;
    },
    string
  >()
};

export type OperationContactActions = ActionType<typeof operationContactActions>;
