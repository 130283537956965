import * as SwitchPrimitive from '@radix-ui/react-switch';
import React from 'react';
import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

type SwitchVariant = 'default' | 'small' | 'smallSecondary';

type SwitchVariantStyles = {
  root_height: string;
  root_width: string;
  root_backgroundColor_checked: string;
  root_backgroundColor_unchecked: string;

  thumb_height: string;
  thumb_width: string;
  thumb_backgroundColor: string;
  thumb_translateX_active: string;
};

const switchVariants: Record<SwitchVariant, SwitchVariantStyles> = {
  default: {
    root_height: '24px',
    root_width: '44px',
    root_backgroundColor_checked: color.BACKGROUND_SECONDARY,
    root_backgroundColor_unchecked: color.DRP_INPUT,

    thumb_height: '20px',
    thumb_width: '20px',
    thumb_backgroundColor: '#fff',
    thumb_translateX_active: '20px'
  },

  small: {
    root_height: '20px',
    root_width: '34px',
    root_backgroundColor_checked: color.DRP_INPUT,
    root_backgroundColor_unchecked: color.DRP_INPUT,

    thumb_height: '16px',
    thumb_width: '16px',
    thumb_backgroundColor: '#fff',
    thumb_translateX_active: '14px'
  },
  smallSecondary: {
    root_height: '20px',
    root_width: '34px',
    root_backgroundColor_checked: color.DRP_INPUT,
    root_backgroundColor_unchecked: color.NEUTRAL,

    thumb_height: '16px',
    thumb_width: '16px',
    thumb_backgroundColor: '#fff',
    thumb_translateX_active: '14px'
  }
};

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitive.Root> & { variant?: SwitchVariant }
>(({ variant, ...props }, ref) => (
  <StyledSwitchRoot variant={variant} ref={ref} {...props}>
    <StyledThumb variant={variant} />
  </StyledSwitchRoot>
));
Switch.displayName = SwitchPrimitive.Root.displayName;

const StyledSwitchRoot = styled(SwitchPrimitive.Root)<{ variant?: SwitchVariant }>`
  display: inline-flex;
  padding: 0;
  flex-shrink: 0;
  cursor: pointer;
  align-items: center;
  border-radius: 9999px;
  border: 2px solid transparent;
  transition: color 0.2s;

  &:focus-visible {
    outline: none;
    box-shadow:
      0 0 0 2px ${color.DRP_INPUT},
      0 0 0 4px ${color.BACKGROUND_SECONDARY};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${({ theme, variant = 'default' }) => {
    const {
      root_height,
      root_width,
      root_backgroundColor_checked,
      root_backgroundColor_unchecked
    } = theme.components.switch[variant];

    return css`
      height: ${root_height};
      width: ${root_width};

      &[data-state='checked'] {
        background-color: ${root_backgroundColor_checked};
      }

      &[data-state='unchecked'] {
        background-color: ${root_backgroundColor_unchecked};
      }
    `;
  }}
`;

const StyledThumb = styled(SwitchPrimitive.Thumb)<{ variant?: SwitchVariant }>`
  pointer-events: none;
  display: block;
  border-radius: 9999px;
  box-shadow:
    0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s;

  ${({ theme, variant = 'default' }) => {
    const { thumb_height, thumb_width, thumb_backgroundColor, thumb_translateX_active } =
      theme.components.switch[variant];

    return css`
      height: ${thumb_height};
      width: ${thumb_width};
      background-color: ${thumb_backgroundColor};

      &[data-state='checked'] {
        transform: translateX(${thumb_translateX_active});
      }

      &[data-state='unchecked'] {
        transform: translateX(0);
      }
    `;
  }}
`;

export { Switch, switchVariants };
export type { SwitchVariant, SwitchVariantStyles };
