import { getType } from 'typesafe-actions';

import {
  DairyRevenueProtectionActions,
  dairyRevenueProtectionActions
} from '../../dairyRevenueProtection/actions';
import {
  ClassPriceWeightingFactorRestrictedValue,
  ComponentPriceWeightingFactorRestrictedValue
} from '../../dairyRevenueProtection/settings/reducer';
import { EndorsementActions, endorsementActions } from '../actions';

export interface EndorsementModalSettings {
  state: number;
  quarter: number;
  canSell: boolean;
  canSellNote: string;
  declaredMilkProduction: number;
  salesEffectiveDate: Date;
  calendarDate: Date;
  coverageLevel: number;
  declaredButterfatTest: number;
  protectionFactor: number;
  declaredProteinTest: number;
  declaredClassPriceWeightingFactor: number;
  declaredComponentPriceWeightingFactor: number;
  quarterList: EndorsementModalSettingsQuarterListItem[];
  isSalesDateAvailable: boolean;
  expectedRevenue: { component: number; class: number };
  revenueGuarantee: { component: number; class: number };
  producerPremium: { component: number; class: number };
  expectedRevenueCwt: { component: number; class: number };
  revenueGuaranteeCwt: { component: number; class: number };
  producerPremiumCwt: { component: number; class: number };
  reinsuranceYear: number;
  share: number;
}

export type EndorsementModalSettingsQuarterListItem = {
  quarter: string;
  practiceCode: number;
  proteinTest: number;
  butterfatTest: number;
  componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
  classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
};

export const initialState: EndorsementModalSettings = {
  state: 6,
  quarter: 0,
  canSell: false,
  canSellNote: '',
  declaredMilkProduction: 10000000,
  salesEffectiveDate: new Date(),
  calendarDate: new Date(),
  coverageLevel: 0.95,
  declaredButterfatTest: 3.9,
  protectionFactor: 1,
  declaredProteinTest: 3.2,
  declaredClassPriceWeightingFactor: 1,
  declaredComponentPriceWeightingFactor: 1,
  quarterList: [],
  isSalesDateAvailable: true,
  expectedRevenue: { component: 0, class: 0 },
  revenueGuarantee: { component: 0, class: 0 },
  producerPremium: { component: 0, class: 0 },
  expectedRevenueCwt: { component: 0, class: 0 },
  revenueGuaranteeCwt: { component: 0, class: 0 },
  producerPremiumCwt: { component: 0, class: 0 },
  reinsuranceYear: 0,
  share: 1
};

export const endorsementModalSettingsReducer = (
  state = initialState,
  action: EndorsementActions | DairyRevenueProtectionActions
) => {
  switch (action.type) {
    case getType(endorsementActions.modal.setModalCoverageLevel):
      return { ...state, coverageLevel: action.payload };
    case getType(endorsementActions.modal.setModalDeclaredButterfatTest):
      return { ...state, declaredButterfatTest: action.payload };
    case getType(endorsementActions.modal.setModalDeclaredClassPriceWeightingFactor):
      return { ...state, declaredClassPriceWeightingFactor: action.payload };
    case getType(endorsementActions.modal.setModalDeclaredComponentPriceWeightingFactor):
      return { ...state, declaredComponentPriceWeightingFactor: action.payload };
    case getType(endorsementActions.modal.setModalDeclaredMilkProduction):
      return { ...state, declaredMilkProduction: action.payload };
    case getType(endorsementActions.modal.setModalDeclaredProteinTest):
      return { ...state, declaredProteinTest: action.payload };
    case getType(endorsementActions.modal.setModalProtectionFactor):
      return { ...state, protectionFactor: action.payload };
    case getType(dairyRevenueProtectionActions.settings.setCanSell):
      return { ...state, canSell: action.payload };
    case getType(endorsementActions.modal.setModalQuarter): {
      const allQuarterData = [...state.quarterList];
      const defaultQuarterData = allQuarterData.find(q => q.practiceCode === action.payload);
      return {
        ...state,
        declaredButterfatTest: defaultQuarterData
          ? defaultQuarterData.butterfatTest
          : state.declaredButterfatTest,
        declaredProteinTest: defaultQuarterData
          ? defaultQuarterData.proteinTest
          : state.declaredProteinTest,
        quarter: action.payload,
        declaredClassPriceWeightingFactor: state.declaredClassPriceWeightingFactor,
        declaredComponentPriceWeightingFactor: state.declaredComponentPriceWeightingFactor
      };
    }
    case getType(endorsementActions.modal.setModalCalendarDate):
      return { ...state, calendarDate: action.payload };
    case getType(endorsementActions.modal.setModalSalesEffectiveDateWithoutPracticeFetch):
      return { ...state, salesEffectiveDate: action.payload };
    case getType(endorsementActions.modal.setModalState):
      return { ...state, state: action.payload };
    case getType(endorsementActions.modal.setModalShare):
      return { ...state, share: action.payload };
    case getType(endorsementActions.modal.setModalData):
      return { ...state, ...action.payload };
    case getType(endorsementActions.modal.initModalPracticeData): {
      const defaults = action.payload.practices.length
        ? action.payload.practices[0]
        : {
            butterfatTest: 3.9,
            proteinTest: 3.2,
            practiceCode: 0,
            componentPriceWeightingFactorRestrictedValue: null,
            classPriceWeightingFactorRestrictedValue: null
          };
      return {
        ...state,
        coverageLevel: action.payload.preferredCoverageLevel || 0.95,
        declaredButterfatTest: defaults.butterfatTest || 3.9,
        protectionFactor: action.payload.preferredProtectionLevel || 1,
        declaredProteinTest: defaults.proteinTest || 3.2,
        declaredClassPriceWeightingFactor: action.payload.preferredClassPriceWeightingFactor || 1,
        declaredComponentPriceWeightingFactor:
          action.payload.preferredComponentPriceWeightingFactor,
        state: Number(action.payload.stateCode),

        canSell: action.payload.canSell,
        canSellNote: action.payload.canSellNote,
        salesEffectiveDate: action.payload.salesEffectiveDate,
        calendarDate: action.payload.calendarDate,
        quarterList: action.payload.practices,
        quarter: defaults.practiceCode,
        reinsuranceYear: action.payload.reinsuranceYear,
        share: action.payload.share ?? 1
      };
    }
    case getType(endorsementActions.modal.setModalPracticeData): {
      const currentlySelectedQuarter: EndorsementModalSettingsQuarterListItem | undefined =
        action.payload.quarterList.find(quarter => quarter.practiceCode === state.quarter);

      const getQuarter = () => {
        if (currentlySelectedQuarter) return state.quarter;
        if (action.payload.quarterList.length) return action.payload.quarterList[0].practiceCode;
        return state.quarter;
      };

      return {
        ...state,
        quarter: getQuarter(),
        ...action.payload
      };
    }
    case getType(dairyRevenueProtectionActions.settings.updatePractice):
      return {
        ...state,
        quarterList: action.payload.practices,
        canSell: action.payload.canSell
      };
    case getType(dairyRevenueProtectionActions.settings.updateIsSalesDateAvailable): {
      return { ...state, isSalesDateAvailable: action.payload };
    }
    case getType(endorsementActions.modal.setIsSalesDateAvailable): {
      return { ...state, isSalesDateAvailable: action.payload };
    }
    default:
      return state;
  }
};
