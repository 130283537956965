import * as oidc from 'oidc-client-ts';

import { authActions } from '../ducks/authentication/actions';
import store from '../ducks/store';

const userManager = new oidc.UserManager({
  authority: import.meta.env.VITE_APP_AUTH_URL ?? '',
  client_id: import.meta.env.VITE_APP_CLIENT_ID ?? '',
  redirect_uri: `${import.meta.env.VITE_APP_PAGE_URL}/callback/login`,
  silent_redirect_uri: `${import.meta.env.VITE_APP_PAGE_URL}/callback/refresh`,
  post_logout_redirect_uri: `${import.meta.env.VITE_APP_PAGE_URL}/callback/logout`,
  automaticSilentRenew: false,
  response_type: 'code',
  scope: 'openid profile roles organization_api identity_api insurance_api offline_access',
  filterProtocolClaims: true,
  loadUserInfo: true
});

userManager.events.addUserLoaded(() => {
  store.dispatch(authActions.token.update());
});

userManager.events.addUserSignedOut(() => {
  store.dispatch(authActions.signIn());
});

export default userManager;
