import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { coveragePercentageOptions } from '@/shared/constants/coveragePercentage';

import { getAccountPreferences } from '../../ducks/accountPreferences/selectors';
import { commodityTypes } from '../../scenes/common/types/Commodity';
import {
  DataPoint,
  DataPointQuantile,
  LrpAnalysisCurrentPrices,
  LrpAnalysisHistoricalPrices,
  LrpPriceEvolutionAnalysis
} from '../../scenes/lrp/types/LrpAnalysis';
import {
  LrpEndorsementDto,
  LrpEndorsementFilterResponseDto
} from '../../scenes/lrp/types/LrpEndorsement';
import {
  LrpCoverageTableAllEndorsementLengthsDto,
  LrpCoverageTableDto,
  LrpCoverageTableHistoricaReducerlAll,
  LrpCoverageTableHistoricalAll,
  LrpFiltersDto
} from '../../scenes/lrp/types/LrpFiltersDto';
import {
  PricePreferenceDto,
  PricePreferenceProcessedDto
} from '../../scenes/lrp/types/PricePreferenceDto';
import {
  QuoterPreferenceDto,
  QuoterPreferenceReducerDto
} from '../../scenes/lrp/types/QuoterPreference';
import insuranceAPI from '../../services/insuranceService';
import { AccountPreferences } from '../../types/AccountPreferences/AccountPrefereces';
import Producer from '../../types/Producer/Producer';
import { formatNumber } from '../../utils/numberFormatter';
import { errorResponseStatusHandler } from '../../utils/responseStatusHandler';
import { utcDateToLocalDate, utcDateToLocalDateOptional } from '../../utils/utcDateToLocalDate';
import { loadersActions } from '../loaders/actions';
import { getOperationName } from '../operationPage/selectors';
import { lrpActions } from './actions';
import { initialState } from './reducer';

function* getFilters(action: ActionType<typeof lrpActions.getFilters>) {
  if (action.meta.setIsFiltersLoading) yield action.meta.setIsFiltersLoading(true);
  yield put(loadersActions.spinner.activate());
  yield put(loadersActions.spinnerLrpEndorsementModal.activate());

  try {
    const filters: AxiosResponse<LrpFiltersDto> = yield call(
      insuranceAPI.getFilter,
      action.payload
    );

    const commodities: { name: string; id: string }[] = [];
    const updatedData = {
      ...filters.data,
      commodities: (filters.data.commodities ?? []).map(e => ({
        ...e,
        types: (e.types ?? []).map(t => ({
          ...t,
          periods: (t.periods ?? []).map(p => ({
            ...p,
            endDate: utcDateToLocalDate(p.endDate)
          }))
        }))
      }))
    };
    if (updatedData.commodities.length > 0) {
      updatedData.commodities.forEach(e => {
        const commodity = { name: e.name, id: e.commodity };
        commodities.push(commodity);
      });
    }

    yield put(lrpActions.saveFiltersCommodities(commodities));

    const filtersProcessed: LrpFiltersDto = {
      ...updatedData,
      salesEffectiveDate: updatedData.salesEffectiveDate
        ? new Date(updatedData.salesEffectiveDate.toString().slice(0, 19))
        : undefined
    };

    yield put(lrpActions.saveFilters(filtersProcessed));
    yield put(lrpActions.saveSelectedCommodityData(filtersProcessed.commodities[0]));
  } catch (e: unknown) {
    errorResponseStatusHandler('No data available for selected criteria!');
  }
  if (action.meta.setIsFiltersLoading) yield action.meta.setIsFiltersLoading(false);
  yield put(loadersActions.spinner.disable());
  yield put(loadersActions.spinnerLrpEndorsementModal.disable());
}
export const checkForDefinitions = (e: any) =>
  typeof e === 'object' &&
  'response' in e &&
  typeof e.response === 'object' &&
  'data' in e.response &&
  typeof e.response.data === 'object' &&
  'Definitions' in e.response.data;

function* addRequestCoverage(action: ActionType<typeof lrpActions.addRequestCoverage>) {
  if (action.meta.setModifyingCoverageRequest) yield action.meta.setModifyingCoverageRequest(true);
  const operationName: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.requestCoverage, operationName, action.payload);
    toast.success('Request coverage submitted successfully.');
    yield action.meta.setModifyingCoverageRequest?.(false);
  } catch (e: any) {
    if (checkForDefinitions(e)) {
      yield action.meta.handleResponseCode?.(e.response.data.Definitions);
    } else {
      errorResponseStatusHandler(
        e.code === 'ERR_NETWORK' ? { ...e, status: 500, data: { status: 500 } } : e
      );
    }
    yield action.meta.setModifyingCoverageRequest?.(null);
  } finally {
    yield call(getLrpEndorsementFilter, lrpActions.getLrpEndorsementFilter(operationName));
  }
}

function* getCoverageTable(action: ActionType<typeof lrpActions.getCoverageTable>) {
  yield action.meta(true);
  yield put(loadersActions.spinner.activate());
  try {
    const coverageTable: AxiosResponse<LrpCoverageTableDto[]> = yield call(
      insuranceAPI.getCoverageTable,
      action.payload
    );

    if (coverageTable.data.length > 1) {
      const updatedData = (coverageTable.data ?? []).map(e => ({
        ...e,
        comparisonMonth: utcDateToLocalDateOptional(e.comparisonMonth),
        endDate: utcDateToLocalDate(e.endDate)
      }));
      yield put(lrpActions.saveCoverageTable(updatedData));
    }
    yield put(lrpActions.isCoverageTableAvailable(true));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.isCoverageTableAvailable(false));
  }
  yield action.meta(false);
  yield put(loadersActions.spinner.disable());
}

function* getEndorsementCalculation(
  action: ActionType<typeof lrpActions.getEndorsementCalculation>
) {
  if (action.meta) yield action.meta(true);
  yield put(loadersActions.spinnerLrpEndorsementModal.activate());
  try {
    const coverageTable: AxiosResponse<LrpCoverageTableDto[]> = yield call(
      insuranceAPI.getCoverageTable,
      action.payload
    );
    if (coverageTable.data.length > 0) {
      yield put(
        lrpActions.setEndorsementCalculation(
          coverageTable.data[action.payload.coveragePercentageRank - 1]
        )
      );
    }
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  }
  if (action.meta) yield action.meta(false);
  yield put(loadersActions.spinnerLrpEndorsementModal.disable());
}

function* getCoverageTableAllEndorsementLengths(
  action: ActionType<typeof lrpActions.getCoverageTable>
) {
  yield action.meta(true);
  try {
    const coverageTable: AxiosResponse<LrpCoverageTableAllEndorsementLengthsDto[]> = yield call(
      insuranceAPI.getCoverageTableAllEndorsementLengths,
      action.payload
    );
    if (coverageTable.data.length > 0) {
      const updatedData = (coverageTable.data ?? []).map(e => ({
        ...e,
        endDate: utcDateToLocalDate(e.endDate),
        comparisonMonth: utcDateToLocalDate(e.comparisonMonth)
      }));
      yield put(lrpActions.saveCoverageTableAllEndorsementLengths(updatedData));
    }
    yield put(lrpActions.isCoverageTableAllLengthsAvailable(true));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.isCoverageTableAllLengthsAvailable(false));
  }
  yield action.meta(false);
}

function* getCoverageTableHistorical(action: ActionType<typeof lrpActions.getCoverageTable>) {
  yield action.meta(true);
  try {
    const coverageTable: AxiosResponse<LrpCoverageTableHistoricalAll> = yield call(
      insuranceAPI.getCoverageTableHistorical,
      action.payload
    );

    const coverageTableProcessed: LrpCoverageTableHistoricaReducerlAll = {
      historicData: coverageTable.data.historicData.map(e => ({
        salesEffectiveDate: e.salesEffectiveDate,
        endDate: utcDateToLocalDate(e.endDate),
        expectedPriceTotal: e.expectedPrice,
        expectedPriceCwt: e.expectedPriceCwt,
        expectedPriceHead: e.expectedPriceHead,
        coveragePercent: e.coveragePercent,
        coveragePriceCwt: e.coveragePriceCwt,
        coveragePriceHead: e.coveragePriceHead,
        insuredValueTotal: e.insuredValue,
        producerPremiumTotal: e.producerPremium,
        producerPremiumCwt: e.producerPremiumCwt,
        producerPremiumHead: e.producerPremiumHead,
        netGuaranteeTotal: e.netGuarantee,
        netGuaranteeHead: e.netGuaranteeHead,
        netGuaranteeCwt: e.netGuaranteeCwt,
        actualEndingValueCwt: e.actualPriceCwt,
        actualEndingValueHead: e.actualPriceHead,
        actualEndingValueTotal: e.actualPrice,
        grossIndemnityCwt: e.grossIndemnityCwt,
        grossIndemnityHead: e.grossIndemnityHead,
        grossIndemnityTotal: e.grossIndemnity
      })),
      historicDataAvg: {
        salesEffectiveDate: action.payload.salesEffectiveDate,
        expectedPriceTotal: coverageTable.data.historicDataAvg.expectedPrice,
        expectedPriceCwt: coverageTable.data.historicDataAvg.expectedPriceCwt,
        expectedPriceHead: coverageTable.data.historicDataAvg.expectedPriceHead,
        coveragePercent: coverageTable.data.historicDataAvg.coveragePercent,
        coveragePriceCwt: coverageTable.data.historicDataAvg.coveragePriceCwt,
        coveragePriceHead: coverageTable.data.historicDataAvg.coveragePriceHead,
        insuredValueTotal: coverageTable.data.historicDataAvg.insuredValue,
        producerPremiumTotal: coverageTable.data.historicDataAvg.producerPremium,
        producerPremiumCwt: coverageTable.data.historicDataAvg.producerPremiumCwt,
        producerPremiumHead: coverageTable.data.historicDataAvg.producerPremiumHead,
        netGuaranteeTotal: coverageTable.data.historicDataAvg.netGuarantee,
        netGuaranteeHead: coverageTable.data.historicDataAvg.netGuaranteeHead,
        netGuaranteeCwt: coverageTable.data.historicDataAvg.netGuaranteeCwt,
        actualEndingValueCwt: coverageTable.data.historicDataAvg.actualPriceCwt,
        actualEndingValueHead: coverageTable.data.historicDataAvg.actualPriceHead,
        actualEndingValueTotal: coverageTable.data.historicDataAvg.actualPrice,
        grossIndemnityCwt: coverageTable.data.historicDataAvg.grossIndemnityCwt,
        grossIndemnityHead: coverageTable.data.historicDataAvg.grossIndemnityHead,
        grossIndemnityTotal: coverageTable.data.historicDataAvg.grossIndemnity
      },
      historicDataAvgYear5: {
        salesEffectiveDate: action.payload.salesEffectiveDate,
        expectedPriceTotal: coverageTable.data.historicDataAvgYear5.expectedPrice,
        expectedPriceCwt: coverageTable.data.historicDataAvgYear5.expectedPriceCwt,
        expectedPriceHead: coverageTable.data.historicDataAvgYear5.expectedPriceHead,
        coveragePercent: coverageTable.data.historicDataAvgYear5.coveragePercent,
        coveragePriceCwt: coverageTable.data.historicDataAvgYear5.coveragePriceCwt,
        coveragePriceHead: coverageTable.data.historicDataAvgYear5.coveragePriceHead,
        insuredValueTotal: coverageTable.data.historicDataAvgYear5.insuredValue,
        producerPremiumTotal: coverageTable.data.historicDataAvgYear5.producerPremium,
        producerPremiumCwt: coverageTable.data.historicDataAvgYear5.producerPremiumCwt,
        producerPremiumHead: coverageTable.data.historicDataAvgYear5.producerPremiumHead,
        netGuaranteeTotal: coverageTable.data.historicDataAvgYear5.netGuarantee,
        netGuaranteeHead: coverageTable.data.historicDataAvgYear5.netGuaranteeHead,
        netGuaranteeCwt: coverageTable.data.historicDataAvgYear5.netGuaranteeCwt,
        actualEndingValueCwt: coverageTable.data.historicDataAvgYear5.actualPriceCwt,
        actualEndingValueHead: coverageTable.data.historicDataAvgYear5.actualPriceHead,
        actualEndingValueTotal: coverageTable.data.historicDataAvgYear5.actualPrice,
        grossIndemnityCwt: coverageTable.data.historicDataAvgYear5.grossIndemnityCwt,
        grossIndemnityHead: coverageTable.data.historicDataAvgYear5.grossIndemnityHead,
        grossIndemnityTotal: coverageTable.data.historicDataAvgYear5.grossIndemnity
      }
    };

    if (coverageTableProcessed.historicData.length > 0) {
      yield put(lrpActions.saveCoverageTableHistorical(coverageTableProcessed));
    }
    yield put(lrpActions.isCoverageTableHistoricalAvailable(true));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.isCoverageTableHistoricalAvailable(false));
  }
  yield action.meta(false);
}

function* getCoverageTableAccordion(
  action: ActionType<typeof lrpActions.getCoverageTableAccordion>
) {
  try {
    const coverageTable: AxiosResponse<LrpCoverageTableAllEndorsementLengthsDto[]> = yield call(
      insuranceAPI.getCoverageTable,
      action.payload
    );
    const updatedData = (coverageTable.data ?? []).map(e => ({
      ...e,
      endDate: utcDateToLocalDate(e.endDate),
      comparisonMonth: utcDateToLocalDate(e.comparisonMonth)
    }));
    action.meta(updatedData);
  } catch (e) {
    errorResponseStatusHandler(e);
  }
}

function* getQuoterPreferences(action: ActionType<typeof lrpActions.getQuoterPreferences>) {
  try {
    const quoterPreferences: AxiosResponse<QuoterPreferenceDto> = yield call(
      insuranceAPI.getQuoterPreferences,
      action.payload
    );
    if (
      quoterPreferences.data.commodity &&
      quoterPreferences.data.conservationCompliance !== undefined &&
      quoterPreferences.data.coveragePercentageRank &&
      quoterPreferences.data.numberOfHead &&
      quoterPreferences.data.insuredSharedPercent &&
      quoterPreferences.data.isBeginner !== undefined &&
      quoterPreferences.data.basisSelection &&
      quoterPreferences.data.monthSelection &&
      quoterPreferences.data.strikeSelection &&
      quoterPreferences.data.targetWeight &&
      quoterPreferences.data.commodityType
    ) {
      const quoterPreferencesProcessed: QuoterPreferenceReducerDto = {
        commodity: quoterPreferences.data.commodity,
        conservationCompliance: quoterPreferences.data.conservationCompliance,
        coveragePercentageRank: quoterPreferences.data.coveragePercentageRank,
        numberOfHead: quoterPreferences.data.numberOfHead,
        insuredSharedPercent: quoterPreferences.data.insuredSharedPercent,
        isBeginner: quoterPreferences.data.isBeginner,
        basisSelection: quoterPreferences.data.basisSelection,
        monthSelection: quoterPreferences.data.monthSelection,
        strikeSelection: quoterPreferences.data.strikeSelection,
        targetWeight: quoterPreferences.data.targetWeight,
        commodityType: quoterPreferences.data.commodityType,
        showAllEndorsementLengths: quoterPreferences.data.showAllEndorsementLengths
          ? quoterPreferences.data.showAllEndorsementLengths
          : false,
        showCMEPutComparison: quoterPreferences.data.showCMEPutComparison
          ? quoterPreferences.data.showCMEPutComparison
          : false,
        showSubsidyOverview: quoterPreferences.data.showSubsidyOverview
          ? quoterPreferences.data.showSubsidyOverview
          : false
      };
      yield put(lrpActions.saveQuoterPreferences(quoterPreferencesProcessed));
    } else {
      yield put(
        lrpActions.saveQuoterPreferences({
          commodity: 'FeederCattle',
          coveragePercentageRank: 1,
          commodityType: 'SteersWeight1',
          numberOfHead: 100,
          targetWeight: 7,
          insuredSharedPercent: 1,
          conservationCompliance: 1,
          isBeginner: false,
          monthSelection: 'FirstAfter',
          basisSelection: 'LrpCoveragePrice',
          strikeSelection: 'CoveragePercentage',
          showAllEndorsementLengths: false,
          showCMEPutComparison: false,
          showSubsidyOverview: false
        })
      );
    }
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(
      lrpActions.saveQuoterPreferences({
        commodity: 'FeederCattle',
        coveragePercentageRank: 1,
        commodityType: 'SteersWeight1',
        numberOfHead: 100,
        targetWeight: 7,
        insuredSharedPercent: 1,
        conservationCompliance: 1,
        isBeginner: false,
        monthSelection: 'FirstAfter',
        basisSelection: 'LrpCoveragePrice',
        strikeSelection: 'CoveragePercentage',
        showAllEndorsementLengths: false,
        showCMEPutComparison: false,
        showSubsidyOverview: false
      })
    );
  }
}

function* getPricePreferences(_action: ActionType<typeof lrpActions.getPricePreferences>) {
  yield put(lrpActions.setPricePreferenceSpinner(true));
  try {
    const operationName: string = yield select(getOperationName);
    const pricepreferences: AxiosResponse<PricePreferenceDto[]> = yield call(
      insuranceAPI.getPricePreferences,
      operationName
    );
    const pricePreferencesProcessed: PricePreferenceProcessedDto[] = pricepreferences.data.map(
      e => {
        const percentageOption = coveragePercentageOptions.filter(
          co => co.id === e.coverageLevelOrdinal.toString()
        );
        const commodityTypeOption = commodityTypes.filter(co => co.label === e.type);
        return {
          ...e,
          typeString: commodityTypeOption.length ? commodityTypeOption[0].name : '',
          targetWeightString: e.targetWeight ? `${formatNumber(2)(e.targetWeight)}` : '',
          coverageLevelOrdinalString: percentageOption.length ? percentageOption[0].name : ''
        };
      }
    );

    yield put(lrpActions.savePricePreferences(pricePreferencesProcessed));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    yield put(lrpActions.setPricePreferenceSpinner(false));
  }
}

function* updateQuoterPreferences(action: ActionType<typeof lrpActions.updateQuoterPreferences>) {
  const operationName: string = yield select(getOperationName);
  const {
    commodity,
    coveragePercentageRank,
    commodityType,
    numberOfHead,
    targetWeight,
    insuredSharedPercent,
    conservationCompliance,
    isBeginner,
    monthSelection,
    basisSelection,
    strikeSelection,
    showAllEndorsementLengths,
    showCMEPutComparison,
    showSubsidyOverview
  } = action.payload;
  try {
    yield call(
      insuranceAPI.updateQuoterPreference,
      operationName,
      commodity,
      coveragePercentageRank,
      commodityType,
      numberOfHead,
      targetWeight,
      insuredSharedPercent,
      conservationCompliance,
      isBeginner,
      monthSelection,
      basisSelection,
      strikeSelection,
      showAllEndorsementLengths,
      showCMEPutComparison,
      showSubsidyOverview
    );

    put(lrpActions.getQuoterPreferences(operationName));

    toast.success('Quoter preferences updated successfully');
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    yield call(getQuoterPreferences, lrpActions.getQuoterPreferences(operationName));
  }
}

function* createPricePreference(action: ActionType<typeof lrpActions.createPricePreference>) {
  const operationName: string = yield select(getOperationName);

  try {
    yield call(insuranceAPI.createPricePreference, operationName, action.payload);
    put(lrpActions.getPricePreferences());

    toast.success('Category saved successfully');
    const accountPreferences: AccountPreferences = yield select(getAccountPreferences);
    if (!accountPreferences.lrpPriceReport.frequency) {
      toast.info(
        'You are currently opted out from receiving price alerts. You can change this in My Account.'
      );
    }
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    yield call(getPricePreferences, lrpActions.getPricePreferences());
    action.meta();
  }
}

function* deletePricePreference(action: ActionType<typeof lrpActions.deletePricePreference>) {
  const operationName: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.deletePricePreference, operationName, action.payload);
    put(lrpActions.getPricePreferences());
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    yield call(getPricePreferences, lrpActions.getPricePreferences());
    action.meta();
  }
}

function* getLrpEndorsements(action: ActionType<typeof lrpActions.getLrpEndorsements>) {
  yield action.meta(true);
  try {
    const lrpEndorsements: AxiosResponse<LrpEndorsementDto[]> = yield call(
      insuranceAPI.getLrpEndorsements,
      action.payload.operationName,
      action.payload
    );
    yield put(lrpActions.setEndorsements(lrpEndorsements.data));
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
    yield put(lrpActions.setEndorsements([]));
  } finally {
    yield action.meta(false);
  }
}

function* getLrpEndorsementSummaryTable(
  action: ActionType<typeof lrpActions.getLrpEndorsementSummaryTable>
) {
  yield action.meta(true);
  try {
    const lrpEndorsementSummaryTable: AxiosResponse<LrpEndorsementDto[]> = yield call(
      insuranceAPI.getLrpEndorsementSummaryTable,
      action.payload.operationName,
      action.payload
    );

    const sortObjectByYearAndMonth = () => (a: LrpEndorsementDto, b: LrpEndorsementDto) => {
      const firstValue = a.endYear?.toString() + '-' + a.endMonth?.toString().padStart(2, '0');
      const secondValue = b.endYear?.toString() + '-' + b.endMonth?.toString().padStart(2, '0');
      if (firstValue < secondValue) {
        return -1;
      }
      if (firstValue > secondValue) {
        return 1;
      }
      return 0;
    };

    lrpEndorsementSummaryTable.data.sort(sortObjectByYearAndMonth());
    yield put(lrpActions.setEndorsementSummary(lrpEndorsementSummaryTable.data));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    yield action.meta(false);
  }
}

function* getLrpEndorsementFilter(action: ActionType<typeof lrpActions.getLrpEndorsementFilter>) {
  try {
    const lrpEndorsementFilter: AxiosResponse<LrpEndorsementFilterResponseDto> = yield call(
      insuranceAPI.getLrpEndorsementFilter,
      action.payload
    );
    let responseDates = {};

    if (lrpEndorsementFilter.data.startDate && lrpEndorsementFilter.data.endDate) {
      responseDates = {
        startDate: new Date(lrpEndorsementFilter.data.startDate.toString()),
        endDate: new Date(lrpEndorsementFilter.data.endDate.toString())
      };
    }
    yield put(lrpActions.saveLrpEndorsementFilter(responseDates));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  }
}

function* recalculateEndorsements(action: ActionType<typeof lrpActions.recalculateEndorsements>) {
  try {
    yield call(insuranceAPI.recalculateLrpEndorsements, action.payload);
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    action.meta();
  }
}

function* addEndorsement(action: ActionType<typeof lrpActions.addEndorsement>) {
  if (action.meta) yield action.meta(true);
  const operationName: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.createLrpEndorsement, operationName, action.payload);
    toast.success('Added new endorsement successfully.');
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    if (action.meta) yield action.meta(false);
    yield call(getLrpEndorsementFilter, lrpActions.getLrpEndorsementFilter(operationName));
  }
}

function* deleteLrpEndorsement(action: ActionType<typeof lrpActions.deleteLrpEndorsement>): any {
  try {
    action.meta(false);
    yield call(insuranceAPI.deleteLrpEndorsement, action.payload);
    toast.success('You have deleted the endorsement successfully');
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    action.meta(true);
    yield call(
      getLrpEndorsementFilter,
      lrpActions.getLrpEndorsementFilter(action.payload.operationName)
    );
  }
}

function* updateLrpEndorsement(action: ActionType<typeof lrpActions.updateLrpEndorsement>) {
  action.meta(true);
  try {
    const data = {
      ...action.payload.data,
      tags: action.payload.data.tags?.length === 0 ? null : action.payload.data.tags,
      countyCode:
        action.payload.data.countyCode === '998' || action.payload.data.countyCode === ''
          ? null
          : action.payload.data.countyCode,
      brokerageNotes:
        action.payload.data.brokerageNotes === '' ? null : action.payload.data.brokerageNotes,
      penIdentifier:
        action.payload.data.penIdentifier === '' ? null : action.payload.data.penIdentifier
    };
    yield call(insuranceAPI.updateLrpEndorsement, action.payload.operationName, data);
    toast.success('You have updated the endorsement successfully');
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  } finally {
    action.meta(false);
    yield call(
      getLrpEndorsementFilter,
      lrpActions.getLrpEndorsementFilter(action.payload.operationName)
    );
  }
}

function* getLrpAnalysisCurrentPrices(
  action: ActionType<typeof lrpActions.getLrpAnalysisCurrentPrices>
) {
  yield action.meta(true);
  try {
    const lrpAnalysisCurrentPrices: AxiosResponse<LrpAnalysisCurrentPrices> = yield call(
      insuranceAPI.getLrpAnalysisCurrentPrices,

      action.payload
    );
    yield put(lrpActions.saveLrpAnalysisCurrentPrices(lrpAnalysisCurrentPrices.data));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.saveLrpAnalysisCurrentPrices(initialState.lrpAnalysisCurrentPrices));
  } finally {
    yield action.meta(false);
  }
}

function* getLrpAnalysisHistoricalPrices(
  action: ActionType<typeof lrpActions.getLrpAnalysisHistoricalPrices>
) {
  yield action.meta(true);
  try {
    if (!action.payload.filterComponent.commodityType) {
      throw new Error('Commodity type is required');
    }

    const lrpAnalysisHistoricalPrices: AxiosResponse<LrpAnalysisHistoricalPrices> = yield call(
      insuranceAPI.getLrpAnalysisHistoricalPrices,
      action.payload
    );

    yield put(lrpActions.saveLrpAnalysisHistoricalPrices(lrpAnalysisHistoricalPrices.data));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.saveLrpAnalysisHistoricalPrices(initialState.lrpAnalysisHistoricalPrices));
  } finally {
    yield action.meta(false);
  }
}

function* getOperationProducers(action: ActionType<typeof lrpActions.getOperationProducer>) {
  try {
    const producers: AxiosResponse<Producer[]> = yield call(
      insuranceAPI.getOperationProducer,
      action.payload.operationName,
      action.payload.includeBindingRequirements
    );
    yield put(lrpActions.saveOperationProducer(producers.data));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
  }
}
function* getLrpPriceEvolutionAnalysis(
  action: ActionType<typeof lrpActions.getLrpPriceEvolutionAnalysis>
) {
  yield put(loadersActions.spinner.activate());
  yield action.meta(true);
  try {
    const lrpPriceEvolutionAnalysis: AxiosResponse<LrpPriceEvolutionAnalysis> = yield call(
      insuranceAPI.getLrpPriceEvolutionAnalysis,
      action.payload
    );
    const formatDataPoint = (dp: DataPoint) => ({ ...dp, date: utcDateToLocalDate(dp?.date) });
    const formatDataPointQuantile = (dpq: DataPointQuantile) => ({
      ...dpq,
      dataPoint: formatDataPoint(dpq?.dataPoint)
    });
    const formatQuantileSection = (section: {
      average: DataPointQuantile;
      low: DataPointQuantile;
      high: DataPointQuantile;
      earliestDate: Date;
      latestDate: Date;
    }) => ({
      ...section,
      average: formatDataPointQuantile(section.average),
      low: formatDataPointQuantile(section.low),
      high: formatDataPointQuantile(section.high),
      earliestDate: utcDateToLocalDate(section.earliestDate),
      latestDate: utcDateToLocalDate(section.latestDate)
    });

    const formatedResponse: LrpPriceEvolutionAnalysis = {
      ...lrpPriceEvolutionAnalysis.data,
      latest: formatDataPointQuantile(lrpPriceEvolutionAnalysis.data.latest),
      previous: formatDataPointQuantile(lrpPriceEvolutionAnalysis.data.previous),
      days7: formatQuantileSection(lrpPriceEvolutionAnalysis.data.days7),
      days30: formatQuantileSection(lrpPriceEvolutionAnalysis.data.days30),
      overall: formatQuantileSection(lrpPriceEvolutionAnalysis.data.overall)
    };
    if ('actual' in lrpPriceEvolutionAnalysis.data) {
      formatedResponse.actual = formatDataPoint(lrpPriceEvolutionAnalysis.data.actual);
    }
    if ('dataPoints' in lrpPriceEvolutionAnalysis.data) {
      formatedResponse.dataPoints.forEach(
        dataPoint => (dataPoint.date = utcDateToLocalDate(dataPoint.date))
      );
    }
    yield put(lrpActions.saveLrpPriceEvolutionAnalysis(formatedResponse));
  } catch (e: unknown) {
    errorResponseStatusHandler(e);
    yield put(lrpActions.saveLrpPriceEvolutionAnalysis(initialState.lrpPriceEvolutionAnalysis));
  } finally {
    yield action.meta(false);
    yield put(loadersActions.spinner.disable());
  }
}

function* lrpSagaWatcher() {
  yield all([
    takeEvery(getType(lrpActions.getFilters), getFilters),
    takeEvery(getType(lrpActions.getCoverageTable), getCoverageTable),
    takeEvery(getType(lrpActions.getEndorsementCalculation), getEndorsementCalculation),
    takeLatest(
      getType(lrpActions.getCoverageTableAllEndorsementLengths),
      getCoverageTableAllEndorsementLengths
    ),
    takeLatest(getType(lrpActions.getCoverageTableHistorical), getCoverageTableHistorical),
    takeEvery(getType(lrpActions.getCoverageTableAccordion), getCoverageTableAccordion),
    takeLatest(getType(lrpActions.getQuoterPreferences), getQuoterPreferences),
    takeLatest(getType(lrpActions.getOperationProducer), getOperationProducers),
    takeLatest(getType(lrpActions.updateQuoterPreferences), updateQuoterPreferences),
    takeLatest(getType(lrpActions.getPricePreferences), getPricePreferences),
    takeLatest(getType(lrpActions.createPricePreference), createPricePreference),
    takeLatest(getType(lrpActions.deletePricePreference), deletePricePreference),
    takeLatest(getType(lrpActions.addRequestCoverage), addRequestCoverage),
    takeLatest(getType(lrpActions.getLrpEndorsements), getLrpEndorsements),
    takeLatest(getType(lrpActions.getLrpEndorsementFilter), getLrpEndorsementFilter),
    takeLatest(getType(lrpActions.getLrpEndorsementSummaryTable), getLrpEndorsementSummaryTable),
    takeLatest(getType(lrpActions.addEndorsement), addEndorsement),
    takeLatest(getType(lrpActions.recalculateEndorsements), recalculateEndorsements),
    takeLatest(getType(lrpActions.deleteLrpEndorsement), deleteLrpEndorsement),
    takeLatest(getType(lrpActions.updateLrpEndorsement), updateLrpEndorsement),
    takeEvery(getType(lrpActions.getLrpAnalysisCurrentPrices), getLrpAnalysisCurrentPrices),
    takeEvery(getType(lrpActions.getLrpAnalysisHistoricalPrices), getLrpAnalysisHistoricalPrices),
    takeEvery(getType(lrpActions.getLrpPriceEvolutionAnalysis), getLrpPriceEvolutionAnalysis)
  ]);
}

export default lrpSagaWatcher;
