import { SelectOption } from '../components/shared/MultiSelect/MultiSelect';
import { DataPointMonth, LrpAnalysisHistoricalPrices } from '../scenes/lrp/types/LrpAnalysis';

const filterObjectsByYear = ({
  historicalData,
  yearsRange
}: {
  historicalData: LrpAnalysisHistoricalPrices;
  yearsRange: SelectOption[];
}) => {
  const yearMap = new Map();
  for (const obj of yearsRange) {
    yearMap.set(obj.value, obj);
  }

  const filteredObjects = historicalData.years.filter(obj => {
    const year = obj.year;
    return yearMap.has(year);
  });

  return filteredObjects;
};

export const filterObjectsByYearAnalysis = ({
  historicalData,
  yearsRange
}: {
  historicalData: {
    year: number;
    endDate: Date;
    monthDataPoints: DataPointMonth[];
  }[];
  yearsRange: SelectOption[];
}) => {
  const yearMap = new Map();
  for (const obj of yearsRange) {
    yearMap.set(obj.value, obj);
  }

  const filteredObjects = historicalData.filter(obj => {
    const year = obj.year;
    return yearMap.has(year);
  });

  return filteredObjects;
};

export default filterObjectsByYear;
