import { addQuarters, getQuarter, parse } from 'date-fns';
import { createSelector } from 'reselect';

import { getHedgeRatioEquivalents } from '@/components/EndorsementReport/PDFReport/ReportVariants/EndorsementReportUtils';

import {
  EndorsementSummariesWithOperationEndorsements,
  ReportTableData
} from '@/types/Endorsement/EndorsementReport';

import { EndorsementChartData } from '../../components/Charts/EndorsementChart';
import { EndorsementSummary } from '../../types/Endorsement/EndorsementSummary';
import { EndorsementDrp, EndorsementLrp } from '../../types/dto/EndorsementDto';
import quarterOfYear from '../../utils/dateToQuarter';
import { parseQuarter } from '../../utils/mapQuarter';
import numberRange from '../../utils/numberRange';
import { getStatesList } from '../areas/selectors';
import {
  getQuarterStringFromProps,
  getReferenceIdentifierFromProps,
  getRouteComponentPropsFromProps
} from '../fromPropsSelector';
import { getOperationName } from '../operationPage/selectors';
import { RootState } from '../store';
import {
  formatHedgeRatio,
  getProjectedProductionDataDollar,
  getProjectedProductionDataLbs
} from './utils/getReportChartData';

export const getEndorsementModalSettings = (state: RootState) => state.endorsement.settings;

export const getModalState = (state: RootState) => state.endorsement.settings.state;
export const getModalQuarter = (state: RootState) => state.endorsement.settings.quarter;
export const getModalCanSell = (state: RootState) => state.endorsement.settings.canSell;
export const getModalCanSellNote = (state: RootState) => state.endorsement.settings.canSellNote;
export const getModalDeclaredMilkProduction = (state: RootState) =>
  state.endorsement.settings.declaredMilkProduction;
export const getModalSalesEffectiveDate = (state: RootState) =>
  state.endorsement.settings.salesEffectiveDate;
export const getModalCalendarDate = (state: RootState) => state.endorsement.settings.calendarDate;
export const getModalCoverageLevel = (state: RootState) => state.endorsement.settings.coverageLevel;
export const getModalDeclaredButterfatTest = (state: RootState) =>
  state.endorsement.settings.declaredButterfatTest;
export const getModalProtectionFactor = (state: RootState) =>
  state.endorsement.settings.protectionFactor;
export const getModalDeclaredProteinTest = (state: RootState) =>
  state.endorsement.settings.declaredProteinTest;
export const getModalDeclaredClassPriceWeightingFactor = (state: RootState) =>
  state.endorsement.settings.declaredClassPriceWeightingFactor;
export const getModalDeclaredComponentPriceWeightingFactor = (state: RootState) =>
  state.endorsement.settings.declaredComponentPriceWeightingFactor;
export const getModalQuarterList = (state: RootState) => state.endorsement.settings.quarterList;
export const getModalIsSalesDateAvailable = (state: RootState) =>
  state.endorsement.settings.isSalesDateAvailable;
export const getModalReinsuranceYear = (state: RootState) =>
  state.endorsement.settings.reinsuranceYear;
export const getModalShare = (state: RootState) => state.endorsement.settings.share;
export const getAllEndorsementSummaries = (state: RootState) =>
  state.endorsement.endorsementsData.operationEndorsementsSummaries;
export const getAllEndorsements = (state: RootState) =>
  state.endorsement.endorsementsData.filteredOperationEndorsements;

export const getCoverageRequest = (state: RootState) =>
  state.endorsement.endorsementsData.coverageRequest;
export const getCoverageRequests = (state: RootState) =>
  state.endorsement.endorsementsData.coverageRequests;
export const getCoverageRequestsFetchTimeStamp = (state: RootState) =>
  state.endorsement.endorsementsData.coverageRequestsFetchTimeStamp;
export const getCoverageRequestEndorsements = (state: RootState) =>
  state.endorsement.endorsementsData.coverageRequestEndorsements;
export const getCoverageRequestQuarterList = (state: RootState) =>
  state.endorsement.endorsementsData.quarterList;
export const getCoverageRequestHistory = (state: RootState) =>
  state.endorsement.endorsementsData.coverageRequestHistory;

export const getSelectCoverageRequestQuarterList = createSelector(
  getCoverageRequestQuarterList,
  list =>
    list.map(({ quarter, practiceCode }) => ({
      name: quarter,
      id: practiceCode
    }))
);

export const mapEndorsementsToModalSettings = (list: (EndorsementDrp | EndorsementLrp)[]) =>
  list.map((l: EndorsementDrp | EndorsementLrp) => ({
    id: l.id,
    priceType: l.typeCode === '832' ? 'Component' : 'Class',
    state: Number(l.stateCode),
    calendarDate: l.salesEffectiveDate,
    quarter: Number(l.practiceCode),
    declaredButterfatTest: 'butterfatTest' in l ? l.butterfatTest : 0,
    declaredProteinTest: 'proteinTest' in l ? l.proteinTest : 0,
    declaredClassPriceWeightingFactor:
      'classPriceWeightingFactor' in l ? l.classPriceWeightingFactor : 0,
    declaredComponentPriceWeightingFactor:
      'componentPriceWeightingFactor' in l ? l.componentPriceWeightingFactor : 0,
    coverageLevel: 'coverageLevel' in l ? l.coverageLevel : 0,
    protectionFactor: 'protectionFactor' in l ? l.protectionFactor : 0,
    declaredMilkProduction: 'milkProduction' in l ? l.milkProduction : 0,
    reinsuranceYear: l.reinsuranceYear,
    tags: l.tags ?? [],
    share: l.share ?? 1
  }));

export const getCoverageRequestParameters = createSelector(
  getCoverageRequestEndorsements,
  (list: (EndorsementDrp | EndorsementLrp)[]) => mapEndorsementsToModalSettings(list)
);

export const mapEndorsementsToCalculatedValues = (list: (EndorsementDrp | EndorsementLrp)[]) =>
  list.map((l: EndorsementDrp | EndorsementLrp) => ({
    component: {
      expectedRevenue: 'revenueExpected' in l ? l.revenueExpected : 0,
      expectedRevenueCwt: 'revenueExpectedCwt' in l ? l.revenueExpectedCwt : 0,
      revenueGuarantee: 'revenueGuarantee' in l ? l.revenueGuarantee : 0,
      revenueGuaranteeCwt: 'revenueGuaranteeCwt' in l ? l.revenueGuaranteeCwt : 0,
      producerPremium: l.premiumProducer,
      producerPremiumCwt: l.premiumProducerCwt
    },
    class: {
      expectedRevenue: 'revenueExpected' in l ? l.revenueExpected : 0,
      expectedRevenueCwt: 'revenueExpectedCwt' in l ? l.revenueExpectedCwt : 0,
      revenueGuarantee: 'revenueGuarantee' in l ? l.revenueGuarantee : 0,
      revenueGuaranteeCwt: 'revenueGuaranteeCwt' in l ? l.revenueGuaranteeCwt : 0,
      producerPremium: l.premiumProducer,
      producerPremiumCwt: l.premiumProducerCwt
    }
  }));

export const getCoverageRequestCalculatedValues = createSelector(
  getCoverageRequestEndorsements,
  (list: (EndorsementDrp | EndorsementLrp)[]) => mapEndorsementsToCalculatedValues(list)
);

export const getCoverageRequestAgentSignerProducer = createSelector(
  getCoverageRequests,
  getReferenceIdentifierFromProps,
  (list, refIdentifier) => {
    const agentProducer = list.filter(l => l.bindingRequest.referenceIdentifier === refIdentifier);
    if (agentProducer.length > 0) {
      const agent = agentProducer[0].agent?.email;
      const producer = agentProducer[0].producer?.email;
      return { agent: agent, producer: producer };
    } else {
      return { agent: '', producer: '' };
    }
  }
);

export const getCoverageRequestAgentSignerProducerFromMail = createSelector(
  getCoverageRequest,
  coverageRequest => {
    if (coverageRequest) {
      const agent = coverageRequest.agent?.email;
      const producer = coverageRequest.producer?.email;
      return { agent: agent, producer: producer };
    } else {
      return { agent: '', producer: '' };
    }
  }
);

export const getModalCalculatedValues = (state: RootState) => ({
  expectedRevenue: state.endorsement.settings.expectedRevenue,
  revenueGuarantee: state.endorsement.settings.revenueGuarantee,
  producerPremium: state.endorsement.settings.producerPremium,
  expectedRevenueCwt: state.endorsement.settings.expectedRevenueCwt,
  revenueGuaranteeCwt: state.endorsement.settings.revenueGuaranteeCwt,
  producerPremiumCwt: state.endorsement.settings.producerPremiumCwt
});

export const getModalStateName = createSelector(getStatesList, getModalState, (list, id) => {
  const state = list[list.findIndex(({ stateCode }) => Number(stateCode) === id)];
  if (state !== undefined) {
    return state.stateName;
  }
  return '';
});

export const getModalQuarterName = createSelector(
  getModalQuarterList,
  getModalQuarter,
  (list, id) => {
    if (list.length === 0) {
      return 'none';
    }
    const quarter = list[list.findIndex(({ practiceCode }) => practiceCode === id)];
    if (!quarter) {
      return 'none';
    }
    return quarter.quarter;
  }
);

export const getModalQuarterSelectList = createSelector(getModalQuarterList, list =>
  list.map(({ quarter, practiceCode }) => ({
    name: quarter,
    id: practiceCode
  }))
);

const getEndorsements = (state: RootState) => state.endorsement.endorsementsData;

export const getOperationEndorsements = createSelector(
  getEndorsements,
  getRouteComponentPropsFromProps,
  (endorsements, operationName) => endorsements.operationEndorsements[operationName]
);

export const endorsementSorting = (s1: EndorsementSummary, s2: EndorsementSummary) => {
  if (s1.year < s2.year) {
    return -1;
  }
  if (s1.year > s2.year) {
    return 1;
  }
  if (s1.year === s2.year) {
    if (s1.quarter < s2.quarter) {
      return -1;
    }
    if (s1.quarter > s2.quarter) {
      return 1;
    }
    return 0;
  }
  return 0;
};

export const getOperationEndorsementSummariesWithOperationNameFromState = createSelector(
  getEndorsements,
  getOperationName,
  (endorsements, operationName) => {
    const endorsementSummaries = endorsements.operationEndorsementsSummaries[operationName];
    if (!endorsementSummaries) {
      return [];
    }
    const sortedSummaries = [...endorsementSummaries].sort(endorsementSorting);
    return sortedSummaries;
  }
);

export const getOperationEndorsementSummaries = createSelector(
  getEndorsements,
  getRouteComponentPropsFromProps,
  (endorsements, operationName) => {
    const endorsementSummaries = endorsements.operationEndorsementsSummaries[operationName];
    if (!endorsementSummaries) {
      return [];
    }
    const sortedSummaries = [...endorsementSummaries].sort(endorsementSorting);
    return sortedSummaries;
  }
);

export const getEndorsementChartData = createSelector(
  getOperationEndorsementSummaries,
  getQuarterStringFromProps,
  (endorsementSummaries, props) => {
    if (!endorsementSummaries) {
      return { lbs: [], dollar: [] };
    }

    const year = props.quarter.slice(9, 13);
    const quarterNumber = parseQuarter(props.quarter.slice(0, 7));
    const startDate =
      props.quarter.length === 0
        ? addQuarters(new Date(), -1)
        : parse(`${year}, ${quarterNumber}`, 'yyyy, Q', new Date());

    const onlyEndorsements = endorsementSummaries?.filter(
      ({ endorsement }) => endorsement !== null
    );
    if (!onlyEndorsements) {
      return { lbs: [], dollar: [] };
    }

    const projectedProductionDataLbs: EndorsementChartData[] = [];
    const projectedProductionDataDollar: EndorsementChartData[] = [];

    numberRange(0, 8).forEach(quarterIncrement => {
      const date = addQuarters(startDate, quarterIncrement);
      const year = date.getFullYear();
      const quarter = getQuarter(date);
      const endorsement = onlyEndorsements?.find(e => e.year === year && e.quarter === quarter);
      const milkProductionDeclared = endorsement?.endorsement?.milkProductionDeclared ?? 100;

      projectedProductionDataLbs.push({
        name: `Q${quarter}-${year}`,
        quarter,
        indemnity: endorsement?.endorsement?.indemnity ?? 0,
        revenueChange: endorsement?.endorsement?.revenueChange ?? 0,
        premiumProducer: endorsement?.endorsement?.premiumProducer ?? 0,
        hedgeRatio: formatHedgeRatio(endorsement?.hedgeRatio?.hedgeRatioEffective)
      });
      projectedProductionDataDollar.push({
        name: `Q${quarter}-${year}`,
        quarter,
        indemnity: (endorsement?.endorsement?.indemnity ?? 0) / (milkProductionDeclared / 100),
        revenueChange:
          (endorsement?.endorsement?.revenueChange ?? 0) / (milkProductionDeclared / 100),
        premiumProducer:
          (endorsement?.endorsement?.premiumProducer ?? 0) / (milkProductionDeclared / 100),
        hedgeRatio: formatHedgeRatio(endorsement?.hedgeRatio?.hedgeRatioEffective)
      });
    });

    return {
      lbs: projectedProductionDataLbs,
      dollar: projectedProductionDataDollar
    };
  }
);

export const getFilteredOperationEndorsements = createSelector(
  getEndorsements,
  getRouteComponentPropsFromProps,
  (endorsements, operationName) => endorsements.filteredOperationEndorsements[operationName]
);

export const getActiveFilteredOperationEndorsements = createSelector(
  getFilteredOperationEndorsements,
  filteredEndorsements => {
    if (!filteredEndorsements) {
      return [];
    }
    return filteredEndorsements.filter(endorsement => endorsement.status === 'Purchased');
  }
);

export const getPendingOperationEndorsements = createSelector(
  getOperationEndorsements,
  operationEndorsements => {
    if (!operationEndorsements) {
      return [];
    }
    return (
      operationEndorsements.filter(endorsement => endorsement.status === 'PurchaseRequest') || []
    );
  }
);

export const getEndorsementPerformanceByQuarterData = createSelector(
  getOperationEndorsementSummaries,
  getQuarterStringFromProps,
  (endorsementSummaries, props) => {
    if (!endorsementSummaries || !props.quarter) {
      return [];
    }

    if (props.actualPriceDate) {
      const year = Number(props.actualPriceDate.slice(9, 13));
      const quarterNumber = parseQuarter(props.actualPriceDate.slice(0, 7));

      return endorsementSummaries.filter(
        e => quarterOfYear(e.endorsement?.actualPriceDate) === quarterNumber && e.year === year
      );
    }

    const year = Number(props.quarter.slice(9, 13));
    const quarterNumber = parseQuarter(props.quarter.slice(0, 7));

    return endorsementSummaries.filter(
      e => e.year > year || (e.quarter >= quarterNumber && e.year === year)
    );
  }
);

export const getEndorsementReportTableData = createSelector(
  getEndorsementPerformanceByQuarterData,
  getOperationEndorsements,
  (endorsementSummaries, operationEndorsements) => {
    if (!endorsementSummaries || !operationEndorsements) return {};
    const data = endorsementSummaries
      .map(summary => ({
        ...summary,
        operationEndorsements: operationEndorsements.filter(
          listItem =>
            listItem.year === summary.year &&
            listItem.quarter === summary.quarter &&
            listItem.status === 'Purchased'
        )
      }))
      .reduce<ReportTableData>((acc, cur) => {
        const year = cur.year;
        if (!acc[year]) {
          acc[year] = [];
        }
        acc[year].push(cur as EndorsementSummariesWithOperationEndorsements);
        return acc;
      }, {});

    return getHedgeRatioEquivalents(data);
  }
);

export const getEndorsementReportChartData = createSelector(
  getEndorsementReportTableData,
  endorsementSummaries => {
    return Object.keys(endorsementSummaries ?? {}).map(year => ({
      year: year,
      lbs: getProjectedProductionDataLbs(endorsementSummaries[+year]),
      dollar: getProjectedProductionDataDollar(endorsementSummaries[+year])
    }));
  }
);
