import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { FormattedNumberInput } from '@/shared/components/common/NumberInput';

import { QuarterListItem } from '@/ducks/dairyRevenueProtection/settings/reducer';

import { dairyRevenueProtectionActions } from '../../ducks/dairyRevenueProtection/actions';
import {
  getDeclaredMilkProduction,
  getQuarterList
} from '../../ducks/dairyRevenueProtection/selectors';
import color from '../../utils/color';
import { Unit } from './PremiumsContainer';

const Td = styled.td<{
  color?: string;
  align: 'right' | 'left' | 'center' | 'justify';
  maxWidth?: number;
}>`
  color: ${({ color }) => color ?? undefined};
  border-bottom: 1px solid ${color.NEUTRAL};
  vertical-align: middle;
  text-align: ${({ align }) => align};
  padding: 10px;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : undefined)};
`;

interface Props {
  unit: Unit;
  index: number;
  rowspan: number;
  type: string;
  measure: string;
  measureValues: (string | number | null | { value: number; practiceCode: number })[];
  onHoverTextChange: (quarterIndex: number) => void;
}

/** 
 * Sonar checks are disabled here due to rendering issues, 
 * bypassing React's optimizations that cause table display problems.
 */

const AllPremiumQuartersRow: React.FC<Props> = ({
  index,
  rowspan,
  type,
  measure,
  measureValues,
  unit,
  onHoverTextChange
}) => {
  const dispatch = useDispatch();

  const declaredCoveredMilk: number = useSelector(getDeclaredMilkProduction);
  const practiceCodes = useSelector(getQuarterList) as QuarterListItem[];

  const deviationFn =
    (type: 'class' | 'component') => (idx: number) => (children: React.ReactNode) =>
      practiceCodes[idx]?.deviation?.[type] ? 'N/A' : children;
  const deviationTypeFn = deviationFn(type.includes('Components') ? 'component' : 'class');

  const onExpectedBasisInputChange = (index: number, practiceCode: number) => (value: number) => {
    if (type.includes('Components')) {
      if (unit === Unit.CWT) {
        dispatch(
          dairyRevenueProtectionActions.allQuarters.setBasisCwtComponent(
            [value, declaredCoveredMilk],
            { index, practiceCode }
          )
        );
      } else {
        dispatch(
          dairyRevenueProtectionActions.allQuarters.setBasisComponent(
            [value, declaredCoveredMilk],
            { index, practiceCode }
          )
        );
      }
    } else {
      if (unit === Unit.CWT) {
        dispatch(
          dairyRevenueProtectionActions.allQuarters.setBasisCwtClass([value, declaredCoveredMilk], {
            index,
            practiceCode
          })
        );
      } else {
        dispatch(
          dairyRevenueProtectionActions.allQuarters.setBasisClass([value, declaredCoveredMilk], {
            index,
            practiceCode
          })
        );
      }
    }
  };

  const onHoverChange = (quarterIndex: number) => {
    onHoverTextChange(quarterIndex);
  };

  return (
    <tr>
      {index === 0 && (
        <Td align="center" maxWidth={120} rowSpan={rowspan}>
          {type}
        </Td>
      )}
      <Td align="left">{measure}</Td>
      {measureValues.map((value, dataIndex) => {
        const renderDeviationValue = deviationTypeFn(dataIndex);
        if (measure !== 'Expected Basis') {
          if (value === 'N/A') {
            return (
              <Td
                align="right"
                color={color.TEXT_PRIMARY}
                key={dataIndex} // NOSONAR
                data-tooltip-content={''}
                data-tooltip-id="unavailableSalesDate"
                onMouseOver={() => onHoverChange(dataIndex)}
              >
                {value}
              </Td>
            );
          } else {
            return (
              <Td align="right" color={color.PRIMARY} key={dataIndex}> {/** NOSONAR */}
                <>{renderDeviationValue(value as React.ReactNode)}</>
              </Td>
            );
          }
        } else {
          if (value === null) {
            return <Td align="right" color={color.PRIMARY} key={dataIndex} />; {/** NOSONAR */}
          } else {
            return (
              <Td align="right" key={dataIndex}> {/** NOSONAR */}
                {renderDeviationValue(
                  <FormattedNumberInput
                    // eslint-disable-next-line
                    // @ts-ignore
                    value={value.value as number}
                    width={100}
                    decimals={unit === Unit.DOLLAR ? 0 : 2}
                    // eslint-disable-next-line
                    // @ts-ignore
                    onChange={onExpectedBasisInputChange(index, value.practiceCode)}
                  />
                )}
              </Td>
            );
          }
        }
      })}
    </tr>
  );
};

export default AllPremiumQuartersRow;
