import React from 'react';
import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

type AlertVariant = 'error' | 'warning';
export type AlertVariantStyles = Record<AlertVariant, React.CSSProperties>;
export const alertVariants: AlertVariantStyles = {
  error: {
    backgroundColor: color.NEGATIVE,
    color: '#fff'
  },
  warning: {
    backgroundColor: color.SECONDARY,
    color: '#000'
  }
};

type AlertProps = React.HTMLAttributes<HTMLDivElement> & { variant: AlertVariant };
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(({ children, ...props }, ref) => (
  <StyledDiv ref={ref} role="alert" {...props}>
    {children}
  </StyledDiv>
));
const StyledDiv = styled.div<AlertProps>`
  ${({ theme, variant = 'error' }) => {
    const { backgroundColor, color } = theme.components.alert[variant];
    return css`
      box-sizing: border-box;
      width: 100%;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;

      background-color: ${({ theme }) =>
        `rgb(from ${theme.components.alert[variant].backgroundColor} r g b / 55%)`};
      border: 1px solid ${backgroundColor};
      color: ${color};
    `;
  }}
`;
Alert.displayName = 'Alert';

const AlertTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ children, ...props }, ref) => (
    <StyledH5 ref={ref} {...props}>
      {children}
    </StyledH5>
  )
);
const StyledH5 = styled.h5`
  padding: 0;
  margin: 0 0 0.5rem 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
`;
AlertTitle.displayName = 'AlertTitle';

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ children, ...props }, ref) => (
  <StyledParagraph ref={ref} {...props}>
    {children}
  </StyledParagraph>
));
const StyledParagraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
`;
AlertDescription.displayName = 'AlertDescription';

export { Alert, AlertTitle, AlertDescription };
