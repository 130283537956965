import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { AccountPreferencesDto } from '../../types/dto/AccountPreferencesDto';
import { loadersActions } from '../loaders/actions';
import { accountPreferences } from './actions';

export function* getAccountPreferences() {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    const accountPreferencesDtoAxiosResponse: AxiosResponse<AccountPreferencesDto> = yield call(
      insuranceAPI.getAccountPreferences
    );
    yield put(accountPreferences.addAccountPreferences(accountPreferencesDtoAxiosResponse.data));
    yield put(loadersActions.accountPreferencesLoader.disable());
  } catch (e: any) {
    yield put(loadersActions.accountPreferencesLoader.disable());
    if (e.response.status !== 404) {
      toast.error(e.message, {
        position: 'top-right'
      });
    }
    console.log(e);
  }
}

export function* updateAccountPreferences(
  action: ActionType<typeof accountPreferences.updateAccountPreferences>
) {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    yield call(insuranceAPI.updateAccountPreferences, action.payload);
    yield put(accountPreferences.saveUpdatedAccountPreferences(action.payload));
    yield put(loadersActions.accountPreferencesLoader.disable());
    yield toast.success('Changes Saved!', {
      position: 'top-right'
    });
  } catch (e: any) {
    yield put(loadersActions.accountPreferencesLoader.disable());
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  }
}

export function* updateAccountStarredPreferences(
  action: ActionType<typeof accountPreferences.setStarredOperations>
) {
  try {
    yield put(accountPreferences.saveUpdatedStarredOperations(action.payload));
    yield call(insuranceAPI.updateAccountStarredPreferences, action.payload);
  } catch (e: any) {
    toast.error(e.message, {
      position: 'top-right'
    });
    console.log(e);
  }
}

export function* updateAccountAndPassword(
  action: ActionType<typeof accountPreferences.updateAccountAndPassword>
) {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    const pref = {
      drpPriceReport: {
        frequency: action.payload.drpPriceReport.frequency,
        channel: action.payload.drpPriceReport.channel,
        operation: action.payload.drpPriceReport.operation
      },
      lrpPriceReport: {
        frequency: action.payload.lrpPriceReport.frequency,
        channel: action.payload.lrpPriceReport.channel,
        operation: action.payload.lrpPriceReport.operation
      },
      drpExpiredEndorsementsChannel: action.payload.drpExpiredEndorsementsChannel,
      lrpExpiredEndorsementsChannel: action.payload.lrpExpiredEndorsementsChannel,
      defaultOperation: action.payload.defaultOperation,
      timeZone: action.payload.timeZone,
      phoneNumber: action.payload.phoneNumber,
      alertChannel: action.payload.alertChannel,
      lrpAlertChannel: action.payload.lrpAlertChannel,
      smsOptedOut: action.payload.smsOptedOut
    };

    yield call(insuranceAPI.updateAccountPreferencesAndPassword, action.payload);
    yield put(accountPreferences.saveUpdatedAccountPreferences(pref));
    yield put(loadersActions.accountPreferencesLoader.disable());
    yield toast.success('Changes Saved!', {
      position: 'top-right'
    });
  } catch (e: any) {
    yield put(loadersActions.accountPreferencesLoader.disable());
    if (e.message === 'Request failed with status code 400') {
      toast.error('The password you entered does not match the current password', {
        position: 'top-right'
      });
    } else {
      toast.error(e.message, {
        position: 'top-right'
      });
    }
  }
}

export function* sendMailNow(
  action: ActionType<typeof accountPreferences.sendDrpPriceReportEmail>
) {
  try {
    yield put(loadersActions.accountPreferencesLoader.activate());
    yield call(insuranceAPI.sendNewsletterToUser, action.payload);
    yield put(loadersActions.accountPreferencesLoader.disable());
    toast.success(
      `${action.payload.type === 'DrpPriceReport' ? 'DRP' : 'LRP'} Price Report is sent!`
    );
  } catch (error: any) {
    yield put(loadersActions.accountPreferencesLoader.disable());
    if (error.response.data) {
      toast.error(error.response.data);
    } else {
      toast.error(
        'Something went wrong and we were unable to send the report to your email address. We have informed the site administrators.'
      );
    }
  }
}

export function* accountPreferencesSagaWatcher() {
  yield all([
    takeLatest(getType(accountPreferences.getAccountPreferences), getAccountPreferences),
    takeLatest(getType(accountPreferences.updateAccountPreferences), updateAccountPreferences),
    takeLatest(getType(accountPreferences.updateAccountAndPassword), updateAccountAndPassword),
    takeLatest(getType(accountPreferences.sendDrpPriceReportEmail), sendMailNow),
    takeLatest(getType(accountPreferences.setStarredOperations), updateAccountStarredPreferences)
  ]);
}
