import { isThursday } from 'date-fns';
import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { LGMSettingsActions, lgmSettingsActions } from './actions';

export enum FeedType {
  CUSTOM = 'Custom',
  DEFAULT = 'Default',
  LOWEST = 'Lowest',
  HIGHEST = 'Highest'
}

interface LgmSettingState {
  calendarDate: Date;
  deductible: number;
  feed: FeedType;
  actualPrices: boolean;
}

const initialState: LgmSettingState = {
  calendarDate: new Date(),
  deductible: 1,
  feed: FeedType.CUSTOM,
  actualPrices: isThursday(new Date())
};

const lgmSettingsReducer = (state = initialState, action: LGMSettingsActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(lgmSettingsActions.setCalendarDate):
        draft.calendarDate = action.payload;
        return;
      case getType(lgmSettingsActions.setDeductible):
        draft.deductible = action.payload;
        return;
      case getType(lgmSettingsActions.setFeedType):
        draft.feed = action.payload;
        return;
      case getType(lgmSettingsActions.setActualPrices):
        draft.actualPrices = action.payload;
        return;
    }
  });

export default lgmSettingsReducer;
