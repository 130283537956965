import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { AccountPreferences } from '../../../types/AccountPreferences/AccountPrefereces';
import { UserDto } from '../../../types/dto/UserDto';
import { AuthState } from '../../authentication/reducer';
import { accountPreferencesActions } from './actions';

type InitialState = {
  user: UserDto | null;
  userAuthState: AuthState | null;
  preferences: AccountPreferences;
};

const initialState: InitialState = {
  user: null,
  userAuthState: null,
  preferences: {
    drpPriceReport: {
      frequency: null,
      channel: [''],
      operation: '',
      frequencyCanModify: false,
      frequencyModifiedBy: '',
      frequencyModified: ''
    },
    lrpPriceReport: {
      frequency: null,
      channel: [''],
      operation: '',
      frequencyCanModify: false,
      frequencyModifiedBy: '',
      frequencyModified: ''
    },
    defaultOperation: '',
    timeZone: 'America/New_York',
    drpExpiredEndorsementsChannel: [''],
    lrpExpiredEndorsementsChannel: [''],
    phoneNumber: '',
    alertChannel: [''],
    lrpAlertChannel: [''],

    showStarredOperationsOnly: false,
    smsOptedOut: true
  }
};

export const userPreferencesReducer = (state = initialState, action: accountPreferencesActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(accountPreferencesActions.userPreferences.setUser): {
        draft.user = action.payload;
        draft.userAuthState = action.meta;
        return;
      }
      case getType(accountPreferencesActions.userPreferences.setUserPreferences): {
        draft.preferences = action.payload;
        return;
      }
    }
  });
