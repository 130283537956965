import React from 'react';
import { NavLinkProps, NavLink as RRNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';

import theme from '@/shared/theme';

import color from '@/utils/color';

type NavigationTabsVariants = 'primary' | 'secondary';

type NavigationTabsVariantStyles = {
  color: string;
  backgroundColor: string;
  lineHeight?: string;
  padding: string;
  borderBottom?: string;
  border?: string;
  borderRadius?: string;
  fontWeight: string;
  color_active: string;
  borderBottom_active?: string;
  border_active?: string;
  backgroundColor_active: string;
  color_hover?: string;
  borderBottom_hover?: string;
  fontWeight_active?: string;
};

const navigationTabsVariants: Record<NavigationTabsVariants, NavigationTabsVariantStyles> = {
  primary: {
    color: color.TEXT_PRIMARY,
    borderBottom: `${color.NEUTRAL} solid 2px`,
    backgroundColor: '#fff',
    padding: '5px 15px 10px 15px',
    fontWeight: '400',
    fontWeight_active: '600',
    color_active: color.PRIMARY,
    borderBottom_active: `${color.PRIMARY} solid 2px`,
    backgroundColor_active: '#fff',
    color_hover: color.PRIMARY,
    borderBottom_hover: `${color.PRIMARY} solid 2px`
  },
  secondary: {
    color: color.TEXT_PRIMARY,
    border: `${color.NEUTRAL} solid 1px`,
    backgroundColor: color.BACKGROUND_SECONDARY,
    lineHeight: '40px',
    padding: '0px 20px',
    fontWeight: '600',
    borderRadius: '3px',
    color_active: '#fff',
    border_active: `${color.PRIMARY} solid 1px`,
    backgroundColor_active: color.PRIMARY
  }
};

export type NavLinkExpandedProps = Array<
  NavLinkProps & { disabled?: boolean } & { active?: boolean }
>;

interface NavigationTabsProps {
  options: NavLinkExpandedProps;
  variant?: NavigationTabsVariants;
}

const NavigationTabs: React.FC<NavigationTabsProps> = ({ options, variant }) => {
  return (
    <Container>
      {(options ?? []).map(({ to, children, disabled, active, ...rest }) => (
        <StyledNavLink
          key={JSON.stringify(children)}
          to={to}
          variant={variant}
          data-cy={to}
          disabled={disabled}
          className={active ? 'active' : ''}
          {...rest}
        >
          {children}
        </StyledNavLink>
      ))}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

interface StyledNavLinkProps extends NavLinkProps {
  variant?: NavigationTabsVariants;
  disabled?: boolean;
}

const StyledNavLink = styled(({ disabled, ...props }: StyledNavLinkProps) => (
  <RRNavLink {...props} />
))<{ variant?: NavigationTabsVariants; disabled?: boolean }>`
  ${({ variant = 'primary', disabled = false }) => {
    const {
      color,
      borderBottom,
      backgroundColor,
      padding,
      fontWeight,
      lineHeight,
      border,
      borderRadius,
      color_active,
      borderBottom_active,
      border_active,
      backgroundColor_active,
      color_hover,
      borderBottom_hover,
      fontWeight_active
    } = theme.components.navigationTabs[variant];
    return css`
      width: 100%;
      text-align: center;
      text-decoration: none;
      color: ${disabled ? 'rgba(0, 0, 0, 0.3)' : color};
      border-bottom: ${borderBottom};
      border: ${border};
      border-radius: ${borderRadius};
      background-color: ${backgroundColor};
      padding: ${padding};
      line-height: ${lineHeight};
      font-weight: ${fontWeight};
      opacity: ${disabled ? 0.5 : 1};
      pointer-events: ${disabled ? 'none' : 'auto'};

      &.active {
        color: ${color_active};
        border-bottom: ${borderBottom_active};
        border: ${border_active};
        background-color: ${backgroundColor_active};
        cursor: pointer;
        font-weight: ${fontWeight_active};
      }

      &:hover {
        color: ${!disabled && color_hover};
        border-bottom: ${!disabled && borderBottom_hover};
      }
    `;
  }}
`;

export { NavigationTabs, navigationTabsVariants };
export type { NavigationTabsVariants, NavigationTabsVariantStyles };
