import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { AipDto, AipDtoEndorsement, AipStatus } from '../../../types/dto/AipOperationDto';
import { AdminActions, adminActions } from '../actions';

type InitialState = {
  aipOperations: AipDto[];
  aipStatus: AipStatus[];
  endorsements: AipDtoEndorsement[];
};

const initialState: InitialState = {
  aipOperations: [],
  aipStatus: [],
  endorsements: []
};

const aipReducer = (state = initialState, action: AdminActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(adminActions.aipSync.saveOperations): {
        draft.aipOperations = action.payload;
        return;
      }
      case getType(adminActions.aipSync.saveStatus): {
        draft.aipStatus = action.payload;
        return;
      }
      case getType(adminActions.aipSync.saveAipEndorsements): {
        draft.endorsements = action.payload;
        return;
      }
    }
  });

export default aipReducer;
