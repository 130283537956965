import { AxiosResponse } from 'axios';

import { Practice } from '../../types/Practice';
import { PracticeDto } from '../../types/dto/PracticeDto';

const mapPracticeDtoToPractice = ({ data }: AxiosResponse<PracticeDto>): Practice => ({
  salesEffectiveDate: data.salesEffectiveDate
    ? new Date(data.salesEffectiveDate.toString().slice(0, 19))
    : new Date(data.calendarDate.toString().slice(0, 19)),
  calendarDate: new Date(data.calendarDate.toString().slice(0, 19)),
  preferredClassPriceWeightingFactor: data.operationPreference?.preferredClassPriceWeightingFactor,
  preferredComponentPriceWeightingFactor:
    data.operationPreference?.preferredComponentPriceWeightingFactor,
  preferredCoverageLevel: data.operationPreference?.preferredCoverageLevel,
  preferredProtectionLevel: data.operationPreference?.preferredProtectionLevel,
  showBasis: data.operationPreference?.showBasis,
  showIndemnity: data.operationPreference?.showIndemnity,
  showSubsidy: data.operationPreference?.showSubsidy,
  showAllQuarters: data.operationPreference?.showAllQuarters,
  showProtectionFactorAnalysis: data.operationPreference?.showProtectionFactorAnalysis,
  reinsuranceYear: data.reinsuranceYear,
  stateCode: data.operationPreference?.stateCode ? data.operationPreference.stateCode : '01',
  share: data.operationPreference?.preferredInsuredShare,
  practices: data.practices.map(
    ({
      code,
      name,
      butterfatTest,
      proteinTest,
      componentPriceWeightingFactorRestrictedValue,
      classPriceWeightingFactorRestrictedValue
    }) => ({
      quarter: name,
      practiceCode: Number(code),
      componentPriceWeightingFactorRestrictedValue,
      classPriceWeightingFactorRestrictedValue,
      butterfatTest,
      proteinTest
    })
  ),
  canSell: data.canSell,
  canSellNote: data.canSellReason || ''
});

export default mapPracticeDtoToPractice;
