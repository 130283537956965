import { ActionType, createAction } from 'typesafe-actions';

import { Scenario } from '../../types/Scenario';

export const scenarioActions = {
  getAll: createAction('@scenario/GET_ALL')<string>(),
  addAllNames: createAction('@scenario/ADD_ALL_NAMES')<string[], string>(),
  save: createAction('@scenario/SAVE')<string, string>(),
  add: createAction('@scenario/ADD')<Scenario, { name: string; operation: string }>(),
  load: createAction('@scenario/LOAD')<{ scenarioName: string; operation: string }, () => void>(),
  delete: createAction('@scenario/DELETE')<string, string>(),
  remove: createAction('@scenario/REMOVE')<string, string>()
};

export type ScenarioActions = ActionType<typeof scenarioActions>;
