import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Tag } from '../../scenes/common/Tags/types/Tag';
import { TagActions, tagActions } from './actions';

type TagState = {
  tags: Tag[];
  isLoading: boolean;
};

const initialState: TagState = {
  tags: [],
  isLoading: false
};

const tagReducer = (state = initialState, action: TagActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(tagActions.tags.set): {
        draft.tags = action.payload;
        break;
      }
      case getType(tagActions.tags.delete): {
        const payload = action.payload;
        draft.tags = draft.tags.filter(existingTag => existingTag.name !== payload.tagName);
        break;
      }
      case getType(tagActions.tagLoader.activate): {
        draft.isLoading = true;
        break;
      }
      case getType(tagActions.tagLoader.disable): {
        draft.isLoading = false;
        break;
      }
    }
  });

export default tagReducer;
