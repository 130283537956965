import { AxiosResponse } from 'axios';
import { isAfter, isBefore } from 'date-fns';
import { toast } from 'react-toastify';
import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import { reviveDate } from '@/utils/dateFormatter';

import insuranceAPI from '../../services/insuranceService';
import { CalculatedValuesEndorsementBinding } from '../../types/Endorsement/CalculatedValuesEndorsementBinding';
import { Practice } from '../../types/Practice';
import {
  CoverageRequestDto,
  CoverageRequestHistoryDto as CoverageRequestHistoryModel
} from '../../types/dto/CoverageRequest';
import { DrpEndorsementDto, EndorsementDrp, EndorsementLrp } from '../../types/dto/EndorsementDto';
import { EndorsementSummaryDto } from '../../types/dto/EndorsementSummaryDto';
import { InsuranceDto } from '../../types/dto/InsuranceDto';
import InsurancePlan from '../../types/dto/InsurancePlan';
import { PracticeDto } from '../../types/dto/PracticeDto';
import { errorResponseStatusHandler } from '../../utils/responseStatusHandler';
import { utcDateToLocalDate } from '../../utils/utcDateToLocalDate';
import mapInsuranceDto from '../dairyRevenueProtection/mapInsuranceDto';
import mapPracticeDtoToPractice from '../dairyRevenueProtection/mapPracticeDtoToPractice';
import {
  ClassPriceWeightingFactorRestrictedValue,
  ComponentPriceWeightingFactorRestrictedValue
} from '../dairyRevenueProtection/settings/reducer';
import { loadersActions } from '../loaders/actions';
import { checkForDefinitions } from '../lrp/sagas';
import { getOperationName } from '../operationPage/selectors';
import { endorsementActions } from './actions';
import {
  getModalCalendarDate,
  getModalCoverageLevel,
  getModalDeclaredButterfatTest,
  getModalDeclaredClassPriceWeightingFactor,
  getModalDeclaredComponentPriceWeightingFactor,
  getModalDeclaredMilkProduction,
  getModalDeclaredProteinTest,
  getModalProtectionFactor,
  getModalQuarter,
  getModalQuarterList,
  getModalReinsuranceYear,
  getModalSalesEffectiveDate,
  getModalShare,
  getModalState
} from './selectors';
import { mapEndorsementSummaryDtoToEndorsementSummary } from './utils/mapEndorsementSummaryDtoToEndorsementSummary';

export function* getEndorsementsSummary(
  action: ActionType<typeof endorsementActions.getEndorsementSummary>
) {
  const tagNames = action.payload ?? [];
  const operation: string = yield select(getOperationName);
  yield put(loadersActions.endorsementSummary.activate());
  try {
    const data: AxiosResponse<EndorsementSummaryDto[]> = yield call(
      insuranceAPI.getEndorsementSummary,
      operation,
      tagNames
    );
    const mappedData = mapEndorsementSummaryDtoToEndorsementSummary(data);
    yield put(endorsementActions.addAllEndorsementSummaries(operation, mappedData));
  } catch (error) {
    yield put(endorsementActions.addAllEndorsementSummaries(operation, []));
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.endorsementSummary.disable());
  }
}

export function* getCoverageRequest(
  action: ActionType<typeof endorsementActions.getCoverageRequest>
) {
  if (action.meta) yield action.meta(true);
  yield put(loadersActions.endorsementSummary.activate());

  const { operationName: operation, refId: referenceIdentifier } = action.payload;
  try {
    const response: AxiosResponse<CoverageRequestDto> = yield call(
      insuranceAPI.getCoverageRequest,
      operation,
      referenceIdentifier
    );
    response.data.bindingRequest.salesEffectiveDate = utcDateToLocalDate(
      response.data.bindingRequest.salesEffectiveDate
    );
    response.data.bindingRequest.created = utcDateToLocalDate(response.data.bindingRequest.created);
    response.data.bindingRequest.updated = utcDateToLocalDate(response.data.bindingRequest.updated);
    yield put(endorsementActions.setCoverageRequest(response.data));
  } catch (error) {
    errorResponseStatusHandler(error);
  } finally {
    if (action.meta) yield action.meta(false);
    yield put(loadersActions.endorsementSummary.disable());
  }
}

export function* getCoverageRequests(
  action?: ActionType<typeof endorsementActions.getCoverageRequests> | undefined
) {
  if (action?.meta) yield action.meta(true);
  yield put(loadersActions.endorsementSummary.activate());

  const operationName = action?.payload.operationName;
  const insurancePlan = action?.payload.insurancePlan;

  try {
    const response: AxiosResponse<CoverageRequestDto[]> = yield call(
      insuranceAPI.getCoverageRequests,
      operationName,
      insurancePlan
    );
    response.data.forEach(x => {
      if (x.bindingRequest.statusLog.includes('SignSubmitted')) {
        x.docuSignSent = true;
      }
      x.bindingRequest.salesEffectiveDate = utcDateToLocalDate(x.bindingRequest.salesEffectiveDate);
      x.bindingRequest.created = utcDateToLocalDate(x.bindingRequest.created);
      x.bindingRequest.updated = utcDateToLocalDate(x.bindingRequest.updated);
    });

    yield put(endorsementActions.setCoverageRequests(response.data));
  } catch (error) {
    yield put(endorsementActions.setCoverageRequests([]));
    errorResponseStatusHandler(error);
  } finally {
    yield put(endorsementActions.setCoverageRequestFetchTimeStamp());
    yield put(loadersActions.endorsementSummary.disable());
    if (action?.meta) yield action.meta(false);
  }
}
export function* getCoverageRequestHistory(
  action: ActionType<typeof endorsementActions.getCoverageRequestHistory>
) {
  const { referenceIdentifier, operationName } = action.payload;

  yield put(loadersActions.spinnerCoverageRequestHistory.activate());
  try {
    const response: AxiosResponse<CoverageRequestHistoryModel[]> = yield call(
      insuranceAPI.getCoverageRequestHistory,
      operationName,
      referenceIdentifier
    );
    yield put(endorsementActions.saveCoverageRequestHistory(response.data));
  } catch (error) {
    yield put(endorsementActions.saveCoverageRequestHistory([]));
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.spinnerCoverageRequestHistory.disable());
  }
}

export function* getEndorsementsByCoverageRequest(
  action: ActionType<typeof endorsementActions.getEndorsementsByCoverageRequest>
) {
  if (action.meta) yield action.meta(true);
  const { operationName, refId, insurancePlan } = action.payload;

  try {
    yield put(loadersActions.endorsementSummary.activate());
    const response: AxiosResponse<EndorsementDrp[] | EndorsementLrp[]> = yield call(
      insuranceAPI.getEndorsementsByCoverageRequest,
      operationName,
      refId,
      InsurancePlan[insurancePlan]
    );

    response.data.forEach((endorsement: EndorsementDrp | EndorsementLrp) => {
      endorsement.salesEffectiveDate = utcDateToLocalDate(endorsement.salesEffectiveDate);
      if ('endDate' in endorsement) {
        endorsement.createdOn = new Date(endorsement.createdOn);
        endorsement.endDate = utcDateToLocalDate(endorsement.endDate);
      }
    });
    yield put(endorsementActions.setCoverageRequestEndorsements(response.data));
    if (insurancePlan === InsurancePlan.drp) {
      const stateCode = response.data[0].stateCode;
      const practiceDto: AxiosResponse<PracticeDto> = yield call(
        insuranceAPI.getInsurancePracticeSales,
        operationName,
        stateCode,
        response.data[0].salesEffectiveDate
      );
      const practice = mapPracticeDtoToPractice(practiceDto);
      yield put(
        endorsementActions.saveQuarterListCoverageRequestByRefIdentifier(practice.practices)
      );
    }
  } catch (error) {
    yield put(endorsementActions.setCoverageRequestEndorsements([]));
    console.error(error);
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.endorsementSummary.disable());
    if (action.meta) yield action.meta(false);
  }
}

export function* getEndorsements() {
  try {
    const operation: string = yield select(getOperationName);
    const data: AxiosResponse<DrpEndorsementDto[]> = yield call(
      insuranceAPI.getEndorsements,
      operation
    );
    yield put(endorsementActions.addAllEndorsements(operation, data.data));
  } catch (error: unknown) {
    errorResponseStatusHandler(error);
  }
}

export function* getFilteredEndorsements(
  action: ActionType<typeof endorsementActions.getFilteredEndorsements>
) {
  const operation: string = yield select(getOperationName);
  yield put(loadersActions.endorsementDetailedView.activate());
  try {
    const { quarter, reinsuranceYear, tagNames } = action.payload;
    const data: AxiosResponse<DrpEndorsementDto[]> = yield call(
      insuranceAPI.getEndorsementsWithFilter,
      operation,
      reinsuranceYear,
      quarter,
      tagNames
    );

    yield put(endorsementActions.addFilteredEndorsements(operation, reviveDate(data.data)));
  } catch (error: unknown) {
    yield put(endorsementActions.addFilteredEndorsements(operation, []));
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.endorsementDetailedView.disable());
  }
}

export function* createEndorsement(
  action: ActionType<typeof endorsementActions.createEndorsement>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const { endorsement, isAgentAdd, quarter, reinsuranceYear } = action.payload;
    const operation: string = yield select(getOperationName);
    yield call(insuranceAPI.createDrpEndorsement, operation, endorsement);
    if (isAgentAdd && reinsuranceYear && quarter) {
      yield put(
        endorsementActions.getFilteredEndorsements({ reinsuranceYear, quarter, tagNames: [] })
      );
    }
    yield put(endorsementActions.getEndorsementSummary());
    yield put(endorsementActions.getEndorsements());
    yield action.meta();
    if (isAgentAdd) {
      toast.success('Endorsement Added');
    } else {
      toast.success(
        'We have sent an email request for insurance coverage. Please note that your purchase will not be complete' +
          ' until you sign a DRP Coverage Endorsement. Thank you!'
      );
    }
  } catch (e) {
    errorResponseStatusHandler(e, {
      status: 409,
      message: 'An endorsement with the same Quarter, Sales Effective Date and Type already exists.'
    });
    yield action.meta();
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* createEndorsementBinding(
  action: ActionType<typeof endorsementActions.createEndorsementBinding>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const { endorsement } = action.payload;
    const operation: string = yield select(getOperationName);

    yield call(insuranceAPI.createEndorsementBinding, operation, endorsement);
    yield action.meta?.onAddCallback();
    toast.success('Request coverage successful');
  } catch (e: any) {
    if (checkForDefinitions(e)) {
      yield action.meta.handleResponseCode?.(e.response.data.Definitions);
    } else {
      errorResponseStatusHandler(
        e.code === 'ERR_NETWORK' ? { ...e, status: 500, data: { status: 500 } } : e
      );
    }
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* updateOperationBindingRequestStatus(
  action: ActionType<typeof endorsementActions.updateOperationBindingRequestStatus>
) {
  yield action.meta(true);
  const { operationName, referenceIdentifier, status, insurancePlan } = action.payload;
  try {
    yield put(loadersActions.spinner.activate());
    yield call(
      insuranceAPI.updateOperationBindingRequestStatus,
      operationName,
      referenceIdentifier,
      status,
      insurancePlan
    );
    toast.success('Status Updated');
  } catch (error) {
    errorResponseStatusHandler('Failed to send to DocuSign');
  } finally {
    yield put(loadersActions.spinner.disable());
    yield action.meta(false);
  }
}

export function* editEndorsement(action: ActionType<typeof endorsementActions.editEndorsement>) {
  const operation: string = yield select(getOperationName);
  const { data, id, reinsuranceYear, quarter } = action.payload;
  yield put(loadersActions.spinner.activate());
  try {
    yield call(insuranceAPI.updateEndorsement, operation, id, {
      ...data,
      tags: data.tags?.length ? data.tags : null
    });
    action.meta();
    yield put(
      endorsementActions.getFilteredEndorsements({ reinsuranceYear, quarter, tagNames: [] })
    );
    yield put(endorsementActions.getEndorsementSummary());
    yield put(endorsementActions.getEndorsements());
    toast.success('Endorsement Edited');
  } catch (error) {
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* editPendingEndorsement(
  action: ActionType<typeof endorsementActions.editPendingEndorsement>
) {
  const { data, id } = action.payload;
  const operation: string = yield select(getOperationName);
  yield put(loadersActions.spinner.activate());
  try {
    yield call(insuranceAPI.updateEndorsement, operation, id, {
      ...data,
      tags: data.tags?.length ? data.tags : null
    });
    yield put(loadersActions.spinner.disable());
    yield put(loadersActions.pendingEndorsementTable.activate());
    yield put(endorsementActions.getEndorsements());
    yield put(loadersActions.pendingEndorsementTable.disable());
    yield put(endorsementActions.getEndorsementSummary());
    yield action.meta();
    toast.success('Endorsement Approved');
  } catch (error) {
    errorResponseStatusHandler(error);
    yield action.meta();
  } finally {
    yield put(loadersActions.spinner.disable());
    yield put(loadersActions.pendingEndorsementTable.disable());
  }
}

export function* deleteEndorsement(
  action: ActionType<typeof endorsementActions.deleteEndorsement>
) {
  yield put(loadersActions.pendingEndorsementTable.activate());
  const id = action.payload;
  const operation: string = yield select(getOperationName);
  try {
    yield call(insuranceAPI.deleteEndorsement, operation, id);
    yield put(endorsementActions.removeEndorsement({ operation, id }));
    yield put(endorsementActions.getEndorsementSummary());
    yield put(endorsementActions.getEndorsements());
    toast.success('Endorsement Deleted');
  } catch (error) {
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.pendingEndorsementTable.disable());
  }
}

export function* recalculateEndorsements(
  action: ActionType<typeof endorsementActions.recalculateEndorsements>
) {
  yield put(loadersActions.endorsementDetailedView.activate());
  yield put(loadersActions.endorsementSummary.activate());
  try {
    const { reinsuranceYear, quarter } = action.payload;
    const operation: string = yield select(getOperationName);
    yield call(insuranceAPI.recalculateEndorsements, operation);
    yield put(endorsementActions.getEndorsementSummary());
    yield put(
      endorsementActions.getFilteredEndorsements({ reinsuranceYear, quarter, tagNames: [] })
    );
    toast.success('Recalculated Endorsements');
  } catch (error) {
    yield put(loadersActions.endorsementDetailedView.disable());
    yield put(loadersActions.endorsementSummary.disable());
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.endorsementDetailedView.disable());
    yield put(loadersActions.endorsementSummary.disable());
  }
}

function* fetchModalData() {
  try {
    yield put(loadersActions.spinner.activate());
    const operationName: string = yield select(getOperationName);

    const stateCode: number = yield select(getModalState);
    const practiceCode: number = yield select(getModalQuarter);

    const coveredMilkProduction: number = yield select(getModalDeclaredMilkProduction);
    const salesEffectiveDate: Date = yield select(getModalSalesEffectiveDate);
    let coverageLevel: number = yield select(getModalCoverageLevel);
    let butterfatTest: number = yield select(getModalDeclaredButterfatTest);
    const protectionFactor: number = yield select(getModalProtectionFactor);
    let proteinTest: number = yield select(getModalDeclaredProteinTest);
    const clsPriceWeightingFactor: number = yield select(getModalDeclaredClassPriceWeightingFactor);
    const cmpPriceWeightingFactor: number = yield select(
      getModalDeclaredComponentPriceWeightingFactor
    );
    const reinsuranceYear: number = yield select(getModalReinsuranceYear);
    const share: number = yield select(getModalShare);

    const calendarDate: Date = yield select(getModalCalendarDate);
    if (isBefore(calendarDate, new Date('July 1, 2019'))) {
      if (butterfatTest < 3.5) {
        yield put(endorsementActions.modal.setModalDeclaredButterfatTest(3.5));
        butterfatTest = 3.5;
      }
      if (proteinTest < 3) {
        yield put(endorsementActions.modal.setModalDeclaredProteinTest(3));
        proteinTest = 3;
      }
    }

    if (isBefore(calendarDate, new Date('July 1, 2020'))) {
      if (butterfatTest > 5) {
        yield put(endorsementActions.modal.setModalDeclaredButterfatTest(5));
        butterfatTest = 5;
      }
      if (proteinTest > 4) {
        yield put(endorsementActions.modal.setModalDeclaredProteinTest(4));
        proteinTest = 4;
      }
    }

    if (isAfter(calendarDate, new Date('July 1, 2019'))) {
      if (coverageLevel < 0.8) {
        yield put(endorsementActions.modal.setModalCoverageLevel(0.8));
        coverageLevel = 0.8;
      }
    }

    const practices: Array<{
      practiceCode: number;
      componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
      classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
    }> = yield select(getModalQuarterList);
    const practiceIndex = practices.findIndex(practice => practice.practiceCode === practiceCode);
    const componentPriceWeightingFactor =
      practices[practiceIndex]?.componentPriceWeightingFactorRestrictedValue ??
      cmpPriceWeightingFactor;
    const classPriceWeightingFactor =
      practices[practiceIndex]?.classPriceWeightingFactorRestrictedValue ?? clsPriceWeightingFactor;

    if (practiceCode !== 0) {
      const response: AxiosResponse<InsuranceDto> = yield call(
        insuranceAPI.getInsurance,
        {
          operationName,
          share,
          stateCode,
          practiceCode,
          salesEffectiveDate,
          butterfatTest,
          proteinTest,
          classPriceWeightingFactor,
          coverageLevel,
          coveredMilkProduction,
          protectionFactor,
          componentPriceWeightingFactor
        },
        reinsuranceYear
      );
      const mappedResponse = mapInsuranceDto(response);

      yield put(
        endorsementActions.modal.setModalData({
          expectedRevenue: mappedResponse.priceComponents.expectedRevenue,
          revenueGuarantee: mappedResponse.priceComponents.revenueGuarantee,
          producerPremium: mappedResponse.priceComponents.producerPremiumAmount,
          expectedRevenueCwt: mappedResponse.whatIfDairyComponents.expectedDRPRevenueCwt,
          revenueGuaranteeCwt: mappedResponse.priceComponents.revenueGuaranteeCwt,
          producerPremiumCwt: mappedResponse.priceComponents.producerPremiumAmountCwt
        })
      );
    }

    yield put(endorsementActions.modal.setIsSalesDateAvailable(true));
    yield put(loadersActions.spinner.disable());
  } catch (error: any) {
    if (error.response.status === 404 || error.response.status === 500) {
      yield put(endorsementActions.modal.setIsSalesDateAvailable(false));
    }
    errorResponseStatusHandler(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* calculateSingleCoverageEndorsementBinding(
  action: ActionType<typeof endorsementActions.calculateSingleCoverageEndorsementBinding>
) {
  const parameters = action.payload.parameters;
  yield put(loadersActions.spinner.activate());
  try {
    const operationName: string = yield select(getOperationName);
    const salesEffectiveDate: Date = yield select(getModalSalesEffectiveDate);
    const reinsuranceYear: number = yield select(getModalReinsuranceYear);

    const practices: Array<{
      practiceCode: number;
      componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
      classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
    }> = yield select(getModalQuarterList);
    const practiceIndex = practices.findIndex(
      practice => practice.practiceCode === parameters.practiceCode
    );
    const componentPriceWeightingFactor =
      practices[practiceIndex]?.componentPriceWeightingFactorRestrictedValue ??
      parameters.componentPriceWeightingFactor;
    const classPriceWeightingFactor =
      practices[practiceIndex]?.classPriceWeightingFactorRestrictedValue ??
      parameters.classPriceWeightingFactor;

    if (parameters.practiceCode !== 0) {
      const response: AxiosResponse<InsuranceDto> = yield call(
        insuranceAPI.getInsurance,
        {
          operationName,
          share: parameters.share,
          stateCode: parameters.stateCode,
          practiceCode: parameters.practiceCode,
          salesEffectiveDate,
          butterfatTest: parameters.butterfatTest,
          proteinTest: parameters.proteinTest,
          classPriceWeightingFactor,
          coverageLevel: parameters.coverageLevel,
          coveredMilkProduction: parameters.coveredMilkProduction,
          protectionFactor: parameters.protectionFactor,
          componentPriceWeightingFactor
        },
        reinsuranceYear
      );

      const responseProcessed: CalculatedValuesEndorsementBinding = {
        component: {
          expectedRevenue: response.data.component.expectedRevenue,
          expectedRevenueCwt: response.data.component.expectedRevenueCwt,
          revenueGuarantee: response.data.component.revenueGuarantee,
          revenueGuaranteeCwt: response.data.component.revenueGuaranteeCwt,
          producerPremium: response.data.component.producerPremium,
          producerPremiumCwt: response.data.component.producerPremiumCwt
        },
        class: {
          expectedRevenue: response.data.class.expectedRevenue,
          expectedRevenueCwt: response.data.class.expectedRevenueCwt,
          revenueGuarantee: response.data.class.revenueGuarantee,
          revenueGuaranteeCwt: response.data.class.revenueGuaranteeCwt,
          producerPremium: response.data.class.producerPremium,
          producerPremiumCwt: response.data.class.producerPremiumCwt
        }
      };
      action.payload.callback(responseProcessed);
    }
  } catch (e) {
    errorResponseStatusHandler(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* calculateSingleCoverageEndorsementBindingReviewCoverage(
  action: ActionType<
    typeof endorsementActions.calculateSingleCoverageEndorsementBindingReviewCoverage
  >
) {
  const parameters = action.payload.parameters;
  const operationName = action.payload.operationName;
  yield put(loadersActions.spinner.activate());
  try {
    const practices: Array<{
      practiceCode: number;
      componentPriceWeightingFactorRestrictedValue: ComponentPriceWeightingFactorRestrictedValue;
      classPriceWeightingFactorRestrictedValue: ClassPriceWeightingFactorRestrictedValue;
    }> = yield select(getModalQuarterList);
    const practiceIndex = practices.findIndex(
      practice => practice.practiceCode === parameters.practiceCode
    );
    const componentPriceWeightingFactor =
      practices[practiceIndex]?.componentPriceWeightingFactorRestrictedValue ??
      parameters.componentPriceWeightingFactor;
    const classPriceWeightingFactor =
      practices[practiceIndex]?.classPriceWeightingFactorRestrictedValue ??
      parameters.classPriceWeightingFactor;

    const reinsuranceYear = parameters.reinsuranceYear;

    if (parameters.practiceCode !== 0) {
      const response: AxiosResponse<InsuranceDto> = yield call(
        insuranceAPI.getInsurance,
        {
          operationName,
          share: parameters.share,
          stateCode: parameters.stateCode,
          practiceCode: parameters.practiceCode,
          salesEffectiveDate: parameters.salesEffectiveDate,
          butterfatTest: parameters.butterfatTest,
          proteinTest: parameters.proteinTest,
          classPriceWeightingFactor,
          coverageLevel: parameters.coverageLevel,
          coveredMilkProduction: parameters.coveredMilkProduction,
          protectionFactor: parameters.protectionFactor,
          componentPriceWeightingFactor
        },
        reinsuranceYear
      );

      const responseProcessed: CalculatedValuesEndorsementBinding = {
        component: {
          expectedRevenue: response.data.component.expectedRevenue,
          expectedRevenueCwt: response.data.component.expectedRevenueCwt,
          revenueGuarantee: response.data.component.revenueGuarantee,
          revenueGuaranteeCwt: response.data.component.revenueGuaranteeCwt,
          producerPremium: response.data.component.producerPremium,
          producerPremiumCwt: response.data.component.producerPremiumCwt
        },
        class: {
          expectedRevenue: response.data.class.expectedRevenue,
          expectedRevenueCwt: response.data.class.expectedRevenueCwt,
          revenueGuarantee: response.data.class.revenueGuarantee,
          revenueGuaranteeCwt: response.data.class.revenueGuaranteeCwt,
          producerPremium: response.data.class.producerPremium,
          producerPremiumCwt: response.data.class.producerPremiumCwt
        }
      };
      action.payload.callback(responseProcessed);
    }
  } catch (e) {
    errorResponseStatusHandler(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* changeStatusRequestCoverage(
  action: ActionType<typeof endorsementActions.changeStatusRequestCoverage>
) {
  if (action.meta) yield action.meta(true);
  const { operationName, insurancePlan, refIdentifier, status } = action.payload;

  yield put(loadersActions.spinner.activate());
  yield put(loadersActions.spinnerCoverageRequestFromMail.activate());
  try {
    const data = { status: status };
    yield call(
      insuranceAPI.updateCoverageRequest,
      operationName,
      refIdentifier,
      data,
      insurancePlan
    );
    toast.success('Request coverage successfully discarded');
  } catch (e) {
    errorResponseStatusHandler(e);
  } finally {
    yield put(loadersActions.spinner.disable());
    yield put(loadersActions.spinnerCoverageRequestFromMail.disable());
    if (action.meta) yield action.meta(false);
  }
}

function* updateRequestCoverage(
  action: ActionType<typeof endorsementActions.updateRequestCoverage>
) {
  yield action.meta.setModifyingCoverageRequest?.(true);
  const { operationName, insurancePlan, refIdentifier, data } = action.payload;

  yield put(loadersActions.spinner.activate());
  yield put(loadersActions.spinnerCoverageRequestFromMail.activate());
  try {
    yield call(
      insuranceAPI.updateCoverageRequest,
      operationName,
      refIdentifier,
      data,
      insurancePlan
    );
    toast.success('Request coverage successfully submitted');
    yield action.meta.setModifyingCoverageRequest?.(false);
  } catch (e: any) {
    if (checkForDefinitions(e)) {
      yield action.meta.handleResponseCode?.(e.response.data.Definitions);
    } else {
      errorResponseStatusHandler(
        e.code === 'ERR_NETWORK' ? { ...e, status: 500, data: { status: 500 } } : e
      );
    }
    yield action.meta.setModifyingCoverageRequest?.(null);
  }
}

function* initEndorsementModal(action: ActionType<typeof endorsementActions.endorsementModalInit>) {
  yield put(loadersActions.spinner.activate());
  try {
    const { operationName, stateCode } = action.payload;
    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePractice,
      operationName,
      stateCode
    );
    const practice: Practice = mapPracticeDtoToPractice(practiceDto);
    yield put(endorsementActions.modal.initModalPracticeData(practice));
    yield call(fetchModalData);
  } catch (e) {
    errorResponseStatusHandler(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

function* updatePracticeAndModalData(
  action: ActionType<typeof endorsementActions.modal.setModalCalendarDate>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const operationName: string = yield select(getOperationName);
    const stateCode: number = yield select(getModalState);
    const salesDate: Date = yield select(getModalCalendarDate);

    const paddedStateCode = String(stateCode).padStart(2, '0');

    const butterfatTest: number = yield select(getModalDeclaredButterfatTest);
    const proteinTest: number = yield select(getModalDeclaredProteinTest);
    const coverageLevel: number = yield select(getModalCoverageLevel);

    const isBeforeJuly2019 = isBefore(action.payload, new Date('July 1, 2019'));
    const isBeforeJuly2020 = isBefore(action.payload, new Date('July 1, 2020'));
    const isAfterJuly2019 = isAfter(action.payload, new Date('July 1, 2019'));

    if (isBeforeJuly2019 && butterfatTest < 3.5) {
      yield put(endorsementActions.modal.setModalDeclaredButterfatTest(3.5));
    }

    if (isBeforeJuly2019 && proteinTest < 3) {
      yield put(endorsementActions.modal.setModalDeclaredProteinTest(3));
    }

    if (isBeforeJuly2020 && butterfatTest > 5) {
      yield put(endorsementActions.modal.setModalDeclaredButterfatTest(5));
    }

    if (isBeforeJuly2020 && proteinTest > 4) {
      yield put(endorsementActions.modal.setModalDeclaredProteinTest(4));
    }

    if (isAfterJuly2019 && coverageLevel < 0.8) {
      yield put(endorsementActions.modal.setModalCoverageLevel(0.8));
    }

    const practiceDto: AxiosResponse<PracticeDto> = yield call(
      insuranceAPI.getInsurancePracticeSales,
      operationName,
      paddedStateCode,
      salesDate
    );
    const practice: Practice = mapPracticeDtoToPractice(practiceDto);

    yield put(
      endorsementActions.modal.setModalPracticeData({
        salesEffectiveDate: practice.salesEffectiveDate,
        canSell: practice.canSell,
        canSellNote: practice.canSellNote,
        quarterList: practice.practices,
        isSalesDateAvailable: practice.salesEffectiveDate !== null,
        reinsuranceYear: practice.reinsuranceYear
      })
    );

    if (practice.practices.length) {
      const code: number = yield select(getModalQuarter);
      if (practice.practices.findIndex(({ practiceCode }) => practiceCode === code) === -1) {
        yield put(endorsementActions.modal.setModalQuarter(practice.practices[0].practiceCode));
      }
    }

    if (!practice.salesEffectiveDate) {
      yield put(endorsementActions.modal.setIsSalesDateAvailable(false));
    }
  } catch (e) {
    yield put(endorsementActions.modal.setIsSalesDateAvailable(false));
    errorResponseStatusHandler(e);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* endorsementSagaWatcher() {
  yield all([
    takeLatest(getType(endorsementActions.getEndorsements), getEndorsements),
    takeLatest(getType(endorsementActions.getCoverageRequest), getCoverageRequest),
    takeLatest(getType(endorsementActions.getCoverageRequests), getCoverageRequests),
    takeLatest(getType(endorsementActions.getCoverageRequestHistory), getCoverageRequestHistory),
    takeLatest(
      getType(endorsementActions.getEndorsementsByCoverageRequest),
      getEndorsementsByCoverageRequest
    ),
    takeLatest(getType(endorsementActions.getFilteredEndorsements), getFilteredEndorsements),
    takeLatest(getType(endorsementActions.getEndorsementSummary), getEndorsementsSummary),
    takeLatest(getType(endorsementActions.createEndorsement), createEndorsement),
    takeLatest(getType(endorsementActions.createEndorsementBinding), createEndorsementBinding),
    takeLatest(
      getType(endorsementActions.updateOperationBindingRequestStatus),
      updateOperationBindingRequestStatus
    ),
    takeLatest(getType(endorsementActions.editEndorsement), editEndorsement),
    takeLatest(getType(endorsementActions.editPendingEndorsement), editPendingEndorsement),
    takeLatest(getType(endorsementActions.deleteEndorsement), deleteEndorsement),
    takeLatest(getType(endorsementActions.recalculateEndorsements), recalculateEndorsements),
    takeLatest(getType(endorsementActions.endorsementModalInit), initEndorsementModal),
    takeLatest(
      getType(endorsementActions.calculateSingleCoverageEndorsementBinding),
      calculateSingleCoverageEndorsementBinding
    ),
    takeLatest(
      getType(endorsementActions.calculateSingleCoverageEndorsementBindingReviewCoverage),
      calculateSingleCoverageEndorsementBindingReviewCoverage
    ),
    takeLatest(
      getType(endorsementActions.changeStatusRequestCoverage),
      changeStatusRequestCoverage
    ),
    takeLatest(getType(endorsementActions.updateRequestCoverage), updateRequestCoverage),
    debounce(
      800,
      [
        getType(endorsementActions.modal.setModalQuarter),
        getType(endorsementActions.modal.setModalDeclaredMilkProduction),
        getType(endorsementActions.modal.setModalCoverageLevel),
        getType(endorsementActions.modal.setModalProtectionFactor),
        getType(endorsementActions.modal.setModalDeclaredButterfatTest),
        getType(endorsementActions.modal.setModalDeclaredProteinTest),
        getType(endorsementActions.modal.setModalDeclaredClassPriceWeightingFactor),
        getType(endorsementActions.modal.setModalDeclaredComponentPriceWeightingFactor),
        getType(endorsementActions.modal.setModalShare)
      ],
      fetchModalData
    ),
    debounce(
      800,
      [getType(endorsementActions.modal.setModalCalendarDate)],
      updatePracticeAndModalData
    )
  ]);
}
