import { getType } from 'typesafe-actions';

import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

type Field = { component: number; class: number };

export interface DairyRevenueProtectionWhatIfComponentsState {
  expectedDRPRevenueCwt: Field;
  drpRevenueGuarantee: Field;
  drpRevenueGuaranteeCwt: Field;
  actualRevenue: Field;
  actualRevenueCwt: Field;
  grossIndemnity: Field;
  grossIndemnityCwt: Field;
}

const initialState: DairyRevenueProtectionWhatIfComponentsState = {
  expectedDRPRevenueCwt: { component: 0, class: 0 },
  drpRevenueGuarantee: { component: 0, class: 0 },
  drpRevenueGuaranteeCwt: { component: 0, class: 0 },
  actualRevenue: { component: 0, class: 0 },
  actualRevenueCwt: { component: 0, class: 0 },
  grossIndemnity: { component: 0, class: 0 },
  grossIndemnityCwt: { component: 0, class: 0 }
};

export const dairyRevenueProtectionWhatIfDairyComponentsReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) =>
  action.type === getType(dairyRevenueProtectionActions.setWhatIfComponents)
    ? action.payload
    : state;
