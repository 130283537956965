import { PriceTrendsActual } from '../../types/PriceTrendsActual';
import { HistoricalAnalysisActualDto } from '../../types/dto/HistoricalAnalysisActualDto';

export const mapHistoricalAnalysisActual = (
  { quarterPriceModel, yearPriceModel, latest, overall, values }: HistoricalAnalysisActualDto,
  fromYear: number,
  toYear: number
): PriceTrendsActual => ({
  quarterPriceModel,
  yearPriceModel,
  latest,
  overall,
  values: values.map(({ date, price }) => ({ price, date: new Date(date) })),
  fromYear,
  toYear
});
