import React, { useMemo } from 'react';
import { FieldValues, Path, PathValue, UseFormReturn } from 'react-hook-form';
import { FaInfoCircle } from 'react-icons/fa';
import { LuCheck, LuChevronsUpDown } from 'react-icons/lu';
import styled, { css } from 'styled-components/macro';
import { z } from 'zod';

import { Form } from '@/shared/components/common/Form';
import { Button } from '@/shared/components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator
} from '@/shared/components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '@/shared/components/ui/popover';

import Tooltip from '@/components/Tooltip/Tooltip';

import color from '@/utils/color';

type ComboboxTriggerVariant = 'select' | 'secondary';
type ComboboxTriggerVariantStyles = Record<ComboboxTriggerVariant, React.CSSProperties>;

const comboboxTriggerVariants: ComboboxTriggerVariantStyles = {
  select: {
    width: '200px',
    fontSize: '0.875rem',
    fontWeight: 600,
    border: 'none'
  },
  secondary: {
    width: '100%',
    fontSize: '1rem',
    fontWeight: 400,
    border: `1px solid ${color.NEUTRAL_LIGHT}`
  }
};

const ComboboxOptionsSchema = z.array(
  z.object({
    value: z.string(),
    label: z.string()
  })
);

export type ComboboxOptionsType = z.infer<typeof ComboboxOptionsSchema>;

interface ComboboxProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  options: ComboboxOptionsType;
  label: string;
  name: Path<T>;
  placeholder?: string;
  emptyMessage?: string;
  disabled?: boolean;
  dataCy?: string;
  renderCustomInput?: () => React.ReactNode;
  handleSelect?: (value: PathValue<T, Path<T>>) => void;
  popoverTriggerStyle?: React.CSSProperties;
  variant?: ComboboxTriggerVariant;
  tooltipId?: string;
  icon?: React.ReactNode;
  tooltipContent?: React.ReactNode;
}

const Combobox = <T extends FieldValues>({
  form,
  options,
  label,
  name,
  placeholder = 'Choose an option',
  emptyMessage = 'No options found',
  disabled,
  dataCy,
  renderCustomInput,
  handleSelect,
  popoverTriggerStyle,
  variant = 'select',
  tooltipId,
  tooltipContent,
  icon = <FaInfoCircle size={15} />
}: ComboboxProps<T>) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const value = form.watch(name);

  const triggerValue = useMemo(() => {
    if (value) {
      const option = options.find(option => option.value === value);
      if (option) {
        return option.label;
      }
    }

    return placeholder;
  }, [options, placeholder, value]);

  return (
    <Form.FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <Form.FormItem>
          {label && (
            <Form.FormLabel variant="small" htmlFor={name} className="combobox-label">
              <LabelWrapper>
                {label ?? ''}
                {tooltipContent && (
                  <>
                    <Tooltip
                      id={tooltipId ?? tooltipContent.toString().toLowerCase().replace(' ', '_')}
                    >
                      {tooltipContent}
                    </Tooltip>
                    <InfoIconWrapper
                      data-tooltip-content=""
                      data-tooltip-id={
                        tooltipId ?? tooltipContent.toString().toLowerCase().replace(' ', '_')
                      }
                    >
                      {icon}
                    </InfoIconWrapper>
                  </>
                )}
              </LabelWrapper>
            </Form.FormLabel>
          )}

          <Popover open={isOpen} onOpenChange={setIsOpen}>
            <PopoverTrigger asChild>
              <Form.FormControl>
                <Trigger
                  data-cy={`${dataCy}_trigger`}
                  variant="select"
                  triggerVariant={variant}
                  onClick={() => setIsOpen(!isOpen)}
                  disabled={disabled}
                  style={popoverTriggerStyle}
                  className="combobox-trigger"
                >
                  {triggerValue}
                  <CaretSortIcon />
                </Trigger>
              </Form.FormControl>
            </PopoverTrigger>
            <StyledPopoverContent ref={ref} variant={variant} className="combobox-popover">
              <Command>
                {renderCustomInput?.() ?? (
                  <CommandInput data-cy={`${dataCy}_input`} placeholder={placeholder} />
                )}
                <CommandSeparator />
                <CommandEmpty>{emptyMessage}</CommandEmpty>
                <StyledCommandGroup>
                  {options.map(option => (
                    <CommandItem
                      value={option.label}
                      key={option.value}
                      data-cy={`${dataCy}_${option.value}`}
                      onSelect={() => {
                        handleSelect?.(option.value as PathValue<T, Path<T>>);
                        form.setValue(name, option.value as PathValue<T, Path<T>>);
                        setIsOpen(false);
                      }}
                    >
                      {option.label}
                      <CheckIcon data-cy={`${dataCy}_check`} show={option.value === field.value} />
                    </CommandItem>
                  ))}
                </StyledCommandGroup>
              </Command>
            </StyledPopoverContent>
          </Popover>

          <Form.FormMessage />
        </Form.FormItem>
      )}
    />
  );
};

export { Combobox, comboboxTriggerVariants };
export type { ComboboxTriggerVariant, ComboboxTriggerVariantStyles };

export const Trigger = styled(Button)<
  React.ComponentPropsWithoutRef<typeof Button> & { triggerVariant: ComboboxTriggerVariant }
>`
  ${({ theme, triggerVariant = 'select' }) => {
    const { width, fontWeight, fontSize, border } = theme.components.combobox[triggerVariant];

    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      border-radius: 0.375rem;
      padding: 0.25rem 0.5rem;

      width: ${width};
      font-size: ${fontSize};
      font-weight: ${fontWeight} !important;
      border: ${border};

      &:disabled {
        opacity: 0.8;
        cursor: not-allowed;
      }
    `;
  }}
`;

const StyledPopoverContent = styled(PopoverContent)<
  React.ComponentPropsWithoutRef<typeof PopoverContent> & { variant: ComboboxTriggerVariant }
>`
  ${({ theme, variant = 'select' }) => {
    const { width } = theme.components.combobox[variant];

    return css`
      width: ${width};
      padding: 0;
    `;
  }}
`;

const StyledCommandGroup = styled(CommandGroup)`
  max-height: 240px;
  overflow-y: auto;
`;

export const CaretSortIcon = styled(LuChevronsUpDown)`
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  opacity: 0.5;
`;

const CheckIcon = styled(LuCheck)<{ show: boolean }>`
  margin-left: auto;
  height: 1rem;
  width: 1rem;

  opacity: ${({ show }) => (show ? 1 : 0)};
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const InfoIconWrapper = styled.div`
  color: ${color.DRP_INPUT};
  font-size: 14px;
  transform: translateY(2px);
  padding: 3px;
`;
