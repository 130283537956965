import { all, put, select, takeLatest } from '@redux-saga/core/effects';
import { addMonths } from 'date-fns';
import { ActionType, getType } from 'typesafe-actions';

import { LgmRow } from '../../../types/Lgm/LgmRow';
import { lgmActions } from '../quoter/actions';
import { getLgmRowData } from '../quoter/selectors';
import { getLgmCalendarDate } from '../settings/selectors';
import { lgmSettingsActions } from './actions';
import { FeedType } from './reducer';

function* calendarDateChange() {
  try {
    const salesDate: Date = yield select(getLgmCalendarDate);
    const lgmRows: LgmRow[] = new Array(10).fill(undefined).map((_, index) => ({
      id: index,
      date: addMonths(new Date(salesDate.getFullYear(), salesDate.getMonth() + 2), index),
      prices: { milk: 0, corn: 0, sbm: 0 },
      quantity: {
        milk: index === 0 || index === 1 ? 10000 : 0,
        corn: index === 0 || index === 1 ? 140 : 0,
        sbm: index === 0 || index === 1 ? 20 : 0
      }
    }));
    yield put(lgmActions.addRows(lgmRows));
    yield put(lgmSettingsActions.setFeedType(FeedType.DEFAULT));
    yield put(lgmActions.getLgmEstimated());
  } catch (e) {
    console.error(e);
  }
}

interface Quantity {
  milk: number;
  corn: number;
  sbm: number;
}
const getQuantity =
  (feedType: FeedType) =>
  ({ milk, corn, sbm }: Quantity) => {
    switch (feedType) {
      case FeedType.LOWEST:
        return {
          milk,
          corn: Math.round(milk * 0.00364 * 100) / 100,
          sbm: Math.round(milk * 0.000805 * 100) / 100
        };
      case FeedType.HIGHEST:
        return {
          milk,
          corn: Math.round(milk * 0.0381 * 100) / 100,
          sbm: Math.round(milk * 0.013 * 100) / 100
        };
      case FeedType.DEFAULT:
        return {
          milk,
          corn: Math.round(milk * 0.014 * 100) / 100,
          sbm: Math.round(milk * 0.002 * 100) / 100
        };
      default:
        return { milk, corn, sbm };
    }
  };

function* onFeedTypeChange(action: ActionType<typeof lgmSettingsActions.setFeedType>) {
  try {
    if (action.payload !== FeedType.CUSTOM) {
      const monthValues: LgmRow[] = yield select(getLgmRowData);

      const lgmRows: LgmRow[] = monthValues.map(({ quantity, ...props }: LgmRow) => ({
        ...props,
        quantity: getQuantity(action.payload)(quantity)
      }));

      yield put(lgmActions.addRows(lgmRows));
      yield put(lgmActions.getLgmEstimated());
    }
  } catch (e) {
    console.log(e);
  }
}

export function* lgmSettingsSagaWatcher() {
  yield all([
    takeLatest(getType(lgmSettingsActions.setCalendarDate), calendarDateChange),
    takeLatest(getType(lgmSettingsActions.setFeedType), onFeedTypeChange)
  ]);
}
