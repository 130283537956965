import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { HistoricalAnalysisModel } from '../../../types/HistoricalAnalysis';
import { HistoricalAnalysisSummariesDto } from '../../../types/dto/HistoricalAnalysisDto';
import { DairyRevenueProtectionActions, dairyRevenueProtectionActions } from '../actions';

export interface DairyRevenueProtectionHistoricalState {
  timeSpan: {
    from: number;
    to: number;
  };
  isClassChart: boolean;
  records: HistoricalAnalysisModel[];
  summary: HistoricalAnalysisSummariesDto;
  isDataAvailable: boolean;
}

const initialState: DairyRevenueProtectionHistoricalState = {
  timeSpan: {
    from: new Date().getFullYear() - 10,
    to: new Date().getFullYear()
  },
  isClassChart: false,
  records: [],
  summary: {
    class: {
      averageNetIndemnity: 0,
      cumulativeNetIndemnity: 0,
      drpPremium: 0,
      drpPremiumCwt: 0,
      indemnityAverage: 0,
      indemnityAverageCwt: 0,
      indemnityMax: 0,
      indemnityMaxCwt: 0,
      netIndemnityAverageCwt: 0,
      netIndemnityCumulativeCwt: 0,
      quarters: 0,
      quartersWithIndemnity: 0
    },
    component: {
      averageNetIndemnity: 0,
      cumulativeNetIndemnity: 0,
      drpPremium: 0,
      drpPremiumCwt: 0,
      indemnityAverage: 0,
      indemnityAverageCwt: 0,
      indemnityMax: 0,
      indemnityMaxCwt: 0,
      netIndemnityAverageCwt: 0,
      netIndemnityCumulativeCwt: 0,
      quarters: 0,
      quartersWithIndemnity: 0
    }
  },
  isDataAvailable: true
};

export const dairyRevenueProtectionHistoricalReducer = (
  state = initialState,
  action: DairyRevenueProtectionActions
) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(dairyRevenueProtectionActions.historical.setFrom):
        draft.timeSpan.from = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.historical.setTo):
        draft.timeSpan.to = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.historical.setRecords):
        draft.records = action.payload.historyAnalysisModels;
        draft.summary = action.payload.summary;
        return;
      case getType(dairyRevenueProtectionActions.historical.setIsClassChartSelected):
        draft.isClassChart = action.payload;
        return;
      case getType(dairyRevenueProtectionActions.historical.setIsDataAvailable): {
        draft.isDataAvailable = action.payload;
        return;
      }
    }
  });
