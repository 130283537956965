import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import ScenarioWhatIfPrices from '../../../types/ScenarioWhatIf/ScenarioWhatIfPrices';
import { formatDateISO } from '../../../utils/dateFormatter';
import { loadersActions } from '../../loaders/actions';
import { getOperationName, getOperationPageOperationData } from '../../operationPage/selectors';
import { scenarioAnalysisActions } from '../actions';
import {
  getScenarioWhatIfPrices,
  getWhatIfScenarios as getWhatIfScenarioNames
} from '../selectors';

export const initalWhatIfPrices = {
  butterfatPrice: [],
  proteinPrice: [],
  otherSolidsPrice: [],
  nonfatSolidsPrice: [],
  milkProduction: [],
  reinsuranceYear: new Date().getFullYear(),
  butterPrice: [],
  cheesePrice: [],
  dryWheyPrice: [],
  nonfatDryMilkPrice: [],
  classIIIPrice: [],
  classIVPrice: []
};

function* initWhatIfScenario(): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operationData = yield select(getOperationPageOperationData);
  const operation = operationData.name;
  const operationFullName = operationData.fullName;

  try {
    const scenarios = yield call(insuranceAPI.getWhatIfScenarios, operation);
    if (scenarios.data.length > 0) {
      yield put(scenarioAnalysisActions.saveWhatIfScenarios(scenarios.data));
      const initialScenario = scenarios.data[0];
      yield put(scenarioAnalysisActions.saveCurrentScenarioName(initialScenario));
      const whatIfPricesResponse: AxiosResponse<ScenarioWhatIfPrices> = yield call(
        insuranceAPI.getWhatIfScenario,
        operation,
        initialScenario
      );
      yield put(scenarioAnalysisActions.saveScenarioWhatIfPrices(whatIfPricesResponse.data));
    } else {
      const initialScenario = `${operationFullName} ${formatDateISO(new Date())}`;
      yield call(insuranceAPI.putWhatIfScenario, operation, initialScenario, initalWhatIfPrices);
      yield put(scenarioAnalysisActions.saveWhatIfScenarios([initialScenario]));
      yield put(scenarioAnalysisActions.saveCurrentScenarioName(initialScenario));
      const whatIfPricesResponse: AxiosResponse<ScenarioWhatIfPrices> = yield call(
        insuranceAPI.getWhatIfScenario,
        operation,
        initialScenario
      );
      yield put(scenarioAnalysisActions.saveScenarioWhatIfPrices(whatIfPricesResponse.data));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(false));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* createWhatIfScenario(
  action: ActionType<typeof scenarioAnalysisActions.createWhatIfScenario>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operation = yield select(getOperationName);
  const scenario = action.payload;
  try {
    yield call(insuranceAPI.putWhatIfScenario, operation, action.payload, initalWhatIfPrices);
    yield put(scenarioAnalysisActions.addWhatIfScenario(scenario));
    yield put(scenarioAnalysisActions.saveCurrentScenarioName(scenario));
    toast.success('Scenario Created');
  } catch (e) {
    console.error(e);
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(false));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* getWhatIfScenario(
  action: ActionType<typeof scenarioAnalysisActions.getWhatIfScenario>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operation = yield select(getOperationName);
  const { year, quarter, scenarioName } = action.payload;

  try {
    const whatIfPricesResponse: AxiosResponse<ScenarioWhatIfPrices> = yield call(
      insuranceAPI.getWhatIfScenario,
      operation,
      scenarioName
    );
    yield all([
      put(scenarioAnalysisActions.saveCurrentScenarioName(scenarioName)),
      put(scenarioAnalysisActions.getScenarioEndorsementsSummary()),
      put(scenarioAnalysisActions.getScenarioEndorsementsByQuarter({ year, quarter })),
      put(scenarioAnalysisActions.saveScenarioWhatIfPrices(whatIfPricesResponse.data)),
      put(scenarioAnalysisActions.dataActions.getAll()),
      put(scenarioAnalysisActions.projectedRevenue.settings.get(year))
    ]);
    toast.success('Scenario Loaded');
  } catch (e) {
    console.error(e);
    toast.error('Failed to load scenario');
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(false));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* getWhatIfScenarios(): any {
  const operation = yield select(getOperationName);
  try {
    const scenarios = yield call(insuranceAPI.getWhatIfScenarios, operation);
    yield put(scenarioAnalysisActions.saveWhatIfScenarios(scenarios.data));
  } catch (e) {
    console.error(e);
  }
}

function* saveWhatIfScenario(
  action: ActionType<typeof scenarioAnalysisActions.saveWhatIfScenario>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operation = yield select(getOperationName);
  const whatIfPrices = yield select(getScenarioWhatIfPrices);
  const newWhatIfPrices = {
    butterfatPrice: whatIfPrices.butterfatPrice,
    proteinPrice: whatIfPrices.proteinPrice,
    otherSolidsPrice: whatIfPrices.otherSolidsPrice,
    nonfatSolidsPrice: whatIfPrices.nonfatSolidsPrice,
    milkProduction: whatIfPrices.milkProduction,
    reinsuranceYear: whatIfPrices.reinsuranceYear,
    butterPrice: whatIfPrices.butterPrice,
    cheesePrice: whatIfPrices.cheesePrice,
    dryWheyPrice: whatIfPrices.dryWheyPrice,
    nonfatDryMilkPrice: whatIfPrices.nonfatDryMilkPrice,
    classIIIPrice: whatIfPrices.classIIIPrice,
    classIVPrice: whatIfPrices.classIVPrice
  };

  try {
    yield call(insuranceAPI.putWhatIfScenario, operation, action.payload, newWhatIfPrices);
    yield put(scenarioAnalysisActions.saveCreatedWhatIfScenario(action.payload));
    yield put(scenarioAnalysisActions.saveCurrentScenarioName(action.payload));
    toast.success('Scenario Saved');
  } catch (e) {
    console.error(e);
  } finally {
    yield put(scenarioAnalysisActions.setRunScenarioButtonState(false));
    yield put(loadersActions.scenarioAnalysis.disable());
  }
}

function* deleteWhatIfScenario(
  action: ActionType<typeof scenarioAnalysisActions.deleteWhatIfScenario>
): any {
  yield put(loadersActions.scenarioAnalysis.activate());
  const operationData = yield select(getOperationPageOperationData);
  const operation = operationData.name;
  const operationFullName = operationData.fullName;
  const { scenarioName, year, quarter } = action.payload;

  try {
    yield call(insuranceAPI.deleteWhatIfScenario, operation, scenarioName);
    yield put(scenarioAnalysisActions.removeWhatIfScenario(scenarioName));
    toast.success('Scenario Deleted');
  } catch (e) {
    console.error(e);
  }

  const operationScenarios = yield select(getWhatIfScenarioNames);
  let scenario = '';
  if (operationScenarios.length === 0) {
    scenario = `${operationFullName} ${formatDateISO(new Date())}`;
    yield call(saveWhatIfScenario, scenarioAnalysisActions.saveWhatIfScenario(scenario));
  } else {
    scenario = operationScenarios[0];
    yield put(scenarioAnalysisActions.saveCurrentScenarioName(scenario));
  }

  yield put(
    scenarioAnalysisActions.getWhatIfScenario(
      { scenarioName: scenario, year, quarter },
      () => void 0
    )
  );

  yield put(loadersActions.scenarioAnalysis.disable());
}

function* scenarioAnalysisScenarioSagaWatcher() {
  yield all([
    takeLatest(getType(scenarioAnalysisActions.initWhatIfScenario), initWhatIfScenario),
    takeLatest(getType(scenarioAnalysisActions.createWhatIfScenario), createWhatIfScenario),
    takeLatest(getType(scenarioAnalysisActions.getWhatIfScenario), getWhatIfScenario),
    takeLatest(getType(scenarioAnalysisActions.getWhatIfScenarios), getWhatIfScenarios),
    takeLatest(getType(scenarioAnalysisActions.saveWhatIfScenario), saveWhatIfScenario),
    takeLatest(getType(scenarioAnalysisActions.deleteWhatIfScenario), deleteWhatIfScenario)
  ]);
}

export default scenarioAnalysisScenarioSagaWatcher;
