import { Units } from '../types/Units';
import { Language } from './language';
import { utcDateToLocalDate } from './utcDateToLocalDate';

export const customScaleFormatter = (number: number, minDecimal: number, maxDecimal: number) =>
  number.toLocaleString(Language.DEFAULT, {
    minimumFractionDigits: minDecimal,
    maximumFractionDigits: maxDecimal
  });

const toDateType = (date: string | Date) =>
  typeof date === 'string' ? utcDateToLocalDate(date) : date;
export const formatDateShort = (date?: string | Date) =>
  Intl.DateTimeFormat(Language.DEFAULT).format(date ? toDateType(date) : new Date());
export const formatDateLong = (date?: string) =>
  Intl.DateTimeFormat(Language.DEFAULT, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: true
  }).format(date ? new Date(date) : new Date());

/**
 * Format a number based on the provided scale and locale, with optional unit.
 *
 * @function
 * @param {number} [scale=0] - The number of decimal places to display in the formatted output.
 * @param {number} [value] - The value which is being formatted.

 * @param {string} [locale=Language.DEFAULT] - The locale code used for formatting the number.
 * @param {Units} [unit] - The optional unit to format the number with. Possible values: 'DOLLAR', 'PERCENT', 'DOLLAR_PER_LB', 'DOLLAR_PER_CWT', 'DOLLAR_PER_HEAD'.
 *
 * @returns {string} The formatted number as a string, including the specified unit (if provided)
 **/
export const formatNumber =
  (scale = 0, locale: string = Language.DEFAULT) =>
  (value: number | null | undefined, unit?: Units) => {
    if (value == null) {
      if (
        unit === Units.DOLLAR ||
        unit === Units.DOLLAR_PER_CWT ||
        unit === Units.DOLLAR_PER_LB ||
        unit === Units.DOLLAR_PER_HEAD
      ) {
        return 'No price';
      }
      return '';
    }

    if (unit !== null) {
      switch (unit) {
        case Units.DOLLAR_PER_CWT:
          return (
            Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: scale,
              maximumFractionDigits: scale
            }).format(value) + '/cwt'
          );
        case Units.DOLLAR_PER_LB:
          return (
            Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: scale,
              maximumFractionDigits: scale
            }).format(value) + '/lb'
          );

        case Units.DOLLAR_PER_HEAD:
          return (
            Intl.NumberFormat(locale, {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: scale,
              maximumFractionDigits: scale
            }).format(value) + '/head'
          );

        case Units.DOLLAR:
          return Intl.NumberFormat(locale, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: scale,
            maximumFractionDigits: scale
          }).format(value);
        case Units.PERCENT:
          return Intl.NumberFormat(locale, {
            style: 'percent',
            minimumFractionDigits: scale,
            maximumFractionDigits: scale
          }).format(value);
        default:
          return Intl.NumberFormat(locale, {
            minimumFractionDigits: scale,
            maximumFractionDigits: scale
          }).format(value);
      }
    }
    return Intl.NumberFormat(locale, {
      minimumFractionDigits: scale,
      maximumFractionDigits: scale
    }).format(value);
  };
