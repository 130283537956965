import { ActionType, createAction } from 'typesafe-actions';

import { ProjectionAnalysis } from '../../types/ProjectionAnalysis';
import { ProjectionAnalysisParameters } from '../../types/ProjectionAnalysisParameters';
import { ProjectionAnalysisParametersDto } from '../../types/dto/ProjectionAnalysisParametersDto';

export interface ProjectionDataQuery {
  projectionModel?: number;
  q1Basis?: number | null;
  q2Basis?: number | null;
  q3Basis?: number | null;
  q4Basis?: number | null;
  q1AdjustedBasisCwt?: number | null;
  q2AdjustedBasisCwt?: number | null;
  q3AdjustedBasisCwt?: number | null;
  q4AdjustedBasisCwt?: number | null;
}

export const projectionDataActions = {
  getProjectionModel: createAction('@projection_data/GET_PROJECTION_MODEL')<number, number>(),
  get: createAction('@projection_data/GET')<number, ProjectionDataQuery>(),
  getOnBlur: createAction('@projection_data/GET_ON_BLUR')<number, ProjectionDataQuery>(),
  save: createAction('@projection_data/SAVE')<ProjectionAnalysis>(),
  nextYearSave: createAction('@projection_data/NEXT_YEAR_SAVE')<ProjectionAnalysis>(),
  settings: {
    get: createAction('@projection_data/SETTINGS_GET')<number>(),
    save: createAction('@projection_data/SETTINGS_SAVE')<ProjectionAnalysisParameters | null>(),
    nextYearSave: createAction(
      '@projection_data/SETTINGS_NEXT_YEAR_SAVE'
    )<ProjectionAnalysisParameters | null>(),
    update: createAction('@projection_data/SETTINGS_UPDATE')<ProjectionAnalysisParametersDto>()
  }
};

export type ProjectionDataActions = ActionType<typeof projectionDataActions>;
