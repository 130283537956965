import { AxiosResponse } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../services/insuranceService';
import { Agent } from '../../types/Agent/Agent';
import { userAgentActions } from './reducer';

export function* getUserAgent(action: ActionType<typeof userAgentActions.getUserAgent>) {
  try {
    const { agentName, agencyName } = action.payload;
    if (agentName && agencyName) {
      const userAgent: AxiosResponse<Agent> = yield call(
        insuranceAPI.getAgencyAgent,
        agencyName,
        agentName,
        true
      );
      yield put(userAgentActions.setUserAgent(userAgent.data));
    }
  } catch (error) {
    console.log(error);
  }
}

export default function* userAgentSaga() {
  yield all([takeLatest(getType(userAgentActions.getUserAgent), getUserAgent)]);
}
