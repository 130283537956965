import * as AccordionPrimitive from '@radix-ui/react-accordion';
import * as React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components/macro';

import color from '@/utils/color';

type AccordionVariant = 'default' | 'secondary';
export type AccordionVariantStyles = Record<AccordionVariant, React.CSSProperties>;

export const accordionVariants: AccordionVariantStyles = {
  default: {
    backgroundColor: color.PRIMARY
  },
  secondary: {
    backgroundColor: color.NEUTRAL_DARK
  }
};

interface Props {
  dataCy: string;
  variant?: AccordionVariant;
  title: string;
  children: React.ReactNode;
  marginBottom?: number;
  itemId: string;
}

const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Root> & Props
>(({ dataCy, itemId, title, children, ...props }, ref) => (
  <StyledAccordion data-cy={dataCy} {...props}>
    <AccordionPrimitive.Item value={itemId}>
      <AccordionTrigger data-cy={`${dataCy}_trigger`} {...props}>
        {title}
      </AccordionTrigger>
      <AccordionContent data-cy={`${dataCy}_content`} ref={ref}>
        {children}
      </AccordionContent>
    </AccordionPrimitive.Item>
  </StyledAccordion>
));
Accordion.displayName = AccordionPrimitive.Root.displayName;

type AccordionTriggerProps = { children: React.ReactNode; variant?: AccordionVariant };
const AccordionTrigger = React.forwardRef<HTMLButtonElement, AccordionTriggerProps>(
  ({ children, ...props }, forwardedRef) => (
    <AccordionPrimitive.Header>
      <StyledHeaderTrigger {...props} ref={forwardedRef} type="button">
        {children}
        <Chevron className="AccordionChevron" aria-hidden />
      </StyledHeaderTrigger>
    </AccordionPrimitive.Header>
  )
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children, ...props }, forwardedRef) => (
    <StyledContent {...props} ref={forwardedRef}>
      <div>{children}</div>
    </StyledContent>
  )
);
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion };

const StyledAccordion = styled(AccordionPrimitive.Root)`
  width: 100%;

  & h3 {
    margin: 0 !important;
  }
`;

const StyledHeaderTrigger = styled(AccordionPrimitive.Trigger)<{
  marginBottom?: number;
  variant?: AccordionVariant;
}>`
  ${({ theme, variant = 'default', marginBottom }) => {
    const { backgroundColor } = theme.components.accordion[variant];

    return css`
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: inherit;
      justify-content: space-between;
      padding: 0.25rem;
      font-size: 1rem;
      background-color: ${backgroundColor + 90};
      font-weight: bold;
      color: ${color.BACKGROUND};
      margin-bottom: ${marginBottom}px;
      outline: none;
      border: none;
      cursor: pointer;

      &:hover {
        transition: background-color 0.2s ease-in;
        background-color: ${backgroundColor};
      }

      &[data-state='open'] > .AccordionChevron {
        transform: rotate(180deg);
      }

      &[data-state='close'] > .AccordionChevron {
        transform: rotate(0deg);
      }
    `;
  }}
`;

const Chevron = styled(FaChevronDown)`
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
`;

const StyledContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
  &[data-state='close'] {
    animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;
