import { all } from 'redux-saga/effects';

import scenarioAnalysisDataSagaWatcher from './analysisData/sagas';
import scenarioAnalysisEndorsementSettingsSagaWatcher from './endorsementSettings/sagas';
import scenarioAnalysisProjectedRevenueSagaWatcher from './projectedRevenue/sagas';
import scenarioAnalysisWhatIfSagaWatcher from './scenarioWhatIf/sagas';
import scenarioAnalysisScenarioSagaWatcher from './scenarios/sagas';
import scenarioAnalysisEndorsementSagaWatcher from './summaries/sagas';

export default function* scenarioAnalysisSagaWatcher() {
  yield all([
    scenarioAnalysisDataSagaWatcher(),
    scenarioAnalysisEndorsementSettingsSagaWatcher(),
    scenarioAnalysisScenarioSagaWatcher(),
    scenarioAnalysisEndorsementSagaWatcher(),
    scenarioAnalysisWhatIfSagaWatcher(),
    scenarioAnalysisProjectedRevenueSagaWatcher()
  ]);
}
