import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import { AccountPreferences } from '../../types/AccountPreferences/AccountPrefereces';
import { AccountPreferencesActions, accountPreferences } from './actions';

const initialState: AccountPreferences = {
  drpPriceReport: {
    frequency: null,
    channel: [''],
    operation: '',
    frequencyCanModify: false,
    frequencyModifiedBy: '',
    frequencyModified: ''
  },
  lrpPriceReport: {
    frequency: null,
    channel: [''],
    operation: '',
    frequencyCanModify: false,
    frequencyModifiedBy: '',
    frequencyModified: ''
  },
  drpExpiredEndorsementsChannel: [''],
  lrpExpiredEndorsementsChannel: [''],
  defaultOperation: '',
  timeZone: 'America/New_York',
  phoneNumber: '',
  alertChannel: [''],
  lrpAlertChannel: [''],
  showStarredOperationsOnly: false,
  smsOptedOut: true
};

const accountPreferencesReducer = (state = initialState, action: AccountPreferencesActions) =>
  produce(state, draft => {
    switch (action.type) {
      case getType(accountPreferences.addAccountPreferences): {
        draft.defaultOperation = action.payload.defaultOperation;
        draft.drpPriceReport.operation = action.payload.drpPriceReport.operation;
        draft.lrpPriceReport.operation = action.payload.lrpPriceReport.operation;
        draft.phoneNumber = action.payload.phoneNumber;
        draft.timeZone = action.payload.timeZone;
        draft.drpPriceReport.frequency = action.payload.drpPriceReport.frequency;
        draft.lrpPriceReport.frequency = action.payload.lrpPriceReport.frequency;
        draft.drpPriceReport.frequencyCanModify = action.payload.drpPriceReport.frequencyCanModify;
        draft.drpPriceReport.frequencyModified = action.payload.drpPriceReport.frequencyModified;
        draft.drpPriceReport.frequencyModifiedBy =
          action.payload.drpPriceReport.frequencyModifiedBy;
        draft.lrpPriceReport.frequencyCanModify = action.payload.lrpPriceReport.frequencyCanModify;
        draft.lrpPriceReport.frequencyModified = action.payload.lrpPriceReport.frequencyModified;
        draft.lrpPriceReport.frequencyModifiedBy =
          action.payload.lrpPriceReport.frequencyModifiedBy;
        draft.alertChannel = action.payload.alertChannel;
        draft.lrpAlertChannel = action.payload.lrpAlertChannel;
        draft.drpPriceReport.channel = action.payload.drpPriceReport.channel;
        draft.lrpPriceReport.channel = action.payload.lrpPriceReport.channel;
        draft.smsOptedOut = action.payload.smsOptedOut;
        draft.showStarredOperationsOnly = action.payload.showStarredOperationsOnly;
        return;
      }
      case getType(accountPreferences.saveUpdatedAccountPreferences): {
        draft.defaultOperation = action.payload.defaultOperation;
        draft.drpPriceReport.operation = action.payload.drpPriceReport.operation;
        draft.lrpPriceReport.operation = action.payload.lrpPriceReport.operation;
        draft.drpPriceReport.frequency = action.payload.drpPriceReport.frequency;
        draft.lrpPriceReport.frequency = action.payload.lrpPriceReport.frequency;
        draft.phoneNumber = action.payload.phoneNumber;
        draft.timeZone = action.payload.timeZone;
        draft.alertChannel = action.payload.alertChannel;
        draft.lrpAlertChannel = action.payload.lrpAlertChannel;
        draft.drpPriceReport.channel = action.payload.drpPriceReport.channel;
        draft.lrpPriceReport.channel = action.payload.lrpPriceReport.channel;
        draft.smsOptedOut = action.payload.smsOptedOut;
        return;
      }
      case getType(accountPreferences.saveUpdatedStarredOperations): {
        draft.showStarredOperationsOnly = action.payload;
        return;
      }
    }
  });

export default accountPreferencesReducer;
