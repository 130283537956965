import { getType } from 'typesafe-actions';

import { PriceTrendsActual } from '../../types/PriceTrendsActual';
import { PriceTrendsExpected } from '../../types/PriceTrendsExpected';
import { PriceTrendsActions, priceTrendsActions } from './actions';

export interface PriceTrends {
  [product: string]: {
    actual: PriceTrendsActual;
    expected: PriceTrendsExpected;
  };
}

const initialState: PriceTrends = {};

export const priceTrendsReducer = (state = initialState, action: PriceTrendsActions) => {
  switch (action.type) {
    case getType(priceTrendsActions.setPriceActualTrends): {
      return { ...state, [action.meta]: { ...state[action.meta], actual: action.payload } };
    }
    case getType(priceTrendsActions.setPriceExpectedTrends): {
      return { ...state, [action.meta]: { ...state[action.meta], expected: action.payload } };
    }
    default:
      return state;
  }
};
