const priceTrendProductsOnHover: { [key: string]: string } = {
  ClassIIIMilk: 'Class III milk price',
  ClassIVMilk: 'Class IV milk price',
  Butter: 'butter price',
  Cheese: 'cheese price',
  DryWhey: 'dry whey price',
  Butterfat: 'butterfat price',
  Protein: 'protein price',
  OtherSolids: 'other solids price',
  NonfatDryMilk: 'nonfat dry milk price'
};

export default priceTrendProductsOnHover;
