import { produce } from 'immer';

import { PreviousOrCurrent } from '../../types/WhatIfBody';
import { MonthPrice } from '../../types/dto/InsuranceDto';
import { DairyRevenueProtectionWhatIfExpectedActualState } from './whatIfExpectedActual/reducer';
import { DairyRevenueProtectionWhatIfInputsState } from './whatIfInputs/reducer';

const simulateWhatIfPreviousAndCurrentParametersFactory = (
  inputs: DairyRevenueProtectionWhatIfInputsState,
  expectedActual: DairyRevenueProtectionWhatIfExpectedActualState,
  milkProductionPerCow: number,
  raw: any
): { previous: PreviousOrCurrent; current: PreviousOrCurrent } => {
  const mapper =
    (inputs: number[]) =>
    ({ date, expectedPrice }: MonthPrice, index: number) => ({
      date,
      expectedPrice,
      actualPrice: inputs[index]
    });

  return {
    previous: raw,
    current: produce(raw, (draft: any) => {
      draft.classIIIPrice.monthPrices = draft.classIIIPrice.monthPrices.map(
        mapper(inputs.classIIIMilk)
      );
      draft.classIVPrice.monthPrices = draft.classIVPrice.monthPrices.map(
        mapper(inputs.classIVMilk)
      );
      draft.butterPrice.monthPrices = draft.butterPrice.monthPrices.map(mapper(inputs.butter));
      draft.cheesePrice.monthPrices = draft.cheesePrice.monthPrices.map(mapper(inputs.cheese));
      draft.dryWheyPrice.monthPrices = draft.dryWheyPrice.monthPrices.map(mapper(inputs.dryWhey));
      // draft.butterfatPrice.monthPrices = draft.butterfatPrice.monthPrices.map(mapper(inputs));
      // draft.proteinPrice.monthPrices = draft.proteinPrice.monthPrices.map(mapper(inputs.pro));
      // draft.otherSolidsPrice.monthPrices = draft.otherSolidsPrice.monthPrices.map(mapper(inputs));
      draft.nonfatDryMilkPrice.monthPrices = draft.nonfatDryMilkPrice.monthPrices.map(
        mapper(inputs.noFatDryMilk)
      );

      draft.classIIIPrice.actualPrice = expectedActual.classIIIPrice.actual;
      draft.classIVPrice.actualPrice = expectedActual.classIVPrice.actual;
      draft.butterfatPrice.actualPrice = expectedActual.butterfat.actual;
      draft.proteinPrice.actualPrice = expectedActual.protein.actual;
      draft.otherSolidsPrice.actualPrice = expectedActual.otherSolidsPrice.actual;
      if (expectedActual.nonfatSolidsPrice) {
        draft.nonfatSolidsPrice.actualPrice = expectedActual.nonfatSolidsPrice.actual;
      }
      draft.milkProduction.actualPrice = milkProductionPerCow;
    }) as any
  };
};

export default simulateWhatIfPreviousAndCurrentParametersFactory;
