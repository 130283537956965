import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import insuranceAPI from '../../../services/insuranceService';
import { Agency } from '../../../types/Agency';
import { AipDto, AipDtoEndorsement, AipStatus } from '../../../types/dto/AipOperationDto';
import InsurancePlan from '../../../types/dto/InsurancePlan';
import { utcDateToLocalDate } from '../../../utils/utcDateToLocalDate';
import { getAdminPageAgency } from '../../adminPage/selectors';
import { getAgencyName } from '../../agency/selectors';
import { endorsementActions } from '../../endorsement/actions';
import { getInsurancePlan } from '../../insurancePlan/selectors';
import { loadersActions } from '../../loaders/actions';
import { getOperationName } from '../../operationPage/selectors';
import { adminActions } from '../actions';

export function* getAipStatus(action: ActionType<typeof adminActions.aipSync.getStatus>) {
  try {
    const status: AxiosResponse<AipStatus[]> = yield call(
      insuranceAPI.getAipStatus,
      action.payload
    );
    yield put(adminActions.aipSync.saveStatus(status.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getAipOperations(action: ActionType<typeof adminActions.aipSync.getOperations>) {
  yield put(loadersActions.spinner.activate());
  try {
    const operations: AxiosResponse<AipDto[]> = yield call(
      insuranceAPI.getAipOperations,
      action.payload
    );
    yield put(adminActions.aipSync.saveOperations(operations.data));
  } catch (error) {
    console.log(error);
  }
  yield put(loadersActions.spinner.disable());
}

export function* rematchOperation(action: ActionType<typeof adminActions.aipSync.matchOperation>) {
  try {
    yield call(insuranceAPI.unmatchOperation, action.payload, {
      agencyName: action.meta.agencyName,
      insurancePlan: action.meta.insurancePlan
    });
    yield call(
      insuranceAPI.matchAipOperation,
      action.meta.agencyName,
      action.meta.operationName,
      action.payload
    );
  } catch (error) {
    console.log(error);
    toast.error('Failed to match operation ' + action.payload.businessName);
  } finally {
    yield call(fetchOperations, action.meta.insurancePlan, action.meta.reinsuranceYear);
  }
}

export function* matchOperation(action: ActionType<typeof adminActions.aipSync.matchOperation>) {
  yield put(loadersActions.spinner.activate());
  try {
    yield call(
      insuranceAPI.matchAipOperation,
      action.meta.agencyName,
      action.meta.operationName,
      action.payload
    );
  } catch (error) {
    console.log(error);
    toast.error('Failed to match operation ' + action.payload.businessName);
  } finally {
    yield call(fetchOperations, action.meta.insurancePlan, action.meta.reinsuranceYear);
  }
}

export function* unmatchOperation(
  action: ActionType<typeof adminActions.aipSync.unmatchOperation>
) {
  yield put(loadersActions.spinner.activate());
  try {
    yield call(insuranceAPI.unmatchOperation, action.payload, {
      agencyName: action.meta.agencyName,
      insurancePlan: action.meta.insurancePlan
    });
  } catch (error) {
    console.log(error);
    toast.error('Failed to unmatch operation ' + action.payload.businessName);
  } finally {
    yield call(fetchOperations, action.meta.insurancePlan, action.meta.reinsuranceYear);
  }
}

export function* fetchOperations(insurancePlan: string | undefined, reinsuranceYear?: number) {
  yield put(loadersActions.spinner.activate());
  try {
    const agency: Agency = yield select(getAdminPageAgency);
    const operations: AxiosResponse<AipDto[]> = yield call(insuranceAPI.getAipOperations, {
      agencyName: agency.name,
      insurancePlan: insurancePlan,
      includeProbableMatches: true,
      reinsuranceYear
    });
    yield put(adminActions.aipSync.saveOperations(operations.data));
  } catch (error) {
    console.log(error);
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* fetchAipEndorsements() {
  yield put(loadersActions.spinner.activate());
  try {
    const agencyName: string = yield select(getAgencyName);
    const operationName: string = yield select(getOperationName);
    const insurancePlan: number = yield select(getInsurancePlan);
    const endorsements: AxiosResponse<AipDtoEndorsement[]> = yield call(
      insuranceAPI.getAipEndorsements,
      agencyName,
      operationName,
      InsurancePlan[insurancePlan]
    );

    const updatedData = endorsements.data.map(e => {
      if (e.endorsement?.salesEffectiveDate) {
        if ('endDate' in e.endorsement && e.endorsement.endDate) {
          e.endorsement.endDate = utcDateToLocalDate(e.endorsement.endDate).toString();
        }
        e.endorsement.salesEffectiveDate = utcDateToLocalDate(
          e.endorsement.salesEffectiveDate
        ).toString();
      }

      if (e.aipEndorsement?.salesEffectiveDate) {
        e.aipEndorsement.salesEffectiveDate = utcDateToLocalDate(
          e.aipEndorsement.salesEffectiveDate
        ).toString();
      }
      return e;
    });
    yield put(adminActions.aipSync.saveAipEndorsements(updatedData));
  } catch (error) {
    console.log(error);
    yield put(adminActions.aipSync.saveAipEndorsements([]));
  } finally {
    yield put(loadersActions.spinner.disable());
  }
}

export function* importAipEndorsement(
  action: ActionType<typeof adminActions.aipSync.importAipEndorsement>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const agencyName: string = action.meta.agencyName;
    const operationName: string = action.meta.operationName;
    const id: string = action.meta.id;
    const insurancePlan: number = yield select(getInsurancePlan);
    yield call(
      insuranceAPI.importAipEndorsement,
      action.payload,
      agencyName,
      operationName,
      id,
      InsurancePlan[insurancePlan]
    );
  } catch (error) {
    console.log(error);
    toast.error('Failed to import endorsement!');
  } finally {
    yield call(fetchAipEndorsements);
    yield put(endorsementActions.getEndorsements());
    yield put(endorsementActions.getEndorsementSummary());
  }
}

export function* ignoreAipDiscrepancy(
  action: ActionType<typeof adminActions.aipSync.ignoreAipDiscrepancy>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const agencyName: string = action.payload.agencyName;
    const operationName: string = action.payload.operationName;
    const id: string = action.payload.id;
    const aipIgnore: boolean = action.payload.aipIgnore;
    const insurancePlan: number = yield select(getInsurancePlan);
    yield call(
      insuranceAPI.ignoreAipDiscrepancy,
      operationName,
      agencyName,
      id,
      aipIgnore,
      InsurancePlan[insurancePlan]
    );
  } catch (error) {
    console.log(error);
    toast.error('Failed to make changes to the endorsement!');
  } finally {
    yield call(fetchAipEndorsements);
    yield put(endorsementActions.getEndorsements());
    yield put(endorsementActions.getEndorsementSummary());
  }
}

export function* deleteEndorsement(
  action: ActionType<typeof adminActions.aipSync.deleteEndorsement>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const id = action.payload;
    const operation: string = yield select(getOperationName);
    yield call(insuranceAPI.deleteEndorsement, operation, id);
  } catch (error) {
    console.log(error);
    toast.error('Failed to import endorsement!');
  } finally {
    yield call(fetchAipEndorsements);
    yield put(endorsementActions.getEndorsements());
    yield put(endorsementActions.getEndorsementSummary());
  }
}

export function* unmatchAipEndorsement(
  action: ActionType<typeof adminActions.aipSync.unmatchAipEndorsement>
) {
  yield put(loadersActions.spinner.activate());
  try {
    const agencyName: string = action.meta.agencyName;
    const operationName: string = action.meta.operationName;
    const insurancePlan: number = yield select(getInsurancePlan);
    yield call(
      insuranceAPI.deleteAipEndorsement,
      action.payload,
      agencyName,
      operationName,
      InsurancePlan[insurancePlan]
    );
  } catch (error) {
    console.log(error);
    toast.error('Failed to delete endorsement!');
  } finally {
    yield call(fetchAipEndorsements);
    yield put(endorsementActions.getEndorsements());
    yield put(endorsementActions.getEndorsementSummary());
  }
}

export default function* aipSyncSagaWatcher() {
  yield all([
    takeLatest(getType(adminActions.aipSync.getStatus), getAipStatus),
    takeLatest(getType(adminActions.aipSync.getOperations), getAipOperations),
    takeEvery(getType(adminActions.aipSync.matchOperation), matchOperation),
    takeEvery(getType(adminActions.aipSync.rematchOperation), rematchOperation),
    takeEvery(getType(adminActions.aipSync.unmatchOperation), unmatchOperation),
    takeLatest(getType(adminActions.aipSync.fetchAipEndorsements), fetchAipEndorsements),
    takeEvery(getType(adminActions.aipSync.importAipEndorsement), importAipEndorsement),
    takeEvery(getType(adminActions.aipSync.ignoreAipDiscrepancy), ignoreAipDiscrepancy),
    takeEvery(getType(adminActions.aipSync.deleteEndorsement), deleteEndorsement),
    takeEvery(getType(adminActions.aipSync.unmatchAipEndorsement), unmatchAipEndorsement)
  ]);
}
