import { ActionType, createAction } from 'typesafe-actions';

import { Agency } from '../../types/Agency';

export const adminPageActions = {
  setSearch: createAction('@adminPage/SET_SEARCH')<string>(),
  setAgency: createAction('@adminPage/SET_AGENCY')<Agency | null>()
};

export type AdminPageActions = ActionType<typeof adminPageActions>;
