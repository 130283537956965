export const utcDateToLocalDateOptional = (date?: Date | string): Date | undefined => {
  if (date === undefined) return undefined;

  return utcDateToLocalDate(date);
};

export const utcDateToLocalDate = (date: Date | string): Date => {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  if (!date) return date;

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0);
};

export const localDateToUtcDate = (date: Date | string): Date => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
};

export const dateOnly = (date?: Date | string): Date => {
  if (!date) {
    date = new Date();
  } else if (typeof date === 'string') {
    date = new Date(date);
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
};
